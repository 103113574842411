import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import { filterProductCategory } from "./../../../redux/productCatergory/productCategoryAction";

export default function FilterCategoryDropDown({
  payLoad,
  page,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.coupon);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    name: "",
  });
  const { name } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      name: "",
    });
    await dispatch(filterProductCategory(payLoad, page));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (name) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterProductCategory({ ...data }));
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Name"}
              htmlFor={"name"}
              name={"name"}
              onChange={handleChange}
              value={name}
              placeholder={"Enter Name"}
            />
            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
            {errorMsg && (
              <div className="flex gap-1 -mt-2">
                <img src={Warning} alt="warning" />
                <p>No value to filter by</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
