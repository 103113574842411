import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Input from "../../../components/inputs/inputs";
import { cleanInput } from "../../../helpers/cleanInput";
import { getTeamFromCookies, getOutletFromCookies } from "../../../utils/Auth";
import { storeInvite } from "../../../redux/invite/inviteActions";

export default function InviteEmployee() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const business = getTeamFromCookies();
  const [input, setInput] = useState({
    invitee_email: "",
    job_title: "",
    job_description: "",
    type: "employee",
  });
  const [submitted, setSubmitted] = useState(false);
  const { invitee_email, job_description, job_title, type } = input;

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (invitee_email && job_title && type) {
      cleanInput(input);
      // get return url from location state or default to home page
      const response = dispatch(storeInvite(input, business));
      if (response) {
        navigate(
          `/admin/${
            outlet?.restaurant_name || outlet?.store_name
          }/employees/all/1`
        );
      }
    }
  };

  return (
    <>
      <div className="border border-[#E4E4F3] bg-white rounded-md pt-8 pb-16 px-10 max-w-3xl mx-auto mt-24">
        <div className="border-b border-[#E4E4F3] py-3 text-center">
          <h3 className="text-lg font-medium">Invite New Employee</h3>
        </div>
        <div className="mt-6">
          <form name="form" id="invite_form" onSubmit={handleSubmit}>
            <div
              className={
                "mt-4" + (submitted && job_description ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Job Descriptions"}
                type="text"
                name="job_description"
                htmlFor={"job_description"}
                onChange={handleChange}
                autoComplete="off"
                placeholder="Enter Job description"
              />
            </div>
            <div>
              <Input.Label
                title={"Job Title*"}
                type="text"
                name="job_title"
                htmlFor={"job_title"}
                autoComplete="off"
                placeholder="Enter Job title"
                onChange={handleChange}
              />
              {submitted && !job_title && (
                <div className="text-primary text-xs ">
                  Job title is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" + (submitted && invitee_email ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Email*"}
                type="email"
                htmlFor={"invitee_email"}
                name="invitee_email"
                id="invitee_email"
                autoComplete="off"
                placeholder="Enter email address"
                onChange={handleChange}
              />
              {submitted && !invitee_email && (
                <div className="text-primary text-xs ">
                  Invitee email is required
                </div>
              )}
            </div>
          </form>
        </div>
      </div>
      <div className="py-8 px-10 max-w-3xl mx-auto text-center mt-6">
        <button
          className="bg-[#CE2600] px-4 py-2 rounded-md text-base  text-center text-white whitespace-nowrap"
          form="invite_form"
        >
          Send Invite
        </button>
      </div>
    </>
  );
}
