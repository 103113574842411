// Application types

const USER = {
  STORING: "STORING",
  ASSIGNING: "ASSIGNING",
  LOADING_AUTH: "LOADING_AUTH",
  GET_ALL_ROLES: "GET_ALL_ROLES",
  GET_A_ROLE: "GET_A_ROLE",
  STORE_A_ROLE: "STORE_A_ROLE",
};

export default USER;
