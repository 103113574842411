import axios from "../plugins/axios.plugin";

class HumanVerificationLevelService {
  baseServicePath = "/api/verification-level";

  getAllHumanVerification() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }

  showAHumanVerification(user_id) {
    return axios.get(
      `${this.baseServicePath}/show?user_id=${user_id}&properties=1`
    );
  }

  editHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  filterHumanVerificationLevel(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }

  storeHumanVerificationLevel(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  searchHumanVerificationLevel(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  verifyHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/verify`, payload);
  }

  rejectHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/reject`, payload);
  }
}

export const humanVerificationLevelService = new HumanVerificationLevelService();
