import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import catalogueType from "./catalogueTypes";
import { catalogueService } from "./../../services/catalogueService";

/**
 * Get my Catalogue
 **/

export const getMyCatalogue = () => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: true });
    const response = await catalogueService.getMyCatalogue();
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({ type: catalogueType["MY_CATALOGUE"], payLoad: response });
  } catch (error) {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};
export const getACatalogue = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: true });
    const response = await catalogueService.getACatalogue(payLoad);
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({ type: catalogueType["GET_A_CATALOGUE"], payLoad: response });
  } catch (error) {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};

export const createCatalogue = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: true });
    const response = await catalogueService.createCatalogue(payLoad);
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({ type: catalogueType["CREATE_CATALOGUE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};
export const editACatalogue = (payLoad) => async (dispatch) => {
  try {
    const response = await catalogueService.editMyCatalogue(payLoad);
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({ type: catalogueType["EDIT_CATALOGUE"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};
export const getAllCatalogues = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: true });
    const response = await catalogueService.getAllCatalogues(payLoad);

    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({
      type: catalogueType["GET_ALL_CATALOGUES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: catalogueType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};

export const approveACatalogue = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["APPROVING"], payLoad: true });
    const response = await catalogueService.approveCatalogue(payLoad);
    dispatch({ type: catalogueType["APPROVE_CATALOGUE"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: catalogueType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const unApproveACatalogue = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["APPROVING"], payLoad: true });
    const response = await catalogueService.unapproveCatalogue(payLoad);
    dispatch({ type: catalogueType["UNAPPROVE_CATALOGUE"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: catalogueType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const searchCatalogueAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["LOADING_SEARCH"], payLoad: true });
    const response = await catalogueService.searchCatalogue(payLoad);
    dispatch({ type: catalogueType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: catalogueType["SEARCH_CATALOGUE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: catalogueType["LOADING_SEARCH"], payLoad: false });
  }
};

export const filterCatalogueAction = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["FILTERING"], payLoad: true });
    const response = await catalogueService.filterCatalogue(payLoad, page);

    dispatch({ type: catalogueType["FILTERING"], payLoad: false });
    dispatch({ type: catalogueType["FILTER_CATALOGUE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: catalogueType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const filterCatalogueActionNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["FILTERING"], payLoad: true });
    const response = await catalogueService.filterCatalogueNoPage(payLoad);
    dispatch({ type: catalogueType["FILTERING"], payLoad: false });
    dispatch({
      type: catalogueType["FILTER_CATALOGUE_NOPAGE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: catalogueType["FILTERING"], payLoad: false });
  }
};
export const deleteCatalogueAction = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: catalogueType["DELETE_CATALOGUE"], payLoad: true });
    const response = await catalogueService.filterCatalogue(payLoad, page);
    dispatch({ type: catalogueType["DELETE_CATALOGUE"], payLoad: false });
    dispatch({ type: catalogueType["FILTER_CATALOGUE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: catalogueType["DELETE_CATALOGUE"], payLoad: false });
  }
};
