// Application types

const PRODUCT_TAG = {
  GET_PRODUCT_TAG: "GET_PRODUCT_TAG",
  STORE_A_PRODUCT_TAG: "STORE_A_PRODUCT_TAG",
  DELETE_A_PRODUCT_TAG: "DELETE_A_PRODUCT_TAG",
  SEARCH_PRODUCT_TAG: "SEARCH_PRODUCT_TAG",
  FILTER_PRODUCT_TAG: "FILTER_PRODUCT_TAG",
  EDIT_A_PRODUCT_TAG: "EDIT_A_PRODUCT_TAG",
  SHOW_A_PRODUCT_TAG: "SHOW_A_PRODUCT_TAG",
  TEST_PRODUCT_TAG_MODEL: "TEST_PRODUCT_TAG_MODEL",
  LOADING_PRODUCT_TAG: "LOADING_PRODUCT_TAG",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
};

export default PRODUCT_TAG;
