import axios from "../plugins/axios.plugin";

class PublicStoreService {
  baseServicePath = "/api/public/store";

  getAllPublicStores(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  searchPublicStore(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  getPublicStore(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
  filterPublicStore(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
}

export const publicStoreService = new PublicStoreService();
