import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../plugins/spinners.plugin";
import { deleteSubscriptionMerchAction } from "../../../redux/subscriptionMerchandise/subscriptionMerchAction";
// import { getAllAttributesAction } from "./../../../redux/attribute/attributeActions";

export default function DeleteSubscriptionMerch({ id, merchandise, outlet }) {
  const merchandise_id = id;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let [isOpen, setIsOpen] = useState(false);
  function handleClose() {
    setIsOpen(false);
  }

  let { deleting } = useSelector((state) => state.subscriptionFood);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const response = await dispatch(
      deleteSubscriptionMerchAction({ id: merchandise_id })
    );
    if (response) {
      handleClose();
      navigate("/admin/merchandise-packages/items/1");
    }
  };
  return (
    <>
      <Button.Primary
        title={"Delete Merchandise Package Item"}
        onClick={() => setIsOpen(true)}
      />
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[358px] rounded-lg sm:w-[450px] p-6 sm:p-6 ">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      {/* <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                        <button
                          type="button"
                          className=" "
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close</span>
                          <RiCloseLine />
                        </button>
                      </div> */}
                      <div>
                        <div className=" text-center  ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black "
                          >
                            Delete Merchandise Package Item
                          </Dialog.Title>
                        </div>
                      </div>

                      <div className="mt-5 sm:mt-7 text-center">
                        <p className="text-lg px-4 text-[#344B67]">
                          Are you sure you want to delete this merchandise
                          package Item ({merchandise}) from {outlet}?
                        </p>
                      </div>
                      <div className="flex justify-between mt-10 sm:mt-10 mb-6">
                        <Button.Secondary
                          title={"Back"}
                          className={"rounded-md px-4"}
                          onClick={handleClose}
                        />
                        <Button.Primary
                          title={
                            deleting ? <Beat color={"#ffffff"} /> : "Delete"
                          }
                          disabled={deleting}
                          className={"rounded-md px-4"}
                          type="submit"
                        />
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
