import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { shipperService } from "../../services/shippers.service";
import { serviceAreaService } from "../../services/serviceArea.service";
import serviceAreaType from "./serviceAreasTypes";

/**
 * GetAllShippers
 *
 */
export const getAllServiceArea = (page) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["LOADING_SHIPPERS"],
      payLoad: true,
    });
    const response = await shipperService.getAllShippers(page);
    dispatch({
      type: serviceAreaType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["GET_ALL_SHIPPERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: serviceAreaType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * StoreServiceAreas
 * @param {object} payLoad{ name:"gaming",types: skilled}
 * @returns {void}
 * */
export const createServiceAreas = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["CREATING"],
      payLoad: true,
    });
    const response = await serviceAreaService.storeServiceAreas({
      ...payLoad,
    });
    dispatch({
      type: serviceAreaType["CREATING"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["CREATE_SERVICE_AREA"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: serviceAreaType["CREATING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete ServiceArea
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteServiceArea = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["DELETING"],
      payLoad: true,
    });
    const response = await serviceAreaService.deleteServiceArea(payLoad);
    dispatch({
      type: serviceAreaType["DELETING"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: serviceAreaType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Edit ServiceArea
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editServiceArea = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["UPDATING"],
      payLoad: true,
    });
    const response = await serviceAreaService.editServiceArea({
      ...payLoad,
    });

    dispatch({
      type: serviceAreaType["UPDATING"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["EDIT_SERVICE_AREA"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: serviceAreaType["UPDATING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Filter Service area
 * @param {object} payLoad {company_id :"7b3e58e0-b68c-498d-b8d5-82198ef6d481"}
 * @returns {void}
 * */
export const filterServiceAreas = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["FILTERING"],
      payLoad: true,
    });
    const response = await serviceAreaService.filterServiceAreas(payLoad, page);
    dispatch({
      type: serviceAreaType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["FILTER_SERVICE_AREAS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: serviceAreaType["FILTERING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Show Shipper
 * @param {object} payLoad {properties:1}
 * */
export const showServiceArea = (payLoad) => async (dispatch) => {
  try {
    const response = await shipperService.showServiceArea(payLoad);

    dispatch({
      type: serviceAreaType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["SHOW_SHIPPERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: serviceAreaType["LOADING_SHIPPERS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
