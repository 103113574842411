import React from "react";
import { EditAddressModal } from "../modals/address/editAddressModal";
import { DeleteAddressModal } from "../modals/address/deleteAddressModal";

export default function AddressDropDown({ address }) {
  return (
    <div className="min-w-[80px]">
      <DeleteAddressModal address={address} />
      <EditAddressModal address={address} />
    </div>
  );
}
