//STORE Types

const STORE_ORDERS = {
  LOADING_STORE_ORDER: "LOADING_STORE_ORDER",
  UPDATING: "UPDATING",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
  UPDATING_STORE_ORDERS: "UPDATING_STORE_ORDERS",
  CREATING: "CREATING",
  SHIPPING: "SHIPPING",
  DOWNLOADING: "DOWNLOADING",
  MARK_STORE_ORDER_CLOSED: "MARK_STORE_ORDER_CLOSED",
  MARK_STORE_ORDER_DELIVERED: "MARK_STORE_ORDER_DELIVERED",
  CREATE_STORE_ORDER: "CREATE_STORE_ORDER",
  GET_ALL_STORE_ORDERS: "GET_ALL_STORE_ORDERS",
  GET_ALL_STORE_ORDERS_NOPAGE: "GET_ALL_STORE_ORDERS_NOPAGE",
  FILTER_STORE_ORDER: "FILTER_STORE_ORDER",
  SEARCH_STORE_ORDERS: "SEARCH_STORE_ORDERS",
  SHOW_A_STORE_ORDER: "SHOW_A_STORE_ORDER",
  GET_MY_STORE_ORDERS: "GET_MY_STORE_ORDERS",
  EDIT_STORE_ORDER: "EDIT_STORE_ORDER",
  DELETE_STORE_ORDER: " DELETE_STORE_ORDER",
};
export default STORE_ORDERS;
