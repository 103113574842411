import axios from "../plugins/axios.plugin";

class TransactionService {
  baseServicePath = "/api/wallet-transaction";

  getAllTransactions(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAllIncompleteTransactions(page) {
    return axios.get(`${this.baseServicePath}/incomplete`);
  }

  getAllTransactionsNoPagination(payload) {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getATransaction(id) {
    return axios.get(
      `${this.baseServicePath}/show?reference_id=${id}&properties=1`
    );
  }

  searchTransactions(params) {
    return axios.post(`${this.baseServicePath}/search/index`, params);
  }

  //   getMyRestaurants() {
  //     return axios.get(`${this.baseServicePath}/me`);
  //   }

  filterTransactions(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }

  transferToWallet(payload) {
    return axios.post(`${this.baseServicePath}/transfer`, payload);
  }

  concludeTransaction(payload) {
    return axios.post(`${this.baseServicePath}/conclude`, payload);
  }

  withdrawFromWallet(payload) {
    return axios.post(`${this.baseServicePath}/withdraw`, payload);
  }
}

export const transactionService = new TransactionService();
