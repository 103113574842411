import authType from "./rolesTypes";

const initialState = {
  isLoading: false,
  assigning: false,
  allRoles: {},
  singleRole: {},
};

export default function roleReducer(state = initialState, action) {
  switch (action.type) {
    case authType.ASSIGNING:
      return {
        ...state,
        assigning: action.payLoad,
      };

    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.GET_ALL_ROLES:
      return {
        ...state,
        allRoles: action.payLoad?.data,
      };

    case authType.GET_A_ROLE:
      return {
        ...state,
        singleRole: action.payLoad?.data,
      };

    case authType.STORE_A_ROLE:
      return {
        ...state,
        allRoles: {
          ...state.allRoles,
          data: [...state?.allRoles?.data, action.payLoad?.data],
        },
      };

    default:
      return state;
  }
}
