//Employee Types

const EMPLOYEES = {
  LOADING_EMPLOYEES: "LOADING_EMPLOYEES",
  FILTERING: "FILTERING",
  LOADING_SEARCH: "LOADING_SEARCH",
  APPROVE_EMPLOYEE: "APPROVE_EMPLOYEE",
  UNAPPROVE_EMPLOYEE: "UNAPPROVE_EMPLOYEE",
  TERMINATE_EMPLOYEE: "TERMINATE_EMPLOYEE",
  GET_ALL_EMPLOYEES: "GET_ALL_EMPLOYEES",
  FILTER_EMPLOYEES: "FILTER_EMPLOYEES",
  FILTER_EMPLOYEES_NOPAGE: "FILTER_EMPLOYEES_NOPAGE",
  SEARCH_EMPLOYEES: "SEARCH_EMPLOYEES",
  SHOW_AN_EMPLOYEE: "GET_AN_EMPLOYEE",
  GET_MY_EMPLOYEE: "GET_MY_EMPLOYEE",
  SHOW_MY_EMPLOYEE_LOCATION: "SHOW_MY_EMPLOYEE_LOCATION",
  EDIT_EMPLOYEE: "EDIT_EMPLOYEE",
  SET_EMPLOYEE_ACTIVE: " SET_EMPLOYEE_ACTIVE",
  SET_EMPLOYEE_INACTIVE: " SET_EMPLOYEE_INACTIVE",
  DELETE_EMPLOYEE: " DELETE_EMPLOYEE",
  TEST_EMPLOYEE_MODEL: "TEST_EMPLOYEE_MODEL",
};
export default EMPLOYEES;
