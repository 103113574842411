import axios from "../plugins/axios.plugin";

class ScoutService {
  baseServicePath = "/api/scout";

  searchApplication(payload) {
    return axios.post(
      `${this.baseServicePath}/search/index?properties=1`,
      payload
    );
  }

}
export const scoutService = new ScoutService();
