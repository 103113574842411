import React, { useEffect, useState } from "react";

export default function Details({ restaurant }) {
  const daysOfWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];
  const [arrangedOperatingTime, setArrangedOperatingTime] = useState([]);

  useEffect(() => {
    if (restaurant?.restaurant_operating_time) {
      const newOperatingTime = daysOfWeek.map((day) => ({
        [day]: restaurant?.restaurant_operating_time[day],
      }));
      setArrangedOperatingTime(newOperatingTime);
    }
    // eslint-disable-next-line
  }, [restaurant?.restaurant_operating_time]);

  return (
    <div data-test="Restaurant-details">
      <section aria-labelledby="section-2-title">
        <h2 className="sr-only" id="section-2-title">
          Restaurant Information
        </h2>
        <div>
          {/* Your content */}
          <h3 className="text-xl font-medium mb-6">Restaurant Information</h3>
          <div className="mb-10">
            <h4 className="mb-2 text-lg">Address</h4>
            <p className="mb-2 text-[#676565]">
              {restaurant?.restaurant_address}, {restaurant?.restaurant_state},{" "}
              {restaurant?.restaurant_country}
            </p>
          </div>

          <div className="mb-10">
            <h4 className="mb-2 text-lg">Open Times</h4>

            {restaurant?.restaurant_operating_time ? (
              arrangedOperatingTime.map((time, i) => {
                return (
                  <div key={i} className="mb-1">
                    {Object.keys(time).map((key, i) => {
                      return (
                        <div key={i + key} className="mb-1">
                          <span className="capitalize">{key}</span> (
                          <span>
                            {restaurant?.restaurant_operating_time[key].open} -{" "}
                            {restaurant?.restaurant_operating_time[key].close}
                          </span>
                          )
                        </div>
                      );
                    })}
                  </div>
                );
              })
            ) : (
              <p className="text-[#676565]">Not available</p>
            )}
          </div>

          <div className="mb-10">
            <h4 className="mb-2 text-lg">Phone</h4>
            <p className="text-[#676565]">{restaurant?.restaurant_phone}</p>
          </div>

          <div className="mb-10">
            <h4 className="mb-2 text-lg">Categories</h4>
            <a href="/" className="text-primary-500">
              {restaurant?.restaurant_categories &&
                restaurant?.restaurant_categories.map((category, i) => (
                  <span key={category + i}>
                    <span>{category}</span>
                    {i < restaurant?.restaurant_categories?.length - 1 ? (
                      <span>, </span>
                    ) : (
                      ""
                    )}
                  </span>
                ))}
            </a>
          </div>
        </div>
      </section>
    </div>
  );
}
