import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import { timezoneService } from '../../services/timezone.service';
import timezoneType from "./timezoneTypes";

/**
 * Get my Tokens
 **/

export const getAllTimeZone = (category) => async (dispatch) => {
  try {
    dispatch({ type: timezoneType["LOADING"], payLoad: true });
    const response = await timezoneService.getAllTimeZone(category);
    dispatch({ type: timezoneType["LOADING"], payLoad: false });
    dispatch({
      type: timezoneType["ALL_TIMEZONES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: timezoneType["LOADING"], payLoad: false });
    errorHandler(error, true);
  }
};
