import axios from "../plugins/axios.plugin";

class OnboardService {
  baseServicePath = "/api/onboard";

  onboardBusiness(payload) {
    return axios.post(`${this.baseServicePath}/business`, payload);
  }
  onboardEmployee(payload) {
    return axios.post(`${this.baseServicePath}/employee`, payload);
  }
  onboardInvite(payload) {
    return axios.post(`${this.baseServicePath}/invite`, payload);
  }
}

export const onboardService = new OnboardService();
