import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import advertismentType from "./advertismentTypes";
import { advertisementService } from "./../../services/advertisment.service";

export const createAdvertisment = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    const response = await advertisementService.CreateAdvertisement({
      ...payLoad,
    });
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["CREATE_ADVERTISMENT"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Get all Advertisment
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllAdvertisments = (page) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    const response = await advertisementService.getAllAdvertisements(page);
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["GET_ALL_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const filterAdvertisments = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: true,
    });

    const response = await advertisementService.filterAdvertisements(
      payLoad,
      page
    );
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["FILTER_ADVERTISMENT"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const searchAllAdvertisments = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_SEARCH"], payLoad: true });
    const response = await advertisementService.searchAdvertisement(payLoad);
    dispatch({ type: advertismentType["LOADING_SEARCH"], payLoad: false });
    dispatch({
      type: advertismentType["SEARCH_ADVERTISMENT"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};

export const getSingleAdvertisment = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    const response = await advertisementService.showAdvertisement(payLoad);
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["SHOW_AN_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const getMyAdvertisments = () => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    const response = await advertisementService.showMyAdvertisements();
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["GET_MY_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const editAdvertisment = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: true,
    });
    const response = await advertisementService.editAdvertisement({
      ...payLoad,
    });
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["EDIT_ADVERTISMENT"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const deleteAdvertisment = (payLoad) => async (dispatch) => {
  try {
    const response = await advertisementService.deleteAdvertisement(payLoad);
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    dispatch({
      type: advertismentType["DELETE_ADVERTISMENT"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const approveAdvertisment = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: true,
    });
    const response = await advertisementService.approveAdvertisement({
      id: payLoad,
    });

    dispatch({
      type: advertismentType["APPROVE_ADVERTISMENT"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const unApproveAdvertisment = (payLoad) => async (dispatch) => {
  try {
    const response = await advertisementService.unapproveAdvertisement({
      id: payLoad,
    });
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    dispatch({
      type: advertismentType["UNAPPROVE_ADVERTISMENT"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
