import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { employeesService } from "./../../services/employees.service";
import employeeType from "./employeeTypes";
import { history } from "../../helpers/history";

/**
 * Approve an employee
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const approveEmployee = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService.approveEmployee({ id: payLoad });
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });

    errorHandler(error, true);
  }
};

/**
 * Approve an employee
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const unApproveEmployee = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService.unApproveEmployee({
      id: payLoad,
    });

    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    dispatch({ type: employeeType["UNAPPROVE_EMPLOYEE"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });

    errorHandler(error, true);
  }
};

/**
 * Terminate employee
 * @param {object} payLoad {id:"6d380968-233e-4f38-b85c-0040ee94bdd4"}
 * @returns
 */
export const terminateEmployee = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService.terminateEmployee(payLoad);
    history("/admin/ntachi/employees");
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    dispatch({ type: employeeType["TERMINATE_EMPLOYEE"], payLoad: payLoad });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Get all employees
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllEmployees = (page) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService.getAllEmployees(page);
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    dispatch({ type: employeeType["GET_ALL_EMPLOYEES"], payLoad: response });
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter employees
 * 
 * @param {object} payLoad {
  employee_email:"bluehills@gmail.com",
  employee_name:"blue hills",
  employee_phone:"07034738829",
  employee_address:"12 victoria island lane",
  employee_city:"lagos",
  employee_state:"Lagos",
  employee_country:"Nigeria",
  }
 * @returns {void}
 */
export const filterEmployees = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["FILTERING"], payLoad: true });
    const response = await employeesService.filterEmployee(payLoad, page);
    dispatch({ type: employeeType["FILTERING"], payLoad: false });
    dispatch({ type: employeeType["FILTER_EMPLOYEES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: employeeType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const filterEmployeesNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["FILTERING"], payLoad: true });
    const response = await employeesService.filterEmployee(payLoad);
    dispatch({ type: employeeType["FILTERING"], payLoad: false });
    dispatch({
      type: employeeType["FILTER_EMPLOYEES_NOPAGE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: employeeType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Search all employees
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const searchAllEmployees = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_SEARCH"], payLoad: true });
    const response = await employeesService.searchEmployee(payLoad);
    dispatch({ type: employeeType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: employeeType["SEARCH_EMPLOYEES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: employeeType["LOADING_SEARCH"], payLoad: false });
  }
};
/**
 * Show employee
 * @param {object} payLoad {id:"eb2483aa-2c6a-407d-92af-238778668605"}
 * @returns
 */
export const getSingleEmployee = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService.showAnEmployee(payLoad);
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    dispatch({ type: employeeType["SHOW_AN_EMPLOYEE"], payLoad: response });
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Show employee location
 * @param {object} payLoad {id:"eb2483aa-2c6a-407d-92af-238778668605", company_id:"bc4f908e-1e4f-4493-88c7-c1c00e756d86"}
 * @returns
 */
export const getEmployeeLocation = () => async (dispatch) => {
  try {
    const response = await employeesService.showEmployeeLocation();

    dispatch({
      type: employeeType["SHOW_MY_EMPLOYEE_LOCATION"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Show employee
 * @param {object}
 * @returns
 */
export const getMyEmployee = () => async (dispatch) => {
  try {
    const response = await employeesService.getMyEmployee();

    dispatch({ type: employeeType["GET_MY_EMPLOYEE"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Edit employee
 * @param {object} payLoad{
     id:"eb2483aa-2c6a-407d-92af-238778668605"
    employee_email:"bluehills@yandex.com",
    employee_name:"bluehills",
    employee_phone:"07034738829",
    employee_gender:"female",
    }
    * @returns {void}
 */

export const editEmployee =
  (payLoad, callback = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
      const response = await employeesService.editEmployee({
        ...payLoad,
      });
      dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
      dispatch({ type: employeeType["EDIT_EMPLOYEE"], payLoad: payLoad });
      callback();
      successHandler(response, true);
    } catch (error) {
      dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
      callback();
      errorHandler(error, true);
    }
  };

/**
 * Delete employee
 * @param {object} payLoad {id:"6d380968-233e-4f38-b85c-0040ee94bdd4"}
 * @returns
 */
export const deleteEmployee = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService.deleteEmployee(payLoad);
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    dispatch({ type: employeeType["DELETE_EMPLOYEE"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Set employee active
 * @param {object} payLoad {status:active}
 * @returns
 */

export const setEmployeeActive = () => async (dispatch) => {
  try {
    const response = await employeesService.setEmployeeActive();

    dispatch({ type: employeeType["SET_EMPLOYEE_ACTIVE"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Set employee Inactive
 * @param {object} payLoad {status:inactive}
 * @returns
 */

export const setEmployeeInactive = () => async (dispatch) => {
  try {
    const response = await employeesService.setEmployeeInactive();

    dispatch({
      type: employeeType["SET_EMPLOYEE_INACTIVE"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Test employee model
 * @param {void}
 * @returns {void}
 */
export const testEmployeeModel = () => async (dispatch) => {
  try {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: true });
    const response = await employeesService;

    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    dispatch({ type: employeeType["TEST_EMPLOYEE_MODEL"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: employeeType["LOADING_EMPLOYEES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};
