import axios from "../plugins/axios.plugin";

class StatisticService {
  baseServicePath = "/api/stats";

  getBusinessStatistics(payload) {
    return axios.post(`${this.baseServicePath}/business`, payload);
  }

  getUserStatistics(payload) {
    return axios.post(`${this.baseServicePath}/user`, payload);
  }

  getGeneralStatistics(payload) {
    return axios.post(`${this.baseServicePath}/general`, payload);
  }

  getStoreStatistics(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  getRestaurantStatistics(payload) {
    return axios.post(`${this.baseServicePath}/restaurant`, payload);
  }
}

export const statisticService = new StatisticService();
