import storeOrderType from "./storeOrderTypes";

const initialState = {
  isLoading: false,
  updating: false,
  creating: false,
  searching: false,
  filtering: false,
  shipping: false,
  downloading: false,
  allStoreOrders: {},
  allStoreOrdersNoPage: {},
  search: [],
  store: {},
  singleStoreOrder: {},
  filterStores: {},
  myStoreOrders: {},
  testModel: {},
};

export default function storeOrderReducer(state = initialState, action) {
  switch (action.type) {
    case storeOrderType.LOADING_STORE_ORDER:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case storeOrderType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case storeOrderType.CREATING:
      return {
        ...state,
        creating: action.payLoad,
      };
    case storeOrderType.SHIPPING:
      return {
        ...state,
        shipping: action.payLoad,
      };
    case storeOrderType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };
    case storeOrderType.GET_ALL_STORE_ORDERS:
      return {
        ...state,
        allStoreOrders: action.payLoad?.data,
      };
    case storeOrderType.GET_ALL_STORE_ORDERS_NOPAGE:
      return {
        ...state,
        allStoreOrdersNoPage: action.payLoad?.data,
      };
    case storeOrderType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };
    case storeOrderType.EDIT_STORE_ORDER:
      return {
        ...state,
        editStores: action.payLoad?.data,
      };
    case storeOrderType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case storeOrderType.FILTER_STORE_ORDER:
      return {
        ...state,
        allStoreOrders: action.payLoad?.data,
      };
    case storeOrderType.GET_MY_STORE_ORDERS:
      return {
        ...state,
        myStoreOrders: action.payLoad?.data,
      };
    case storeOrderType.DELETE_STORE_ORDER:
      return {
        ...state,
        deleteStoreOrder: action.payLoad?.data,
      };
    case storeOrderType.CREATE_STORE_ORDER:
      return {
        ...state,
        storeOrder: action.payLoad.data.data,
      };
    case storeOrderType.SHOW_A_STORE_ORDER:
      return {
        ...state,
        singleStoreOrder: action.payLoad?.data,
      };
    case storeOrderType.SEARCH_STORE_ORDERS:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case storeOrderType.MARK_STORE_ORDER_DELIVERED:
      return {
        ...state,
        delivered: action.payLoad?.data,
      };

    case storeOrderType.MARK_STORE_ORDER_CLOSED:
      return {
        ...state,
        closed: action.payLoad?.data,
      };

    default:
      return state;
  }
}
