import axios from "../plugins/axios.plugin";

class InviteService {
  baseServicePath = "/api/invite";

  storeInvite(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  getAllInvites() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }
  searchInvites(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterInvites(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }
  deleteInvite(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  editInvite(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  showInvite(payload) {
    return axios.post(
      `${this.baseServicePath}/show?id=${payload}&properties=1`,
      payload
    );
  }
  showMyInvites() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }
  RefuseInvite(payload) {
    return axios.post(`${this.baseServicePath}/refuse`, payload);
  }
}

export const inviteService = new InviteService();
