// Application types

const ATTRIBUTE = {
  LOADING_ATTRIBUTE: "LOADING_ATTRIBUTE",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
  ALL_ATTRIBUTES: "ALL_ATTRIBUTES",
  SET_ATTRIBUTE: "SET_ATTRIBUTE",
  EDIT_ATTRIBUTE: "EDIT_ATTRIBUTE",
  DELETE_ATTRIBUTE: "DELETE_ATTRIBUTE",
  SINGLE_ATTRIBUTE: "SINGLE_ATTRIBUTE",
  ARRAY_ATTRIBUTES: "ARRAY_ATTRIBUTES",
  SEARCH_ATTRIBUTE: "SEARCH_ATTRIBUTE",
  FILTER_ATTRIBUTE: "FILTER_ATTRIBUTE",
};

export default ATTRIBUTE;
