import React from "react";
import { ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/solid";
import Left from "../../assets/images/icons/left.svg";
import Right from "../../assets/images/icons/right.svg";

export function NavButton({
  navigationPrevRef,
  navigationNextRef,
  handleNext,
  handlePrev,
}) {
  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <div
        // ref={navigationPrevRef}
        onClick={handlePrev}
        className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-primary-500 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <span className="sr-only">Previous</span>
        <ChevronLeftIcon className="h-5 w-5 text-primary" aria-hidden="true" />
      </div>
      <div
        // ref={navigationNextRef}
        onClick={handleNext}
        className="-ml-px relative inline-flex items-center px-2 py-2 rounded-r-md border border-primary-500 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-10 focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500"
      >
        <span className="sr-only">Next</span>
        <ChevronRightIcon className="h-5 w-5 text-primary" aria-hidden="true" />
      </div>
    </span>
  );
}
export function NavBtn2({
  navigationPrevRef,
  navigationNextRef,
  handleNext,
  handlePrev,
}) {
  return (
    <div>
      <div
        // ref={navigationPrevRef}
        onClick={handlePrev}
        className="absolute z-10 top-24 sm:top-44 -left-3 sm:left-10"
      >
        <span className="sr-only">Previous</span>
        <img src={Left} alt="" className=" cursor-pointer" />
      </div>
      <div
        // ref={navigationNextRef}
        onClick={handleNext}
        className="absolute z-10 top-24 sm:top-44 -right-3 sm:right-10"
      >
        <span className="sr-only">Next</span>
        <img src={Right} alt="" className=" cursor-pointer " />
      </div>
    </div>
  );
}
