import React from "react";

export default function StoreStats({ userStats, submitted }) {
  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return (
    <div>
      <ul className="mt-6">
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Total Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.total_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Unshipped Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.unshipped_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Shipped Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.shipped_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Closed Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.closed_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Delivered Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.delivered_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Third Party Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.third_party_orders : 0}
          </span>
        </li>
        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Paid Orders</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            {submitted ? userStats?.stats?.storeOrders?.order_paid : 0}
          </span>
        </li>

        <li className="flex px-3 py-4 mt-1 justify-between border-b">
          <span className="text-xs text-[#344B67]">Paid Orders Total</span>
          <span className="text-sm text-[#4D4D4D] font-bold">
            NGN{" "}
            {submitted
              ? numberWithCommas(
                  Number(
                    userStats?.stats?.storeOrders?.order_paid_amount.toFixed(2)
                  )
                )
              : 0}
          </span>
        </li>
      </ul>
    </div>
  );
}
