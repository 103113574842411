import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { history } from "../../../helpers/history";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import { resetPasswordAction } from "../../../redux/auth/authActions";

export default function ResetPasswordForm() {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const token_name = new URLSearchParams(search).get("token");
  const { isLoading } = useSelector((state) => state.auth);

  const [input, setInput] = useState({
    email: "",
    token: token_name,
    new_password: "",
    new_password_confirmation: "",
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(resetPasswordAction(input));
      if (response) {
        history("/user/home");
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="mt-4">
        <Input.Label
          title={"Email Address"}
          type="email"
          name="email"
          id="email"
          className="mt-3 h-12 py-4 rounded-lg px-2 sm:text-md w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your email address"
          onChange={handleChange}
        />
      </div>

      <div className="mt-4">
        <Input.Label
          title={"Token"}
          type="text"
          name="token"
          id="token"
          defaultValue={token_name}
          disabled
          className="mt-3 h-12 py-4 rounded-lg px-2 sm:text-md w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your token"
        />
      </div>

      <div className="mt-4">
        <Input.Password
          title={"New Password"}
          name="new_password"
          id="new_password"
          htmlFor="new_password"
          className="mt-3 h-12 py-4 rounded-lg px-2 sm:text-md w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your new password"
          onChange={handleChange}
        />
      </div>
      <div className="mt-4">
        <Input.Password
          title={"New Password Confirmation"}
          name="new_password_confirmation"
          id="new_password_confirmation"
          htmlFor="new_password_confirmation"
          className="mt-3 h-12 py-4 rounded-lg px-2 sm:text-md w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter new password confirmation"
          onChange={handleChange}
        />
      </div>

      <div className="mt-1 mb-6">
        <button
          disabled={isLoading}
          type="submit"
          className=" mt-5 w-full inline-flex justify-center  h-14  rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-bold text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
        >
          {isLoading ? <Beat color={"#ffffff"} /> : "Reset"}
        </button>
      </div>
    </form>
  );
}
