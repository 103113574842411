import axios from "../plugins/axios.plugin";

class ProfileService {
  baseServicePath = "/api/profile";

  getMyProfile() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }

  editMyProfile(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
}

export const profileService = new ProfileService();
