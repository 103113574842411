import attributeType from "./attributeTypes";

const initialState = {
  isLoading: false,
  all_attributes: { data: [] },
  filtering: false,
  edit: {},
  store: {},
  delete: {},
  attribute: {},
  arrayAttributes: [],
};

export default function attributeReducer(state = initialState, action) {
  switch (action.type) {
    case attributeType.LOADING_ATTRIBUTE:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case attributeType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case attributeType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case attributeType.SEARCH_ATTRIBUTE:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case attributeType.FILTER_ATTRIBUTE:
      return {
        ...state,
        allAttributes: action.payLoad?.data,
      };
    case attributeType.SET_ATTRIBUTE:
      return {
        ...state,
        store: action.payLoad.data.data,
      };
    case attributeType.ALL_ATTRIBUTES:
      return {
        ...state,
        allAttributes: action.payLoad?.data,
      };

    case attributeType.SINGLE_ATTRIBUTE:
      return {
        ...state,
        attribute: action.payLoad?.data,
      };

    case attributeType.ARRAY_ATTRIBUTES:
      return {
        ...state,
        arrayAttributes: [...state.arrayAttributes, action.payLoad],
      };

    case attributeType.EDIT_ATTRIBUTE:
      return {
        ...state,
        edit: action.payLoad.data,
      };

    case attributeType.DELETE_ATTRIBUTE:
      return {
        ...state,
        delete: action.payLoad.data,
      };

    default:
      return state;
  }
}
