// Application types

const RESTAURANT_ORDER = {
  LOADING_AUTH: "LOADING_AUTH",
  LOADING_SEARCH: "LOADING_SEARCH",
  UPDATING: "UPDATING",
  APPROVING: "APPROVING",
  FILTERING: "FILTERING",
  MARKING: "MARKING",
  SHIPPING: "SHIPPING",
  DOWNLOADING: "DOWNLOADING",
  ALL_RESTAURANT_ORDERS: "ALL_RESTAURANT_ORDERS",
  ALL_RESTAURANTS_NO_PAGINATION: "ALL_RESTAURANTS_NO_PAGINATION",
  SINGLE_RESTAURANT_ORDER: "SINGLE_RESTAURANT_ORDER",
  SEARCH_RESTAURANT_ORDERS: "SEARCH_RESTAURANT_ORDERS",
  MY_RESTAURANT_ORDERS: "MY_RESTAURANT_ORDERS",
  FILTER_RESTAURANT_ORDERS: "FILTER_RESTAURANT_ORDERS",
  CLOSE_RESTAURANT_ORDERS: "CLOSE_RESTAURANT_ORDERS",
  DELIVER_RESTAURANT_ORDERS: "DELIVER_RESTAURANT_ORDERS",
};

export default RESTAURANT_ORDER;
