import axios from "../plugins/axios.plugin";

class HumanVerificationService {
  baseServicePath = "/api/verification";

  getAllHumanVerification() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }

  showAHumanVerification(user_id) {
    return axios.get(
      `${this.baseServicePath}/show?user_id=${user_id}&properties=1`
    );
  }

  showMyHumanVerification() {
    return axios.get(`${this.baseServicePath}/me`);
  }

  editHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  filterHumanVerification(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }

  storeHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  searchHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  verifyHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/verify`, payload);
  }

  rejectHumanVerification(payload) {
    return axios.post(`${this.baseServicePath}/reject`, payload);
  }
}

export const humanVerificationService = new HumanVerificationService();
