import React from "react";
import { Fragment, useState } from "react";
import { useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";
import CLOSE from "../../assets/images/icons/close.svg";
import Button from "../buttons/buttons";
import Input from "../inputs/inputs";
import { DeleteAUsersAction } from "../../redux/users/userActions";
import { logoutUserLocallyAction } from "../../plugins/axios.plugin";
import { getUserFromCookies } from "../../utils/Auth";

export function DeleteAccountModal(props) {
  const dispatch = useDispatch();
  const user = getUserFromCookies();

  const [disable, setDisable] = useState(true);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value === user?.email) {
      setDisable(false);
    } else {
      setDisable(true);
    }
  };
  const handleSubmit = async () => {
    let response = await dispatch(DeleteAUsersAction({ user_id: user?.id }));
    if (response.status === "success") {
      logoutUserLocallyAction();
    }
  };
  return (
    <>
      <Transition appear show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full sm:max-w-[612px] bg-white rounded-md py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div>
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3  "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="sm:flex justify-center">
                      <div className="text-center sm:text-center mt-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-medium text-black"
                        >
                          Delete Account
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  <div className=" border-b-2 mt-4"></div>
                  <div className="mt-5 sm:mt-7 sm:px-8 p-4">
                    <div className="flex gap-3 items-start px-3 py-3 border border-[#FFC34F] rounded-md mb-6">
                      <InformationCircleIcon className="h-5 w-5 text-[#FFC34F]" />
                      <p className="font-medium text-[#FFC34F]">
                        You can’t undo this action, your data will be removed
                        permanently.
                      </p>
                    </div>
                    <p className="text-center mb-3">
                      Your user account {user?.email}, any existing businesses
                      and all business employees will be deleted permanently.
                    </p>
                    <p className="text-center mb-9">
                      If you have a business all your personal data, business
                      data, employees data, and saved products will be deleted
                      permanently.
                    </p>

                    <Input.Label
                      title={"Enter Your Email To Confirm"}
                      placeholder={"Enter current email"}
                      className="mb-8"
                      onChange={handleChange}
                    />

                    <Button.Secondary
                      title={"Cancel"}
                      className="w-full mb-4 py-3"
                    />
                    <Button.Primary
                      type={"button"}
                      title={"Delete"}
                      disabled={disable}
                      onClick={handleSubmit}
                      className="w-full mb-4 py-3"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
