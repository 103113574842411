import axios from "../plugins/axios.plugin";

class PublicPackageService {
  baseServicePath = "/api/public/package";

  getAllPackages(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAPackage(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  filterPackage(payLoad) {
    return axios.post(`${this.baseServicePath}/filter/index`, payLoad);
  }

  filterPackageWithPage(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?properties=1&page=${page}`,
      payLoad
    );
  }
}

export const publicPackageService = new PublicPackageService();
