import axios from "../plugins/axios.plugin";

class ReferralService {
  baseServicePath = "/api/referral";

  getAllReferrals(page) {
    return axios.get(`${this.baseServicePath}/index?page=${page}&properties=1`);
  }

  getAllReferralsNoPagination(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}&properties=1`,
      payload
    );
  }

  getMyReferrals() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }

  searchReferrals(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  filterReferrals(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}&properties=1`,
      payload
    );
  }
}
export const referralService = new ReferralService();
