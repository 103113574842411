import axios from "../plugins/axios.plugin";

class PackageService {
  baseServicePath = "/api/package";

  getAllPackages() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllPackagesNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getAPackage(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  storePackage(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }

  filterPackage(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }

  approvePackageByMgt(id) {
    return axios.post(`${this.baseServicePath}/approve/management`, id);
  }

  unapprovePackageByMgt(id) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, id);
  }

  searchPackage(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  updatePackage(payLoad) {
    return axios.post(`${this.baseServicePath}/update`, payLoad);
  }
}

export const packageService = new PackageService();
