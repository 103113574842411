import React, { useCallback } from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavBtn2 } from "../buttons/NavButton";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import "swiper/css/autoplay";

SwiperCore.use([Navigation, Autoplay]);

export const HomeNav = ({ advertRef }) => {
  const handlePrev = useCallback(() => {
    if (!advertRef?.current) return;
    advertRef?.current.swiper.slidePrev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = useCallback(() => {
    if (!advertRef?.current) return;
    advertRef?.current.swiper.slideNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NavBtn2 handleNext={handleNext} handlePrev={handlePrev} />;
};

export default function HomeSlider({ children, advertRef }) {

  return (
    <div>
      {Autoplay && (
        <Swiper
          modules={[Navigation, Autoplay]}
          ref={advertRef}
          spaceBetween={20}
          slidesPerView={2}
          autoplay={{ delay: 3000 }}
          breakpoints={{
            0: {
              slidesPerView: 1,
            },
            500: {
              slidesPerView: 2,
            },
            800: {
              slidesPerView: 2,
            },
            1000: {
              items: 2,
            },
          }}
        >
          {children?.map((child, index) => (
            <SwiperSlide key={index}>{child}</SwiperSlide>
          ))}
        </Swiper>
      )}
    </div>
  );
}
