import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
// docsImages
import { XIcon } from "@heroicons/react/outline";
import { Beat } from "../../../plugins/spinners.plugin";
import { cleanInput } from "../../../helpers/cleanInput";
import {
  editHumanVerificationAction,
  showMyHumanVerification,
} from "../../../redux/humanVerification/humanVerificationActions";

export default function EditKYCVerification() {
  const dispatch = useDispatch();
  const hiddenDocsInput = useRef(null);
  const hiddenProofInput = useRef(null);

  // const [profileData, setprofileData] = useState({});
  const [data, setData] = useState({});
  const [docsImages, setDocsImages] = useState();
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [livePhotos, setLivePhotos] = useState([]);
  const [photos64, setPhotos64] = useState([]);
  const [photosUrl, setPhotosUrl] = useState([]);
  const [submitted, setSubmitted] = useState(false);

  const { updating, userVerification } = useSelector(
    (state) => state.humanVerification
  );
  console.log("userVerification", userVerification);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    dispatch(showMyHumanVerification());
  }, [dispatch]);

  //handling removing and adding images to existing images
  useEffect(() => {
    if (userVerification?.documents?.length > 0) {
      setData({
        ...data,
        user_id: userVerification?.user_id,
        document_type: userVerification?.document_type,
        document_number: userVerification?.document_number,
        url_docs: userVerification?.documents,
        url_photos: userVerification?.pictures,
      });
    }
    // eslint-disable-next-line
  }, [userVerification?.documents, userVerification?.pictures]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  function onImageChange(e) {
    setDocsImages([...e.target.files]);
  }

  const handleDocsClick = (event) => {
    event.preventDefault();
    hiddenDocsInput.current.click();
  };

  const handleProofClick = (event) => {
    event.preventDefault();
    hiddenProofInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = docsImages;
    newArr.splice(index, 1);
    setDocsImages([...newArr]);
  };

  const removeUrlDocs = (index) => {
    let newArr = [...data?.url_docs];
    newArr.splice(index, 1);
    setData({ ...data, url_docs: newArr });
  };

  useEffect(() => {
    if (docsImages) {
      const newImageUrls = [];
      const newImage64 = [];
      docsImages?.forEach((image) => {
        newImageUrls.push(URL.createObjectURL(image));
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          newImage64.push(reader.result);
        };
      });
      setImageUrls(newImageUrls);
      setImage64(newImage64);
    }
  }, [docsImages]);

  const uploadProofOfLife = (e) => {
    setLivePhotos([...e.target.files]);
  };

  const removeUrlPhotos = (index) => {
    let newArr = [...data?.url_photos];
    newArr.splice(index, 1);
    setData({ ...data, url_photos: newArr });
  };

  useEffect(() => {
    if (livePhotos.length < 1) return;
    if (livePhotos.length > 0) {
      const newImageUrls = [];
      const newImage64 = [];
      livePhotos?.forEach((image) => {
        newImageUrls.push(URL.createObjectURL(image));
        let reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = () => {
          newImage64.push(reader.result);
        };
      });
      setPhotosUrl(newImageUrls);
      setPhotos64(newImage64);
    }
    // eslint-disable-next-line
  }, [livePhotos]);

  const handleSubmit = async () => {
    setSubmitted(true);
    let newInput = {
      ...data,
      base64_docs: image64 ? image64 : [],
      base64_photos: photos64 ? photos64 : [],
    };

    cleanInput(newInput);
    try {
      dispatch(editHumanVerificationAction(newInput));
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div>
      <form className="sm:px-10">
        <div className="sm:grid sm:grid-cols-1 gap-1">
          {/* grid column one begins */}
          <div className="relative">
            <div className="absolute right-0 text-sm">
              {userVerification?.status === "approved" && (
                <span className="px-2 py-1 rounded-md text-[#00D220] bg-[#D1FED8]">
                  Approved
                </span>
              )}
              {userVerification?.status === "pending" && (
                <span className="px-2 py-1 rounded-md text-[#FFA927] bg-[#FFE2B6]">
                  Pending
                </span>
              )}
              {userVerification?.status === "rejected" && (
                <span className="px-2 py-1 rounded-md text-primary bg-[#ce260026]">
                  Rejected
                </span>
              )}
            </div>
            <Input.Select
              title={"Select Identity Card"}
              name="document_type"
              id="document_type"
              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
              htmlFor="document_type"
              disabled={
                userVerification?.status === "approved" ||
                userVerification?.status === "pending"
              }
              defaultValue={userVerification?.document_type}
              onChange={handleChange}
            >
              <option>Select card type</option>
              <option>Drivers License</option>
              <option>International Passport</option>
              <option>NIN</option>
            </Input.Select>
            {submitted && !data?.document_type && (
              <div className="text-primary text-xs ">
                Document Type is required
              </div>
            )}
          </div>

          <div>
            <label
              htmlFor="document_number"
              className="block text-sm font-medium"
            >
              Enter ID Card Number
            </label>
            <div className="mt-2">
              <input
                type="number"
                name="document_number"
                id="document_number"
                className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                placeholder="Enter ID card number"
                defaultValue={userVerification?.document_number}
                disabled={
                  userVerification?.status === "approved" ||
                  userVerification?.status === "pending"
                }
                onChange={(e) => handleChange(e)}
              />
            </div>
            {submitted && !data?.document_number && (
              <div className="text-primary text-xs ">
                ID Card number is required
              </div>
            )}
          </div>

          <div>
            <label htmlFor="first_name" className="block text-sm font-medium">
              Upload Identity Card (Accepts .jpg, .png and .jpeg image formats)
            </label>
            <div className="mt-2">
              <button
                className="w-full border border-[#E4E4F3] bg-[#FAFAFA] p-3 text-center text-[#9CA3AF]"
                onClick={handleDocsClick}
                disabled={
                  userVerification?.status === "approved" ||
                  userVerification?.status === "pending"
                }
              >
                + Upload Image
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={onImageChange}
                ref={hiddenDocsInput}
              />
              {submitted &&
                (image64?.length < 1 || data?.url_docs?.length < 1) && (
                  <div className="text-primary text-xs ">
                    Identity Card image is required
                  </div>
                )}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-4 gap-8 mt-4 mb-4">
              {data?.url_docs?.length > 0 &&
                data?.url_docs?.map((image, index) => (
                  <figure className="w-full h-[120px] relative" key={index}>
                    <img
                      src={image}
                      className="object-cover w-full h-full"
                      alt="document"
                    />
                    <button
                      type="button"
                      className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                      onClick={() => removeUrlDocs(index)}
                    >
                      <XIcon className="h-5 w-5 text-primary mx-auto" />
                    </button>
                  </figure>
                ))}

              {imageUrls?.length > 0 &&
                imageUrls?.map((image, index) => (
                  <figure className="w-full h-[120px] relative" key={index}>
                    <img
                      src={image}
                      className="object-cover w-full h-full"
                      alt="document"
                    />
                    <button
                      type="button"
                      className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                      onClick={() => removeFile(index)}
                    >
                      <XIcon className="h-5 w-5 text-primary mx-auto" />
                    </button>
                  </figure>
                ))}
            </div>
          </div>
          {/* grid ends */}

          <div>
            <label htmlFor="first_name" className="block text-sm font-medium">
              Upload Proof Of Life
            </label>
            <div className="mt-2">
              <button
                className="w-full border border-[#E4E4F3] bg-[#FAFAFA] p-3 text-center text-[#9CA3AF]"
                onClick={handleProofClick}
                disabled={
                  userVerification?.status === "approved" ||
                  userVerification?.status === "pending"
                }
              >
                + Upload Image
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={uploadProofOfLife}
                ref={hiddenProofInput}
              />
              {submitted &&
                (livePhotos?.length < 1 || data?.url_photos?.length < 1) && (
                  <div className="text-primary text-xs ">
                    Proof of life is required
                  </div>
                )}
            </div>
            <div className="mt-4 ">
              {photosUrl?.length > 0 ? (
                <div className="grid grid-cols-1 gap-8">
                  {photosUrl.map((url, index) => (
                    <figure className="w-full h-[250px] relative" key={index}>
                      <img
                        src={url}
                        className="object-cover w-full h-[250px]"
                        alt="banner"
                      />
                    </figure>
                  ))}
                </div>
              ) : (
                data?.url_photos?.length > 0 && (
                  <div className="grid grid-cols-1 gap-8">
                    {data?.url_photos.map((banner, index) => (
                      <figure className="w-full h-[250px] relative" key={index}>
                        <img
                          src={banner}
                          className="object-cover w-full h-[250px]"
                          alt="banner"
                        />
                        <button
                          type="button"
                          className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                          onClick={() => removeUrlPhotos(index)}
                        >
                          <XIcon className="h-5 w-5 text-primary mx-auto" />
                        </button>
                      </figure>
                    ))}
                  </div>
                )
              )}
            </div>
          </div>
        </div>

        <div className="my-10 flex flex-col items-center justify-center">
          {userVerification?.status === "approved" ||
          userVerification?.status === "pending" ? (
            ""
          ) : (
            <Button.Primary
              type="button"
              disabled={updating}
              title={updating ? <Beat color="#ffffff" /> : "Edit KYC"}
              className={"sm:block rounded-md mb-6 sm:mb-0 w-full sm:w-[333px]"}
              onClick={(e) => handleSubmit(e)}
            />
          )}
        </div>
      </form>
    </div>
  );
}
