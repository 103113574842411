import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cleanInput } from "../../../../../helpers/cleanInput";
import { Beat } from "../../../../../plugins/spinners.plugin";
import Button from "../../../../../components/buttons/buttons";
import Input from "../../../../../components/inputs/inputs";
import HourDays from "./HourDays";
import {
  upDateRestaurantAction,
  getARestaurantAction,
} from "../../../../../redux/restaurant/restaurantActions";
import { getAllTimeZone } from "../../../../../redux/timezone/timezoneActions";
import { getOutletFromCookies } from "../../../../../utils/Auth";

export default function OpeningHours() {
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const { restaurant, updating } = useSelector((state) => state.restaurant);
  const { allTimezones, isLoading } = useSelector((state) => state.timezone);

  const [timezone, setTimeZone] = useState("");
  const [restaurant_operating_time, set_restaurant_operating_time] = useState({
    monday: {
      open: "00:00",
      close: "00:00",
    },
    tuesday: {
      open: "00:00",
      close: "00:00",
    },
    wednesday: {
      open: "00:00",
      close: "00:00",
    },
    thursday: {
      open: "00:00",
      close: "00:00",
    },
    friday: {
      open: "00:00",
      close: "00:00",
    },
    saturday: {
      open: "00:00",
      close: "00:00",
    },
    sunday: {
      open: "00:00",
      close: "00:00",
    },
  });

  useEffect(() => {
    if (restaurant?.restaurant_operating_time) {
      set_restaurant_operating_time(restaurant?.restaurant_operating_time);
    }
  }, [restaurant]);

  useEffect(() => {
    dispatch(getAllTimeZone("time_zones"));
    // eslint-disable-next-line
  }, []);

  const handleSubmit = async () => {
    cleanInput(restaurant_operating_time);
    let updateData = {};
    if (timezone !== "") {
      updateData = {
        id: outlet?.id,
        business_id: outlet?.business_id,
        restaurant_operating_time,
        restaurant_time_zone: timezone,
      };
    } else {
      updateData = {
        id: outlet?.id,
        business_id: outlet?.business_id,
        restaurant_operating_time,
      };
    }

    try {
      const response = await dispatch(upDateRestaurantAction(updateData));
      if (response?.status === "success") {
        dispatch(getARestaurantAction(outlet?.id));
      }
    } catch (error) {}
  };

  return (
    <div data-test="openingHours-page">
      <div className="p-5 w-full bg-white rounded-sm border shadow-xl sm:p-8 mx-auto">
        <div className="mb-12">
          <div className="flex items-center justify-between">
            <h3 className="text-2xl text-[#2B3674] font-bold">Opening Hours</h3>
            <Button.Primary
              type={"button"}
              disabled={updating}
              title={updating ? <Beat color={"#ffffff"} /> : "Set Schedule"}
              className={"w-[153px] hidden sm:block"}
              onClick={handleSubmit}
            />
          </div>
          <p className="text-[#A3AED0]">
            Set the standard hours of operation for this restaurant.
          </p>
        </div>

        <div className="flex w-full sm:w-auto items-center gap-10 mb-8">
          <label className="block text-2xl min-w-[160px] font-medium text-[#2B3674] capitalize">
            Time Zone
          </label>

          <div className="w-full">
            <Input.Select
              title={""}
              name="restaurant_time_zone"
              defaultValue={restaurant?.restaurant_time_zone}
              className="w-full"
              onChange={(e) => setTimeZone(e.target.value)}
            >
              <option>
                {restaurant?.restaurant_time_zone ?? "Select Timezone"}
              </option>
              {isLoading ? (
                <option>Loading...</option>
              ) : (
                allTimezones &&
                Object.keys(allTimezones).map((key, index) => (
                  <option key={index} value={allTimezones[key].utc}>
                    {allTimezones[key].iana} ({allTimezones[key].utc})
                  </option>
                ))
              )}
            </Input.Select>
          </div>
        </div>

        <div className="flow-root">
          {Object.keys(restaurant_operating_time).map((day, index) => (
            <HourDays
              key={index}
              day={day}
              restaurant_operating_time={restaurant_operating_time}
              set_restaurant_operating_time={set_restaurant_operating_time}
            />
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-8 pb-8 sm:hidden block">
        <Button.Primary
          type={"button"}
          disabled={updating}
          title={updating ? <Beat color={"#ffffff"} /> : "Set Schedule"}
          className={"w-[153px]"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
