import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import TableFilter from "../../../components/tables/tableFilter";
import Coupon from "../../../assets/images/icons/couponEmpty.svg";
import { Pagination } from "../../../components/pagination/pagination";
import {
  searchCoupons,
  filterCoupons,
} from "../../../redux/coupons/couponAction";
import couponType from "../../../redux/coupons/couponTypes";
import { filterCouponsNoPage } from "./../../../redux/coupons/couponAction";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import FilterCouponDropDown from "../../../components/dropDown/filter/couponFilter";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";

export default function All() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const { allCoupons, filtering, searching, filterNoPage } = useSelector(
    (state) => state.coupon
  );
  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    // start_date: lastMonth,
    end_date: new Date(),
  });

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(filterCoupons(payLoad, page));
    dispatch(filterCouponsNoPage(payLoad));
    // eslint-disable-next-line
  }, [page]);

  function checkExpiry(dateString) {
    //get today's date and check if it is greater than expiry date.
    //if yes, return status as expired, else active
    const specifiedDate = new Date(dateString);
    const today = new Date();
    return today > specifiedDate;
  }

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((coupon, index) => {
        let show = {
          id: index + 1,
          status:
            coupon?.status === "active" ? (
              checkExpiry(coupon?.expiry) ? (
                <span className="text-primary">Expired</span>
              ) : (
                <span className="text-[#00D220]">Active</span>
              )
            ) : (
              <span className="text-primary">Inactive</span>
            ),
          title: coupon?.title,
          slug: coupon?.slug,
          cap: coupon?.min_order_amount_cap,
          created: (
            <span>
              {moment(coupon?.created_at).format("MMM DD, YYYY/ HH:MMA")}
            </span>
          ),
          expires: <span>{moment(coupon?.expiry).format("MMM DD, YYYY")}</span>,
          coupon: coupon,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allCoupons?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCoupons]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((coupon, index) => {
        let show = {
          status:
            coupon?.status === "active"
              ? checkExpiry(coupon?.expiry)
                ? "Expired"
                : "Active"
              : "Inactive",
          title: coupon?.title,
          slug: coupon?.slug,
          cap: coupon?.min_order_amount_cap,
          created: coupon?.created_at,
          expires: coupon?.expiry,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  function goToSinglePage(params) {
    let coupon_id = params?.show?.coupon?.id;
    navigate(`/admin/coupons/${coupon_id}`, {
      state: { coupon: params?.show?.coupon },
    });
  }

  function createCoupon() {
    navigate(`/admin/create-coupon`);
  }

  const downloadCSV = () => {
    if (filterNoPage) {
      const newData = createTableNoPage(filterNoPage);
      writeFileWithXLSX(newData, {
        filename: "All Coupons.xlsx",
        download: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allCoupons?.data);
      setData(dataSet);
      dispatch({
        type: couponType["SEARCH_COUPON"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchCoupons(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Title",
            accessor: "show.title",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Created",
            accessor: "show.created",
          },
          {
            Header: "Slug",
            accessor: "show.slug",
          },
          {
            Header: "Cap",
            accessor: "show.cap",
          },
          {
            Header: "Expires",
            accessor: "show.expires",
          },
        ],
      },
    ],
    []
  );

  return (
    <div data-test="all-coupon">
      <TableFilter
        title={"Coupons"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        results={allCoupons?.to}
        totalResults={allCoupons?.total}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterCouponDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Title, Cap & Status"}
      >
        <Button.Primary title={"+ Create Coupon"} onClick={createCoupon} />
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>
      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={Coupon} alt="coupon" />
            </div>
            <p className="font-bold text-xl my-3">Coupons</p>
            <p className="text-sm text-gray-500">
              You do not have any coupons yet
            </p>
          </div>
        )}

        <Pagination data={allCoupons} route={`admin/coupons/all`} />
      </div>
    </div>
  );
}
