import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import All from "./all";
import Discount from "./discount";
import Souvenir from "./souvenir";
import Expired from "./expired";

const tabs = [
  { name: "All", href: "all" },
  // { name: "Discount", href: "discount" },
  // { name: "Souvenir", href: "souvenir" },
  { name: "Expired", href: "expired" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PromoCode() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "all";
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/promo/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/promo/${tab.href}/1`);
    }
  };
  return (
    <div data-test="coupon-page">
      <div className="sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div>
        {active_tab === "all" && <All />}
        {active_tab === "discount" && <Discount />}
        {active_tab === "souvenir" && <Souvenir />}
        {active_tab === "expired" && <Expired />}
      </div>
    </div>
  );
}
