import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../plugins/spinners.plugin";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { RiCloseLine } from "react-icons/ri";
import {
  createThirdPartyToken,
  getMyThirdPartyToken,
} from "../../../redux/thirdParty/thirdPartyActions";

export default function TokeModal(props) {
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    label: "",
  });
  const { label } = data;
  const { creating } = useSelector((state) => state.thirdParty);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...setData, [name]: value });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (label) {
      try {
        const response = await dispatch(createThirdPartyToken(data));
        if (response?.status === "success") {
          dispatch(getMyThirdPartyToken());
          props.onClose();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };
  return (
    <>
      <Transition appear show={props.show}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[358px] sm:rounded-md rounded-sm sm:w-[450px] p-4 sm:p-6 ">
                  <div>
                    <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className=" "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <RiCloseLine />
                      </button>
                    </div>
                    <div className="">
                      <div className=" text-center sm:text-left ">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-medium text-black "
                        >
                          Create a third party access token
                        </Dialog.Title>
                      </div>
                    </div>
                    <div className=" border-b-2 mt-4"></div>
                    <div className="mt-5 sm:mt-7 text-left">
                      <form>
                        <div>
                          <label
                            htmlFor="label"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Label/Name
                          </label>
                          <div className="mt-2 border-gray-300 focus-within:border-primary">
                            <input
                              type="text"
                              name="label"
                              value={label}
                              onChange={handleChange}
                              className="block w-full border px-2 py-2 rounded-md focus:border-primary focus:ring-0 sm:text-sm"
                              placeholder="Enter token label/name"
                            />
                          </div>
                          {submitted && !label && (
                            <div className="text-xs text-red-500">
                              Label is required
                            </div>
                          )}
                        </div>
                      </form>
                    </div>
                    <div className="flex justify-between mt-14 sm:mt-24">
                      <Button.Secondary
                        title={"Back"}
                        className={"rounded-md px-4"}
                        onClick={props.onClose}
                      />
                      <Button.Primary
                        title={creating ? <Beat /> : "Create Token"}
                        className={"rounded-md px-4"}
                        type="button"
                        onClick={handleSubmit}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
