import React, { useState } from "react";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";

const Collapse = ({
  children,
  defaultOpen = [0, 1, 2],
  open: openFromProps,
  onToggle: onToggleFromProps = () => {},
}) => {
  const isControlled = () => (openFromProps ? true : false);
  const [open, setIsOpen] = useState(defaultOpen);
  const getOpen = () => (isControlled() ? openFromProps : open);
  const isOpen = (index) => {
    return getOpen().includes(index) ? true : false;
  };
  const onToggle = (index) => {
    if (isControlled()) {
      onToggleFromProps(index);
    } else {
      if (getOpen().includes(index)) {
        setIsOpen(getOpen().filter((item) => item !== index));
      } else {
        setIsOpen([...getOpen(), index]);
      }

      onToggleFromProps(index);
    }
  };

  return (
    <>
      {React.Children.map(children, (child, index) => {
        return React.cloneElement(child, {
          isOpen: isOpen(index),
          onToggle: () => onToggle(index),
        });
      })}
    </>
  );
};

Collapse.Question = ({ title, isOpen, answerId, onToggle }) => {
  return (
    <button
      className="w-full"
      aria-expanded={isOpen}
      aria-controls={answerId}
      onClick={onToggle}
    >
      <div className="mb-2 pb-2 border-b border-gray-200 flex flex-nowrap items-center justify-between">
        <div className="text-left">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            {title}
          </h3>
        </div>
        <div className="ml-4 mt-2 flex-shrink-0">
          <span className="rounded-md bg-primary-500 h-8 w-8 flex items-center justify-center">
            {isOpen ? (
              <ChevronDownIcon
                className="h-6 w-6 text-primary"
                aria-hidden="true"
              />
            ) : (
              <ChevronUpIcon
                className="h-6 w-6 text-primary"
                aria-hidden="true"
              />
            )}

            {/* {children(isOpen, onToggle)} */}
          </span>
        </div>
      </div>
    </button>
  );
};
Collapse.Answer = ({ children, id, isOpen }) => {
  return (
    <div className={!isOpen ? "hidden mb-6" : "mt-5 block mb-8"} id={id}>
      {children}
    </div>
  );
};

function QAItem({ children, isOpen, onToggle }) {
  return React.Children.map(children, (child, index) => {
    return React.cloneElement(child, {
      isOpen: isOpen,
      onToggle: onToggle,
    });
  });
}

Collapse.QAItem = QAItem;

export default Collapse;
