import React, { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import EmptyState from "../../../assets/images/emptyState/category-list.jpg";
//tabs
import Restaurant from "./tabs/restaurant";
import Store from "./tabs/store";
import RestaurantPackage from "./tabs/restaurant-package";
import { filterAddressesAction } from "../../../redux/address/addressActions";
import { filterReferralsAction } from "../../../redux/referral/referralActions";

const tabs = [
  { name: "Restaurant Orders", href: "restaurant" },
  { name: "Store Orders", href: "store" },
  { name: "Food Package Orders", href: "food-package" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function UserProfile() {
  const location = useLocation();
  const dispatch = useDispatch();
  const user = location.state.user;
  const [data, setData] = useState([]);
  const [active_tab, setActiveTab] = useState("restaurant");
  const { allAddresses } = useSelector((state) => state.address);
  const { allReferrals, filtering } = useSelector((state) => state.referral);

  let filterReferral = filtering;

  const onImageError = (e) => {
    e.target.src = EmptyState;
  };

  useEffect(() => {
    dispatch(filterAddressesAction({ user_id: user?.id }));
    dispatch(filterReferralsAction({ user_id: user?.id }));
    // eslint-disable-next-line
  }, [user?.id]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((referral, index) => {
        let show = {
          id: index + 1,
          user_id: referral?.user_id,
          count: referral?.referral_count,
          code: referral?.referral_code,
          referrer_code: referral?.referrer_code ?? "No referee",
          created: (
            <span>{moment(referral?.created_at).format("MMM DD, YYYY")}</span>
          ),
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allReferrals?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allReferrals]);

  const toggle = (tab) => {
    if (active_tab !== tab.href) {
      setActiveTab(tab.href);
    }
  };

  const referralColumns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "User ID",
            accessor: "show.user_id",
          },
          {
            Header: "Referral Count",
            accessor: "show.count",
          },
          {
            Header: "Referral Code",
            accessor: "show.code",
          },
          {
            Header: "Referee Code",
            accessor: "show.referrer_code",
          },
          {
            Header: "Created On",
            accessor: "show.created",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="userProfile-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">User Details</h3>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <figure className="min-h-[150px] xl:max-h-[164px] w-auto xl:max-w-[200px] border border-[#E4E4F3] rounded-sm">
            <img
              className="object-cover h-full w-full rounded"
              src={user?.picture ?? EmptyState}
              alt="User"
              onError={onImageError}
            />
          </figure>

          <div className="col-span-4 grid grid-cols-5 gap-8">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
              <p className="text-[#151515] text-sm">{user?.name}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">{user?.email}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone number
              </label>
              <p className="text-[#151515] text-sm">{user?.phone}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Location </label>
              <p className="text-[#151515] text-sm">{user?.profile?.city}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Birthday</label>
              <p className="text-[#151515] text-sm">{user?.birth_date}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Orders</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Coupons</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Referrals</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              <p className="text-[#151515] text-sm">N/A</p>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Delivery Address</h3>
        </div>
        {allAddresses?.data?.length > 0 ? (
          allAddresses?.data?.map((address, index) => (
            <div key={index} className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
                <p className="text-[#151515] text-sm">{address?.address}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Location</label>
                <p className="text-[#151515] text-sm">{address?.city}</p>
              </div>
            </div>
          ))
        ) : (
          <p className="font-medium py-4 text-center">
            No delivery addresses available
          </p>
        )}
      </div>

      <div className="border-b border-gray-200 mt-12">
        <nav
          className="-mb-px flex space-x-8 overflow-x-auto"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => {
                toggle(tab);
              }}
              className={classNames(
                tab.href === active_tab
                  ? "border-primary text-primary font-bold"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.href === active_tab ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="p-3 bg-white rounded-md">
        <div className="mt-1">
          {active_tab === "restaurant" && <Restaurant />}
          {active_tab === "store" && <Store />}
          {active_tab === "food-package" && <RestaurantPackage />}
        </div>
      </div>

      <div className=" border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Referrals</h3>
        </div>
        <div className="bg-white rounded-md py-4">
          {filterReferral ? (
            <TableSkeleton />
          ) : (
            <Table
              columns={referralColumns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={() => {}}
            />
          )}
        </div>
      </div>
    </div>
  );
}
