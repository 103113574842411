// Application types

const SUBSCRIPTION_MERCHANDISE = {
  LOADING_AUTH: "LOADING_AUTH",
  SEARCHING: "SEARCHING",
  FILTERING: "FILTERING",
  DELETING: "DELETING",
  DOWNLOADING: "DOWNLOADING",
  APPROVING_MERCH: "APPROVING_MERCH",
  ALL_MERCHANDISE_SUBSCRIPTIONS: "ALL_MERCHANDISE_SUBSCRIPTIONS",
  SEARCH_MERCHANDISE_SUBSCRIPTIONS: "SEARCH_MERCHANDISE_SUBSCRIPTIONS",
  LOADING_SUBSCRIPTION: "LOADING_SUBSCRIPTION",
  ADD_SUBSCRIPTION_ITEM: "ADD_SUBSCRIPTION_ITEM",
  APPROVE_SUBSCRIPTION_MERCH: "APPROVE_SUBSCRIPTION_MERCH",
  UNAPPROVE_SUBSCRIPTION_MERCH: "UNAPPROVE_SUBSCRIPTION_MERCH",
  FILTER_MERCHANDISE_SUBSCRIPTIONS: "FILTER_MERCHANDISE_SUBSCRIPTIONS",
  SINGLE_SUBSCRIPTION_MERCHANDISE: "SINGLE_SUBSCRIPTION_MERCHANDISE",
  DELETE_SUBSCRIPTION_MERCHANDISE: "DELETE_SUBSCRIPTION_MERCHANDISE",
};

export default SUBSCRIPTION_MERCHANDISE;
