import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { FormWizardSection, FormWizard } from "../../FormWizard";
//components
import FormSection3 from "./components/formSection3";
import FormSection4 from "./components/formSection4";
import FormSection0 from "./components/formSection0";
import FormSection1 from "./components/formSection1";
import FormSection2 from "./components/formSection2";
import FormSection5 from "./components/formSection5";
import FormSection6 from "./components/formSection6";

export function ChooseAccountModal(props) {
  const formWizardRef = React.useRef(null);
  return (
    <div className="SignUp" data-test="chooseAccountModal">
      <Transition appear show={props.show}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <FormWizard ref={formWizardRef}>
                  <FormWizardSection position={0}>
                    <FormSection3 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>

                  <FormWizardSection position={1}>
                    <FormSection0 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>

                  <FormWizardSection position={2}>
                    <FormSection1 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>

                  <FormWizardSection position={3}>
                    <FormSection2 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>

                  <FormWizardSection position={4}>
                    <FormSection4 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>

                  <FormWizardSection position={5}>
                    <FormSection5 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>

                  <FormWizardSection position={6}>
                    <FormSection6 formWizardRef={formWizardRef} props={props} />
                  </FormWizardSection>
                </FormWizard>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
