import axios from "../plugins/axios.plugin";

class RestaurantOrderService {
  baseServicePath = "/api/restaurant/order";

  getAllRestaurantOrders(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAllRestaurantOrdersNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getARestaurantOrder(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  createRestaurantOrder(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  updateRestaurantOrder(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  searchRestaurantOrder(params) {
    return axios.post(`${this.baseServicePath}/search/index`, params);
  }

  getMyRestaurantOrders(page) {
    return axios.get(`${this.baseServicePath}/me?properties=1&page=${page}`);
  }

  filterRestaurantOrder(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }

  markRestaurantOrderClosed(id) {
    return axios.post(`${this.baseServicePath}/mark/closed`, id);
  }

  shipRestaurantOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/ship`, payLoad);
  }

  markRestaurantOrderDelivered(id) {
    return axios.post(`${this.baseServicePath}/mark/delivered`, id);
  }
}

export const restaurantOrderService = new RestaurantOrderService();
