import authType from "./trsansactionTypes";

const initialState = {
  isLoading: false,
  transferring: false,
  searching: false,
  filtering: false,
  downloading: false,
  transferTransaction: false,
  allTransactions: {},
  incompleteTransactions: {},
  allTransactionsNoPagination: {},
  transaction: {},
  search: [],
};

export default function transactionReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.UPDATING:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.TRANSFERRING:
      return {
        ...state,
        transferring: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.ALL_TRANSACTIONS:
      return {
        ...state,
        allTransactions: action.payLoad?.data,
      };

    case authType.ALL_TRANSACTIONS_NO_PAGINATION:
      return {
        ...state,
        allTransactionsNoPagination: action.payLoad?.data,
      };

    case authType.INCOMPLETE_TRANSACTIONS:
      return {
        ...state,
        incompleteTransactions: action.payLoad?.data,
      };

    case authType.SINGLE_TRANSACTION:
      return {
        ...state,
        transaction:
          action.payLoad?.data && action.payLoad?.data?.length > 0
            ? action.payLoad?.data[0]
            : action.payLoad?.data,
      };

    case authType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_TRANSACTION:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case authType.TRANSFER_TRANSACTIONS:
      let approved = {
        ...state.transaction,
        transferTransaction: true,
      };
      return {
        ...state,
        transaction: approved,
      };

    case authType.FILTER_TRANSACTIONS:
      return {
        ...state,
        allTransactions: action.payLoad?.data,
      };
    default:
      return state;
  }
}
