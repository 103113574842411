import axios from "../plugins/axios.plugin";

class StoreOrderService {
  baseServicePath = "/api/store/order";

  createAStoreOrder(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  updateAStoreOrder(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  markAsClosed(payload) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payload);
  }
  markAsDelivered(payload) {
    return axios.post(`${this.baseServicePath}/mark/delivered`, payload);
  }
  getAllStoreOrders(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  getAllStoreOrdersNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
  filterStoreOrders(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  searchStoreOrder(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showAStoreOrder(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  showMyStoreOrders() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  deleteStoreOrder(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  editStoreOrder(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  shipStoreOrder(payload) {
    return axios.post(`${this.baseServicePath}/ship`, payload);
  }
}

export const storeOrderService = new StoreOrderService();
