import axios from "../plugins/axios.plugin";

class StoreService {
  baseServicePath = "/api/store";

  CreateAStore(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  approveStore(payload) {
    return axios.post(`${this.baseServicePath}/approve/business`, payload);
  }
  unApproveStore(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/business`, payload);
  }
  approveStoreByManagement(payload) {
    return axios.post(`${this.baseServicePath}/approve/management`, payload);
  }
  unApproveStoreByManagement(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payload);
  }
  getAllStores(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  getAllStoresNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
  filterStores(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  searchStore(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showAStore(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  showMyStores() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  deleteStore(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  editStore(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
}

export const storeService = new StoreService();
