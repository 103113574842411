import axios from "../plugins/axios.plugin";

class AuthService {
  baseServicePath = "/api/auth";

  emailRegistration(payload) {
    return axios.post(`${this.baseServicePath}/register`, payload);
  }

  emailLogin(payload) {
    return axios.post(`${this.baseServicePath}/login`, payload);
  }

  forgotPassword(payload) {
    return axios.post(`${this.baseServicePath}/forgot`, payload);
  }

  resetPassword(payload) {
    return axios.post(`${this.baseServicePath}/reset`, payload);
  }

  changePassword(payload) {
    return axios.post(`${this.baseServicePath}/change`, payload);
  }
}

export const authService = new AuthService();
