import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/buttons/buttons";
import { useNavigate } from "react-router-dom";
import { Beat } from "../../../../../plugins/spinners.plugin";
import {
  approveRestaurantBySelfAction,
  unapproveRestaurantBySelfAction,
  getARestaurantAction,
} from "../../../../../redux/restaurant/restaurantActions";
import { getOutletFromCookies } from "../../../../../utils/Auth";

export default function RestaurantInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();

  const { restaurant, approving } = useSelector((state) => state.restaurant);

  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  function goToSinglePage() {
    navigate(
      `/admin/${outlet.restaurant_name}/settings/restaurant/${outlet?.id}/edit`,
      {
        state: { restaurant: restaurant },
      }
    );
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }

  const approve = () => {
    dispatch(approveRestaurantBySelfAction({ id: restaurant?.id }));
  };
  const unapprove = () => {
    dispatch(unapproveRestaurantBySelfAction({ id: restaurant?.id }));
  };

  useEffect(() => {
    dispatch(getARestaurantAction(outlet?.id));
    // eslint-disable-next-line
  }, []);

  return (
    <div data-test="restaurantInfo-page">
      <div className="bg-white border w-full px-4 sm:px-6 py-4 mt-8">
        <p>
          Manage your restaurant details, make changes to your restaurant
          details here.
        </p>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 my-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">{restaurant?.restaurant_name}</h3>
          <div className="flex items-center gap-4">
            <Button.Secondary
              title={"Edit Restaurant"}
              onClick={goToSinglePage}
              className="hidden sm:block px-6"
            />
            {restaurant?.business_approved ? (
              <Button.Primary
                disabled={approving}
                title={
                  approving ? (
                    <Beat color={"#ffffff"} />
                  ) : isMobile() ? (
                    "Unpublish"
                  ) : (
                    "Unpublish Restaurant"
                  )
                }
                className={"px-6"}
                onClick={unapprove}
              />
            ) : (
              <Button.Primary
                disabled={approving}
                title={
                  approving ? (
                    <Beat color={"#ffffff"} />
                  ) : isMobile() ? (
                    "Publish"
                  ) : (
                    "Publish Restaurant"
                  )
                }
                className={"px-6"}
                onClick={approve}
              />
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Restaurant ID</label>
            <p className="text-[#151515] text-sm">{restaurant?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm break-words">
              {restaurant?.restaurant_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] capitalize text-sm">
              {restaurant?.restaurant_operating_status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">
              {restaurant?.restaurant_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm">
              {" "}
              {restaurant?.restaurant_city}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm">
              {restaurant.restaurant_city}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">
              {restaurant.restaurant_address}
            </p>
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">Restaurant Images</h3>
          </div>
        </div>

        <div className="mt-8">
          {restaurant?.restaurant_pictures?.length > 0 ? (
            <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
              {restaurant?.restaurant_pictures?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="w-[160px] h-[160px] object-cover"
                    alt="merchandise"
                  />
                </figure>
              ))}
            </div>
          ) : (
            <p className="text-base font-medium ">
              No Images uploaded. Edit restaurant to add Images
            </p>
          )}
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">Restaurant Banner</h3>
          </div>
        </div>

        <div className="mt-8">
          {restaurant?.restaurant_banners?.length > 0 ? (
            <div className="grid grid-cols-1 gap-8">
              {restaurant?.restaurant_banners?.map((image, index) => (
                <figure className="w-auto h-[200px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="merchandise"
                  />
                </figure>
              ))}
            </div>
          ) : (
            <p className="text-base font-medium">
              No banners uploaded. Edit restaurant to add Banner
            </p>
          )}
        </div>
      </div>
      <div className="mx-auto flex justify-center my-16">
        <Button.Primary
          title={"Edit Restaurant"}
          className="sm:hidden block"
          onClick={goToSinglePage}
        />
      </div>
    </div>
  );
}
