import productCategoryType from "./productCategoryTypes";

const initialState = {
  allCategories: [],
  loading: false,
  searching: false,
  filtering: false,
  store: {},
  search: [],
  showCategory: {},
  editCategory: {},
  deleteCategory: {},
  testCategoryModel: {},
};
export default function productCategoriesReducer(state = initialState, action) {
  switch (action.type) {
    case productCategoryType.GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        allCategories: action.payLoad?.data,
      };
    case productCategoryType.STORE_A_PRODUCT_CATEGORY:
      return {
        ...state,
        store: action.payLoad?.data?.data,
      };
    case productCategoryType.DELETE_A_PRODUCT_CATEGORY:
      // const deleteCategory = state.allCategories.filter(
      //   (item) => item.id !== action.payLoad.id
      // );
      return {
        ...state,
        deleteCategory: action.payLoad.data,
      };
    case productCategoryType.EDIT_A_PRODUCT_CATEGORY:
      // const editCategories = state.allCategories.findIndex(
      //   (item) => item.id === action.payLoad.id
      // );
      // const allCategories = [...state.allCategories];
      // allCategories[editCategories] = action.payLoad;
      return {
        ...state,
        editCategory: action.payLoad.data,
      };
    case productCategoryType.SHOW_A_PRODUCT_CATEGORY:
      return {
        ...state,
        showCategory: action.payLoad.data.data,
      };
    case productCategoryType.TEST_PRODUCT_CATEGORY_MODEL:
      return {
        ...state,
        testCategoryModel: action.payLoad.data.data,
      };
    case productCategoryType.LOADING_PRODUCT_CATEGORIES:
      return {
        ...state,
        loading: action.payLoad,
      };
    case productCategoryType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case productCategoryType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case productCategoryType.SEARCH_PRODUCT_CATEGORY:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case productCategoryType.FILTER_PRODUCT_CATEGORY:
      return {
        ...state,
        allCategories: action.payLoad?.data,
      };

    default:
      return state;
  }
}
