import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../components/inputs/inputs";
import { getARoleAction } from "../../../../redux/roles/rolesActions";
import { EditRoleModal } from "../../../../components/modals/roles/EditRoleModal";
import { AddPermissionModal } from "../../../../components/modals/roles/addPermissionModal";

export default function ManagePermission() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { singleRole } = useSelector((state) => state.roles);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (id) {
      dispatch(getARoleAction(id));
    }
    // eslint-disable-next-line
  }, [id]);

  return (
    <div data-test="inviteUser-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md pt-8 pb-16 px-10 max-w-4xl mx-auto mt-24">
        <div className="border-b border-[#E4E4F3] py-3 text-center flex justify-between items-center">
          <h3 className="text-lg font-medium">Manage Permission</h3>
          <EditRoleModal />
        </div>

        <div className="mt-6 mb-12">
          <Input.Label
            title={"Role"}
            type="text"
            name="address"
            className="w-2/3 border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            value={singleRole?.display_name}
            disabled
          />

          <Input.Label
            title={"Description"}
            type="text"
            name="address"
            className="w-2/3 border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            value={singleRole?.description}
            disabled
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-8">
          {singleRole?.permissions?.length > 0 &&
            singleRole?.permissions?.map((permit, index) => (
              <div
                key={index}
                className="relative flex items-center gap-3 py-4"
              >
                <div className="flex items-center h-5">
                  <input
                    id={`permit-${permit?.id}`}
                    name={`permit-${permit?.id}`}
                    type="checkbox"
                    defaultChecked
                    className="focus:ring-0 h-6 w-6 text-[#CE2600] border-2 border-[#CE2600] rounded-[4px] shadow checked:shadow-xl"
                  />
                </div>
                <div className="min-w-0 text-sm">
                  <label
                    htmlFor={`permit-${permit?.id}`}
                    className="font-medium text-[#333333] select-none"
                  >
                    {permit?.display_name}
                  </label>
                </div>
              </div>
            ))}
        </div>

        <div className="mt-16 text-center">
          <button className="text-primary" onClick={openModal}>
            + Add New Permission
          </button>
        </div>
        <AddPermissionModal show={isOpen} onClose={closeModal} />
      </div>
      <div className="mt-8 text-center">
        <EditRoleModal />
      </div>
    </div>
  );
}
