import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import All from "./tabs/all";
import Incomplete from "./tabs/incomplete";
import Pending from "./tabs/pending";
import Complete from "./tabs/complete";

const tabs = [
  { name: "All", href: "all" },
  { name: "Initiated", href: "initiated" },
  { name: "Pending", href: "pending" },
  { name: "Completed", href: "completed" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Transactions() {
  const DEFAULT_ACTIVE_TAB = "all";
  const navigate = useNavigate();
  const { active_tab } = useParams();
  console.log("active_tab", active_tab);

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/wallet-transactions/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line
  }, [active_tab]);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/wallet-transactions/${tab.href}/1`);
    }
  };

  return (
    <div data-test="transaction-page">
      {/* <div className="mb-8">
        <h1 className="text-3xl font-semibold text-gray-900">Restaurants</h1>
      </div> */}
      <div className="sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div>{active_tab === "all" && <All />}</div>
      <div>{active_tab === "initiated" && <Incomplete />}</div>
      <div>{active_tab === "pending" && <Pending />}</div>
      <div>{active_tab === "completed" && <Complete />}</div>
    </div>
  );
}
