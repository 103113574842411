import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import Warning from "../../../assets/images/icons/warning.svg";
import { filterStores } from "../../../redux/stores/storeActions";
import { Beat } from "../../../plugins/spinners.plugin";

export default function FilterStoreDropDown({
  payLoad,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.store);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    store_name: "",
    store_phone: "",
    store_city: "",
    store_email: "",
    start_date: "",
    end_date: "",
  });
  const {
    store_name,
    store_phone,
    store_city,
    store_email,
    start_date,
    end_date,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      store_name: "",
      store_phone: "",
      store_city: "",
      store_email: "",
      start_date: "",
      end_date: "",
    });
    await dispatch(filterStores(payLoad, 1));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (
      store_name ||
      store_email ||
      store_phone ||
      store_city ||
      start_date ||
      end_date
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterStores({
            ...data,
            ...payLoad,
          })
        );
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Store Name"}
              htmlFor={"store_name"}
              name={"store_name"}
              onChange={handleChange}
              value={store_name}
              autoComplete="off"
              placeholder={"Enter Store Name"}
            />
            <Input.Number
              title={"Phone Number"}
              htmlFor={"store_phone"}
              name={"store_phone"}
              onChange={handleChange}
              value={store_phone}
              autoComplete="off"
              placeholder={"Enter Phone Number"}
            />

            <Input.Label
              title={"Email"}
              htmlFor={"store_email"}
              name={"store_email"}
              value={store_email}
              onChange={handleChange}
              autoComplete="off"
              placeholder={"Enter Email Address"}
            />
            <Input.Label
              title={"Location"}
              htmlFor={"store_city"}
              name={"store_city"}
              value={store_city}
              onChange={handleChange}
              autoComplete="off"
              placeholder={"Enter City"}
            />
            <Input.DatePicker
              title={"Start Date"}
              htmlFor={"start_date"}
              name={"start_date"}
              value={start_date}
              autoComplete="off"
              onChange={handleChange}
            />
            <Input.DatePicker
              title={"End Date"}
              htmlFor={"end_date"}
              name={"end_date"}
              value={end_date}
              onChange={handleChange}
            />
            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
