import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { useDispatch } from "react-redux";
import {
  editEmployee,
  getSingleEmployee,
} from "../../../redux/employee/employeeActions";
import Input from "../../inputs/inputs";
import Location from "./../../includes/location";

export default function EditEmployee({ singleEmployee }) {
  const employee_id = singleEmployee.id;
  const dispatch = useDispatch();

  let [isOpen, setIsOpen] = useState(false);
  function handleClose() {
    setIsOpen(false);
  }
  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      employee_name: singleEmployee.employee_name,
      employee_phone: singleEmployee.employee_phone,
      employee_address: singleEmployee.employee_address,
      employee_city: singleEmployee.employee_city,
      employee_state: singleEmployee.employee_state,
      employee_country: singleEmployee.employee_country,
    };

    setData({ ...dataObject });
  }, [singleEmployee]);

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    id: "",
    employee_name: "",
    employee_phone: "",
    employee_address: "",
    employee_city: "",
    employee_state: "",
    employee_country: "",
  });
  const {
    employee_name,
    employee_address,
    employee_city,
    employee_state,
    employee_country,
    employee_phone,
  } = data;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };
  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    // dispatch action to create data
    dispatch(
      editEmployee({ ...data, id: employee_id }, () =>
        dispatch(getSingleEmployee(employee_id))
      )
    );
    handleClose();
  };
  return (
    <div>
      <Button.Primary
        title={"Edit Employee"}
        className={"mr-4 mb-2 sm:mb-0"}
        onClick={() => setIsOpen(true)}
      />
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[358px]  rounded-sm sm:w-[630px] p-4 sm:p-6 sm:px-20">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4"></div>
                      <div className="">
                        <div className=" text-center  ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black "
                          >
                            Edit Employee Details
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className=" border-b-2 mt-4"></div>
                      <div className="text-left mt-5 sm:mt-7">
                        <div
                          className={
                            "form-group col-md-12 mb-4" +
                            (submitted && employee_name
                              ? "  border-red-500"
                              : "")
                          }
                        >
                          <Input.Label
                            htmlFor={"employee_name"}
                            title={"Employee name*"}
                            defaultValue={data.employee_name}
                            name="employee_name"
                            onChange={handleChange}
                          />
                          {submitted && employee_name && (
                            <div className="text-xs text-red-500">
                              Employee name is required
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            "form-group col-md-12 mb-4" +
                            (submitted && employee_phone
                              ? "  border-red-500"
                              : "")
                          }
                        >
                          <Input.Label
                            htmlFor={"employee_phone"}
                            title={"Employee phone*"}
                            defaultValue={data.employee_phone}
                            name="employee_phone"
                            onChange={handleChange}
                          />

                          {submitted && employee_phone && (
                            <div className="text-xs text-red-500">
                              Employee phone is required
                            </div>
                          )}
                        </div>

                        <div
                          className={
                            "form-group col-md-12 mb-4" +
                            (submitted && employee_address
                              ? "  border-red-500"
                              : "")
                          }
                        >
                          <Input.Label
                            htmlFor={"employee_address"}
                            title={"Employee address*"}
                            defaultValue={data.employee_address}
                            name="employee_address"
                            onChange={handleChange}
                          />
                          {submitted && employee_address && (
                            <div className="text-xs text-red-500">
                              Employee address is required.
                            </div>
                          )}
                        </div>

                        {/* Here, we utilize the location component to get the location data */}
                        <Location
                          country_id={locationId.country_id}
                          state_id={locationId.state_id}
                          city_id={locationId.city_id}
                          loadingLocation={(value) => {
                            setLocationLoader(value);
                          }}
                          countryList={(value) => {
                            setLocationList({
                              ...locationList,
                              countryList: value,
                            });
                          }}
                          stateList={(value) => {
                            setLocationList({
                              ...locationList,
                              stateList: value,
                            });
                          }}
                          cityList={(value) => {
                            setLocationList({
                              ...locationList,
                              cityList: value,
                            });
                          }}
                          country_name={data.employee_country}
                          state_name={data.employee_state}
                          city_name={data.employee_city}
                        />
                        <div
                          className={
                            "form-group col-md-12 mb-4" +
                            (submitted && employee_country
                              ? "  border-red-500"
                              : "")
                          }
                        >
                          <label
                            htmlFor="employee_country"
                            className="text-sm font-medium text-[#151515]"
                          >
                            Employee country*
                          </label>
                          <select
                            name="employee_country"
                            defaultValue={data.employee_country}
                            className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                            onChange={(e) => {
                              setLocationId({
                                ...locationId,
                                country_id: fromJson(e.target.value).id,
                              });
                              handleRegion(e);
                            }}
                          >
                            {data.employee_country && (
                              <option value={data.employee_country}>
                                {data.employee_country}
                              </option>
                            )}
                            {locationLoader ? (
                              <option>Loading...</option>
                            ) : (
                              locationList.countryList &&
                              locationList.countryList.length > 0 &&
                              locationList.countryList.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={toJson({
                                      name: item.name,
                                      id: item.id,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })
                            )}
                          </select>
                          {submitted && employee_country && (
                            <div className="text-xs text-red-500">
                              Employee country is required
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            "form-group col-md-12 mb-4" +
                            (submitted && employee_state
                              ? "  border-red-500"
                              : "")
                          }
                        >
                          <label
                            htmlFor="employee_state"
                            className="text-sm font-medium text-[#151515]"
                          >
                            Employee state*
                          </label>
                          <select
                            name="employee_state"
                            selected={data.employee_state}
                            className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                            onChange={(e) => {
                              setLocationId({
                                ...locationId,
                                state_id: fromJson(e.target.value).id,
                              });
                              handleRegion(e);
                            }}
                          >
                            {data.employee_state && (
                              <option value={data.employee_state}>
                                {data.employee_state}
                              </option>
                            )}
                            {locationList.stateList &&
                              locationList.stateList.length > 0 &&
                              locationList.stateList.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={toJson({
                                      name: item.name,
                                      id: item.id,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          {submitted && employee_state && (
                            <div className="text-xs text-red-500">
                              Employee state is required.
                            </div>
                          )}
                        </div>
                        <div
                          className={
                            "form-group col-md-12 mb-4" +
                            (submitted && employee_city
                              ? "  border-red-500"
                              : "")
                          }
                        >
                          <label
                            htmlFor="employee_city"
                            className="text-sm font-medium text-[#151515]"
                          >
                            Employee city*
                          </label>
                          <select
                            className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                            selected={data.employee_city}
                            name="employee_city"
                            onChange={(e) => {
                              setLocationId({
                                ...locationId,
                                city_id: fromJson(e.target.value).id,
                              });
                              handleRegion(e);
                            }}
                          >
                            {data.employee_city && (
                              <option value={data.employee_city}>
                                {data.employee_city}
                              </option>
                            )}
                            {locationList.cityList &&
                              locationList.cityList.length > 0 &&
                              locationList.cityList.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={toJson({
                                      name: item.name,
                                      id: item.id,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                          </select>
                          {submitted && employee_city && (
                            <div className="text-xs text-red-500">
                              Employee city is required
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex mt-10 justify-center mb-4">
                        <button
                          type="submit"
                          className="h-10 text-center rounded-sm border border-transparent shadow-sm px-4  bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        >
                          {"Save Changes"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
