import feedbackType from "./feedbackTypes";

const initialState = {
  loadingFeedback: false,
  storingFeedback: false,
  filtering: false,
  searching: false,
  downloading: false,
  allFeedback: {},
  singleFeedback: {},
};

export default function feedbackReducer(state = initialState, action) {
  switch (action.type) {
    case feedbackType.LOADING_FEEDBACK:
      return {
        ...state,
        loadingFeedback: action.payLoad,
      };
    case feedbackType.STORING_FEEDBACK:
      return {
        ...state,
        storingFeedback: action.payLoad,
      };
    case feedbackType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case feedbackType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };
    case feedbackType.FILTERING_FEEDBACK:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case feedbackType.ALL_FEEDBACK:
      return {
        ...state,
        allFeedback: action.payLoad?.data,
      };

    case feedbackType.SINGLE_FEEDBACK:
      return {
        ...state,
        singleFeedback: action.payLoad?.data,
      };

    case feedbackType.FILTER_FEEDBACK:
      return {
        ...state,
        allFeedback: action.payLoad?.data,
      };

    default:
      return state;
  }
}
