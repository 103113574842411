import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../../assets/images/icons/close.svg";
import Warning from "../../../../assets/images/icons/warning.svg";
import Input from "../../../inputs/inputs";
import Button from "../../../buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import Location from "../../../includes/location";
import { cleanInput } from "../../../../helpers/cleanInput";
import {
  editServiceArea,
  deleteServiceArea,
  filterServiceAreas,
} from "../../../../redux/serviceAreas/serviceAreasActions";

export default React.forwardRef(function UpdateServiceArea(
  {
    visibility = false,
    primaryButtonTitle,
    primaryButton,
    primaryButtonStyle,
    primaryButtonClassName,
    modalTitle,
    modalTitleSection,
    modelContent,
    modelContentSection,
    disableClose = false,
    disableOpen = false,
    onOpen = () => {},
    onClose = () => {},
  },
  ref
) {
  // Ref handler
  React.useImperativeHandle(ref, () => ({
    open: () => openModal(),
    close: () => closeModal(),
    getServiceArea: (data) => insertServiceArea(data),
  }));

  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = React.useState(visibility);
  const [submitted, setSubmitted] = useState(false);
  const [serviceArea, setServiceArea] = React.useState({});
  const [input, setInput] = useState({
    id: "",
    shipper_id: "",
    area: "",
    charge: "",
    city: "",
    state: "",
    country: "",
    zip: "",
    latitude: "",
    longitude: "",
  });

  const { area, charge, country, state, city } = input;
  const { updating, deleting } = useSelector((state) => state.serviceArea);
  const { settings } = useSelector((state) => state.application);

  useEffect(() => {
    let newInput = {
      id: serviceArea?.id,
      shipper_id: serviceArea?.shipper_id,
      area: serviceArea?.area,
      charge: serviceArea?.charge,
      city: serviceArea?.city,
      state: serviceArea?.state,
      country: serviceArea?.country,
      zip: serviceArea?.zip,
      latitude: serviceArea?.latitude,
      longitude: serviceArea?.longitude,
    };
    setInput(newInput);
  }, [serviceArea]);

  function closeModal() {
    !disableClose && setIsOpen(false);
    !disableClose && onClose();
  }

  function openModal() {
    !disableOpen && setIsOpen(true);
    !disableOpen && onOpen();
  }

  function insertServiceArea(data) {
    setServiceArea(data);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  function handleRegion(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  }

  const handleDelete = async () => {
    setSubmitted(true);

    try {
      const response = await dispatch(
        deleteServiceArea({ id: serviceArea?.id })
      );
      if (response.status === "success") {
        dispatch(
          filterServiceAreas({ shipper_id: serviceArea?.shipper_id }, 1)
        );
        closeModal();
      }
    } catch (error) {
      console.log("error");
    }
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    cleanInput(input);
    if (area && charge) {
      try {
        const response = await dispatch(
          editServiceArea({ ...input, id: serviceArea?.id })
        );
        if (response.status === "success") {
          dispatch(
            filterServiceAreas({ shipper_id: serviceArea?.shipper_id }, 1)
          );
          closeModal();
        }
      } catch (error) {
        console.log("error");
      }
    }
  };
  return (
    <React.Fragment>
      <div className="">
        {primaryButton ? (
          <span className="cursor-pointer" onClick={openModal}>
            {primaryButton}
          </span>
        ) : (
          <button
            type="button"
            style={primaryButtonStyle}
            onClick={openModal}
            disabled={primaryButtonStyle?.disabled}
            className={
              "glade-normal-text-three glade-button-blue whitespace-nowrap w-11/12 py-2 px-4 flex justify-center items-center text-white text-center rounded-sm " +
              primaryButtonClassName
            }
          >
            {primaryButtonTitle ?? "Title"}
          </button>
        )}
      </div>

      <Transition appear show={visibility || isOpen} as={React.Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <div className="min-h-screen px-4">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay
                className="fixed inset-0"
                style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
              />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}

            <div className="fixed z-10 inset-0 overflow-y-auto">
              <div className="flex items-center justify-center min-h-full p-4 sm:p-0">
                <Transition.Child
                  as={React.Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="relative w-[629px]  bg-white rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full sm:p-8">
                    <div>
                      <div className="sm:block absolute top-4 right-5">
                        <button
                          type="button"
                          className="bg-[#ffeae5] rounded-md  p-1 sm:p-3  "
                          onClick={closeModal}
                        >
                          <span className="sr-only">Close</span>
                          <img src={CLOSE} alt="close" className=" w-3 h-3" />
                        </button>
                      </div>
                      <div className="sm:flex">
                        <div className="text-left sm:text-left sm:px-8">
                          <Dialog.Title
                            as="p"
                            className="text-md font-meduim text-[#717485]"
                          >
                            Service Area Charge
                          </Dialog.Title>
                        </div>
                      </div>
                    </div>

                    <div className=" border-b-2 mt-4"></div>
                    <div className="mt-5 sm:mx-auto flex justify-center p-4">
                      <form>
                        <div className="border border-gray-300 py-2 px-2 mb-5">
                          <h3 className="block text-lg  font-bold text-[#151515]">
                            Charge per longitude and latitude (3km range) :
                          </h3>
                          <p className="text-[#4D4D4DB2] text-sm">
                            This is the charge per specific area charge for
                            customer item delivery.
                          </p>
                          {settings?.checkout_service_area_status ===
                            "unavailable" && (
                            <div className="flex gap-1 mt-2">
                              <img src={Warning} alt="warning" />
                              <p className="text-primary text-[12px] sm:text-[14px]">
                                Currently disabled by system administrator
                              </p>
                            </div>
                          )}
                        </div>
                        <div>
                          <div className="grid grid-cols-2 gap-2">
                            <div>
                              <Input.Label
                                title={"Title"}
                                htmlFor={"area"}
                                name={"area"}
                                placeholder={"Name your location"}
                                defaultValue={input?.area}
                                disabled
                              />
                              {submitted && !area && (
                                <div className="text-primary text-xs ">
                                  Location title is required
                                </div>
                              )}
                            </div>

                            <div>
                              <Input.Number
                                title={"Charge"}
                                htmlFor={"charge"}
                                name={"charge"}
                                placeholder={"NGN 1000"}
                                defaultValue={input?.charge}
                                onChange={(e) => handleChange(e)}
                              />
                              {submitted && !charge && (
                                <div className="text-primary text-xs ">
                                  Base charge is required
                                </div>
                              )}
                            </div>
                          </div>
                          <Location
                            country_id={locationId.country_id}
                            state_id={locationId.state_id}
                            city_id={locationId.city_id}
                            loadingLocation={(value) => {
                              setLocationLoader(value);
                            }}
                            countryList={(value) => {
                              setLocationList({
                                ...locationList,
                                countryList: value,
                              });
                            }}
                            stateList={(value) => {
                              setLocationList({
                                ...locationList,
                                stateList: value,
                              });
                            }}
                            cityList={(value) => {
                              setLocationList({
                                ...locationList,
                                cityList: value,
                              });
                            }}
                          />
                          <div className="mt-2">
                            <Input.Select
                              title={"Select Country"}
                              name="country"
                              id="country"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"country"}
                              onChange={(e) => {
                                setLocationId({
                                  ...locationId,
                                  country_id: fromJson(e.target.value).id,
                                });
                                handleRegion(e);
                              }}
                            >
                              <option>{country ?? "Select Country"}</option>
                              {locationLoader ? (
                                <option>Loading...</option>
                              ) : (
                                locationList.countryList &&
                                locationList.countryList.length > 0 &&
                                locationList.countryList.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={toJson({
                                        name: item.name,
                                        id: item.id,
                                      })}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })
                              )}
                            </Input.Select>
                          </div>
                          <div className="mt-4">
                            <Input.Select
                              title={"Select State"}
                              name="state"
                              id="state"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"state"}
                              onChange={(e) => {
                                setLocationId({
                                  ...locationId,
                                  state_id: fromJson(e.target.value).id,
                                });
                                handleRegion(e);
                              }}
                            >
                              <option>{state ?? "Select state"}</option>
                              {locationList.stateList &&
                                locationList.stateList.length > 0 &&
                                locationList.stateList.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={toJson({
                                        name: item.name,
                                        id: item.id,
                                      })}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </Input.Select>
                          </div>
                          <div className="mt-4">
                            <Input.Select
                              title={"Select City"}
                              name="city"
                              id="city"
                              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              htmlFor={"city"}
                              onChange={(e) => {
                                setLocationId({
                                  ...locationId,
                                  city_id: fromJson(e.target.value).id,
                                });
                                handleRegion(e);
                              }}
                            >
                              <option>{city ?? "Select city"}</option>
                              {locationList.cityList &&
                                locationList.cityList.length > 0 &&
                                locationList.cityList.map((item, key) => {
                                  return (
                                    <option
                                      key={key}
                                      value={toJson({
                                        name: item.name,
                                        id: item.id,
                                      })}
                                    >
                                      {item.name}
                                    </option>
                                  );
                                })}
                            </Input.Select>
                          </div>
                          <Input.Number
                            title={"Zip Code"}
                            htmlFor={"zip"}
                            name={"zip"}
                            placeholder={"Enter zip code"}
                            defaultValue={input?.zip}
                            onChange={(e) => handleChange(e)}
                          />

                          <Input.Label
                            title={"Longitude"}
                            htmlFor={"longitude"}
                            name={"longitude"}
                            placeholder={"Enter longitude"}
                            defaultValue={input?.longitude}
                            onChange={(e) => handleChange(e)}
                          />

                          <Input.Label
                            title={"Latitude"}
                            htmlFor={"latitude"}
                            name={"latitude"}
                            placeholder={"Enter Latitude"}
                            defaultValue={input?.latitude}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                        <div className="mt-8 mb-3 text-center flex justify-center gap-5">
                          <Button.Secondary
                            type={"button"}
                            disabled={updating}
                            title={
                              deleting ? <Beat color={"#ff0000"} /> : "Delete"
                            }
                            onClick={handleDelete}
                          />
                          <Button.Primary
                            type={"button"}
                            disabled={updating}
                            title={updating ? <Beat /> : "Save Changes"}
                            onClick={handleSubmit}
                          />
                        </div>
                      </form>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition>
    </React.Fragment>
  );
});
