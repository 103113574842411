import authType from "./thirdPartyTypes";

const initialState = {
  isLoading: false,
  creating: false,
  myTokens: {},
};

export default function thirdPartyReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.CREATING:
      return {
        ...state,
        creating: action.payLoad,
      };

    case authType.MY_THIRD_PARTY_TOKENS:
      return {
        ...state,
        myTokens: action.payLoad?.data,
      };

    default:
      return state;
  }
}
