import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { RiCloseLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import {
  editACategory,
  filterProductCategory,
} from "../../../redux/productCatergory/productCategoryAction";

export default function EditStoreCategory({ storeCategories }) {
  const category_id = storeCategories.id;
  const dispatch = useDispatch();

  let [isOpen, setIsOpen] = useState(false);
  function handleClose() {
    setIsOpen(false);
  }

  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      name: storeCategories.name,
      type: storeCategories.type,
    };
    setData({ ...dataObject });
  }, [storeCategories]);

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([{ name: "", type: "" }]);
  const { name, type } = data;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const [payLoad] = useState({
    type: "store",
  });
  const page = 1;
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (name && type) {
      if (name && type) {
        // dispatch action to create data
        const response = await dispatch(
          editACategory({ ...data, id: category_id })
        );
        if (response.status === "success") {
          dispatch(filterProductCategory(payLoad, page));
        }
        handleClose();
      }
    }
  };
  return (
    <>
      <p
        className="text-sm text-[#0E7DE3] mt-1 cursor-pointer"
        onClick={() => setIsOpen(true)}
      >
        Edit
      </p>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[358px] sm:rounded-md rounded-sm sm:w-[450px] p-4 sm:p-6 ">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                        <button type="button" onClick={handleClose}>
                          <span className="sr-only">Close</span>
                          <RiCloseLine />
                        </button>
                      </div>
                      <div className="">
                        <div className=" text-center sm:text-left ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black "
                          >
                            Edit Category
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className=" border-b-2 mt-4"></div>
                      <div className="mt-5 sm:mt-7 text-left">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name*
                          </label>
                          <div className="mt-2 ">
                            <input
                              type="text"
                              name="name"
                              className="block w-full border border-[#E4E4F3] px-2 py-2 mb-3 rounded-md  focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              defaultValue={data.name}
                              onChange={handleChange}
                            />
                            {submitted && !name && (
                              <div className="text-primary text-xs ">
                                Name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Type*
                          </label>
                          <div className="mt-2 ">
                            <select
                              name="type"
                              defaultValue={data.type}
                              className="block w-full border border-[#E4E4F3] px-2 py-2 rounded-md  focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                              onChange={handleChange}
                            >
                              <option>Select a type</option>
                              <option value="store">Store</option>
                              <option value="restaurant">Restaurant</option>
                            </select>
                            {submitted && !type && (
                              <div className="text-primary text-xs ">
                                Type is required
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mt-10 sm:mt-20 mb-6">
                        <Button.Secondary
                          title={"Back"}
                          className={"rounded-sm px-4 h-10 w-16"}
                          onClick={handleClose}
                        />
                        <button
                          type="submit"
                          className="h-10 text-center rounded-sm border border-transparent shadow-sm px-4  bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        >
                          {"Update Category"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
