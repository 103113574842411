import productTagType from "./productTagTypes";

const initialState = {
  allTags: [],
  loading: false,
  searching: false,
  filtering: false,
  search: [],
  showTag: {},
  editTag: {},
  deleteTag: {},
  testTagModel: {},
};
export default function productTagReducer(state = initialState, action) {
  switch (action.type) {
    case productTagType.GET_PRODUCT_TAG:
      return {
        ...state,
        allTags: action.payLoad?.data,
      };
    case productTagType.STORE_A_PRODUCT_TAG:
      return {
        ...state,
        allTags: action.payLoad?.data?.data,
      };
    case productTagType.DELETE_A_PRODUCT_TAG:
      // const deleteTag = state.allTags.filter(
      //   (item) => item.id !== action.payLoad.id
      // );
      return {
        ...state,
        deleteTag: action.payLoad?.data,
      };
    case productTagType.EDIT_A_PRODUCT_TAG:
      // const editTags = state.allTags.findIndex(
      //   (item) => item.id === action.payLoad.id
      // );
      // const allTags = [...state.allTags];
      // allTags[editTags] = action.payLoad;
      return {
        ...state,
        editTag: action.payLoad?.data?.data,
      };
    case productTagType.SHOW_A_PRODUCT_TAG:
      return {
        ...state,
        showTag: action.payLoad.data.data,
      };
    case productTagType.TEST_PRODUCT_TAG_MODEL:
      return {
        ...state,
        testTagModel: action.payLoad.data.data,
      };
    case productTagType.LOADING_PRODUCT_TAG:
      return {
        ...state,
        loading: action.payLoad,
      };
    case productTagType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case productTagType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case productTagType.SEARCH_PRODUCT_TAG:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case productTagType.FILTER_PRODUCT_TAG:
      return {
        ...state,
        allTags: action.payLoad?.data,
      };
    default:
      return state;
  }
}
