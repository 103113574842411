import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { profileService } from "../../services/profile.service";
import authType from "./profileTypes";

/**
 * Get my Profile
 **/

export const getMyProfileAction = () => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await profileService.getMyProfile();
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["MY_PROFILE"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

export const editAProfileAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["UPDATING"], payLoad: true });
    const response = await profileService.editMyProfile(payLoad);
    dispatch({ type: authType["UPDATING"], payLoad: false });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: authType["UPDATING"], payLoad: false });
  }
};
