import axios from "../plugins/axios.plugin";

class SubscriptionFoodService {
  baseServicePath = "/api/subscription-food";

  getAllSubscriptionFoods() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllSubscriptionFoodNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getASubscriptionFood(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}`);
  }

  createSubscriptionFood(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }

  searchSubscriptionFood(payLoad) {
    return axios.post(`${this.baseServicePath}/search/index`, payLoad);
  }

  filterSubscriptionFood(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }

  deleteSubscriptionFood(payLoad) {
    return axios.post(`${this.baseServicePath}/delete`, payLoad);
  }

  approveSubscriptionFood(payLoad) {
    return axios.post(`${this.baseServicePath}/approve/management`, payLoad);
  }

  unapproveSubscriptionFood(payLoad) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payLoad);
  }
}

export const subscriptionFoodService = new SubscriptionFoodService();
