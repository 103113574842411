import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { restaurantOrderService } from "../../services/restaurantOrder.service";
import restaurantType from "./restaurantOrderTypes";

/**
 * Get all restaurants
 **/

export const getAllRestaurantOrdersAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: true });
    const response = await restaurantOrderService.getAllRestaurantOrders(page);
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: restaurantType["ALL_RESTAURANT_ORDERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all restaurants
 **/

export const getAllRestaurantOrdersNoPagination =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: restaurantType["DOWNLOADING"], payLoad: true });
      const response =
        await restaurantOrderService.getAllRestaurantOrdersNoPagination(
          payload
        );
      dispatch({ type: restaurantType["DOWNLOADING"], payLoad: false });
      dispatch({
        type: restaurantType["ALL_RESTAURANTS_NO_PAGINATION"],
        payLoad: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: restaurantType["DOWNLOADING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Get a restaurants
 **/

export const getARestaurantOrderAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: true });
    const response = await restaurantOrderService.getARestaurantOrder(id);
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: restaurantType["SINGLE_RESTAURANT_ORDER"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * create restaurant order
 **/

export const createRestaurantOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: true });
    const response = await restaurantOrderService.createRestaurantOrder(payload);
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    successHandler(response, true);
    // dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};


/**
 * update restaurant order
 **/

export const updateRestaurantOrder = (payload) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: true });
    const response = await restaurantOrderService.updateRestaurantOrder(payload);
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    // dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get my restaurant
 **/

export const getMyRestaurantOrders = (page) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: true });
    const response = await restaurantOrderService.getMyRestaurantOrders(page);
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: restaurantType["MY_RESTAURANT_ORDERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: restaurantType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter restaurant order
 **/

export const filterRestaurantOrderAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: restaurantType["FILTERING"], payLoad: true });
      const response = await restaurantOrderService.filterRestaurantOrder(
        payload,
        page
      );
      dispatch({ type: restaurantType["FILTERING"], payLoad: false });
      dispatch({
        type: restaurantType["FILTER_RESTAURANT_ORDERS"],
        payLoad: response ?? { data: { data: [] } },
      });
      return response;
    } catch (error) {
      dispatch({ type: restaurantType["FILTERING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * mark restaurant order
 **/

export const markRestaurantOrderClosed = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["MARKING"], payLoad: true });
    const response = await restaurantOrderService.markRestaurantOrderClosed(id);
    dispatch({ type: restaurantType["MARKING"], payLoad: false });
    dispatch({
      type: restaurantType["CLOSE_RESTAURANT_ORDERS"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["MARKING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * mark restaurant order
 **/

export const markRestaurantOrderDelivered = (id) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["MARKING"], payLoad: true });
    const response = await restaurantOrderService.markRestaurantOrderDelivered(
      id
    );
    dispatch({ type: restaurantType["MARKING"], payLoad: false });
    dispatch({
      type: restaurantType["DELIVER_RESTAURANT_ORDERS"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["MARKING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search restaurant order
 **/

export const searchRestaurantOrderAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: true });
      const response = await restaurantOrderService.searchRestaurantOrder(
        payload,
        page
      );
      dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: false });
      dispatch({
        type: restaurantType["SEARCH_RESTAURANT_ORDERS"],
        payLoad: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: restaurantType["LOADING_SEARCH"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Ship restaurant order
 **/

export const shipRestaurantOrderAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: restaurantType["SHIPPING"], payLoad: true });
    const response = await restaurantOrderService.shipRestaurantOrder(payload);
    dispatch({ type: restaurantType["SHIPPING"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: restaurantType["SHIPPING"], payLoad: false });
    errorHandler(error, true);
  }
};
