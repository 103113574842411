import authType from "./timezoneTypes";

const initialState = {
  isLoading: false,
  allTimezones: {},
};

export default function timezoneReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.ALL_TIMEZONES:
      return {
        ...state,
        allTimezones: action.payLoad?.data,
      };

    default:
      return state;
  }
}
