import publicMerchandiseType from "./publicMerchandiseTypes";

const initialState = {
  isLoading: false,
  allPublicMerchandise: {},
  filter: [],
  singlePublicMerchandise: {},
  search: [],
};

export default function publicMerchandiseReducer(state = initialState, action) {
  switch (action.type) {
    case publicMerchandiseType.LOADING_PUBLIC_MERCHANDISE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case publicMerchandiseType.ALL_PUBLIC_MERCHANDISES:
      return {
        ...state,
        allPublicMerchandise: action.payLoad?.data,
      };

    case publicMerchandiseType.SINGLE_PUBLIC_MERCHANDISE:
      return {
        ...state,
        singlePublicMerchandise: action.payLoad?.data,
      };
    case publicMerchandiseType.FILTER_PUBLIC_MERCHANDISE:
      return {
        ...state,
        filter: action.payLoad?.data,
      };
    case publicMerchandiseType.SEARCH_PUBLIC_MERCHANDISE:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    default:
      return state;
  }
}
