import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ChevronDownIcon, ChevronUpIcon } from "@heroicons/react/outline";
import { AttachGroupRecipients } from "./attachGroupRecipients";
import Button from "../../../../../components/buttons/buttons";
import Input from "../../../../../components/inputs/inputs";
import Location from "../../../../../components/includes/location";
import { cleanInput } from "../../../../../helpers/cleanInput";
import { Beat } from "../../../../../plugins/spinners.plugin";
import Close from "../../../../../assets/images/icons/close.svg";
import Warning from "../../../../../assets/images/icons/warning-circle.svg";
import { filterUsersAction } from "../../../../../redux/users/userActions";
import { storeANotifierGroup } from "../../../../../redux/notifierGroup/notifierGroupAction";

export default function CreateRecipientGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [showSelected, setShowSelected] = useState([]);

  const [showFilter, setShowFilter] = useState(false);
  const [filteredUsers, setFilteredUsers] = useState([]);

  const [data, setData] = useState({
    title: "",
    is_all_users: 1,
    recipients: [],
  });
  const [filterData, setFilterData] = useState({
    start_date: "",
    end_date: "",
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const { title, is_all_users } = data;
  const { loading } = useSelector((state) => state.notifierGroup);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  function handleRegion(e) {
    const { name, value } = e.target;
    setFilterData((filterData) => ({
      ...filterData,
      [name]: fromJson(value).name,
    }));
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilterData((filterData) => ({ ...filterData, [name]: value }));
  };

  const filterUsers = async () => {
    cleanInput(filterData);
    const response = await dispatch(
      filterUsersAction({ ...filterData, pagination: 0 })
    );
    if (response?.status === "success") {
      const filter = response?.data.map((data) => data?.id);
      setFilteredUsers(filter);
      setData({ ...data, recipients: [...data?.recipients, ...filter] });
    }
  };

  const resetFilterUsers = async () => {
    const old_recipients = [...data?.recipients];
    const filter = old_recipients.filter(
      (id) => filteredUsers.indexOf(id) === -1
    );

    const new_recipients = [...filter, ...selectedArr];
    setData({ ...data, recipients: new_recipients });
    setFilteredUsers([]);
    setFilterData({
      start_date: "",
      end_date: "",
      country_id: "",
      state_id: "",
      city_id: "",
    });
  };

  const removeItem = (value) => {
    const filtered = selected.filter((item) => item.id !== value?.id);
    setSelected(filtered);

    const filterArr = selectedArr.filter((item) => item !== value?.id);
    setSelectedArr(filterArr);
    let prev_recipients = [...data?.recipients];
    let new_recipients = [...prev_recipients, value?.id];
    setData({ ...data, recipients: new_recipients });

    const filterShowArr = showSelected.filter((item) => item.id !== value?.id);
    setShowSelected(filterShowArr);
  };

  const handleSubmit = async () => {
    // event.preventDefault();
    setSubmitted(true);

    if (title) {
      let final_data = {};
      if (is_all_users === "1" || is_all_users === 1) {
        final_data = {
          title: title,
          is_all_users: 1,
        };
      } else {
        final_data = {
          title: title,
          recipients: data?.recipients,
        };
      }
      const response = await dispatch(storeANotifierGroup(final_data));
      if (response) {
        navigate(-1);
      }
    }
  };

  return (
    <div>
      <div className="pt-4 sm:pt-2 sm:flex items-center mx-auto">
        <div className=" max-w-4xl mx-auto mt-10">
          <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200 bg-[#ffffff]">
            <form name="form">
              <div className="border-b-2 py-4 mb-6">
                <p className=" text-base font-medium text-gray-800 flex justify-center ">
                  Create Group
                </p>
              </div>
              <div className="sm:px-10">
                <div
                  className={
                    "mt-4" + (submitted && title ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Group Name"}
                    htmlFor={"title"}
                    value={title}
                    type={"text"}
                    name={"title"}
                    onChange={handleChange}
                    placeholder={"Enter group name"}
                  />
                  {submitted && !title && (
                    <div className="text-xs text-red-500">
                      Business name is required
                    </div>
                  )}
                </div>

                <div className="mt-4">
                  <label
                    htmlFor="is_all_users"
                    className="block text-sm font-medium text-[#151515]"
                  >
                    All Users
                  </label>
                  <select
                    name="is_all_users"
                    value={is_all_users}
                    className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFF] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={handleChange}
                  >
                    <option value={1}>Yes</option>
                    <option value={0}>No</option>
                  </select>
                </div>

                {is_all_users === "0" && (
                  <div>
                    <div className="mt-6">
                      <label
                        htmlFor="users"
                        className="text-sm font-medium text-[#151515] mb-2 block"
                      >
                        Specific Users
                      </label>

                      <button
                        type="button"
                        className="w-full border border-dashed border-[#C2C9D1] flex flex-wrap gap-3 text-center justify-center p-3 mb-1"
                        onClick={() => setIsOpen(true)}
                      >
                        {showSelected?.length > 0 ? (
                          <>
                            {showSelected.map((val, i) => (
                              <span
                                key={i}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  removeItem(val);
                                }}
                                className="px-3 text-[16px] bg-[#F4F4F4] px-2 py-1 rounded-md inline-flex gap-2 items-center"
                              >
                                {val?.name}
                                <img
                                  src={Close}
                                  alt="close"
                                  className="w-[13px] h-[13px]"
                                />
                              </span>
                            ))}
                            <span className="text-[#D2D2D2]">
                              {"+ Search Users"}
                            </span>
                          </>
                        ) : (
                          "+ Search Users"
                        )}
                      </button>
                    </div>

                    <div
                      className="mt-12 pb-6"
                      onClick={() => setShowFilter(!showFilter)}
                    >
                      <p className="text-[16px]">Filtered Users</p>
                      <div className="flex itms-center justify-between cursor-pointer">
                        <div className="flex items-center gap-2">
                          <img
                            src={Warning}
                            alt="warning"
                            className="w-[13px] h-[13px] cursor-pointer"
                          />
                          <p className="text-[#9CA3AF] text-[14px]">
                            Showing filtered users that will be added to the
                            group
                          </p>
                        </div>
                        {!showFilter ? (
                          <ChevronDownIcon
                            className="h-6 w-6 text-primary"
                            aria-hidden="true"
                          />
                        ) : (
                          <ChevronUpIcon
                            className="h-6 w-6 text-primary"
                            aria-hidden="true"
                          />
                        )}
                      </div>
                    </div>

                    {showFilter && (
                      <>
                        <Input.DatePicker
                          title={"Account Registration (Start Date)"}
                          name="start_date"
                          defaultValue={filterData?.start_date}
                          onChange={handleFilter}
                        />

                        <Input.DatePicker
                          title={"Account Registration (End Date)"}
                          name="end_date"
                          defaultValue={filterData?.end_date}
                          onChange={handleFilter}
                        />

                        <Location
                          country_id={locationId.country_id}
                          state_id={locationId.state_id}
                          city_id={locationId.city_id}
                          loadingLocation={(value) => {
                            setLocationLoader(value);
                          }}
                          countryList={(value) => {
                            setLocationList({
                              ...locationList,
                              countryList: value,
                            });
                          }}
                          stateList={(value) => {
                            setLocationList({
                              ...locationList,
                              stateList: value,
                            });
                          }}
                          cityList={(value) => {
                            setLocationList({
                              ...locationList,
                              cityList: value,
                            });
                          }}
                        />
                        <div className="mt-4">
                          <Input.Select
                            title={"Select Country"}
                            name="country"
                            id="country"
                            className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                            htmlFor={"country"}
                            onChange={(e) => {
                              setLocationId({
                                ...locationId,
                                country_id: fromJson(e.target.value).id,
                              });
                              handleRegion(e);
                            }}
                          >
                            <option>Select Country</option>
                            {locationLoader ? (
                              <option>Loading...</option>
                            ) : (
                              locationList?.countryList &&
                              locationList?.countryList?.length > 0 &&
                              locationList?.countryList?.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={toJson({
                                      name: item.name,
                                      id: item.id,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })
                            )}
                          </Input.Select>
                        </div>
                        <div className="mt-6">
                          <Input.Select
                            title={"Select State"}
                            name="state"
                            id="state"
                            className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                            htmlFor={"state"}
                            onChange={(e) => {
                              setLocationId({
                                ...locationId,
                                state_id: fromJson(e.target.value).id,
                              });
                              handleRegion(e);
                            }}
                          >
                            <option>Select State</option>
                            {locationLoader ? (
                              <option>Loading...</option>
                            ) : (
                              locationList?.stateList &&
                              locationList?.stateList?.length > 0 &&
                              locationList?.stateList?.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={toJson({
                                      name: item.name,
                                      id: item.id,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })
                            )}
                          </Input.Select>
                        </div>
                        <div className="mt-6">
                          <Input.Select
                            title={"Select City"}
                            name="city"
                            id="city"
                            className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                            htmlFor={"city"}
                            onChange={(e) => {
                              setLocationId({
                                ...locationId,
                                city_id: fromJson(e.target.value).id,
                              });
                              handleRegion(e);
                            }}
                          >
                            <option>Select City</option>
                            {locationLoader ? (
                              <option>Loading...</option>
                            ) : (
                              locationList?.cityList &&
                              locationList?.cityList?.length > 0 &&
                              locationList?.cityList?.map((item, key) => {
                                return (
                                  <option
                                    key={key}
                                    value={toJson({
                                      name: item.name,
                                      id: item.id,
                                    })}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })
                            )}
                          </Input.Select>
                        </div>

                        {/* Show status result */}
                        <div className="border-2 border-primary rounded-lg flex justify-between p-3 mb-5 mt-6">
                          <div>
                            <div></div>
                            <div>
                              <p className="text-[#9CA3AF] text-[13px]">
                                Status Result:
                              </p>
                              <p>Users Found : {filteredUsers?.length ?? 0}</p>
                            </div>
                          </div>
                          <div className="flex items-center gap-5">
                            <Button.Secondary
                              type={"button"}
                              title={"Reset"}
                              onClick={resetFilterUsers}
                            />
                            <Button.Primary
                              type={"button"}
                              title={"Filter"}
                              onClick={filterUsers}
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                )}
              </div>
            </form>
          </div>
          <Button.Primary
            type={"button"}
            title={loading ? <Beat /> : "Create Group"}
            onClick={handleSubmit}
            className={"w-[333px] sm:mt-10 mx-auto py-4 flex justify-center  "}
          />
        </div>
        <AttachGroupRecipients
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          data={data}
          setData={setData}
          selected={selected}
          showSelected={showSelected}
          setShowSelected={setShowSelected}
          setSelected={setSelected}
          selectedArr={selectedArr}
          setSelectedArr={setSelectedArr}
        />
      </div>
    </div>
  );
}
