import axios from "../plugins/axios.plugin";

class BusinessService {
  baseServicePath = "/api/business";

  getMyBusiness() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }
  getABusiness(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  getAllBusinesses(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  editMyBusiness(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  approveBusiness(payLoad) {
    return axios.post(`${this.baseServicePath}/approve/management`, payLoad);
  }
  unapproveBusiness(payLoad) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payLoad);
  }

  searchBusiness(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterBusiness(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  filterBusinessNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
}

export const businessService = new BusinessService();
