import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import advertismentType from "./publicAdvertismentTypes";
import { publicAdvertisementService } from "../../services/publicAdvertisment.service";

/**
 * Get all Advertisment
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllPublicAdvertisments = () => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    const response =
      await publicAdvertisementService.getAllPublicAdvertisements();
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["GET_ALL_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const filterPublicAdvertisments = (payLoad) => async (dispatch) => {
  try {
    const response =
      await publicAdvertisementService.filterPublicAdvertisements(payLoad);
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });

    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["FILTER_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const searchPublicAdvertisments = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_SEARCH"], payLoad: true });
    const response = await publicAdvertisementService.searchPublicAdvertisement(
      payLoad
    );
    dispatch({ type: advertismentType["LOADING_SEARCH"], payLoad: false });
    dispatch({
      type: advertismentType["SEARCH_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};

export const getSinglePublicAdvertisment = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: advertismentType["LOADING_ADVERTISMENT"], payLoad: true });
    const response = await publicAdvertisementService.showPublicAdvertisement(
      payLoad
    );
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    dispatch({
      type: advertismentType["SHOW_A_ADVERTISMENT"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: advertismentType["LOADING_ADVERTISMENT"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
