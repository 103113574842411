import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Button from "../../../../components/buttons/buttons";
import ChooseMeal from "../../../../components/singleList/chooseMeal";
import { LeftColumn } from "../../../../layouts/RestaurantLayout";

export default function LeftSide({
  startDate,
  handleSubmit,
  subscriptionDays,
  setSubscriptionDays,
  count,
  counter,
  setCounter,
  setCount,
  setStartDate,
  selectedMeal,
  setSelectedMeal,
}) {
  const location = useLocation();
  const option = { weekday: "long" };
  const weekDay = startDate.toLocaleDateString("en-us", option);
  let day = weekDay.toLowerCase();
  // let endDate = new Date(location.state.endDate);
  let endDate = getSixDaysLater(
    location?.state?.startDate,
    location?.state?.endDate
  );
  const [selectedFoods, setSelectedFoods] = useState({});

  const { singlePackage } = useSelector((state) => state.publicPackages);
  const disableButton = checkExpiry(singlePackage?.package_end_date);
  const expiryDate = new Date(singlePackage?.package_end_date);

  const incrementCounter = (counter) => setCounter(counter);

  useEffect(() => {
    if (selectedFoods) {
      const no_of_selected_foods = Object.keys(selectedFoods).length;
      setCounter(no_of_selected_foods);
    }
  }, [selectedFoods, setCounter]);

  function checkExpiry(dateString) {
    //get today's date and check if it is greater than expiry date.
    //if yes, return status as expired, else active
    const specifiedDate = new Date(dateString);
    // const today = new Date();
    const end_of_selection = new Date(location.state.endDate);
    return end_of_selection > specifiedDate;
  }

  // when you click the next button
  function showNextDate(prevDate, endDate) {
    let nextDate = new Date(prevDate);
    nextDate = nextDate.setDate(nextDate.getDate() + 1);
    let d1 = new Date(nextDate);
    let d2 = new Date(endDate);

    if (d1 <= d2) {
      setCount(count + 1);
      setStartDate(d1);
      return;
    }
    setStartDate(d2);
  }

  //handles the previous button
  function showPreviousDate(currDate) {
    let prevDate = new Date(currDate);
    prevDate = prevDate.setDate(prevDate.getDate() - 1);
    let d1 = new Date(prevDate);
    if (count > 1) {
      setCount(count - 1);
      setStartDate(d1);
      return;
    }
    setStartDate(new Date(location.state.startDate));
    return;
  }

  //handles the skip button
  function skipDate(prevDate, endDate) {
    //We're going to check if the date has a meal before and we're now skipping
    //it or if it's a fresh entry. Remove entry from selected food if it exists
    const itemChecker = doesKeyExist(selectedFoods, day);
    if (itemChecker) {
      const newObj = { ...selectedFoods };
      delete newObj[day];
      setSelectedFoods(newObj);
    }

    //If a date is skipped, we also need to remove the food from subscriptionDays if it exist
    const newSubscriptionDays = { ...subscriptionDays };
    newSubscriptionDays[day] = {};
    setSubscriptionDays(newSubscriptionDays);

    let nextDate = new Date(prevDate);
    nextDate = nextDate.setDate(nextDate.getDate() + 1);
    let d1 = new Date(nextDate);
    let d2 = new Date(endDate);

    // this first checks if d1 which is the next day, is less than the end date.
    // If true, it sets the current day meal as skipped and increments the day by 1
    if (d1 <= d2) {
      const skipped = selectedMeal.map((a, index) => {
        if (a.date.getTime() === prevDate.getTime()) {
          return { ...a, skipped: true, display: true };
        }
        return a;
      });
      setSelectedMeal(skipped);
      setCount(count + 1);
      setStartDate(d1);
      return;
    } else {
      //else if the d1 which is the next day is greater than the next day,
      //only set as current day meal skipped without any other increments
      const skipped = selectedMeal.map((a, index) => {
        if (a.date.getTime() === prevDate.getTime()) {
          return { ...a, skipped: true, display: true };
        }
        return a;
      });
      setSelectedMeal(skipped);
      setStartDate(d2);
    }
  }

  // updates the selected meal array on click
  function updateSelectedMeal(date, meal) {
    const updated = selectedMeal.map((a, index) => {
      if (a.date.getTime() === date.getTime()) {
        return { ...a, ...meal, display: true, skipped: false };
      }
      return a;
    });
    setSelectedMeal(updated);

    //We're going to check if the date has a meal before and we're now updating
    //it or if it's a fresh entry. Increment counter for fresh entries only
    const itemChecker = doesKeyExist(selectedFoods, day);
    if (!itemChecker) {
      incrementCounter(counter + 1);
    }
  }

  function disableNextButton() {
    if (selectedFoods[day] !== undefined) {
      return false;
    } else {
      return true;
    }
  }

  const disableNext = disableNextButton();

  return (
    <LeftColumn>
      <div className="flex justify-between items-center border-b-2 mb-3 ">
        <h2 className="text-2xl font-bold pb-3">Choose your meals</h2>
      </div>

      <p className="mb-4">NB: You can only choose a meal per day</p>
      <section aria-labelledby="section-1-title">
        <h2 className="sr-only" id="section-1-title">
          Restaurant Plans
        </h2>
        <h2 className="text-xl font-medium mb-1">{weekDay}</h2>
        {disableButton && (
          <p className="text-xs text-primary">
            This package expires on{" "}
            {expiryDate.toLocaleDateString("en-us", options)}. Adjust your
            package end date
          </p>
        )}
        {/* <p className="text-xs">
          {startDate.toLocaleDateString("en-us", options)}
        </p> */}
        <div className="mt-6">
          {/* Your content */}
          <ChooseMeal
            setCurrentMeal={(value) => {
              //   setCurrentMeal(value);
              updateSelectedMeal(new Date(startDate), value);
            }}
            selectedFoods={selectedFoods}
            setSelectedFoods={setSelectedFoods}
            weekDay={weekDay}
            subscriptionDays={subscriptionDays}
            setSubscriptionDays={setSubscriptionDays}
          />
        </div>

        <div className="mt-20 flex gap-4 items-center ">
          {count !== 1 && (
            <div
              className="text-primary text-lg font-medium cursor-pointer"
              onClick={() => {
                showPreviousDate(startDate);
              }}
            >
              Previous
            </div>
          )}

          <div className="flex gap-4 ml-auto">
            <Button.Secondary
              title={"Skip"}
              className="px-5"
              onClick={() => skipDate(startDate, endDate)}
            />

            {startDate.getTime() === new Date(endDate).getTime() ? (
              <Button.Primary
                disabled={disableButton}
                title={disableButton ? "Expired Package" : "Confirm Package"}
                type="button"
                className="px-5"
                onClick={() => handleSubmit()}
              />
            ) : (
              <Button.Primary
                title={"Next"}
                disabled={disableNext}
                className="px-5"
                onClick={() => {
                  showNextDate(startDate, endDate);
                }}
              />
            )}
          </div>
        </div>
      </section>
    </LeftColumn>
  );
}

// for converting date to local date string
const options = {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
};

// this checks if a key exists in an object
const doesKeyExist = (obj, key) => {
  return obj.hasOwnProperty(key);
};

//get a date 6 days after date1
function getSixDaysLater(startDate, endDate) {
  const newEndDate = new Date(endDate);
  const sixDaysLater = new Date(startDate);
  sixDaysLater.setDate(sixDaysLater.getDate() + 6);
  return newEndDate > sixDaysLater ? sixDaysLater : newEndDate;
}
