import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import AdminDashboardSummary from '../components/AdminDashboardSummary';
import Card from "../../../../components/cards/admin/cards";
import DashboardSkeleton from "../../../../components/skeleton/dashboard";
import { ReactComponent as UNSHIPPED } from "../../../../assets/images/icons/unshipped-orders.svg";
import { ReactComponent as SHIPPED } from "../../../../assets/images/icons/shipped-orders.svg";
import { ReactComponent as COMPLETED } from "../../../../assets/images/icons/completed-orders.svg";
import { ReactComponent as CLOSED } from "../../../../assets/images/icons/closed-orders.svg";
//tabs
import Restaurant from "./tabs/restaurant";
import Store from "./tabs/store";
import RestaurantPackage from "./tabs/restaurant-package";
import StorePackage from "./tabs/store-package";
//apis
import { getGeneralStatistics } from "../../../../redux/statistics/statisticsAction";
import { getUserFromCookies } from "../../../../utils/Auth";

const tabs = [
  { name: "Restaurant Orders", href: "restaurant" },
  { name: "Store Orders", href: "store" },
  { name: "Food Package Orders", href: "food-package" },
  { name: "Merchandise Package Orders", href: "merchandise-package" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard() {
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  let business = Object.keys(user?.groups);
  const [active_tab, setActiveTab] = useState("restaurant");

  var currentDate = new Date();
  const { generalStats, isLoading } = useSelector((state) => state.statistics);

  useEffect(() => {
    dispatch(
      getGeneralStatistics({
        business_id: business[0],
        date: moment(currentDate).format("YYYY-MM-DD"),
      })
    );
    // eslint-disable-next-line
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab.href) {
      setActiveTab(tab.href);
    }
  };

  const summaries = [
    {
      name: "Restaurant",
      amount: generalStats?.restaurant_orders_unshipped,
      icon: UNSHIPPED,
      status: "Unshipped Orders",
    },
    {
      name: "Restaurant",
      amount: generalStats?.restaurant_orders_shipped,
      icon: SHIPPED,
      status: "Shipped Orders",
    },
    {
      name: "Restaurant",
      amount: generalStats?.restaurant_orders_delivered,
      icon: COMPLETED,
      status: "Completed Orders",
    },
    {
      name: "Restaurant",
      amount: generalStats?.restaurantOrders?.closed_orders,
      icon: CLOSED,
      status: "Closed Orders",
    },
    {
      name: "Store",
      amount: generalStats?.store_orders_unshipped,
      icon: UNSHIPPED,
      status: "Unshipped Orders",
    },
    {
      name: "Store",
      amount: generalStats?.store_orders_shipped,
      icon: SHIPPED,
      status: "Shipped Orders",
    },
    {
      name: "Store",
      amount: generalStats?.store_orders_delivered,
      icon: COMPLETED,
      status: "Completed Orders",
    },
    {
      name: "Store",
      amount: generalStats?.store_orders_closed,
      icon: CLOSED,
      status: "Closed Orders",
    },
  ];

  return (
    <div>
      <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Welcome back, {user?.name}
        </h1>
        <div className="flex flex-col sm:flex-row gap-3 justify-between sm:items-center">
          <p>Here’s what’s happening with your store today </p>
          <div className="bg-white py-2 px-4 border border-[#E4E4F3] rounded-lg text-sm tracking-widest w-fit">
            {moment(currentDate).format("dddd, DD/MM/YYYY")}
          </div>
        </div>
      </div>

      {isLoading ? (
        <DashboardSkeleton />
      ) : (
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-4 sm:gap-6">
          {summaries?.map((summary, i) => (
            <Card.Dashboard key={i} summary={summary} />
          ))}
        </div>
      )}

      <AdminDashboardSummary />

      <div className="border-b border-gray-200 mt-16 w-full">
        <nav
          className="-mb-px flex space-x-6 sm:space-x-8 overflow-x-scroll"
          aria-label="Tabs"
        >
          {tabs.map((tab) => (
            <button
              key={tab.name}
              onClick={() => {
                toggle(tab);
              }}
              className={classNames(
                tab.href === active_tab
                  ? "border-primary text-primary font-bold"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={tab.href === active_tab ? "page" : undefined}
            >
              {tab.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="p-3 bg-white rounded-md">
        <div className="mt-1">
          {active_tab === "restaurant" && <Restaurant />}
          {active_tab === "store" && <Store />}
          {active_tab === "food-package" && <RestaurantPackage />}
          {active_tab === "merchandise-package" && <StorePackage />}
        </div>
      </div>
    </div>
  );
}
