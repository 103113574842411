// Application types

const PAYMENT = {
  LOADING_AUTH: "LOADING_AUTH",
  VERIFYING: "VERIFYING",
  ALL_PAYMENT_PROVIDERS: "ALL_PAYMENT_PROVIDERS",
  MY_PAYMENTS: "MY_PAYMENTS",
  PAY_FOR_ME: "PAY_FOR_ME",
  SINGLE_PAYMENT: "SINGLE_PAYMENT",
  FILTER_PAYMENT: "FILTER_PAYMENT",
  FILTERING_PAYMENT: "FILTERING_PAYMENT",
  LOADING_SEARCH: "LOADING_SEARCH",
  SEARCH_PAYMENT: "SEARCH_PAYMENT",
  DOWNLOADING: "DOWNLOADING",
  ALL_PAYMENT_NO_PAGINATION: "ALL_PAYMENT_NO_PAGINATION",
};

export default PAYMENT;
