import axios from "../plugins/axios.plugin";

class CouponService {
  baseServicePath = "/api/coupon";

  getAllCoupons() {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=1`);
  }
  filterCoupon(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  filterCouponNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
  storeCoupon(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  searchCoupon(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showACoupon(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  showMyCoupon(payload) {
    return axios.get(`${this.baseServicePath}/me?properties=1`, payload);
  }
  editCoupon(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  deleteCoupon(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  expiredCoupon(payload) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payload);
  }
}
export const couponService = new CouponService();
