//Feature Types

const FEATURE = {
  LOADING: "LOADING",
  STORING: "STORING",
  LOADING_FEATURED: "LOADING_FEATURED",
  DOWNLOADING_FEATURED: "DOWNLOADING_FEATURED",
  ACTIVATING: "ACTIVATING",
  FILTERING: "FILTERING",
  ESTIMATING: "ESTIMATING",
  GET_ALL_FEATURED: "GET_ALL_FEATURED",
  LOADING_SEARCH: "LOADING_SEARCH",
  SEARCH_FEATURED: "SEARCH_FEATURED",
  GET_SINGLE_FEATURED: "GET_SINGLE_FEATURED",
  ACTIVATE_FEATURED: "ACTIVATE_FEATURED",
  DEACTIVATE_FEATURED: "DEACTIVATE_FEATURED",
  FILTER_FEATURED: "FILTER_FEATURED",
  ESTIMATE_FEATURED_RATE: "ESTIMATE_FEATURED_RATE",
  STORE_FEATURED_PRODUCT: "STORE_FEATURED_PRODUCT",
  GET_FEATURE_RATE: "GET_FEATURE_RATE",
};
export default FEATURE;
