import featureType from "./featureTypes";

const initialState = {
  isLoading: false,
  allFeatured: {},
  search: [],
  searching: false,
  filtering: false,
  activating: false,
  estimating: false,
  downloading: false,
  storing: false,
  singleFeatured: {},
  featureRate: {},
  testModel: {},
};

export default function featureReducer(state = initialState, action) {
  switch (action.type) {
    case featureType.LOADING:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case featureType.STORING:
      return {
        ...state,
        storing: action.payLoad,
      };
    case featureType.LOADING_FEATURED:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case featureType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case featureType.DOWNLOADING_FEATURED:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case featureType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case featureType.ACTIVATING:
      return {
        ...state,
        activating: action.payLoad,
      };

    case featureType.SEARCH_FEATURED:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case featureType.GET_ALL_FEATURED:
      return {
        ...state,
        allFeatured: action.payLoad?.data,
      };

    case featureType.GET_SINGLE_FEATURED:
      return {
        ...state,
        singleFeatured: action.payLoad?.data,
      };

    case featureType.ACTIVATE_FEATURED:
      let activate = {
        ...state.singleFeatured,
        status: "active",
      };
      return {
        ...state,
        singleFeatured: activate,
      };

    case featureType.DEACTIVATE_FEATURED:
      let deactivate = {
        ...state.singleFeatured,
        status: "inactive",
      };
      return {
        ...state,
        singleFeatured: deactivate,
      };

    case featureType.FILTER_FEATURED:
      return {
        ...state,
        allFeatured: action.payLoad?.data,
      };

    case featureType.ESTIMATING:
      return {
        ...state,
        estimating: action.payLoad,
      };

    case featureType.ESTIMATE_FEATURED_RATE:
      return {
        ...state,
        featureRate: action.payLoad?.data,
      };

    case featureType.GET_FEATURE_RATE:
      return {
        ...state,
        featureRate: action.payLoad?.data,
      };

    default:
      return state;
  }
}
