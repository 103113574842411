import React from "react";
import { SUSHI } from "../../assets/images/pictures/images";
import PLUS from "../../assets/images/icons/plus.svg";
import { useNavigate } from "react-router-dom";

export default function SinglePackage({ title }) {
  const navigate = useNavigate();

  const singleOrder = [
    {
      menu: "Summer Food Package",
      Price: "NGN 400",
      description:
        "Weekly food package for you, delivered to you right when you want it and on time",
      type: "Breakfast",
    },
    {
      menu: "Winter Food Package",
      Price: "NGN 400",
      description:
        "Weekly food package for you, delivered to you right when you want it and on time",
      type: "Lunch",
    },
  ];
  return (
    <div className="sm:max-w-2xl mb-12 " data-test="single-package">
      <h2 className="text-3xl font-bold border-b-2 mb-3 pb-3">{title}</h2>
      {singleOrder.map((order, index) => (
        <ul key={index}>
          <li className="py-3 sm:py-4">
            <div className="flex items-center justify-between">
              <div className="flex">
                <img
                  className=" w-20 sm:w-auto h-24 sm:h-auto"
                  src={SUSHI}
                  alt="email"
                />
                <div className="ml-4">
                  <p className=" text-lg mb-2 text-start font-medium text-gray-900 truncate">
                    {order.menu}
                  </p>
                  <p className="text-sm mb-2 text-gray-500 max-w-sm">
                    {order.description}
                  </p>
                  <p className="text-sm text-primary-500 font-medium">
                    {order.Price}
                  </p>
                  <p className="text-sm mt-2 font-medium text-[#676565]">
                    Type: <span>{order.type}</span>
                  </p>
                </div>
              </div>

              <div className=" sm:mb-14 bg-primary px-2 rounded-lg w-12 h-12 flex items-center justify-center ">
                <img
                  src={PLUS}
                  alt="PLUS"
                  className=" cursor-pointer"
                  onClick={() => navigate("/food-package/customise")}
                />
              </div>
            </div>
          </li>
          <div className=" border-b-2"></div>
        </ul>
      ))}
    </div>
  );
}
