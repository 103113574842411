import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import StoreArrangement from "./storeArrangment";
import RestaurantsArrangement from "./restaurantArrangment";
import SlidersArrangement from "./slidersArrangement";
import PackageArrangement from "./foodPackages";
import AllFeaturedProducts from "./features";

const tabs = [
  { name: "Web Sliders", href: "slider" },
  { name: "Restaurants", href: "restaurant" },
  { name: "Stores", href: "store" },
  { name: "Features", href: "feature" },
  { name: "Packages", href: "packages" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Arrangment() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "slider";
  const { active_tab } = useParams();
  const { tab } = useParams();

  useEffect(() => {
    if (active_tab === "arrangement") {
      navigate(`/admin/settings/arrangement/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/settings/arrangement/${tab.href}/1`);
    }
  };

  return (
    <div>
      <div className="sm:block">
        <nav
          className="-mb-px flex space-x-8 overflow-x-auto"
          aria-label="Tabs"
        >
          {tabs.map((singleTab) => (
            <button
              key={singleTab.name}
              onClick={() => {
                toggle(singleTab);
              }}
              className={classNames(
                singleTab.href === tab
                  ? "border-primary text-primary font-bold"
                  : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
              )}
              aria-current={singleTab.href === tab ? "page" : undefined}
            >
              {singleTab.name}
            </button>
          ))}
        </nav>
      </div>

      <div>
        {tab === "restaurant" && <RestaurantsArrangement />}
        {tab === "store" && <StoreArrangement />}
        {tab === "slider" && <SlidersArrangement />}
        {tab === "packages" && <PackageArrangement />}
        {tab === "feature" && <AllFeaturedProducts />}
      </div>
    </div>
  );
}
