import React, { useState, useEffect } from "react";
import { Dialog } from "@headlessui/react";
// import Cookies from "js-cookie";
import LEFT from "../../../../assets/images/icons/arrow-left.svg";
import ChooseRestaurant from "./chooseRestaurant";
import ChooseStore from "./chooseStore";
import axios from "../../../../plugins/axios.plugin";

const tabs = [
  { name: "Restaurants", href: "restaurants" },
  { name: "Stores", href: "stores" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function FormSection4({ formWizardRef, props }) {
  const [active_tab, setActiveTab] = useState("restaurants");
  const toggle = (tab) => {
    if (active_tab.href !== tab) {
      setActiveTab(tab.href);
    }
  };

  useEffect(() => {
    axios.defaults.headers.common["Team"] = `${formWizardRef?.team}`;
  }, [formWizardRef?.team]);

  return (
    <Dialog.Panel className="relative sm:w-[708px] h-[877px] bg-white rounded-3xl px-4 pt-5 pb-4 text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8  w-full sm:p-8">
      <div className=" sm:block absolute top-2 pt-4 pr-4">
        <button
          type="button"
          className="bg-[#ffeae5] rounded-md p-2 sm:p-3 "
          onClick={() => formWizardRef.current.goTo(0)}
        >
          <span className="sr-only">Close</span>
          <img src={LEFT} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>
      <div className="sm:flex sm:items-start">
        <div className="mt-16 md:mt-20 sm:mt-10 text-left max-w-[358px]">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-[20px]"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>
      <div className="mt-12 sm:mt-8">
        {active_tab === "restaurants" && (
          <ChooseRestaurant
            formWizardRef={formWizardRef}
            team={formWizardRef?.team}
          />
        )}
        {active_tab === "stores" && (
          <ChooseStore
            formWizardRef={formWizardRef}
            team={formWizardRef?.team}
          />
        )}
      </div>
    </Dialog.Panel>
  );
}
