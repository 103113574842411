import axios from "../plugins/axios.plugin";

class UserService {
  baseServicePath = "/api/user";

  getAllUsers(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAllUsersNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getAUser(id) {
    return axios.get(`${this.baseServicePath}/show?user_id=${id}&properties=1`);
  }

  deleteAUser(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  searchUsers(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  filterUsers(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}&properties=1`,
      payload
    );
  }

  getUserWithRelation(payload, page) {
    return axios.post(
      `${this.baseServicePath}/relation?properties=1&page=${page}`,
      payload
    );
  }

  editAUser(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  showUserRoles(user_id) {
    return axios.get(`${this.baseServicePath}/roles?user_id=${user_id}`);
  }
}

export const userService = new UserService();
