import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import CLOSE from "../../../assets/images/icons/close.svg";
import { searchAllStores } from "../../../redux/stores/storeActions";
import storeType from "../../../redux/stores/storeTypes";
import merchandiseType from "../../../redux/merchandise/merchandiseTypes";
import {
  getSingleMerchandise,
  searchAllMerchandises,
} from "./../../../redux/merchandise/merchandiseActions";
import AddPackageSkeleton from "../../skeleton/addPackage";

export default function AddStoreOrderModal({
  submitted,
  data,
  setData,
  displayData,
  setDisplayData,
}) {
  const dispatch = useDispatch();
  let { searchStore, searching } = useSelector((state) => state.store);
  let { searchMerch, SingleMerchandise } = useSelector(
    (state) => state.merchandise
  );

  const [errorMsg, setErrorMsg] = useState();
  const [display, setDisplay] = useState({});
  const [outlet_type, setOutletType] = useState({ product_outlet_type: "" });
  const [searchValue, setSearchValue] = useState({ search: "" });
  const [attributeFields, setAttributeFields] = useState([
    { attribute_id: "", quantity: "" },
  ]);

  const [itemData, setItemData] = useState({
    product_outlet_type: "store",
    product_outlet_id: "",
    product_outlet_name: "",
    product_name: "",
    product_id: "",
    quantity: "",
    attributes: [],
  });

  // eslint-disable-next-line
  const { search } = searchValue;
  // eslint-disable-next-line
  const { product_id, product_outlet_id, product_outlet_name, product_name } =
    itemData;
  const { business_id, store_id, order_items } = data;
  const [open, setOpen] = useState(false);
  function closeModalTwo() {
    setOpen(false);
  }
  function openModalTwo() {
    setOpen(true);
  }
  const handleSearchStore = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setItemData((itemData) => ({
      ...itemData,
      product_outlet_name: value,
    }));
    setSearchValue((searchValue) => ({ ...searchValue, [name]: value }));
    if (value.length >= 3) {
      dispatch(
        searchAllStores({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      searchStore = { data: [] };
      dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
    }
  };

  const handleSearchMerch = (event) => {
    event.preventDefault();
    const { name, value } = event.target;
    setItemData((itemData) => ({
      ...itemData,
      product_name: value,
    }));
    setSearchValue((searchValue) => ({ ...searchValue, [name]: value }));
    if (value.length >= 3) {
      dispatch(
        searchAllMerchandises({
          search: value,
          store_id: store_id,
          business_id: business_id,
        })
      );
    } else if (value.length <= 3) {
      searchMerch = { data: [] };
      dispatch({
        type: merchandiseType["SEARCH_MERCHANDISE"],
        payLoad: searchMerch,
      });
    }
  };

  function fillStore(result) {
    setData((data) => ({
      ...data,
      store_id: result.id,
      business_id: result.business_id,
      store_name: result.store_name,
    }));
    setItemData((itemData) => ({
      ...itemData,
      product_outlet_name: result.store_name,
    }));
    var mydiv = document.getElementById(result.id);
    if (result.id) {
      mydiv.style.borderColor = "border-[#D14522]";
    }

    setSearchValue((searchValue) => ({
      search: result.store_name,
    }));

    searchStore = { data: [] };
    dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
  }

  function fillMerch(merch) {
    setData((data) => ({
      ...data,
      merchandise_name: merch.merchandise_name,
    }));

    setDisplay((display) => ({
      ...display,
      quantity: "1",
      merchandise_name: merch.merchandise_name,
      merchandise_amount: merch?.merchandise_amount,
    }));
    var mydiv = document.getElementById(merch.id);
    if (merch.id) {
      mydiv.style.borderColor = "border-[#D14522]";
    }

    setSearchValue((searchValue) => ({
      search: merch.merchandise_attributes,
    }));
    setItemData({
      product_outlet_type: "store",
      product_outlet_id: merch.store_id,
      product_id: merch.id,
      product_name: merch.merchandise_name,
      quantity: "1",
    });
    searchMerch = { data: [] };
    dispatch({
      type: merchandiseType["SEARCH_MERCHANDISE"],
      payLoad: searchMerch,
    });
    if (merch?.merchandise_attributes) {
      dispatch(getSingleMerchandise(merch?.id));
    }
  }

  const handleFormChange = (index, event) => {
    let data = [...attributeFields];
    data[index][event.target.name] = event.target.value;
    setAttributeFields(data);
  };

  const addAttributeFields = () => {
    let newfield = { attribute_id: "", quantity: "" };
    setAttributeFields([...attributeFields, newfield]);
  };
  const handleSubmit = () => {
    //Check if a store and a Merchandise has been chosen if yes
    //begin the process to add chosen items to the parent array
    //else show an error to prompt user to add item
    if ((product_id, product_outlet_id)) {
      setErrorMsg(false);
      let newInput;
      // let newInput = {
      //   ...itemData,
      //   attributes: attributeFields ? attributeFields : [],
      // };
      function removeEmptyAttributes(attr) {
        return attr.filter((obj) => obj.attribute_id !== "");
      }
      const filteredAttr = removeEmptyAttributes(attributeFields);
      newInput = {
        ...itemData,
        attributes: filteredAttr,
      };
      if (product_id !== "" && outlet_type !== "") {
        order_items?.push(newInput);
      }
      setData((data) => ({
        ...data,
        order_items: order_items,
      }));

      //Generate the displayed attributes that include name and price to be set
      //to the display arr and displayed for uses to see
      let merchAttr = SingleMerchandise?.attributes?.map((e, i) => {
        let temp = attributeFields.find(
          (element) => Number(element.attribute_id) === Number(e.id)
        );
        if (temp) {
          temp.name = e.name;
          temp.amount = e.amount;
        }
        return temp;
      });

      setDisplayData((displayData) => [
        ...displayData,
        { ...display, attributes: attributeFields ? merchAttr : [] },
      ]);
      setItemData({
        product_outlet_type: "store",
        product_outlet_id: "",
        product_id: "",
        quantity: "1",
        attributes: [],
        merchandise_name: "",
        merchandise_amount: "",
        product_outlet_name: "",
        product_name: "",
      });
      setAttributeFields([{ attribute_id: "", quantity: "" }]);
      dispatch({ type: merchandiseType["SHOW_A_MERCHANDISE"], payLoad: [] });
      closeModalTwo();
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div className="Coupon" data-test="couponModal">
      <div className=" flex justify-center text-center">
        <button className="text-primary" onClick={openModalTwo}>
          Add New Item +
        </button>
      </div>

      <Transition appear show={open}>
        <Dialog as="div" className="relative z-10" onClose={closeModalTwo}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[380px] text-left sm:w-[630px] p-4 sm:p-6">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-sm p-1 sm:p-3 "
                        onClick={closeModalTwo}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>

                    <div className="pb-5 text-left sm:mt-2 sm:text-left border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="p"
                        className="text-base font-meduim text-gray-500 "
                      >
                        Add New Item
                      </Dialog.Title>
                    </div>
                  </div>
                  <form className="py-5 mt-4 px-5">
                    <div>
                      <Input.Select
                        title={"Select Outlet type"}
                        name={"product_outlet_type"}
                        htmlFor={"product_outlet_type"}
                        onChange={(e) => {
                          setOutletType("store");
                        }}
                      >
                        <option value="store">Store</option>
                      </Input.Select>
                    </div>
                    <div className="mt-2">
                      <label className="block text-sm font-medium text-[#151515]">
                        Store Name
                      </label>
                      <input
                        type={"search"}
                        name={"store_id"}
                        htmlFor={"store_id"}
                        onChange={handleSearchStore}
                        value={product_outlet_name}
                        autoComplete="off"
                        className={
                          "mt-2 block pl-3 pr-10 w-full text-base bg-gray-100 focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                        }
                        placeholder={"Search store by name, phone, email..."}
                      />
                      {(submitted || errorMsg) && !product_outlet_id && (
                        <div className="text-primary text-xs ">
                          store is required
                        </div>
                      )}
                      {searching ? (
                        <div className="absolute w-full bg-white shadow top-[300px]">
                          <div className="p-3">
                            <AddPackageSkeleton />
                          </div>
                        </div>
                      ) : (
                        searchStore &&
                        Array.isArray(searchStore) &&
                        searchStore?.map((result, index) => (
                          <div
                            className="p-3 mb-2 bg-white border"
                            id={result.id}
                            key={index}
                            onClick={() => fillStore(result)}
                          >
                            <div>
                              <div>
                                <h6>Name: {result.store_name}</h6>
                              </div>
                              <div>
                                <h6>Phone: {result.store_phone}</h6>
                              </div>
                            </div>
                          </div>
                        ))
                      )}
                    </div>

                    <div className="mt-2">
                      <label className="block text-sm font-medium text-[#151515]">
                        Merchandise Name
                      </label>
                      <input
                        type={"search"}
                        name={"product_id"}
                        htmlFor={"product_id"}
                        onChange={handleSearchMerch}
                        autoComplete="off"
                        value={product_name}
                        className={
                          "mt-2 block pl-3 pr-10 w-full text-base bg-gray-100 focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                        }
                        placeholder={"Search merchandise by name "}
                      />
                      {submitted && !product_id && (
                        <div className="text-primary text-xs ">
                          merchandise is required
                        </div>
                      )}
                    </div>

                    <p className="text-sm mb-5">
                      Search and add a reciepient merchandise, only merchandise
                      registered under{" "}
                      <span className="text-primary">
                        {data.store_name ?? "Selected Store"}
                      </span>{" "}
                      will show here.
                    </p>
                    {searchMerch &&
                      Array.isArray(searchMerch) &&
                      searchMerch?.map((merch, index) => (
                        <div
                          className="p-3 border border-primary  mb-2"
                          id={merch.id}
                          key={index}
                          onClick={() => fillMerch(merch)}
                        >
                          <div className=" grid-cols-2">
                            <div className="col-span-1">
                              <div className="">
                                <h6>Name: {merch.merchandise_name}</h6>
                              </div>
                            </div>
                            <div className="col-span-1">
                              <div>
                                <h6>
                                  Category: {merch.merchandise_categories}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    {SingleMerchandise?.attributes.length > 0 && (
                      <>
                        {attributeFields.map((input, index) => (
                          <div className="grid grid-cols-2 gap-8" key={index}>
                            <Input.Select
                              title={"Attribute"}
                              name="attribute_id"
                              id="attribute_id"
                              value={Input.attribute_id}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                            >
                              <option>Select attribute</option>
                              {SingleMerchandise?.attributes?.map(
                                (item, index) => (
                                  <option key={index} value={item.id}>
                                    {item.name}
                                  </option>
                                )
                              )}
                            </Input.Select>
                            <Input.Number
                              title={"Quantity"}
                              name="quantity"
                              id="quantity"
                              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                              placeholder="Enter quantity"
                              defaultValue={1}
                              min={1}
                              value={input.quantity}
                              onChange={(event) =>
                                handleFormChange(index, event)
                              }
                            />
                          </div>
                        ))}
                        {SingleMerchandise?.attributes?.length > 1 && (
                          <div className="w-full flex justify-end">
                            <button
                              type="button"
                              onClick={addAttributeFields}
                              className="text-[#CE2600B2]"
                            >
                              Add more +
                            </button>
                          </div>
                        )}
                      </>
                    )}
                  </form>

                  <div className="py-8 max-w-sm mx-auto flex justify-center text-center">
                    <Button.Primary
                      title={"Add Item"}
                      className="px-4 rounded-sm"
                      onClick={handleSubmit}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
