import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../assets/images/icons/close.svg";
import Button from "../buttons/buttons";
import Input from "../inputs/inputs";
import { Beat } from "../../plugins/spinners.plugin";
import { getAllShippers } from "./../../redux/shippers/shippersActions";
import { shipRestaurantOrderAction } from "../../redux/restaurantOrder/restaurantOrderActions";
import { shipStoreOrder } from "../../redux/storeOrders/storeOrderAction";
import { shipSubscriptionFoodOrder } from "../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderAction";

export function Assignshipping({ order_id, order_type }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [input, setInput] = useState({ id: order_id, shipper_id: "" });
  const { shipper_id } = input;
  const { allShippers } = useSelector((state) => state.shipper);
  const { shipping } = useSelector((state) => state.restaurantOrder);
  // const { shipping } = useSelector((state) => state.storeOrder);
  // const { shipping } = useSelector(
  //   (state) => state.packageSubscriptionFoodOrder
  // );

  useEffect(() => {
    dispatch(getAllShippers());
  }, [dispatch]);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const selected = allShippers?.data?.filter(function (shipper) {
    return shipper.shipper_selected === true;
  });

  function assign() {
    let dataToMap = selected;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((shipper, i) => {
        let singleRowArray = [
          <option value={shipper.id}>{shipper.name}</option>,
        ];
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  const handleSubmit = async () => {
    try {
      if (order_type === "restaurant") {
        let response = await dispatch(
          shipRestaurantOrderAction({ ...input, id: order_id })
        );
        if (response.status === "success") {
          closeModal();
        }
      } else if (order_type === "store") {
        let response = await dispatch(
          shipStoreOrder({ ...input, id: order_id })
        );
        if (response.status === "success") {
          closeModal();
        }
      } else if (order_type === "package-food") {
        let response = await dispatch(
          shipSubscriptionFoodOrder({ ...input, id: order_id })
        );
        if (response.status === "success") {
          closeModal();
        }
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <>
      <Button.Green
        type={"button"}
        title={"Ship Order"}
        className="w-28"
        onClick={openModal}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[358px] sm:w-[629px] sm:h-[649px] bg-white rounded-sm py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8">
                  <div>
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md  p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img src={CLOSE} alt="close" className=" w-3 h-3" />
                      </button>
                    </div>
                    <div className="sm:flex">
                      <div className="text-left sm:text-left mt-2 sm:px-8">
                        <Dialog.Title
                          as="p"
                          className="text-[22px] font-meduim text-[#717485]"
                        >
                          Select Shipping Company
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  <div className=" border-b-2 mt-4"></div>
                  <div className="mt-5 sm:mt-7 sm:mx-auto flex justify-center p-4">
                    <form>
                      <Input.Select
                        title={"Shipping Company"}
                        name="shipper_id"
                        id="shipper_id"
                        htmlFor={"shipper_id"}
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm sm:w-[509px]"
                        onChange={handleChange}
                      >
                        <option>Select Shipping company</option>
                        {assign()}
                      </Input.Select>
                      <div className="flex justify-center mt-20">
                        <Button.Primary
                          type={"button"}
                          title={
                            shipping ? <Beat color={"#FFFFFF"} /> : "Ship Order"
                          }
                          disabled={shipping || !shipper_id}
                          className="w-28 rounded-sm"
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
