import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import RestaurantLayout from "../../../../layouts/RestaurantLayout";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { useNavigate } from "react-router-dom";
import { LeftColumn } from "../../../../layouts/RestaurantLayout";
import { showApplication } from "../../../../redux/applicationSettings/applicationAction";
import { getAPublicPackageAction } from "../../../../redux/publicPackages/publicPackagesActions";

function replaceUnderscoresWithSpaces(inputString) {
  return inputString.replace(/_/g, " ");
}

export default function CustomisePlan() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { id } = useParams();

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [input, setInput] = useState({
    package_delivery_period: {
      start_time: "",
      end_time: "",
    },
  });
  const { package_delivery_period } = input;

  const disabled =
    !startDate ||
    !endDate ||
    !package_delivery_period?.start_time ||
    !package_delivery_period?.end_time;

  const { singlePackage } = useSelector((state) => state.publicPackages);
  const { settings } = useSelector((state) => state.application);

  useEffect(() => {
    dispatch(showApplication());
    dispatch(getAPublicPackageAction(id));
    // eslint-disable-next-line
  }, []);

  function numberWithCommas(x) {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const handleChange = (e) => {
    const { value } = e.target;
    let timeRange = settings?.package_delivery_periods[value];

    setInput((prevInput) => ({
      ...prevInput,
      package_delivery_period: {
        ...prevInput.package_delivery_period,
        start_time: timeRange?.start_time,
        end_time: timeRange?.end_time,
      },
    }));
  };

  return (
    <RestaurantLayout restaurant={singlePackage}>
      <LeftColumn>
        {package_delivery_period?.start_time &&
          package_delivery_period?.end_time && (
            <p className="text-primary-500">
              Note: This package will be delivered between{" "}
              {package_delivery_period?.start_time} -{" "}
              {package_delivery_period?.end_time}
            </p>
          )}
        <h2 className="text-3xl font-bold border-b-2 mb-3 pb-3">
          Customise your plan
        </h2>
        <section aria-labelledby="section-1-title">
          <h2 className="sr-only" id="section-1-title">
            Restaurant Plans
          </h2>
          <div className="mt-6">
            {/* Your content */}
            <div className="grid grid-cols-2 gap-8">
              <div>
                <label className="text-sm text-[#151515] mb-2 block">
                  Package Name:
                </label>
                <p>{singlePackage?.package_name}</p>
              </div>
              <div>
                <label className="text-sm text-[#151515] mb-2 block">
                  Average Package Price:
                </label>
                <p>
                  NGN {""}
                  {numberWithCommas(singlePackage?.package_average_amount)}
                </p>
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8">
              <Input.DatePicker
                title={"Start Date"}
                placeholder={"Select start date"}
                className={" w-full border rounded"}
                min={new Date().toISOString().split("T")[0]}
                max={singlePackage?.package_end_date}
                onChange={(e) => setStartDate(e.target.value)}
              />
              <Input.DatePicker
                title={"End Date"}
                placeholder={"Select end date"}
                className={" w-full border rounded"}
                min={new Date().toISOString().split("T")[0]}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </div>
            <div className="mt-12 grid grid-cols-1">
              <Input.Select
                title={"Set Delivery Time:"}
                onChange={handleChange}
              >
                <option>Select delivery time</option>
                {settings?.package_delivery_periods &&
                  Object.keys(settings?.package_delivery_periods).map(
                    (key, index) =>
                      settings?.package_delivery_periods[key]?.status ===
                        "available" && (
                        <option key={index} value={key}>
                          {replaceUnderscoresWithSpaces(key)}
                        </option>
                      )
                  )}
              </Input.Select>
            </div>
          </div>

          <div className="mt-8 text-right">
            <Button.Primary
              disabled={disabled}
              title={"Continue"}
              className={
                disabled ? "bg-gray-300 px-5 cursor-not-allowed" : "px-5"
              }
              onClick={() =>
                navigate(`/food-package/customise/${id}/meals`, {
                  state: {
                    startDate: startDate,
                    endDate: endDate,
                    package_delivery_period: package_delivery_period,
                  },
                })
              }
            />
          </div>
        </section>
      </LeftColumn>
    </RestaurantLayout>
  );
}
