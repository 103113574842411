import authType from "./publicCatalogueTypes";

const initialState = {
  isLoading: false,
  allCatalogues: {},
  singleCatalogue: {},
};

export default function publicCatalogueReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_CATALOGUE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.ALL_CATALOGUES:
      return {
        ...state,
        allCatalogues: action.payLoad?.data,
      };

    case authType.SINGLE_CATALOGUE:
      return {
        ...state,
        singleCatalogue: action.payLoad?.data,
      };

    default:
      return state;
  }
}
