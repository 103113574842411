import paymentType from "./paymentTypes";

const initialState = {
  isLoading: false,
  filtering: false,
  searching: false,
  downloading: false,
  verifying: false,
  allProviders: {},
  myPayments: {},
  pay_for_me: {},
  singlePayment: {},
  allPayments: {},
};

export default function paymentReducer(state = initialState, action) {
  switch (action.type) {
    case paymentType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case paymentType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case paymentType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };
    case paymentType.FILTERING_PAYMENT:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case paymentType.VERIFYING:
      return {
        ...state,
        verifying: action.payLoad,
      };

    case paymentType.ALL_PAYMENT_PROVIDERS:
      return {
        ...state,
        allProviders: action.payLoad?.data,
      };

    case paymentType.MY_PAYMENTS:
      return {
        ...state,
        myPayments: action.payLoad?.data,
      };

    case paymentType.PAY_FOR_ME:
      return {
        ...state,
        pay_for_me: action.payLoad,
      };

    case paymentType.SINGLE_PAYMENT:
      return {
        ...state,
        singlePayment: action.payLoad?.data,
      };

    case paymentType.FILTER_PAYMENT:
      return {
        ...state,
        allPayments: action.payLoad?.data,
      };

    default:
      return state;
  }
}
