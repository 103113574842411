import cartType from "./publicCartType";

const initialState = {
  isLoading: false,
  allCarts: {},
};

export default function publicCartReducer(state = initialState, action) {
  switch (action.type) {
    case cartType.LOADING_CARTS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case cartType.ALL_CARTS:
      return {
        ...state,
        allCarts: action.payLoad?.data,
      };

    default:
      return state;
  }
}
