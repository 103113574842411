import React from "react";
import { useParams } from "react-router-dom";
import Sliders from "./sliders/Sliders";
import RateSettings from "./rates";
import Arrangment from "./arrangment/Arrangement";
import SettingsCategories from "./categories/SettingsCategories";
import SettingsTag from "./tags/SettingsTag";
import AllRoles from "./roles/AllRoles";
import Application from "./applicationSettings";
// import ApplicationSettings from "./applicationSettings/application";
// import ApplicationSettings from "./application";
import EnhancedApplicationSettings from "./applicationSettings/enhancedApplication";
// import EnhancedApplicationSettings from "./enhancedApplication";
import UserRoles from "./userRoles";
import Feedback from "./feedback/Feedback";
import BroadcastNotifications from "./broadcast";
import KYC from "./kyc";

export default function Settings() {
  const { active_tab } = useParams();

  return (
    <div data-test="settings-page">
      <div className="mt-4">
        {active_tab === "sliders" && <Sliders />}
        {active_tab === "categories" && <SettingsCategories />}
        {active_tab === "tags" && <SettingsTag />}
        {active_tab === "rate" && <RateSettings />}
        {active_tab === "arrangement" && <Arrangment />}
        {active_tab === "roles" && <AllRoles />}
        {active_tab === "application" && <Application />}
        {active_tab === "enhanced-application" && (
          <EnhancedApplicationSettings />
        )}
        {active_tab === "user-roles" && <UserRoles />}
        {active_tab === "feedback" && <Feedback />}
        {active_tab === "broadcast-notification" && <BroadcastNotifications />}
        {active_tab === "kyc-verification" && <KYC />}
      </div>
    </div>
  );
}
