import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import { statisticService } from "../../services/statistics.service";
import cartType from "./statisticsTypes";

/**
 * GetBusinessStatistics
 *
 */
export const getBusinessStatistics = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: true,
    });
    const response = await statisticService.getBusinessStatistics(payLoad);
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    dispatch({
      type: cartType["GET_BUSINESS_STATISTICS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * GetUserStatistics
 *
 */
export const getUserStatistics = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: true,
    });
    const response = await statisticService.getUserStatistics(payLoad);
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    dispatch({
      type: cartType["GET_USER_STATISTICS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * GetGeneralStatistics
 *
 */
export const getGeneralStatistics = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: true,
    });
    const response = await statisticService.getGeneralStatistics(payLoad);
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    dispatch({
      type: cartType["GET_GENERAL_STATISTICS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * GetStoreStatistics
 *
 */
export const getStoreStatistics = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: true,
    });
    const response = await statisticService.getStoreStatistics(payLoad);
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    dispatch({
      type: cartType["GET_STORE_STATISTICS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * GetRestaurantStatistics
 *
 */
export const getRestaurantStatistics = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: true,
    });
    const response = await statisticService.getRestaurantStatistics(payLoad);
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    dispatch({
      type: cartType["GET_RESTAURANT_STATISTICS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: cartType["LOADING_STATS"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
