import React, { useMemo, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/food-package.svg";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterPackageItemDropDown from "../../../../components/dropDown/filter/filterPackageItem";
import { searchSubscriptionFoodAction } from "../../../../redux/subscriptionFood/subscriptionFoodActions";
import {
  filterSubscriptionFoodAction,
  getAllSubscriptionFoodNoPagination,
} from "../../../../redux/subscriptionFood/subscriptionFoodActions";
import subscriptionType from "../../../../redux/subscriptionFood/subscriptionFoodTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Items() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  let { isLoading, searching, filtering } = useSelector(
    (state) => state.subscriptionFood
  );
  let { allSubscriptionFoods, downloading } = useSelector(
    (state) => state.subscriptionFood
  );
  const [input, setInput] = useState({
    search: "",
  });

  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 60);

  const [payLoad] = useState({
    // start_date: lastMonth,
    end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterSubscriptionFoodAction(payLoad, page));
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((food, index) => {
        let show = {
          id: index + 1,
          price: <span>NGN {numberWithCommas(food?.food_amount)}</span>,
          name: food?.food_name,
          status: food?.food_operating_status,
          created: (
            <span>{moment(food?.created_at).format("MMM D, YYYY")}</span>
          ),
          packageItem: food,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((food, index) => {
        let show = {
          index: index + 1,
          price: food?.food_amount,
          name: food?.food_name,
          status: food?.food_operating_status,
          created: moment(food?.created_at).format("MMM D, YYYY"),
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allSubscriptionFoods?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubscriptionFoods]);

  function goToSinglePage(params) {
    let id = params?.show?.packageItem?.id;
    navigate(`/admin/food-packages/item/${id}`);
  }
  function goToCreatePage() {
    navigate(`/admin/food-packages/item/create`);
  }

  const handleDownload = async () => {
    const response = await dispatch(
      getAllSubscriptionFoodNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Food_Package_Items.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allSubscriptionFoods?.data);
      setData(dataSet);
      dispatch({
        type: subscriptionType["SEARCH_FOOD_SUBSCRIPTIONS"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchSubscriptionFoodAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Item",
            accessor: "show.name",
          },
          {
            Header: "Price",
            accessor: "show.price",
          },

          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Date Created",
            accessor: "show.created",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="items-page">
      <TableFilter
        title={"Food Package Items"}
        results={allSubscriptionFoods?.to}
        totalResults={allSubscriptionFoods?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder={"Item Name, Restuarant, or Store"}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterPackageItemDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Primary
          title={"+ Create New Food Package Item"}
          onClick={goToCreatePage}
        />
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          disabled={!data}
          type="button"
          onClick={handleDownload}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-3 sm:px-8">
        {isLoading || searching || filtering ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Food Packages Items</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You have not added any food package items yet.
            </p>
          </div>
        )}
      </div>

      <Pagination
        data={allSubscriptionFoods}
        route={"admin/food-packages/items"}
      />
    </div>
  );
}
