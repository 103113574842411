import React, { useMemo } from "react";
import Table from "../../../../components/tables/tableCols1";

// sample data array looks like this
const data = [
  {
    show: {
      id: "001",
      food: "Catfish Peppersoup",
      location: "Enugu",
      phone: "090989796948",
      approval: "Approved",
      restaurant: "Breeze Bar",
    },
  },
  {
    show: {
      id: "001",
      food: "Catfish Peppersoup",
      location: "Enugu",
      phone: "090989796948",
      approval: "Approved",
      restaurant: "Breeze Bar",
    },
  },
  {
    show: {
      id: "001",
      food: "Catfish Peppersoup",
      location: "Enugu",
      phone: "090989796948",
      approval: "Approved",
      restaurant: "Breeze Bar",
    },
  },
  {
    show: {
      id: "001",
      food: "Catfish Peppersoup",
      location: "Enugu",
      phone: "090989796948",
      approval: "Approved",
      restaurant: "Breeze Bar",
    },
  },
  {
    show: {
      id: "001",
      food: "Catfish Peppersoup",
      location: "Enugu",
      phone: "090989796948",
      approval: "Approved",
      restaurant: "Breeze Bar",
    },
  },
];

export default function SubscriberDetail() {
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Food Name",
            accessor: "show.food",
          },
          {
            Header: "Location",
            accessor: "show.location",
          },
          {
            Header: "Amount",
            accessor: "show.approval",
          },
          {
            Header: "Restuarant Name",
            accessor: "show.restaurant",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="subscriberDetails-page">
      <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Subscriber Details
        </h1>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Subscriber Details</h3>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Subscriber</label>
            <p className="text-[#151515] text-sm">Noble</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Days</label>
            <p className="text-[#151515] text-sm">5</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Cap</label>
            <p className="text-[#151515] text-sm">₦ 2,000.00</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">Mar 8, 2020</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Expires</label>
            <p className="text-[#151515] text-sm">Mar 8, 2021</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Package Details</h3>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">Breakfast Extra</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Amount</label>
            <p className="text-[#151515] text-sm"> ₦8,000.00</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Paid</label>
            <p className="text-[#151515] text-sm">Yes</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] text-sm">Active</p>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-md py-4 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">User Subscription History</h3>
        </div>
        <Table
          columns={columns}
          data={data}
          className="w-full"
          rowClass="hover:shadow-md cursor-pointer"
        />
      </div>
    </div>
  );
}
