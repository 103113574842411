import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../../../../components/buttons/buttons";
import Card from "../../../../../../components/cards/admin/cards";
import Table from "../../../../../../components/tables/tableCols1";
import { Beat } from "../../../../../../plugins/spinners.plugin";
import TableSkeleton from "../../../../../../components/skeleton/table";
import { Pagination } from "../../../../../../components/pagination/pagination";
import EmptyState from "../../../../../../assets/images/emptyState/food-package.svg";
import { ReactComponent as COPY } from "../../../../../../assets/images/icons/copy1.svg";
import { AllFiltersModal } from "../../../../../../components/modals/allFiltersModal";
import { writeFileWithXLSX } from "../../../../../../components/exports/xlsx";
import FilterMerchandisePackageOrdersDropDown from "../../../../../../components/dropDown/filter/filterMerchandisePackageOrders";
import {
  filterSubscriptionMerchandiseOrder,
  searchSubscriptionMerchandiseOrder,
  getAllSubscriptionMerchandiseOrdersNoPagination,
} from "../../../../../../redux/packageSubscriptionMerchandiseOrder/packageSubscriptionMerchandiseOrderAction";
import packageMerchType from "../../../../../../redux/packageSubscriptionMerchandiseOrder/packageSubscriptionMerchandiseOrderTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function concatId(id) {
  if (typeof id === "string") {
    return id?.slice(0, 8) + "...." + id?.slice(id?.length - 8);
  }
}

export default function Unshipped() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page, id } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    status: "unshipped",
    // start_date: lastMonth,
    // end_date: new Date(),
    user_id: id,
  });

  const { allPackageOrders, downloading, filtering, searching } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );

  useEffect(() => {
    dispatch(filterSubscriptionMerchandiseOrder(payLoad, page));
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((order, index) => {
        let show = {
          id: (
            <span className="flex items-center">
              #{concatId(order?.id)}
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          status: (
            <span>
              {order?.order_paid ? (
                <span className="text-[#00D220] font-bold">Paid</span>
              ) : (
                <span className="text-primary font-bold">Unpaid</span>
              )}
            </span>
          ),
          customer_name: order?.recipient_name,
          customer_phone: order?.recipient_phone,
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((order, index) => {
        let show = {
          id: order?.id,
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: order?.order_amount,
          status: order?.order_paid ? "Paid" : "Unpaid",
          customer_name: order?.recipient_name,
          customer_phone: order?.recipient_phone,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allPackageOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPackageOrders]);

  function goToSinglePage(params) {
    let id = params?.show?.orderData?.id;
    navigate(`/admin/orders/package-merchandise/${id}`, {
      state: { merchandise: params?.show?.orderData },
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allPackageOrders?.data);
      setData(dataSet);
      dispatch({
        type: packageMerchType["SEARCH_PACKAGE"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchSubscriptionMerchandiseOrder(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllSubscriptionMerchandiseOrdersNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "UsersReport_Merchandise_Package_Unshipped.xlsx",
        download: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Payment Status",
            accessor: "show.status",
          },
          {
            Header: "Customer Name",
            accessor: "show.customer_name",
          },
          {
            Header: "Customer Phone",
            accessor: "show.customer_phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="orders-page">
      <Card.TableFilter
        title={"Merchandise Package Orders"}
        results={allPackageOrders?.to}
        totalResults={allPackageOrders?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        searchPlaceholder="Order ID or Phone Number"
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterMerchandisePackageOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          disabled={downloading || !data}
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-8">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Merchandise Packages</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You have not added any merchandise package yet.
            </p>
          </div>
        )}
      </div>

      <Pagination />
    </div>
  );
}
