import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import storeType from "./storeTypes";
import { storeService } from "./../../services/store.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * CREATE STORE
 * 
 * @param {object} payLoad {
  Store_email:"bluehills@gmail.com",
  Store_name:"blue hills",
  Store_phone:"07034738829",
  Store_address:"12 victoria island lane",
  Store_city:"lagos",
  Store_state:"Lagos",
  Store_country:"Nigeria",
  }
 * @returns {void}
 */
export const createAStore = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: true });
    const response = await storeService.CreateAStore({
      ...payLoad,
    });
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["CREATE_STORE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve a Store
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const approveStore = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["APPROVING"], payLoad: true });
    const response = await storeService.approveStore(payLoad);
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    dispatch({ type: storeType["APPROVE_STORE"], payLoad: false });
    // successHandler(response, true);
    response &&
      toast.success("Store was published", {
        position: toast.POSITION.TOP_RIGHT,
        theme: "colored",
      });
    return true;
  } catch (error) {
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * unApprove a Store
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const unApproveStore = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["APPROVING"], payLoad: true });
    const response = await storeService.unApproveStore(payLoad);
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    dispatch({ type: storeType["UNAPPROVE_STORE"], payLoad: false });
    // successHandler(response, true);
    toast.success("Store was unpublished", {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
    return response;
  } catch (error) {
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const approveStoreByManagement = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["APPROVING"], payLoad: true });
    const response = await storeService.approveStoreByManagement(payLoad);
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    dispatch({
      type: storeType["APPROVE_STORE_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * unApprove a Store
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const unApproveStoreByManagement = (payLoad) => async (dispatch) => {
  dispatch({ type: storeType["APPROVING"], payLoad: true });
  try {
    const response = await storeService.unApproveStoreByManagement(payLoad);
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    dispatch({
      type: storeType["UNAPPROVE_STORE_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Get all STORES
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllStores = (page) => async (dispatch) => {
  try {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: true });
    const response = await storeService.getAllStores(page);
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["GET_ALL_STORES"], payLoad: response });
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const getAllStoresNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: true });
    const response = await storeService.getAllStoresNoPage(payLoad);
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["GET_ALL_STORES_NOPAGE"], payLoad: response });
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Filter STORES
 * 
 * @param {object} payLoad {
  Store_email:"bluehills@gmail.com",
  Store_name:"blue hills",
  Store_phone:"07034738829",
  Store_address:"12 victoria island lane",
  Store_city:"lagos",
  Store_state:"Lagos",
  Store_country:"Nigeria",
  }
 * @returns {void}
 */
export const filterStores = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: storeType["FILTERING"], payLoad: true });
    const response = await storeService.filterStores(payLoad, page);
    dispatch({ type: storeType["FILTERING"], payLoad: false });
    dispatch({ type: storeType["FILTER_STORES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: storeType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search all STORES
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const searchAllStores = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["LOADING_SEARCH"], payLoad: true });
    const response = await storeService.searchStore({ ...payLoad });
    dispatch({ type: storeType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: storeType["SEARCH_STORES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: storeType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Show Store
 * @param {object} payLoad {id:"eb2483aa-2c6a-407d-92af-238778668605"}
 * @returns
 */
export const getSingleStore = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: true });
    const response = await storeService.showAStore(payLoad);
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["SHOW_A_STORE"], payLoad: response });
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Show Store
 * @param {object}
 * @returns
 */
export const getMyStores = () => async (dispatch) => {
  try {
    const response = await storeService.showMyStores();
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["GET_MY_STORES"], payLoad: response });
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Edit Store
 * @param {object} payLoad {
     id:"eb2483aa-2c6a-407d-92af-238778668605"
    Store_email:"bluehills@yandex.com",
    Store_name:"bluehills",
    Store_phone:"07034738829",
    Store_gender:"female",
    }
    * @returns {void}
 */

export const editStore = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeType["UPDATING"], payLoad: true });
    const response = await storeService.editStore({
      ...payLoad,
    });
    dispatch({ type: storeType["UPDATING"], payLoad: false });
    dispatch({ type: storeType["EDIT_STORE"], payLoad: payLoad });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeType["UPDATING"], payLoad: false });

    errorHandler(error, true);
  }
};

/**
 * Delete Store
 * @param {object} payLoad {id:"6d380968-233e-4f38-b85c-0040ee94bdd4"}
 * @returns
 */
export const deleteStore = (payLoad) => async (dispatch) => {
  try {
    const response = await storeService.deleteStore(payLoad);
    dispatch({ type: storeType["LOADING_STORES"], payLoad: true });

    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["DELETE_STORE"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });

    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Test Store model
 * @param {void}
 * @returns {void}
 */
export const testStoreModel = () => async (dispatch) => {
  try {
    const response = await storeService;
    dispatch({ type: storeType["LOADING_STORES"], payLoad: true });

    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    dispatch({ type: storeType["TEST_Store_MODEL"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: storeType["LOADING_STORES"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};
