import serviceAreaType from "./serviceAreasTypes";

const initialState = {
  loading: false,
  creating: false,
  deleting: false,
  updating: false,
  searching: false,
  filtering: false,
  allServiceAreas: {},
  storeServiceAreas: {},
};
export default function serviceAreaReducer(state = initialState, action) {
  switch (action.type) {
    case serviceAreaType.GET_ALL_SHIPPERS:
      return {
        ...state,
        allShippers: action.payLoad?.data,
      };
    case serviceAreaType.CREATE_SERVICE_AREA:
      return {
        ...state,
        storeServiceAreas: action.payLoad?.data,
      };
    case serviceAreaType.DELETE_SHIPPERS:
      return {
        ...state,
        deleteShipper: action.payLoad.data.data,
      };

    case serviceAreaType.SHOW_SHIPPERS:
      return {
        ...state,
        shipper: action.payLoad?.data,
      };

    case serviceAreaType.FILTER_SERVICE_AREAS:
      return {
        ...state,
        allServiceAreas: action.payLoad?.data,
      };
    case serviceAreaType.SEARCH_SHIPPERS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case serviceAreaType.LOADING_SHIPPERS:
      return {
        ...state,
        loading: action.payLoad,
      };
    case serviceAreaType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case serviceAreaType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case serviceAreaType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };

    case serviceAreaType.CREATING:
      return {
        ...state,
        creating: action.payLoad,
      };

    case serviceAreaType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    default:
      return state;
  }
}
