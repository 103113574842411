import React, { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Card from "../../../components/cards/admin/cards";
import Table from "../../../components/tables/tableCols1";
import { Pagination } from "../../../components/pagination/pagination";
import Employees from "../../../assets/images/icons/employeesEmpty.svg";
import {
  filterEmployees,
  searchAllEmployees,
  filterEmployeesNoPage,
} from "../../../redux/employee/employeeActions";
import employeeType from "../../../redux/employee/employeeTypes";
import { getOutletFromCookies, getTeamFromCookies } from "../../../utils/Auth";
import TableSkeleton from "./../../../components/skeleton/table";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import FilterEmployee from "../../../components/dropDown/filter/employeeFilter";

export default function UnApprovedEmployees() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const team = getTeamFromCookies();
  const [data, setData] = useState([]);
  let params = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const { allEmployees, filtering, searching } = useSelector(
    (state) => state.employee
  );

  const [input, setInput] = useState({
    search: "",
  });

  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    business_id: team,
    business_approved: 0,
    // start_date: lastMonth,
    // end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterEmployees(payLoad, params.page));
    dispatch(filterEmployeesNoPage(payLoad));
  }, [dispatch, payLoad, params]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((employee, index) => {
        let single = {
          name: employee?.employee_name,
          position: employee?.employee_job_title,

          status:
            employee.status === "active" ? (
              <span className="text-green-400 font-bold">Active</span>
            ) : (
              <span className="text-primary font-bold">Inactive</span>
            ),
          address: employee?.employee_address,
          city: employee?.employee_city,
          phone: employee?.employee_phone,
          employee: employee,
        };
        outputArray.push({ single });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allEmployees?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allEmployees]);
  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((employee, index) => {
        let single = {
          name: employee?.employee_name,
          position: employee?.employee_job_title,
          status: employee.status === "active" ? "active" : "inactive",
          address: employee?.employee_address,
          city: employee?.employee_city,
          phone: employee?.employee_phone,
        };
        outputArray.push(single);
        return true;
      });
      return outputArray;
    }
  }

  const downloadCSV = async () => {
    const response = await dispatch(filterEmployeesNoPage(payLoad));
    if (response) {
      const newData = createTableNoPage(response?.data.data);
      writeFileWithXLSX(newData, {
        filename: "Unapproved employees.xlsx",
        download: true,
      });
    }
  };
  function goToSinglePage(params) {
    let employee_id = params?.single?.employee?.id;
    navigate(
      `/admin/${
        outlet?.store_name || outlet?.restaurant_name
      }/employees/${employee_id}`,
      {
        state: { employee: params?.single?.employee },
      }
    );
  }
  function goToInvitePage() {
    navigate(
      `/admin/${outlet?.store_name || outlet?.restaurant_name}/employees/invite`
    );
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allEmployees?.data);
      setData(dataSet);
      dispatch({
        type: employeeType["SEARCH_EMPLOYEES"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchAllEmployees(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Employee Name",
            accessor: "single.name",
          },
          {
            Header: "Position",
            accessor: "single.position",
          },
          {
            Header: "Status",
            accessor: "single.status",
          },

          {
            Header: "Phone Number",
            accessor: "single.phone",
          },
          {
            Header: "Address",
            accessor: "single.address",
          },

          {
            Header: "City",
            accessor: "single.city",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="">
      <Card.TableFilter
        title={"Employees"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        payLoad={payLoad}
        page={params.page}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterEmployee
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Name, Email & Phone number"}
      >
        <Button.Primary title={"+ Invite Employee"} onClick={goToInvitePage} />
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4">
        {searching || filtering ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={Employees} alt="Employee" />
            </div>
            <p className="font-bold text-xl my-3">Employees</p>
            <p className="text-sm text-gray-500">
              You do not have any Employees yet
            </p>
          </div>
        )}
        <Pagination
          data={allEmployees}
          route={`admin/${
            outlet?.store_name || outlet?.restaurant_name
          }/employees/all`}
        />
      </div>
    </div>
  );
}
