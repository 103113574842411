const ARRANGEMENTS = {
  ADVERTISMENT_ARRANGEMENTS: "ADVERTISMENT_ARRANGEMENTS",
  STORE_ARRANGEMENTS: "STORE_ARRANGEMENTS",
  RESTAURANT_ARRANGEMENTS: "RESTAURANT_ARRANGEMENTS",
  MERCHANDISE_ARRANGEMENTS: "MERCHANDISE_ARRANGEMENTS",
  FOOD_ARRANGEMENTS: "FOOD_ARRANGEMENTS",
  LOADING_ARRANGEMENTS: "LOADING_ARRANGEMENTS",
  FEATURE_ARRANGEMENTS: "FEATURE_ARRANGEMENTS",
  PACKAGE_ARRANGEMENTS: "PACKAGE_ARRANGEMENTS",
  LOADING_STORE_ARRANGEMENTS: "LOADING_STORE_ARRANGEMENTS",
};

export default ARRANGEMENTS;
