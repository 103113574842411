const INVITES = {
  GET_ALL_INVITES: "GET_ALL_INVITES",
  FILTER_INVITES: "FILTER_INVITES",
  SEARCH_INVITES: "SEARCH_INVITES",
  STORE_INVITE: "STORE_INVITE",
  SHOW_INVITE: "SHOW_INVITE",
  SHOW_MY_INVITE: "SHOW_MY_INVITE",
  EDIT_INVITE: "EDIT_INVITE",
  REFUSE_INVITE: "REFUSE_INVITE",
  DELETE_INVITE: "DELETE_INVITE",
  LOADING_INVITE: "LOADING_INVITES",
};

export default INVITES;
