import axios from "../plugins/axios.plugin";

class FeedbackService {
  baseServicePath = "/api/feedback";

  getAllFeedback(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  getSingleFeedback(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
  storeFeedback(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }
  filterFeedback(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }
  searchFeedback(params) {
    return axios.post(`${this.baseServicePath}/search/index`, params);
  }
}
export const feedbackService = new FeedbackService();
