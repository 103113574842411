// Application types

const TRANSACTION = {
  LOADING_AUTH: "LOADING_AUTH",
  LOADING_SEARCH: "LOADING_SEARCH",
  UPDATING: "UPDATING",
  TRANSFERRING: "TRANSFERRING",
  FILTERING: "FILTERING",
  DOWNLOADING: "DOWNLOADING",
  ALL_TRANSACTIONS: "ALL_TRANSACTIONS",
  ALL_TRANSACTIONS_NO_PAGINATION: "ALL_TRANSACTIONS_NO_PAGINATION",
  INCOMPLETE_TRANSACTIONS: "INCOMPLETE_TRANSACTIONS",
  SINGLE_TRANSACTION: "SINGLE_TRANSACTION",
  SEARCH_TRANSACTION: "SEARCH_TRANSACTION",
  MY_RESTAURANTS: "MY_RESTAURANTS",
  FILTER_TRANSACTIONS: "FILTER_TRANSACTIONS",
  TRANSFER_TRANSACTIONS: "TRANSFER_TRANSACTIONS",
};

export default TRANSACTION;
