import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { history } from "../../../../helpers/history";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";
import { getAllStores } from "../../../../redux/stores/storeActions";
import { createSubscriptionMerchAction } from "../../../../redux/subscriptionMerchandise/subscriptionMerchAction";

export default function CreateMerchPackageItem() {
  const dispatch = useDispatch();
  const { allStores } = useSelector((state) => state.store);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { isLoading } = useSelector((state) => state.subscriptionMerch);
  const [input, setInput] = useState({
    store_id: "",
    merchandise_name: "",
    merchandise_description: "",
    merchandise_amount: "",
    merchandise_categories: [],
    merchandise_tags: [],
  });

  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });

  const storeTags = allTags?.data?.filter((item) => {
    return item?.type === "store";
  });

  useEffect(() => {
    dispatch(filterProductCategory({ type: "store" }, 1));
    dispatch(filterProductTag({ type: "store" }, 1));
    dispatch(getAllStores());
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();

      setInput({ ...input, [name]: value });

  };

  const handleToggleTags = (value) => {
    let former_tags = [];
    if (input?.merchandise_tags) {
      former_tags = [...input?.merchandise_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, merchandise_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setInput({ ...input, merchandise_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setInput({ ...input, merchandise_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (input?.merchandise_categories) {
      former_categories = [...input?.merchandise_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, merchandise_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setInput({ ...input, merchandise_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setInput({ ...input, merchandise_categories: new_categories });
    }
  };

  const handleSubmit = async () => {
    try {
      const response = await dispatch(createSubscriptionMerchAction(input));
      if (response?.status === "success") {
        history("/admin/merchandise-packages/items/1");
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div data-test="createItem-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-8 px-10 max-w-3xl mx-auto mt-24">
        <div className="border-b border-[#E4E4F3] py-3 text-center">
          <h3 className="text-lg font-medium">Merchandise Package Items</h3>
        </div>

        <div className="mt-6">
          <Input.Select
            title={"Store"}
            name="store_id"
            id="store_id"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
          >
            <option>Select a store</option>
            {allStores?.data?.length > 0 &&
              allStores?.data?.map((store) => (
                <option key={store.id} value={store.id}>
                  {store.store_name}
                </option>
              ))}
          </Input.Select>
          <Input.Label
            title={"Item Name"}
            type="text"
            name="merchandise_name"
            id="merchandise_name"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter Item name"
            onChange={handleChange}
          />
          <Input.Select
            title={"Format"}
            name="format"
            id="format"
            htmlFor={"format"}
            className={"border border-[#C2C9D1] w-full"}
            onChange={handleChange}
          >
            <option>Select format</option>
            <option value={"physical"}>Physical</option>
            <option value={"digital"}>Digital</option>
          </Input.Select>
          <Input.TextArea
            title={"Item Description"}
            type="text"
            name="merchandise_description"
            id="merchandise_description"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter Item description"
            onChange={handleChange}
          />
          <Input.Label
            title={"Amount"}
            type="number"
            name="merchandise_amount"
            id="merchandise_amount"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Price including shipping markup"
            onChange={handleChange}
          />
          <div className="mb-4">
            <label
              htmlFor="merchandise_categories"
              className="text-sm font-medium text-[#151515] mb-2 block"
            >
              Merchandise Category*
            </label>
            <SelectableInput
              options={storeCategories}
              label="merchandise_categories"
              name={"+ Add Category"}
              type="category"
              selectOption={handleToggleCategories}
            />
          </div>

          <div className="mb-4">
            <label
              htmlFor="merchandise_tags"
              className="text-sm font-medium text-[#151515] mb-2 block"
            >
              Package Tags
            </label>
            <SelectableInput
              options={storeTags}
              label="merchandise_tags"
              name={"+ Add Tag"}
              type="tag"
              selectOption={handleToggleTags}
            />
          </div>
        </div>
      </div>
      <div className="py-8 px-10 max-w-3xl mx-auto text-center mt-6">
        <Button.Primary
          disabled={isLoading}
          title={isLoading ? <Beat color={"#ffffff"} /> : "Create Package Item"}
          className="px-4"
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
