import businessType from "./businessTypes";

const initialState = {
  isLoading: false,
  approving: false,
  arching: false,
  filtering: false,
  mybusiness: {},
  business: {},
  allBusinesses: {},
  filterNoPage: {},
  unaApproved: {},
  approved: {},
  search: [],
};

export default function businessReducer(state = initialState, action) {
  switch (action.type) {
    case businessType.LOADING_BUSINESS:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case businessType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case businessType.FILTER_BUSINESS:
      return {
        ...state,
        allBusinesses: action.payLoad?.data,
      };
    case businessType.FILTER_BUSINESS_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payLoad?.data,
      };
    case businessType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case businessType.SEARCH_BUSINESS:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case businessType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };

    case businessType.MY_BUSINESS:
      return {
        ...state,
        myBusiness: action.payLoad?.data,
      };
    case businessType.A_BUSINESS:
      return {
        ...state,
        business: action.payLoad?.data,
      };

    case businessType.UPDATE_BUSINESS:
      return {
        ...state,
        myBusiness: action.payLoad?.data,
      };
    case businessType.GET_ALL_BUSINESSES:
      let unapprovedData = {};
      if (action.payLoad?.data === undefined) {
        unapprovedData = {};
      } else {
        unapprovedData = action.payLoad?.data;
      }
      return {
        ...state,
        allBusinesses: unapprovedData,
      };
    case businessType.APPROVE_BUSINESS:
      return {
        ...state,
        approving: action.payLoad,
      };
    case businessType.UNAPPROVE_BUSINESS:
      return {
        ...state,
        approving: action.payLoad,
      };

    default:
      return state;
  }
}
