import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// components
import GroupRecipients from "./GroupRecipients";
import Button from "../../../../../components/buttons/buttons";
import { Beat } from "../../../../../plugins/spinners.plugin";
// requests
import {
  showANotifierGroup,
  // closeANotifierGroup,
  deleteANotifierGroup
} from "../../../../../redux/notifierGroup/notifierGroupAction";

export default function SingleRecipientGroup() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [page, setPage] = useState(1);
  // const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const { singleNotifierGroup, deleting } = useSelector(
    (state) => state.notifierGroup
  );

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(showANotifierGroup(id, page));
    }
    // eslint-disable-next-line
  }, [id, page]);

  const handleDeleteGroup = async () => {
    const response = await dispatch(deleteANotifierGroup({ id: id }));
    if (response?.status === "success") {
      navigate(-1);
    }
  };
  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mb-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">{singleNotifierGroup?.title}</h3>
          <Button.Primary
            type={"button"}
            title={deleting ? <Beat /> : "Delete Group"}
            onClick={handleDeleteGroup}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">
              {singleNotifierGroup?.title}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">User Count</label>
            <p className="text-[#151515] text-sm break-words">
              {singleNotifierGroup?.recipients?.length}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(singleNotifierGroup?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>
      </div>

      {/* Group recipients section */}
      <GroupRecipients page={page} setPage={setPage} />
    </div>
  );
}
