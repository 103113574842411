// Application types

const PUBLIC_MERCHANDISE = {
  LOADING_PUBLIC_MERCHANDISE: "LOADING_PUBLIC_MERCHANDISE",
  ALL_PUBLIC_MERCHANDISES: "ALL_PUBLIC_MERCHANDISES",
  SINGLE_PUBLIC_MERCHANDISE: "SINGLE_PUBLIC_MERCHANDISE",
  SEARCH_PUBLIC_MERCHANDISE: "SEARCH_PUBLIC_MERCHANDISE",
  MY_PUBLIC_MERCHANDISES: "MY_PUBLIC_MERCHANDISES",
  FILTER_PUBLIC_MERCHANDISE: "FILTER_PUBLIC_MERCHANDISE",
};

export default PUBLIC_MERCHANDISE;
