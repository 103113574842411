import axios from "../plugins/axios.plugin";

class NotifierService {
  baseServicePath = "/api/notifier";

  storeANotifier(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  deleteANotifier(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  closeANotifier(payload) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payload);
  }

  editACategory(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  showANotifier(payload) {
    return axios.get(
      `${this.baseServicePath}/show?id=${payload}&properties=1`,
      payload
    );
  }
  searchNotifier(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterNotifier(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
}

export const notifierService = new NotifierService();
