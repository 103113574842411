import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ReactComponent as RIGHT } from "../../../assets/images/icons/arrow-right.svg";

export default function AdminBreadCrumbs() {
  const location = useLocation();
  const navigate = useNavigate();

  // eslint-disable-next-line
  let current = "";
  function currentLocation(params) {
    const pathname = location.pathname;
    const arr = pathname.split("/");
    let index = "";
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i] === params) {
        index = i;
      }
    }
    const nav = arr.slice(0, index + 1).join("/");
    navigate(nav);
  }
  const navTo = () => {
    navigate("dashboard");
  };

  // admin/Ntachi%20Osa/food/approved/1
  const crumbs = location.pathname
    .split("/")
    .filter((crumb) => crumb !== "")
    .map((crumb, index) => {
      current += `${crumb}`;

      return (
        <div className="flex py-7 px-2 cursor-pointer" key={index}>
          <button
            onClick={() =>
              crumb === "admin" ? navTo() : currentLocation(crumb)
            }
            className="text-[#4D4D4D] capitalize"
          >
            {crumb === "admin" ? "Home" : crumb}
          </button>
          <RIGHT className="h-6 w-6" />
        </div>
      );
    });

  const isDashboard = location.pathname.includes("/dashboard");
  return <div>{!isDashboard && <div className="flex ">{crumbs}</div>}</div>;
}
