import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Switch } from "@headlessui/react";
import DotMenu from "../../../../assets/images/icons/dot-menu.svg";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import ViewFeature from "../../../../components/modals/feature/viewFeature";
// import AddFoodVariationModal from "../../../../components/modals/admin/attributes/addFoodVariationModal";
import EditFoodPriceModal from "../../../../components/modals/admin/food/editFoodPriceModal";
import { getTeamFromCookies } from "../../../../utils/Auth";
import { getAllAttributesAction } from "../../../../redux/attribute/attributeActions";
import { getAllCategories } from "../../../../redux/productCatergory/productCategoryAction";
import {
  getAFoodAction,
  deleteAFoodAction,
} from "../../../../redux/food/foodActions";
import {
  approveFoodByMgtAction,
  unapproveFoodByMgtAction,
} from "../../../../redux/food/foodActions";
import {
  approveFoodBySelfAction,
  unapproveFoodBySelfAction,
} from "../../../../redux/food/foodActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function calcDaysLeft(end_date) {
  const start_date = new Date();
  if (end_date !== null) {
    const diffTime = Math.abs(end_date - start_date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  } else {
    return 0;
  }
}

export default function FoodDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const team = getTeamFromCookies();

  const { singleFood, deleting } = useSelector((state) => state.food);
  // const [isOpen, setIsOpen] = useState(false);
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [enabledApprove, setEnabledApprove] = useState();
  const [enabledPublish, setEnabledPublish] = useState();

  const [price, setPrice] = useState({
    id: "",
    food_amount: "",
    food_discount: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (id) {
      dispatch(getAFoodAction(id));
    }
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getAllAttributesAction());
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setEnabledApprove(singleFood?.management_approved);
    setEnabledPublish(singleFood?.business_approved);
    setPrice({
      id: singleFood?.id,
      food_amount: singleFood?.food_amount,
      food_discount: singleFood?.food_discount,
    });
    // eslint-disable-next-line
  }, [singleFood?.id]);

  function closeUpdateModal() {
    setOpenUpdate(false);
  }
  function openUpdateModal() {
    setOpenUpdate(true);
  }

  const toggleBtn = () => {
    setOpenDropdown(!openDropdown);
  };

  const handleApprove = () => {
    dispatch(approveFoodByMgtAction({ id: singleFood?.id }));
  };

  const handleUnapprove = () => {
    dispatch(unapproveFoodByMgtAction({ id: singleFood?.id }));
  };

  const approveByRestaurant = () => {
    dispatch(approveFoodBySelfAction({ id: singleFood?.id }));
  };
  const unapproveByRestaurant = () => {
    dispatch(unapproveFoodBySelfAction({ id: singleFood?.id }));
  };

  function goToSingleStore() {
    const restaurant_id = singleFood?.restaurant_id;
    navigate(`/admin/restaurants/${restaurant_id}`);
  }

  const deleteFood = async () => {
    try {
      const response = await dispatch(
        deleteAFoodAction({ id: singleFood?.id })
      );
      if (response.status === "success") {
        navigate("/admin/food/approved/1");
      }
    } catch (error) {}
  };

  return (
    <>
      {/* <AddFoodVariationModal show={isOpen} onClose={closeModal} /> */}
      <EditFoodPriceModal
        show={openUpdate}
        onClose={closeUpdateModal}
        price={price}
        setPrice={setPrice}
      />
      <div data-test="editFood-page">
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">
              Food: {singleFood?.food_name}
            </h3>

            <div className="relative block sm:hidden">
              <button
                className="flex text-[#717485] gap-1 items-center"
                onClick={toggleBtn}
                type="button"
              >
                <img src={DotMenu} alt="menu" />
              </button>
              {openDropdown && (
                <div className="absolute right-0 z-10 w-[280px] border-[#E4E4F3] bg-white px-0 overflow-y-auto shadow-xl transform transition-all">
                  <div className="grid grid-cols-1 gap-4 p-4">
                    {team === "administrator" && (
                      <Button.Secondary
                        disabled={deleting}
                        title={
                          deleting ? <Beat color={"#CE2600"} /> : "Delete Food"
                        }
                        onClick={() => deleteFood()}
                        className="px-5"
                        type={"button"}
                      />
                    )}
                    <Button.Primary
                      title={"Edit Food"}
                      onClick={() =>
                        navigate(`/admin/food/edit/${singleFood?.id}`, {
                          state: { food: singleFood },
                        })
                      }
                      className="px-5"
                      type={"button"}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="hidden sm:flex gap-4 items-center">
              {team === "administrator" && (
                <Button.Secondary
                  disabled={deleting}
                  title={deleting ? <Beat color={"#CE2600"} /> : "Delete Food"}
                  onClick={() => deleteFood()}
                  className="px-5"
                  type={"button"}
                />
              )}
              <Button.Primary
                title={"Edit Food"}
                onClick={() =>
                  navigate(`/admin/food/edit/${singleFood?.id}`, {
                    state: { food: singleFood },
                  })
                }
                className="px-5"
                type={"button"}
              />
            </div>
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Food ID</label>
              <p className="text-[#151515] text-sm">{singleFood?.id}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Category</label>
              <p className="text-[#151515] text-sm ">
                {singleFood?.food_categories?.length > 0 &&
                  singleFood?.food_categories.map((item, index) => (
                    <span key={index}>{item}, </span>
                  ))}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Stock</label>
              <p className="text-[#151515] text-sm">{singleFood?.stock}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              <p className="text-[#151515] text-sm capitalize">
                {singleFood?.food_operating_status}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
              <p className="text-[#151515] text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] text-sm">{singleFood?.flag}</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                {singleFood?.business_approved
                  ? "Unpublish Food"
                  : "Publish Food"}
              </label>
              <Switch.Group as="div" className="flex">
                <Switch
                  checked={enabledPublish}
                  onChange={(e) => {
                    setEnabledPublish(e);
                    singleFood?.business_approved
                      ? unapproveByRestaurant()
                      : approveByRestaurant();
                  }}
                  className={classNames(
                    enabledPublish ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabledPublish ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                {singleFood?.management_approved
                  ? "Unapprove Food"
                  : "Approve Food"}
              </label>
              <Switch.Group as="div" className="flex">
                <Switch
                  checked={enabledApprove}
                  onChange={(e) => {
                    setEnabledApprove(e);
                    singleFood?.management_approved
                      ? handleUnapprove()
                      : handleApprove();
                  }}
                  className={classNames(
                    enabledApprove ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabledApprove ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>

            {/* Split payment details */}
            {/* <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Split Payment
              </label>
              <p className="text-[#151515] text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Initial Payment
              </label>
              <p className="text-[#151515] text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Payment Intervals
              </label>
              <p className="text-[#151515] text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Subsequent Payment
              </label>
              <p className="text-[#151515] text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Duration</label>
              <p className="text-[#151515] text-sm">
                {moment(singleFood?.created_at).format("DD, MMM YYYY, HH:MM A")}
              </p>
            </div> */}
          </div>
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Pricing</h3>
            <Button.Primary title={"Edit Price"} onClick={openUpdateModal} />
          </div>
          <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Price</label>
              <p className="text-[#151515] text-sm">
                NGN {numberWithCommas(singleFood?.food_amount)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Discount</label>
              <p className="text-[#151515] text-sm">
                NGN {numberWithCommas(singleFood?.food_discount)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">VAT</label>
              <p className="text-[#151515] text-sm">
                NGN {numberWithCommas(singleFood?.food_amount)}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Handling Fee
              </label>
              <p className="text-[#151515] text-sm">
                NGN {numberWithCommas(singleFood?.food_discount)}
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Category</h3>
            </div>
            <div className="mt-6">
              <label className="text-[#9CA3AF] text-sm mb-3">Category</label>
              <p className="text-[#151515] text-sm">
                {singleFood?.food_categories?.length > 0 &&
                  singleFood?.food_categories?.map((category, i) => (
                    <span key={i}>{category}, </span>
                  ))}
              </p>
            </div>
          </div>

          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Tags</h3>
            </div>
            <div className="mt-6">
              <label className="text-[#9CA3AF] text-sm mb-3">Tags</label>
              <p className="text-[#151515] text-sm">
                {singleFood?.food_tags?.length > 0 &&
                  singleFood?.food_tags?.map((tag, i) => (
                    <span key={i}>{tag}, </span>
                  ))}
              </p>
            </div>
          </div>
        </div>
        {singleFood?.featured?.length > 0 && (
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Feature Food</h3>
              <Button.Primary
                title={"View Feature Request"}
                onClick={() => setOpen(true)}
              />
              <ViewFeature
                feature={singleFood?.featured?.[0]}
                isOpen={open}
                setOpen={setOpen}
              />
            </div>
            <div className="grid grid-cols-2 lg:grid-cols-6 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Cost</label>
                <p className="text-[#151515] text-sm">
                  NGN{" "}
                  {numberWithCommas(singleFood?.featured?.[0]?.featured_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
                <p className="text-[#151515] text-sm capitalize">
                  {singleFood?.featured?.[0]?.status}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Duration</label>
                <p className="text-[#151515] text-sm">
                  {Number(
                    singleFood?.featured?.[0]?.featured_duration_in_hours
                  ) / 24}{" "}
                  days
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Time Left</label>
                <p className="text-[#151515] text-sm">
                  {calcDaysLeft(singleFood?.featured?.[0]?.featured_end_date)}{" "}
                  days{" "}
                </p>
              </div>
            </div>
          </div>
        )}

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Restaurant Details</h3>
            <Button.Primary
              title={"View Restaurant"}
              onClick={goToSingleStore}
            />
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Store Name</label>
              <p className="text-[#151515] text-sm">
                {singleFood?.restaurant?.restaurant_name}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm break-words">
                {singleFood?.restaurant?.restaurant_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] text-sm">
                {singleFood?.restaurant?.restaurant_phone}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
              <p className="text-[#151515] text-sm">
                {singleFood?.restaurant?.restaurant_address}
              </p>
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">Food Images</h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-5 sm:mt-8">
            {singleFood?.food_pictures?.map((image, index) => (
              <figure className="w-full h-[160px] relative" key={index}>
                <img
                  src={image}
                  className="object-cover w-full h-full"
                  alt="food"
                />
              </figure>
            ))}
          </div>
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-5 sm:mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Atributes</h3>
            {/* <button className="text-primary" onClick={openModal}>
              + Add variation
            </button> */}
          </div>
          {singleFood?.attributes?.length > 0 ? (
            singleFood?.attributes.map((attr, i) => (
              <div key={i} className="border-b border-[#E4E4F3] mt-5 sm:mt-8">
                <p className="text-md my-6">
                  Title : <span className="font-medium">{attr.title}</span>
                </p>
                <div className="grid grid-cols-2 gap-24 px-4">
                  <p className="py-2 text-md">Name</p>
                  <p className="py-2 text-md">Value</p>
                </div>
                <div key={i} className="grid grid-cols-2 gap-24 px-4">
                  <p className="py-2 font-medium">{attr.name}</p>
                  <p className="py-2 font-medium">NGN {attr.amount}</p>
                </div>
              </div>
            ))
          ) : (
            <div className="text-center mx-auto max-w-2xl py-6">
              <h4 className="text-2xl font-medium mb-2">
                You have no food attributes yet
              </h4>
              <p className="max-w-[473px] mx-auto text-lg mb-4">
                When you create or add food attributes, it will be dispalyed
                here.
              </p>
              {/* <button
                className="text-primary text-lg"
                type="button"
                onClick={openModal}
              >
                + Add variation
              </button> */}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
