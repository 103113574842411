// Application types

const FEEDBACK = {
  LOADING_FEEDBACK: "LOADING_FEEDBACK",
  STORING_FEEDBACK: "STORING_FEEDBACK",
  VERIFYING: "VERIFYING",
  ALL_FEEDBACK: "ALL_FEEDBACK",
  SINGLE_FEEDBACK: "SINGLE_FEEDBACK",
  FILTER_FEEDBACK: "FILTER_FEEDBACK",
  FILTERING_FEEDBACK: "FILTERING_FEEDBACK",
  LOADING_SEARCH: "LOADING_SEARCH",
  SEARCH_FEEDBACK: "SEARCH_FEEDBACK",
  DOWNLOADING: "DOWNLOADING",
};

export default FEEDBACK;
