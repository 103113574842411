import notifierGroupType from "./notifierGroupTypes";

const initialState = {
  allNotifierGroup: [],
  singleNotifierGroup: {},
  loading: false,
  searching: false,
  filtering: false,
  deleting: false,
  updating:false,
  store: {},
  search: [],
  showCategory: {},
  editCategory: {},
  deleteCategory: {},
  testCategoryModel: {},
};
export default function notifierGroupReducer(state = initialState, action) {
  switch (action.type) {
    case notifierGroupType.GET_PRODUCT_CATEGORIES:
      return {
        ...state,
        allNotifierGroup: action.payLoad?.data,
      };
    case notifierGroupType.STORE_A_NOTIFIER:
      return {
        ...state,
        store: action.payLoad?.data?.data,
      };
    case notifierGroupType.DELETE_A_NOTIFIER_GROUP:
      // const deleteCategory = state.allNotifier.filter(
      //   (item) => item.id !== action.payLoad.id
      // );
      return {
        ...state,
        deleteCategory: action.payLoad.data,
      };
    case notifierGroupType.EDIT_A_PRODUCT_CATEGORY:
      // const editCategories = state.allNotifier.findIndex(
      //   (item) => item.id === action.payLoad.id
      // );
      // const allNotifier = [...state.allNotifier];
      // allNotifier[editCategories] = action.payLoad;
      return {
        ...state,
        editCategory: action.payLoad.data,
      };
    case notifierGroupType.SHOW_A_NOTIFIER_GROUP:
      return {
        ...state,
        singleNotifierGroup: action.payLoad.data,
      };
    case notifierGroupType.TEST_NOTIFIER_GROUP_MODEL:
      return {
        ...state,
        testCategoryModel: action.payLoad.data.data,
      };
    case notifierGroupType.LOADING_NOTIFIER:
      return {
        ...state,
        loading: action.payLoad,
      };
    case notifierGroupType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case notifierGroupType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case notifierGroupType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };
    case notifierGroupType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };
    case notifierGroupType.SEARCH_NOTIFIER:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case notifierGroupType.FILTER_NOTIFIER_GROUP:
      return {
        ...state,
        allNotifierGroup: action.payLoad?.data,
      };

    default:
      return state;
  }
}
