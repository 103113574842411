import { useSelector } from "react-redux";
import Card from "../../../components/cards/cards";
import EmptyState from "../../../assets/images/icons/outletEmpty.svg";

export default function FilterStore() {
  const { filter } = useSelector((state) => state.publicStore);
  const { settings } = useSelector((state) => state.application);
  let store = filter?.data;
  return (
    <div data-test="filterstoreComponent">
      {store?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
          {store?.map((store, i) => (
            <Card.Store
              key={i}
              id={store?.id}
              title={store?.store_name}
              tags={store?.store_tags}
              image={store?.store_pictures}
              categories={store?.store_categories}
              operating_settings={settings?.store_operating_status}
              operating_status={store?.store_operating_status}
              operating_hours={store?.store_operating_time}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center py-16">
          <div className="text-center">
            <img src={EmptyState} alt="empty" />
            <p className="my-6">No stores found</p>
          </div>
        </div>
      )}
    </div>
  );
}
