//MERCHANDISE Types

const MERCHANDISE = {
  LOADING_MERCHANDISE: "LOADING_MERCHANDISE",
  LOADING_SEARCH: "LOADING_SEARCH",
  APPROVING_MERCHANDISE: "APPROVING_MERCHANDISE",
  CREATE_MERCHANDISE: "CREATE_MERCHANDISE",
  GET_ALL_MERCHANDISE: "GET_ALL_MERCHANDISE",
  FILTER_MERCHANDISE: "FILTER_MERCHANDISE",
  FILTER_MERCHANDISE_NOPAGE: "FILTER_MERCHANDISE_NOPAGE",
  FILTERING: "FILTERING",
  UPDATING: "UPDATING",
  SEARCH_MERCHANDISE: "SEARCH_MERCHANDISE",
  SHOW_A_MERCHANDISE: "GET_AN_MERCHANDISE",
  GET_MY_MERCHANDISE: "GET_MY_MERCHANDISE",
  EDIT_MERCHANDISE: "EDIT_MERCHANDISE",
  DELETE_MERCHANDISE: "DELETE_MERCHANDISE",
  DELETING_MERCHANDISE:'DELETING_MERCHANDISE',
  RELOADING_MERCHANDISE: "RELOADING_MERCHANDISE",
  APPROVE_MERCHANDISE_MANAGEMENT: "APPROVE_MERCHANDISE_MANAGEMENT",
  UNAPPROVE_MERCHANDISE_MANAGEMENT: "UNAPPROVE_MERCHANDISE_MANAGEMENT",
  APPROVE_MERCHANDISE_BUSINESS: "APPROVE_MERCHANDISE_BUSINESS",
  UNAPPROVE_MERCHANDISE_BUSINESS: "UNAPPROVE_MERCHANDISE_BUSINESS",
};
export default MERCHANDISE;
