import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "../../../../../../components/buttons/buttons";
import Card from "../../../../../../components/cards/admin/cards";
import Table from "../../../../../../components/tables/tableCols2";
import Copy from "../../../../../../assets/images/icons/copy-outline.svg";
import TableSkeleton from "../../../../../../components/skeleton/table";
import { Pagination } from "../../../../../../components/pagination/pagination";
import EmptyState from "../../../../../../assets/images/emptyState/report.svg";
import {
  filterStoreOrders,
  searchAllStoreOrders,
  getAllStoreOrdersNoPage,
} from "../../../../../../redux/storeOrders/storeOrderAction";
import storeOrderType from "../../../../../../redux/storeOrders/storeOrderTypes";
import { writeFileWithXLSX } from "../../../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../../../components/modals/allFiltersModal";
import FilterStoreOrdersDropDown from "../../../../../../components/dropDown/filter/storeOrderFilter";

export default function UnShippedOrders() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page, id } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);
  const { allStoreOrders, filtering, searching, allStoreOrdersNoPage } =
    useSelector((state) => state.storeOrder);

  const [isFilterOpen, setFilterState] = useState(false);
  const [payLoad] = useState({
    status: "unshipped",
    user_id: id,
    // start_date: lastMonth,
    // end_date: new Date(),
  });
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    if (!page) {
      navigate(`/admin/store-orders/unshipped/1`);
    }
  }, [page, navigate]);

  useEffect(() => {
    dispatch(filterStoreOrders(payLoad, page));
    dispatch(getAllStoreOrdersNoPage(payLoad));
  }, [dispatch, payLoad, page]);

  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Copied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let single = {
          created: (
            <span>{moment(order?.created_at).format("MMM DD, YYYY")}</span>
          ),
          id: (
            <div className="flex ">
              {truncateText(order.id)}
              <img
                className="ml-2"
                src={Copy}
                alt="copy-icon"
                onClick={() => copyTextToClipboard(order.id)}
              />
            </div>
          ),
          name: order?.recipient_name,
          phone: <div>{order?.recipient_phone}</div>,
          payment:
            order?.order_paid === true ? (
              <p className="font-bold text-sm text-green-500">Paid</p>
            ) : (
              <p className="font-bold text-sm text-primary">Not Paid</p>
            ),
          amount: order?.order_amount,
          order: order,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let single = {
          created: order?.created_at,
          id: order.id,
          name: order?.recipient_name,
          phone: order?.recipient_phone,
          payment: order?.order_paid === true ? "true" : "false",
          amount: order?.order_amount,
        };

        outputArray.push(single);
        return true;
      });

      return outputArray;
    }
  }

  const goToSinglePage = (params) => {
    let id = params?.single?.order?.id;
    navigate(`/admin/orders/store/${id}`, {
      state: { order: params?.single?.order },
    });
  };

  const downloadCSV = () => {
    if (allStoreOrdersNoPage) {
      const newData = createTableNoPage(allStoreOrdersNoPage);
      writeFileWithXLSX(newData, {
        filename: "unshipped orders.xlsx",
        download: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allStoreOrders);
      setData(dataSet);
      dispatch({
        type: storeOrderType["SEARCH_STORE_ORDERS"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchAllStoreOrders(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order ID",
            accessor: "single.id",
          },
          {
            Header: "Date",
            accessor: "single.created",
          },
          {
            Header: "Amount",
            accessor: "single.amount",
          },
          {
            Header: "Payment Status",
            accessor: "single.payment",
          },
          {
            Header: "Customer Name",
            accessor: "single.name",
          },
          {
            Header: "Customer Phone",
            accessor: "single.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="stores-page">
      <Card.TableFilter
        title={"Store Orders"}
        results={allStoreOrders?.to}
        totalResults={allStoreOrders?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        payLoad={payLoad}
        page={page}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterStoreOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Order Id or Phone Number"}
      >
        <Button.Secondary
          title={"Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 ">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={EmptyState} alt="orders" />
            </div>
            <p className="font-bold text-xl my-3">Store Orders</p>
            <p className="text-sm text-gray-500">
              You do not have any unshipped Orders yet
            </p>
          </div>
        )}
        <Pagination
          data={allStoreOrders}
          route={"admin/store-order/unshipped"}
        />
      </div>
    </div>
  );
}
