import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import TableFilter from "../../../components/tables/tableFilter";
import { Pagination } from "../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import FilterStoreDropDown from "../../../components/dropDown/filter/storeFilter";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import STORE from "../../../assets/images/icons/outletEmpty.svg";
import { Beat } from "../../../plugins/spinners.plugin";
import {
  filterStores,
  searchAllStores,
  getAllStoresNoPage,
} from "../../../redux/stores/storeActions";
import storeType from "../../../redux/stores/storeTypes";

export default function ActiveStores() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isFilterOpen, setFilterState] = useState(false);
  const { allStores, filtering, downloading, searching, allStoresNoPage } =
    useSelector((state) => state.store);

  const [payLoad] = useState({
    visibility: "public",
  });
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  let { page } = useParams();
  useEffect(() => {
    if (!page) {
      navigate(`/admin/stores/active/1`);
    }
  }, [page, navigate]);

  useEffect(() => {
    dispatch(filterStores(payLoad, page));
    dispatch(getAllStoresNoPage(payLoad));
  }, [dispatch, payLoad, page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((store, index) => {
        let single = {
          location: store?.store_city,
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: store?.store_name,
          phone: <div>{store?.store_phone}</div>,
          management:
            store?.management_approved === true ? (
              <p className="font-bold text-sm text-green-500">Approved</p>
            ) : (
              <p className="font-bold text-sm text-primary">Unapproved</p>
            ),
          business:
            store?.business_approved === true ? (
              <p className="font-bold text-sm text-green-500">Approved</p>
            ) : (
              <p className="font-bold text-sm text-primary">Unapproved</p>
            ),

          email: store?.store_email,
          store: store,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allStores?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStores]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((store, index) => {
        let single = {
          location: store?.store_city,
          name: store?.store_name,
          phone: store?.store_phone,
          management: store?.management_approved === true ? "true" : "false",
          business: store?.business_approved === true ? "true" : "false",

          email: store?.store_email,
        };

        outputArray.push(single);
        return true;
      });

      return outputArray;
    }
  }

  const goToSinglePage = (params) => {
    let id = params?.single?.store?.id;
    navigate(`/admin/stores/${id}`, {
      state: { store: params?.single?.store },
    });
  };
  const downloadCSV = () => {
    if (allStoresNoPage) {
      const newData = createTableNoPage(allStoresNoPage);
      writeFileWithXLSX(newData, {
        filename: "Active stores.xlsx",
        download: true,
      });
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allStores?.data);
      setData(dataSet);
      dispatch({
        type: storeType["SEARCH_STORES"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchAllStores(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.num",
          },
          {
            Header: "Store Name",
            accessor: "single.name",
          },
          {
            Header: "Phone Number",
            accessor: "single.phone",
          },
          {
            Header: "Management Approval",
            accessor: "single.management",
          },
          {
            Header: "Business Approval",
            accessor: "single.business",
          },
          {
            Header: "Location",
            accessor: "single.location",
          },
          {
            Header: "Email",
            accessor: "single.email",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="stores-page">
      <TableFilter
        title={"Stores"}
        results={allStores?.to}
        totalResults={allStores?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterStoreDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Store Name, Email or Phone Number"}
      >
        {/* <Button.Primary title={"+ Invite Stores"} onClick={goToInvitePage} /> */}
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 ">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            {" "}
            <div className="flex justify-center my-12 ">
              <img src={STORE} alt="store" />
            </div>
            <p className="font-bold text-xl my-3">Stores</p>
            <p className="text-sm text-gray-500">You have no active stores</p>
          </div>
        )}
        <Pagination data={allStores} route={"admin/stores/active"} />
      </div>
    </div>
  );
}
