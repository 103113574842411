//STORE Types

const STORES = {
  LOADING_STORES: "LOADING_STORES",
  UPDATING: "UPDATING",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
  UPDATING_STORES: "UPDATING_STORES",
  APPROVING: "APPROVING",
  APPROVE_STORE: "APPROVE_STORE",
  UNAPPROVE_STORE: "UNAPPROVE_STORE",
  APPROVE_STORE_MANAGEMENT: "APPROVE_STORE_MANAGEMENT",
  UNAPPROVE_STORE_MANAGEMENT: "UNAPPROVE_STORE_MANAGEMENT",
  CREATE_STORE: "CREATE_STORE",
  GET_ALL_STORES: "GET_ALL_STORES",
  GET_ALL_STORES_NOPAGE: "GET_ALL_STORES_NOPAGE",
  FILTER_STORES: "FILTER_STORES",
  SEARCH_STORES: "SEARCH_STORES",
  SHOW_A_STORE: "GET_AN_STORE",
  GET_MY_STORES: "GET_MY_STORES",
  EDIT_STORE: "EDIT_STORE",
  DELETE_STORE: " DELETE_STORE",
};
export default STORES;
