import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import Restaurant from "../../../assets/images/emptyState/restaurant.svg";

export default function RestaurantBusiness({ myBusiness }) {
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.business);
  const [data, setData] = useState([]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((restaurant, index) => {
        let single = {
          location: restaurant?.restaurant_city,
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: restaurant?.restaurant_name,
          phone: <div>{restaurant?.restaurant_phone}</div>,
          approval:
            restaurant?.management_approved === true ? (
              <p className="font-bold text-sm text-green-500">Approved</p>
            ) : (
              <p className="font-bold text-sm text-primary">Unapproved</p>
            ),

          email: restaurant?.restaurant_email,
          restaurant: restaurant,
        };

        outputArray.push({ single });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(myBusiness?.restaurants);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myBusiness]);

  const goToCreate = () => {
    navigate(`/create-restaurant`);
  };

  function goToSingleStore(params) {
    const restaurant_id = params?.single?.restaurant?.id;
    navigate(`/admin/restaurants/${restaurant_id}`);
  }
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.num",
          },
          {
            Header: "Name",
            accessor: "single.name",
          },
          {
            Header: "Email",
            accessor: "single.email",
          },
          {
            Header: "Location",
            accessor: "single.location",
          },
          {
            Header: "Status",
            accessor: "single.approval",
          },
          {
            Header: "Phone Number",
            accessor: "single.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="business-page ">
      <div className=" bg-white rounded-md py-4">
        <div className="flex items-end justify-end mx-6 py-3 ">
          <Button.Primary
            title={"Create Restaurant"}
            onClick={() => goToCreate()}
          />
        </div>
        <div className="border-b"></div>

        <div>
          {data?.length > 0 ? (
            <>
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <Table
                  columns={columns}
                  data={data?.length > 0 ? data : []}
                  onClick={goToSingleStore}
                  className="w-full"
                  rowClass="hover:shadow-md cursor-pointer"
                />
              )}
            </>
          ) : (
            <div className="text-center">
              {" "}
              <div className="flex justify-center my-12 ">
                <img src={Restaurant} alt="restaurant" />
              </div>
              <p className="font-bold text-xl my-3">Stores</p>
              <p className="text-sm text-gray-500">
                You have no inactive stores
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
