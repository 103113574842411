import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import merchandiseType from "./merchandiseTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

//import { history } from "../../helpers/history";
import { merchandiseService } from "./../../services/merchandise.service";

/**
 * CREATE merchandise
 * 
 * @param {object} payLoad {
business_id:bc4f908e-1e4f-4493-88c7-c1c00e756d86
store_id:bc4f908e-1e4f-4493-88c7-c1c00e756d86
merchandise_name:lg tv
merchandise_description:plasma tv with high resolution
merchandise_categories[]:tech
merchandise_tags[]:home
merchandise_attributes[]:1
merchandise_amount:3000
merchandise_discount:10
merchandise_currency:ngn
merchandise_preparation_time:00:00
merchandise_operating_time[monday][open]:09:00
merchandise_operating_time[monday][close]:21:00
merchandise_operating_time[tuesday][open]:09:00
merchandise_operating_time[tuesday][close]:20:00
merchandise_operating_time[wednesday][open]:10:00
merchandise_operating_time[wednesday][close]:18:00
merchandise_operating_time[thursday][open]:08:00
merchandise_operating_time[thursday][close]:20:00
merchandise_operating_time[friday][open]:08:00
merchandise_operating_time[friday][close]:22:00
merchandise_operating_time[saturday][open]:10:00
merchandise_operating_time[saturday][close]:22:00
merchandise_operating_time[sunday][open]:12:00
merchandise_operating_time[sunday][close]:20:00
merchandise_operating_status:available
stock:20
  }
 * @returns {void}
 */
export const createAMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: true });
    const response = await merchandiseService.CreateAMerchandise(payLoad);

    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    dispatch({
      type: merchandiseType["CREATE_MERCHANDISE"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Approve a merchandise by business
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const approveMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: true,
    });
    const response = await merchandiseService.approveMerchandiseBusiness({
      id: payLoad,
    });
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: merchandiseType["APPROVE_MERCHANDISE_BUSINESS"],
      payLoad: false,
    });
    toast.success("Merchandise was published", {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
    return response;
  } catch (error) {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });

    errorHandler(error, true);
  }
};

/**
 * unApprove a merchandise by Business
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const unApproveMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: true,
    });
    const response = await merchandiseService.unApproveMerchandiseBusiness({
      id: payLoad,
    });
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: merchandiseType["UNAPPROVE_MERCHANDISE_BUSINESS"],
      payLoad: false,
    });
    // successHandler(response, true);
    toast.success("Merchandise was unpublished", {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
    return response;
  } catch (error) {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });

    errorHandler(error, true);
  }
};

/**
 * Get all merchandise
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllMerchandises = (page) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: true });
    const response = await merchandiseService.getAllMerchandises(page);
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    dispatch({
      type: merchandiseType["GET_ALL_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter merchandise
 * 
 * @param {object} payLoad {
  //user_id:bc4f908e-1e4f-4493-88c7-c1c00e756d86
//business_id:bc4f908e-1e4f-4493-88c7-c1c00e756d86
//store_id:bc4f908e-1e4f-4493-88c7-c1c00e756d86
merchandise_name:Schneider and Sons
//merchandise_categories[]:african
//merchandise_tags[]:breakfast
//merchandise_attributes[]:1
//merchandise_amount:3000
//merchandise_discount:10
//merchandise_currency:ngn
//merchandise_preparation_time:
//merchandise_operating_time[monday][open]:09:00
//merchandise_operating_time[monday][close]:21:00
//merchandise_operating_time[tuesday][open]:09:00
//merchandise_operating_time[tuesday][close]:20:00
//merchandise_operating_time[wednesday][open]:10:00
//merchandise_operating_time[wednesday][close]:18:00
//merchandise_operating_time[thursday][open]:08:00
//merchandise_operating_time[thursday][close]:20:00
//merchandise_operating_time[friday][open]:08:00
//merchandise_operating_time[friday][close]:22:00
//merchandise_operating_time[saturday][open]:10:00
//merchandise_operating_time[saturday][close]:22:00
//merchandise_operating_time[sunday][open]:12:00
//merchandise_operating_time[sunday][close]:20:00
//merchandise_operating_status:available
//stock:2
//pageination:1
  }
 * @returns {void}
 */
export const filterMerchandises = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["FILTERING"], payLoad: true });
    const response = await merchandiseService.filterMerchandises(payLoad, page);
    dispatch({ type: merchandiseType["FILTERING"], payLoad: false });
    dispatch({
      type: merchandiseType["FILTER_MERCHANDISE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: merchandiseType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const filterMerchandisesNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["FILTERING"], payLoad: true });
    const response = await merchandiseService.filterMerchandisesNoPage(payLoad);
    dispatch({ type: merchandiseType["FILTERING"], payLoad: false });
    dispatch({
      type: merchandiseType["FILTER_MERCHANDISE_NOPAGE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: merchandiseType["FILTERING"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Search all merchandiseS
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const searchAllMerchandises = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["LOADING_SEARCH"], payLoad: true });
    const response = await merchandiseService.searchMerchandise(payLoad);
    dispatch({ type: merchandiseType["LOADING_SEARCH"], payLoad: false });
    dispatch({
      type: merchandiseType["SEARCH_MERCHANDISE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: merchandiseType["LOADING_SEARCH"], payLoad: false });
  }
};
/**
 * Show merchandise
 * @param {object} payLoad {id:"eb2483aa-2c6a-407d-92af-238778668605"}
 * @returns
 */
export const getSingleMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: true });
    const response = await merchandiseService.showMerchandise(payLoad);
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    dispatch({
      type: merchandiseType["SHOW_A_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Show merchandise
 * @param {object}
 * @returns
 */
export const getMyMerchandises = () => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: true });
    const response = await merchandiseService.showMyMerchandises();
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    dispatch({
      type: merchandiseType["GET_MY_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Edit merchandise
 * @param {object} payLoad {
 id:1
merchandise_name:"speaker"
merchandise_categories[]:"tech"
merchandise_tags[]:"home"
merchandise_attributes[]:"2"
merchandise_amount:"3000"
merchandise_discount:"10"
merchandise_currency:"ngn"
merchandise_preparation_time:"00:00"
merchandise_operating_time[monday][open]:"09:00"
merchandise_operating_time[monday][close]:"21:00"
merchandise_operating_time[tuesday][open]:"09:00"
merchandise_operating_time[tuesday][close]:"20:00"
merchandise_operating_time[wednesday][open]:"10:00"
merchandise_operating_time[wednesday][close]:"18:00"
merchandise_operating_time[thursday][open]:"08:00"
merchandise_operating_time[thursday][close]:"20:00"
merchandise_operating_time[friday][open]:"08:00"
merchandise_operating_time[friday][close]:"22:00"
merchandise_operating_time[saturday][open]:"10:00"
merchandise_operating_time[saturday][close]:"22:00"
merchandise_operating_time[sunday][open]:"12:00"
merchandise_operating_time[sunday][close]:"20:00"
merchandise_operating_status:available
stock:2
//base64_photos:
    * @returns {void}
 */

export const editmerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: merchandiseType["UPDATING"],
      payLoad: true,
    });
    const response = await merchandiseService.editMerchandise(payLoad);
    dispatch({
      type: merchandiseType["UPDATING"],
      payLoad: false,
    });
    dispatch({ type: merchandiseType["EDIT_MERCHANDISE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: merchandiseType["UPDATING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete merchandise
 * @param {object} payLoad {id:"6d380968-233e-4f38-b85c-0040ee94bdd4"}
 * @returns
 */
export const deleteMerchandise = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: merchandiseType["DELETING_MERCHANDISE"], payLoad: true });
    const response = await merchandiseService.deleteMerchandise(payLoad);
    dispatch({ type: merchandiseType["DELETING_MERCHANDISE"], payLoad: false });
    dispatch({
      type: merchandiseType["DELETE_MERCHANDISE"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: merchandiseType["DELETING_MERCHANDISE"], payLoad: false });

    errorHandler(error, true);
    throw new Error("");
  }
};
/**
 * Approve a merchandise by Management
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const approveMerchandiseManagement = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: true,
    });
    const response = await merchandiseService.approveMerchandiseManagement({
      id: payLoad,
    });
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: merchandiseType["APPROVE_MERCHANDISE_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });

    errorHandler(error, true);
  }
};

/**
 * unApprove a merchandise by Management
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const unApproveMerchandiseManagement = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: true,
    });
    const response = await merchandiseService.unApproveMerchandiseManagement({
      id: payLoad,
    });
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });
    dispatch({
      type: merchandiseType["UNAPPROVE_MERCHANDISE_MANAGEMENT"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: merchandiseType["APPROVING_MERCHANDISE"],
      payLoad: false,
    });

    errorHandler(error, true);
  }
};

/**
 * Test merchandise model
 * @param {void}
 * @returns {void}
 */
export const testmerchandiseModel = () => async (dispatch) => {
  try {
    const response = await merchandiseService;
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: true });

    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    dispatch({
      type: merchandiseType["TEST_MERCHANDISE_MODEL"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: merchandiseType["LOADING_MERCHANDISE"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};
