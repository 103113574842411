// Application types

const RESTAURANT = {
  LOADING_AUTH: "LOADING_AUTH",
  LOADING_MORE: "LOADING_MORE",
  ALL_RESTAURANTS: "ALL_RESTAURANTS",
  MORE_RESTAURANTS: "MORE_RESTAURANTS",
  SINGLE_RESTAURANT: "SINGLE_RESTAURANT",
  SEARCH_RESTAURANT: "SEARCH_RESTAURANT",
  FILTER_RESTAURANTS: "FILTER_RESTAURANTS",
};

export default RESTAURANT;
