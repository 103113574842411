import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Card from "../../../../components/cards/admin/cards";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import TableSkeleton from "../../../../components/skeleton/table";
import CLOSE from "../../../../assets/images/icons/close.svg";
import Adjust from "../../../../assets/images/icons/adjust.svg";
import { Beat } from "../../../../plugins/spinners.plugin";
import { Pagination } from "../../../../components/pagination/pagination";
import { restaurantArrangement } from "../../../../redux/arrangements/arrangementAction";
import {
  getAllRestaurantsAction,
  searchRestaurantsAction,
} from "../../../../redux/restaurant/restaurantActions";

export const CheckBox = ({
  setPosition,
  selectId,
  setSelectId,
  restaurant_id,
  position,
  setShowModal,
}) => {
  return (
    <div
      onClick={() => {
        setSelectId(restaurant_id);
        setShowModal(true);
        setPosition(position);
      }}
      className={` w-4 h-4 text-primary bg-gray-100 rounded border
       ${
         selectId === restaurant_id
           ? "border-primary bg-primary"
           : "border-gray-300"
       }`}
    />
  );
};

export default function RestaurantsArrangement() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();

  const { allRestaurants } = useSelector((state) => state.restaurant);
  const { arranging } = useSelector((state) => state.arrangement);
  const { isLoading, search, searching } = useSelector(
    (state) => state.restaurant
  );

  const [input, setInput] = useState({
    search: "",
  });
  const [data, setData] = useState({ position: "" });
  const [selectId, setSelectId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [position, setPosition] = useState(false);
  const [restaurants, setRestaurant] = useState(allRestaurants?.data);
  const [active_tab, setActive_tab] = useState("all-restaurants");

  useEffect(() => {
    if (!page) {
      navigate(`/admin/settings/arrangement/restaurant/1`);
    }
  }, [page, navigate]);

  useEffect(() => {
    dispatch(getAllRestaurantsAction(page));
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setRestaurant(allRestaurants.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allRestaurants]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  
  const showSearch = () => {
    if (input?.search?.length > 2) {
      setActive_tab("searched");
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      setActive_tab("all-restaurants");
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchRestaurantsAction(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(restaurants);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setRestaurant(items);
  };

  const handlePosition = () => {
    const lists = {};
    search?.map((list) => {
      lists[list.id] = parseInt(data);
      return list;
    });
    dispatch(restaurantArrangement({ list: lists }));
    removeSearch();
  };

  const handleSave = async () => {
    // if the page = 1, position number will start from 0,
    // else position number needs to add 1 to account for zero indexing
    const position_number = () => {
      if (page > 1) {
        let last_item = Number((page - 1) * 24);
        return last_item + 1;
      } else {
        return Number((page - 1) * 24);
      }
    };
    const lists = restaurants?.reduce((acc, list, i) => {
      acc[list.id] = i + position_number();
      return acc;
    }, {});

    try {
      const response = await dispatch(restaurantArrangement({ list: lists }));
      if (response) {
        dispatch(getAllRestaurantsAction(page));
      }
    } catch (error) {}
  };

  return (
    <div className="relative">
      <div className="mb-12">
        <Card.TableFilter
          results={allRestaurants?.to}
          totalResults={allRestaurants?.total}
          onChange={handleChange}
          title={"Restaurant Arrangement"}
          searchPlaceholder="Restaurant Name, Status or Phone Number"
        >
          {active_tab === "all-restaurants" && (
            <Button.Primary
              type={"button"}
              title={arranging ? <Beat /> : "Save"}
              disabled={!data || arranging}
              className="w-20"
              onClick={handleSave}
            />
          )}
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3]" />
        {active_tab === "all-restaurants" && (
          <div className="bg-white rounded-md py-4">
            {isLoading ? (
              <TableSkeleton />
            ) : (
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <table className="w-full text-sm text-left text-[#151515]">
                  <thead className=" text-sm font-bold">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        S/N
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th scope="col" className="py-3 px-6">
                        Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Position
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Created On
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Phone Number
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Adjust
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="restaurant">
                    {(provided) => (
                      <tbody
                        className="restaurant text-sm"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {restaurants?.map((restaurant, index) => (
                          <Draggable
                            draggableId={restaurant?.restaurant_name}
                            index={index}
                            key={restaurant.id}
                          >
                            {(provided) => (
                              <tr
                                className="bg-white border-b"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className="py-4 px-6">{index + 1}</td>
                                <td className="p-4 w-4">
                                  <div className="flex items-center">
                                    <CheckBox
                                      setPosition={setPosition}
                                      setShowModal={setShowModal}
                                      setSelectId={setSelectId}
                                      selectId={selectId}
                                      position={restaurant?.position}
                                      restaurant_id={restaurant?.id}
                                    />
                                  </div>
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant?.restaurant_name}
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant?.position}
                                </td>
                                <td className="py-4 px-6">
                                  {moment(restaurant?.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant.restaurant_phone}
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant?.business_approved === true ? (
                                    <span className="text-[#00D220] font-bold">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-primary font-bold">
                                      Inactive
                                    </span>
                                  )}
                                </td>

                                <td
                                  className="py-4 px-6"
                                  {...provided.dragHandleProps}
                                >
                                  {
                                    <img
                                      className="cursor-pointer"
                                      src={Adjust}
                                      alt="adjust"
                                    ></img>
                                  }
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            )}

            <Pagination
              data={allRestaurants}
              route={"admin/settings/arrangement/restaurant"}
            />
          </div>
        )}

        {active_tab === "searched" && (
          <div className="bg-white rounded-md py-4">
            {searching ? (
              <TableSkeleton />
            ) : (
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <table className="w-full text-sm text-left text-[#151515]">
                  <thead className=" text-sm font-bold">
                    <tr>
                      <th scope="col" className="py-3 px-6">
                        S/N
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th scope="col" className="py-3 px-6">
                        Name
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Created On
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Phone Number
                      </th>
                      <th scope="col" className="py-3 px-6">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="restaurant">
                    {(provided) => (
                      <tbody
                        className="restaurant text-sm"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {search?.map((restaurant, index) => (
                          <Draggable
                            draggableId={restaurant?.restaurant_name}
                            index={index}
                            key={restaurant.id}
                          >
                            {(provided) => (
                              <tr
                                className="bg-white border-b"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className="py-4 px-6">{index + 1}</td>
                                <td className="p-4 w-4">
                                  <div className="flex items-center">
                                    <CheckBox
                                      setPosition={setPosition}
                                      setShowModal={setShowModal}
                                      setSelectId={setSelectId}
                                      selectId={selectId}
                                      position={restaurant?.position}
                                      restaurant_id={restaurant?.restaurant_id}
                                    />
                                  </div>
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant.restaurant_name}
                                </td>
                                <td className="py-4 px-6">
                                  {moment(restaurant?.created_at).format(
                                    "MMM DD, YYYY"
                                  )}
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant.restaurant_phone}
                                </td>
                                <td className="py-4 px-6">
                                  {restaurant?.business_approved === true ? (
                                    <span className="text-[#00D220]">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-primary">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            )}
          </div>
        )}
      </div>

      {showModal && (
        <div className="absolute top-0 bottom-0 h-full w-full bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="relative bg-white mt-20 mx-auto w-[990px] rounded-md py-5 px-8 flex justify-around">
            <button
              type="button"
              className="text-black block absolute top-5 right-5"
              onClick={() => {
                setShowModal(false);
                setSelectId("");
              }}
            >
              <span className="sr-only">Close</span>
              <img src={CLOSE} alt="close" className=" w-3 h-3" />
            </button>

            <Input.Label
              title={"Current Position"}
              className={"w-80"}
              defaultValue={position}
              disabled
            />

            <Input.Label
              title={"New Position"}
              htmlFor="position"
              name="position"
              onChange={(e) => setData(e.target.value)}
              className={"w-80"}
            />
            <Button.Primary
              title={arranging ? <Beat /> : "Change Position"}
              disabled={arranging}
              className={"h-11 mt-6"}
              onClick={handlePosition}
            />
          </div>
        </div>
      )}
    </div>
  );
}
