import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import AddPackageSkeleton from "../../skeleton/addPackage";
import { getAllCategories } from "../../../redux/productCatergory/productCategoryAction";
import { filterMerchandises } from "../../../redux/merchandise/merchandiseActions";
import {
  getOutletFromCookies,
  getTeamFromCookies,
  getUserFromCookies,
} from "../../../utils/Auth";
import { searchAllStores } from "../../../redux/stores/storeActions";
import storeType from "../../../redux/stores/storeTypes";

export default function FilterMerchandise({
  payLoad,
  page,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const team = getTeamFromCookies();

  const { searchStore, searching } = useSelector((state) => state.store);
  const { isLoading, filtering } = useSelector((state) => state.merchandise);
  const { allCategories } = useSelector((state) => state.productCatergory);

  const [errorMsg, setErrorMsg] = useState(false);
  const [searchData, setsearchData] = useState([]);
  const [searchValue, setSearchValue] = useState("");

  const [data, setData] = useState({
    merchandise_name: "",
    merchandise_categories: [],
    merchandise_amount: "",
    start_date: "",
    end_date: "",
    store_id: "",
  });
  const {
    merchandise_name,
    merchandise_categories,
    merchandise_amount,
    start_date,
    end_date,
    store_id,
  } = data;

  const user = getUserFromCookies();
  let business = Object.keys(user?.groups)[0];
  useEffect(() => {
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (searchStore) {
      setsearchData(searchStore);
    }
  }, [searchStore]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "merchandise_categories") {
      setData({ ...data, merchandise_categories: [value] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setSearchValue(value);
    if (value.length >= 3) {
      dispatch(
        searchAllStores({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      let searchStore = { data: [] };
      dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
    }
  };

  function handleSelected(result) {
    setData((data) => ({
      ...data,
      store_id: result.id,
    }));

    setSearchValue(result.store_name);

    let searchStore = { data: [] };
    dispatch({ type: storeType["SEARCH_STORES"], payLoad: searchStore });
  }

  const handleReset = async () => {
    setData({
      merchandise_name: "",
      merchandise_categories: [],
      merchandise_amount: "",
      start_date: "",
      end_date: "",
      store_id: "",
    });
    try {
      const response = await dispatch(filterMerchandises(payLoad, page));
      if (response?.status === "success") {
        setFilterState(!isFilterOpen);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    cleanInput(data);
    let newData = { ...data, ...payLoad };

    if (team !== "administrator") {
      newData.business_id = business;
      newData.store_id = outlet?.id;
    }
    if (
      merchandise_name ||
      merchandise_categories?.length > 0 ||
      merchandise_amount ||
      start_date ||
      end_date ||
      store_id
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterMerchandises(newData, page));
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1 relative">
          <Input.Label
            title={"Store name"}
            type="search"
            id="search"
            value={searchValue}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Search stores."
            onChange={handleSearch}
          />
          {searching ? (
            <div className="absolute w-full bg-white shadow top-[68px]">
              <div className="p-3">
                <AddPackageSkeleton />
              </div>
            </div>
          ) : (
            searchData?.length > 0 && (
              <div className="p-3 px-1">
                {searchData?.length > 0 &&
                  searchData?.map((item, i) => (
                    <div
                      key={i}
                      className="cursor-pointer mb-2 hover:border-b"
                      onClick={() => handleSelected(item)}
                    >
                      <div>
                        <p className="text-sm">{item?.store_name}</p>
                      </div>
                    </div>
                  ))}
              </div>
            )
          )}
          <Input.Label
            title={"Merchandise Name"}
            htmlFor={"merchandise_name"}
            name={"merchandise_name"}
            onChange={handleChange}
            autoComplete="off"
            value={merchandise_name}
            placeholder={"Enter Merchandise Name"}
          />
          <Input.Number
            title={"Price"}
            htmlFor={"merchandise_amount"}
            name={"merchandise_amount"}
            onChange={handleChange}
            autoComplete="off"
            value={merchandise_amount}
            placeholder={"Enter Price"}
          />

          <Input.Select
            title={"Category"}
            htmlFor={"merchandise_categories"}
            name={"merchandise_categories"}
            value={merchandise_categories?.[0]}
            onChange={handleChange}
            placeholder={"Enter City"}
          >
            <option>Select Category</option>
            {allCategories?.data?.length > 0 &&
              allCategories?.data?.map(
                (category, i) =>
                  category.type === "store" && (
                    <option key={i} value={category?.name}>
                      {category?.name}
                    </option>
                  )
              )}
          </Input.Select>
          <Input.DatePicker
            title={"Start Date"}
            htmlFor={"start_date"}
            name={"start_date"}
            value={start_date}
            onChange={handleChange}
            selected
          />
          <Input.DatePicker
            title={"End Date"}
            htmlFor={"end_date"}
            name={"end_date"}
            value={end_date}
            onChange={handleChange}
            selected
          />
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={isLoading}
            title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </>
  );
}
