import inviteType from "./inviteTypes";

const initialState = {
  allInvites: [],
  loading: false,
  storeInvite: {},
  showInvite: {},
  editInvite: {},
  deleteInvite: {},
  myInvites: {},
  refuseInvite: {},
  filterInvites: [],
  searchInvites: [],
};
export default function inviteReducer(state = initialState, action) {
  switch (action.type) {
    case inviteType.GET_ALL_INVITES:
      return {
        ...state,
        allInvites: action.payLoad?.data?.data,
      };
    case inviteType.STORE_INVITE:
      return {
        ...state,
        storeInvite: action.payLoad.data.data,
      };
    case inviteType.DELETE_INVITE:
      return {
        ...state,
        deleteInvite: action.payLoad.data.data,
      };
    case inviteType.EDIT_INVITE:
      return {
        ...state,
        editInvite: action.payLoad.data.data,
      };
    case inviteType.SHOW_INVITE:
      return {
        ...state,
        showInvite: action.payLoad.data.data,
      };
    case inviteType.SHOW_MY_INVITE:
      return {
        ...state,
        myInvites: action.payLoad?.data,
      };
    case inviteType.REFUSE_INVITE:
      return {
        ...state,
        refuseInvites: action.payLoad.data.data,
      };
    case inviteType.FILTER_INVITES:
      return {
        ...state,
        filterInvites: action.payLoad.data.data,
      };
    case inviteType.SEARCH_INVITES:
      return {
        ...state,
        searchInvites: action.payLoad.data.data,
      };

    case inviteType.LOADING_INVITE:
      return {
        ...state,
        loading: action.payLoad,
      };
    default:
      return state;
  }
}
