import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Table from "../../../../components/tables/tableCols1";
import Card from "../../../../components/cards/admin/cards";
import TableSkeleton from "../../../../components/skeleton/table";
import { Pagination } from "../../../../components/pagination/pagination";
import { getAllRolesAction } from "../../../../redux/roles/rolesActions";
import EmptyState from "../../../../assets/images/icons/settingsEmpty.svg";
import { AddNewRoleModal } from "../../../../components/modals/roles/addNewRoleModal";
import Button from "../../../../components/buttons/buttons";

export default function AllRoles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { allRoles, isLoading } = useSelector((state) => state.roles);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    dispatch(getAllRolesAction());
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((role, index) => {
        let show = {
          id: index + 1,
          name: role?.display_name,
          description: role?.description,
          // action: <span className="text-[#0E7DE3]">Manage Permission</span>,
          role_id: role?.id,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allRoles?.data);
    setData(dataSet);
    // eslint-disable-next-line
  }, [allRoles]);

  function goToSinglePage(params) {
    let role_id = params?.show?.role_id;
    navigate(`/admin/settings/roles/${role_id}`);
  }

  const handleChange = (e) => {
    const { value } = e.target;
    e.preventDefault();
    setSearch(value);
  };

  useEffect(() => {
    if (search?.length > 2) {
      const data = allRoles?.data?.filter((role) =>
        role.display_name.toLowerCase().includes(search.toLowerCase())
      );
      const dataSet = createTableData(data);
      setData(dataSet);
    } else if (search?.length === 0) {
      const dataSet = createTableData(allRoles?.data);
      setData(dataSet);
    }
    // eslint-disable-next-line
  }, [search]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Roles",
            accessor: "show.name",
          },
          {
            Header: "Description",
            accessor: "show.description",
          },
          // {
          //   Header: "Action",
          //   accessor: "show.action",
          // },
        ],
      },
    ],
    []
  );
  return (
    <>
      <AddNewRoleModal show={isOpen} onClose={closeModal} />
      <div className="mt-12">
        <Card.TableFilter
          title={"Roles"}
          searchPlaceholder={"Search Roles"}
          onChange={handleChange}
          // filter={<FilterRestaurantDropDown />}
        >
          <Button.Primary title={"+ Add new role"} onClick={openModal} />
        </Card.TableFilter>
        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white rounded-md py-4 ">
          {isLoading ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              rowClass={"hover:shadow-md cursor-pointer"}
              onClick={goToSinglePage}
            />
          ) : (
            <div className="py-24 text-center">
              <div className="flex justify-center">
                <img src={EmptyState} className="w-auto" alt="no data" />
              </div>
              <h3 className="mt-5 text-xl font-bold">Roles</h3>
              <p className="mt-2 text-sm text-[#9CA3AF]">No roles found.</p>
            </div>
          )}
        </div>
        <Pagination />
      </div>
    </>
  );
}
