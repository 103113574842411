import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Switch } from "@headlessui/react";
import moment from "moment";
import ImageError from "../../../../assets/images/emptyState/category-list.jpg";
import { getAPackageAction } from "../../../../redux/packages/packagesActions";
import {
  approvePackageByMgtAction,
  unapprovePackageByMgtAction,
} from "../../../../redux/packages/packagesActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleMerchandisePackage() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [enabled, setEnabled] = useState();
  let { singlePackage } = useSelector((state) => state.packages);

  useEffect(() => {
    if (id !== "") {
      dispatch(getAPackageAction(id));
    }
  }, [id, dispatch]);

  const onImageError = (e) => {
    e.target.src = ImageError;
  };

  useEffect(() => {
    setEnabled(singlePackage?.management_approved);
  }, [singlePackage?.management_approved]);

  // const columns = useMemo(
  //   () => [
  //     {
  //       Header: "",
  //       hideHeader: false,
  //       id: "checkbox-table-column",
  //       // First group columns
  //       columns: [
  //         {
  //           Header: "Name",
  //           accessor: "show.name",
  //         },
  //         {
  //           Header: "Phone Number",
  //           accessor: "show.phone",
  //         },
  //         {
  //           Header: "Email",
  //           accessor: "show.email",
  //         },
  //         {
  //           Header: "Amount",
  //           accessor: "show.amount",
  //         },
  //         {
  //           Header: "Subscription Date",
  //           accessor: "show.subscription_date",
  //         },
  //         {
  //           Header: "Status",
  //           accessor: "show.status",
  //         },
  //       ],
  //     },
  //   ],
  //   []
  // );

  const handleApprove = () => {
    dispatch(approvePackageByMgtAction({ id: singlePackage?.id }));
  };

  const handleUnapprove = () => {
    dispatch(unapprovePackageByMgtAction({ id: singlePackage?.id }));
  };

  return (
    <div data-test="singlePackage-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Package Details</h3>
          <button
            className="text-primary"
            onClick={() =>
              navigate(`/admin/merchandise-packages/edit/${singlePackage?.id}`)
            }
          >
            Edit Details
          </button>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <figure className="h-[200px] w-[240px]">
            <img
              className="object-cover h-full"
              src={singlePackage?.package_pictures?.[0] ?? ImageError}
              onError={onImageError}
              alt="package"
            />
          </figure>

          <div className="col-span-4 grid grid-cols-4 gap-8">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Package Name
              </label>
              <p className="text-[#151515] text-sm">
                {singlePackage?.package_name}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Package type
              </label>
              <p className="text-[#151515] text-sm">
                {singlePackage?.package_type}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Minimum Price
              </label>
              <p className="text-[#151515] text-sm">
                NGN {singlePackage?.package_average_amount}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2 block">
                {singlePackage?.management_approved
                  ? "Unapprove Package"
                  : "Approve Package"}
              </label>
              <Switch.Group as="div" className="flex">
                <Switch
                  checked={enabled}
                  onChange={(e) => {
                    setEnabled(e);
                    singlePackage?.management_approved
                      ? handleUnapprove()
                      : handleApprove();
                  }}
                  className={classNames(
                    enabled ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      enabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
              <p className="text-[#151515] text-sm">
                {moment(singlePackage?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              <p className="text-[#151515] text-sm">
                {singlePackage?.management_approved ? "Approved" : "Unapproved"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Description:
              </label>
              <p className="text-[#151515] text-sm">
                {singlePackage?.package_description}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-xl font-medium">Subscribed Users</h3>
        </div>
        <Table
          columns={columns}
          data={data}
          onClick={goToSinglePage}
          className="w-full"
          rowClass="hover:shadow-md"
        />
      </div>

      <Pagination /> */}
    </div>
  );
}
