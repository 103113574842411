import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ReactComponent as Filter } from "../../assets/images/icons/filter.svg";
import CLOSE from "../../assets/images/icons/close.svg";
import { getAllTags } from "../../redux/productTags/productTagActions";

export function FilterStoreModal({ categories, setFilterCount, handleFilter }) {
  const dispatch = useDispatch();
  let [isOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const [tags, setTags] = useState([]);
  const [tagsArr, setTagsArr] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [data, setData] = useState({
    store_categories: [],
    store_tags: [],
  });
  const { store_categories, store_tags } = data;
  const { allTags } = useSelector((state) => state.productTag);
  useEffect(() => {
    dispatch(getAllTags());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "store");
    setTags(items);
  }, [allTags]);

  const handleTags = (tag, i) => {
    let arr = [];
    let newArr = [];
    let tagIndex = [...tagsArr];
    let newtagIndex = [];
    if (data?.store_tags) {
      arr = [...data.store_tags];
      if (arr.includes(tag?.name)) {
        newArr = arr.filter((name) => name !== tag.name);
      } else {
        newArr = [...arr, tag.name];
      }
      setData({
        ...data,
        store_tags: newArr,
      });
      // track the index of items added and use their index to indicate as selected
      if (tagIndex.includes(i)) {
        newtagIndex = tagIndex.filter((index) => index !== i);
      } else {
        newtagIndex = [...tagIndex, i];
      }
      setTagsArr(newtagIndex);
    } else {
      let new_tags = [...arr, tag?.name];
      setData({ ...data, store_tags: new_tags });
      newtagIndex = [...tagIndex, i];
      setTagsArr(newtagIndex);
    }
  };

  const handleCategories = (category,i) => {
    let arr = [];
    let newArr = [];
    let catIndex = [...categoryArr];
    let newcatIndex = [];
    if (data?.store_categories) {
      arr = [...data?.store_categories];
      if (arr.includes(category?.name)) {
        newArr = arr.filter((name) => name !== category.name);
      } else {
        newArr = [...arr, category.name];
      }
      setData({
        ...data,
        store_categories: newArr,
      });
      // track the index of items added and use their index to indicate as selected
      if (catIndex.includes(i)) {
        newcatIndex = catIndex.filter((index) => index !== i);
      } else {
        newcatIndex = [...catIndex, i];
      }
      setCategoryArr(newcatIndex);
    } else {
      let new_tags = [...arr, category?.name];
      setData({ ...data, store_categories: new_tags });
      newcatIndex = [...catIndex, i];
      setCategoryArr(newcatIndex);
    }
    
    
  };

  const handleClear = () => {
    setData({
      ...data,
      store_tags: [],
      store_categories: [],
    });
    setTagsArr([]);
    setCategoryArr([]);
    setFilterCount(0);
    closeModal();
  };

  return (
    <div data-test="filterModal">
      <Filter className=" cursor-pointer  h-6 w-6" onClick={openModal} />
      <Transition show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[600px]  bg-white rounded-3xl px-4 pt-5 pb-4  overflow-hidden shadow-xl transform transition-all sm:my-10 sm:max-w-lg sm:w-full  sm:p-8">
                  <div className=" sm:block absolute top-1 sm:top-3 right-5 pt-4 pr-4">
                    <button
                      type="button"
                      className="bg-[#ffeae5] rounded-md sm:p-3 p-2"
                      onClick={closeModal}
                    >
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>
                  <div>
                    <div className=" text-center sm:mt-6 sm:text-center">
                      <Dialog.Title
                        as="h3"
                        className="text-xl mb-4 font-bold text-black"
                      >
                        Filter
                      </Dialog.Title>
                    </div>
                  </div>{" "}
                  <div>
                    <div className="border-b-2"></div>
                    <h3 className=" text-lg font-medium px-4 text-left sm:text-left mt-6 ">
                      Sort by Tags
                    </h3>

                    <div className="flex flex-wrap gap-3 mt-3">
                      {tags?.length > 0 &&
                        tags.map((tag, i) => (
                          <button
                            key={i}
                            className={`${
                              tagsArr?.includes(i)
                                ? "bg-primary text-white"
                                : "bg-[#F6F3F3] text-[#4D4C4C]"
                            }  rounded-md px-2 py-2 text-center text-sm cursor-pointer`}
                            onClick={() => handleTags(tag, i)}
                          >
                            {tag?.name}
                          </button>
                        ))}
                    </div>

                    <h3 className=" text-lg font-medium px-4 text-left sm:text-left mt-12 ">
                      Sort by Categories
                    </h3>
                    <div className="flex flex-wrap gap-3 mt-3">
                      {categories?.length > 0 &&
                        categories?.map((category, i) => (
                          <button
                            key={i}
                            className={`${
                              categoryArr?.includes(i)
                                ? "bg-primary text-white"
                                : "bg-[#F6F3F3] text-[#4D4C4C]"
                            } rounded-md px-2 py-2 text-center text-sm cursor-pointer`}
                            onClick={() => handleCategories(category, i)}
                          >
                            {category?.name}
                          </button>
                        ))}
                    </div>

                    <div className="flex justify-between mt-32">
                      <button
                        className="bg-white text-primary w-48 h-12 sm:w-60 sm:mr-4 border border-primary rounded-lg"
                        type="button"
                        onClick={handleClear}
                      >
                        Clear All
                      </button>
                      <button
                        className="bg-primary text-white w-48 h-12 sm:w-60 rounded-lg "
                        type="button"
                        // onClick={() => props.onClick(data)}
                        onClick={() => {
                          setFilterCount(
                            store_tags.length + store_categories.length
                          );
                          handleFilter(data);
                          closeModal();
                        }}
                      >
                        Apply Filter
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
