import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import Sms from "./Sms";
import Email from "./Email";
import Whatsapp from './Whatsapp'
import PushNotification from "./PushNotification";
import { filterNotifierGroup } from "../../../../redux/notifierGroup/notifierGroupAction";

const tabs = [
  { name: "Emails", href: "email" },
  { name: "Push Notifications", href: "push-notifications" },
  { name: "Sms", href: "sms" },
  { name: "Whatsapp", href: "whatsapp" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function BroadcastNotifications() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const DEFAULT_ACTIVE_TAB = "email";
  const { active_tab } = useParams();
  const { tab } = useParams();

  const [payLoad] = useState({
    end_date: new Date(),
    pagination: 0,
  });

  useEffect(() => {
    if (active_tab === "broadcast-notification") {
      navigate(
        `/admin/settings/broadcast-notification/${DEFAULT_ACTIVE_TAB}/1`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(filterNotifierGroup(payLoad));
  }, [dispatch, payLoad]);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/settings/broadcast-notification/${tab.href}/1`);
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="sm:block">
            <div>
              <nav
                className="-mb-px flex space-x-4 overflow-x-auto"
                aria-label="Tabs"
              >
                {tabs.map((singleTab) => (
                  <button
                    key={singleTab.name}
                    onClick={() => {
                      toggle(singleTab);
                    }}
                    className={classNames(
                      singleTab.href === tab
                        ? "border-primary text-primary font-bold"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4  border-b-2 font-medium text-sm"
                    )}
                    aria-current={singleTab.href === tab ? "page" : undefined}
                  >
                    {singleTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        {tab === "sms" && <Sms />}
        {tab === "email" && <Email />}
        {tab === "push-notifications" && <PushNotification />}
        {tab === "whatsapp" && <Whatsapp />}
      </div>
    </div>
  );
}
