import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Card from "../../../../components/cards/admin/cards";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import Adjust from "../../../../assets/images/icons/adjust.svg";
import CLOSE from "../../../../assets/images/icons/close.svg";
import TableSkeleton from "../../../../components/skeleton/table";
import { Beat } from "../../../../plugins/spinners.plugin";
import { Pagination } from "../../../../components/pagination/pagination";
import { searchFeaturedProducts } from "../../../../redux/feature/featureActions";
import { getAllFeaturedProducts } from "../../../../redux/feature/featureActions";
import { featureArrangements } from "../../../../redux/arrangements/arrangementAction";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const CheckBox = ({
  setPosition,
  selectId,
  setSelectId,
  feature_id,
  position,
  setShowModal,
}) => {
  return (
    <div
      onClick={() => {
        setSelectId(feature_id);
        setShowModal(true);
        setPosition(position);
      }}
      className={` w-4 h-4 text-primary bg-gray-100 rounded border
       ${
         selectId === feature_id
           ? "border-primary bg-primary"
           : "border-gray-300"
       }`}
    />
  );
};

export default function AllFeaturedProducts() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { page } = useParams();
  const { allFeatured, isLoading, filtering, search, searching } = useSelector(
    (state) => state.feature
  );
  const { arranging } = useSelector((state) => state.arrangement);

  const [featured, setFeatured] = useState(allFeatured?.data);
  const [data, setData] = useState({ position: "" });
  const [selectId, setSelectId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [position, setPosition] = useState(false);
  const [active_tab, setActive_tab] = useState("all-featured");
  useEffect(() => {
    dispatch(getAllFeaturedProducts(page));
  }, [dispatch, page]);

  useEffect(() => {
    if (!page) {
      navigate(`/admin/settings/arrangement/features/1`);
    }
  }, [page, navigate]);

  useEffect(() => {
    setFeatured(allFeatured?.data);
    // eslint-disable-next-line
  }, [allFeatured]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(featured);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setFeatured(items);
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      setActive_tab("searched");
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      setFeatured(allFeatured?.data);
    }
  };
  const [input, setInput] = useState({
    search: "",
  });
  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchFeaturedProducts(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const handlePosition = () => {
    const lists = {};
    search?.map((list) => {
      lists[list.id] = parseInt(data);
      return list;
    });
    dispatch(featureArrangements({ list: lists }));
    removeSearch();
  };
  const handleSave = async () => {
    // if the page = 1, position number will start from 0,
    // else position number needs to add 1 to account for zero indexing
    const position_number = () => {
      if (page > 1) {
        let last_item = Number((page - 1) * 24);
        return last_item + 1;
      } else {
        return Number((page - 1) * 24);
      }
    };
    const lists = featured?.reduce((acc, list, i) => {
      acc[list.id] = i + position_number();
      return acc;
    }, {});

    try {
      const response = await dispatch(featureArrangements({ list: lists }));
      if (response) {
        dispatch(getAllFeaturedProducts(page));
      }
    } catch (error) {}
  };
  return (
    <div className="relative">
      <div data-test="all-feature-page">
        <Card.TableFilter
          title={"Features Arrangement"}
          searchPlaceholder="Product name, price or duration"
          onChange={handleChange}
        >
          {active_tab === "all-featured" && (
            <Button.Primary
              type={"button"}
              title={arranging ? <Beat /> : "Save"}
              disabled={!data || arranging}
              className=" w-20"
              onClick={handleSave}
            />
          )}
        </Card.TableFilter>
        <div className="border-b border-[#E4E4F3]" />
        {active_tab === "all-featured" && (
          <div className="bg-white rounded-md py-4 px-8">
            {isLoading || filtering ? (
              <TableSkeleton />
            ) : (
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <table className="w-full text-sm text-left text-[#151515]">
                  <thead className=" text-sm font-bold">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        S/N
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Food/Merchandise
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Position
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Feature Duration (hrs)
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Paid/Unpaid
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Adjust
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="feature">
                    {(provided) => (
                      <tbody
                        className="feature text-lg"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {featured?.map((feature, index) => (
                          <Draggable
                            draggableId={`${feature?.id}`}
                            index={index}
                            key={feature?.id}
                          >
                            {(provided) => (
                              <tr
                                className="bg-white border-b hover:shadow-md cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className="py-4 px-8 text-sm">
                                  {index + 1}
                                </td>
                                <td className="p-4 w-4">
                                  <div className="flex items-center">
                                    <CheckBox
                                      setPosition={setPosition}
                                      setShowModal={setShowModal}
                                      setSelectId={setSelectId}
                                      selectId={selectId}
                                      position={feature?.position}
                                      feature_id={feature?.id}
                                    />
                                  </div>
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.product?.merchandise_name ??
                                    feature?.product?.food_name}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.position}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {numberWithCommas(feature?.featured_amount)}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature.status === "active" ? (
                                    <span className="text-green-400">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-red-600">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.featured_duration_in_hours}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.featured_paid ? (
                                    <span className="text-green-400">Paid</span>
                                  ) : (
                                    <span className="text-red-600">Unpaid</span>
                                  )}
                                </td>
                                <td
                                  className="py-4 px-8"
                                  {...provided.dragHandleProps}
                                >
                                  {
                                    <img
                                      className="cursor-pointer"
                                      src={Adjust}
                                      alt="adjust"
                                    />
                                  }
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            )}

            <Pagination data={allFeatured} route={"admin/feature/all"} />
          </div>
        )}

        {active_tab === "searched" && (
          <div className="bg-white rounded-md py-4 px-8">
            {searching ? (
              <TableSkeleton />
            ) : (
              <DragDropContext onDragEnd={(results) => handleDragEnd(results)}>
                <table className="w-full text-sm text-left text-[#151515]">
                  <thead className=" text-sm font-bold">
                    <tr>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        S/N
                      </th>
                      <th scope="col" className="py-3 px-6"></th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Food/Merchandise
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Price
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Feature Duration (hrs)
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Paid/Unpaid
                      </th>
                      <th
                        scope="col"
                        className="py-3 px-8 border-b border-[#E4E4F3]"
                      >
                        Adjust
                      </th>
                    </tr>
                  </thead>
                  <Droppable droppableId="feature">
                    {(provided) => (
                      <tbody
                        className="feature text-lg"
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {search?.map((feature, index) => (
                          <Draggable
                            draggableId={`${feature?.id}`}
                            index={index}
                            key={feature?.id}
                          >
                            {(provided) => (
                              <tr
                                className="bg-white border-b hover:shadow-md cursor-pointer"
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                              >
                                <td className="py-4 px-8 text-sm">
                                  {index + 1}
                                </td>

                                <td className="p-4 w-4">
                                  <div className="flex items-center">
                                    <CheckBox
                                      setPosition={setPosition}
                                      setShowModal={setShowModal}
                                      setSelectId={setSelectId}
                                      selectId={selectId}
                                      position={feature?.position}
                                      restaurant_id={feature?.feature_id}
                                    />
                                  </div>
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.merchandise?.merchandise_name ??
                                    feature?.food?.food_name}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {numberWithCommas(feature?.featured_amount)}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature.status === "active" ? (
                                    <span className="text-green-400">
                                      Active
                                    </span>
                                  ) : (
                                    <span className="text-red-600">
                                      Inactive
                                    </span>
                                  )}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.featured_duration_in_hours}
                                </td>
                                <td className="py-4 px-8 text-sm">
                                  {feature?.featured_paid ? (
                                    <span className="text-green-400">Paid</span>
                                  ) : (
                                    <span className="text-red-600">Unpaid</span>
                                  )}
                                </td>
                                <td
                                  className="py-4 px-8"
                                  {...provided.dragHandleProps}
                                >
                                  {
                                    <img
                                      className="cursor-pointer"
                                      src={Adjust}
                                      alt="adjust"
                                    />
                                  }
                                </td>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </table>
              </DragDropContext>
            )}
          </div>
        )}
      </div>

      {showModal && (
        <div className="absolute top-0 bottom-0 h-full w-full bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="relative bg-white mt-20 mx-auto w-[990px] rounded-md py-5 px-8 flex justify-around">
            <button
              type="button"
              className="text-black block absolute top-5 right-5"
              onClick={() => {
                setShowModal(false);
                setSelectId("");
              }}
            >
              <span className="sr-only">Close</span>
              <img src={CLOSE} alt="close" className=" w-3 h-3" />
            </button>

            <Input.Label
              title={"Current Position"}
              className={"w-80"}
              defaultValue={position}
              disabled
            />

            <Input.Label
              title={"New Position"}
              htmlFor="position"
              name="position"
              onChange={(e) => setData(e.target.value)}
              className={"w-80"}
            />
            <Button.Primary
              title={arranging ? <Beat /> : "Change Position"}
              disabled={arranging}
              className={"h-11 mt-6"}
              onClick={handlePosition}
            />
          </div>
        </div>
      )}
    </div>
  );
}
