import axios from "../plugins/axios.plugin";

class CatalogueService {
  baseServicePath = "/api/catalogue";

  getMyCatalogue() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }
  createCatalogue(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  getACatalogue(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  getAllCatalogues(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  editMyCatalogue(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  approveCatalogue(payLoad) {
    return axios.post(`${this.baseServicePath}/approve/management`, payLoad);
  }
  unapproveCatalogue(payLoad) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payLoad);
  }

  searchCatalogue(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterCatalogue(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  filterCatalogueNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
}

export const catalogueService = new CatalogueService();
