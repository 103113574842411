import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/restaurant.svg";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";

import transactionType from "../../../../redux/walletTransaction/trsansactionTypes";
import {
  filterTransactionsAction,
  searchTransactionsAction,
} from "../../../../redux/walletTransaction/transactionActions";
import FilterWalletTransactionDropDown from "../../../../components/dropDown/filter/filterWalletTransactions";

export default function Pending() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    status: "pending",
  });

  const { allTransactions, downloading } = useSelector(
    (state) => state.walletTransaction
  );
  const { filtering, searching } = useSelector(
    (state) => state.walletTransaction
  );

  useEffect(() => {
    dispatch(filterTransactionsAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(myData) {
    let dataToMap = myData;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((transaction, index) => {
        let show = {
          sn: index + 1,
          reference_id: transaction.reference_id,
          status: (
            <>
              <span className="text-[#FFA927] font-bold uppercase">
                {transaction.status}
              </span>
            </>
          ),
          amount: transaction.amount,
          created: new Date(transaction.created_at).toDateString(),
          transaction: transaction,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(myData) {
    let dataToMap = myData;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((transaction, index) => {
        let show = {
          sn: index + 1,
          reference: transaction.reference_id,
          status: transaction.status,
          amount: transaction.amount,
          created: transaction.created_at,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allTransactions?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTransactions]);

  function goToSinglePage(params) {
    let id = params?.show?.transaction?.reference_id;
    navigate(`/admin/wallet-transactions/${id}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allTransactions?.data);
      setData(dataSet);
      dispatch({
        type: transactionType["SEARCH_TRANSACTION"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchTransactionsAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      filterTransactionsAction({
        status: "initiated",
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Incomplete_Transactions.xlsx",
        download: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.sn",
          },
          {
            Header: "Transaction ID",
            accessor: "show.reference_id",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Created",
            accessor: "show.created",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <TableFilter
        results={allTransactions?.to}
        totalResults={allTransactions?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterWalletTransactionDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Transaction Reference ID"}
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Transactions</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any incomplete transactions yet.
            </p>
          </div>
        )}
        <Pagination
          data={allTransactions}
          route={"admin/wallet-transactions/incomplete"}
        />
      </div>
    </div>
  );
}
