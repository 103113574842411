import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Container from "../../../../components/container/container";
import Input from "../../../../components/inputs/inputs";
import { Beat } from "../../../../plugins/spinners.plugin";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import { getAllTags } from "../../../../redux/productTags/productTagActions";
import { getAllCategories } from "../../../../redux/productCatergory/productCategoryAction";

export default function OnboardStore({
  handleChange,
  data,
  setData,
  handleSubmit,
  submitted,
  Location,
  locationId,
  locationList,
  setLocationList,
  setLocationId,
  handleRegion,
  store_phone,
  setStorePhone,
}) {
  const dispatch = useDispatch();
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const {
    store_name,
    store_email,
    store_address,
    store_city,
    store_state,
    store_country,
    store_description,
    store_categories,
    store_tags,
  } = data;

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  const [storeCategories, setStoreCategories] = useState([]);
  const [storeTags, setStoreTags] = useState([]);
  const { allTags } = useSelector((state) => state.productTag);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading } = useSelector((state) => state.onboard);

  useEffect(() => {
    const storeCategories = allCategories?.data?.filter((item) => {
      return item?.type === "store";
    });
    setStoreCategories(storeCategories);
    // eslint-disable-next-line
  }, [allCategories]);

  useEffect(() => {
    const storeTags = allTags?.data?.filter((item) => {
      return item?.type === "store";
    });
    setStoreTags(storeTags);
    // eslint-disable-next-line
  }, [allTags]);

  const handleAddTags = (value) => {
    const former_tags = [...data?.store_tags];
    let new_tags = [...former_tags, value.name];
    setData({ ...data, store_tags: new_tags });
  };

  const handleAddCategories = (value) => {
    const former_categories = [...data?.store_categories];
    let new_categories = [...former_categories, value.name];
    setData({ ...data, store_categories: new_categories });
  };

  return (
    <div>
      <Container>
        <div className="py-16">
          <h2
            className="text-5xl font-bold mb-5"
            style={{ lineHeight: "64px" }}
          >
            Store Details
          </h2>
        </div>
      </Container>
      <div
        className="pt-4 sm:pt-2 sm:flex items-center mx-auto"
        data-test="createStoreComponent"
      >
        <div className=" max-w-4xl mx-auto">
          <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
            <form name="form " id="business-form" onSubmit={handleSubmit}>
              <div className="border-b-2 py-4 mb-6">
                <p className=" text-base font-medium text-gray-800 flex justify-center ">
                  Store Details
                </p>
              </div>
              <div className=" sm:px-10">
                <div
                  className={
                    "mt-4" + (submitted && store_name ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Name "}
                    htmlFor={"store_name"}
                    type={"text"}
                    name={"store_name"}
                    placeholder={"Enter store name"}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    "mt-4" + (submitted && store_email ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Email *"}
                    htmlFor={"store_email"}
                    type={"email"}
                    name={"store_email"}
                    placeholder={"Enter store email address"}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    "mt-4" + (submitted && store_phone ? " border-red-500" : "")
                  }
                >
                  <Input.Phone
                    defaultCountry="NG"
                    title={"Store Phone Number"}
                    htmlFor={"store_phone"}
                    onChange={setStorePhone}
                    value={store_phone}
                  />
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && store_address ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Store Address *"}
                    htmlFor={"store_address"}
                    type={"text"}
                    name={"store_address"}
                    placeholder={"Enter store Address"}
                    onChange={handleChange}
                  />
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && store_description ? " border-red-500" : "")
                  }
                >
                  <Input.TextArea
                    title={"Store Description "}
                    htmlFor={"store_description"}
                    type={"text"}
                    name={"store_description"}
                    placeholder={"Describe your store to us"}
                    onChange={handleChange}
                  />
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && store_categories ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_categories"
                    className="text-sm font-medium text-[#151515]"
                  >
                    Store Categories*
                  </label>
                  <SelectableInput
                    options={storeCategories}
                    label="store_categories"
                    name={"+ Add Category"}
                    type="category"
                    selectOption={handleAddCategories}
                  />
                </div>

                <div
                  className={
                    "mt-4" + (submitted && store_tags ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="store_tags"
                    className="text-sm font-medium text-[#151515]"
                  >
                    Store Tags*
                  </label>
                  <SelectableInput
                    options={storeTags}
                    label="store_tags"
                    name={"+ Add Tag"}
                    type="tag"
                    selectOption={handleAddTags}
                  />
                </div>

                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({
                      ...locationList,
                      countryList: value,
                    });
                  }}
                  stateList={(value) => {
                    setLocationList({
                      ...locationList,
                      stateList: value,
                    });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                />
                <div
                  className={
                    "mt-4" +
                    (submitted && store_country ? " border-red-500" : "")
                  }
                >
                  <label htmlFor="store_country">Country*</label>
                  <select
                    name="store_country"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select Country</option>

                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className={
                    "mt-4" + (submitted && store_state ? " border-red-500" : "")
                  }
                >
                  <label htmlFor="store_state"> State </label>
                  <select
                    name="store_state"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select state</option>

                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
                <div
                  className={
                    "mt-4 mb-16" +
                    (submitted && store_city ? " border-red-500" : "")
                  }
                >
                  <label htmlFor="store_city"> City </label>
                  <select
                    name="store_city"
                    className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFFFF] focus:outline-none focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>Select a city</option>
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </div>
            </form>
          </div>

          <button
            form="business-form"
            type="submit"
            className=" rounded-md  w-[333px] sm:mt-10 mx-auto flex justify-center shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700  sm:text-sm"
          >
            {isLoading ? <Beat color={"#ffffff"} /> : "Submit"}
          </button>
        </div>
      </div>
    </div>
  );
}
