import axios from "../plugins/axios.plugin";

class PackageSubscriptionFoodOrderService {
  baseServicePath = "/api/subscription-food/order";

  getAllSubscriptionFoodOrders() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllSubscriptionFoodOrdersNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getASubscriptionFoodOrder(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  getMySubscriptionFoodOrder() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  createSubscriptionFood(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }

  searchSubscriptionFoodOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/search/index`, payLoad);
  }

  filterSubscriptionFoodOrder(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }

  closeSubscriptionFoodOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payLoad);
  }

  shipSubscriptionFoodOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/ship`, payLoad);
  }
}

export const packageSubscriptionFoodOrderService =
  new PackageSubscriptionFoodOrderService();
