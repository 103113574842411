import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/food-package.svg";
import FilterFoodPackageDropDown from "../../../../components/dropDown/filter/filterFoodPackage";
import {
  searchSubscriptionFoodOrder,
  getAllSubscriptionFoodOrdersNoPagination,
} from "../../../../redux/packageSubscriptionFoodOrder/packageSubscriptionFoodOrderAction";
import { getUserWithRelationAction } from "../../../../redux/users/userActions";

export default function Users() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    subscription_food_orders: 1,
  });

  const { allPackageOrders, downloading } = useSelector(
    (state) => state.packageSubscriptionFoodOrder
  );
  const { isLoading, searching, search, allUsers } = useSelector(
    (state) => state.user
  );

  useEffect(() => {
    dispatch(getUserWithRelationAction(payLoad, 1));
    // eslint-disable-next-line
  }, []);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((user, index) => {
        let show = {
          recipient_name: user?.name,
          recipient_phone: user?.phone,
          recipient_email: user?.email,
          package_name: "package name",
          status: user?.status,
          days: user?.days,
          created: moment(user?.created_at).format("MMM DD, YYYY"),
          expires: moment(user?.created_at).format("MMM DD, YYYY"),
          packageData: user,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allUsers?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allUsers]);

  function goToSinglePage(params) {
    let id = params?.show?.packageData?.id;
    navigate(`/admin/food-packages/package/${id}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search?.data);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allPackageOrders?.data);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchSubscriptionFoodOrder(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllSubscriptionFoodOrdersNoPagination({
        ...payLoad,
        pagination: 0,
      })
    );
    if (response) {
      writeFileWithXLSX(response?.data);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Subscriber Name",
            accessor: "show.recipient_name",
          },
          {
            Header: "Phone Number",
            accessor: "show.recipient_phone",
          },
          {
            Header: "Subscriber Email",
            accessor: "show.recipient_email",
          },
          {
            Header: "Package Name",
            accessor: "show.package_name",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Days",
            accessor: "show.days",
          },
          {
            Header: "Created On",
            accessor: "show.created",
          },
          {
            Header: "Expires",
            accessor: "show.expires",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="subscription-page">
      <Card.TableFilter
        title={"Subscribed Users"}
        searchPlaceholder={"Order ID or Phone Number"}
        onChange={handleChange}
        filter={<FilterFoodPackageDropDown />}
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </Card.TableFilter>
      <div className="border-b border-[#E4E4F3]"></div>
      <div className="bg-white rounded-md py-4 px-8">
        {isLoading || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Food Packages</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You have not added any food package yet.
            </p>
          </div>
        )}
      </div>

      <Pagination data={allPackageOrders} />
    </div>
  );
}
