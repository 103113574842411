import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { attributeService } from "../../services/attribute.service";
import attributeType from "./attributeTypes";

export const getAllAttributesAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: true });
    const response = await attributeService.getAllAttributes(page);
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    dispatch({ type: attributeType["ALL_ATTRIBUTES"], payLoad: response });
  } catch (error) {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
  }
};

/**
 * Get set new address
 **/

export const setNewAttributeAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: true });
    const response = await attributeService.setNewAttribute(payLoad);

    dispatch({ type: attributeType["SET_ATTRIBUTE"], payLoad: response });
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    errorHandler(error, true);
  }
};

export const editAttributeAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: true });
    const response = await attributeService.editAttribute(payLoad);
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    dispatch({ type: attributeType["EDIT_ATTRIBUTE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    errorHandler(error, true);
  }
};
export const showSingleAttributeAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: true });
    const response = await attributeService.showSingleAttribute(payLoad);
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    dispatch({ type: attributeType["SINGLE_ATTRIBUTE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    errorHandler(error, true);
  }
};

export const deleteAttributeAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: true });
    const response = await attributeService.deleteAttribute(payLoad);
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    dispatch({ type: attributeType["DELETE_ATTRIBUTE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: attributeType["LOADING_ATTRIBUTE"], payLoad: false });
    errorHandler(error, true);
  }
};
export const searchAttributeAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["LOADING_SEARCH"], payLoad: true });
    const response = await attributeService.searchAttributes(payLoad);
    dispatch({ type: attributeType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: attributeType["SEARCH_ATTRIBUTE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: attributeType["LOADING_SEARCH"], payLoad: false });
  }
};

export const filterAttributeAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: attributeType["FILTERING"], payLoad: true });
    const response = await attributeService.filterAttributes(payLoad);
    dispatch({ type: attributeType["FILTERING"], payLoad: false });
    dispatch({ type: attributeType["FILTER_ATTRIBUTE"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: attributeType["FILTERING"], payLoad: false });
  }
};
