// Application types

const NOTIFIER = {
  GET_PRODUCT_CATEGORIES: "GET_PRODUCT_CATEGORIES",
  STORE_A_NOTIFIER: "STORE_A_NOTIFIER",
  SEARCH_NOTIFIER: "SEARCH_NOTIFIER",
  DELETING: "DELETING",
  DELETE_A_NOTIFIER: "DELETE_A_NOTIFIER",
  EDIT_A_PRODUCT_CATEGORY: "EDIT_A_PRODUCT_CATEGORY",
  SHOW_A_NOTIFIER: "SHOW_A_NOTIFIER",
  TEST_PRODUCT_CATEGORY_MODEL: "TEST_PRODUCT_CATEGORY_MODEL",
  LOADING_NOTIFIER: "LOADING_NOTIFIER",
  RELOAD_PRODUCT_CATEGORY: "RELOAD_PRODUCT_CATEGORY",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
  FILTER_NOTIFIER: "FILTER_NOTIFIER",
};

export default NOTIFIER;
