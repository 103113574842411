import React from "react";
import { Transition } from "@headlessui/react";

export function FormWizardSection({
  position,
  currentStep,
  children,
  transitionType = "instant",
}) {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    if (position === currentStep) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [position, currentStep]);

  return (
    <React.Fragment>
      {transitionType === "instant" ? (
        <Transition
          as={React.Fragment}
          enter="transition-opacity duration-1000"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-0"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          appear={true}
          show={show}
        >
          <span>{children ?? null}</span>
        </Transition>
      ) : null}

      {transitionType === "slide" ? (
        <Transition
          as={React.Fragment}
          enter="transition ease-in-out duration-1000 transform"
          enterFrom="-translate-x-full"
          enterTo="translate-x-0"
          leave="transition ease-in-out duration-100 transform"
          leaveFrom="translate-x-0"
          leaveTo="-translate-x-full"
          appear={true}
          show={show}
        >
          <span>{children ?? null}</span>
        </Transition>
      ) : null}

      {transitionType === "fade" ? (
        <Transition
          as={React.Fragment}
          enter="transition-opacity duration-75"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition-opacity duration-150"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          appear={true}
          show={show}
        >
          <span>{children ?? null}</span>
        </Transition>
      ) : null}
    </React.Fragment>
  );
}

export const FormWizard = React.forwardRef(({ children }, ref) => {
  const [step, setStep] = React.useState(0);
  const numberOfSections = React.Children.count(children);

  React.useImperativeHandle(ref, () => ({
    prev: () => {
      step - 1 <= 0 ? setStep(0) : setStep(step - 1);
    },
    next: () => {
      step + 1 >= numberOfSections - 1
        ? setStep(numberOfSections - 1)
        : setStep(step + 1);
    },
    goTo: (step) => {
      step <= numberOfSections - 1 ? setStep(step) : setStep(0);
    },
    getCurrentStep: () => {
      return step;
    },
  }));

  return React.Children.toArray(children).map((child) =>
    React.cloneElement(child, { currentStep: step }, child.props.children)
  );
});
