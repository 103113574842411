// Application types

const PROFILE = {
  APPROVING: "APPROVING",
  UPDATING: "UPDATING",
  DOWNLOADING: "DOWNLOADING",
  LOADING_AUTH: "LOADING_AUTH",
  ALL_PACKAGES: "ALL_PACKAGES",
  SINGLE_PACKAGE: "SINGLE_PACKAGE",
  STORE_PACKAGE: "STORE_PACKAGE",
  SEARCHING: "SEARCHING",
  SEARCH_PACKAGE: "SEARCH_PACKAGE",
  FILTERING: "FILTERING",
  FILTER_PACKAGE: "FILTER_PACKAGE",
  UPDATE_PACKAGE: "UPDATE_PACKAGE",
};

export default PROFILE;
