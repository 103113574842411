import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import Button from "../../buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import {
  activateFeaturedProducts,
  deactivateFeaturedProducts,
} from "../../../redux/feature/featureActions";

export default function FeatureApproval({ outlet_name }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const { activating, singleFeatured } = useSelector((state) => state.feature);
  const featured_id = singleFeatured.id;

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  const handleApprove = async () => {
    try {
      const response = await dispatch(
        activateFeaturedProducts({ id: featured_id })
      );
      if (response) {
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleUnApprove = async () => {
    try {
      const response = await dispatch(
        deactivateFeaturedProducts({ id: featured_id })
      );
      if (response) {
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Button.Primary
        type={"button"}
        title={
          singleFeatured?.status === "active"
            ? "Deactivate Feature"
            : "Activate Feature"
        }
        onClick={openModal}
      />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[374px] sm:w-[394px] bg-white rounded-3xl py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div className="pb-5 pt-1 px-6">
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="sm:flex justify-center">
                      <div className="text-center sm:text-center mt-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-bold text-black pb-2 border-b mb-8"
                        >
                          {singleFeatured?.status === "active"
                            ? "Deactivate"
                            : "Activate"}{" "}
                          this Feature?
                        </Dialog.Title>
                        <Dialog.Description as="p" className="text-md mb-10">
                          Are you sure you{" "}
                          {singleFeatured?.status === "active"
                            ? "deactivate"
                            : "activate"}{" "}
                          this food feature (
                          {singleFeatured?.product?.food_name ??
                            singleFeatured?.product?.merchandise_name}
                          ) from {outlet_name}?
                        </Dialog.Description>
                      </div>
                    </div>
                    <div className="flex justify-between gap-4">
                      <Button.Secondary
                        title={"Back"}
                        className="w-full"
                        onClick={closeModal}
                      />
                      <Button.Primary
                        type={"button"}
                        className="w-full"
                        title={
                          activating ? (
                            <Beat color={"#ffffff"} />
                          ) : singleFeatured?.status === "active" ? (
                            "Deactivate Feature"
                          ) : (
                            "Activate Feature"
                          )
                        }
                        disabled={activating}
                        onClick={
                          singleFeatured?.status === "active"
                            ? handleUnApprove
                            : handleApprove
                        }
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
