import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import { Pagination } from "../../../../components/pagination/pagination";

// sample data array looks like this
const data = [
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
  {
    show: {
      subscriber: "Raphael Obinna",
      phone: "090989796949",
      email: "subscriber@gmail.com",
      package: "Breakfast Extra",
      status: "Pending",
      days: "5",
      created: "Mar 8, 2020",
      expires: "Mar 15, 2020",
    },
  },
];

export default function Users() {
  const navigate = useNavigate();
  function goToSinglePage(params) {
    navigate(`/admin/food-packages/subscribers/hjhjf`);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Subscriber Name",
            accessor: "show.subscriber",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
          {
            Header: "Subscriber Email",
            accessor: "show.email",
          },
          {
            Header: "Package Name",
            accessor: "show.package",
          },
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Days",
            accessor: "show.days",
          },
          {
            Header: "Created On",
            accessor: "show.created",
          },
          {
            Header: "Expires",
            accessor: "show.expires",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="users-page">
      <Card.TableFilter results={80} />

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-8">
        <Table
          columns={columns}
          data={data}
          onClick={goToSinglePage}
          className="w-full"
          rowClass="hover:shadow-md cursor-pointer"
        />
      </div>

      <Pagination />
    </div>
  );
}
