import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import { Beat } from "../../../../../plugins/spinners.plugin";
import CLOSE from "../../../../../assets/images/icons/close.svg";
import Input from "../../../../../components/inputs/inputs";
import Button from "../../../../../components/buttons/buttons";
import AddPackageSkeleton from "../../../../../components/skeleton/addPackage";
import { searchUsersAction } from "../../../../../redux/users/userActions";
import { editANotifierGroup,showANotifierGroup } from "../../../../../redux/notifierGroup/notifierGroupAction";

export function AttachUsers(props) {
  const {
    isOpen,
    setIsOpen,
    selected,
    setSelected,
    selectedArr,
    setSelectedArr,
    showSelected,
    setShowSelected,
  } = props;
  const dispatch = useDispatch();

  const [show_search, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { searchUser, searching } = useSelector((state) => state.user);
  const { singleNotifierGroup, loading } = useSelector(
    (state) => state.notifierGroup
  );

  function closeModal() {
    setIsOpen(false);
  }
  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    e.preventDefault();
    if (value.length > 2) {
      dispatch(searchUsersAction({ search: value }));
      setShowSearch(true);
    }
  };

  function handleSelected(item) {
    setShowSearch(false);
    setSelected([...selected, item]);
    setSelectedArr([...selectedArr, item.id]);
  }
  const removeItem = (i) => {
    const filtered = selected.filter((item, index) => index !== i);
    setSelected(filtered);
  };

  async function handleContinue() {
    setShowSelected([...showSelected, selected]);
    let old_recipients = singleNotifierGroup?.recipients ?? [];
    const data = {
      id: singleNotifierGroup?.id,
      title: singleNotifierGroup?.title,
      recipients: [...old_recipients, ...selectedArr],
    };
    const response = await dispatch(editANotifierGroup(data));
    if (response?.status === "success") {
      closeModal();
      setSelected([]);
      setSelectedArr([]);
      dispatch(showANotifierGroup(singleNotifierGroup?.id));
    }
  }

  return (
    <>
      {/* <Button.Secondary
        title={"+ Add User"}
        type="button"
        className="text-center p-3 w-full"
        onClick={() => setIsOpen(true)}
      /> */}

      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full min-h-[502px] sm:w-[650px] sm:max-w-2xl bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-8">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-sm p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-3 text-center sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black "
                      >
                        Search and select users
                      </Dialog.Title>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="relative">
                      <Input.Label
                        title={"Specific Recipient"}
                        type="search"
                        id="search"
                        value={searchValue}
                        className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Search users."
                        onChange={handleSearch}
                      />
                      {searching ? (
                        <div className="absolute w-full bg-white shadow top-[68px]">
                          <div className="p-3">
                            <AddPackageSkeleton />
                          </div>
                        </div>
                      ) : (
                        show_search &&
                        searchUser?.data?.length > 0 && (
                          <div className="absolute w-full bg-white shadow top-[68px]">
                            <div className="p-3">
                              {searchUser?.data?.length > 0 &&
                                searchUser?.data?.map((item, i) => (
                                  <div
                                    key={i}
                                    className="flex justify-between cursor-pointer mb-2 hover:border-b"
                                    onClick={() => handleSelected(item)}
                                  >
                                    <div>
                                      {" "}
                                      <p className="text-sm">
                                        {item?.name}
                                      </p>{" "}
                                    </div>
                                    <p className="text-primary text-sm">
                                      + Add
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    {selected?.length > 0 && (
                      <div>
                        {selected?.map((restaurant, i) => (
                          <div
                            key={i}
                            className="flex items-center gap-6  mb-4"
                          >
                            <div className="w-full">
                              <h4 className="text-md mb-1">
                                {restaurant?.name}
                              </h4>
                              <p className="text-xs">{restaurant?.id}</p>
                            </div>
                            <div className="w-full text-right">
                              <button
                                className="text-primary text-md"
                                onClick={() => removeItem(i)}
                              >
                                Remove
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                    <div>
                      <Button.Primary
                        className="rounded-sm flex justify-center mx-auto mt-5"
                        title={loading ? <Beat /> : "Continue"}
                        onClick={handleContinue}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
