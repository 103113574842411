import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * get an array of dates
 * @param {date} startDate 16/09/2022
 * @param {date} endDate 20/09/2022
 * @returns {Array} [16/09/2022, 17/09/2022, 18/09/2022, 19/09/2022, 20/09/2022]
 *
 */
export const getDaysArray = (s, e) => {
  for (
    var a = [], d = new Date(s);
    d <= new Date(e);
    d.setDate(d.getDate() + 1)
  ) {
    a.push(new Date(d));
  }
  return a;
};

export const copyTextToClipboard = async (text) => {
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand("copy", true, text);
  }
  toast.success("Copied", {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export const copyTextFromTable = async (e, text) => {
  e.stopPropagation();
  if ("clipboard" in navigator) {
    await navigator.clipboard.writeText(text);
  } else {
    document.execCommand("copy", true, text);
  }
  toast.success("Copied", {
    position: toast.POSITION.TOP_RIGHT,
  });
};

export function concatId(id) {
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);
  let lengthToShow = 0;
  if (isMobile()) {
    lengthToShow = 5;
  } else {
    lengthToShow = 8;
  }
  if (typeof id === "string") {
    return (
      id?.slice(0, lengthToShow) + "...." + id?.slice(id?.length - lengthToShow)
    );
  }
}

export function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
