import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "../../../../components/container/container";
import Card from "../../../../components/cards/cards";
import Button from "../../../../components/buttons/buttons";
import { FilterStoreModal } from "../../../../components/modals/filterStoreModal";
import { filterPublicStore } from "../../../../redux/publicStore/publicStoreAction";
import Empty from "../../../../assets/images/pictures/listingPlaceHolder2.png";

export default function StoreCategoryList() {
  const dispatch = useDispatch();
  const { name } = useParams();

  const { settings } = useSelector((state) => state.application);
  const { filter } = useSelector((state) => state.publicStore);
  const stores = filter?.data;

  const [data, setData] = useState({
    store_categories: [],
  });

  useEffect(() => {
    setData({ ...data, store_categories: [name] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (data.store_categories.length > 0) {
      dispatch(filterPublicStore(data));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const addImageFallback = (event) => {
    event.currentTarget.src = Empty;
  };
  return (
    <div className="category" data-test="categoryList">
      <Container>
        <div className="py-5 mt-12">
          <div className="flex justify-between gap-4 items-center mb-2">
            <h3 className="text-3xl font-bold">Category: {name}</h3>
            <div className="flex items-center gap-4">
              <span className="hidden sm:block">
                Sort by: <span className="text-primary">All</span>
              </span>
              <div className="w-[48px] h-[48px] bg-[#FFEAE5] rounded items-center justify-center flex">
                <FilterStoreModal />
              </div>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="py-5">
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
            {stores?.length > 0 ? (
              stores?.map((store, i) => (
                <Card.Store
                  key={i}
                  id={store?.id}
                  image={store?.store_pictures}
                  title={store?.store_name}
                  tags={store?.store_tags}
                  imageFallBack={addImageFallback}
                  categories={store?.store_categories}
                  operating_settings={settings?.store_operating_status}
                  operating_status={store?.store_operating_status}
                  operating_hours={store?.store_operating_time}
                />
              ))
            ) : (
              <p className="mb-2 text-xl font-bold tracking-tight text-black">
                No Store Available
              </p>
            )}
          </div>
          {filter?.data?.length < filter?.total && (
            <Button.Secondary
              title={"Load More"}
              // disabled={loadingMore}
              className="px-16"
              // onClick={() => loadMore()}
            />
          )}
        </div>
      </Container>
    </div>
  );
}
