import axios from "../plugins/axios.plugin";

class ContactService {
  baseServicePath = "/api/contact";

  getAllContacts(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAllContactsNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getAContact(id) {
    return axios.get(`${this.baseServicePath}/show?user_id=${id}&properties=1`);
  }

  deleteAContact(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  searchContacts(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  filterContacts(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}&properties=1`,
      payload
    );
  }

  getContactWithRelation(payload, page) {
    return axios.post(
      `${this.baseServicePath}/relation?properties=1&page=${page}`,
      payload
    );
  }

  editAContact(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  showContactRoles(user_id) {
    return axios.get(`${this.baseServicePath}/roles?user_id=${user_id}`);
  }
}

export const contactService = new ContactService();
