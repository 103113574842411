import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Switch } from "@headlessui/react";
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import ADVERTIMG from "../../../../assets/images/pictures/advertImage.jpg";
import DotMenu from "../../../../assets/images/icons/dot-menu.svg";

import { Beat } from "../../../../plugins/spinners.plugin";
import FeatureMerchItemModal from "../../../../components/modals/feature/featureMerch";
import { FeaturePaymentModal } from "../../../../components/modals/feature/featurePayment";
import EditMerchandisePriceModal from "../../../../components/modals/admin/editMerchPrice";
import { getOutletFromCookies } from "../../../../utils/Auth";
import {
  approveMerchandise,
  deleteMerchandise,
  getSingleMerchandise,
  unApproveMerchandise,
} from "../../../../redux/merchandise/merchandiseActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function SingleStoreMerchandise() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const featureMerchRef = useRef(null);
  const merchandise = location.state.merchandise;
  const outlet = getOutletFromCookies();

  const [openPayment, setOpenPayment] = useState(false);
  const [enabled, setEnabled] = useState();
  const [openUpdate, setOpenUpdate] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [price, setPrice] = useState({
    id: merchandise?.id,
    merchandise_amount: merchandise?.merchandise_amount,
    merchandise_discount: merchandise?.merchandise_discount,
  });

  const { singleMerchandise, deleting } = useSelector(
    (state) => state.merchandise
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (merchandise?.id) {
      dispatch(getSingleMerchandise(merchandise?.id));
    }
    // eslint-disable-next-line
  }, [merchandise]);

  useEffect(() => {
    setEnabled(singleMerchandise?.business_approved);
  }, [singleMerchandise?.business_approved]);

  function closeUpdateModal() {
    setOpenUpdate(false);
  }
  function openUpdateModal() {
    setOpenUpdate(true);
  }

  const toggleBtn = () => {
    setOpenDropdown(!openDropdown);
  };

  const publish = () => {
    dispatch(approveMerchandise(merchandise?.id));
  };
  const unPublish = () => {
    dispatch(unApproveMerchandise(merchandise?.id));
  };

  function goToSinglePage() {
    navigate(
      `/admin/${outlet.store_name}/merchandise/${merchandise?.id}/edit`,
      { state: { merchandise: merchandise } },
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    );
  }
  const addImageFallback = (event) => {
    event.currentTarget.src = ADVERTIMG;
  };

  const deleteAMerchandise = async () => {
    try {
      const response = await dispatch(
        deleteMerchandise({ id: singleMerchandise?.id })
      );
      if (response.status === "success") {
        navigate(`/admin/${outlet.store_name}/merchandise/all/1`);
      }
    } catch (error) {}
  };

  return (
    <>
      <EditMerchandisePriceModal
        show={openUpdate}
        onClose={closeUpdateModal}
        price={price}
        setPrice={setPrice}
        id={merchandise?.id}
      />
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">
            {singleMerchandise?.merchandise_name}
          </h3>
          <div className="relative block sm:hidden">
            <button
              className="flex text-[#717485] gap-1 items-center"
              onClick={toggleBtn}
              type="button"
            >
              <img src={DotMenu} alt="menu" />
            </button>
            {openDropdown && (
              <div className="absolute right-0 z-10 w-[280px] border-[#E4E4F3] bg-white px-0 overflow-y-auto shadow-xl transform transition-all">
                <div className="grid grid-cols-1 gap-4 p-4">
                  <Button.Secondary
                    disabled={deleting}
                    title={
                      deleting ? (
                        <Beat color={"#ffffff"} />
                      ) : (
                        "Delete Merchandise"
                      )
                    }
                    className={"px-6"}
                    onClick={() => deleteAMerchandise()}
                  />

                  <Button.Primary
                    title={"Edit Merchandise"}
                    onClick={goToSinglePage}
                  />
                </div>
              </div>
            )}
          </div>
          <div className="relative sm:block hidden">
            <div className="flex gap-4 p-4">
              <Button.Secondary
                disabled={deleting}
                title={
                  deleting ? <Beat color={"#ffffff"} /> : "Delete Merchandise"
                }
                className={"px-6"}
                onClick={() => deleteAMerchandise()}
              />

              <Button.Primary
                title={"Edit Merchandise"}
                onClick={goToSinglePage}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Merchandise ID
            </label>
            <p className="text-[#151515] text-sm">{singleMerchandise?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Category</label>
            <p className="text-[#151515] text-sm">
              {singleMerchandise?.merchandise_categories}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Stock</label>
            <p className="text-[#151515] text-sm">{singleMerchandise?.stock}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] capitalize text-sm">
              {singleMerchandise?.merchandise_operating_status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "MMM, DD YYYY, HH:MMA"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Number Sold</label>
            <p className="text-[#151515] text-sm">N/A</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] text-sm">{singleMerchandise?.flag}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              {singleMerchandise?.business_approved
                ? "Unpublish Merchandise"
                : "Publish Merchandise"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabled}
                onChange={(e) => {
                  setEnabled(e);
                  singleMerchandise?.business_approved
                    ? unPublish()
                    : publish();
                }}
                className={classNames(
                  enabled ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          {/* Split payment details */}
          {/* <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Split Payment</label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Initial Payment
            </label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Payment Intervals
            </label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Subsequent Payment
            </label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Duration</label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div> */}
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Merchandise Price</h3>
          <Button.Primary title={"Edit Price"} onClick={openUpdateModal} />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-5 gap-4 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Price</label>
            <p className="text-[#151515] text-sm">
              {singleMerchandise?.merchandise_amount}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Discount</label>
            <p className="text-[#151515] text-sm">
              {" "}
              {singleMerchandise?.merchandise_discount}
            </p>
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <FeaturePaymentModal
          amount={singleMerchandise?.featured?.[0]?.featured_amount}
          reference_id={singleMerchandise?.featured?.[0]?.payment_id}
          isOpen={openPayment}
          setOpen={setOpenPayment}
        />

        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Feature Details</h3>

          {singleMerchandise?.featured?.length > 0 ? (
            singleMerchandise?.featured?.[0]?.featured_paid ? (
              <Button.Primary
                type={"button"}
                title={"Feature Merchandise"}
                disabled={singleMerchandise?.featured?.[0]?.featured_paid}
                onClick={() => {
                  featureMerchRef.current.open();
                  featureMerchRef.current.getAmount(
                    singleMerchandise?.merchandise_amount
                  );
                }}
              />
            ) : (
              <Button.Primary
                type={"button"}
                title={"Pay For Feature"}
                disabled={singleMerchandise?.featured?.[0]?.featured_paid}
                onClick={() => setOpenPayment(true)}
              />
            )
          ) : (
            <Button.Primary
              type={"button"}
              title={"Feature Merchandise"}
              disabled={singleMerchandise?.featured?.[0]?.featured_paid}
              onClick={() => {
                featureMerchRef.current.open();
                featureMerchRef.current.getAmount(
                  singleMerchandise?.merchandise_amount
                );
              }}
            />
          )}
        </div>
        {singleMerchandise?.featured === null ? (
          <p className="text-[#151515] text-base py-2 mt-3">
            Merchandise has not been featured
          </p>
        ) : (
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Cost</label>
              <p className="text-[#151515] text-sm">
                {singleMerchandise?.featured?.[0]?.featured_amount
                  ? "NGN " +
                    numberWithCommas(
                      singleMerchandise?.featured?.[0]?.featured_amount
                    )
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              <p className="text-[#151515] text-sm">
                {singleMerchandise?.featured?.[0]?.status ?? "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Duration</label>
              <p className="text-[#151515] text-sm">
                {singleMerchandise?.featured?.[0]?.featured_duration_in_hours
                  ? singleMerchandise?.featured?.[0]
                      ?.featured_duration_in_hours + "hours"
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Time Left</label>
              <p className="text-[#151515] text-sm">
                {singleMerchandise?.featured?.[0]?.featured_duration_in_hours
                  ? singleMerchandise?.featured?.[0]
                      ?.featured_duration_in_hours + "hours"
                  : "Not available"}
              </p>
            </div>
          </div>
        )}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8">
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Merchandise Category</h3>
          </div>
          <div className="mt-6">
            <label className="text-[#9CA3AF] text-sm mb-2">Category</label>
            <p className="text-[#151515] text-sm mb-2">
              {singleMerchandise?.merchandise_categories?.length > 0 &&
                singleMerchandise?.merchandise_categories?.map(
                  (item, index) => <span key={index}>{item}, </span>
                )}
            </p>
          </div>
        </div>
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Merchandise Tag</h3>
          </div>
          <div className="mt-6">
            <label className="text-[#9CA3AF] text-sm mb-2">Tags</label>
            <p className="text-[#151515] text-sm mb-2">
              {singleMerchandise?.merchandise_tags?.length > 0 &&
                singleMerchandise?.merchandise_tags?.map((item, index) => (
                  <span key={index}>{item}, </span>
                ))}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">Merchandise Image</h3>
            <p className="text-sm">
              Accepts .jpg, .png and .jpeg image formats
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
          {singleMerchandise?.merchandise_pictures?.map((image, index) => (
            <figure className="w-full h-[160px] relative" key={index}>
              <img
                src={image}
                onError={addImageFallback}
                className="w-[160px] h-[160px] object-cover"
                alt="merchandise"
              />
            </figure>
          ))}
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Merchandise Attributes</h3>
        </div>
        {singleMerchandise?.attributes?.length > 0 ? (
          <Card.AttributeItem attributes={singleMerchandise?.attributes} />
        ) : (
          <div className="text-center mx-auto max-w-2xl py-8">
            <h4 className="text-xl font-medium mb-1">
              You have no product Attributes yet
            </h4>
            <p>
              When you create new Product Attributes, it will be displayed here
            </p>
          </div>
        )}
      </div>
      <FeatureMerchItemModal ref={featureMerchRef} />
    </>
  );
}
