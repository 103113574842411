import merchandiseType from "./merchandiseTypes";

const initialState = {
  isLoading: false,
  approving: false,
  searching: false,
  filtering: false,
  updating: false,
  deleting: false,
  allMerchandises: {},
  searchMerch: [],
  singleMerchandise: {},
  approveMerchandiseBusiness: {},
  unApproveMerchandiseBusiness: {},
  approveMerchandiseManagement: {},
  unApproveMerchandiseManagement: {},
  filterNoPage: {},
  employeeLocation: {},
  myMerchandises: {},
  reloadMerch: {},
  createMerchandise: {},
  testModel: {},
};

export default function merchandiseReducer(state = initialState, action) {
  switch (action.type) {
    case merchandiseType.LOADING_MERCHANDISE:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case merchandiseType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case merchandiseType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };
    case merchandiseType.APPROVING_MERCHANDISE:
      return {
        ...state,
        approving: action.payLoad,
      };
    case merchandiseType.GET_ALL_MERCHANDISE:
      let newData = {};
      if (action.payLoad?.data === undefined) {
        newData = {};
      } else {
        newData = action.payLoad?.data;
      }
      return {
        ...state,
        allMerchandises: newData,
      };

    case merchandiseType.RELOADING_MERCHANDISE:
      return {
        ...state,
        reloadMerch: !state.reloadMerch,
      };
    case merchandiseType.FILTER_MERCHANDISE:
      return {
        ...state,
        allMerchandises: action.payLoad?.data,
      };
    case merchandiseType.FILTER_MERCHANDISE_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payLoad?.data,
      };
    case merchandiseType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case merchandiseType.GET_MY_MERCHANDISE:
      let payLoadData = {};
      if (action.payLoad?.data === undefined) {
        payLoadData = {};
      } else {
        payLoadData = action.payLoad?.data;
      }
      return {
        ...state,
        myMerchandises: payLoadData,
      };

    case merchandiseType.DELETING_MERCHANDISE:
      return {
        ...state,
        deleting: action.payLoad,
      };

    // case merchandiseType.DELETE_MERCHANDISE:
    //   const deleteMerchandise = state.allMerchandises.filter(
    //     (item) => item.id !== action.payLoad.id
    //   );

    //   return {
    //     ...state,
    //     allMerchandises: deleteMerchandise,
    //   };

    case merchandiseType.CREATE_MERCHANDISE:
      return {
        ...state,
        store: action.payLoad.data.data,
      };
    case merchandiseType.SHOW_A_MERCHANDISE:
      return {
        ...state,
        singleMerchandise: action.payLoad?.data,
      };
    case merchandiseType.SEARCH_MERCHANDISE:
      return {
        ...state,
        searchMerch: action.payLoad?.data,
      };
    case merchandiseType.APPROVE_MERCHANDISE_BUSINESS:
      let approvedBusiness = {
        ...state.singleMerchandise,
        business_approved: true,
      };
      return {
        ...state,
        singleMerchandise: approvedBusiness,
      };
    case merchandiseType.UNAPPROVE_MERCHANDISE_BUSINESS:
      let unApprovedBusiness = {
        ...state.singleMerchandise,
        business_approved: false,
      };
      return {
        ...state,
        singleMerchandise: unApprovedBusiness,
      };
    case merchandiseType.APPROVE_MERCHANDISE_MANAGEMENT:
      let approved = {
        ...state.singleMerchandise,
        management_approved: true,
      };
      return {
        ...state,
        singleMerchandise: approved,
      };
    case merchandiseType.UNAPPROVE_MERCHANDISE_MANAGEMENT:
      let unApproved = {
        ...state.singleMerchandise,
        management_approved: false,
      };
      return {
        ...state,
        singleMerchandise: unApproved,
      };
    default:
      return state;
  }
}
