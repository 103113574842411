import React, { useState, useEffect } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../../buttons/buttons";
import { RiCloseLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import { editAttributeAction } from "../../../../redux/attribute/attributeActions";
import { getUserFromCookies } from "../../../../utils/Auth";
import { cleanInput } from "../../../../helpers/cleanInput";
import { getAllAttributesAction } from "../../../../redux/attribute/attributeActions";
import { getAFoodAction } from "../../../../redux/food/foodActions";

export default function EditFoodAttribute({ id, myAttributes }) {
  const attribute_id = myAttributes?.id;
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  let business_id = Object.keys(user.groups)?.[0];

  const [isOpen, setIsOpen] = useState(false);
  const { isLoading } = useSelector((state) => state.attribute);

  function handleClose() {
    setIsOpen(false);
  }
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    // Update the document title using the browser API
    let dataObject = {
      name: myAttributes.name,
      type: myAttributes.type,
      title: myAttributes.title,
      amount: myAttributes.amount,
    };
    setData({ ...dataObject });
  }, [myAttributes]);

  const [data, setData] = useState([
    { name: "", type: "", amount: "", title: "" },
  ]);
  const { name, type, amount, title } = data;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    cleanInput(data);
    setSubmitted(true);
    // dispatch action to create data
    const response = await dispatch(
      editAttributeAction({
        ...data,
        id: attribute_id,
        business_id: business_id,
      })
    );
    if (response) {
      dispatch(getAllAttributesAction());
      dispatch(getAFoodAction(id));
    }

    handleClose();
  };
  return (
    <>
      <button
        type="button"
        className="text-primary text-sm mt-1"
        onClick={() => setIsOpen(true)}
      >
        Edit
      </button>

      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[358px] sm:rounded-md rounded-sm sm:w-[450px] p-4 sm:p-6 ">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                        <button type="button" onClick={handleClose}>
                          <span className="sr-only">Close</span>
                          <RiCloseLine />
                        </button>
                      </div>
                      <div className="">
                        <div className=" text-center sm:text-left ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black "
                          >
                            Edit Attributes
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className=" border-b-2 mt-4"></div>
                      <div className="mt-5 sm:mt-7 text-left">
                        <div
                          className={
                            submitted && title ? "  border-red-500" : ""
                          }
                        >
                          <label
                            htmlFor="title"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Title
                          </label>
                          <div className="mt-2 ">
                            <input
                              type="text"
                              name="title"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                              defaultValue={data.title}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            submitted && name ? "  border-red-500" : ""
                          }
                        >
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name*
                          </label>
                          <div className="mt-2 ">
                            <input
                              type="text"
                              name="name"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                              defaultValue={data.name}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div
                          className={
                            submitted && type ? "  border-red-500" : ""
                          }
                        >
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Type*
                          </label>
                          <div className="mt-2 ">
                            <select
                              name="type"
                              defaultValue={data.type}
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                              onChange={handleChange}
                            >
                              <option>Select a type</option>
                              <option value="store">Store</option>
                              <option value="restaurant">Restaurant</option>
                            </select>
                          </div>
                        </div>
                        <div
                          className={
                            submitted && amount ? "  border-red-500" : ""
                          }
                        >
                          <label
                            htmlFor="amount"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Value
                          </label>
                          <div className="mt-2 ">
                            <input
                              type="text"
                              name="amount"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                              defaultValue={data.amount}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="flex justify-between mt-10 sm:mt-20 mb-6">
                        <Button.Secondary
                          title={"Back"}
                          className={"px-4 h-10 w-20"}
                          onClick={handleClose}
                        />
                        <Button.Primary
                          title={isLoading ? <Beat /> : "Update Attribute"}
                          disabled={isLoading}
                          type="submit"
                          className="h-10 text-center px-4"
                        />
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
