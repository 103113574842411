import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
import Warning from "../../../assets/images/icons/warning.svg";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { filterAdvertisments } from "../../../redux/advertisment/advertismentAction";
import { Beat } from "../../../plugins/spinners.plugin";

export default function FilterSliderDropDown({
  payLoad,
  page,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.advertisment);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    title: "",
    management_approved: "",
    start_date: "",
    end_date: "",
  });
  const { title, management_approved, end_date, start_date } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      title: "",
      status: "",
      start_date: "",
      end_date: "",
    });
    await dispatch(filterAdvertisments(payLoad, page));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (title || management_approved || start_date || end_date) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterAdvertisments({ ...data }));
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Title"}
              htmlFor={"title"}
              name={"title"}
              onChange={handleChange}
              value={title}
              placeholder={"Enter Title"}
            />

            <Input.Select
              title={"Status"}
              name="management_approved"
              htmlFor={"management_approved"}
              value={management_approved}
            >
              <option className="text-primary">Select a status</option>
              <option value={1}>Active</option>
              <option value={0}>Inactive</option>
            </Input.Select>

            <Input.DatePicker
              title={"Start Date"}
              htmlFor={"start_date"}
              name={"start_date"}
              value={start_date}
              onChange={handleChange}
            />
            <Input.DatePicker
              title={"End Date"}
              htmlFor={"end_date"}
              name={"end_date"}
              value={end_date}
              onChange={handleChange}
            />

            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
