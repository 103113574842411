import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog } from "@headlessui/react";
import Button from "../../../buttons/buttons";
import Input from "../../../inputs/inputs";
import { getUserFromCookies } from "../../../../utils/Auth";
import { Beat } from "../../../../plugins/spinners.plugin";
import Location from "../../../includes/location";
import CLOSE from "../../../../assets/images/icons/arrow-left.svg";
import { createAStore } from "../../../../redux/stores/storeActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";

export default function FormSection6({ formWizardRef, props }) {
  const user = getUserFromCookies();
  const dispatch = useDispatch();
  let business_id = Object.keys(user.groups)?.[0];

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading } = useSelector((state) => state.store);

  useEffect(() => {
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });

  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([
    {
      store_email: "",
      store_name: "",
      store_address: "",
      store_city: "",
      store_state: "",
      store_country: "",
      store_description: "",
      store_categories: [],
    },
  ]);
  const {
    store_name,
    store_email,
    store_address,
    store_city,
    store_state,
    store_country,
    store_description,
    store_categories,
  } = data;

  let [store_phone, setStorePhone] = useState("");
  store_phone = store_phone?.slice(1);
  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  const handleChange = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    if (name === "store_categories") {
      setData({ ...data, store_categories: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };
  function categories() {
    let dataToMap = storeCategories;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((category, i) => {
        let singleRowArray = [
          <option key={i} value={category.name}>
            {category.name}
          </option>,
        ];
        outputArray.push(singleRowArray);

        return true;
      });

      return outputArray;
    }
  }
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      store_name &&
      store_phone &&
      store_address &&
      store_categories &&
      store_city &&
      store_state &&
      store_country
    ) {
      // dispatch action to create data
      const response = await dispatch(
        createAStore({ ...data, store_phone, business_id: business_id })
      );
      if (response.status === "success") {
        formWizardRef.current.goTo(4);
      }
    }
  };

  return (
    <Dialog.Panel className="relative sm:w-[708px] h-[877px] bg-white rounded-3xl px-4 pt-5 pb-4 text-left overflow-y-scroll shadow-xl transform transition-all sm:my-8  w-full sm:p-8">
      <div className=" sm:block absolute top-2 left-8 pt-4 pr-4">
        <button
          type="button"
          className="bg-[#ffeae5] rounded-md p-2 sm:p-3 "
          onClick={() => formWizardRef.current.goTo(4)}
        >
          <span className="sr-only">Close</span>
          <img src={CLOSE} alt="close" className=" w-3 h-3 sm:w-4 sm:h-4" />
        </button>
      </div>

      <div className="sm:mt-10 text-left">
        <form
          name="form"
          className="border"
          id="store_form"
          onSubmit={handleSubmit}
        >
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 flex justify-center ">
              Store Details
            </p>
          </div>

          <div className=" sm:px-10">
            <div
              className={
                "mt-4" + (submitted && store_name ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Store Name "}
                htmlFor={"store_name"}
                type={"text"}
                name={"store_name"}
                onChange={handleChange}
                placeholder={"Enter store name"}
              />
              {submitted && !store_name && (
                <div className="text-xs text-red-500">
                  Store name is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" + (submitted && store_email ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Store Email *"}
                htmlFor={"store_email"}
                type={"email"}
                name={"store_email"}
                onChange={handleChange}
                placeholder={"Enter store email address"}
              />
            </div>
            <div
              className={
                "mt-4" + (submitted && store_phone ? " border-red-500" : "")
              }
            >
              <Input.Phone
                defaultCountry="NG"
                title={"Store Phone Number"}
                htmlFor={"store_phone"}
                value={store_phone}
                onChange={setStorePhone}
              />
              {submitted && !store_phone && (
                <div className="text-xs text-red-500">
                  Store phone is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" + (submitted && store_address ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Store Address *"}
                htmlFor={"store_address"}
                type={"text"}
                name={"store_address"}
                onChange={handleChange}
                placeholder={"Enter store Address"}
              />
            </div>
            {submitted && !store_address && (
              <div className="text-xs text-red-500">
                Store address is required
              </div>
            )}
            <div
              className={
                "mt-4" +
                (submitted && store_description ? " border-red-500" : "")
              }
            >
              <Input.TextArea
                title={"Store Description "}
                htmlFor={"store_description"}
                type={"text"}
                name={"store_description"}
                onChange={handleChange}
                placeholder={"Describe your store to us"}
              />
            </div>
            <div
              className={
                submitted && !store_categories ? "  border-red-500" : ""
              }
            >
              <label
                htmlFor="store_categories"
                className="block text-sm font-medium text-gray-700"
              >
                Store Category*
              </label>
              <div className="mt-2 ">
                <select
                  name="store_categories"
                  className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  onChange={handleChange}
                >
                  <option>Select a store category</option>
                  {categories()}
                </select>
                {submitted && !store_categories && (
                  <div className="text-primary text-xs ">
                    Store category is required
                  </div>
                )}
              </div>
            </div>
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              loadingLocation={(value) => {
                setLocationLoader(value);
              }}
              countryList={(value) => {
                setLocationList({ ...locationList, countryList: value });
              }}
              stateList={(value) => {
                setLocationList({ ...locationList, stateList: value });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div
              className={
                "mt-4" + (submitted && store_country ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="store_country"
                className="block text-sm font-medium text-gray-700"
              >
                Country*
              </label>
              <select
                name="store_country"
                className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select Country</option>
                {locationLoader ? (
                  <option>Loading...</option>
                ) : (
                  locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })
                )}
              </select>

              {submitted && !store_country && (
                <div className="text-xs text-red-500">
                  Store country is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" + (submitted && store_state ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="store_state"
                className="block text-sm font-medium text-gray-700"
              >
                {" "}
                State{" "}
              </label>
              <select
                name="store_state"
                className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select state</option>

                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !store_state && (
                <div className="text-xs text-red-500">
                  Store state is required.
                </div>
              )}
            </div>
            <div
              className={
                "mt-4 mb-16" +
                (submitted && store_city ? " border-red-500" : "")
              }
            >
              <label
                htmlFor="store_city"
                className="block text-sm font-medium text-gray-700"
              >
                {" "}
                City*{" "}
              </label>
              <select
                name="store_city"
                className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFF] focus:outline-none  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select a city</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({ name: item.name, id: item.id })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !store_city && (
                <div className="text-xs text-red-500">
                  Store city is required
                </div>
              )}
            </div>
          </div>
        </form>
        <Button.Primary
          type={"submit"}
          form={"store_form"}
          title={isLoading ? <Beat color={"#ffffff"} /> : "Submit"}
          className={"w-[333px] sm:mt-10 mx-auto flex justify-center"}
        />
      </div>
    </Dialog.Panel>
  );
}
