import React, { useMemo, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import Orders from "../../../../assets/images/icons/ordersEmpty.svg";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
import TableSkeleton from "../../../../components/skeleton/table";
import { getOutletFromCookies } from "../../../../utils/Auth";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { getStoreStatistics } from "../../../../redux/statistics/statisticsAction";
import {
  filterStoreOrders,
  searchAllStoreOrders,
  getAllStoreOrdersNoPage,
} from "../../../../redux/storeOrders/storeOrderAction";
import { getAUsersAction } from "./../../../../redux/users/userActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function OutletStoreReportDetail() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const { allStoreOrders, filtering, search, searching, allStoreOrdersNoPage } =
    useSelector((state) => state.storeOrder);
  const { user } = useSelector((state) => state.user);
  const { storeStats } = useSelector((state) => state.statistics);

  let lastYear = new Date();
  lastYear.setDate(lastYear.getDate() - 365);

  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [payLoad] = useState({
    store_id: outlet?.id,
    start_date: lastMonth,
    end_date: new Date(),
  });
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(getStoreStatistics(payLoad));
    dispatch(getAUsersAction(outlet?.user_id));
    // eslint-disable-next-line
  }, [outlet?.id]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    dispatch(filterStoreOrders({ ...payLoad, user_id: outlet?.user_id }, 1));
    dispatch(getAllStoreOrdersNoPage(payLoad));
  }, [dispatch, payLoad, outlet?.user_id]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          status: (
            <span>
              {order?.status === "delivered" ? (
                <span className="text-[#00D220] font-bold">
                  {order?.status}
                </span>
              ) : (
                <span className="text-primary font-bold">{order?.status}</span>
              )}
            </span>
          ),
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          payment_type: order?.order_payment_method ?? "Not available",
          id: (
            <span className="flex items-center">
              {order?.id}{" "}
              <button
                type="button"
                onClick={() => {
                  navigator.clipboard.writeText(order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          foodData: order,
        };
        outputArray.push({ show });
        return true;
      });

      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allStoreOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStoreOrders]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order, index) => {
        let show = {
          status: order?.status,
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          payment_type: order?.order_payment_method ?? "Not available",
          id: order?.id,
          amount: order?.order_amount,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  const downloadCSV = () => {
    if (allStoreOrdersNoPage) {
      const newData = createTableNoPage(allStoreOrdersNoPage);
      writeFileWithXLSX(newData, {
        filename: "shipped orders.xlsx",
        download: true,
      });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allStoreOrders);
      setData(dataSet);
    }
  };
  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchAllStoreOrders(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Status",
            accessor: "show.status",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Payment Type",
            accessor: "show.payment_type",
          },
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="singleStore-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">{outlet?.store_name}</h3>
          <Button.Primary
            title={"Check Stats"}
            onClick={() =>
              navigate(`/admin/${outlet?.restaurant_name}/report/statistics`)
            }
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Store ID</label>
            <p className="text-[#151515] text-sm">{outlet?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">{outlet?.store_email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">{outlet?.store_phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] text-sm">{outlet?.flag}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] text-sm">
              {outlet?.management_approved ? "Approved" : "Unapproved"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(outlet?.created_at).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">{outlet?.store_country}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm"> {outlet?.store_state}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm"> {outlet?.store_city}</p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Store Owner Details</h3>
        </div>

        <div className="grid sm:grid-cols-5 grid-col-2 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">{user?.name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">{user?.email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">
              {user.phone ? user?.phone : "Not Available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">
              {user?.address > 0 ? user?.address[0] : "Not Available"}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Orders History</h3>
        </div>

        <div className="grid sm:grid-cols-6 grid-cols-2 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Total Orders </label>
            <p className="text-[#151515] text-sm">
              {storeStats?.stats?.orders?.total_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Delivered Orders
            </label>
            <p className="text-[#151515] text-sm">
              {storeStats?.stats?.orders?.delivered_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Shipped Orders
            </label>
            <p className="text-[#151515] text-sm">
              {storeStats?.stats?.orders?.shipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Unshipped Orders
            </label>
            <p className="text-[#151515] text-sm">
              {storeStats?.stats?.orders?.unshipped_orders}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Standard Orders
            </label>
            <p className="text-[#151515] text-sm">
              {storeStats?.stats?.orders?.standard_orders}
            </p>
          </div>
        </div>
      </div>

      <div className="mt-6">
        <Card.TableFilter
          onChange={handleChange}
          searchPlaceholder={"Search Orders"}
        >
          <Button.Secondary
            title={"Download CSV"}
            onClick={downloadCSV}
            disabled={!data}
          />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3]"></div>

        <div className="bg-white rounded-md py-4 px-8">
          {data?.length > 0 ? (
            <>
              {filtering || searching ? (
                <TableSkeleton />
              ) : (
                <Table
                  columns={columns}
                  data={data?.length > 0 ? data : []}
                  className="w-full"
                  rowClass="hover:shadow-md cursor-pointer"
                  onClick={() => {}}
                />
              )}
            </>
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Orders} alt="orders" />
              </div>
              <p className="font-bold text-xl my-3">Store Orders</p>
              <p className="text-sm text-gray-500">
                You do not have any Store Orders yet
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
