import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import moment from "moment";
import TableSkeleton from "../../../../../components/skeleton/table";
import EmptyState from "../../../../../assets/images/emptyState/orders.svg";
import { copyTextToClipboard } from "../../../../../components/helpers";
import { ReactComponent as CopyIcon } from "../../../../../assets/images/icons/copy-outline.svg";
import { getSingleStoreOrder } from "../../../../../redux/storeOrders/storeOrderAction";
import { showAPayment } from "../../../../../redux/payment/paymentActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleStoreOrder() {
  const dispatch = useDispatch();
  const location = useLocation();
  const order = location.state.order;
  const [pfmLink, setPfmLink] = useState("");

  const { singlePayment } = useSelector((state) => state.payment);
  const { singleStoreOrder, isLoading } = useSelector(
    (state) => state.storeOrder
  );

  useEffect(() => {
    if (order?.id) {
      dispatch(getSingleStoreOrder(order?.id));
    }
    // eslint-disable-next-line
  }, [order]);

  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };

  useEffect(() => {
    if (singleStoreOrder?.payment_id) {
      dispatch(showAPayment(singleStoreOrder?.payment_id));
    }
  }, [dispatch, singleStoreOrder, singleStoreOrder?.payment_id]);

  useEffect(() => {
    if (singlePayment?.pfm) {
      let base_url = window.location.origin;
      let text = `${base_url}/pay-for-me/${singlePayment?.pfm}`;
      setPfmLink(text);
    }
  }, [singlePayment, singlePayment?.pfm]);

  return (
    <>
      <div className="grid grid-cols-1 gap-8 pt-6">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md px-4 sm:px-8 py-5">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
            </div>

            <div className="grid grid-cols-2 lg:grid-cols-4 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {moment(singleStoreOrder?.created_at).format("MMM DD, YYYY")}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN{" "}
                  {numberWithCommas(
                    Number(singleStoreOrder?.order_amount) -
                      Number(singleStoreOrder?.order_delivery_fee)
                  )}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm font-bold flex">
                  {truncateText(singleStoreOrder?.id)}

                  <CopyIcon
                    className="h-4 w-4 ml-1"
                    onClick={() => copyTextToClipboard(order.id)}
                  />
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {singleStoreOrder?.order_delivery_fee}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Insurance Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_insurance_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(singleStoreOrder?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN {numberWithCommas(Number(singleStoreOrder?.order_amount))}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Status
                </label>
                {singleStoreOrder?.status === "unshipped" ? (
                  <p className="text-primary text-sm font-bold">unshipped</p>
                ) : singleStoreOrder?.status === "shipped" ? (
                  <p className="text-[#046ECF] text-sm font-bold">Shipped</p>
                ) : singleStoreOrder?.status === "closed" ? (
                  <p className="text-primary text-sm font-bold">Closed</p>
                ) : singleStoreOrder?.status === "delivered" ? (
                  <p className="text-[#00D220] text-sm font-bold">Delivered</p>
                ) : (
                  ""
                )}
              </div>

              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Status
                </label>
                {singleStoreOrder?.order_paid ? (
                  <p className="text-[#00D220] text-sm font-bold">Paid</p>
                ) : (
                  <p className="text-primary text-sm font-bold">Unpaid</p>
                )}
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Recipient City
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleStoreOrder?.recipient_city}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Link
                </label>
                <div className="flex gap-1 max-w-[160px] w-full items-center justify-between">
                  <span className="text-sm font-bold text-[#1634A1] truncate">
                    {pfmLink}
                  </span>
                  <CopyIcon
                    className="h-4 w-4 cursor-pointer flex-none"
                    onClick={() => copyTextToClipboard(pfmLink)}
                  />
                </div>
              </div>
            </div>
          </div>

          {singleStoreOrder?.order_coupons?.length > 0 && (
            <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Coupon Details</h3>
              </div>

              {singleStoreOrder?.order_coupons?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
                    <p className="text-[#151515] text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}

          {singleStoreOrder?.order_promotion_codes?.length > 0 && (
            <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
              <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
                <h3 className="text-lg font-medium">Promo Code Details</h3>
              </div>

              {singleStoreOrder?.order_promotion_codes?.map((coupon, index) => (
                <div
                  key={index}
                  className="grid grid-cols-2 gap-4 sm:gap-8 mt-6"
                >
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Slug Name
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {coupon?.slug}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
                    <p className="text-[#151515] text-sm font-bold flex">
                      {coupon?.title}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Amount
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      NGN {numberWithCommas(coupon?.amount)}
                    </p>
                  </div>
                  <div>
                    <label className="text-[#9CA3AF] text-sm mb-2">
                      Expires
                    </label>
                    <p className="text-[#151515] text-sm font-bold">
                      {moment(coupon?.expiry).format("MMM DD, YYYY")}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 pb-12 px-8 mb-5">
            <div className="border-b border-[#E4E4F3] py-3 mb-4 flex justify-between items-center">
              <h3 className="text-lg font-medium">Product Details</h3>
            </div>

            {isLoading ? (
              <TableSkeleton />
            ) : singleStoreOrder?.order_items?.length > 0 ? (
              singleStoreOrder?.order_items?.map((product, i) => (
                <div key={i}>
                  <div className="flex justify-between items-start mb-5">
                    <div className="flex">
                      <span className="w-6 mr-3 sm:mr-10">
                        {product.quantity}x
                      </span>
                      <div>
                        <p className="mb-1">{product?.product_name}</p>
                        {product?.product_attributes?.length > 0 &&
                          product?.product_attributes?.map((attr, i) => (
                            <p key={i} className="text-sm text-[#676565]">
                              {attr.quantity}x {attr?.name} - NGN {attr.amount}
                            </p>
                          ))}
                      </div>
                    </div>
                    <p>NGN {product?.product_amount}</p>
                  </div>
                </div>
              ))
            ) : (
              <div className="py-12 text-center">
                <div className="flex justify-center">
                  <img src={EmptyState} className="w-auto" alt="no data" />
                </div>
                <h3 className="mt-5 text-xl font-bold">Store Orders</h3>
                <p className="mt-2 text-sm text-[#9CA3AF]">
                  You do not have any orders yet.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
