import React, { useState } from "react";
import Input from "../../../components/inputs/inputs";
import Button from "../../../components/buttons/buttons";
import { useDispatch } from "react-redux";
import { getTeamFromCookies } from "../../../utils/Auth";
import { storeInvite } from "../../../redux/invite/inviteActions";

export default function InviteStore() {
  const business = getTeamFromCookies();
  const [input, setInput] = useState({
    invitee_email: "",
    job_title: "",
    job_description: "",
    type: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { invitee_email, job_description, job_title, type } = input;
  const dispatch = useDispatch();

  function handleChange(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: value }));
  }

  function handleSubmit(e) {
    e.preventDefault();
    setSubmitted(true);

    if (invitee_email && job_title && type) {
      // get return url from location state or default to home page
      dispatch(storeInvite(input, business));
    }
  }
  return (
    <div data-test="inviteStore-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md pt-8 pb-16 px-10 max-w-3xl mx-auto mt-24">
        <div className="border-b border-[#E4E4F3] py-3 text-center">
          <h3 className="text-lg font-medium">Invite New Store</h3>
        </div>

        <div className="mt-6">
          <form name="form" id="invite_form" onSubmit={handleSubmit}>
            <div
              className={
                "mt-4" + (submitted && invitee_email ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Email*"}
                type="email"
                htmlFor={"invitee_email"}
                name="invitee_email"
                id="invitee_email"
                placeholder="Enter email address"
                onChange={handleChange}
              />{" "}
              {submitted && !invitee_email && (
                <div className="text-primary text-xs ">
                  Invitee email is required
                </div>
              )}
            </div>
            <div>
              <label
                htmlFor="type"
                className="block text-sm font-medium text-gray-700"
              >
                Type*
              </label>
              <div className="mt-2 ">
                <select
                  name="type"
                  className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  onChange={handleChange}
                >
                  <option>Select a type</option>
                  <option value="employee">Employee</option>
                  <option value="courier">Courier</option>
                </select>
                {submitted && !type && (
                  <div className="text-primary text-xs ">Type is required</div>
                )}
              </div>
            </div>
            <div>
              <Input.Label
                title={"Job Title*"}
                type="text"
                name="job_title"
                htmlFor={"job_title"}
                placeholder="Enter Job title"
                onChange={handleChange}
              />
              {submitted && !job_title && (
                <div className="text-primary text-xs ">
                  Job title is required
                </div>
              )}
            </div>
            <div
              className={
                "mt-4" + (submitted && job_description ? " border-red-500" : "")
              }
            >
              <Input.Label
                title={"Job Descriptions"}
                type="text"
                name="job_description"
                htmlFor={"job_description"}
                onChange={handleChange}
                placeholder="Enter Job description"
              />
            </div>
          </form>
        </div>
      </div>
      <div className="py-8 px-10 max-w-3xl mx-auto text-center mt-6">
        <Button.Primary
          title={"Send Invite"}
          className="px-4"
          form={"invite_form"}
        />
      </div>
    </div>
  );
}
