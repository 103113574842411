import React, { useState } from "react";
import Food from "./foodPackage";
import Merchandise from "./MerchandisePackage";
import Store from "./storeOrder";
import Restaurant from "./restaurantOrder";

const tabs = [
  { name: "Restaurant Orders", href: "restaurant" },
  { name: "Store Orders", href: "store" },
  { name: "Food Package Orders", href: "food" },
  { name: "Merchandise Package Orders", href: "merchandise" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Table({ shipper }) {
  const [active_tab, setActiveTab] = useState("restaurant");

  const toggle = (tab) => {
    if (active_tab !== tab.href) {
      setActiveTab(tab.href);
    }
  };

  return (
    <div>
      <div className="px-5 mt-8 ">
        <div className="sm:block">
          <div>
            <nav
              className="-mb-px flex space-x-4 overflow-x-auto"
              aria-label="Tabs"
            >
              {tabs.map((singleTab) => (
                <button
                  key={singleTab.name}
                  onClick={() => {
                    toggle(singleTab);
                  }}
                  className={classNames(
                    singleTab.href === active_tab
                      ? "border-primary text-primary font-bold"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={
                    singleTab.href === active_tab ? "page" : undefined
                  }
                >
                  {singleTab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>
      </div>

      <div>
        {active_tab === "store" && <Store shipper={shipper} />}
        {active_tab === "restaurant" && <Restaurant shipper={shipper} />}
        {active_tab === "merchandise" && <Merchandise shipper={shipper} />}
        {active_tab === "food" && <Food shipper={shipper} />}
      </div>
    </div>
  );
}
