import React, { useEffect, useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import Button from "../../buttons/buttons";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import { updateARoleAction } from "../../../redux/roles/rolesActions";

export function EditRoleModal() {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const { singleRole } = useSelector((state) => state.roles);
  const [input, setInput] = useState({
    role_id: singleRole?.id,
    name: singleRole?.name,
    display_name: singleRole?.display_name,
    description: singleRole?.description,
    visibility: singleRole?.visibility,
  });
  const { name, display_name, description, visibility } = input;
  const { isLoading } = useSelector((state) => state.roles);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (singleRole) {
      setInput({
        ...input,
        role_id: singleRole?.id,
        name: singleRole?.name,
        display_name: singleRole?.display_name,
        description: singleRole?.description,
        visibility: singleRole?.visibility,
      });
    }
    // eslint-disable-next-line
  }, [singleRole]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (name && display_name && description) {
      try {
        const response = await dispatch(updateARoleAction(input));
        response === true && closeModal();
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Button.Primary title={"Edit Role"} type={"button"} onClick={openModal} />
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[415px] sm:w-[500px] bg-white rounded-3xl py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div>
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="sm:flex justify-center">
                      <div className="text-center sm:text-center mt-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-bold text-black"
                        >
                          Edit Role
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  <div className=" border-b-2 mt-4"></div>
                  <div className="mt-5 sm:mt-7 sm:px-8 p-4">
                    <form>
                      <Input.Label
                        title={"Name"}
                        type="text"
                        name="name"
                        id="name"
                        value={name}
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Enter role name"
                        onChange={handleChange}
                      />
                      {submitted && !name && (
                        <div className="text-xs text-red-500">
                          Name is required
                        </div>
                      )}
                      <Input.Label
                        title={"Display Name"}
                        type="text"
                        name="display_name"
                        id="display_name"
                        value={display_name}
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Permission display name"
                        onChange={handleChange}
                      />
                      {submitted && !display_name && (
                        <div className="text-xs text-red-500">
                          Display name is required
                        </div>
                      )}
                      <div>
                        <Input.Select
                          title={"Visibility"}
                          name="visibility"
                          id="visibility"
                          value={visibility}
                          htmlFor={"visibility"}
                          className={"border border-[#C2C9D1] w-full"}
                          onChange={handleChange}
                        >
                          <option value={""}>Select visibility type</option>
                          <option value={"public"}>Public</option>
                          <option value={"private"}>Private</option>
                          <option value={"protected"}>Protected</option>
                        </Input.Select>
                        {submitted && !visibility && (
                          <div className="text-xs text-red-500 -mt-2 mb-2">
                            Visibility is required
                          </div>
                        )}
                      </div>
                      <Input.TextArea
                        title={"Description"}
                        type="text"
                        name="description"
                        id="description"
                        value={description}
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Describe permission"
                        onChange={handleChange}
                      />
                      {submitted && !description && (
                        <div className="text-xs text-red-500">
                          Description is required
                        </div>
                      )}

                      <div className="flex gap-10 mt-8">
                        <Button.Secondary
                          title={"Cancel"}
                          className="w-full"
                          onClick={closeModal}
                        />
                        <Button.Primary
                          title={
                            isLoading ? <Beat color={"#ffffff"} /> : "Update Role"
                          }
                          disabled={isLoading}
                          type={"button"}
                          className="w-full"
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
