import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/buttons/buttons";
import { changePasswordAction } from "../../../redux/auth/authActions";

export default function ResetPassword() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [input, setInput] = useState({
    old_password: "",
    new_password: "",
    new_password_confirmation: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const { old_password, new_password, new_password_confirmation } = input;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      old_password &&
      new_password &&
      new_password_confirmation &&
      new_password === new_password_confirmation
    ) {
      try {
        await dispatch(changePasswordAction(input));
      } catch (error) {
        console.log(error);
      }
    }
  };
  function toggleFunction1(e) {
    var x = document.getElementById("old_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    e.target.classList.toggle("show");
  }
  function toggleFunction2(e) {
    var x = document.getElementById("new_password");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    e.target.classList.toggle("show");
  }
  function toggleFunction3(e) {
    var x = document.getElementById("new_password_confirmation");
    if (x.type === "password") {
      x.type = "text";
    } else {
      x.type = "password";
    }
    e.target.classList.toggle("show");
  }
  return (
    <div data-test="resetComponent">
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-6 pb-10 bg-white sm:min-h-[1000px] rounded-sm shadow-lg">
        <div className="border-b-2 py-4 sm:py-6 mb-6 flex sm:block justify-between items-center">
          <p className="text-base font-medium text-gray-800 text-center">
            Change Password
          </p>
        </div>
        <div className="justify-center items-center mx-auto max-w-[569px]">
          <form onSubmit={(e) => handleSubmit(e)}>
            <div className="mt-10 relative">
              <label
                htmlFor="old_password"
                className="block text-sm font-medium text-gray-700"
              >
                Old Password
              </label>
              <div>
                <input
                  type="password"
                  name="old_password"
                  id="old_password"
                  className="mt-4 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  placeholder="Enter old password"
                  onChange={(e) => handleChange(e)}
                />
                <p
                  className="text-sm text-[#FF725E] absolute top-[49px] right-5 cursor-pointer"
                  onClick={toggleFunction1}
                >
                  Show
                </p>
              </div>
              {submitted && !old_password && (
                <div className="text-xs text-red-500">
                  Old Password is required
                </div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="new_password"
                className="block text-sm font-medium text-gray-700"
              >
                New Password
              </label>
              <div>
                <input
                  type="password"
                  name="new_password"
                  id="new_password"
                  className="mt-4 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  placeholder="Enter new password"
                  onChange={(e) => handleChange(e)}
                />
                <p
                  className="text-sm text-[#FF725E] absolute top-[49px] right-5 cursor-pointer"
                  onClick={toggleFunction2}
                >
                  Show
                </p>
              </div>
              {submitted && !new_password && (
                <div className="text-xs text-red-500">
                  New Password is required
                </div>
              )}
              {submitted && new_password !== new_password_confirmation && (
                <div className="text-xs text-red-500">Passwords dont match</div>
              )}
            </div>
            <div className="relative">
              <label
                htmlFor="new_password_confirmation"
                className="block text-sm font-medium text-gray-700"
              >
                Confirm New Password
              </label>
              <div>
                <input
                  type="password"
                  name="new_password_confirmation"
                  id="new_password_confirmation"
                  className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  placeholder="Enter new password again"
                  onChange={(e) => handleChange(e)}
                />
                <p
                  className="text-sm text-[#FF725E] absolute top-[40px] right-5 cursor-pointer"
                  onClick={toggleFunction3}
                >
                  Show
                </p>
              </div>
              {submitted && !new_password_confirmation && (
                <div className="text-xs text-red-500">
                  New Password Confirmation is required
                </div>
              )}
              {submitted && new_password !== new_password_confirmation && (
                <div className="text-xs text-red-500">Passwords dont match</div>
              )}
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className=" mt-5 w-full inline-flex justify-center h-12 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
              >
                Submit
              </button>
            </div>
          </form>
          <Button.Secondary
            type="button"
            title={"Back"}
            className={"sm:hidden rounded-md mb-8 sm:mb-0 mt-6 w-full"}
            onClick={() => navigate("/profile")}
          />
        </div>
      </div>
    </div>
  );
}
