import React, { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import Card from "../../../components/cards/admin/cards";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import { Beat } from "../../../plugins/spinners.plugin";
import { PaginationNoRoute } from "../../../components/pagination/paginationNoRoute";
import EmptyState from "../../../assets/images/emptyState/orders.svg";
import FilterRestaurantOrdersDropDown from "../../../components/dropDown/filter/filterRestaurantOrders";
import { AllFiltersModal } from "../../../components/modals/allFiltersModal";
import { copyTextFromTable } from "../../../components/helpers";
import { ReactComponent as COPY } from "../../../assets/images/icons/copy1.svg";
import { writeFileWithXLSX } from "../../../components/exports/xlsx";
import { filterRestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";
import { searchRestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";
import searchRestaurantType from "../../../redux/restaurantOrder/restaurantOrderTypes";
import { getAllRestaurantOrdersNoPagination } from "../../../redux/restaurantOrder/restaurantOrderActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function RestaurantOrders() {
  const dispatch = useDispatch();
  const [isFilterOpen, setFilterState] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const { restaurant } = useSelector((state) => state.restaurant);
  const { allOrders, filtering, searching, downloading } = useSelector(
    (state) => state.restaurantOrder
  );

  const [payLoad] = useState({
    end_date: new Date(),
    restaurant_id: restaurant?.id,
  });

  useEffect(() => {
    dispatch(
      filterRestaurantOrderAction(
        { ...payLoad, restaurant_id: restaurant?.id },
        page
      )
    );
    // eslint-disable-next-line
  }, [page]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(
        searchRestaurantOrderAction({ ...input, restaurant_id: restaurant?.id })
      );
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllRestaurantOrdersNoPagination({
        ...payLoad,
        restaurant_id: restaurant?.id,
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Restaurant_Orders.xlsx",
        download: true,
      });
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allOrders?.data);
      setData(dataSet);
      dispatch({
        type: searchRestaurantType["SEARCH_RESTAURANT_ORDERS"],
        payLoad: { data: [] },
      });
    }
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order) => {
        let show = {
          id: (
            <span className="flex items-center">
              #
              {order?.id?.substr(0, 6) +
                "...." +
                order?.id?.substr(order?.id?.length - 6)}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, order?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          createdAt: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: <span>NGN {numberWithCommas(order?.order_amount)}</span>,
          payment_type: order?.order_payment_method ?? "Not available",
          status: order?.status,
          recipient: order?.recipient_name,
          recipient_phone: order?.recipient_phone,
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order) => {
        let show = {
          id: order?.id,
          createdAt: moment(order?.created_at).format("MMM DD, YYYY"),
          amount: order?.order_amount,
          payment_type: order?.order_payment_method ?? "Not available",
          status: order?.status,
          recipient: order?.recipient_name,
          recipient_phone: order?.recipient_phone,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allOrders?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allOrders]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Order Status",
            accessor: "show.status",
          },
          {
            Header: "Date",
            accessor: "show.createdAt",
          },
          {
            Header: "Payment Type",
            accessor: "show.payment_type",
          },
          {
            Header: "Order ID",
            accessor: "show.id",
          },
          {
            Header: "Customer Name",
            accessor: "show.recipient",
          },
          {
            Header: "Customer Phone",
            accessor: "show.recipient_phone",
          },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
        ],
      },
    ],
    []
  );

  

  return (
    <div>
      <Card.TableFilter
        title={
          allOrders?.total > 0 &&
          `Showing ${allOrders?.from ?? "0"} - ${allOrders?.to ?? "0"} of ${
            allOrders?.total ?? "0"
          } Results`
        }
        searchPlaceholder={"Search Orders"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterRestaurantOrdersDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#ffffff"} /> : "Download CSV"}
          disabled={!data}
          onClick={handleDownload}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-8">
        <h4>Orders Summary</h4>
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full"
            onClick={()=>{}}
          />
        ) : (
          <div className="py-16 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Orders</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any orders yet.
            </p>
          </div>
        )}
      </div>
      <PaginationNoRoute data={allOrders} page={page} setPage={setPage} />
    </div>
  );
}
