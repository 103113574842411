import React from "react";
import MyFoodPackageOrders from "./orders";

export default function UserFoodPackageOrders() {
  return (
    <div className="store-page">
      <div>
        <MyFoodPackageOrders />
      </div>
    </div>
  );
}
