// Application types

const SUBSCRIPTION_FOOD = {
  LOADING_AUTH: "LOADING_AUTH",
  SEARCHING: "SEARCHING",
  FILTERING: "FILTERING",
  DELETING: "DELETING",
  DOWNLOADING: "DOWNLOADING",
  APPROVING_FOOD: "APPROVING_FOOD",
  ALL_FOOD_SUBSCRIPTIONS: "ALL_FOOD_SUBSCRIPTIONS",
  SEARCH_FOOD_SUBSCRIPTIONS: "SEARCH_FOOD_SUBSCRIPTIONS",
  LOADING_SUBSCRIPTION: "LOADING_SUBSCRIPTION",
  ADD_SUBSCRIPTION_ITEM: "ADD_SUBSCRIPTION_ITEM",
  FILTER_FOOD_SUBSCRIPTIONS: "FILTER_FOOD_SUBSCRIPTIONS",
  SINGLE_SUBSCRIPTION_FOOD: "SINGLE_SUBSCRIPTION_FOOD",
  DELETE_SUBSCRIPTION_FOOD: "DELETE_SUBSCRIPTION_FOOD",
  APPROVE_SUBSCRIPTION_FOOD: "APPROVE_SUBSCRIPTION_FOOD",
  UNAPPROVE_SUBSCRIPTION_FOOD: "UNAPPROVE_SUBSCRIPTION_FOOD",
};

export default SUBSCRIPTION_FOOD;
