import React, { useEffect, useRef } from "react";
import { ReactComponent as Filter } from "../../assets/images/icons/table-filter.svg";

export const useOnOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (!ref.current?.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  });
};

export function AllFiltersModal({ children, isFilterOpen, setFilterState }) {
  const mobileNavRef = useRef();

  useOnOutsideClick(mobileNavRef, () => {
    if (isFilterOpen) setFilterState(false);
  });

  const toggleFilter = () => {
    setFilterState(!isFilterOpen);
  };

  return (
    <div data-test="filterModal" className="relative block">
      <div className="relative bg-red block w-full h-full" ref={mobileNavRef}>
        <button
          className="flex text-[#717485] gap-1 items-center"
          onClick={toggleFilter}
          type="button"
        >
          <Filter className="h-4 w-4" />
          Filter
        </button>
        {isFilterOpen && (
          <div className="absolute left-0 z-10 sm:w-[380px] border-[#E4E4F3] bg-white px-0 overflow-y-auto shadow-xl transform transition-all">
            {children}
          </div>
        )}
      </div>
    </div>
  );
}
