import axios from "../plugins/axios.plugin";

class SubscriptionMerchService {
  baseServicePath = "/api/subscription-merchandise";

  getAllSubscriptionMerchs() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllSubscriptionMerchNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getASubscriptionMerch(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}`);
  }

  createSubscriptionMerch(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }

  searchSubscriptionMerch(payLoad) {
    return axios.post(`${this.baseServicePath}/search/index`, payLoad);
  }

  filterSubscriptionMerch(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }

  deleteSubscriptionMerch(payLoad) {
    return axios.post(`${this.baseServicePath}/delete`, payLoad);
  }

  approveSubscriptionMerch(payLoad) {
    return axios.post(`${this.baseServicePath}/approve/management`, payLoad);
  }

  unapproveSubscriptionMerch(payLoad) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payLoad);
  }
}

export const subscriptionMerchService = new SubscriptionMerchService();
