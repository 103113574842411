import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import { filterHumanVerificationLevel } from "../../../redux/humanVerificationLevel/humanVerificationLevelActions";

export default function KYCTiersFilterDropDown({
  page,
  payLoad,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { loadingFeedback, filtering } = useSelector((state) => state.feedback);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    title: "",
    daily_limit: "",
    document_number: "",
    monthly_limit: "",
    start_date: "",
    end_date: "",
  });
  const {
    title,
    daily_limit,
    document_number,
    monthly_limit,
    start_date,
    end_date,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      title: "",
      daily_limit: "",
      document_number: "",
      monthly_limit: "",
      start_date: "",
      end_date: "",
    });
    await dispatch(filterHumanVerificationLevel(payLoad, page));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (
      title ||
      daily_limit ||
      document_number ||
      monthly_limit ||
      start_date ||
      end_date
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterHumanVerificationLevel({ ...data })
        );
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Title"}
              htmlFor={"title"}
              name={"title"}
              onChange={handleChange}
              value={title}
              placeholder={"Enter title"}
            />
            <Input.Number
              title={"Daily Limit"}
              htmlFor={"daily_limit"}
              name={"daily_limit"}
              onChange={handleChange}
              value={daily_limit}
              placeholder={"Daily limit e.g 1000"}
            />
            <Input.Number
              title={"Monthly limit"}
              htmlFor={"monthly_limit"}
              name={"monthly_limit"}
              onChange={handleChange}
              value={monthly_limit}
              placeholder={"Monthly limit e.g 1000"}
            />

            <Input.DatePicker
              title={"Start Date"}
              name="start_date"
              id="start_date"
              htmlFor={"start_date"}
              defaultValue={start_date}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />
            <Input.DatePicker
              title={"End Date"}
              name="end_date"
              id="end_date"
              htmlFor={"end_date"}
              defaultValue={end_date}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />

            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={loadingFeedback}
                title={loadingFeedback ? <Beat color={"#ffffff"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
            {errorMsg && (
              <div className="flex gap-1 -mt-2">
                <img src={Warning} alt="warning" />
                <p>No value to filter by</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
