import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { featureService } from "../../services/feature.service";
import featureType from "./featureTypes";

/**
 * Get all Featured Products
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllFeaturedProducts = (page) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: true });
    const response = await featureService.getAllFeaturedProducts(page);

    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    dispatch({ type: featureType["GET_ALL_FEATURED"], payLoad: response });
  } catch (error) {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all Featured Products
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllFeaturedNoPagination = (page) => async (dispatch) => {
  try {
    dispatch({ type: featureType["DOWNLOADING_FEATURED"], payLoad: true });
    const response = await featureService.getAllFeaturedNoPagination(page);
    dispatch({ type: featureType["DOWNLOADING_FEATURED"], payLoad: false });
    return response;
  } catch (error) {
    dispatch({ type: featureType["DOWNLOADING_FEATURED"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get active Featured Products
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getActiveFeaturedProducts = (page) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: true });
    const response = await featureService.getActiveFeaturedProducts(page);

    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    dispatch({ type: featureType["GET_ALL_FEATURED"], payLoad: response });
  } catch (error) {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get inactive Featured Products
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getInactiveFeaturedProducts = (page) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: true });
    const response = await featureService.getInactiveFeaturedProducts(page);

    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    dispatch({ type: featureType["GET_ALL_FEATURED"], payLoad: response });
  } catch (error) {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter Featured Products
 * 
 * @param {object} payLoad {
  employee_email:"bluehills@gmail.com",
  employee_name:"blue hills",
  employee_phone:"07034738829",
  employee_address:"12 victoria island lane",
  employee_city:"lagos",
  employee_state:"Lagos",
  employee_country:"Nigeria",
  }
 * @returns {void}
 */
export const filterFeaturedProducts = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: featureType["FILTERING"], payLoad: true });
    const response = await featureService.filterFeaturedProducts(payLoad);
    dispatch({ type: featureType["FILTERING"], payLoad: false });
    dispatch({ type: featureType["FILTER_FEATURED"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: featureType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search all employees
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const searchFeaturedProducts = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING_SEARCH"], payLoad: true });
    const response = await featureService.searchFeaturedProducts(payload);
    dispatch({ type: featureType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: featureType["SEARCH_FEATURED"], payLoad: response });
    // successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: featureType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Show employee
 * @param {object} payLoad {id:"eb2483aa-2c6a-407d-92af-238778668605"}
 * @returns
 */
export const getSingleFeatureProduct = (id) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: true });
    const response = await featureService.getSingleFeatureProduct(id);
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    dispatch({ type: featureType["GET_SINGLE_FEATURED"], payLoad: response });
  } catch (error) {
    dispatch({ type: featureType["LOADING_FEATURED"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search all employees
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const activateFeaturedProducts = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["ACTIVATING"], payLoad: true });
    const response = await featureService.activateFeaturedProducts(payload);
    dispatch({ type: featureType["ACTIVATING"], payLoad: false });
    dispatch({ type: featureType["ACTIVATE_FEATURED"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: featureType["ACTIVATING"], payLoad: false });
  }
};

/**
 * deactivate featured product
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const deactivateFeaturedProducts = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["ACTIVATING"], payLoad: true });
    const response = await featureService.deactivateFeaturedProducts(payload);
    dispatch({ type: featureType["ACTIVATING"], payLoad: false });
    dispatch({ type: featureType["DEACTIVATE_FEATURED"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: featureType["ACTIVATING"], payLoad: false });
  }
};

/**
 * store featured product
 */

export const storeFeaturedProduct = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING"], payLoad: true });
    const response = await featureService.storeFeaturedProduct(payload);
    dispatch({ type: featureType["LOADING"], payLoad: false });
    dispatch({
      type: featureType["STORE_FEATURED_PRODUCT"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    errorHandler(error, true);
    dispatch({ type: featureType["LOADING"], payLoad: false });
  }
};

/**
 * estimate featured product rate
 */

export const estimateFeaturedRate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["ESTIMATING"], payLoad: true });
    const response = await featureService.estimateFeaturedRate(payload);
    dispatch({ type: featureType["ESTIMATING"], payLoad: false });
    dispatch({
      type: featureType["ESTIMATE_FEATURED_RATE"],
      payLoad: response,
    });
    // successHandler(response, true);
    return response;
  } catch (error) {
    errorHandler(error, true);
    dispatch({ type: featureType["ESTIMATING"], payLoad: false });
  }
};

/**
 * store featured product rate
 */

export const storeFeaturedProductRate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["STORING"], payLoad: true });
    const response = await featureService.storeFeaturedProductRate(payload);
    dispatch({ type: featureType["STORING"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    errorHandler(error, true);
    dispatch({ type: featureType["STORING"], payLoad: false });
  }
};

/**
 * show featured product rate
 */

export const showFeaturedProductRate = (payload) => async (dispatch) => {
  try {
    dispatch({ type: featureType["LOADING"], payLoad: true });
    const response = await featureService.showFeaturedProductRate(payload);
    dispatch({ type: featureType["LOADING"], payLoad: false });
    dispatch({ type: featureType["GET_FEATURE_RATE"], payLoad: response });
    return response;
  } catch (error) {
    errorHandler(error, true);
    dispatch({ type: featureType["LOADING"], payLoad: false });
  }
};
