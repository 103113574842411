import React from "react";
import { useParams } from "react-router-dom";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";

export default function CreateBusinessDetails({
  handleChange,
  data,
  handleSubmit,
  submitted,
  Location,
  locationId,
  locationList,
  setLocationList,
  setLocationId,
  handleRegion,
  handleNext,
  business_phone,
  setBusinessPhone,
}) {
  const { type } = useParams();
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const {
    business_email,
    business_name,
    business_type,
    business_city,
    business_address,
    business_state,
    business_country,
    business_description,
  } = data;

  return (
    <div>
      <div
        className="pt-4 sm:pt-2 sm:flex items-center mx-auto"
        data-test="businessComponent"
      >
        <div className=" max-w-4xl mx-auto  mt-10">
          <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
            <form
              name="form"
              id="business-form"
              onSubmit={(e) => handleSubmit(e)}
            >
              <div className="border-b-2 py-4 mb-6">
                <p className=" text-base font-medium text-gray-800 flex justify-center ">
                  Create Business
                </p>
              </div>
              <div className="sm:px-10">
                <div
                  className={
                    "mt-4" +
                    (submitted && business_name ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Business Name *"}
                    htmlFor={"business_name"}
                    type={"text"}
                    name={"business_name"}
                    value={business_name}
                    onChange={handleChange}
                    placeholder={"Enter business name"}
                  />
                  {submitted && !business_name && (
                    <div className="text-xs text-red-500">
                      Business name is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && business_email ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Business Email *"}
                    htmlFor={"business_email"}
                    type={"email"}
                    value={business_email}
                    name={"business_email"}
                    onChange={handleChange}
                    placeholder={"Enter business email"}
                  />
                  {submitted && !business_email && (
                    <div className="text-xs text-red-500">
                      Business email is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && business_phone ? " border-red-500" : "")
                  }
                >
                  <Input.Phone
                    defaultCountry="NG"
                    title={"Business phone *"}
                    htmlFor={"business_phone"}
                    onChange={setBusinessPhone}
                    value={business_phone}
                  />
                  {submitted && !business_phone && (
                    <div className="text-xs text-red-500">
                      Business phone is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && business_type ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="business_type"
                    className="text-sm font-medium text-[#151515]"
                  >
                    Business Type *
                  </label>
                  <select
                    name="business_type"
                    value={business_type}
                    className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFF]  focus:ring-2 focus:ring-red-600  focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={handleChange}
                  >
                    <option>
                      {business_type ? business_type : "Select type"}
                    </option>
                    {type === "restaurant" ? (
                      <option value="restaurant">Restaurant</option>
                    ) : (
                      <option value="store">Store</option>
                    )}
                  </select>
                  {submitted && !business_type && (
                    <div className="text-xs text-red-500">
                      Business type is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && business_address ? " border-red-500" : "")
                  }
                >
                  <Input.Label
                    title={"Business Address *"}
                    htmlFor={"business_address"}
                    type={"text"}
                    value={business_address}
                    name={"business_address"}
                    placeholder={"Enter business address"}
                    onChange={handleChange}
                  />
                  {submitted && !business_address && (
                    <div className="text-xs text-red-500">
                      Business address is required
                    </div>
                  )}
                </div>

                <div
                  className={
                    "mt-4" +
                    (submitted && business_description ? " border-red-500" : "")
                  }
                >
                  <Input.TextArea
                    title={"Business Description "}
                    htmlFor={"business_description"}
                    type={"text"}
                    value={business_description}
                    name={"business_description"}
                    onChange={handleChange}
                    placeholder={"Describe your business to us"}
                  />
                </div>
                {/* Here, we utilize the location component to get the location data */}
                <Location
                  country_id={locationId.country_id}
                  state_id={locationId.state_id}
                  city_id={locationId.city_id}
                  countryList={(value) => {
                    setLocationList({
                      ...locationList,
                      countryList: value,
                    });
                  }}
                  stateList={(value) => {
                    setLocationList({
                      ...locationList,
                      stateList: value,
                    });
                  }}
                  cityList={(value) => {
                    setLocationList({ ...locationList, cityList: value });
                  }}
                />
                <div
                  className={
                    submitted && business_country ? " border-red-500" : ""
                  }
                >
                  <label
                    className="block text-sm font-medium text-[#151515]"
                    htmlFor="business_country"
                  >
                    Country*
                  </label>
                  <select
                    name="business_country"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600  sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        country_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>
                      {business_country ? business_country : "Select country"}
                    </option>

                    {locationList.countryList &&
                      locationList.countryList.length > 0 &&
                      locationList.countryList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>

                  {submitted && !business_country && (
                    <div className="text-xs text-red-500">
                      Business country is required
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4" +
                    (submitted && business_state ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="business_state"
                    className="block text-sm font-medium text-[#151515]"
                  >
                    {" "}
                    State*
                  </label>
                  <select
                    name="business_state"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        state_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>
                      {business_state ? business_state : "Select a state"}
                    </option>

                    {locationList.stateList &&
                      locationList.stateList.length > 0 &&
                      locationList.stateList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !business_state && (
                    <div className="text-xs text-red-500">
                      Business state is required.
                    </div>
                  )}
                </div>
                <div
                  className={
                    "mt-4 mb-16" +
                    (submitted && business_city ? " border-red-500" : "")
                  }
                >
                  <label
                    htmlFor="business_city"
                    className="block text-sm font-medium text-[#151515]"
                  >
                    City*{" "}
                  </label>
                  <select
                    name="business_city"
                    className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:outline-none focus:ring-2 focus:ring-red-600 focus:border-red-600  sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                    onChange={(e) => {
                      setLocationId({
                        ...locationId,
                        city_id: fromJson(e.target.value).id,
                      });
                      handleRegion(e);
                    }}
                  >
                    <option>
                      {business_city ? business_city : "Select a city"}
                    </option>
                    {locationList.cityList &&
                      locationList.cityList.length > 0 &&
                      locationList.cityList.map((item, key) => {
                        return (
                          <option
                            key={key}
                            value={toJson({
                              name: item.name,
                              id: item.id,
                            })}
                          >
                            {item.name}
                          </option>
                        );
                      })}
                  </select>
                  {submitted && !business_city && (
                    <div className="text-xs text-red-500">
                      Business city is required
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <Button.Primary
            onClick={handleNext}
            title={"Next"}
            className={"w-[333px] sm:mt-10 mx-auto py-4 flex justify-center  "}
          />
        </div>
      </div>
    </div>
  );
}
