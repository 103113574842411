import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import { publicServiceAreaService } from "../../services/publicServiceArea.service";
import serviceAreaType from "./publicServiceAreasTypes";

/**
 * GetAllPublicServiceAreas
 *
 */
export const getAllPublicServiceAreas = (page) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["LOADING_PUBLIC_SERVICE_AREAS"],
      payLoad: true,
    });
    const response = await publicServiceAreaService.getAllPublicServiceAreas(
      page
    );
    dispatch({
      type: serviceAreaType["LOADING_PUBLIC_SERVICE_AREAS"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["GET_ALL_PUBLIC_SERVICE_AREAS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: serviceAreaType["LOADING_PUBLIC_SERVICE_AREAS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * FilterPublicServiceAreas
 *
 */
export const filterPublicServiceAreas = (page) => async (dispatch) => {
  try {
    dispatch({
      type: serviceAreaType["FILTERING_PUBLIC_SERVICE_AREAS"],
      payLoad: true,
    });
    const response = await publicServiceAreaService.filterPublicServiceAreas(
      page
    );
    dispatch({
      type: serviceAreaType["FILTERING_PUBLIC_SERVICE_AREAS"],
      payLoad: false,
    });
    dispatch({
      type: serviceAreaType["FILTER_PUBLIC_SERVICE_AREAS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: serviceAreaType["FILTERING_PUBLIC_SERVICE_AREAS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
