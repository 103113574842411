import React, { useEffect, useState } from "react";
import Button from "../../../../components/buttons/buttons";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import { Switch } from "@headlessui/react";
import ADVERTIMG from "../../../../assets/images/pictures/advertImage.jpg";
import DotMenu from "../../../../assets/images/icons/dot-menu.svg";
import { Beat } from "../../../../plugins/spinners.plugin";
import EditMerchandisePriceModal from "../../../../components/modals/admin/editMerchPrice";
import ViewMerchFeature from "../../../../components/modals/feature/viewMerchFeature";
import { getTeamFromCookies } from "../../../../utils/Auth";
import {
  getSingleMerchandise,
  deleteMerchandise,
  approveMerchandiseManagement,
  unApproveMerchandiseManagement,
  approveMerchandise,
  unApproveMerchandise,
} from "../../../../redux/merchandise/merchandiseActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function SingleMerchandise() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const merchandise = location.state.merchandise;
  const team = getTeamFromCookies();

  const { singleMerchandise, deleting } = useSelector(
    (state) => state.merchandise
  );
  const [enabledApprove, setEnabledApprove] = useState(false);
  const [enabledPublish, setEnabledPublish] = useState(false);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [price, setPrice] = useState({
    id: merchandise?.id,
    merchandise_amount: merchandise?.merchandise_amount,
    merchandise_discount: merchandise?.merchandise_discount,
  });

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (merchandise?.id) {
      dispatch(getSingleMerchandise(merchandise?.id));
    }

    // eslint-disable-next-line
  }, [merchandise]);

  useEffect(() => {
    if (singleMerchandise?.id) {
      setEnabledApprove(singleMerchandise?.management_approved);
      setEnabledPublish(singleMerchandise?.business_approved);
    }
    // eslint-disable-next-line
  }, [singleMerchandise?.id]);

  function closeUpdateModal() {
    setOpenUpdate(false);
  }
  function openUpdateModal() {
    setOpenUpdate(true);
  }

  const toggleBtn = () => {
    setOpenDropdown(!openDropdown);
  };

  const approveByStore = () => {
    dispatch(approveMerchandise(merchandise?.id));
  };
  const unapproveByStore = () => {
    dispatch(unApproveMerchandise(merchandise?.id));
  };

  const handleApprove = () => {
    dispatch(approveMerchandiseManagement(merchandise?.id));
  };
  const handleUnApprove = () => {
    dispatch(unApproveMerchandiseManagement(merchandise?.id));
  };

  function goToSinglePage() {
    navigate(
      `/admin/merchandise/${merchandise?.id}/edit`,
      { state: { merchandise: merchandise } },
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
    );
  }

  function goToSingleStore() {
    const store_id = singleMerchandise?.store.id;
    navigate(`/admin/stores/${store_id}`);
  }

  const addImageFallback = (event) => {
    event.currentTarget.src = ADVERTIMG;
  };

  const deleteAMerchandise = async () => {
    try {
      const response = await dispatch(
        deleteMerchandise({ id: singleMerchandise?.id })
      );
      if (response.status === "success") {
        navigate("/admin/merchandise/approved/1");
      }
    } catch (error) {}
  };

  return (
    <>
      <EditMerchandisePriceModal
        show={openUpdate}
        onClose={closeUpdateModal}
        price={price}
        setPrice={setPrice}
        id={merchandise?.id}
      />
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">
            {singleMerchandise?.merchandise_name}
          </h3>

          <div className="relative block sm:hidden">
            <button
              className="flex text-[#717485] gap-1 items-center"
              onClick={toggleBtn}
              type="button"
            >
              <img src={DotMenu} alt="menu" />
            </button>
            {openDropdown && (
              <div className="absolute right-0 z-10 w-[280px] border-[#E4E4F3] bg-white px-0 overflow-y-auto shadow-xl transform transition-all">
                <div className="grid grid-cols-1 gap-4 p-4">
                  {team === "administrator" && (
                    <Button.Secondary
                      disabled={deleting}
                      title={
                        deleting ? (
                          <Beat color={"#ffffff"} />
                        ) : (
                          "Delete Merchandise"
                        )
                      }
                      className={"px-6"}
                      onClick={() => deleteAMerchandise()}
                    />
                  )}
                  <Button.Primary
                    title={"Edit Merchandise"}
                    onClick={goToSinglePage}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="hidden sm:flex gap-4 items-center">
            {team === "administrator" && (
              <Button.Secondary
                disabled={deleting}
                title={
                  deleting ? <Beat color={"#ffffff"} /> : "Delete Merchandise"
                }
                className={"px-6"}
                onClick={() => deleteAMerchandise()}
              />
            )}
            <Button.Primary
              title={"Edit Merchandise"}
              onClick={goToSinglePage}
            />
          </div>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Merchandise ID
            </label>
            <p className="text-[#151515] text-sm">{singleMerchandise?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Category</label>
            <p className="text-[#151515] text-sm">
              {singleMerchandise?.merchandise_categories}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Stock</label>
            <p className="text-[#151515] text-sm">{singleMerchandise?.stock}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] capitalize text-sm">
              {singleMerchandise?.merchandise_operating_status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "MMM, DD YYYY, HH:MMA"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] text-sm">{singleMerchandise?.flag}</p>
          </div>
          {/* Publish/Unpublish merchandise */}
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              {singleMerchandise?.business_approved ? "Unpublish" : "Publish"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabledPublish}
                onChange={(e) => {
                  setEnabledPublish(e);
                  singleMerchandise?.business_approved
                    ? unapproveByStore()
                    : approveByStore();
                }}
                className={classNames(
                  enabledPublish ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabledPublish ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
          {/* Approve/Unapprove switch */}
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              {singleMerchandise?.management_approved ? "Unapprove" : "Approve"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabledApprove}
                onChange={(e) => {
                  setEnabledApprove(e);
                  singleMerchandise?.management_approved
                    ? handleUnApprove()
                    : handleApprove();
                }}
                className={classNames(
                  enabledApprove ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabledApprove ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>

          {/* Split payment details */}
          {/* <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Split Payment</label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Initial Payment
            </label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Payment Intervals
            </label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Subsequent Payment
            </label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Duration</label>
            <p className="text-[#151515] text-sm">
              {moment(singleMerchandise?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div> */}
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Merchandise Price</h3>
          <Button.Primary title={"Edit Price"} onClick={openUpdateModal} />
        </div>

        <div className="grid grid-cols-2 lg:grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Price</label>
            <p className="text-[#151515] text-sm">
              {singleMerchandise?.merchandise_amount}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Discount</label>
            <p className="text-[#151515] text-sm">
              {" "}
              {singleMerchandise?.merchandise_discount}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">VAT</label>
            <p className="text-[#151515] text-sm">
              {singleMerchandise?.merchandise_amount}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Handling Fee</label>
            <p className="text-[#151515] text-sm">
              {" "}
              {singleMerchandise?.merchandise_discount}
            </p>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Merchandise Category</h3>
          </div>
          <div className="mt-6">
            <label className="text-[#9CA3AF] text-sm mb-2">Category</label>
            <p className="text-[#151515] text-sm mb-2">
              {singleMerchandise?.merchandise_categories?.length > 0 &&
                singleMerchandise?.merchandise_categories?.map(
                  (item, index) => <span key={index}>{item}, </span>
                )}
            </p>
          </div>
        </div>
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Merchandise Tag</h3>
          </div>
          <div className="mt-6">
            <label className="text-[#9CA3AF] text-sm mb-2">Tags</label>
            <p className="text-[#151515] text-sm mb-2">
              {singleMerchandise?.merchandise_tags?.length > 0 &&
                singleMerchandise?.merchandise_tags?.map((item, index) => (
                  <span key={index}>{item}, </span>
                ))}
            </p>
          </div>
        </div>
      </div>

      {singleMerchandise?.featured?.length > 0 && (
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Feature Details</h3>
            <ViewMerchFeature />
          </div>

          <div className="grid grid-cols-2 sm:grid-cols-5 gap-8 mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Cost</label>
              <p className="text-[#151515] text-sm">
                {singleMerchandise?.merchandise_amount}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
              <p className="text-[#151515] text-sm">Inactive</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Duration</label>
              <p className="text-[#151515] text-sm">1 year</p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Time Left</label>
              <p className="text-[#151515] text-sm">90 days </p>
            </div>
          </div>
        </div>
      )}

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Store Details</h3>
          <Button.Primary
            title={"View Store Details"}
            onClick={goToSingleStore}
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Store Name</label>
            <p className="text-[#151515] text-sm">
              {singleMerchandise?.store?.store_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm break-words">
              {" "}
              {singleMerchandise?.store?.store_email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">
              {" "}
              +{singleMerchandise?.store?.store_phone}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">
              {" "}
              {singleMerchandise?.store?.store_state}
            </p>
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">Merchandise Image</h3>
            <p className="text-sm">
              Accepts .jpg, .png and .jpeg image formats
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
          {singleMerchandise?.merchandise_pictures?.map((image, index) => (
            <figure className="w-full h-[160px] relative" key={index}>
              <img
                src={image}
                onError={addImageFallback}
                className="w-[160px] h-[160px] object-cover"
                alt="merchandise"
              />
            </figure>
          ))}
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Merchandise Attributes</h3>
        </div>

        {singleMerchandise?.attributes?.length > 0 ? (
          singleMerchandise?.attributes?.map((attr, i) => (
            <div key={i} className="border-b border-[#E4E4F3] mt-8">
              <p className="text-md my-6">
                Title : <span className="font-medium">{attr.title}</span>
              </p>

              <div className="grid grid-cols-2 gap-24 px-4">
                <p className="py-2 text-md">Name</p>
                <p className="py-2 text-md">Value</p>
              </div>
              <div key={i} className="grid grid-cols-2 gap-24 px-4">
                <p className="py-2 font-medium">{attr.name}</p>
                <p className="py-2 font-medium">NGN {attr.amount}</p>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center mx-auto max-w-2xl py-8">
            <h4 className="text-xl font-medium mb-1">
              You have no product Attributes yet
            </h4>
            <p>
              When you create new Product Attributes, it will be displayed here
            </p>
          </div>
        )}
      </div>
    </>
  );
}
