import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import cartType from "./publicCartType";
import { publicCartService } from "../../services/publicCart.services";

/**
 * Get all Foods
 **/

export const loadPublicCartAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: cartType["LOADING_FOOD"], payLoad: true });
    const response = await publicCartService.loadPublicCarts(payload);
    dispatch({ type: cartType["LOADING_FOOD"], payLoad: false });
    dispatch({ type: cartType["ALL_FOODS"], payLoad: response });
  } catch (error) {
    dispatch({ type: cartType["LOADING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

export const checkoutPublicCart = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: cartType["PROCESSING"],
      payLoad: true,
    });
    const response = await publicCartService.checkoutPublicCart(payLoad);

    dispatch({
      type: cartType["PROCESSING"],
      payLoad: false,
    });

    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: cartType["PROCESSING"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
