import axios from "../plugins/axios.plugin";

class ArrangementService {
  baseServicePath = "/api/arrangement";

  advertismentArrangements(payLoad) {
    return axios.post(`${this.baseServicePath}/advertisement`, payLoad);
  }

  storeArrangement(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  restaurantArrangement(payload) {
    return axios.post(`${this.baseServicePath}/restaurant`, payload);
  }
  merchandiseArrangement(payload) {
    return axios.post(`${this.baseServicePath}/merchandise`, payload);
  }
  foodArrangement(payload) {
    return axios.post(`${this.baseServicePath}/food`, payload);
  }
  featureArrangement(payload) {
    return axios.post(`${this.baseServicePath}/featured`, payload);
  }
  packageArrangement(payload) {
    return axios.post(`${this.baseServicePath}/package`, payload);
  }
}
export const arrangementService = new ArrangementService();
