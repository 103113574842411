// Application types

const BUSINESS = {
  LOADING_BUSINESS_SETTINGS: "LOADING_BUSINESS_SETTINGS",
  LOADING_SEARCH: "LOADING_SEARCH",
  APPROVING: "APPROVING",
  FILTERING: "FILTERING",
  UPDATING_BUSINESS_SETTINGS:'UPDATING_BUSINESS_SETTINGS',
  SEARCH_BUSINESS: " SEARCH_BUSINESS",
  FILTER_BUSINESS: " FILTER_BUSINESS",
  FILTER_BUSINESS_NOPAGE: " FILTER_BUSINESS_NOPAGE",
  MY_BUSINESS_SETTINGS: "MY_BUSINESS_SETTINGS",
  A_BUSINESS: "A_BUSINESS",
  UPDATE_BUSINESS_SETTINGS: "UPDATE_BUSINESS_SETTINGS",
  GET_ALL_BUSINESSES: "GET_ALL_BUSINESSES",
  EDIT_BUSINESS: "EDIT_BUSINESS",
};

export default BUSINESS;
