import advertismentType from "./publicAdvertismentTypes";

const initialState = {
  isLoading: false,
  approving: false,
  searching: false,
  allAdvertisments: [],
  search: [],
  singleAdvertisment: {},
  editAdvertisment: {},
  approveAdvertismentBusiness: {},
  unApproveAdvertismentBusiness: {},
  approveAdvertismentManagement: {},
  unApproveAdvertismentManagement: {},
  filterAdvertisments: [],
  employeeLocation: {},
  myAdvertisments: {},
  reloadMerch: {},
  createAdvertisment: {},
  testModel: {},
};

export default function publicAdvertismentReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case advertismentType.LOADING_ADVERTISMENT:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case advertismentType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case advertismentType.GET_ALL_ADVERTISMENT:
      let newData = {};
      if (action.payLoad?.data === undefined) {
        newData = {};
      } else {
        newData = action.payLoad?.data;
      }
      return {
        ...state,
        allAdvertisments: newData,
      };
    case advertismentType.EDIT_ADVERTISMENT:
      return {
        ...state,
        editAdvertisment: action.payLoad?.data,
      };

    case advertismentType.FILTER_ADVERTISMENT:
      return {
        ...state,
        filterAdvertisment: action.payLoad?.data?.data,
      };
    case advertismentType.GET_MY_ADVERTISMENT:
      let payLoadData = {};
      if (action.payLoad?.data === undefined) {
        payLoadData = {};
      } else {
        payLoadData = action.payLoad?.data;
      }
      return {
        ...state,
        myAdvertisments: payLoadData,
      };
    case advertismentType.DELETE_ADVERTISMENT:
      const deleteAdvertisment = state.allAdvertisments.filter(
        (item) => item.id !== action.payLoad.id
      );

      return {
        ...state,
        allAdvertisments: deleteAdvertisment,
      };
    case advertismentType.CREATE_ADVERTISMENT:
      return {
        ...state,
        create: action.payLoad.data,
      };
    case advertismentType.SHOW_AN_ADVERTISMENT:
      return {
        ...state,
        singleAdvertisment: action.payLoad?.data,
      };
    case advertismentType.SEARCH_ADVERTISMENT:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    default:
      return state;
  }
}
