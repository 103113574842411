import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import {
  storeFeaturedProductRate,
  showFeaturedProductRate,
} from "../../../../redux/feature/featureActions";

export default function FeatureRates() {
  const dispatch = useDispatch();
  const { storing, featureRate } = useSelector((state) => state.feature);

  useEffect(() => {
    dispatch(showFeaturedProductRate());
    // eslint-disable-next-line
  }, []);

  const [data, setData] = useState({
    featured_cost_per_hour: "",
    featured_discount: "0",
    featured_currency: "ngn",
  });
  const { featured_cost_per_hour } = data;

  useEffect(() => {
    setData({
      ...data,
      featured_cost_per_hour: featureRate?.featured_cost_per_hour,
    });
    // eslint-disable-next-line
  }, [featureRate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    try {
      await dispatch(storeFeaturedProductRate(data));
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div>
      <div className="mt-6">
        <div className="border border-[#E4E4F3] bg-white rounded-md sm:w-[569px] pt-8 pb-16 px-10 max-w-3xl mx-auto sm:mt-12">
          <div className="border-b border-[#E4E4F3] py-3 text-center">
            <h3 className="text-lg font-medium">Feature Rate Settings</h3>
          </div>

          <div className="mt-6">
            {/* <h4 className="font-bold ">Percentage Per Product Cost (%) :</h4>
            <p className="text-gray-400 text-sm mb-6">
              This is the charge per cost of product to be featured.
            </p>
            <Input.Label
              title={"Charge"}
              type="text"
              name="address"
              id="address"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="NGN 500"
            /> */}

            <h4 className="font-bold ">Cost Per Duration (NGN) :</h4>
            <p className="text-gray-400 text-sm mb-6">
              This is the charge per duration of feature.
            </p>
            <div className="flex gap-2">
              <Input.Label
                title={"Duration"}
                type="text"
                disabled
                className="w-full bg-[#F3F3F3] border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="1 hour"
              />

              <Input.Number
                title={"Charge"}
                type="number"
                name="featured_cost_per_hour"
                id="featured_cost_per_hour"
                value={featured_cost_per_hour}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm flex-1"
                placeholder="Enter featured cost per hour"
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className="mt-6 flex justify-center">
          <Button.Primary
            disabled={storing}
            title={storing ? <Beat color={"#ffffff"} /> : "Save Settings"}
            type="button"
            onClick={handleSubmit}
          />
        </div>
      </div>
    </div>
  );
}
