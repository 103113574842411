/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import MORE from "../../assets/images/icons/seeMore.svg";
import EditEmployee from "../modals/admin/editEmployee";
import TerminateEmployee from "../modals/admin/terminateEmployee";

export default function EmployeeDropDown({ singleEmployee }) {
  return (
    <Menu as="div" className="relative " data-test="dropDownComponent">
      <div>
        <Menu.Button className="inline-flex items-center justify-center cursor-pointer text-base h-10 w-10 ">
          <img src={MORE} alt="options icon" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="bg-white h-32 w-48 border rounded-sm p-3 shadow absolute top-30 right-1">
          <div className="py-1">
            <Menu.Item>
              <EditEmployee singleEmployee={singleEmployee} />
            </Menu.Item>

            <Menu.Item>
              <TerminateEmployee singleEmployee={singleEmployee} />
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
