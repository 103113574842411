import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { XIcon } from "@heroicons/react/outline";
import moment from "moment";
import Card from "../../../../components/cards/admin/cards";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { cleanInput } from "../../../../helpers/cleanInput";
import Location from "../../../../components/includes/location";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import EmptyState from "../../../../assets/images/emptyState/single-food.jpg";
import { AddPackageItemModal } from "../../../../components/modals/admin/addPackageItemModal";
import { getAllCurrencies } from "../../../../redux/currency/currencyAction";
import {
  updatePackageAction,
  getAPackageAction,
} from "../../../../redux/packages/packagesActions";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";

export default function EditFoodPackage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const hiddenFileInput = useRef(null);
  const hiddenFileInput2 = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const [file, setFile] = useState();
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [restaurantCategories, setRestaurantCategories] = useState([]);
  const [restaurantTags, setRestaurantTags] = useState([]);

  const [input, setInput] = useState({
    id: id,
    package_name: "",
    package_description: "",
    package_type: "",
    package_discount: 0,
    package_categories: [],
    package_tags: [],
    package_minimum_selection: "",
    package_products: {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
      saturday: [],
      sunday: [],
    },
    base64_photos: [],
    banners: [],
    package_start_date: "",
    package_end_date: "",
    package_currency: "",
    package_average_discount: "",
    package_average_amount: "",
    package_country: "",
    package_state: "",
    package_city: "",
    package_zip: "",
    package_latitude: "",
    package_longitude: "",
  });

  const {
    package_name,
    package_description,
    package_type,
    package_categories,
    package_tags,
    package_end_date,
    package_minimum_selection,
    package_currency,
    package_average_discount,
    package_average_amount,
  } = input;

  const [selected, setSelected] = useState({
    monday: [],
    tuesday: [],
    wednesday: [],
    thursday: [],
    friday: [],
    saturday: [],
    sunday: [],
  });

  const { singlePackage } = useSelector((state) => state.packages);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { updating } = useSelector((state) => state.packages);
  const { allCurrencies } = useSelector((state) => state.currency);

  useEffect(() => {
    dispatch(getAPackageAction(id));
  }, [dispatch, id]);

  useEffect(() => {
    if (singlePackage) {
      setInput({
        id: id,
        package_name: singlePackage?.package_name,
        package_description: singlePackage?.package_description,
        package_type: singlePackage?.package_type,
        package_discount: singlePackage?.package_discount,
        package_average_amount: singlePackage?.package_average_amount,
        package_average_discount: singlePackage?.package_average_discount,
        package_categories: singlePackage?.package_categories,
        package_tags: singlePackage?.package_tags,
        package_minimum_selection: singlePackage?.package_minimum_selection,
        package_products: singlePackage?.package_products,
        package_currency: singlePackage?.package_currency,
        base64_photos: singlePackage?.base64_photos,
        banners: singlePackage?.banners,
        package_end_date: moment(singlePackage?.package_end_date).format(
          "YYYY-MM-DD"
        ),
        package_start_date: moment(singlePackage?.package_start_date).format(
          "YYYY-MM-DD"
        ),
        package_country: singlePackage?.package_country,
        package_state: singlePackage?.package_state,
        package_city: singlePackage?.package_city,
        package_zip: singlePackage?.package_zip,
        package_latitude: singlePackage?.package_latitude,
        package_longitude: singlePackage?.package_longitude,
      });

      if (singlePackage?.package_products) {
        const newArr = createselectedarr();
        setSelected(newArr);
      }
    }

    // eslint-disable-next-line
  }, [singlePackage, id]);

  let currencies = [];
  for (const alphabetic_code in allCurrencies) {
    currencies.push(allCurrencies[alphabetic_code]);
  }

  function createselectedarr() {
    const news = Object.keys(singlePackage?.package_products);
    let newarr = {};
    for (let index = 0; index < news.length; index++) {
      let arrFoods = singlePackage?.package_products[news[index]].map(
        (item) => {
          let temp = singlePackage?.subscription_foods?.find(
            (element) => Number(element.id) === Number(item)
          );
          return temp;
        }
      );

      newarr[news[index]] = arrFoods;
    }
    return newarr;
  }

  const handleToggleTags = (value) => {
    let former_tags = [];
    if (input?.package_tags) {
      former_tags = [...input?.package_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, package_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setInput({ ...input, package_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setInput({ ...input, package_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (input?.package_categories) {
      former_categories = [...input?.package_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, package_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setInput({ ...input, package_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setInput({ ...input, package_categories: new_categories });
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    if (name === "package_end_date") {
      setInput({
        ...input,
        package_end_date: moment(value).format("YYYY-MM-DD"),
      });
    } else {
      setInput({ ...input, [name]: value });
    }
  };

  useEffect(() => {
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    dispatch(filterProductTag({ type: "restaurant" }, 1));
    dispatch(getAllCurrencies());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const restaurantCategories = allCategories?.data?.filter((item) => {
      return item?.type === "restaurant";
    });
    setRestaurantCategories(restaurantCategories);
    // eslint-disable-next-line
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "restaurant");
    setRestaurantTags(items);
  }, [allTags]);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  function handleRegion(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  }

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  //handle all image types
  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  const removeFile2 = (index) => {
    let newArr = file;
    newArr.splice(index, 1);
    setFile([...newArr]);
  };

  function onImage2Change(e) {
    let images = [...e.target.files];
    setFile(images);
    images?.forEach((image) => {
      const formData = new FormData();
      formData.append("filename", image);
      // setFileUpload(formData);
    });
  }
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const handleFileClick2 = (event) => {
    event.preventDefault();
    hiddenFileInput2.current.click();
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleSubmit = async () => {
    setSubmitted(true);
    let newInput = {
      ...input,
      base64_photos: image64 ? image64 : [],
    };
    cleanInput(newInput.package_products);
    if (
      package_name &&
      package_description &&
      package_description.length > 10 &&
      package_type &&
      package_categories &&
      package_tags
    ) {
      try {
        cleanInput(newInput);
        const response = await dispatch(updatePackageAction(newInput));
        if (response) {
          navigate("/admin/food-packages/packages");
        }
      } catch (error) {
        console.log(error);
        navigate("/admin/food-packages/packages");
      }
    }
  };
  return (
    <>
      <AddPackageItemModal
        show={isOpen}
        onClose={closeModal}
        selected={selected}
        setSelected={setSelected}
        input={input}
        setInput={setInput}
      />
      <div data-test="addFood-page">
        <div className="border border-[#E4E4F3] bg-white rounded-md h-fit">
          <div className="border-b border-[#E4E4F3] py-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Edit Food Package</h3>
            <Button.Primary
              disabled={updating}
              title={
                updating ? <Beat color={"#ffffff"} /> : "Update Food Package"
              }
              onClick={handleSubmit}
            />
          </div>

          <div className="py-5 px-8">
            <Input.Label
              title={"Title"}
              type={"text"}
              name="package_name"
              id="package_name"
              htmlFor={"package_name"}
              value={package_name}
              placeholder="Enter package name"
              className={"border border-[#C2C9D1] w-full"}
              onChange={handleChange}
            />
            {submitted && !package_name && (
              <div className="text-xs text-red-500">
                Package name is required
              </div>
            )}
            <Input.Label
              title={"Package Description"}
              type={"text"}
              name="package_description"
              id="package_description"
              htmlFor={"package_description"}
              value={package_description}
              placeholder="Kindly describe package"
              className={"border border-[#C2C9D1] w-full"}
              onChange={handleChange}
            />
            {package_description && package_description.length < 10 && (
              <div className="text-xs text-red-500">
                Food package description must be a minimum than 10 characters
              </div>
            )}
            {submitted && !package_description && (
              <div className="text-xs text-red-500">
                Package description is required
              </div>
            )}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <Input.Select
                  title={"Package Type"}
                  name="package_type"
                  id="package_type"
                  htmlFor={"package_type"}
                  className={"border border-[#C2C9D1] w-full"}
                  onChange={handleChange}
                >
                  <option value={"food"}>Food</option>
                </Input.Select>
                {submitted && !package_type && (
                  <div className="text-xs text-red-500">
                    Package type is required
                  </div>
                )}
              </div>

              <div>
                <Input.DatePicker
                  title={"Package Expiration Date"}
                  htmlFor={"package_end_date"}
                  name={"package_end_date"}
                  value={moment(singlePackage?.package_end_date).format(
                    "DD/MM/YYYY"
                  )}
                  onChange={handleChange}
                />
                {submitted && !package_end_date && (
                  <div className="text-xs text-red-500">
                    Package expiration date is required
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-4">
              <div>
                <Input.Number
                  title={"Package Average Amount"}
                  type="number"
                  name="package_average_amount"
                  id="package_average_amount"
                  value={package_average_amount}
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="NGN (₦)"
                  onChange={handleChange}
                />
              </div>

              <div>
                <Input.Number
                  title={"Package Average Discount"}
                  type="number"
                  name="package_average_discount"
                  id="package_average_discount"
                  value={package_average_discount}
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="NGN (₦)"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <label
                  htmlFor="package_categories"
                  className="text-sm font-medium text-[#151515] mb-2 block"
                >
                  Package Category*
                </label>
                <SelectableInput
                  options={restaurantCategories}
                  label="package_categories"
                  name={"+ Add Category"}
                  type="category"
                  previousSelected={package_categories}
                  selectOption={handleToggleCategories}
                />

                {submitted && !package_categories && (
                  <div className="text-xs text-red-500">
                    Package categories is required
                  </div>
                )}
              </div>

              <div>
                <label
                  htmlFor="package_tags"
                  className="text-sm font-medium text-[#151515] mb-2 block"
                >
                  Package Tags
                </label>
                <SelectableInput
                  options={restaurantTags}
                  label="package_tags"
                  name={"+ Add Tag"}
                  type="tag"
                  previousSelected={package_tags}
                  selectOption={handleToggleTags}
                />

                {submitted && !package_tags && (
                  <div className="text-xs text-red-500">
                    Package tags is required
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div>
                <Input.Select
                  title={"Currency"}
                  htmlFor={"package_currency"}
                  name={"package_currency"}
                  id={"package_currency"}
                  onChange={handleChange}
                  className={
                    "mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                  }
                >
                  <option>{package_currency ?? "Select Currency"}</option>
                  {currencies?.map((currency, i) => (
                    <option key={i} value={currency.alphabetic_code}>
                      {currency.alphabetic_code}
                    </option>
                  ))}
                </Input.Select>
              </div>

              <Input.Number
                title={"Minimum Selection"}
                type="number"
                name="package_minimum_selection"
                id="package_minimum_selection"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter minimum selection"
                value={package_minimum_selection}
                onChange={handleChange}
              />
            </div>

            {/* Location i.e: country, state, city, zip are added to package */}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              loadingLocation={(value) => {
                setLocationLoader(value);
              }}
              countryList={(value) => {
                setLocationList({
                  ...locationList,
                  countryList: value,
                });
              }}
              stateList={(value) => {
                setLocationList({
                  ...locationList,
                  stateList: value,
                });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div className="grid grid-cols-2 gap-4 mt-3">
              <Input.Select
                title={"Select Country"}
                name="package_country"
                id="country"
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"country"}
                value={input?.package_country}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>
                  {input?.package_country
                    ? input?.package_country
                    : "Select Country"}
                </option>
                {locationLoader ? (
                  <option>Loading...</option>
                ) : (
                  locationList?.countryList &&
                  locationList?.countryList?.length > 0 &&
                  locationList?.countryList?.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })
                )}
              </Input.Select>

              <Input.Select
                title={"Select State"}
                name="package_state"
                id="state"
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"state"}
                value={input?.package_state}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>
                  {input?.package_state ? input?.package_state : "Select State"}
                </option>
                {locationLoader ? (
                  <option>Loading...</option>
                ) : (
                  locationList?.stateList &&
                  locationList?.stateList?.length > 0 &&
                  locationList?.stateList?.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })
                )}
              </Input.Select>
            </div>
            {/* Location continues */}
            <div className="grid grid-cols-2 gap-4 mt-3">
              <div>
                <Input.Select
                  title={"Select City"}
                  name="package_city"
                  id="city"
                  className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                  htmlFor={"city"}
                  value={input?.package_city}
                  onChange={(e) => {
                    setLocationId({
                      ...locationId,
                      city_id: fromJson(e.target.value).id,
                    });
                    handleRegion(e);
                  }}
                >
                  <option>
                    {input?.package_city ? input?.package_city : "Select City"}
                  </option>
                  {locationLoader ? (
                    <option>Loading...</option>
                  ) : (
                    locationList?.cityList &&
                    locationList?.cityList?.length > 0 &&
                    locationList?.cityList?.map((item, key) => {
                      return (
                        <option
                          key={key}
                          value={toJson({
                            name: item.name,
                            id: item.id,
                          })}
                        >
                          {item.name}
                        </option>
                      );
                    })
                  )}
                </Input.Select>
              </div>

              <Input.Number
                title={"Zip Code"}
                type="number"
                name="package_zip"
                id="package_zip"
                value={input?.package_zip}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter zip code"
                onChange={handleChange}
              />
            </div>
            {/* Location continues */}
            <div className="grid grid-cols-2 gap-4 mt-3">
              <Input.Number
                title={"Latitude"}
                type="text"
                name="package_latitude"
                id="package_latitude"
                value={input?.package_latitude}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter latitude"
                onChange={handleChange}
              />

              <Input.Number
                title={"Longitude"}
                type="text"
                name="package_longitude"
                id="package_longitude"
                value={input?.package_longitude}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter longitude"
                onChange={handleChange}
              />
            </div>
            {/* Location ends */}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
              <div className="mb-4">
                <label className="block text-sm font-medium text-[#151515] mb-1">
                  Attach Food Package Image (banner)
                </label>
                <button
                  className="w-full border border-dashed border-[#C2C9D1] text-center p-3 mb-1"
                  onClick={handleFileClick2}
                >
                  --Attach--
                </button>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  multiple
                  onChange={onImage2Change}
                  ref={hiddenFileInput2}
                />
                <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                  {file?.length > 0 &&
                    file?.map((image, index) => (
                      <figure className="w-full h-[120px] relative" key={index}>
                        <img
                          src={URL.createObjectURL(image)}
                          className="object-cover w-full h-full"
                          alt="food"
                        />
                        <button
                          className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                          onClick={() => removeFile2(index)}
                        >
                          <XIcon className="h-5 w-5 text-primary mx-auto" />
                        </button>
                      </figure>
                    ))}
                </div>
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-[#151515] mb-1">
                  Attach Food Package Image (preview)
                </label>
                <button
                  className="w-full border border-dashed border-[#C2C9D1] text-center p-3 mb-1"
                  onClick={handleFileClick}
                >
                  --Attach--
                </button>
                <input
                  type="file"
                  className="hidden"
                  accept="image/*"
                  multiple
                  onChange={onImageChange}
                  ref={hiddenFileInput}
                />
                <div className="grid grid-cols-1 md:grid-cols-4 gap-3">
                  {imageUrls?.length > 0 &&
                    imageUrls?.map((image, index) => (
                      <figure className="w-full h-[120px] relative" key={index}>
                        <img
                          src={image}
                          className="object-cover w-full h-full"
                          alt="food"
                        />
                        <button
                          className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                          onClick={() => removeFile(index)}
                        >
                          <XIcon className="h-5 w-5 text-primary mx-auto" />
                        </button>
                      </figure>
                    ))}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <label className="block text-sm font-medium text-[#151515] mb-1">
                Attach Food Package Item
              </label>
              <button
                className="border border-dashed border-[#C2C9D1] text-center p-3 w-full"
                onClick={openModal}
              >
                --Attach--
              </button>
            </div>
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Monday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.monday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.monday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Tuesday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.tuesday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.tuesday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Wednesday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.wednesday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.wednesday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Thursday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.thursday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.thursday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Friday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.friday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.friday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Saturday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.saturday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.saturday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="mb-9 border border-[#E4E4F3] bg-white rounded-md py-5 mt-8">
          <div className="border-b border-[#E4E4F3] pb-3 px-8 flex justify-between items-center">
            <h3 className="text-lg font-medium">Sunday</h3>
            <Button.Primary title={"Add Meals"} onClick={openModal} />
          </div>
          <div className="px-8 mt-8">
            {selected?.sunday?.length > 0 ? (
              <Card.AddPackageItem
                foods={selected?.sunday}
                image={EmptyState}
              />
            ) : (
              <div className="max-w-3xl mx-auto text-center p-3">
                <h3 className="text-xl font-bold mb-2">
                  Food package shows here after you add food package item.
                </h3>
                <p className="text-md">
                  Select the best available food package deals for our
                  customers.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
