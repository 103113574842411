import axios from "../plugins/axios.plugin";

class PromoCodeService {
  baseServicePath = "/api/promotion/code";

  getAllCoupons() {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=1`);
  }
  filterPromoCodes(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  filterPromoCodesNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
  storePromoCode(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  searchPromoCodes(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showAPromoCode(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  showMyPromoCodes(payload) {
    return axios.get(`${this.baseServicePath}/me?properties=1`, payload);
  }
  editPromoCodes(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  deletePromoCode(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  expirePromoCode(payload) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payload);
  }
}
export const promoCodeService = new PromoCodeService();
