// import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { publicPackageService } from "../../services/publicPackage.service";
import authType from "./publicPackagesTypes";

/**
 * Get all packages
 **/

export const getAllPublicPackagesAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await publicPackageService.getAllPackages(page);
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["ALL_PACKAGES"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all packages
 **/

export const getAllPublicPackagesByFilter = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await publicPackageService.filterPackageWithPage(
      payload,
      page
    );
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["ALL_PACKAGES"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Get more packages
 **/

export const getMorePublicPackagesAction = (payload,page) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_MORE"], payLoad: true });
    const response = await publicPackageService.filterPackageWithPage(
      payload,page
    );
    dispatch({ type: authType["LOADING_MORE"], payLoad: false });
    dispatch({ type: authType["MORE_PACKAGES"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_MORE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a package
 **/

export const getAPublicPackageAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await publicPackageService.getAPackage(id);
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["SINGLE_PACKAGE"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter a package
 **/

export const filterPublicPackageAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await publicPackageService.filterPackage(payLoad);
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["FILTER_PACKAGE"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};
