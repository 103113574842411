import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../components/cards/cards";
import Button from "../../../../components/buttons/buttons";
import Container from "../../../../components/container/container";
import { cleanInput } from "../../../../helpers/cleanInput";
import { Beat } from "../../../../plugins/spinners.plugin";
import RestaurantSkeleton from "../../../../components/skeleton/restaurants";
import EmptyState from "../../../../assets/images/emptyState/restaurant.svg";
import { ReactComponent as Filter } from "../../../../assets/images/icons/filter.svg";
import { FilterModal } from "../../../../components/modals/filterModal";
import FilterRestaurantCategory from "../../filter/RestaurantCategory";
import { filterPublicRestaurantsAction } from "../../../../redux/publicRestaurant/publicRestaurantActions";

export default function CategoryRestaurant() {
  const { name } = useParams();
  const dispatch = useDispatch();

  const { isLoading, filter, loadingMore } = useSelector(
    (state) => state.publicRestaurant
  );
  const { settings } = useSelector((state) => state.application);

  const [restaurants, setRestaurants] = useState([]);
  const [filterCount, setFilterCount] = useState(0);
  let [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  async function makeRequest() {
    const response = await dispatch(
      filterPublicRestaurantsAction({
        restaurant_categories: [name],
      })
    );
    if (response.status === "success") {
      setRestaurants(response?.data?.data);
    }
  }
  useEffect(() => {
    if (name !== "") {
      makeRequest();
    }
    // eslint-disable-next-line
  }, [name, dispatch]);

  const handleFilter = (data) => {
    cleanInput(data);
    dispatch(filterPublicRestaurantsAction(data));
  };

  return (
    <div className="category" data-test="categoryList">
      <Container>
        <div className="py-5 mt-12">
          <div className="flex justify-between gap-4 items-center mb-2">
            <h3 className="text-3xl font-bold">Category - {name}</h3>
            <div className="flex items-center gap-4">
              <span className="hidden sm:block">
                Sort by: <span className="text-primary">All</span>
              </span>
              <div className="w-[40px] h-[40px] bg-[#FFEAE5] rounded items-center justify-center flex relative">
                <span
                  className={`${
                    filterCount > 0 ? "block" : "hidden"
                  } h-4 w-4 rounded-full bg-black text-white absolute -top-1 -right-2 text-center text-xs`}
                >
                  {filterCount}
                </span>
                <Filter
                  className="cursor-pointer h-6 w-6"
                  onClick={openModal}
                />
              </div>
              <FilterModal
                show={isOpen}
                onClose={closeModal}
                onClick={handleFilter}
                setFilterCount={setFilterCount}
              />
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="py-5">
          {isLoading && <RestaurantSkeleton />}
          {!isLoading && (
            <>
              {filterCount === 0 ? (
                <>
                  {restaurants?.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-8">
                      {restaurants?.map((restaurant, i) => (
                        <Card.Restaurants
                          key={i}
                          id={restaurant?.id}
                          title={restaurant?.restaurant_name}
                          tags={restaurant?.restaurant_tags}
                          categories={restaurant?.restaurant_categories}
                          image={restaurant?.restaurant_pictures?.[0]}
                          operating_settings={
                            settings?.restaurant_operating_status
                          }
                          operating_status={
                            restaurant?.restaurant_operating_status
                          }
                          operating_hours={
                            restaurant?.restaurant_operating_time
                          }
                        />
                      ))}
                    </div>
                  ) : (
                    <div className="flex justify-center py-16">
                      <div className="text-center">
                        <img src={EmptyState} alt="empty" />
                        <p className="my-6">No restaurants found</p>
                      </div>
                    </div>
                  )}
                  <div className="mt-24 mb-10 text-center">
                    <p className="mb-4">
                      You’ve viewed{" "}
                      <span className="font-bold">{filter?.data?.length}</span>{" "}
                      out of {filter?.total} results
                    </p>
                    {filter?.data?.length < filter?.total && (
                      <Button.Secondary
                        title={loadingMore ? <Beat /> : "Load More"}
                        disabled={loadingMore}
                        className="px-16"
                        // onClick={() => loadMore()}
                      />
                    )}
                  </div>
                </>
              ) : (
                <FilterRestaurantCategory />
              )}
            </>
          )}
        </div>
      </Container>
    </div>
  );
}
