import { useSelector } from "react-redux";
import Card from "../../../components/cards/cards";
import EmptyState from "../../../assets/images/emptyState/restaurant.svg";

export default function FilterRestaurants() {
  const { filter } = useSelector((state) => state.publicRestaurant);
  const { settings } = useSelector((state) => state.application);
  let restaurants = filter?.data;
  return (
    <div data-test="filterRestaurantsComponent">
      {restaurants?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
          {restaurants?.map((restaurant, i) => (
            <Card.Restaurants
              key={i}
              id={restaurant?.id}
              title={restaurant?.restaurant_name}
              tags={restaurant?.restaurant_tags}
              image={restaurant?.restaurant_pictures?.[0]}
              categories={restaurant?.restaurant_categories}
              operating_settings={settings?.restaurant_operating_status}
              operating_status={restaurant?.restaurant_operating_status}
              operating_hours={restaurant?.restaurant_operating_time}
            />
          ))}
        </div>
      ) : (
        <div className="flex justify-center py-16">
          <div className="text-center">
            <img src={EmptyState} alt="empty" />
            <p className="my-6">No restaurants found</p>
          </div>
        </div>
      )}
    </div>
  );
}
