import React, { useMemo } from "react";
//import { useDispatch } from "react-redux";
//import { useNavigate, useParams } from "react-router-dom";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import { Pagination } from "../../../../components/pagination/pagination";
//import { showMyCart } from "./../../../../redux/cart/cartAction";

// sample data array looks like this
const data = [
  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },
  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },

  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },
  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },
  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },
  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },
  {
    show: {
      cart_id: "15849",
      restaurant: "KFC",
      buyer: "Guest",
      quantity: "090989796949",
      date: "7th, Apr 2022: 05:30 AM",
      product: "KFC's Chicken & Chips",
      unit_price: "₦153,000.00",
      action: "Manage",
    },
  },
];

export default function StoreCarts() {

  const columns = useMemo(
    () => [
      {
        Header: "",
        hcart_eHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Cart ID",
            accessor: "show.cart_id",
          },
          {
            Header: "Restaurant",
            accessor: "show.restaurant",
          },
          {
            Header: "Buyer",
            accessor: "show.buyer",
          },
          {
            Header: "Quantity",
            accessor: "show.quantity",
          },
          {
            Header: "Date",
            accessor: "show.date",
          },
          {
            Header: "Product",
            accessor: "show.product",
          },
          {
            Header: "Unit Price",
            accessor: "show.unit_price",
          },
          {
            Header: "Action",
            accessor: "show.action",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="store-cart">
      <Card.TableFilter results={80} />
      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-8">
        <Table columns={columns} data={data} className="w-full" />
      </div>

      <Pagination />
    </div>
  );
}
