import { currencyService } from "../../services/currency.service";
import currencyType from "./currencyTypes";

/**
 * Get all currencies
 **/

export const getAllCurrencies = () => async (dispatch) => {
  try {
    dispatch({ type: currencyType["LOADING_CURRENCY"], payLoad: true });
    const response = await currencyService.getAllCurrencies();
    dispatch({ type: currencyType["LOADING_CURRENCY"], payLoad: false });
    dispatch({ type: currencyType["GET_ALL_CURRENCIES"], payLoad: response });
  } catch (error) {
    dispatch({ type: currencyType["LOADING_CURRENCY"], payLoad: false });
  }
};
