import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { roleService } from "../../services/roles.service";
import roleType from "./rolesTypes";

/**
 * Get all roles
 **/

export const getAllRolesAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: true });
    const response = await roleService.getAllRoles(page);
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: roleType["GET_ALL_ROLES"], payLoad: response });
  } catch (error) {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a role
 **/

export const getARoleAction = (role_id) => async (dispatch) => {
  try {
    const response = await roleService.getARole(role_id);
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: roleType["GET_A_ROLE"], payLoad: response });
  } catch (error) {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * assign a role
 **/

export const assignARoleAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: roleType["ASSIGNING"], payLoad: true });
    const response = await roleService.assignARole(payload);
    dispatch({ type: roleType["ASSIGNING"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: roleType["ASSIGNING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * retract a role
 **/

export const retractARoleAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: roleType["ASSIGNING"], payLoad: true });
    const response = await roleService.retractARole(payload);
    dispatch({ type: roleType["ASSIGNING"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: roleType["ASSIGNING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Store a role
 **/

export const storeARoleAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: true });
    const response = await roleService.storeARole(payload);
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: roleType["STORE_A_ROLE"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Store a role
 **/

export const updateARoleAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: true });
    const response = await roleService.updateARole(payload);
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    // dispatch({ type: roleType["STORE_A_ROLE"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Store a permission
 **/

export const storeAPermissionAction = (payload) => async (dispatch) => {
  try {
    const response = await roleService.storeAPermission(payload);
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * assign a permission
 **/

export const assignAPermissionAction = (payload) => async (dispatch) => {
  try {
    const response = await roleService.assignAPermission(payload);
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: roleType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};
