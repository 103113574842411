import axios from "../plugins/axios.plugin";

class FoodService {
  baseServicePath = "/api/food";

  getAllFood() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllFoodNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getAFood(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  storeAFood(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  updateAFood(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  deleteAFood(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  approveFoodByMgt(payload) {
    return axios.post(`${this.baseServicePath}/approve/management`, payload);
  }

  unapproveFoodByMgt(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payload);
  }

  approveFoodBySelf(payload) {
    return axios.post(`${this.baseServicePath}/approve/business`, payload);
  }

  unapproveFoodBySelf(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/business`, payload);
  }

  searchFoods(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  filterFood(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}&properties=1`,
      payload
    );
  }
}

export const foodService = new FoodService();
