import React from "react";
import Container from "../../components/container/container";
import ResetPasswordForm from "../../components/forms/auth/resetpasswordForm";

function ResetPassword() {
  return (
    <>
      <div data-test="">
        <Container>
          <div className="max-w-[720px] mx-auto mt-20">
            <h3 className="text-2xl mb-12 font-medium">Reset Password</h3>
            <ResetPasswordForm />
          </div>
        </Container>
      </div>
    </>
  );
}

export default ResetPassword;
