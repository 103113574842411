import axios from "../plugins/axios.plugin";

class RestaurantService {
  baseServicePath = "/api/restaurant";

  getAllRestaurants(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAllRestaurantsNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getARestaurant(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }

  searchRestaurants(params) {
    return axios.post(`${this.baseServicePath}/search/index`, params);
  }

  getMyRestaurants() {
    return axios.get(`${this.baseServicePath}/me`);
  }

  approveRestaurantBySelf(payload) {
    return axios.post(`${this.baseServicePath}/approve/business`, payload);
  }

  unapproveRestaurantBySelf(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/business`, payload);
  }

  approveRestaurantByMgt(payload) {
    return axios.post(`${this.baseServicePath}/approve/management`, payload);
  }

  unapproveRestaurantByMgt(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payload);
  }

  filterRestaurants(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }

  createRestaurant(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  upDateRestaurant(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
}

export const restaurantService = new RestaurantService();
