import errorHandler from "../../handlers/errorHandler";
import { publicCatalogueService } from "../../services/publicCatalogue.service";
import authType from "./publicCatalogueTypes";

/**
 * Get all Catalogues
 **/

export const getAllPublicCataloguesAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_CATALOGUE"], payLoad: true });
    const response = await publicCatalogueService.getAllPublicCatalogues();
    dispatch({ type: authType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({ type: authType["ALL_CATALOGUES"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a Catalogue
 **/

export const getAPublicCatalogueAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_CATALOGUE"], payLoad: true });
    const response = await publicCatalogueService.getAPublicCatalogue(id);
    dispatch({ type: authType["LOADING_CATALOGUE"], payLoad: false });
    dispatch({ type: authType["SINGLE_CATALOGUE"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_CATALOGUE"], payLoad: false });
    errorHandler(error, true);
  }
};
