import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
// components
import Button from "../../../components/buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
// requests
import {
  showAPayment,
  verifyPayment,
} from "../../../redux/payment/paymentActions";
import errorHandler from "../../../handlers/errorHandler";
import successHandler from "../../../handlers/successHandler";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SinglePayment() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);

  const { singlePayment, verifying } = useSelector((state) => state.payment);

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(showAPayment(id));
    // eslint-disable-next-line
  }, []);

  const verifyPaymentMethod = async (provider, payLoad) => {
    try {
      const response = await dispatch(verifyPayment(provider, { id: payLoad }));
      if (response?.status === "success") {
        successHandler(response, true);
        dispatch(showAPayment(id));
      }
    } catch (error) {
      errorHandler(error, true);
    }
  };

  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">Payment Details</h3>
          <Button.Primary
            type={"button"}
            disabled={
              verifying ||
              singlePayment?.method === null ||
              singlePayment?.status !== "pending"
            }
            title={
              verifying ? <Beat /> : isMobile() ? "Verify" : "Verify Payments"
            }
            onClick={() =>
              verifyPaymentMethod(singlePayment?.method, singlePayment?.id)
            }
          />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Payment ID</label>
            <p className="text-[#151515] text-sm">{singlePayment?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Amount</label>
            <p className="text-[#151515] text-sm break-words">
              NGN {numberWithCommas(singlePayment?.amount)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Payment Method
            </label>
            <p className="text-[#151515] text-sm">
              {singlePayment?.method ?? "Not available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
            <p className="text-[#151515] text-sm">
              {moment(singlePayment?.created_at).format("DD, MMM YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Reference ID</label>
            <p className="text-[#151515] text-sm">
              {singlePayment?.reference ?? "Not available"}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Type</label>
            <p className="text-[#151515] text-sm">{singlePayment?.type}</p>
          </div>
        </div>
      </div>

      {singlePayment?.restaurant_order?.length > 0 &&
        singlePayment?.restaurant_order?.map((order, index) => (
          <div
            key={index}
            className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8"
          >
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
              <h3 className="text-lg font-medium">
                Restaurant Order - {order?.id}
              </h3>
              <Button.Primary
                type={"button"}
                title={isMobile() ? "View" : "View Order"}
                onClick={() =>
                  navigate(`/admin/orders/restaurant/${order?.id}`)
                }
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm">{order?.id}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm break-words">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
                <p className="text-[#151515] text-sm">
                  {moment(order?.created_at).format("DD, MMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        ))}

      {singlePayment?.store_order?.length > 0 &&
        singlePayment?.store_order?.map((order, index) => (
          <div
            key={index}
            className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8"
          >
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
              <h3 className="text-lg font-medium">Store Order - {order?.id}</h3>
              <Button.Primary
                type={"button"}
                title={isMobile() ? "View" : "View Order"}
                onClick={() => navigate(`/admin/orders/store/${order?.id}`)}
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm">{order?.id}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm break-words">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
                <p className="text-[#151515] text-sm">
                  {moment(order?.created_at).format("DD, MMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        ))}

      {singlePayment?.subscription_food_order?.length > 0 &&
        singlePayment?.subscription_food_order?.map((order, index) => (
          <div
            key={index}
            className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8"
          >
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
              <h3 className="text-lg font-medium">
                Food Package Order - {order?.id}
              </h3>
              <Button.Primary
                type={"button"}
                title={isMobile() ? "View" : "View Order"}
                onClick={() =>
                  navigate(`/admin/orders/package-food/${order?.id}`)
                }
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm">{order?.id}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm break-words">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
                <p className="text-[#151515] text-sm">
                  {moment(order?.created_at).format("DD, MMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        ))}

      {singlePayment?.subscription_merchandise_order?.length > 0 &&
        singlePayment?.subscription_merchandise_order?.map((order, index) => (
          <div
            key={index}
            className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8"
          >
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
              <h3 className="text-lg font-medium">
                Food Package Order - {order?.id}
              </h3>
              <Button.Primary
                type={"button"}
                title={isMobile() ? "View" : "View Order"}
                onClick={() =>
                  navigate(`/admin/orders/package-merchandise/${order?.id}`)
                }
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm">{order?.id}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm break-words">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
                <p className="text-[#151515] text-sm">
                  {moment(order?.created_at).format("DD, MMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        ))}

      {singlePayment?.featured?.length > 0 &&
        singlePayment?.featured?.map((order, index) => (
          <div
            key={index}
            className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8"
          >
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
              <h3 className="text-lg font-medium">
                Food Package Order - {order?.id}
              </h3>
              <Button.Primary
                type={"button"}
                title={isMobile() ? "View" : "View Order"}
                onClick={() => navigate(`/admin/feature/${order?.id}`)}
              />
            </div>

            <div className="grid grid-cols-2 sm:grid-cols-6 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm">{order?.id}</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm break-words">
                  NGN {numberWithCommas(order?.order_amount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Discount
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_discount)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_delivery_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Service Fee
                </label>
                <p className="text-[#151515] text-sm">
                  NGN {numberWithCommas(order?.order_service_fee)}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
                <p className="text-[#151515] text-sm">
                  {moment(order?.created_at).format("DD, MMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
}
