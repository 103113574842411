import authType from "./humanVerificationLevelTypes";

const initialState = {
  isLoading: false,
  updating: false,
  filtering: false,
  myProfile: {},
  allVerificationLevel: {},
  singleVerification: {},
  searching: false,
  search: {},
};

export default function humanVerificationLevelReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.MY_PROFILE:
      return {
        ...state,
        myProfile: action.payLoad?.data,
      };

    case authType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.FILTER_VERIFICATION_LEVEL:
      return {
        ...state,
        allVerificationLevel: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_VERIFICATION:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case authType.SHOW_A_VERIFICATION:
      return {
        ...state,
        singleVerification: action.payLoad?.data,
      };

    default:
      return state;
  }
}
