import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../../../../../components/buttons/buttons";
import Input from "../../../../../components/inputs/inputs";
import { cleanInput } from "../../../../../helpers/cleanInput";
import { Beat } from "../../../../../plugins/spinners.plugin";
import {
  editABusiness,
  getMyBusiness,
} from "../../../../../redux/business/businessActions";

export default function EditBusiness() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const hiddenFileInput = useRef(null);
  const business = location?.state?.business;

  const [input, setInput] = useState({});
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState();
  const [businessData, setBusinessData] = useState({});

  const { isLoading } = useSelector((state) => state.business);

  useEffect(() => {
    setBusinessData(business);
  }, [business]);

  useEffect(() => {
    setInput({
      id: businessData?.id,
      business_name: businessData?.business_name,
      business_email: businessData?.business_email,
      business_address: businessData?.business_address,
      business_phone: businessData?.business_phone,
    });
  }, [businessData]);

  const handleBusinessChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handlePhone = (e) => {
    let business_phone = e?.slice(1);
    setInput({ ...input, business_phone: business_phone });
  };
  function onImageChange(e) {
    setImages([...e.target.files]);
    let reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onloadend = () => {
      setImage64(reader.result);
    };
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  useEffect(() => {
    if (images.length < 1) return;
    const newImageUrls = [];
    images.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setImageUrls(newImageUrls);
  }, [images]);

  const handleBusinessSubmit = async () => {
    const newInput = { ...input, base64_photos: image64 ? [image64] : [] };
    cleanInput(newInput);
    try {
      const response = await dispatch(editABusiness(newInput));
      if (response.status === "success") {
        dispatch(getMyBusiness());
        navigate(-1);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  return (
    <div className="flex justify-center items-center mx-auto">
      <div className="sm:w-[976px] w-full sm:border px-6 bg-white sm:border-gray-200 ">
        <div className="border-b-2 py-4 mb-6 flex justify-between ">
          <p className=" text-base sm:mt-6 font-bold text-gray-800 ">
            Business Information
          </p>
          <Button.Primary
            title={isLoading ? <Beat /> : "Save Changes"}
            type={"button"}
            form={"b_form"}
            className={"w-[127px] rounded-sm sm:mt-4"}
            onClick={(e) => handleBusinessSubmit(e)}
          />
        </div>
        <form id="b_form" className="sm:px-10 max-w-lg">
          <div>
            <Input.Label
              title={"Business Name"}
              htmlFor="business_name"
              name="business_name"
              placeholder="Enter businessname"
              onChange={(e) => handleBusinessChange(e)}
              defaultValue={business?.business_name}
            />
          </div>

          <div className="mt-4">
            <Input.Phone
              title={" Business Phone Number"}
              htmlFor="business_phone"
              name="business_phone"
              value={business?.business_phone}
              onChange={(e) => handlePhone(e)}
            />
          </div>
          <div className="mt-4">
            <Input.Label
              title={"Business Email"}
              htmlFor="business_email"
              name={"business_email"}
              defaultValue={business?.business_email}
              onChange={(e) => handleBusinessChange(e)}
            />
          </div>
          <div className="mt-4">
            <Input.Label
              title={"Business Address"}
              htmlFor={"business_address"}
              defaultValue={business?.business_address}
              name={"business_address"}
              onChange={(e) => handleBusinessChange(e)}
            />
          </div>
          <div>
            <label className="text-sm font-medium text-[#151515]">
              Business Banner
            </label>
            <div className="mt-2 block pl-3 pr-10 w-full text-base focus:outline-2 bg-[#FFFFFF]  focus:ring-4 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm min-h-[40px] px-4 py-2 mb-8 border border-dashed border-gray-300 cursor-pointer">
              {imageUrls?.length > 0 ? (
                imageUrls?.map((image, index) => (
                  <div key={index}>
                    <img
                      src={image}
                      className="w-[160px] min-h-[120px] "
                      alt="Business logo"
                    />
                  </div>
                ))
              ) : (
                <div>
                  <p
                    className="text-[#676565] text-center"
                    onClick={handleFileClick}
                  >
                    + Choose File
                  </p>
                  <input
                    type="file"
                    className="hidden "
                    accept="image/*"
                    onChange={onImageChange}
                    ref={hiddenFileInput}
                  />
                </div>
              )}
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
