import scoutType from "./scoutTypes";

const initialState = {
  searching: false,
  searchApplication:{}
};
export default function scoutReducer(state = initialState, action) {
  switch (action.type) {
    case scoutType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case scoutType.SEARCH_APPLICATION:
      return {
        ...state,
        searchApplication: action.payLoad,
      };

    default:
      return state;
  }
}
