import React, { useMemo, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols2";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import { Pagination } from "../../../../components/pagination/pagination";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterFeedbackDropDown from "../../../../components/dropDown/filter/feedbackFilter";
import {
  getAllFeedback,
  searchFeedback,
} from "../../../../redux/feedback/feedbackActions";

export default function Feedback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { tab } = useParams();

  const { loadingFeedback, allFeedback, searching } = useSelector(
    (state) => state.feedback
  );

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [payLoad] = useState({
    pagination: 0,
  });

  useEffect(() => {
    navigate(`/admin/settings/feedback/1`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getAllFeedback(tab));
  }, [dispatch, tab]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((feedback, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          title: feedback.title,
          status: feedback.status,
          date: moment(feedback.created_at).format("MMM DD, YYYY"),
          feedbackData: feedback,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allFeedback?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allFeedback]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };
  const showSearch = (search) => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allFeedback?.data);
      setData(dataSet);
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchFeedback(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  function goToSinglePage(params) {
    let id = params?.feedbackData?.id;
    navigate(`/admin/settings/feedback/details/${id}`);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Status",
            accessor: "status",
          },
          {
            Header: "Date",
            accessor: "date",
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      <div>
        <TableFilter
          results={allFeedback?.per_page}
          totalResults={allFeedback?.total}
          onChange={handleChange}
          title={"Feedback"}
          payLoad={payLoad}
          page={tab}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <FilterFeedbackDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
              />
            </AllFiltersModal>
          }
          handleKeyDown={handleKeyDown}
          searchPlaceholder={"Search feedback"}
        ></TableFilter>

        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white rounded-md py-4 ">
          {loadingFeedback || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={goToSinglePage}
              rowClass="hover:shadow-md cursor-pointer"
            />
          ) : (
            <div className="text-center">
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settings" />
              </div>
              <p className="font-bold text-xl my-3">Settings</p>
              <p className="text-sm text-gray-500">
                You do not have any feedback yet
              </p>
            </div>
          )}
        </div>

        <Pagination data={allFeedback} route={"/admin/settings/feedback"} />
      </div>
    </>
  );
}
