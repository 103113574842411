import authType from "./userTypes";

const initialState = {
  isLoading: false,
  searching: false,
  filtering: false,
  downloading: false,
  user: {},
  allUsers: [],
  searchUser: [],
  singleUser: {},
  userRoles: {},
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.ALL_USERS:
      return {
        ...state,
        allUsers: action.payLoad?.data,
      };

    case authType.GET_USER:
      return {
        ...state,
        user: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_USERS:
      return {
        ...state,
        searchUser: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.FILTER_USERS:
      return {
        ...state,
        allUsers: action.payLoad?.data,
      };

    case authType.SINGLE_USER:
      return {
        ...state,
        singleUser: action.payLoad?.data,
      };

    case authType.SHOW_USER_ROLES:
      return {
        ...state,
        userRoles: action.payLoad?.data,
      };
    default:
      return state;
  }
}
