import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { filterShippers } from "../../../redux/shippers/shippersActions";
import { Beat } from "../../../plugins/spinners.plugin";

export default function FilterShippersDropDown({
  payLoad,
  page,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.shipper);
  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    base_fee: "",
    currency: "",
    city: "",
  });
  const { base_fee, currency, city } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      base_fee: "",
      currency: "",
      city: "",
    });
    await dispatch(filterShippers(payLoad, page));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (base_fee || currency || city) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterShippers({ ...data }));
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Base Fee"}
              htmlFor={"base_fee"}
              name={"base_fee"}
              autoComplete="off"
              onChange={handleChange}
              value={base_fee}
              placeholder={"Enter Base Fee"}
            />
            <Input.Label
              title={"Currency"}
              htmlFor={"currency"}
              name={"currency"}
              autoComplete="off"
              onChange={handleChange}
              value={currency}
              placeholder={"Enter Currency"}
            />

            <Input.Label
              title={"Location"}
              htmlFor={"city"}
              autoComplete="off"
              name={"city"}
              value={city}
              onChange={handleChange}
              placeholder={"Enter City"}
            />

            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
