import React from "react";
import Input from "../../../components/inputs/inputs";
import Button from "../../../components/buttons/buttons";

export default function InviteUser() {
  return (
    <div data-test="inviteUser-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md pt-8 pb-16 px-10 max-w-3xl mx-auto mt-24">
        <div className="border-b border-[#E4E4F3] py-3 text-center">
          <h3 className="text-lg font-medium">Invite New User</h3>
        </div>

        <div className="mt-6">
          <Input.Select
            title={"User Name"}
            type="text"
            name="address"
            id="address"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter user name"
          />

          <Input.Label
            title={"User Email"}
            type="text"
            name="address"
            id="address"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter user email address"
          />
        </div>
      </div>
      <div className="py-8 px-10 max-w-3xl mx-auto text-center mt-6">
        <Button.Primary title={"Send Invite"} className="px-4" />
      </div>
    </div>
  );
}
