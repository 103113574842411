import React, { useMemo, useEffect, useState, useLayoutEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Beat } from "../../../../plugins/spinners.plugin";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import { concatId, copyTextFromTable } from "../../../../components/helpers";
import { Pagination } from "../../../../components/pagination/pagination";
import EmptyState from "../../../../assets/images/emptyState/payment.svg";
import { ReactComponent as COPY } from "../../../../assets/images/icons/copy1.svg";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterPaymentDropDown from '../../../../components/dropDown/filter/filterPayment';
import {
  filterPayment,
  searchPayments,
  getAllPaymentsNoPagination,
} from "../../../../redux/payment/paymentActions";
import paymentType from "../../../../redux/payment/paymentTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function Success() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { page } = useParams();

  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [payLoad] = useState({
    status: "success",
  });

  const { downloading } = useSelector((state) => state.restaurant);
  const { filtering, allPayments, searching } = useSelector(
    (state) => state.payment
  );

  useEffect(() => {
    dispatch(filterPayment(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(myData) {
    let dataToMap = myData;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((payment, index) => {
        let show = {
          sn: index + 1,
          payment_id: (
            <span className="flex items-center">
              #{concatId(payment?.id)}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, payment?.id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          user_id: (
            <span className="flex items-center">
              #{concatId(payment?.user_id)}
              <button
                type="button"
                onClick={(e) => {
                  copyTextFromTable(e, payment?.user_id);
                }}
              >
                <COPY className="w-[14px] h-[14px] ml-1" />
              </button>
            </span>
          ),
          amount: <span>NGN {numberWithCommas(payment?.amount)}</span>,
          method: payment?.method ?? "Not available",
          reference: payment?.reference ?? "Not available",
          type: payment?.type,
          date: moment(payment?.created_at).format("MMM DD, YYYY"),
          restaurant: payment,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  function createTableDataDownload(myData) {
    let dataToMap = myData;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((restaurant, index) => {
        let show = {
          sn: index + 1,
          name: restaurant.restaurant_name,
          phone: restaurant.restaurant_phone,
          status: restaurant.management_approved ? "Approved" : "Unapproved",
          location: restaurant.restaurant_city,
          email: restaurant.restaurant_email,
        };
        outputArray.push(show);
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allPayments?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allPayments]);

  function goToSinglePage(params) {
    let id = params?.show?.restaurant?.id;
    navigate(`/admin/payments/${id}`);
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allPayments?.data);
      setData(dataSet);
      dispatch({
        type: paymentType["SEARCH_PAYMENT"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchPayments(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const handleDownload = async () => {
    const response = await dispatch(
      getAllPaymentsNoPagination({
        status: "success",
        pagination: 0,
      })
    );
    if (response) {
      const newData = createTableDataDownload(response?.data);
      writeFileWithXLSX(newData, {
        filename: "Success_payments.xlsx",
        download: true,
      });
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.sn",
          },
          {
            Header: "Payment ID",
            accessor: "show.payment_id",
          },
          // {
          //   Header: "User ID",
          //   accessor: "show.user_id",
          // },
          {
            Header: "Amount",
            accessor: "show.amount",
          },
          {
            Header: "Payment Method",
            accessor: "show.method",
          },
          {
            Header: "Reference",
            accessor: "show.reference",
          },
          {
            Header: "Payment Type",
            accessor: "show.type",
          },
          {
            Header: "Payment Date",
            accessor: "show.date",
          },
        ],
      },
    ],
    []
  );
  return (
    <div>
      <TableFilter
        title={"Payments"}
        results={allPayments?.to}
        totalResults={allPayments?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterPaymentDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Amount, payment id, user id..."}
      >
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          type="button"
          onClick={handleDownload}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="py-36 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Payments</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any success payment yet.
            </p>
          </div>
        )}
        <Pagination data={allPayments} route={"admin/payments/success"} />
      </div>
    </div>
  );
}
