import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { thirdPartyService } from "../../services/thirdParty.service";
import thirdPartyType from "./thirdPartyTypes";

/**
 * Get my Tokens
 **/

export const getMyThirdPartyToken = (page) => async (dispatch) => {
  try {
    dispatch({ type: thirdPartyType["LOADING"], payLoad: true });
    const response = await thirdPartyService.getMyThirdPartyToken(page);
    dispatch({ type: thirdPartyType["LOADING"], payLoad: false });
    dispatch({
      type: thirdPartyType["MY_THIRD_PARTY_TOKENS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: thirdPartyType["LOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all Users
 **/

export const createThirdPartyToken = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: thirdPartyType["CREATING"], payLoad: true });
    const response = await thirdPartyService.createThirdPartyToken(payLoad);
    dispatch({ type: thirdPartyType["CREATING"], payLoad: false });
    dispatch({
      type: thirdPartyType["CREATE_THIRD_PARTY_TOKENS"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: thirdPartyType["CREATING"], payLoad: false });
    errorHandler(error, true);
  }
};
