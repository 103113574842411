import axios from "../plugins/axios.plugin";

class ApplicationService {
  baseServicePath = "/api/application-setting";

  createApplicationSettings(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }
  updateApplicationSettings(payLoad) {
    return axios.post(`${this.baseServicePath}/update`, payLoad);
  }
  showApplicationSettings() {
    return axios.get(`${this.baseServicePath}/show`);
  }
  enhanceApplicationSettings(payLoad) {
    return axios.post(`${this.baseServicePath}/enhance`, payLoad);
  }
}
export const applicationService = new ApplicationService();
