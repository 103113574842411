import React, { useEffect } from "react";
import SinglePackage from "../../../../components/singleList/singlePackage";
import RestaurantLayout from "../../../../layouts/RestaurantLayout";
import { LeftColumn, RightColumn } from "../../../../layouts/RestaurantLayout";
import Details from "../../../../components/restaurant/restaurant-details";

export default function FoodPackage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <RestaurantLayout>
      <LeftColumn>
        <section aria-labelledby="section-1-title">
          <h2 className="sr-only" id="section-1-title">
            Restaurant Menu
          </h2>

          <div>
            {/* Your content */}
            <SinglePackage title={"Food Packages"} />
          </div>
        </section>
      </LeftColumn>
      <RightColumn>
        <Details />
      </RightColumn>
    </RestaurantLayout>
  );
}
