import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import OpeningHours from "./restaurant/OpeningHours";
import StoreOpeningHours from "./store/storeOperating";
import Token from "./token";
import HelpCenter from "./helpCenter";
import StorePrivacyPolicy from "./PrivacyPolicy";
import StoreAttribute from "./attributes/storeAttributes";
import { getOutletTypeFromCookies } from "../../../../utils/Auth";
import RestaurantAttribute from "./attributes/restaurantAttributes";
import StoreInformation from "./store/storeInfo";
import MyBusiness from "./business/businessInfo";
import RestaurantInformation from "./restaurant/restaurantInfo";
import BusinessFinancials from './business/businessFinancials';
import EmployeeRoles from './employeeRoles';

export default function StoreSettings() {
  const outlet_type = getOutletTypeFromCookies();
  const [type, setType] = useState("");
  const { active_tab } = useParams();

  useEffect(() => {
    setType(outlet_type);
  }, [outlet_type]);

  return (
    <div data-test="storeSettings-page">
      <div className="mt-4 ">
        {type === "store" && active_tab === "attributes" && <StoreAttribute />}
        {type === "restaurant" && active_tab === "attributes" && (
          <RestaurantAttribute />
        )}
        {active_tab === "token" && <Token />}
        {active_tab === "business_restaurant" && <MyBusiness />}
        {active_tab === "business_store" && <MyBusiness />}
        {type === "restaurant" && active_tab === "opening" && <OpeningHours />}
        {type === "store" && active_tab === "opening" && <StoreOpeningHours />}
        {type === "restaurant" && active_tab === "restaurant" && (
          <RestaurantInformation />
        )}
        {type === "store" && active_tab === "store" && <StoreInformation />}
        {active_tab === "help" && <HelpCenter />}
        {active_tab === "privacy" && <StorePrivacyPolicy />}
        {active_tab === "business-financials" && <BusinessFinancials />}
        {active_tab === "employee-roles" && <EmployeeRoles />}
      </div>
    </div>
  );
}
