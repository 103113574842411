import authType from "./publicFoodTypes";

const initialState = {
  isLoading: false,
  allFoods: {},
  singleFood: {},
};

export default function publicFoodReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_FOOD:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.ALL_FOODS:
      return {
        ...state,
        allFoods: action.payLoad?.data,
      };

    case authType.SINGLE_FOOD:
      return {
        ...state,
        singleFood: action.payLoad?.data,
      };

    default:
      return state;
  }
}
