import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import Warning from "../../../assets/images/icons/warning.svg";
import { filterRestaurantsAction } from "../../../redux/restaurant/restaurantActions";
import { getAllCategories } from "../../../redux/productCatergory/productCategoryAction";

export default function FilterRestaurantDropDown({
  isFilterOpen,
  setFilterState,
  payLoad,
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.food);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    restaurant_name: "",
    restaurant_phone: "",
    restaurant_email: "",
    restaurant_city: "",
    start_date: "",
    end_date: "",
  });
  const {
    restaurant_name,
    restaurant_email,
    restaurant_phone,
    restaurant_city,
    start_date,
    end_date,
  } = data;

  useEffect(() => {
    dispatch(getAllCategories());
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "restaurant_categories") {
      setData({ ...data, restaurant_categories: [value] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleReset = async () => {
    setData({
      restaurant_name: "",
      restaurant_phone: "",
      restaurant_email: "",
      restaurant_city: "",
      start_date: "",
      end_date: "",
    });
    await dispatch(filterRestaurantsAction(payLoad, 1));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (
      restaurant_name ||
      restaurant_email ||
      restaurant_phone ||
      restaurant_city ||
      start_date ||
      end_date
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterRestaurantsAction({
            ...data,
            ...payLoad,
          })
        );
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div data-test="">
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1">
          <Input.Label
            title={"Restaurant Name"}
            htmlFor={"restaurant_name"}
            name={"restaurant_name"}
            onChange={handleChange}
            value={restaurant_name}
            autoComplete="off"
            placeholder={"Enter Restaurant Name"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />
          <Input.Label
            title={"Phone Number"}
            type={"tel"}
            htmlFor={"restaurant_phone"}
            name={"restaurant_phone"}
            onChange={handleChange}
            value={restaurant_phone}
            autoComplete="off"
            placeholder={"Enter Phone Number"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />
          <Input.Label
            title={"Restaurant Email"}
            type={"email"}
            htmlFor={"restaurant_email"}
            name={"restaurant_email"}
            onChange={handleChange}
            value={restaurant_email}
            autoComplete="off"
            placeholder={"Enter Restaurant Email"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.Label
            title={"Location"}
            htmlFor={"restaurant_city"}
            name={"restaurant_city"}
            onChange={handleChange}
            value={restaurant_city}
            autoComplete="off"
            placeholder={"Enter City"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.DatePicker
            title={"Start Date"}
            name="start_date"
            id="start_date"
            htmlFor={"start_date"}
            defaultValue={start_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
          <Input.DatePicker
            title={"End Date"}
            name="end_date"
            id="end_date"
            htmlFor={"end_date"}
            defaultValue={end_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={isLoading}
            title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </div>
  );
}
