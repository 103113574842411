import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
// import Button from "../../buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import Location from "../../includes/location";
import {
  updateAnAddressAction,
  getMyAddressesAction,
} from "../../../redux/address/addressActions";
import { filterPublicServiceAreas } from "../../../redux/publicServiceAreas/publicServiceAreasActions";

export default function EditAddressform({ props, onClose }) {
  let addressData = props?.address;
  const dispatch = useDispatch();
  const { filter, filtering } = useSelector((state) => state.publicServiceArea);

  const [payLoad] = useState({ end_date: new Date(), pagination: 0 });
  const [isDefault, setIsDefault] = useState(false);
  const [updating, setUpdating] = useState(false);
  const [input, setInput] = useState({
    id: "",
    title: "",
    name: "",
    phone: "",
    address: "",
    country: "",
    state: "",
    city: "",
    address_selected: 0,
    service_area_id: "",
  });
  const { title, name, phone, address, country, state, city, service_area_id } =
    input;

  useEffect(() => {
    dispatch(filterPublicServiceAreas(payLoad));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setInput({
      id: addressData?.id,
      title: addressData?.title,
      name: addressData?.name,
      phone: addressData?.phone,
      address: addressData?.address,
      country: addressData?.country,
      state: addressData?.state,
      city: addressData?.city,
      address_selected: addressData?.address_selected,
      service_area_id: addressData?.service_area_id,
    });
    // eslint-disable-next-line
  }, [addressData]);

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = React.useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  const handleRegion = (e) => {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  useEffect(() => {
    if (city !== "") {
      dispatch(filterPublicServiceAreas({ city: city, pagination: 0 }));
    }
  }, [dispatch, city]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (title && phone && address && country && state && city) {
      try {
        setUpdating(true);
        const response = await dispatch(
          updateAnAddressAction({
            ...input,
          })
        );

        if (response.status === "success") {
          onClose();
          setUpdating(false);
          dispatch(getMyAddressesAction());
        }
      } catch (error) {
        console.log(error);
        setUpdating(false);
      }
    }
  };

  const handleDefault = async () => {
    try {
      setIsDefault(true);
      const response = await dispatch(
        updateAnAddressAction({ id: addressData?.id, address_selected: 1 })
      );
      if (response.status === "success") {
        onClose();
        setIsDefault(false);
        dispatch(getMyAddressesAction());
      } else {
        setIsDefault(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form>
      <div>
        <Input.Label
          title={"Title For Address"}
          type="text"
          name="title"
          id="title"
          value={title}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your address title e.g: Home, Office, etc."
          onChange={handleChange}
        />
      </div>
      <div className="mt-6">
        <Input.Label
          title={"Address Details (Door, Apartment Number)"}
          type="text"
          name="address"
          id="address"
          value={address}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your full address"
          onChange={handleChange}
        />
      </div>
      <div className="mt-6">
        <Input.Label
          title={"Recipient Name"}
          type="text"
          name="name"
          id="name"
          value={name}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter recipient name"
          onChange={handleChange}
        />
      </div>
      <div className="mt-6">
        <Input.Label
          title={"Phone Number"}
          type="number"
          name="phone"
          id="phone"
          value={phone}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your phone number"
          onChange={handleChange}
        />
      </div>

      <Location
        country_id={locationId.country_id}
        state_id={locationId.state_id}
        city_id={locationId.city_id}
        loadingLocation={(value) => {
          setLocationLoader(value);
        }}
        countryList={(value) => {
          setLocationList({
            ...locationList,
            countryList: value,
          });
        }}
        stateList={(value) => {
          setLocationList({
            ...locationList,
            stateList: value,
          });
        }}
        cityList={(value) => {
          setLocationList({ ...locationList, cityList: value });
        }}
      />

      <div className="mt-6">
        <Input.Select
          title={"Select Country"}
          name="country"
          id="country"
          defaultValue={country}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"country"}
          onChange={(e) => {
            setLocationId({
              ...locationId,
              country_id: fromJson(e.target.value).id,
            });
            handleRegion(e);
          }}
        >
          <option>{country}</option>
          {locationLoader ? (
            <option>Loading...</option>
          ) : (
            locationList.countryList &&
            locationList.countryList.length > 0 &&
            locationList.countryList.map((item, key) => {
              return (
                <option
                  key={key}
                  value={toJson({
                    name: item.name,
                    id: item.id,
                  })}
                >
                  {item.name}
                </option>
              );
            })
          )}
        </Input.Select>
      </div>
      <div className="mt-6">
        <Input.Select
          title={"Select State"}
          name="state"
          id="state"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"state"}
          defaultValue={state}
          onChange={(e) => {
            setLocationId({
              ...locationId,
              state_id: fromJson(e.target.value).id,
            });
            handleRegion(e);
          }}
        >
          <option>{state}</option>
          {locationLoader ? (
            <option>Loading...</option>
          ) : (
            locationList.stateList &&
            locationList.stateList.length > 0 &&
            locationList.stateList.map((item, key) => {
              return (
                <option
                  key={key}
                  value={toJson({
                    name: item.name,
                    id: item.id,
                  })}
                >
                  {item.name}
                </option>
              );
            })
          )}
        </Input.Select>
      </div>
      <div className="mt-6">
        <Input.Select
          title={"Select City"}
          name="city"
          id="city"
          defaultValue={city}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"city"}
          onChange={(e) => {
            setLocationId({
              ...locationId,
              city_id: fromJson(e.target.value).id,
            });
            handleRegion(e);
          }}
        >
          <option>{city}</option>
          {locationLoader ? (
            <option>Loading...</option>
          ) : (
            locationList.cityList &&
            locationList.cityList.length > 0 &&
            locationList.cityList.map((item, key) => {
              return (
                <option
                  key={key}
                  value={toJson({
                    name: item.name,
                    id: item.id,
                  })}
                >
                  {item.name}
                </option>
              );
            })
          )}
        </Input.Select>
      </div>
      <div className="mt-6">
        <Input.Select
          title={"Service Area"}
          name="service_area_id"
          id="service_area_id"
          value={service_area_id}
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"service_area_id"}
          onChange={handleChange}
        >
          <option>Select service area</option>
          {filtering ? (
            <option>Loading...</option>
          ) : (
            filter?.length > 0 &&
            filter?.map((item, i) => {
              return (
                <option key={i} value={item?.id}>
                  {item.area}
                </option>
              );
            })
          )}
        </Input.Select>
      </div>

      <button
        type="submit"
        className=" mt-5 w-full inline-flex justify-center h-14 rounded-md border border-primary shadow-sm px-4 py-4 bg-inherit text-base text-primary font-medium   sm:mt-6 sm:text-sm"
        onClick={handleDefault}
        disabled={updating || isDefault}
      >
        {isDefault ? <Beat color={"#CE2600"} /> : "Set As Default Address"}
      </button>
      <button
        type="button"
        onClick={handleSubmit}
        disabled={updating || isDefault}
        className="mt-5 w-full inline-flex justify-center py-4 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
      >
        {updating ? <Beat color={"#ffffff"} /> : "Update Address"}
      </button>
    </form>
  );
}
