import React from "react";
import MyMerchandisePackageOrders from "./orders";

export default function UserMerchandisePackageOrders() {
  return (
    <div className="store-page">
      <div>
        <MyMerchandisePackageOrders />
      </div>
    </div>
  );
}
