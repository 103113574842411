import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import TagRestaurant from "./tagRestaurant";
import TagStore from "./tagStore";

const tabs = [
  { name: "Restaurant", href: "restaurant" },
  { name: "Store", href: "store" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SettingsTag() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "restaurant";
  const { active_tab } = useParams();
  const { tab } = useParams();

  useEffect(() => {
    if (active_tab === "tags") {
      navigate(`/admin/settings/tags/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/settings/tags/${tab.href}/1`);
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="sm:block">
            <div>
              <nav
                className="-mb-px flex space-x-4 overflow-x-auto"
                aria-label="Tabs"
              >
                {tabs.map((singleTab) => (
                  <button
                    key={singleTab.name}
                    onClick={() => {
                      toggle(singleTab);
                    }}
                    className={classNames(
                      singleTab.href === tab
                        ? "border-primary text-primary font-bold"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-2 px-1 border-b-2 font-medium text-sm"
                    )}
                    aria-current={singleTab.href === tab ? "page" : undefined}
                  >
                    {singleTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        {tab === "restaurant" && <TagRestaurant />}
        {tab === "store" && <TagStore />}
      </div>
    </div>
  );
}
