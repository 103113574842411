import couponType from "./couponTypes";

const initialState = {
  allCoupons: {},
  filterNoPage: {},
  loading: false,
  deleting: false,
  filtering: false,
  searching: false,
  storeCoupon: {},
  singleCoupon: {},
  deleteCoupon: {},
  myCoupons: {},
  emptyCoupon: {},
  filterCoupons: [],
  search: [],
  reloadCoupon: false,
};
export default function CouponReducer(state = initialState, action) {
  switch (action.type) {
    case couponType.GET_ALL_COUPON:
      return {
        ...state,
        allCoupons: action.payLoad?.data,
      };
    case couponType.STORE_COUPON:
      return {
        ...state,
        storeCoupon: action.payLoad?.data?.data,
      };
    case couponType.DELETE_COUPON:
      return {
        ...state,
        deleteCoupon: action.payLoad.data.data,
      };

    case couponType.SHOW_COUPON:
      return {
        ...state,
        singleCoupon: action.payLoad?.data,
      };
    case couponType.SHOW_MY_COUPON:
      return {
        ...state,
        myCoupons: action.payLoad?.data,
      };
    case couponType.FILTER_COUPON:
      return {
        ...state,
        allCoupons: action.payLoad?.data,
      };
    case couponType.FILTER_COUPON_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payLoad?.data,
      };
    case couponType.SEARCH_COUPON:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case couponType.LOADING_COUPON:
      return {
        ...state,
        loading: action.payLoad,
      };
    case couponType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };
    case couponType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case couponType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    default:
      return state;
  }
}
