import axios from "../plugins/axios.plugin";

class ServiceAreaService {
  baseServicePath = "/api/service-area";

  getAllShippers() {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=1`);
  }
  filterServiceAreas(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  storeServiceAreas(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  searchShipper(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showAShipper(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  showMyShipper(payload) {
    return axios.get(`${this.baseServicePath}/me?properties=1`, payload);
  }
  editServiceArea(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  deleteServiceArea(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
}
export const serviceAreaService = new ServiceAreaService();
