import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import { filterEmployees } from "../../../redux/employee/employeeActions";

export default function FilterEmployee({
  payLoad,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { filtering } = useSelector((state) => state.employee);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    employee_name: "",
    status: "",
    flag: "",
    employee_phone: "",
    employee_job_title: "",
  });
  const { employee_name, employee_phone, status, flag, employee_job_title } =
    data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      employee_name: "",
      status: "",
      flag: "",
      employee_phone: "",
      employee_job_title: "",
    });
    try {
      const response = await dispatch(filterEmployees(payLoad, 1));
      if (response?.status === "success") {
        setFilterState(!isFilterOpen);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (
      employee_name ||
      employee_phone ||
      status ||
      flag ||
      employee_job_title
    ) {
      setErrorMsg(false);
      let newData = { ...data, ...payLoad };
      try {
        const response = await dispatch(filterEmployees(newData, 1));
        if (response) {
          setFilterState(!isFilterOpen);
        } else {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Employee Name"}
              htmlFor={"employee_name"}
              name={"employee_name"}
              onChange={handleChange}
              value={employee_name}
              autoComplete="off"
              placeholder={"Enter Employee Name"}
            />
            <Input.Label
              title={"Position"}
              htmlFor={"employee_job_title"}
              name={"employee_job_title"}
              onChange={handleChange}
              value={employee_job_title}
              autoComplete="off"
              placeholder={"Enter Position"}
            />
            <Input.Label
              title={"Phone Number"}
              htmlFor={"employee_phone"}
              name={"employee_phone"}
              autoComplete="off"
              onChange={handleChange}
              value={employee_phone}
              placeholder={"Enter Phone Number"}
            />

            <Input.Select
              title={"Status"}
              htmlFor={"status"}
              name={"status"}
              value={status}
              onChange={handleChange}
            >
              <option>Select Status</option>

              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input.Select>
            <Input.Label
              title={"Flag"}
              htmlFor={"flag"}
              name={"flag"}
              onChange={handleChange}
              value={flag}
              placeholder={"Enter a number"}
            />

            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                title={"Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
