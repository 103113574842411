import cartType from "./statisticsTypes";

const initialState = {
  isLoading: false,
  businessStats: {},
  userStats: {},
  generalStats: {},
  storeStats: {},
  restaurantStats: {},
};
export default function StatisticsReducer(state = initialState, action) {
  switch (action.type) {
    case cartType.LOADING_STATS:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case cartType.GET_BUSINESS_STATISTICS:
      return {
        ...state,
        businessStats: action.payLoad?.data,
      };

    case cartType.GET_USER_STATISTICS:
      return {
        ...state,
        userStats: action.payLoad?.data,
      };

    case cartType.GET_GENERAL_STATISTICS:
      return {
        ...state,
        generalStats: action.payLoad?.data,
      };

    case cartType.GET_STORE_STATISTICS:
      return {
        ...state,
        storeStats: action.payLoad?.data,
      };

    case cartType.GET_RESTAURANT_STATISTICS:
      return {
        ...state,
        restaurantStats: action.payLoad?.data,
      };

    default:
      return state;
  }
}
