import authType from "./subscriptionMerchTypes";

const initialState = {
  isLoading: false,
  searching: false,
  filtering: false,
  deleting: false,
  downloading: false,
  allSubscriptionMerchs: { data: [] },
  search: {},
  singleSubscriptionMerch: {},
};

export default function subscriptionMerchReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };

    case authType.ALL_MERCHANDISE_SUBSCRIPTIONS:
      return {
        ...state,
        allSubscriptionMerchs: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.LOADING_SUBSCRIPTION:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.SEARCH_MERCHANDISE_SUBSCRIPTIONS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case authType.ADD_SUBSCRIPTION_ITEM:
      const prevItems = state?.allSubscriptionmerchs;
      let newItems;
      if (prevItems?.data?.length > 0) {
        newItems = {
          ...state.allSubscriptionmerchs,
          data: [...state.allSubscriptionmerchs?.data, action.payLoad?.data],
        };
      } else {
        newItems = action.payLoad?.data;
      }
      return {
        ...state,
        allSubscriptionMerchs: newItems,
      };

    case authType.FILTER_MERCHANDISE_SUBSCRIPTIONS:
      return {
        ...state,
        allSubscriptionMerchs: action.payLoad?.data,
      };

    case authType.SINGLE_SUBSCRIPTION_MERCHANDISE:
      return {
        ...state,
        singleSubscriptionMerch: action.payLoad?.data,
      };

    case authType.DELETE_SUBSCRIPTION_MERCHANDISE:
      return {
        ...state,
        singleSubscriptionMerch: action.payLoad?.data,
      };

    case authType.APPROVE_SUBSCRIPTION_MERCH:
      let approved = {
        ...state.singleSubscriptionMerch,
        management_approved: true,
      };
      return {
        ...state,
        singleSubscriptionMerch: approved,
      };

    case authType.UNAPPROVE_SUBSCRIPTION_MERCH:
      let unapproved = {
        ...state.singleSubscriptionMerch,
        management_approved: false,
      };
      return {
        ...state,
        singleSubscriptionMerch: unapproved,
      };

    default:
      return state;
  }
}
