import axios from "../plugins/axios.plugin";

class PackageSubscriptionMerchandiseOrderService {
  baseServicePath = "/api/subscription-merchandise/order";

  getAllSubscriptionMerchandiseOrders() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAllSubscriptionMerchandiseOrdersNoPagination(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?properties=1`,
      payload
    );
  }

  getASubscriptionMerchandiseOrder(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
  getMySubscriptionMerchandiseOrder() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  createSubscriptionMerchandise(payLoad) {
    return axios.post(`${this.baseServicePath}/store`, payLoad);
  }

  searchSubscriptionMerchandiseOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/search/index`, payLoad);
  }

  filterSubscriptionMerchandiseOrder(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }

  closeSubscriptionMerchandiseOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payLoad);
  }

  shipSubscriptionMerchandiseOrder(payLoad) {
    return axios.post(`${this.baseServicePath}/ship`, payLoad);
  }
}

export const packageSubscriptionMerchandiseOrderService =
  new PackageSubscriptionMerchandiseOrderService();
