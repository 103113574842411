import React from "react";
import MyRestaurantOrders from "./orders";

export default function UserRestaurantOrders() {
  return (
    <div className="store-page">
      <div>
        <MyRestaurantOrders />
      </div>
    </div>
  );
}
