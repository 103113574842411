import authType from "./referralTypes";

const initialState = {
  isLoading: false,
  searching: false,
  filtering: false,
  downloading: false,
  search: [],
  myReferrals: {},
  allReferrals: {},
};

export default function referralReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.GET_MY_REFERRALS:
      return {
        ...state,
        myReferrals: action.payLoad?.data,
      };

    case authType.ALL_REFERRALS:
      return {
        ...state,
        allReferrals: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_REFERRALS:
      return {
        ...state,
        search: action.payLoad,
      };

    case authType.FILTERING_REFERRALS:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.FILTER_REFERRALS:
      return {
        ...state,
        allReferrals: action.payLoad?.data,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    default:
      return state;
  }
}
