import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { subscriptionMerchService } from "../../services/subscriptionMerch.service";
import subscriptionType from "./subscriptionMerchTypes";

/**
 * Get all packages
 **/
export const getAllSubscriptionMerchAction = () => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: true });
    const response = await subscriptionMerchService.getAllSubscriptionMerchs();
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: subscriptionType["ALL_MERCHANDISE_SUBSCRIPTIONS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all packages no pagination
 **/

export const getAllSubscriptionMerchNoPagination =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: subscriptionType["DOWNLOADING"], payLoad: true });
      const response =
        await subscriptionMerchService.getAllSubscriptionMerchNoPagination(
          payload
        );
      dispatch({ type: subscriptionType["DOWNLOADING"], payLoad: false });
      return response;
    } catch (error) {
      dispatch({ type: subscriptionType["DOWNLOADING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Get all packages
 **/
export const getASubscriptionMerchAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: true });
    const response = await subscriptionMerchService.getASubscriptionMerch(id);
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: subscriptionType["SINGLE_SUBSCRIPTION_MERCHANDISE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Store subscription food
 **/
export const createSubscriptionMerchAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["LOADING_SUBSCRIPTION"], payLoad: true });
    const response = await subscriptionMerchService.createSubscriptionMerch(
      payload
    );
    dispatch({
      type: subscriptionType["LOADING_SUBSCRIPTION"],
      payLoad: false,
    });
    dispatch({
      type: subscriptionType["ADD_SUBSCRIPTION_ITEM"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: subscriptionType["LOADING_SUBSCRIPTION"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Search Subscription
 **/

export const searchSubscriptionMerchAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["SEARCHING"], payLoad: true });
    const response = await subscriptionMerchService.searchSubscriptionMerch(
      params
    );
    dispatch({ type: subscriptionType["SEARCHING"], payLoad: false });
    dispatch({
      type: subscriptionType["SEARCH_MERCHANDISE_SUBSCRIPTIONS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["SEARCHING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter Subscription
 **/

export const filterSubscriptionMerchAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: subscriptionType["FILTERING"], payLoad: true });
      const response = await subscriptionMerchService.filterSubscriptionMerch(
        payload,
        page
      );
      dispatch({ type: subscriptionType["FILTERING"], payLoad: false });
      dispatch({
        type: subscriptionType["FILTER_MERCHANDISE_SUBSCRIPTIONS"],
        payLoad: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: subscriptionType["FILTERING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Filter Subscription
 **/

export const deleteSubscriptionMerchAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["DELETING"], payLoad: true });
    const response = await subscriptionMerchService.deleteSubscriptionMerch(id);
    dispatch({ type: subscriptionType["DELETING"], payLoad: false });
    dispatch({
      type: subscriptionType["DELETE_SUBSCRIPTION_MERCHANDISE"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["DELETING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve Merch by mgt
 **/

export const approveSubscriptionMerch = (payload) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["APPROVING_MERCH"], payLoad: true });
    const response = await subscriptionMerchService.approveSubscriptionMerch(
      payload
    );
    dispatch({ type: subscriptionType["APPROVING_MERCH"], payLoad: false });
    dispatch({
      type: subscriptionType["APPROVE_SUBSCRIPTION_MERCH"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["APPROVING_MERCH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove Merch by mgt
 **/

export const unapproveSubscriptionMerch = (payload) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["APPROVING_MERCH"], payLoad: true });
    const response = await subscriptionMerchService.unapproveSubscriptionMerch(
      payload
    );
    dispatch({ type: subscriptionType["APPROVING_MERCH"], payLoad: false });
    dispatch({
      type: subscriptionType["UNAPPROVE_SUBSCRIPTION_MERCH"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["APPROVING_MERCH"], payLoad: false });
    errorHandler(error, true);
  }
};
