import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { businessSettingsService } from "../../services/businessSettings.service";
import businessType from "./businessSettingsTypes";

/**
 * Get my Business
 **/

export const getMyBusinessSettings = () => async (dispatch) => {
  try {
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: true,
    });
    const response = await businessSettingsService.getMyBusinessSettings();
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    dispatch({ type: businessType["MY_BUSINESS_SETTINGS"], payLoad: response });
  } catch (error) {
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const getABusiness = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: true,
    });
    const response = await businessSettingsService.getABusiness(payLoad);
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    dispatch({ type: businessType["A_BUSINESS"], payLoad: response });
  } catch (error) {
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const editBusinessSettings = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: businessType["UPDATING_BUSINESS_SETTINGS"],
      payLoad: true,
    });
    const response = await businessSettingsService.editMyBusinessSettings(
      payLoad
    );
    dispatch({
      type: businessType["UPDATING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    dispatch({ type: businessType["EDIT_BUSINESS"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: businessType["UPDATING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const getAllBusinesses = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: true,
    });
    const response = await businessSettingsService.getAllBusinesses(payLoad);

    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    dispatch({
      type: businessType["GET_ALL_BUSINESSES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: businessType["LOADING_BUSINESS_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const filterBusinessAction = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: businessType["FILTERING"], payLoad: true });
    const response = await businessSettingsService.filterBusiness(
      payLoad,
      page
    );
    dispatch({ type: businessType["FILTERING"], payLoad: false });
    dispatch({ type: businessType["FILTER_BUSINESS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: businessType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const filterBusinessActionNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["FILTERING"], payLoad: true });
    const response = await businessSettingsService.filterBusinessNoPage(
      payLoad
    );
    dispatch({ type: businessType["FILTERING"], payLoad: false });
    dispatch({
      type: businessType["FILTER_BUSINESS_NOPAGE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: businessType["FILTERING"], payLoad: false });
  }
};
