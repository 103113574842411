import axios from "../plugins/axios.plugin";

class TimezoneService {
  baseServicePath = "/api/time-zone";

  getAllTimeZone(category) {
    return axios.get(
      `${this.baseServicePath}/earth/index?category=${category}&properties=1`
    );
  }
}

export const timezoneService = new TimezoneService();
