// Application types

const THIRDPARTY = {
  LOADING: "LOADING",
  CREATING: "CREATING",
  MY_THIRD_PARTY_TOKENS: "MY_THIRD_PARTY_TOKENS",
  CREATE_THIRD_PARTY_TOKENS: "CREATE_THIRD_PARTY_TOKENS",
};

export default THIRDPARTY;
