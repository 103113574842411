import React, { useState, useMemo, useLayoutEffect, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Table from "../../../components/tables/tableCols1";
import TableSkeleton from "../../../components/skeleton/table";
import { PaginationNoRoute } from "../../../components/pagination/paginationNoRoute";
import EmptyState from "../../../assets/images/icons/ShippersEmpty.svg";
import UpdateServiceArea from "../../../components/modals/admin/shippers/updateServiceArea";
import { CreateServiceArea } from "../../../components/modals/admin/shippers/createServiceArea";
import { filterServiceAreas } from "../../../redux/serviceAreas/serviceAreasActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function ServiceAreaCharges({ shipper }) {
  const dispatch = useDispatch();
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const updateAreaRef = React.useRef(null);

  const { allServiceAreas, filtering } = useSelector(
    (state) => state.serviceArea
  );
  

  useEffect(() => {
    if (shipper?.id) {
      dispatch(filterServiceAreas({ shipper_id: shipper?.id }, page));
    }
    // eslint-disable-next-line
  }, [shipper?.id, page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((order) => {
        let show = {
          title: order?.area,
          charge: <span>NGN {numberWithCommas(order?.charge)}</span>,
          longitude: order?.longitude ?? "Not available",
          latitude: order?.latitude ?? "Not available",
          date: moment(order?.created_at).format("MMM DD, YYYY"),
          orderData: order,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(allServiceAreas?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allServiceAreas]);

  const openUpdateModal = (params) => {
    let data = params?.show?.orderData;
    updateAreaRef.current.open();
    updateAreaRef.current.getServiceArea(data);
  };

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "Title",
            accessor: "show.title",
          },
          {
            Header: "Charge",
            accessor: "show.charge",
          },
          {
            Header: "Longitude",
            accessor: "show.longitude",
          },
          {
            Header: "Latitude",
            accessor: "show.latitude",
          },
          {
            Header: "Date Created",
            accessor: "show.date",
          },
        ],
      },
    ],
    []
  );
  return (
    <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
      <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
        <h3 className="text-lg font-medium">Service Area Charge</h3>
        <CreateServiceArea id={shipper?.id} />
      </div>
      <div className="bg-white rounded-md py-4 ">
        {filtering ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <>
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              onClick={openUpdateModal}
              rowClass="hover:shadow-md cursor-pointer"
            />
            <PaginationNoRoute
              data={allServiceAreas}
              page={page}
              setPage={setPage}
            />
          </>
        ) : (
          <div className="py-20 text-center">
            <div className="flex justify-center">
              <img
                src={EmptyState}
                className="w-auto h-[160px]"
                alt="no data"
              />
            </div>
            <h3 className="mt-8 text-xl font-bold">Set Charge</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You have to set your Service area charge to complete this setup.
            </p>
          </div>
        )}
      </div>
      <UpdateServiceArea ref={updateAreaRef} visibility={false} />
    </div>
  );
}
