import axios from "../plugins/axios.plugin";

class AddressService {
  baseServicePath = "/api/address";

  getMyAddresses() {
    return axios.get(`${this.baseServicePath}/me`);
  }

  setNewAddress(params) {
    return axios.post(`${this.baseServicePath}/store`, params);
  }

  deleteAnAddress(params) {
    return axios.post(`${this.baseServicePath}/delete`, params);
  }

  updateAnAddress(params) {
    return axios.post(`${this.baseServicePath}/update`, params);
  }

  filterAddresses(params) {
    return axios.post(`${this.baseServicePath}/filter/index`, params);
  }
}
export const addressService = new AddressService();
