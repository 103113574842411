import React, { useState, Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import Button from "../../buttons/buttons";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import {
  getAllRolesAction,
  assignARoleAction,
  retractARoleAction,
} from "../../../redux/roles/rolesActions";
import { getSingleEmployee } from "../../../redux/employee/employeeActions";

const tabs = [
  { name: "Assign", id: "assign" },
  { name: "Unassign", id: "unassign" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export function ManageEmployeeRolesModal(props) {
  const { user, employee_id } = props;
  const dispatch = useDispatch();

  const [business, setBusiness] = useState();
  const [active_tab, setActiveTab] = useState("assign");
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    user_id: user?.id,
    role_id: "",
    team_name: "",
  });
  const { user_id, role_id } = input;
  const { allRoles, assigning } = useSelector((state) => state.roles);

  useEffect(() => {
    if (user?.groups) {
      setBusiness(Object.keys(user?.groups)[0]);
    }
    // eslint-disable-next-line
  }, [user?.groups]);

  useEffect(() => {
    dispatch(getAllRolesAction());
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleAssign = async () => {
    setSubmitted(true);
    if (user_id && role_id) {
      try {
        const response = await dispatch(
          assignARoleAction({
            ...input,
            user_id: user?.id,
            team_name: business,
            team_id: business,
          })
        );

        if (response === true) {
          dispatch(getSingleEmployee(employee_id));
          props.onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleUnassign = async () => {
    setSubmitted(true);
    if (user_id && role_id) {
      try {
        const response = await dispatch(
          retractARoleAction({
            ...input,
            user_id: user?.id,
            team_name: business,
            team_id: business,
          })
        );
        if (response === true) {
          dispatch(getSingleEmployee(employee_id));
          props.onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Transition appear show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[415px] sm:w-[500px] bg-white rounded-3xl py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div>
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3  "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="sm:flex justify-center">
                      <div className="text-center sm:text-center mt-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-bold text-black"
                        >
                          Manage Roles
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  <div className=" border-b-2 mt-4"></div>
                  <div className="sm:px-8 p-4">
                    <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                      {tabs.map((tab) => (
                        <button
                          key={tab.id}
                          onClick={() => {
                            setActiveTab(tab.id);
                          }}
                          className={classNames(
                            tab.id === active_tab
                              ? "border-primary text-primary font-bold"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "whitespace-nowrap pt-4 pb-2 px-1 border-b-2 font-medium text-sm"
                          )}
                          aria-current={
                            tab.id === active_tab ? "page" : undefined
                          }
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                  </div>

                  <div className="mt-4 sm:mt-5 sm:px-8 p-4">
                    {active_tab === "assign" && (
                      <form>
                        <Input.Select
                          title={"Roles"}
                          name="role_id"
                          id="role_id"
                          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                          placeholder="Enter role name"
                          onChange={handleChange}
                        >
                          <option>Select Role</option>
                          {allRoles?.data?.length > 0 &&
                            allRoles?.data.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                        </Input.Select>
                        {submitted && !role_id && (
                          <div className="text-xs text-red-500">
                            Role is required
                          </div>
                        )}

                        <div className="flex gap-10 mt-8">
                          <Button.Secondary
                            title={"Cancel"}
                            className="w-full"
                            onClick={props.onClose}
                          />
                          <Button.Primary
                            title={
                              assigning ? <Beat color={"#ffffff"} /> : "Proceed"
                            }
                            disabled={assigning}
                            type={"button"}
                            className="w-full"
                            onClick={handleAssign}
                          />
                        </div>
                      </form>
                    )}
                    {active_tab === "unassign" && (
                      <form>
                        <Input.Select
                          title={"Roles"}
                          name="role_id"
                          id="role_id"
                          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                          placeholder="Enter role name"
                          onChange={handleChange}
                        >
                          <option>Select Role</option>
                          {user?.roles?.length > 0 &&
                            user?.roles.map((role) => (
                              <option key={role.id} value={role.id}>
                                {role.name}
                              </option>
                            ))}
                        </Input.Select>
                        {submitted && !role_id && (
                          <div className="text-xs text-red-500">
                            Role is required
                          </div>
                        )}

                        <div className="flex gap-10 mt-8">
                          <Button.Secondary
                            title={"Cancel"}
                            className="w-full"
                            onClick={props.onClose}
                          />
                          <Button.Primary
                            title={
                              assigning ? <Beat color={"#ffffff"} /> : "Proceed"
                            }
                            disabled={assigning}
                            type={"button"}
                            className="w-full"
                            onClick={handleUnassign}
                          />
                        </div>
                      </form>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
