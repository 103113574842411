const CARTS = {
  GET_ALL_CARTS: "GET_ALL_CARTS",
  FILTER_CARTS: "FILTER_CARTS",
  FILTERING: "FILTERING",
  SEARCH_CARTS: "SEARCH_CARTS",
  STORE_CART: "STORE_CART",
  SHOW_CART: "SHOW_CART",
  SHOW_MY_CART: "SHOW_MY_CART",
  EDIT_CART: "EDIT_CART",
  EMPTY_CART: "EMPTY_CART",
  DELETE_CART: "DELETE_CART",
  LOADING_CART: "LOADING_CART",
  PROCESSING: "PROCESSING",
  LOADING_SEARCH: "LOADING_SEARCH",
  CHECKOUT_CART: "CHECKOUT_CART",
  RELOADING_CART: "RELOADING_CART",
  CART_PRODUCTS: "CART_PRODUCTS",
  UPDATING_COUPON: "UPDATING_COUPON",
  STORING_PUBLIC_CART: "STORING_PUBLIC_CART",
  SHOW_MY_CART_NO_PROPERTIES: "SHOW_MY_CART_NO_PROPERTIES",
};

export default CARTS;
