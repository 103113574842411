import axios from "../plugins/axios.plugin";

class PublicCatalogueService {
  baseServicePath = "/api/public/catalogue";

  getAllPublicCatalogues() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getAPublicCatalogue(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
}

export const publicCatalogueService = new PublicCatalogueService();
