import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import { filterHumanVerificationAction } from "../../../redux/humanVerification/humanVerificationActions";

// Define initial state outside of component
const initialFormData = {
  user_id: "",
  document_type: "",
  document_number: "",
  verified_by: "",
  verified_at_start_date: "",
  verified_at_end_date: "",
  start_date: "",
  end_date: "",
};

export default function KYCFilterDropDown({
  page,
  payLoad,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { loadingFeedback, filtering } = useSelector((state) => state.feedback);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState(initialFormData);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData(initialFormData);
    await dispatch(filterHumanVerificationAction(payLoad, page));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (Object.values(data).some((value) => value !== "")) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterHumanVerificationAction({ ...data })
        );
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"User ID"}
              htmlFor={"user_id"}
              name={"user_id"}
              onChange={handleChange}
              value={data?.user_id}
              placeholder={"Enter user id"}
            />
            <Input.Select
              title={"Document Type"}
              name="document_type"
              id="document_type"
              className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
              htmlFor="document_type"
              value={data?.document_type}
              onChange={handleChange}
            >
              <option>Select card type</option>
              <option>Drivers License</option>
              <option>International Passport</option>
              <option>NIN</option>
            </Input.Select>
            <Input.Label
              title={"Document Number"}
              htmlFor={"document_number"}
              name={"document_number"}
              onChange={handleChange}
              value={data?.document_number}
              placeholder={"Select document number"}
            />
            <Input.Label
              title={"Verified By (ID)"}
              htmlFor={"verified_by"}
              name={"verified_by"}
              onChange={handleChange}
              value={data?.verified_by}
              placeholder={"Verified by"}
            />
            <Input.DatePicker
              title={"Verified At (Start Date)"}
              name="verified_at_start_date"
              id="verified_at_start_date"
              htmlFor={"verified_at_start_date"}
              defaultValue={data?.verified_at_start_date}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />
            <Input.DatePicker
              title={"Verified At (End Date)"}
              name="verified_at_end_date"
              id="verified_at_end_date"
              htmlFor={"verified_at_end_date"}
              defaultValue={data?.verified_at_end_date}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />
            <Input.DatePicker
              title={"Start Date"}
              name="start_date"
              id="start_date"
              htmlFor={"start_date"}
              defaultValue={data?.start_date}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />
            <Input.DatePicker
              title={"End Date"}
              name="end_date"
              id="end_date"
              htmlFor={"end_date"}
              defaultValue={data?.end_date}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />

            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={loadingFeedback}
                title={loadingFeedback ? <Beat color={"#ffffff"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
            {errorMsg && (
              <div className="flex gap-1 -mt-2">
                <img src={Warning} alt="warning" />
                <p>No value to filter by</p>
              </div>
            )}
          </div>
        </form>
      </div>
    </>
  );
}
