import employeeType from "./employeeTypes";

const initialState = {
  isLoading: false,
  searching: false,
  allEmployees: {},
  search: [],
  filtering: false,
  singleEmployee: {},
  approveEmployee: {},
  unApproveEmployee: {},
  filterEmployees: [],
  filterNoPage: {},
  employeeLocation: {},
  myEmployee: {},
  testModel: {},
};

export default function employeesReducer(state = initialState, action) {
  switch (action.type) {
    case employeeType.LOADING_EMPLOYEES:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case employeeType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case employeeType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case employeeType.SEARCH_EMPLOYEES:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case employeeType.GET_ALL_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payLoad?.data,
      };
    case employeeType.DELETE_EMPLOYEE:
      return {
        ...state,
        deleteEmployee: action.payload?.data?.data,
      };
    case employeeType.EDIT_EMPLOYEE:
      const editEmployee = state.allEmployees.findIndex(
        (item) => item.id === action.payLoad.id
      );
      const allEmployees = [...state.allEmployees];
      allEmployees[editEmployee] = action.payLoad;
      return {
        ...state,
        allEmployees: allEmployees,
      };
    case employeeType.FILTER_EMPLOYEES:
      return {
        ...state,
        allEmployees: action.payLoad?.data,
      };
    case employeeType.FILTER_EMPLOYEES_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payload?.data,
      };
    case employeeType.GET_MY_EMPLOYEE:
      return {
        ...state,
        myEmployee: action.payload?.data?.data,
      };
    case employeeType.TERMINATE_EMPLOYEE:
      const terminateEmployee = state.terminateEmployee.filter(
        (item) => item.id !== action.payLoad.id
      );

      return {
        ...state,
        allEmployees: terminateEmployee,
      };
    case employeeType.TEST_EMPLOYEE_MODEL:
      return {
        ...state,
        testModel: action.payload?.data?.data,
      };
    case employeeType.SHOW_MY_EMPLOYEE_LOCATION:
      return {
        ...state,
        employeeLocation: action.payload?.data?.data,
      };
    case employeeType.SHOW_AN_EMPLOYEE:
      return {
        ...state,
        singleEmployee: action.payLoad?.data,
      };

    case employeeType.APPROVE_EMPLOYEE:
      return {
        ...state,
        approveEmployee: action.payLoad?.data?.data,
      };
    case employeeType.UNAPPROVE_EMPLOYEE:
      return {
        ...state,
        unApproveEmployee: action.payLoad?.data?.data,
      };
    case employeeType.SET_EMPLOYEE_ACTIVE:
      return {
        ...state,
        setEmployeeActive: action.payLoad?.data?.data,
      };
    case employeeType.SET_EMPLOYEE_INACTIVE:
      return {
        ...state,
        setEmployeeInactive: action.payLoad?.data?.data,
      };
    default:
      return state;
  }
}
