import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import Warning from "../../../assets/images/icons/warning.svg";
import { cleanInput } from "../../../helpers/cleanInput";
import { filterPackageAction } from "../../../redux/packages/packagesActions";
import { filterProductCategory } from "../../../redux/productCatergory/productCategoryAction";

export default function FilterMerchandisePackageDropDown(props) {
  const { payLoad, isFilterOpen, setFilterState } = props;
  const dispatch = useDispatch();
  const { page } = useParams();
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { isLoading, filtering } = useSelector((state) => state.packages);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    package_name: "",
    package_average_amount: "",
    package_categories: [],
    start_date: "",
    end_date: "",
  });
  const {
    package_name,
    package_average_amount,
    package_categories,
    start_date,
    end_date,
  } = data;

  useEffect(() => {
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "package_categories") {
      setData({ ...data, package_categories: [value] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleReset = async () => {
    setData({
      package_name: "",
      package_average_amount: "",
      package_categories: [],
      start_date: "",
      end_date: "",
    });
    await dispatch(filterPackageAction(payLoad, 1));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (
      package_name ||
      package_average_amount ||
      package_categories.length > 0 ||
      start_date ||
      end_date
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterPackageAction(data, page));
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div data-test="">
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1">
          <Input.Label
            title={"Package Name"}
            type="text"
            name="package_name"
            id="package_name"
            value={package_name}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter package name"
            onChange={handleChange}
          />

          <Input.Select
            title={"Package Category"}
            name="package_categories"
            id="package_categories"
            defaultValue={package_categories?.[0]}
            htmlFor={"package_categories"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="E.g: 200"
            onChange={handleChange}
          >
            <option>Select Category</option>
            {allCategories?.data?.length > 0 &&
              allCategories?.data?.map(
                (category, i) =>
                  category.type === "store" && (
                    <option key={i} value={category?.name}>
                      {category?.name}
                    </option>
                  )
              )}
          </Input.Select>

          <Input.Number
            title="Package Amount"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter package amount"
            name="package_average_amount"
            id="package_average_amount"
            defaultValue={package_average_amount}
            htmlFor="package_average_amount"
            onChange={handleChange}
          />

          <Input.DatePicker
            title={"Start Date"}
            name="start_date"
            id="start_date"
            htmlFor={"start_date"}
            defaultValue={start_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
          <Input.DatePicker
            title={"End Date"}
            name="end_date"
            id="end_date"
            htmlFor={"end_date"}
            defaultValue={end_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={isLoading}
            title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </div>
  );
}
