import shipperType from "./shippersTypes";

const initialState = {
  allShippers: {},
  loading: false,
  creating: false,
  deleting: false,
  updating: false,
  searching: false,
  filtering: false,
  approving: false,
  storeShipper: {},
  shipper: {},
  deleteShipper: {},
  myShippers: {},
  emptyShipper: {},
  filterNoPage: [],
  search: [],
  reloadShipper: false,
};
export default function ShippersReducer(state = initialState, action) {
  switch (action.type) {
    case shipperType.GET_ALL_SHIPPERS:
      return {
        ...state,
        allShippers: action.payLoad?.data,
      };
    case shipperType.STORE_SHIPPERS:
      return {
        ...state,
        storeShipper: action.payLoad?.data?.data,
      };
    case shipperType.DELETE_SHIPPERS:
      return {
        ...state,
        deleteShipper: action.payLoad.data.data,
      };

    case shipperType.SHOW_SHIPPERS:
      return {
        ...state,
        shipper: action.payLoad?.data,
      };
    case shipperType.SHOW_MY_SHIPPERS:
      return {
        ...state,
        myShippers: action.payLoad?.data,
      };
    case shipperType.FILTER_SHIPPERS:
      return {
        ...state,
        allShippers: action.payLoad?.data,
      };
    case shipperType.SEARCH_SHIPPERS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case shipperType.LOADING_SHIPPERS:
      return {
        ...state,
        loading: action.payLoad,
      };
    case shipperType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case shipperType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };
    case shipperType.CREATING:
      return {
        ...state,
        creating: action.payLoad,
      };
    case shipperType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };
    case shipperType.FILTER_SHIPPERS_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payLoad?.data,
      };
    case shipperType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };

    case shipperType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case shipperType.APPROVE_SHIPPER:
      let approved = {
        ...state.shipper,
        management_approved: true,
      };
      return {
        ...state,
        shipper: approved,
      };

    case shipperType.UNAPPROVE_SHIPPER:
      let unapproved = {
        ...state.shipper,
        management_approved: false,
      };
      return {
        ...state,
        shipper: unapproved,
      };
    default:
      return state;
  }
}
