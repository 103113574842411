import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { humanVerificationService } from "../../services/humanVerifiation.service";
import actionType from "./humanVerificationTypes";

export const editHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationService.editHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const storeHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationService.storeHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const filterHumanVerificationAction =
  (payLoad, page) => async (dispatch) => {
    try {
      dispatch({ type: actionType["FILTERING"], payLoad: true });
      const response = await humanVerificationService.filterHumanVerification(
        payLoad,
        page
      );
      dispatch({ type: actionType["FILTERING"], payLoad: false });
      dispatch({
        type: actionType["FILTER_VERIFICATION"],
        payLoad: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: actionType["FILTERING"], payLoad: false });
    }
  };

export const searchHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["SEARCHING"], payLoad: true });
    const response = await humanVerificationService.searchHumanVerification({
      ...payLoad,
    });
    dispatch({ type: actionType["SEARCHING"], payLoad: false });
    dispatch({
      type: actionType["SEARCH_VERIFICATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: actionType["SEARCHING"], payLoad: false });
  }
};

export const showAHumanVerification = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["LOADING"], payLoad: true });
    const response = await humanVerificationService.showAHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["LOADING"], payLoad: false });
    dispatch({
      type: actionType["SHOW_A_VERIFICATION"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: actionType["LOADING"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const showMyHumanVerification = () => async (dispatch) => {
  try {
    dispatch({ type: actionType["LOADING"], payLoad: true });
    const response = await humanVerificationService.showMyHumanVerification();
    dispatch({ type: actionType["LOADING"], payLoad: false });
    dispatch({
      type: actionType["SHOW_MY_VERIFICATION"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: actionType["LOADING"], payLoad: false });
    if (error?.response?.status !== 404) {
      errorHandler(error, true);
      throw new Error("");
    }
  }
};

export const verifyHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationService.verifyHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const rejectHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationService.rejectHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};
