import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import storeOrderType from "./storeOrderTypes";
import { storeOrderService } from "../../services/storeOrder.service";

/**
 * CREATE STORE
 * 
 * @param {object} payLoad {
  StoreOrder_email:"bluehills@gmail.com",
  StoreOrder_name:"blue hills",
  StoreOrder_phone:"07034738829",
  StoreOrder_address:"12 victoria island lane",
  StoreOrder_city:"lagos",
  StoreOrder_state:"Lagos",
  StoreOrder_country:"Nigeria",
  }
 * @returns {void}
 */
export const createAStoreOrder = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: storeOrderType["CREATING"],
      payLoad: true,
    });
    const response = await storeOrderService.createAStoreOrder({
      ...payLoad,
    });
    dispatch({ type: storeOrderType["CREATING"], payLoad: false });
    dispatch({ type: storeOrderType["CREATE_STORE_ORDER"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["CREATING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Update Store Order
 * 
 */
export const updateAStoreOrder = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: storeOrderType["UPDATING"],
      payLoad: true,
    });
    const response = await storeOrderService.updateAStoreOrder({
      ...payLoad,
    });
    dispatch({ type: storeOrderType["UPDATING"], payLoad: false });
    // dispatch({ type: storeOrderType["CREATE_STORE_ORDER"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve a StoreOrder
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const markAsClosed = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: true });
    const response = await storeOrderService.markAsClosed(payLoad);
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({
      type: storeOrderType["MARK_STORE_ORDER_CLOSED"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * unApprove a StoreOrder
 * @param {object} payLoad {id:"67ef618e-fc15-44bd-9795-5505bd925ee1"}
 * @returns {void}
 */
export const markAsDelivered = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: true });
    const response = await storeOrderService.markAsDelivered({
      id: payLoad,
    });
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({
      type: storeOrderType["MARK_STORE_ORDER_CLOSED"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all STORES
 * @param {object} payLoad { properties:"1", approved:"1", active:"1"}
 * @returns {void}
 */

export const getAllStoreOrders = (page) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: true });
    const response = await storeOrderService.getAllStoreOrders(page);
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({
      type: storeOrderType["GET_ALL_STORE_ORDERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    errorHandler(error, true);
  }
};

export const getAllStoreOrdersNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["DOWNLOADING"], payLoad: true });
    const response = await storeOrderService.getAllStoreOrdersNoPage(payLoad);
    dispatch({ type: storeOrderType["DOWNLOADING"], payLoad: false });
    dispatch({
      type: storeOrderType["GET_ALL_STORE_ORDERS_NOPAGE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: storeOrderType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Filter STORES
 * 
 * @param {object} payLoad {
  StoreOrder_email:"bluehills@gmail.com",
  StoreOrder_name:"blue hills",
  StoreOrder_phone:"07034738829",
  StoreOrder_address:"12 victoria island lane",
  StoreOrder_city:"lagos",
  StoreOrder_state:"Lagos",
  StoreOrder_country:"Nigeria",
  }
 * @returns {void}
 */
export const filterStoreOrders = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["FILTERING"], payLoad: true });
    const response = await storeOrderService.filterStoreOrders(payLoad, page);
    dispatch({ type: storeOrderType["FILTERING"], payLoad: false });
    dispatch({ type: storeOrderType["FILTER_STORE_ORDER"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search all STORES
 *
 * @param {object} payLoad {search: "christpillar21@gmail.com"}
 */

export const searchAllStoreOrders = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["LOADING_SEARCH"], payLoad: true });
    const response = await storeOrderService.searchStoreOrder({ ...payLoad });
    dispatch({ type: storeOrderType["LOADING_SEARCH"], payLoad: false });
    dispatch({
      type: storeOrderType["SEARCH_STORE_ORDERS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Show StoreOrder
 * @param {object} payLoad {id:"eb2483aa-2c6a-407d-92af-238778668605"}
 * @returns
 */
export const getSingleStoreOrder = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: true });
    const response = await storeOrderService.showAStoreOrder(payLoad);
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({ type: storeOrderType["SHOW_A_STORE_ORDER"], payLoad: response });
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Show StoreOrder
 * @param {object}
 * @returns
 */
export const getMyStoreOrders = () => async (dispatch) => {
  try {
    dispatch({
      type: storeOrderType["LOADING_STORE_ORDER"],
      payLoad: true,
    });
    const response = await storeOrderService.showMyStoreOrders();
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({
      type: storeOrderType["GET_MY_STORE_ORDERS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    errorHandler(error, true);
  }
};
/**
 * Edit StoreOrder
 * @param {object} payLoad {
     id:"eb2483aa-2c6a-407d-92af-238778668605"
    StoreOrder_email:"bluehills@yandex.com",
    StoreOrder_name:"bluehills",
    StoreOrder_phone:"07034738829",
    StoreOrder_gender:"female",
    }
    * @returns {void}
 */

export const editStoreOrder = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["UPDATING"], payLoad: true });
    const response = await storeOrderService.editStoreOrder({
      ...payLoad,
    });
    dispatch({ type: storeOrderType["UPDATING"], payLoad: false });
    dispatch({ type: storeOrderType["EDIT_StoreOrder"], payLoad: payLoad });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["UPDATING"], payLoad: false });

    errorHandler(error, true);
  }
};

/**
 * Delete StoreOrder
 * @param {object} payLoad {id:"6d380968-233e-4f38-b85c-0040ee94bdd4"}
 * @returns
 */
export const deleteStoreOrder = (payLoad) => async (dispatch) => {
  try {
    const response = await storeOrderService.deleteStoreOrder(payLoad);
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: true });

    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({ type: storeOrderType["DELETE_STORE_ORDER"], payLoad: response });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });

    errorHandler(error, true);
  }
};

/**
 * Test StoreOrder model
 * @param {void}
 * @returns {void}
 */
export const testStoreOrderModel = () => async (dispatch) => {
  try {
    const response = await storeOrderService;
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: true });

    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    dispatch({
      type: storeOrderType["TEST_StoreOrder_MODEL"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: storeOrderType["LOADING_STORE_ORDER"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Ship StoreOrder
 * @param {void}
 * @returns {void}
 */
export const shipStoreOrder = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: storeOrderType["SHIPPING"], payLoad: true });
    const response = await storeOrderService.shipStoreOrder(payLoad);
    dispatch({ type: storeOrderType["SHIPPING"], payLoad: false });
    // dispatch({ type: storeOrderType["DELETE_STORE_ORDER"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: storeOrderType["SHIPPING"], payLoad: false });

    errorHandler(error, true);
  }
};
