import React from "react";
import { useSelector } from "react-redux";
import Delete from "../../../../assets/images/icons/delete-outline.svg";

function replaceUnderscoresWithSpaces(inputString) {
  return inputString?.replace(/_/g, " ");
}

export default function PackageItems({ pack, removeCartItem }) {
  const { settings } = useSelector((state) => state.application);
  const delivery_periods = settings?.package_delivery_periods;

  const delivery_time = Object.keys(delivery_periods).filter((time) => {
    return (
      delivery_periods[time].start_time ===
        pack?.package_delivery_period?.start_time &&
      delivery_periods[time].end_time ===
        pack?.package_delivery_period?.end_time
    );
  });

  const numberOfDays = () => {
    const date1 = new Date(pack?.subscription_start_date);
    const date2 = new Date(pack?.subscription_end_date);
    const tolerance = 1; // 1 millisecond tolerance
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    // Check if the start and end dates are the same
    if (diffTime <= tolerance) {
      return 1; // Dates are effectively the same
    }

    // Cap the result at a maximum of seven days
    return Math.min(diffDays + 1, 7);
  };

  return (
    <div className="flex justify-between items-start mb-5">
      <div className="flex">
        <span className="w-6 mr-10">1x</span>
        <div>
          <p className="mb-2">{pack?.package_name}</p>
          <p className="text-sm">
            {numberOfDays()} days. Delivery Time:{" "}
            <span className="capitalize">
              ({replaceUnderscoresWithSpaces(delivery_time[0])})
            </span>
          </p>
          <p className="text-sm">
            Duration: ({pack?.subscription_start_date}. -{" "}
            {pack?.subscription_end_date})
          </p>
        </div>
      </div>
      {/* <p>₦{pack?.amount}</p> */}
      <button type="button" onClick={() => removeCartItem(pack?.delete_id)}>
        <img src={Delete} alt="icon" className="mr-2" />
      </button>
    </div>
  );
}
