import arrangmentType from "./arrangmentTypes";

const initialState = {
  isLoading: false,
  arranging: false,
  foodArrangments: [],
  storeArrangments: [],
  packageArrangments: [],
  restaurantArrangments: [],
  merchandiseArrangments: [],
  advertsimentArrangments: [],
  featureArrangements: [],
};

export default function arrangementReducer(state = initialState, action) {
  switch (action.type) {
    case arrangmentType.LOADING_ARRANGEMENTS:
      return {
        ...state,
        arranging: action.payLoad,
      };
    case arrangmentType.LOADING_STORE_ARRANGEMENTS:
      return {
        ...state,
        arranging: action.payLoad,
      };
    case arrangmentType.STORE_ARRANGEMENTS:
      return {
        ...state,
        storeArrangments: action.payLoad.data,
      };
    case arrangmentType.ADVERTISMENT_ARRANGEMENTS:
      return {
        ...state,
        advertismentArrangments: action.payLoad.data,
      };
    case arrangmentType.RESTAURANT_ARRANGEMENTS:
      return {
        ...state,
        restaurantArrangments: action.payLoad?.data,
      };
    case arrangmentType.FOOD_ARRANGEMENTS:
      return {
        ...state,
        foodArrangments: action.payLoad?.data?.data,
      };
    case arrangmentType.MERCHANDISE_ARRANGEMENTS:
      return {
        ...state,
        restaurantArrangments: action.payLoad?.data,
      };
    case arrangmentType.FEATURE_ARRANGEMENTS:
      return {
        ...state,
        featureArrangements: action.payLoad?.data,
      };
    case arrangmentType.PACKAGE_ARRANGEMENTS:
      return {
        ...state,
        packageArrangements: action.payLoad?.data,
      };
    default:
      return state;
  }
}
