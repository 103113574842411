import React, { useState, Fragment, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Dialog, Transition, Switch } from "@headlessui/react";
import CLOSE from "../../../../assets/images/icons/close.svg";
import Button from "../../../buttons/buttons";
import Input from "../../../inputs/inputs";
import { cleanInput } from "../../../../helpers/cleanInput";
import { getAllTimeZone } from "../../../../redux/timezone/timezoneActions";
import HourDays from "../../../../pages/admin/settings/store/restaurant/HourDays";

export function ShipperOperatingHours({ index, data, setData }) {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [enabled, setEnabled] = useState(true);

  const { shipper } = useSelector((state) => state.shipper);
  const { allTimezones, isLoading } = useSelector((state) => state.timezone);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const [time_zone, setTimeZone] = useState("");
  const [operating_status, setOperatingStatus] = useState("available");
  const [operating_time, set_operating_time] = useState({
    monday: {
      open: "00:00",
      close: "00:00",
    },
    tuesday: {
      open: "00:00",
      close: "00:00",
    },
    wednesday: {
      open: "00:00",
      close: "00:00",
    },
    thursday: {
      open: "00:00",
      close: "00:00",
    },
    friday: {
      open: "00:00",
      close: "00:00",
    },
    saturday: {
      open: "00:00",
      close: "00:00",
    },
    sunday: {
      open: "00:00",
      close: "00:00",
    },
  });

  useEffect(() => {
    dispatch(getAllTimeZone("time_zones"));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (shipper?.base_fees_by_delivery_mode?.[index]?.operating_time) {
      set_operating_time(
        shipper?.base_fees_by_delivery_mode?.[index]?.operating_time
      );
    }
    if (shipper?.base_fees_by_delivery_mode?.[index]?.time_zone) {
      setTimeZone(shipper?.base_fees_by_delivery_mode?.[index]?.time_zone);
    }
  }, [shipper, index]);

  const handleSwitch = (e) => {
    // switch operating status
    if (e === false) {
      setOperatingStatus("unavailable");
    } else {
      setOperatingStatus("available");
    }
  };

  const handleSubmit = () => {
    cleanInput(operating_time);
    let oldBaseBydelivery = [...data?.base_fees_by_delivery_mode];

    oldBaseBydelivery[index] = {
      ...oldBaseBydelivery[index],
      operating_time,
      operating_status: operating_status,
      time_zone: time_zone,
    };

    if (operating_time) {
      setData({ ...data, base_fees_by_delivery_mode: oldBaseBydelivery });
    }

    closeModal();
  };

  return (
    <>
      <button
        type="button"
        className="border border-dashed border-[#C2C9D1] text-center p-2 w-full"
        onClick={openModal}
      >
        --Attach--
      </button>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[358px] sm:w-[1200px] sm:min-h-[649px] bg-white rounded-sm py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8">
                  <div>
                    <div className="sm:block absolute top-4 left-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md  p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img src={CLOSE} alt="close" className=" w-3 h-3" />
                      </button>
                    </div>
                  </div>

                  <div className="p-5 w-full sm:p-8 mx-auto">
                    <div className="mb-12 mt-6">
                      <div className="flex items-center justify-between">
                        <h3 className="text-2xl text-[#2B3674] font-bold">
                          Opening Hours
                        </h3>

                        <Switch.Group
                          as="div"
                          className="flex w-full sm:w-auto gap-4 items-center"
                        >
                          <Switch
                            checked={enabled}
                            onChange={(e) => {
                              setEnabled(e);
                              handleSwitch(e);
                            }}
                            className={classNames(
                              enabled ? "bg-primary" : "bg-gray-200",
                              "relative inline-flex h-5 sm:h-6 w-10 sm:w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                            )}
                          >
                            <span
                              aria-hidden="true"
                              className={classNames(
                                enabled ? "translate-x-5" : "translate-x-0",
                                "pointer-events-none inline-block h-4 sm:h-5 w-4 sm:w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                              )}
                            />
                          </Switch>
                          {/* <p className="text-lg font-medium text-primary">
                            {enabled ? "Open" : "Close"}
                          </p> */}
                        </Switch.Group>
                      </div>
                      <p className="text-[#A3AED0]">
                        Set the standard hours of operation for this mode of
                        delivery.
                      </p>
                    </div>

                    <div className="flex w-full sm:w-auto items-center gap-10 mb-8">
                      <label className="block text-2xl min-w-[160px] font-medium text-[#2B3674] capitalize">
                        Time Zone
                      </label>

                      <div className="w-full">
                        <Input.Select
                          title={""}
                          name="time_zone"
                          defaultValue={time_zone}
                          className="w-full"
                          onChange={(e) => setTimeZone(e.target.value)}
                        >
                          <option>
                            {shipper?.base_fees_by_delivery_mode?.[index]
                              ?.time_zone ?? "Select Timezone"}
                          </option>
                          {isLoading ? (
                            <option>Loading...</option>
                          ) : (
                            allTimezones &&
                            Object.keys(allTimezones).map((key, index) => (
                              <option key={index} value={allTimezones[key].utc}>
                                {allTimezones[key].iana} (
                                {allTimezones[key].utc})
                              </option>
                            ))
                          )}
                        </Input.Select>
                      </div>
                    </div>

                    <div className="flow-root">
                      {Object.keys(operating_time).map((day, index) => (
                        <HourDays
                          key={index}
                          day={day}
                          restaurant_operating_time={operating_time}
                          set_restaurant_operating_time={set_operating_time}
                        />
                      ))}
                    </div>
                  </div>

                  <div className="flex justify-center mt-8 pb-8  block">
                    <Button.Primary
                      type={"button"}
                      title={"Set Schedule"}
                      className={"w-[153px]"}
                      onClick={handleSubmit}
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
