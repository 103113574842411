import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { getUserFromCookies } from "../../../utils/Auth";
import Warning from "../../../assets/images/icons/warning.svg";
import { filterRestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";
import { getOutletFromCookies, getTeamFromCookies } from "../../../utils/Auth";

export default function FilterRestaurantOrdersDropDown(props) {
  const { payLoad, isFilterOpen, setFilterState } = props;
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const team = getTeamFromCookies();
  const { filtering } = useSelector((state) => state.restaurantOrder);
  const { page } = useParams();

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    recipient_name: "",
    recipient_phone: "",
    recipient_city: "",
    order_amount: "",
    start_date: "",
    end_date: "",
    order_paid: "",
    status: payLoad?.status,
  });
  const {
    recipient_name,
    recipient_phone,
    order_amount,
    start_date,
    end_date,
    order_paid,
    recipient_city,
  } = data;

  const user = getUserFromCookies();
  let business = Object.keys(user?.groups)[0];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleReset = async () => {
    setData({
      recipient_name: "",
      recipient_phone: "",
      recipient_city: "",
      order_amount: "",
      start_date: "",
      end_date: "",
      order_paid: "",
    });
    await dispatch(
      filterRestaurantOrderAction({ ...payLoad, pagination: 1 }, page)
    );
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    let newData = { ...data };
    if (team !== "administrator") {
      newData.business_id = business;
      newData.restaurant_id = outlet?.id;
    }
    if (
      recipient_name ||
      recipient_phone ||
      recipient_city ||
      order_amount ||
      order_paid ||
      start_date ||
      end_date
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterRestaurantOrderAction(newData, 1)
        );
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div data-test="">
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1">
          <Input.Label
            title={"Customer Name"}
            htmlFor={"recipient_name"}
            name={"recipient_name"}
            onChange={handleChange}
            value={recipient_name}
            autoComplete="off"
            placeholder={"Enter Restaurant Name"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.Number
            title={"Customer Phone"}
            htmlFor={"recipient_phone"}
            name={"recipient_phone"}
            onChange={handleChange}
            value={recipient_phone}
            autoComplete="off"
            placeholder={"Enter Customer Phone"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.Label
            title={"Customer City"}
            htmlFor={"recipient_city"}
            name={"recipient_city"}
            onChange={handleChange}
            value={recipient_city}
            autoComplete="off"
            placeholder={"Enter Customer City"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.Number
            title={"Order Amount"}
            htmlFor={"order_amount"}
            name={"order_amount"}
            onChange={handleChange}
            value={order_amount}
            autoComplete="off"
            placeholder={"Enter Order Amount"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />
          <Input.Select
            title={"Order Paid"}
            htmlFor={"order_paid"}
            name={"order_paid"}
            value={order_paid}
            onChange={handleChange}
          >
            <option>Select payment status</option>
            <option value={"1"}>Paid</option>
            <option value={"0"}>Unpaid</option>
          </Input.Select>

          <Input.DatePicker
            title={"Start Date"}
            name="start_date"
            id="start_date"
            htmlFor={"start_date"}
            defaultValue={start_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
          <Input.DatePicker
            title={"End Date"}
            name="end_date"
            id="end_date"
            htmlFor={"end_date"}
            defaultValue={end_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={filtering}
            title={"Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </div>
  );
}
