// Application types

const RESTAURANT = {
  LOADING_AUTH: "LOADING_AUTH",
  LOADING_SEARCH: "LOADING_SEARCH",
  UPDATING: "UPDATING",
  APPROVING: "APPROVING",
  FILTERING: "FILTERING",
  DOWNLOADING: "DOWNLOADING",
  ALL_RESTAURANTS: "ALL_RESTAURANTS",
  ALL_RESTAURANTS_NO_PAGINATION: "ALL_RESTAURANTS_NO_PAGINATION",
  SINGLE_RESTAURANT: "SINGLE_RESTAURANT",
  SEARCH_RESTAURANT: "SEARCH_RESTAURANT",
  MY_RESTAURANTS: "MY_RESTAURANTS",
  FILTER_RESTAURANTS: "FILTER_RESTAURANTS",
  APPROVE_RESTAURANT_MANAGEMENT: "APPROVE_RESTAURANT_MANAGEMENT",
  UNAPPROVE_RESTAURANT_MANAGEMENT: "UNAPPROVE_RESTAURANT_MANAGEMENT",
  APPROVE_RESTAURANT_BUSINESS: "APPROVE_RESTAURANT_BUSINESS",
  UNAPPROVE_RESTAURANT_BUSINESS: "UNAPPROVE_RESTAURANT_BUSINESS",
};

export default RESTAURANT;
