import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { Beat } from "../../../plugins/spinners.plugin";
import Warning from "../../../assets/images/icons/warning.svg";
import { filterCatalogueAction } from "../../../redux/catalogue/catalogueAction";

export default function FilterCatalogueDropDown({
  payLoad,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.coupon);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    catalogue_title: "",
    management_approved: "",
    start_date: "",
    end_date: "",
  });
  const { catalogue_title, management_approved, start_date, end_date } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      catalogue_title: "",
      management_approved: "",
      start_date: "",
      end_date: "",
    });
    await dispatch(filterCatalogueAction(payLoad, 1));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (catalogue_title || management_approved || start_date || end_date) {
      setErrorMsg(false);
      let newData = { ...data, ...payLoad };
      try {
        const response = await dispatch(filterCatalogueAction(newData, 1));
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Catalogue Title"}
              htmlFor={"catalogue_title"}
              name={"catalogue_title"}
              onChange={handleChange}
              autoComplete="off"
              value={catalogue_title}
              placeholder={"Enter Title"}
            />

            <Input.Select
              title={"Approval Status"}
              name="management_approved"
              htmlFor={"management_approved"}
              value={management_approved}
              onChange={handleChange}
            >
              <option>Select a status</option>
              <option value={1}>Approved</option>
              <option value={0}>Unapproved</option>
            </Input.Select>

            <Input.DatePicker
              title={"Start Date"}
              htmlFor={"start_date"}
              name={"start_date"}
              autoComplete="off"
              value={start_date}
              onChange={handleChange}
            />

            <Input.DatePicker
              title={"End Date"}
              htmlFor={"end_date"}
              name={"end_date"}
              autoComplete="off"
              value={end_date}
              onChange={handleChange}
            />

            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
