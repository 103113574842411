import axios from "../plugins/axios.plugin";

class PublicAdvertisementService {
  baseServicePath = "/api/public/advertisement";

  getAllPublicAdvertisements() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }
  filterPublicAdvertisements(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }
  searchPublicAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showPublicAdvertisement(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}`);
  }
}

export const publicAdvertisementService = new PublicAdvertisementService();
