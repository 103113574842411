const PROMO = {
  GET_ALL_PROMO: "GET_ALL_PROMO",
  FILTER_PROMO: "FILTER_PROMO",
  FILTER_PROMO_NOPAGE: "FILTER_PROMO_NOPAGE",
  SEARCH_PROMO: "SEARCH_PROMO",
  STORE_PROMO: "STORE_PROMO",
  SHOW_PROMO: "SHOW_PROMO",
  SHOW_MY_PROMO: "SHOW_MY_PROMO",
  EDIT_PROMO: "EDIT_PROMO",
  DELETE_PROMO: "DELETE_PROMO",
  LOADING_PROMO: "LOADING_PROMO",
  LOADING_SEARCH: "LOADING_SEARCH",
  DELETING: "DELETING",
  FILTERING: "FILTERING",
};

export default PROMO;
