export const cleanInput = (data) => {
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      if (
        data[key] === null ||
        data[key] === undefined ||
        data[key] === "" ||
        data[key]?.length === 0 ||
        Object.keys(data[key])?.length === 0
      ) {
        delete data[key];
      }
    }
  }

  return true;
};
