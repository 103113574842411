import React, { useMemo, useEffect, useLayoutEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { AttachUsers } from "./attachUsers";
import { Beat } from "../../../../../plugins/spinners.plugin";
import Button from "../../../../../components/buttons/buttons";
import Card from "../../../../../components/cards/admin/cards";
import Table from "../../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../../components/skeleton/table";
import { PaginationNoRoute } from "../../../../../components/pagination/paginationNoRoute";
import EmptyState from "../../../../../assets/images/emptyState/orders.svg";
import CLOSE from "../../../../../assets/images/icons/close.svg";
import {
  editANotifierGroup,
  showANotifierGroup,
} from "../../../../../redux/notifierGroup/notifierGroupAction";

export default function GroupRecipients({ page, setPage }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const [openActions, setOpenActions] = useState(false);

  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [showSelected, setShowSelected] = useState([]);
  const [removalArr, setRemovalArr] = useState([]);

  const { singleNotifierGroup, loading, updating, searching } = useSelector(
    (state) => state.notifierGroup
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  async function doSearch() {
    const searchResult = singleNotifierGroup?.users.filter(
      (user) =>
        user?.name.includes(input.search) || user?.email.includes(input.search)
    );
    showSearch(searchResult);
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(singleNotifierGroup?.users);
      setData(dataSet);
    }
  };

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((recipient, index) => {
        let show = {
          sn: index + 1,
          name: recipient?.name,
          box: (
            <>
              <input
                type="checkbox"
                id={`custom-checkbox-${index}`}
                onChange={() => {
                  setOpenActions(true);
                  setRemovalArr([...removalArr, recipient?.id]);
                }}
                onClick={(e) => e.stopPropagation()}
                className="w-4 sm:w-5 h-4 sm:h-5 text-primary bg-white hover:bg-primary checked:bg-primary cursor-pointer focus:ring-0"
              />
            </>
          ),
          address: recipient?.address ?? "Not available",
          email: recipient?.email,
          createdAt: moment(recipient?.created_at).format("MMM DD, YYYY"),
          phone: recipient?.phone ?? "Not available",
          recipientData: recipient,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useLayoutEffect(() => {
    const dataSet = createTableData(singleNotifierGroup?.users);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [singleNotifierGroup]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.sn",
          },
          {
            Header: "",
            accessor: "show.box",
          },
          {
            Header: "Name",
            accessor: "show.name",
          },
          {
            Header: "Address",
            accessor: "show.address",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
          {
            Header: "Created On",
            accessor: "show.createdAt",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
        ],
      },
    ],
    []
  );

  const goToUser = (params) => {
    navigate(`/admin/user/${params?.show?.recipientData?.id}`, {
      state: { user: params?.show?.recipientData },
    });
  };

  async function handleRemoveUser() {
    let old_recipients = singleNotifierGroup?.recipients ?? [];
    if (removalArr?.length > 0) {
      const filter = old_recipients.filter(
        (id) => removalArr.indexOf(id) === -1
      );

      const data = {
        id: singleNotifierGroup?.id,
        title: singleNotifierGroup?.title,
        recipients: [...filter],
      };

      const response = await dispatch(editANotifierGroup(data));
      if (response?.status === "success") {
        setOpenActions(false);
        setRemovalArr([]);
        dispatch(showANotifierGroup(singleNotifierGroup?.id, 1));
      }
    }
  }

  return (
    <div className="relative">
      <Card.TableFilter
        title={"Group Recipients"}
        searchPlaceholder={"Search user by name or email"}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
      >
        <Button.Secondary
          title={"+ Add User"}
          type="button"
          className="text-center p-3 w-full"
          onClick={() => setIsOpen(true)}
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-8">
        {loading || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full cursor-pointer"
            onClick={goToUser}
          />
        ) : (
          <div className="py-16 text-center">
            <div className="flex justify-center">
              <img src={EmptyState} className="w-auto" alt="no data" />
            </div>
            <h3 className="mt-5 text-xl font-bold">Group recipients</h3>
            <p className="mt-2 text-sm text-[#9CA3AF]">
              You do not have any recipients yet.
            </p>
          </div>
        )}

        <PaginationNoRoute
          data={singleNotifierGroup?.users}
          page={page}
          setPage={setPage}
        />
      </div>

      <AttachUsers
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        selected={selected}
        showSelected={showSelected}
        setShowSelected={setShowSelected}
        setSelected={setSelected}
        selectedArr={selectedArr}
        setSelectedArr={setSelectedArr}
      />

      {openActions && (
        <div className="absolute top-0 bottom-0 h-full w-full bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="relative bg-white mt-20 mx-auto w-[990px] rounded-md py-5 px-8 flex justify-around items-center">
            <button
              type="button"
              className="text-black block absolute top-5 right-5"
              onClick={() => {
                setOpenActions(false);
                setRemovalArr([]);
              }}
            >
              <span className="sr-only">Close</span>
              <img src={CLOSE} alt="close" className=" w-3 h-3" />
            </button>

            <div>
              <p>Status Result:</p>
              <p>Items Checked: {removalArr?.length}</p>
            </div>

            <div className="flex justify-between items-center gap-8">
              <Button.Secondary
                title={"Reset"}
                className={"h-11 mt-6"}
                onClick={() => {
                  setRemovalArr([]);
                  setOpenActions(false);
                }}
              />
              <Button.Primary
                title={updating ? <Beat /> : "Remove User"}
                disabled={updating}
                className={"h-11 mt-6"}
                onClick={handleRemoveUser}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
