import React, { useState } from "react";
import Button from "../../../components/buttons/buttons";
import Input from "./../../../components/inputs/inputs";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { cleanInput } from "./../../../helpers/cleanInput";
import { AttachRestaurant } from "../../../components/modals/admin/catalogues/attachRestaurant";
import { AttachStore } from "../../../components/modals/admin/catalogues/attachStore";
import { AttachFood } from "../../../components/modals/admin/catalogues/attachFood";
import { AttachMerch } from "../../../components/modals/admin/catalogues/attachMerchandise";
import { AttachPackage } from "../../../components/modals/admin/catalogues/attachPackages";
import { createCatalogue } from "../../../redux/catalogue/catalogueAction";
import { Beat } from "../../../plugins/spinners.plugin";

export default function CreateCatalogue() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.catalogue);

  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [showSelected, setShowSelected] = useState([]);
  const [selectedStore, setSelectedStore] = useState([]);
  const [selectedStoreArr, setSelectedStoreArr] = useState([]);
  const [showStore, setShowStore] = useState([]);
  const [selectedMerchArr, setSelectedMerchArr] = useState([]);
  const [selectedMerch, setSelectedMerch] = useState([]);
  const [showMerch, setShowMerch] = useState([]);
  const [selectedFood, setSelectedFood] = useState([]);
  const [selectedFoodArr, setSelectedFoodArr] = useState([]);
  const [showFood, setShowFood] = useState([]);
  const [selectedPack, setSelectedPack] = useState([]);
  const [selectedPackArr, setSelectedPackArr] = useState([]);
  const [showPack, setShowPack] = useState([]);

  const [data, setData] = useState({
    catalogue_title: "",
    catalogue_description: "",
    catalogue_foods: [],
    catalogue_merchandise: [],
    catalogue_stores: [],
    catalogue_restaurants: [],
    catalogue_packages: [],
  });

  const { catalogue_title } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setData({ ...data, [name]: value });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    let newData = {
      ...data,
      catalogue_foods: selectedFoodArr,
      catalogue_merchandise: selectedMerchArr,
      catalogue_packages: selectedPackArr,
      catalogue_restaurants: selectedArr,
      catalogue_stores: selectedStoreArr,
    };
    cleanInput(newData);
    if (catalogue_title) {
      const response = await dispatch(createCatalogue({ ...newData }));
      if (response.status === "success") {
        navigate("/admin/catalogue/unapproved/1");
      }
    }
  };

  return (
    <>
      <div className=" ml-52">
        <div className=" mx-auto bg-white sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 flex justify-center ">
              Create Catalogue
            </p>
          </div>
          <form>
            <div className=" sm:px-10">
              <div>
                <Input.Label
                  title={"Title*"}
                  htmlFor={"catalogue_title"}
                  name={"catalogue_title"}
                  placeholder={"Enter a catalogue name"}
                  onChange={handleChange}
                />
                {submitted && !catalogue_title && (
                  <div className="text-primary text-xs ">Title is required</div>
                )}
              </div>
              <div>
                <Input.TextArea
                  title={"Description*"}
                  htmlFor={"catalogue_description"}
                  name={"catalogue_description"}
                  placeholder={"Enter description"}
                  onChange={handleChange}
                />
              </div>

              {showSelected.length === 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-[#151515] mb-1">
                    Restaurants
                  </label>

                  <AttachRestaurant
                    selected={selected}
                    showSelected={showSelected}
                    setShowSelected={setShowSelected}
                    setSelected={setSelected}
                    selectedArr={selectedArr}
                    setSelectedArr={setSelectedArr}
                  />
                </div>
              )}

              {showSelected.length > 0 && (
                <div>
                  <div>
                    <Input.Label
                      title={"Restaurants"}
                      defaultValue={showSelected[0]?.map(
                        (restaurant, i) => restaurant?.restaurant_name
                      )}
                      readOnly
                    />
                  </div>
                  <AttachRestaurant
                    selected={selected}
                    showSelected={showSelected}
                    setShowSelected={setShowSelected}
                    setSelected={setSelected}
                    selectedArr={selectedArr}
                    setSelectedArr={setSelectedArr}
                  />
                </div>
              )}
              {showStore?.length === 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-[#151515] mb-1">
                    Stores
                  </label>
                  <AttachStore
                    selectedStore={selectedStore}
                    setSelectedStore={setSelectedStore}
                    showStore={showStore}
                    setShowStore={setShowStore}
                    selectedStoreArr={selectedStoreArr}
                    setSelectedStoreArr={setSelectedStoreArr}
                  />
                </div>
              )}

              {showStore?.length > 0 && (
                <div>
                  <div>
                    <Input.Label
                      title={"Stores"}
                      defaultValue={showStore[0]?.map(
                        (store, i) => store?.store_name
                      )}
                      readOnly
                    />
                  </div>
                  <AttachStore
                    selectedStore={selectedStore}
                    setSelectedStore={setSelectedStore}
                    showStore={showStore}
                    setShowStore={setShowStore}
                    selectedStoreArr={selectedStoreArr}
                    setSelectedStoreArr={setSelectedStoreArr}
                  />
                </div>
              )}
              {showFood?.length === 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-[#151515] mb-1">
                    Foods
                  </label>
                  <AttachFood
                    selectedFood={selectedFood}
                    setSelectedFood={setSelectedFood}
                    showFood={showFood}
                    setShowFood={setShowFood}
                    selectedFoodArr={selectedFoodArr}
                    setSelectedFoodArr={setSelectedFoodArr}
                  />
                </div>
              )}
              {showFood?.length > 0 && (
                <div>
                  <div>
                    <Input.Label
                      title={"Foods"}
                      defaultValue={showFood[0]?.map(
                        (food, i) => food?.food_name
                      )}
                      readOnly
                    />
                  </div>
                  <AttachFood
                    selectedFood={selectedFood}
                    setSelectedFood={setSelectedFood}
                    showFood={showFood}
                    setShowFood={setShowFood}
                    selectedFoodArr={selectedFoodArr}
                    setSelectedFoodArr={setSelectedFoodArr}
                  />
                </div>
              )}

              {showMerch?.length === 0 && (
                <div className="mb-4">
                  <label className="block text-sm font-medium text-[#151515] mb-1">
                    Merchandise
                  </label>
                  <AttachMerch
                    selectedMerchArr={selectedMerchArr}
                    setSelectedMerchArr={setSelectedMerchArr}
                    showMerch={showMerch}
                    setShowMerch={setShowMerch}
                    selectedMerch={selectedMerch}
                    setSelectedMerch={setSelectedMerch}
                  />
                </div>
              )}
              {showMerch?.length > 0 && (
                <div>
                  <div>
                    <Input.Label
                      title={"Merchandise"}
                      defaultValue={showMerch[0]?.map(
                        (merch, i) => merch?.merchandise_name
                      )}
                      readOnly
                    />
                  </div>
                  <AttachMerch
                    selectedMerchArr={selectedMerchArr}
                    setSelectedMerchArr={setSelectedMerchArr}
                    showMerch={showMerch}
                    setShowMerch={setShowMerch}
                    selectedMerch={selectedMerch}
                    setSelectedMerch={setSelectedMerch}
                  />
                </div>
              )}
              {showPack?.length === 0 && (
                <div className="mb-20">
                  <label className="block text-sm font-medium text-[#151515] mb-1">
                    Packages
                  </label>
                  <AttachPackage
                    selectedPack={selectedPack}
                    showPack={showPack}
                    setShowPack={setShowPack}
                    setSelectedPack={setSelectedPack}
                    selectedPackArr={selectedPackArr}
                    setSelectedPackArr={setSelectedPackArr}
                  />
                </div>
              )}
              {showPack?.length > 0 && (
                <div>
                  <div>
                    <Input.Label
                      title={"Packages"}
                      defaultValue={showPack[0]?.map(
                        (pack, i) => pack?.package_name
                      )}
                      readOnly
                    />
                  </div>
                  <AttachPackage
                    selectedPack={selectedPack}
                    showPack={showPack}
                    setShowPack={setShowPack}
                    setSelectedPack={setSelectedPack}
                    selectedPackArr={selectedPackArr}
                    setSelectedPackArr={setSelectedPackArr}
                  />
                </div>
              )}
            </div>
          </form>
        </div>
        <div>
          <Button.Primary
            title={isLoading ? <Beat color={"#ffffff"} /> : "Create Catalogue"}
            className={"w-[151px] sm:mt-10 sm:ml-72 ml-36 rounded-sm "}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}
