import publicStoreType from "./publicStoreTypes";

const initialState = {
  isLoading: false,
  loadingMore: false,
  allPublicStores: [],
  filter: {},
  singlePublicStore: {},
  search: [],
};

export default function publicStoreReducer(state = initialState, action) {
  switch (action.type) {
    case publicStoreType.LOADING_PUBLIC_STORE:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case publicStoreType.LOADING_MORE_STORES:
      return {
        ...state,
        loadingMore: action.payLoad,
      };

    case publicStoreType.ALL_PUBLIC_STORES:
      return {
        ...state,
        allPublicStores: action.payLoad?.data,
      };

    case publicStoreType.MORE_PUBLIC_STORES:
      return {
        ...state,
        allPublicStores: {
          ...state.allPublicStores,
          data: [...state.allPublicStores.data, ...action.payLoad?.data?.data],
        },
      };

    case publicStoreType.SINGLE_PUBLIC_STORE:
      return {
        ...state,
        singlePublicStore: action.payLoad?.data,
      };
    case publicStoreType.FILTER_PUBLIC_STORE:
      return {
        ...state,
        filter: action.payLoad?.data,
        allPublicStores: action.payLoad?.data,
      };
    case publicStoreType.SEARCH_PUBLIC_STORE:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    default:
      return state;
  }
}
