import cartType from "./cartTypes";

const initialState = {
  allCarts: [],
  loading: false,
  storing: false,
  filtering: false,
  storeCart: {},
  cart: {},
  searching: false,
  deleteCart: {},
  myCarts: {},
  filterCarts: [],
  search: [],
  reloadCart: false,
  processing: false,
  updatingCoupon: false,
  cartProducts: [],
  myCartNoProperties: {},
};
export default function CartReducer(state = initialState, action) {
  switch (action.type) {
    case cartType.GET_ALL_CARTS:
      return {
        ...state,
        allCarts: action.payLoad?.data,
      };
    case cartType.STORING_PUBLIC_CART:
      return {
        ...state,
        storing: action.payLoad,
      };
    case cartType.STORE_CART:
      return {
        ...state,
        storeCart: action.payLoad?.data.data,
      };
    case cartType.DELETE_CART:
      return {
        ...state,
        deleteCart: action.payLoad.data.data,
      };
    case cartType.SHOW_CART:
      return {
        ...state,
        cart: action.payLoad?.data,
      };
    case cartType.SHOW_MY_CART:
      return {
        ...state,
        myCarts: action.payLoad?.data,
      };
    case cartType.SHOW_MY_CART_NO_PROPERTIES:
      return {
        ...state,
        myCartNoProperties: action.payLoad?.data,
      };
    case cartType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case cartType.FILTER_CARTS:
      return {
        ...state,
        allCarts: action.payLoad?.data,
      };
    case cartType.SEARCH_CARTS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case cartType.LOADING_CART:
      return {
        ...state,
        loading: action.payLoad,
      };
    case cartType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case cartType.PROCESSING:
      return {
        ...state,
        processing: action.payLoad,
      };

    case cartType.RELOADING_CART:
      return {
        ...state,
        reloadCart: action.payLoad,
      };
    case cartType.CART_PRODUCTS:
      return {
        ...state,
        cartProducts: action.payLoad,
      };

    case cartType.UPDATING_COUPON:
      return {
        ...state,
        updatingCoupon: action.payLoad,
      };

    case cartType.EDIT_CART:
      return {
        ...state,
      };
    default:
      return state;
  }
}
