import React, { useMemo, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import Table from "../../../../components/tables/tableCols1";
import Card from "../../../../components/cards/admin/cards";
import TableSkeleton from "../../../../components/skeleton/table";
import EmptyState from "../../../../assets/images/icons/settingsEmpty.svg";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterUsersDropDown from "../../../../components/dropDown/filter/filterUsers";
import { PaginationNoRoute } from "../../../../components/pagination/paginationNoRoute";
import { AddNewRoleModal } from "../../../../components/modals/roles/addNewRoleModal";
import {
  filterUsersAction,
  searchUsersAction,
} from "../../../../redux/users/userActions";

export default function UserRoles() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const [isFilterOpen, setFilterState] = useState(false);
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });
  const [isOpen, setIsOpen] = useState(false);
  const [payLoad] = useState({
    end_date: new Date(),
  });
  const { allUsers, filtering, searching } = useSelector((state) => state.user);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    dispatch(filterUsersAction(payLoad, page));
    // eslint-disable-next-line
  }, [page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((user, index) => {
        let show = {
          id: index + 1,
          name: user?.name,
          email: user?.email,
          created: (
            <span>{moment(user?.created_at).format("MMM DD, YYYY")}</span>
          ),
          phone: user?.phone ?? "Not available",
          user: user,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allUsers?.data);
    setData(dataSet);
    // eslint-disable-next-line
  }, [allUsers]);

  function goToSinglePage(params) {
    let user_id = params?.show?.user?.id;
    navigate(`/admin/settings/single-user-roles/${user_id}`, {
      state: { user: params?.show?.user },
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allUsers?.data);
      setData(dataSet);
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchUsersAction(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Name",
            accessor: "show.name",
          },
          {
            Header: "Email",
            accessor: "show.email",
          },
          {
            Header: "Created On",
            accessor: "show.created",
          },
          {
            Header: "Phone Number",
            accessor: "show.phone",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <AddNewRoleModal show={isOpen} onClose={closeModal} />
      <div className="mt-12">
        <Card.TableFilter
          title={"Manage User Roles"}
          searchPlaceholder={"Search users"}
          onChange={handleChange}
          handleKeyDown={handleKeyDown}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <FilterUsersDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
              />
            </AllFiltersModal>
          }
        />

        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white rounded-md py-4 ">
          {filtering || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              rowClass={"hover:shadow-md cursor-pointer"}
              onClick={goToSinglePage}
            />
          ) : (
            <div className="py-24 text-center">
              <div className="flex justify-center">
                <img src={EmptyState} className="w-auto" alt="no data" />
              </div>
              <h3 className="mt-5 text-xl font-bold">Roles</h3>
              <p className="mt-2 text-sm text-[#9CA3AF]">No roles found.</p>
            </div>
          )}
        </div>
        <PaginationNoRoute data={allUsers} page={page} setPage={setPage} />
      </div>
    </>
  );
}
