import authType from "./publicRestaurantTypes";

const initialState = {
  isLoading: false,
  loadingMore: false,
  allRestaurants: {},
  filter: {},
  restaurant: {},
  search: [],
};

export default function publicRestaurantReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.LOADING_MORE:
      return {
        ...state,
        loadingMore: action.payLoad,
      };

    case authType.ALL_RESTAURANTS:
      return {
        ...state,
        allRestaurants: action.payLoad?.data,
      };

    case authType.MORE_RESTAURANTS:
      return {
        ...state,
        allRestaurants: {
          ...state.allRestaurants,
          data: [...state.allRestaurants.data, ...action.payLoad?.data?.data],
        },
      };

    case authType.SINGLE_RESTAURANT:
      return {
        ...state,
        restaurant: action.payLoad?.data,
      };

    case authType.FILTER_RESTAURANTS:
      return {
        ...state,
        filter: action.payLoad?.data,
        allRestaurants: action.payLoad?.data,
      };

    default:
      return state;
  }
}
