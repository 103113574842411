import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import Button from "../../../components/buttons/buttons";
import { Beat } from "../../../plugins/spinners.plugin";
import { getTeamFromCookies } from "../../../utils/Auth";
import {
  showPromoCode,
  expirePromoCode,
} from "../../../redux/promo/promoAction";

export default function SinglePromoCode() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const team = getTeamFromCookies();
  const { id } = useParams();

  const { deleting, singlePromoCode } = useSelector((state) => state.promo);

  useEffect(() => {
    if (id) {
      dispatch(showPromoCode(id));
    }
    // eslint-disable-next-line
  }, [id]);

  const handleDelete = async () => {
    const response = await dispatch(expirePromoCode(id));
    if (response) {
      navigate("/admin/promo/all/1");
    }
  };

  return (
    <>
      <div className="border w-full sm:w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-2 items-center flex justify-between">
          <h3 className="text-base sm:text-lg font-medium">Promo Details</h3>
          {team === "administrator" && (
            <Button.Primary
              type={"button"}
              disabled={deleting}
              title={deleting ? <Beat color={"#ffffff"} /> : "Expire Coupon"}
              onClick={handleDelete}
            />
          )}
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-6 gap-4 sm:gap-8 mt-3 sm:mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Title</label>
            <p className="text-[#151515] text-sm">{singlePromoCode?.title}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Slug</label>
            <p className="text-[#151515] text-sm">{singlePromoCode?.slug}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Amount</label>
            <p className="text-[#151515] text-sm">{singlePromoCode?.amount}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created on </label>
            <p className="text-[#151515] text-sm">
              {moment(singlePromoCode?.created_at).format(
                "DD, MMM YYYY, HH:MM A"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Expires</label>
            <p className="text-[#151515] text-sm">
              {moment(singlePromoCode?.expiry).format("DD, MMM YYYY, HH:MM A")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Quantity</label>
            <p className="text-[#151515] text-sm">
              {singlePromoCode?.quantity}
            </p>
          </div>
        </div>
      </div>
      {singlePromoCode?.restaurant && (
        <div className="border w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
          <div className="border-b border-[#E4E4F3] py-2 items-center">
            <h3 className="text-base sm:text-lg font-medium">
              {singlePromoCode?.restaurant?.restaurant_name}
            </h3>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:gap-8 mt-3 sm:mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Restaurant ID
              </label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.restaurant_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.restaurant_phone
                  ? singlePromoCode?.restaurant?.restaurant_phone
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.flag}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
              <p className="text-[#151515] text-sm">
                {moment(singlePromoCode?.restaurant?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Visibility</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.visibility}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.restaurant_country}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">State</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.restaurant_state}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">City</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.restaurant?.restaurant_city}
              </p>
            </div>
          </div>
        </div>
      )}
      {singlePromoCode?.store && (
        <div className="border w-full border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
          <div className="border-b border-[#E4E4F3] py-2 items-center">
            <h3 className="text-base sm:text-lg font-medium">
              {singlePromoCode?.store?.store_name}
            </h3>
          </div>
          <div className="grid grid-cols-2 sm:grid-cols-5 gap-4 sm:gap-8 mt-3 sm:mt-6">
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Store ID</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.id}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.store_email}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">
                Phone Number
              </label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.store_phone
                  ? singlePromoCode?.store?.store_phone
                  : "Not available"}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.flag}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
              <p className="text-[#151515] text-sm">
                {moment(singlePromoCode?.store?.created_at).format(
                  "DD, MMM YYYY, HH:MM A"
                )}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Visibility</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.visibility}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.store_country}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">State</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.store_state}
              </p>
            </div>
            <div>
              <label className="text-[#9CA3AF] text-sm mb-2">City</label>
              <p className="text-[#151515] text-sm">
                {singlePromoCode?.store?.store_city}
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
