import axios from "../plugins/axios.plugin";

class RoleService {
  baseServicePath = "/api/role";

  getAllRoles() {
    return axios.get(`${this.baseServicePath}/index?properties=1`);
  }

  getARole(role_id) {
    return axios.get(
      `${this.baseServicePath}/show?role_id=${role_id}&properties=1`
    );
  }

  storeARole(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  assignARole(payload) {
    return axios.post(`${this.baseServicePath}/assign`, payload);
  }

  retractARole(payload) {
    return axios.post(`${this.baseServicePath}/retract`, payload);
  }

  updateARole(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  storeAPermission(payload) {
    return axios.post(`/api/permission/store`, payload);
  }

  assignAPermission(payload) {
    return axios.post(`/api/permission/assign`, payload);
  }
}
export const roleService = new RoleService();
