import React, { useState, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import FoodImage from "../../../assets/images/emptyState/single-food.jpg";
import Input from "../../inputs/inputs";
import AddPackageSkeleton from "../../skeleton/addPackage";
import { searchSubscriptionMerchAction } from "../../../redux/subscriptionMerchandise/subscriptionMerchAction";

const tabs = [
  { name: "Mon.", id: "monday" },
  { name: "Tue.", id: "tuesday" },
  { name: "Wed.", id: "wednesday" },
  { name: "Thurs.", id: "thursday" },
  { name: "Fri.", id: "friday" },
  { name: "Sat.", id: "saturday" },
  { name: "Sun.", id: "sunday" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function AddItemModal(props) {
  const { selected, setSelected, setInput } = props;
  const dispatch = useDispatch();
  const [active_tab, setActiveTab] = useState("monday");
  const [show_search, setShowSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const { search, searching } = useSelector((state) => state.subscriptionMerch);

  const toggle = (tab) => {
    if (active_tab !== tab.id) {
      setSearchValue("");
      return setActiveTab(tab.id);
    }
  };

  const handleSearch = (e) => {
    const { value } = e.target;
    setSearchValue(value);
    e.preventDefault();
    if (value.length > 2) {
      dispatch(searchSubscriptionMerchAction({ search: value }));
      setShowSearch(true);
    }
  };

  const handleSelected = (item) => {
    setShowSearch(false);
    if (active_tab === "monday") {
      setSelected({ ...selected, monday: [...selected.monday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            monday: [...input.package_products.monday, item?.id],
          },
        };
      });
    }
    if (active_tab === "tuesday") {
      setSelected({ ...selected, tuesday: [...selected.tuesday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            tuesday: [...input.package_products.tuesday, item?.id],
          },
        };
      });
    }
    if (active_tab === "wednesday") {
      setSelected({ ...selected, wednesday: [...selected.wednesday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            wednesday: [...input.package_products.wednesday, item?.id],
          },
        };
      });
    }
    if (active_tab === "thursday") {
      setSelected({ ...selected, thursday: [...selected.thursday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            thursday: [...input.package_products.thursday, item?.id],
          },
        };
      });
    }
    if (active_tab === "friday") {
      setSelected({ ...selected, friday: [...selected.friday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            friday: [...input.package_products.friday, item?.id],
          },
        };
      });
    }
    if (active_tab === "saturday") {
      setSelected({ ...selected, saturday: [...selected.saturday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            saturday: [...input.package_products.saturday, item?.id],
          },
        };
      });
    }
    if (active_tab === "sunday") {
      setSelected({ ...selected, sunday: [...selected.sunday, item] });
      setInput((input) => {
        return {
          ...input,
          package_products: {
            ...input.package_products,
            sunday: [...input.package_products.sunday, item?.id],
          },
        };
      });
    }
  };

  const removeItem = (i) => {
    const filtered = selected[active_tab].filter((item, index) => index !== i);
    setSelected({ ...selected, [active_tab]: filtered });
  };

  return (
    <>
      <Transition appear show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full sm:w-[650px] sm:max-w-2xl bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-8">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-3xl p-1 sm:p-3  "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-3 text-center sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black "
                      >
                        Attach Package Item
                      </Dialog.Title>
                    </div>
                  </div>

                  <div className="mt-4">
                    <nav
                      className="-mb-px flex space-x-8 overflow-x-auto"
                      aria-label="Tabs"
                    >
                      {tabs.map((tab) => (
                        <button
                          key={tab.id}
                          onClick={() => {
                            toggle(tab);
                          }}
                          className={classNames(
                            tab.id === active_tab
                              ? "border-primary text-primary font-bold"
                              : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                            "whitespace-nowrap pt-4 pb-2 px-1 border-b-2 font-medium text-sm"
                          )}
                          aria-current={
                            tab.id === active_tab ? "page" : undefined
                          }
                        >
                          {tab.name}
                        </button>
                      ))}
                    </nav>
                    <div className="mt-8">
                      <div className="relative">
                        <Input.Label
                          title={"Merchandise Package Items"}
                          type="search"
                          id="search"
                          value={searchValue}
                          className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                          placeholder="Search merchandise package items"
                          onChange={handleSearch}
                        />
                        {searching ? (
                          <div className="absolute w-full bg-white shadow top-[68px]">
                            <div className="p-3">
                              <AddPackageSkeleton />
                            </div>
                          </div>
                        ) : (
                          show_search &&
                          search?.length > 0 && (
                            <div className="absolute w-full bg-white shadow top-[68px]">
                              <div className="p-3">
                                {search?.length > 0 &&
                                  search?.map((item, i) => (
                                    <div
                                      key={i}
                                      className="flex justify-between cursor-pointer mb-2 hover:border-b"
                                      onClick={() => handleSelected(item)}
                                    >
                                      <p className="text-sm">
                                        {item?.merchandise_name}
                                      </p>{" "}
                                      <p className="text-primary text-sm">
                                        + Add
                                      </p>
                                    </div>
                                  ))}
                              </div>
                            </div>
                          )
                        )}
                      </div>

                      <p className="text-sm mt-2 mb-5">
                        Select a package item for monday or skip to prefered day
                        of the week
                      </p>

                      {active_tab === "monday" &&
                        selected?.monday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.monday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {active_tab === "tuesday" &&
                        selected?.tuesday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.tuesday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {active_tab === "wednesday" &&
                        selected?.wednesday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.wednesday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {active_tab === "thursday" &&
                        selected?.thursday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.thursday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {active_tab === "friday" &&
                        selected?.friday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.friday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {active_tab === "saturday" &&
                        selected?.saturday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.saturday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}

                      {active_tab === "sunday" &&
                        selected?.sunday?.length > 0 && (
                          <div>
                            <h4 className="py-2 mb-2 text-md border-b font-medium">
                              Overview
                            </h4>
                            {selected?.sunday.map((merchandise, i) => (
                              <div
                                key={i}
                                className="flex items-center gap-6 mb-4"
                              >
                                <div>
                                  <img
                                    src={FoodImage}
                                    className="h-[52px] w-[130px] object-cover rounded-md"
                                    alt="merchandise"
                                  />
                                </div>
                                <div className="w-full">
                                  <h4 className="text-md mb-1">
                                    {merchandise?.merchandise_name}
                                  </h4>
                                  <p className="text-xs">
                                    {merchandise?.merchandise_description}
                                  </p>
                                </div>
                                <div className="w-full text-right">
                                  <button
                                    className="text-primary text-md"
                                    onClick={() => removeItem(i)}
                                  >
                                    Remove
                                  </button>
                                  <p>
                                    ₦
                                    {numberWithCommas(
                                      merchandise?.merchandise_amount
                                    )}
                                  </p>
                                </div>
                              </div>
                            ))}
                          </div>
                        )}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
