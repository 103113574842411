import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { XIcon } from "@heroicons/react/outline";
import { Beat } from "../../../../plugins/spinners.plugin";
import { history } from "../../../../helpers/history";
import { cleanInput } from "../../../../helpers/cleanInput";
import SelectableInput from "../../../../components/inputs/SelectableInput";
import Warning from "../../../../assets/images/icons/warning-circle.svg";
import { ReactComponent as Upload } from "../../../../assets/images/icons/upload.svg";
import AddFoodVariationModal from "../../../../components/modals/admin/attributes/addFoodVariationModal";
import RemoveFoodAttributeModal from "../../../../components/modals/admin/attributes/removeFoodAttributeModal";
import { storeAFoodAction } from "../../../../redux/food/foodActions";
import { filterProductCategory } from "../../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../../redux/productTags/productTagActions";
import { filterAttributeAction } from "../../../../redux/attribute/attributeActions";
import {
  getUserFromCookies,
  getOutletFromCookies,
  getTeamFromCookies,
} from "../../../../utils/Auth";

export default function CreateFood() {
  const dispatch = useDispatch();
  const user = getUserFromCookies();
  const team = getTeamFromCookies();
  const outlet = getOutletFromCookies();
  let business = Object.keys(user?.groups)[0];
  const hiddenFileInput = useRef(null);

  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const { isLoading } = useSelector((state) => state.food);

  const [categoryList, setCategoryList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const [selected, setSelected] = useState([]);
  const [selectedArr, setSelectedArr] = useState([]);
  const [displayArr, setDisplayArr] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);

  const [input, setInput] = useState({
    business_id: business,
    restaurant_id: outlet?.id,
    food_name: "",
    food_description: "",
    food_categories: [],
    food_tags: [],
    food_amount: "",
    food_discount: 0,
    food_preparation_time: "",
    food_operating_status: "",
    base64_photos: "",
    food_attributes: [],
    format: "",
  });
  const {
    food_name,
    food_description,
    food_tags,
    food_amount,
    food_categories,
    format,
  } = input;

  useEffect(() => {
    dispatch(filterAttributeAction({ business_id: team }));
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    dispatch(filterProductTag({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };

  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  useEffect(() => {
    let items = allCategories?.data?.filter(
      (item) => item.type === "restaurant"
    );
    setCategoryList(items);
  }, [allCategories]);

  useEffect(() => {
    let items = allTags?.data?.filter((item) => item.type === "restaurant");
    setTagList(items);
  }, [allTags]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleToggleTags = (value) => {
    let former_tags = [];
    if (input?.food_tags) {
      former_tags = [...input?.food_tags];
      if (former_tags.includes(value)) {
        let arr = [...former_tags];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, food_tags: filtered });
      } else {
        let new_tags = [...former_tags, value.name];
        setInput({ ...input, food_tags: new_tags });
      }
    } else {
      let new_tags = [...former_tags, value.name];
      setInput({ ...input, food_tags: new_tags });
    }
  };

  const handleToggleCategories = (value) => {
    let former_categories = [];
    if (input?.food_categories) {
      former_categories = [...input?.food_categories];
      if (former_categories.includes(value)) {
        let arr = [...former_categories];
        const filtered = arr.filter((item) => item !== value);
        setInput({ ...input, food_categories: filtered });
      } else {
        let new_categories = [...former_categories, value.name];
        setInput({ ...input, food_categories: new_categories });
      }
    } else {
      let new_categories = [...former_categories, value.name];
      setInput({ ...input, food_categories: new_categories });
    }
  };

  function getFoodWithCategories() {
    let titleArr = [];
    let newArray = [];
    for (let index = 0; index < selected?.length; index++) {
      if (!titleArr.includes(selected[index].title)) {
        titleArr.push(selected[index].title);
      }
    }
    for (let index = 0; index < titleArr?.length; index++) {
      let temp = {
        title: titleArr[index],
        attributes: selected.filter((food) => titleArr[index] === food.title),
      };
      newArray.push(temp);
    }
    return newArray;
  }

  useEffect(() => {
    const foodAttr = getFoodWithCategories();
    setDisplayArr(foodAttr);
    // eslint-disable-next-line
  }, [selected]);

  const handleSubmit = async () => {
    setSubmitted(true);
    let newInput = {
      ...input,
      base64_photos: image64 ? image64 : [],
      food_attributes: selectedArr,
    };
    cleanInput(newInput);

    if (
      food_name &&
      food_description &&
      food_description.length > 10 &&
      food_tags &&
      food_amount &&
      food_categories
    ) {
      try {
        const response = await dispatch(storeAFoodAction({ ...newInput }));
        if (response?.status === "success") {
          history(`/admin/${outlet?.restaurant_name}/food`);
          setInput({
            business_id: business,
            restaurant_id: outlet?.id,
            food_name: "",
            food_description: "",
            food_categories: [],
            food_tags: [],
            food_amount: "",
            food_discount: "",
            food_preparation_time: "",
            food_operating_status: "",
            base64_photos: "",
            food_attributes: [],
          });
        }
      } catch (error) {
        console.log(error);
        history(`/admin/food`);
      }
    }
  };

  return (
    <div data-test="createFood-page">
      <form>
        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Details</h3>
            <div className=" text-center">
              <Button.Primary
                title={isLoading ? <Beat color={"#ffffff"} /> : "Create Food"}
                onClick={handleSubmit}
                className="px-5"
                type={"button"}
                disabled={isLoading}
              />
            </div>
          </div>

          <div className="mt-6">
            <div>
              <Input.Label
                title={"Food Name"}
                type="text"
                name="food_name"
                id="food_name"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter food name"
                onChange={handleChange}
              />
              {submitted && !food_name && (
                <div className="text-xs text-red-500">
                  Food name is required
                </div>
              )}
            </div>

            <div>
              <Input.Label
                title={
                  "Short Description * (Hint: Input food Highlights/Features in bullets. Not more than 200 Characters)"
                }
                type="text"
                name="food_description"
                id="food_description"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="Enter a short food description"
                onChange={handleChange}
              />
              {food_description && food_description.length < 10 && (
                <div className="text-xs text-red-500">
                  Food description must be a minimum than 10 characters
                </div>
              )}
              {submitted && !food_description && (
                <div className="text-xs text-red-500">
                  Food description is required
                </div>
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <Input.Number
                  title={"Price"}
                  type="number"
                  name="food_amount"
                  id="food_amount"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="Enter food price"
                  onChange={handleChange}
                />
                {submitted && !food_amount && (
                  <div className="text-xs text-red-500">
                    Food amount is required
                  </div>
                )}
              </div>

              <Input.Number
                title={"Discount"}
                type="number"
                name="food_discount"
                id="food_discount"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="0"
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <Input.TimePicker
                title={"How many minutes does it take to prepare?"}
                name="food_preparation_time"
                id="food_preparation_time"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                onChange={handleChange}
              />

              <Input.Number
                title={"Stock"}
                type="number"
                name="stock"
                id="stock"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="0"
                onChange={handleChange}
              />
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <Input.Select
                title={"Product Type"}
                htmlFor={"format"}
                name={"format"}
                value={format}
                onChange={handleChange}
              >
                <option>Select Product Type</option>
                <option value={"physical"}>Physical Product</option>
                <option value={"digital"}>Digital Product</option>
              </Input.Select>

              <Input.Select
                title={"Split Payments Available?"}
                // htmlFor={"format"}
                // name={"format"}
                // value={format}
                onChange={handleChange}
              >
                <option value={"no"}>No</option>
                {/* <option value={"yes"}>Yes</option> */}
              </Input.Select>
            </div>
          </div>

          {/* Split payment section */}
          <div className="mt-8">
            {/* <div className="py-3 mb-2 flex justify-between items-center">
              <h3 className="text-lg font-medium">*Split Payment Setup*</h3>
              <Button.Primary
                title={isLoading ? <Beat color={"#ffffff"} /> : "Close Process"}
                onClick={handleSubmit}
                className="px-5"
                type={"button"}
                disabled={isLoading}
              />
            </div>
            <Input.Label
              title={"Split Payment Name"}
              type="text"
              name="restaurant_id"
              id="restaurant_id"
              className="w-full border border-[#E4E4F3] bg-[#F3F3F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="This is the designated name for describing the plan in notification messages."
              onChange={handleChange}
            />
            <Input.Number
              title={"Initial Payment"}
              type="number"
              name="food_amount"
              id="food_amount"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="This is the initial payment required before releasing the product to the customer."
              onChange={handleChange}
            />
            <Input.Number
              title={"Subsequent Payment Amount (£)"}
              type="number"
              name="food_amount"
              id="food_amount"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="This is the fee for each recurring payment from the customer"
              onChange={handleChange}
            />

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
              <div>
                <Input.Number
                  title={"Payment Frequency/Intervals"}
                  type="number"
                  name="food_amount"
                  id="food_amount"
                  className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                  placeholder="This can be monthly, quarterly, yearly e.t.c"
                  onChange={handleChange}
                />
              </div>

              <Input.Number
                title={"Duration"}
                type="number"
                name="food_discount"
                id="food_discount"
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
                placeholder="This is the duration the plan should endure in terms of intervals"
                onChange={handleChange}
              />
            </div> */}

            {/* Service area notice */}
            {/* <div className="mt-1 py-2 flex gap-3">
              <img src={Warning} alt="warning" className="w-[18px] h-[18px]" />
              <p className="text-sm text-[#333333]">
                Without a specified duration, we'll bill the customer until
                cancellation.
              </p>
            </div> */}
            {/* Service area notice */}
            <div className="mt-1 py-2 flex gap-3">
              <img src={Warning} alt="warning" className="w-[18px] h-[18px]" />
              <p className="text-sm text-[#333333]">
                The default currency for charging is set to Naira
              </p>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8 mt-8">
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Category</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="food_categories"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Food Categories
              </label>
              <SelectableInput
                options={categoryList}
                label="food_categories"
                name={"+ Add Category"}
                type="category"
                selectOption={handleToggleCategories}
              />
              {submitted && food_categories?.length < 1 && (
                <div className="text-xs text-red-500">
                  Food category is required
                </div>
              )}
            </div>
          </div>

          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Food Tags</h3>
            </div>
            <div className="mt-6">
              <label
                htmlFor="food_tags"
                className="text-sm font-medium text-[#151515] mb-2 block"
              >
                Food Tags
              </label>
              <SelectableInput
                options={tagList}
                label="food_tags"
                name={"+ Add Tag"}
                type="tag"
                selectOption={handleToggleTags}
              />
              {submitted && food_tags?.length < 1 && (
                <div className="text-xs text-red-500">
                  Food tags is required
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Food Images (200px by 200px)
              </h3>
              <p className="text-sm">
                Accepts .jpg, .png and .jpeg image formats
              </p>
            </div>
          </div>
          {imageUrls?.length === 0 ? (
            <div className="bg-[#FFFBFA] text-center border border-dashed border-[#E4E4F3] rounded-[10px] mt-4 p-5 h-[130px]">
              <button
                className="text-primary mx-auto"
                onClick={handleFileClick}
              >
                <Upload className="h-[56px] w-[56px]" />
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                multiple
                onChange={onImageChange}
                ref={hiddenFileInput}
              />
              <p className="text-[#676565] text-lg text-center mt-1">
                Click here to select a photo
              </p>
            </div>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
              {imageUrls?.map((image, index) => (
                <figure className="w-full h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="food"
                  />
                  <button
                    type="button"
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))}
            </div>
          )}
        </div>

        <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <h3 className="text-lg font-medium">Food Attributes</h3>
            <button
              className="text-primary text-lg"
              type="button"
              onClick={openModal}
            >
              + Add Attributes
            </button>
          </div>
          <AddFoodVariationModal
            show={isOpen}
            onClose={closeModal}
            selected={selected}
            setSelected={setSelected}
            selectedArr={selectedArr}
            setSelectedArr={setSelectedArr}
          />
          {displayArr?.length > 0 ? (
            displayArr.map((attr, i) => (
              <div key={i} className="border-b border-[#E4E4F3] mt-8">
                <p className="text-md my-6">
                  Title : <span className="font-medium">{attr.title}</span>
                </p>
                <div className="grid grid-cols-3 gap-24 px-4">
                  <p className="py-2 text-md">Name</p>
                  <p className="py-2 text-md">Value</p>
                  <p className="py-2 text-md">Action</p>
                </div>
                {attr?.attributes?.map((attribute, i) => (
                  <div key={i} className="grid grid-cols-3 gap-24 px-4">
                    <p className="py-2 font-medium">{attribute.name}</p>
                    <p className="py-2 font-medium">NGN {attribute.amount}</p>
                    <div>
                      <div className="flex justify-between max-w-[26px] py-2">
                        <RemoveFoodAttributeModal
                          id={attribute?.id}
                          myAttributes={attribute}
                          selected={selected}
                          setSelected={setSelected}
                          selectedArr={selectedArr}
                          setSelectedArr={setSelectedArr}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ))
          ) : (
            <div className="text-center mx-auto max-w-2xl py-6">
              <h4 className="text-2xl font-medium mb-2">
                You have no food attributes yet
              </h4>
              <p className="max-w-[473px] mx-auto text-lg mb-4">
                When you create new food attributes, it will be displayed here
              </p>
              <button
                className="text-primary text-lg"
                type="button"
                onClick={openModal}
              >
                + Add Attributes
              </button>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
