import React, { useState, useEffect, useRef, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Button from "../../../../../components/buttons/buttons";
import Input from "../../../../../components/inputs/inputs";
import Location from "../../../../../components/includes/location";
import { Beat } from "../../../../../plugins/spinners.plugin";
import { XIcon } from "@heroicons/react/outline";
import SelectableInput from "../../../../../components/inputs/SelectableInput";
import { cleanInput } from "../../../../../helpers/cleanInput";
import {
  getOutletFromCookies,
  getToken,
  getTeamFromCookies,
} from "../../../../../utils/Auth";
import { getARestaurantAction } from "../../../../../redux/restaurant/restaurantActions";
import { filterProductCategory } from "../../../../../redux/productCatergory/productCategoryAction";
import { getAllTags } from "../../../../../redux/productTags/productTagActions";
import successHandler from "../../../../../handlers/successHandler";
import errorHandler from "../../../../../handlers/errorHandler";

export default function EditRestaurant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const hiddenFileInput = useRef(null);
  const hiddenBannerInput = useRef(null);
  const base_url = process.env.REACT_APP_API_URL;

  const { updating } = useSelector((state) => state.restaurant);
  const { restaurant } = useSelector((state) => state.restaurant);
  const { allCategories } = useSelector((state) => state.productCatergory);
  const { allTags } = useSelector((state) => state.productTag);
  const [images, setImages] = useState(outlet?.restaurant_pictures);
  const [loading, setLoading] = useState(false);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState({});

  const [input, setInput] = useState({
    id: outlet?.id,
    business_id: outlet?.business_id,
    restaurant_email: outlet?.restaurant_email,
    restaurant_name: outlet?.restaurant_name,
    restaurant_phone: outlet?.restaurant_phone,
    restaurant_address: outlet?.restaurant_address,
    restaurant_categories: outlet?.restaurant_categories,
    restaurant_tags: outlet?.restaurant_tags,
    restaurant_country: outlet?.restaurant_country,
    restaurant_city: outlet?.restaurant_city,
    restaurant_operating_status: outlet?.restaurant_operating_status,
    restaurant_state: outlet?.restaurant_state,
  });
  const {
    restaurant_email,
    restaurant_name,
    restaurant_phone,
    restaurant_categories,
    restaurant_tags,
    restaurant_address,
    restaurant_country,
    restaurant_state,
    restaurant_city,
    restaurant_operating_status,
  } = input;

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  useEffect(() => {
    dispatch(getARestaurantAction(outlet?.id));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    dispatch(getAllTags());
    dispatch(filterProductCategory({ type: "restaurant" }, 1));
    // eslint-disable-next-line
  }, []);

  const restaurantCategories = allCategories?.data?.filter((item) => {
    return item?.type === "restaurant";
  });
  const restaurantTags = allTags?.data?.filter((item) => {
    return item?.type === "restaurant";
  });

  function handleRegion(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleAddTags = (value) => {
    let former_tags = [];
    if (input?.restaurant_tags) {
      former_tags = [...input?.restaurant_tags];
    } else {
      former_tags = [];
    }
    let new_tags = [...former_tags, value.name];
    setInput({ ...input, restaurant_tags: new_tags });
  };
  const handleAddCategories = (value) => {
    const former_categories = [...input?.restaurant_categories];
    let new_categories = [...former_categories, value.name];
    setInput({ ...input, restaurant_categories: new_categories });
  };
  const handleRestaurantPhone = (e) => {
    let restaurant_phone = e?.slice(1);
    setInput({ ...input, restaurant_phone: restaurant_phone });
  };

  function onImageChange(e) {
    setImages([...images, ...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };

  const updateInput = useCallback(() => {
    setInput((prevInput) => ({
      ...prevInput,
      restaurant_email: restaurant?.restaurant_email,
      restaurant_name: restaurant?.restaurant_name,
      restaurant_phone: restaurant?.restaurant_phone,
      restaurant_address: restaurant?.restaurant_address,
      restaurant_country: restaurant?.restaurant_country,
      restaurant_city: restaurant?.restaurant_city,
      restaurant_operating_status: restaurant?.restaurant_operating_status,
      restaurant_state: restaurant?.restaurant_state,
      restaurant_categories: restaurant?.restaurant_categories,
      restaurant_tags: restaurant?.restaurant_tags,
    }));
  }, [restaurant, setInput]);

  useEffect(() => {
    updateInput();
  }, [updateInput]);

  useEffect(() => {
    let newImageUrls = [];
    const newImage64 = [];

    images?.length > 0 &&
      images.forEach((image) => {
        if ("File" in window && image instanceof File) {
          newImageUrls.push(URL.createObjectURL(image));
          let reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onloadend = () => {
            newImage64.push(reader.result);
          };
        } else {
          newImageUrls.push(image);
        }
      });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const [fileArray, setFileArray] = useState([]);
  const [bannersUrl, setBannersUrl] = useState([]);

  const handleBannerClick = (event) => {
    event.preventDefault();
    hiddenBannerInput.current.click();
  };
  const uploadMultipleFiles = (e) => {
    setFileArray([...e.target.files]);
  };

  useEffect(() => {
    if (fileArray.length < 1) return;
    const newImageUrls = [];
    fileArray.forEach((image) => newImageUrls.push(URL.createObjectURL(image)));
    setBannersUrl(newImageUrls);
  }, [fileArray]);

  const handleSubmit = async () => {
    let newInput = {
      ...input,
      base64_photos: image64 ? image64 : [],
      banners: fileArray ? fileArray : [],
    };
    cleanInput(newInput);
    setLoading(true);
    axios
      .post(`${base_url}/api/restaurant/update`, newInput, {
        headers: {
          accept: "application/json",
          "Accept-Language": "en-US,en;q=0.8",
          "Content-Type": `multipart/form-data;`,
          Authorization: `Bearer ${getToken()}`,
          Team: `${getTeamFromCookies()}`,
        },
      })
      .then((response) => {
        setLoading(false);
        successHandler(response, true);
        navigate(`/admin/$${outlet?.restaurant_name}/settings/restaurant`);
      })
      .catch((error) => {
        //handle error
        setLoading(false);
        errorHandler(error, true);
      });
  };

  return (
    <div data-test="openingHours-page">
      <div className="bg-white border w-full px-4  mx-auto sm:max-w-6xl sm:px-6 py-4 mt-8">
        <p>
          Manage your restaurant details, make changes to your restaurant
          details here.
        </p>
      </div>

      <div className="pt-8 justify-center items-center mx-auto sm:max-w-6xl">
        <div className=" p-5 w-full bg-white rounded-sm border sm:p-8 ">
          <div className="flex justify-between items-center my-4">
            <h3 className="text-base text-gray-500 font-meduim flex justify-center mb-3  ">
              Restaurant Information
            </h3>
          </div>

          <div className="border-b mb-5"></div>
          <form className="sm:max-w-[570px]">
            <Input.Label
              title={"Restaurant Name"}
              type="text"
              name="restaurant_name"
              id="restaurant_name"
              defaultValue={restaurant_name}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter Restaurant Name"
              onChange={handleChange}
            />
            <Input.Label
              title={"Restaurant Email"}
              type="email"
              name="restaurant_email"
              id="restaurant_email"
              defaultValue={restaurant_email}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter Restaurant Name"
              onChange={handleChange}
            />
            <Input.Phone
              title={"Restaurant Phone Number"}
              name={"restaurant_phone"}
              id={"restaurant_phone"}
              defaultCountry={"NG"}
              value={restaurant_phone}
              htmlFor={"restaurant_phone"}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleRestaurantPhone}
            />
            <Input.Select
              title={"Restaurant Status"}
              type="text"
              name="restaurant_operating_status"
              id="restaurant_operating_status"
              value={restaurant_operating_status}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            >
              <option>
                {restaurant_operating_status ?? "Select operating status"}
              </option>
              <option value={"open"}>Open</option>
              <option value={"closed"}>Closed</option>
            </Input.Select>
            <div>
              <label
                htmlFor="restaurant_categories"
                className="text-sm font-medium text-[#151515]"
              >
                Restaurant Categories
              </label>
              <SelectableInput
                options={restaurantCategories}
                label="restaurant_categories"
                name={"+ Add Category"}
                type="category"
                previousSelected={restaurant_categories}
                selectOption={handleAddCategories}
              />
            </div>

            <div>
              <label
                htmlFor="restaurant_tags"
                className="text-sm font-medium text-[#151515]"
              >
                Restaurant Tags
              </label>
              <SelectableInput
                options={restaurantTags}
                label="restaurant_tags"
                name={"+ Add Tag"}
                type="tag"
                previousSelected={restaurant_tags}
                selectOption={handleAddTags}
              />
            </div>

            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({
                  ...locationList,
                  countryList: value,
                });
              }}
              stateList={(value) => {
                setLocationList({
                  ...locationList,
                  stateList: value,
                });
              }}
              cityList={(value) => {
                setLocationList({ ...locationList, cityList: value });
              }}
            />
            <div className="mt-6">
              <Input.Select
                title={"Select Country"}
                name="restaurant_country"
                id="restaurant_country"
                defaultValue={restaurant_country}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"restaurant_country"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{restaurant_country ?? "Select Country"}</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>
            <div className="mt-6">
              <Input.Select
                title={"Select State"}
                name="restaurant_state"
                id="restaurant_state"
                defaultValue={restaurant_state}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"restaurant_state"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{restaurant_state ?? "Select State"}</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>
            <div className="mt-6">
              <Input.Select
                title={"Select City"}
                name="restaurant_city"
                id="restaurant_city"
                defaultValue={restaurant_city}
                className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                htmlFor={"restaurant_city"}
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>{restaurant_city ?? "Select City"}</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </Input.Select>
            </div>

            <Input.Label
              title={"Restaurant Address"}
              type="text"
              name="restaurant_address"
              id="restaurant_address"
              defaultValue={restaurant_address}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Enter Restaurant Address"
              onChange={handleChange}
            />
          </form>
        </div>
        <div className="border border-[#E4E4F3]  bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Restaurant Images (200px by 200px)
              </h3>
            </div>
            <>
              <button className="text-primary" onClick={handleFileClick}>
                + Upload Image
              </button>
              <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={onImageChange}
                ref={hiddenFileInput}
              />
            </>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-6 gap-8 mt-8">
            {imageUrls?.length > 0 ? (
              imageUrls?.map((image, index) => (
                <figure className="w-auto h-[160px] relative" key={index}>
                  <img
                    src={image}
                    className="w-[160px] h-[160px] object-cover"
                    alt="merchandise"
                  />
                  <button
                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                    onClick={() => removeFile(index)}
                  >
                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                  </button>
                </figure>
              ))
            ) : (
              <p>No Images.</p>
            )}
          </div>
        </div>

        <div className="border border-[#E4E4F3]  bg-white rounded-md py-5 px-8 mt-8">
          <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
            <div>
              <h3 className="text-lg font-medium">
                Restaurant Banner (1440px by 576px)
              </h3>
            </div>
            <>
              <button className="text-primary" onClick={handleBannerClick}>
                + Upload Banner
              </button>
              <input
                type="file"
                hidden
                className="form-control"
                onChange={uploadMultipleFiles}
                ref={hiddenBannerInput}
                multiple
              />
              {/* <input
                type="file"
                className="hidden"
                accept="image/*"
                onChange={onImageChange}
                ref={hiddenFileInput}
              /> */}
            </>
          </div>
          {/* {
            restaurant?.store_banners?.length > 0 ? (
              restaurant?.store_banners.map((image, index) => (
                <figure className="w-full h-[200px] relative" key={index}>
                  <img
                    src={image}
                    className="object-cover w-full h-full"
                    alt="merchandise"
                  />
                </figure>
              ))
            ) : (
              <p>No banners uploaded. Edit restaurant to add Images</p>
            )} */}
          <div className="mt-8">
            {bannersUrl?.length > 0 ? (
              <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
                {bannersUrl.map((url, index) => (
                  <figure className="w-full h-[160px] relative" key={index}>
                    <img
                      src={url}
                      className="object-cover w-[160px] h-[160px]"
                      alt="merchandise"
                    />
                  </figure>
                ))}
              </div>
            ) : (
              <p>No images</p>
            )}
          </div>
        </div>
      </div>

      <div className="flex mx-auto justify-center my-14">
        <Button.Primary
          disabled={updating || loading}
          title={loading ? <Beat color={"#ffffff"} /> : "Edit Restaurant"}
          className={"w-[160px] rounded-sm"}
          onClick={handleSubmit}
        />
      </div>
    </div>
  );
}
