import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
import { Switch } from "@headlessui/react";
import {
  getASubscriptionMerchAction,
  approveSubscriptionMerch,
  unapproveSubscriptionMerch,
} from "../../../../redux/subscriptionMerchandise/subscriptionMerchAction";
import { getSingleStore } from "../../../../redux/stores/storeActions";
import DeleteSubscriptionMerch from "../../../../components/modals/admin/deleteSubscriptionMerch";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ItemDetails() {
  const dispatch = useDispatch();
  const { id } = useParams();
  const [enabled, setEnabled] = useState();

  const { singleStore } = useSelector((state) => state.store);
  let { singleSubscriptionMerch } = useSelector(
    (state) => state.subscriptionMerch
  );

  useEffect(() => {
    if (id) {
      dispatch(getASubscriptionMerchAction(id));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    setEnabled(singleSubscriptionMerch?.management_approved);
  }, [singleSubscriptionMerch?.management_approved]);

  useEffect(() => {
    if (singleSubscriptionMerch?.store_id) {
      dispatch(getSingleStore(singleSubscriptionMerch?.store_id));
    }
    // eslint-disable-next-line
  }, [singleSubscriptionMerch?.store_id]);

  const handleApprove = () => {
    dispatch(approveSubscriptionMerch({ id: singleSubscriptionMerch?.id }));
  };

  const handleUnapprove = () => {
    dispatch(unapproveSubscriptionMerch({ id: singleSubscriptionMerch?.id }));
  };

  return (
    <div data-test="merchandiseDetails-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Package Item Details</h3>
          <DeleteSubscriptionMerch
            id={singleSubscriptionMerch?.id}
            merchandise={singleSubscriptionMerch?.merchandise_name}
            outlet={singleStore?.store_name}
          />
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Item</label>
            <p className="text-[#151515] text-sm">
              {singleSubscriptionMerch?.merchandise_name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Amount</label>
            <p className="text-[#151515] text-sm">
              NGN{" "}
              {numberWithCommas(singleSubscriptionMerch?.merchandise_amount)}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(singleSubscriptionMerch?.created_at).format(
                "MMM DD, YYYY"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              {singleSubscriptionMerch?.management_approved
                ? "Unapprove Package Item"
                : "Approve Package Item"}
            </label>
            <Switch.Group as="div" className="flex">
              <Switch
                checked={enabled}
                onChange={(e) => {
                  setEnabled(e);
                  singleSubscriptionMerch?.management_approved
                    ? handleUnapprove()
                    : handleApprove();
                }}
                className={classNames(
                  enabled ? "bg-primary" : "bg-gray-200",
                  "relative inline-flex mt-1 h-5 w-10 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                )}
              >
                <span
                  aria-hidden="true"
                  className={classNames(
                    enabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none inline-block h-4 w-4 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                  )}
                />
              </Switch>
            </Switch.Group>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Store Details</h3>
        </div>

        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_name}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Flag Count</label>
            <p className="text-[#151515] text-sm">{singleStore?.flag}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>

            {singleStore?.management_approved ? (
              <p className="text-[#151515] text-sm">Approved</p>
            ) : (
              <p className="text-[#151515] text-sm">Unapproved</p>
            )}
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Created On</label>
            <p className="text-[#151515] text-sm">
              {moment(singleStore?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">
              {singleStore?.store_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_state}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_city}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
