import React from "react";
import RestaurantLayout from "../../../../layouts/RestaurantLayout";
import Button from "../../../../components/buttons/buttons";
import FoodImage from "../../../../assets/images/pictures/restaurant-banner.png";
import { useNavigate } from "react-router-dom";

export default function ConfirmMeals() {
  const navigate = useNavigate();
  return (
    <RestaurantLayout>
      <h2 className="text-3xl font-bold border-b-2 mb-3 pb-3">
        Confirm your meals
      </h2>
      <section aria-labelledby="section-1-title">
        <h2 className="sr-only" id="section-1-title">
          Restaurant Plans
        </h2>

        <div className="mt-6">
          {/* Your content */}

          <div className="mb-8 w-full">
            <h2 className="text-lg mb-2 font-medium">Day 1</h2>
            <div className="flex gap-4 items-center">
              <img
                src={FoodImage}
                alt="food"
                className="h-24 w-24 object-cover rounded"
              />
              <div>
                <h5 className="py-2 font-medium">Special Chicken Fried Rice</h5>
                <p>
                  Our very professional and special mouth watering fried rice
                  with shredded...
                </p>
              </div>
              <a href="/" className="text-primary-500">
                Change
              </a>
            </div>
          </div>
          <div className="mb-8 w-full">
            <h2 className="text-lg mb-2 font-medium">Day 2</h2>
            <div className="flex gap-4 items-center">
              <img
                src={FoodImage}
                alt="food"
                className="h-24 w-24 object-cover rounded"
              />
              <div>
                <h5 className="py-2 font-medium">Special Chicken Fried Rice</h5>
                <p>
                  Our very professional and special mouth watering fried rice
                  with shredded...
                </p>
              </div>
              <a href="/" className="text-primary-500">
                Change
              </a>
            </div>
          </div>
          <div className="mb-8 w-full">
            <h2 className="text-lg mb-2 font-medium">Day 3</h2>
            <div className="flex gap-4 items-center">
              <img
                src={FoodImage}
                alt="food"
                className="h-24 w-24 object-cover rounded"
              />
              <div>
                <h5 className="py-2 font-medium">Special Chicken Fried Rice</h5>
                <p>
                  Our very professional and special mouth watering fried rice
                  with shredded...
                </p>
              </div>
              <a href="/" className="text-primary-500">
                Change
              </a>
            </div>
          </div>
          <div className="mb-8 w-full">
            <h2 className="text-lg mb-2 font-medium">Day 4</h2>
            <div className="flex gap-4 items-center">
              <img
                src={FoodImage}
                alt="food"
                className="h-24 w-24 object-cover rounded"
              />
              <div>
                <h5 className="py-2 font-medium">Special Chicken Fried Rice</h5>
                <p>
                  Our very professional and special mouth watering fried rice
                  with shredded...
                </p>
              </div>
              <a href="/" className="text-primary-500">
                Change
              </a>
            </div>
          </div>
          <div className="mb-8 w-full">
            <h2 className="text-lg mb-2 font-medium">Day 5</h2>
            <div className="flex gap-4 items-center">
              <img
                src={FoodImage}
                alt="food"
                className="h-24 w-24 object-cover rounded"
              />
              <div>
                <h5 className="py-2 font-medium">Special Chicken Fried Rice</h5>
                <p>
                  Our very professional and special mouth watering fried rice
                  with shredded...
                </p>
              </div>
              <a href="/" className="text-primary-500">
                Change
              </a>
            </div>
          </div>
        </div>
        <div className="mt-8 text-right">
          <Button.Primary
            title={"Add to cart"}
            className="px-5"
            onClick={() => navigate("/cart")}
          />
        </div>
      </section>
    </RestaurantLayout>
  );
}
