// Application types

const NOTIFIER = {
  GET_PRODUCT_CATEGORIES: "GET_PRODUCT_CATEGORIES",
  STORE_A_NOTIFIER: "STORE_A_NOTIFIER",
  SEARCH_NOTIFIER: "SEARCH_NOTIFIER",
  DELETING: "DELETING",
  DELETE_A_NOTIFIER_GROUP: "DELETE_A_NOTIFIER_GROUP",
  EDIT_A_PRODUCT_CATEGORY: "EDIT_A_PRODUCT_CATEGORY",
  SHOW_A_NOTIFIER_GROUP: "SHOW_A_NOTIFIER_GROUP",
  TEST_NOTIFIER_GROUP_MODEL: "TEST_NOTIFIER_GROUP_MODEL",
  LOADING_NOTIFIER: "LOADING_NOTIFIER",
  RELOAD_PRODUCT_CATEGORY: "RELOAD_PRODUCT_CATEGORY",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
  FILTER_NOTIFIER_GROUP: "FILTER_NOTIFIER_GROUP",
  UPDATING: "UPDATING",
};

export default NOTIFIER;
