import axios from "../plugins/axios.plugin";

class MerchandiseService {
  baseServicePath = "/api/merchandise";

  CreateAMerchandise(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  getAllMerchandises(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  filterMerchandises(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  filterMerchandisesNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
  searchMerchandise(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showMerchandise(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  showMyMerchandises() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  deleteMerchandise(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  editMerchandise(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  approveMerchandiseBusiness(payload) {
    return axios.post(`${this.baseServicePath}/approve/business`, payload);
  }
  unApproveMerchandiseBusiness(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/business`, payload);
  }
  approveMerchandiseManagement(payload) {
    return axios.post(`${this.baseServicePath}/approve/management`, payload);
  }
  unApproveMerchandiseManagement(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payload);
  }
}

export const merchandiseService = new MerchandiseService();
