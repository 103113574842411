import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getTeamFromCookies, getOutletFromCookies } from "../../../utils/Auth";
import { ReactComponent as SETTINGS } from "../../../assets/images/icons/settings.svg";
import { ReactComponent as LOGOUT } from "../../../assets/images/icons/logout.svg";
import { logoutUserLocallyAction } from "../../../redux/auth/unAuthActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function MobileRoutes({
  navigation,
  orders,
  operations,
  reports,
  params,
}) {
  const dispatch = useDispatch();
  const team = getTeamFromCookies();
  const outlet = getOutletFromCookies();
  const outlet_name = outlet?.restaurant_name ?? outlet?.store_name;

  const [admin, setAdmin] = useState(false);

  useEffect(() => {
    if (team === "administrator" || team?.includes("management")) {
      setAdmin(true);
    } else {
      setAdmin(false);
    }
  }, [team]);

  function logoutUser() {
    dispatch(logoutUserLocallyAction());
  }
  return (
    <div>
      <nav className="flex-1 space-y-1 px-2">
        {navigation?.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className={classNames(
              item.current
                ? "bg-gray-100 text-primary"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
              `${item.display ? "block" : "hidden"}`
            )}
          >
            <item.icon
              className={classNames(
                item.current
                  ? "text-gray-500"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-3 flex-shrink-0 h-7 w-7"
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}
      </nav>

      {(team === "administrator" || team.includes("management")) && (
        <h3 className="capitalize px-4 mt-4 mb-3 font-bold">ORDERS</h3>
      )}
      <nav className="flex-1 space-y-1 px-2">
        {orders.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className={classNames(
              item.current
                ? "bg-gray-100 text-primary"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
              `${item.display ? "block" : "hidden"}`
            )}
          >
            <item.icon
              className={classNames(
                item.current
                  ? "text-gray-500"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-3 flex-shrink-0 h-7 w-7"
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}
      </nav>

      {(team === "administrator" ||
        team === "management" ||
        team === "management_accountant" ||
        team === "management_supervisor") && (
        <h3 className="capitalize px-4 mt-4 mb-3 font-bold">OPERATIONS</h3>
      )}
      <nav className="flex-1 space-y-1 px-2">
        {operations.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className={classNames(
              item.current
                ? "bg-gray-100 text-primary"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
              `${item.display ? "block" : "hidden"}`
            )}
          >
            <item.icon
              className={classNames(
                item.current
                  ? "text-gray-500"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-3 flex-shrink-0 h-7 w-7"
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}
      </nav>

      {(team === "administrator" ||
        team === "management" ||
        team === "management_accountant") && (
        <h3 className="capitalize px-4 mt-4 mb-3 font-bold">REPORTS</h3>
      )}
      <nav className="flex-1 space-y-1 px-2 pb-4">
        {reports.map((item) => (
          <a
            key={item.href}
            href={item.href}
            className={classNames(
              item.current
                ? "bg-gray-100 text-primary"
                : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
              "group flex items-center px-2 py-2 text-sm font-medium rounded-md",
              `${item.display ? "block" : "hidden"}`
            )}
          >
            <item.icon
              className={classNames(
                item.current
                  ? "text-gray-500"
                  : "text-gray-400 group-hover:text-gray-500",
                "mr-3 flex-shrink-0 h-7 w-7"
              )}
              aria-hidden="true"
            />
            {item.name}
          </a>
        ))}
      </nav>

      <div className="px-2">
        <a
          href={admin ? "/admin/settings" : `/admin/${outlet_name}/settings`}
          className={classNames(
            params["*"].includes("settings")
              ? "bg-gray-100 text-primary"
              : "text-gray-600 hover:bg-gray-50 hover:text-gray-900",
            "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
          )}
        >
          <SETTINGS
            className={classNames(
              params["*"].includes("settings")
                ? "text-gray-500"
                : "text-gray-400 group-hover:text-gray-500",
              "mr-3 flex-shrink-0 h-7 w-7"
            )}
            aria-hidden="true"
          />
          Settings
        </a>
        <span
          className="flex gap-1 px-2 py-2 text-[#4D4D4D] cursor-pointer text-sm font-medium items-center"
          onClick={logoutUser}
        >
          <LOGOUT className=" h-7 w-7 text-gray-400 hover:text-gray-500 mr-3" />{" "}
          Log Out
        </span>
      </div>
    </div>
  );
}
