import React, { useState, useEffect } from "react";
import { Switch } from "@headlessui/react";
import Input from "../../../components/inputs/inputs";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

function replaceUnderscoresWithSpaces(inputString) {
  return inputString.replace(/_/g, " ");
}

export default function DeliveryRange({ period, settings, data, setData }) {
  const [enabled, setEnabled] = useState(false);

  useEffect(() => {
    if (settings?.package_delivery_periods[period]?.status === "available") {
      setEnabled(true);
    }
  }, [settings, period]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    // Create a copy of the data object
    const updatedPackageData = { ...data };
    updatedPackageData.package_delivery_periods[period][name] = value;
    // Update the state with the modified object
    setData(updatedPackageData);
  };

  const handleSwitch = (e) => {
    // Create a copy of the data object
    const updatedPackageData = { ...data };

    // Update the desired value within the copied object
    e === true
      ? (updatedPackageData.package_delivery_periods[period].status =
          "available")
      : (updatedPackageData.package_delivery_periods[period].status =
          "unavailable");

    // Update the state with the modified object
    setData(updatedPackageData);
  };

  return (
    <div className="mt-6">
      <label className="block text-sm font-medium text-[#151515] capitalize">
        {replaceUnderscoresWithSpaces(period)} delivery Time Range
      </label>

      <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 sm:items-center mb-4 mt-2">
        <Switch.Group as="div" className="flex gap-4 items-center">
          <Switch
            checked={enabled}
            onChange={(e) => {
              setEnabled(e);
              handleSwitch(e);
            }}
            className={classNames(
              enabled ? "bg-primary" : "bg-gray-200",
              "relative inline-flex h-5 sm:h-6 w-10 sm:w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-4 sm:h-5 w-4 sm:w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
          <p className="text-lg font-medium text-primary">
            {enabled ? "Open" : "Close"}
          </p>
        </Switch.Group>

        {enabled && (
          <div className="grid grid-cols-2 gap-4 w-full">
            <Input.TimePicker
              title={"Open Time"}
              name="start_time"
              id="start_time"
              defaultValue={
                settings?.package_delivery_periods[period]?.start_time
              }
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />
            <Input.TimePicker
              title={"Close Time"}
              name="end_time"
              id="end_time"
              defaultValue={
                settings?.package_delivery_periods[period]?.end_time
              }
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              onChange={handleChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}
