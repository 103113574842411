import { paymentService } from "../../services/payment.service";
import paymentType from "./paymentTypes";
import errorHandler from "../../handlers/errorHandler";

/**
 * Get payment providers
 **/

export const getAllPaymentProviders = (page) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: true });
    const response = await paymentService.getAllPaymentProviders(page);
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: paymentType["ALL_PAYMENT_PROVIDERS"], payLoad: response });
  } catch (error) {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
  }
};

/**
 * Get my payments
 **/

export const getMyPayments = (page) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: true });
    const response = await paymentService.getMyPayments(page);
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: paymentType["MY_PAYMENTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
  }
};

/**
 * Pay for me
 **/

export const payForMe = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: true });
    const response = await paymentService.payForMe(payLoad);
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: paymentType["PAY_FOR_ME"], payLoad: response });
    return response;
  } catch (error) {
    errorHandler(error, true);
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
  }
};

/**
 * Show a payment
 **/

export const showAPayment = (id) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: true });
    const response = await paymentService.showAPayment(id);
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: paymentType["SINGLE_PAYMENT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: paymentType["LOADING_AUTH"], payLoad: false });
  }
};

/**
 * Verify a payment
 **/

export const verifyPayment = (provider, payLoad) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["VERIFYING"], payLoad: true });
    const response = await paymentService.verifyPayment(provider, payLoad);
    dispatch({ type: paymentType["VERIFYING"], payLoad: false });
    return response;
  } catch (error) {
    dispatch({ type: paymentType["VERIFYING"], payLoad: false });
  }
};

/**
 * Filter a payment
 **/

export const filterPayment = (payLoad, pagex) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["FILTERING_PAYMENT"], payLoad: true });
    const response = await paymentService.filterPayment(payLoad, pagex);
    dispatch({ type: paymentType["FILTERING_PAYMENT"], payLoad: false });
    dispatch({ type: paymentType["FILTER_PAYMENT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: paymentType["FILTERING_PAYMENT"], payLoad: false });
  }
};

/**
 * Get all restaurants
 **/

export const getAllPaymentsNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["DOWNLOADING"], payLoad: true });
    const response = await paymentService.getAllPaymentsNoPagination(payload);
    dispatch({ type: paymentType["DOWNLOADING"], payLoad: false });
    dispatch({
      type: paymentType["ALL_PAYMENT_NO_PAGINATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: paymentType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search payments
 **/

export const searchPayments = (params) => async (dispatch) => {
  try {
    dispatch({ type: paymentType["LOADING_SEARCH"], payLoad: true });
    const response = await paymentService.searchPayments(params);
    dispatch({ type: paymentType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: paymentType["SEARCH_PAYMENT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: paymentType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};
