import axios from "../plugins/axios.plugin";

class PaymentService {
  baseServicePath = "/api/payment";

  getAllPaymentProviders() {
    return axios.get(`${this.baseServicePath}/show/provider`);
  }
  getMyPayments(page) {
    return axios.get(`${this.baseServicePath}/me?properties=1&page=${page}`);
  }
  showAPayment(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
  verifyPayment(provider, payLoad) {
    return axios.post(`${this.baseServicePath}/verify/${provider}`, payLoad);
  }
  payForMe(payLoad) {
    return axios.post(`api/public/payment/pfm?properties=1`, payLoad);
  }
  filterPayment(payLoad, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payLoad
    );
  }
  getAllPaymentsNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }
  searchPayments(params) {
    return axios.post(`${this.baseServicePath}/search/index`, params);
  }
}
export const paymentService = new PaymentService();
