import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { history } from "../../../helpers/history";
import { Beat } from "../../../plugins/spinners.plugin";
import { forgotPasswordAction } from "../../../redux/auth/authActions";

export default function ForgotPasswordForm() {
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.auth);
  const [message, setMessage] = useState(false);
  const [input, setInput] = useState({
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await dispatch(forgotPasswordAction(input));
      if (response) {
        // history("/reset-link");
        setMessage(true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      {message && (
        <div className="bg-green-400 px-4 py-3">
          <p>Check your email for a link to reset your password</p>
        </div>
      )}
      <div className="mt-4">
        <label
          htmlFor="email"
          className="block text-sm font-bold text-gray-700"
        >
          Email Address
        </label>
        <input
          type="text"
          name="email"
          id="email"
          className="mt-3 h-14 py-4 rounded-lg px-2 border border-gray-300 focus:border-primary focus:outline-none sm:text-md w-full"
          placeholder="Enter Email Address"
          onChange={(e) => handleChange(e)}
        />
      </div>
      <div className="mt-1 mb-6">
        <button
          type="submit"
          disabled={isLoading}
          className="mt-5 w-full inline-flex justify-center h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-bold text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:mt-6 sm:text-sm"
        >
          {isLoading ? <Beat color={"#ffffff"} /> : "Reset My Password"}
        </button>
      </div>
    </form>
  );
}
