import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { businessService } from "../../services/business.service";
import businessType from "./businessTypes";

/**
 * Get my Business
 **/

export const getMyBusiness = () => async (dispatch) => {
  try {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: true });
    const response = await businessService.getMyBusiness();
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    dispatch({ type: businessType["MY_BUSINESS"], payLoad: response });
  } catch (error) {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    errorHandler(error, true);
  }
};
export const getABusiness = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: true });
    const response = await businessService.getABusiness(payLoad);
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    dispatch({ type: businessType["A_BUSINESS"], payLoad: response });
  } catch (error) {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const editABusiness = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: true });
    const response = await businessService.editMyBusiness(payLoad);
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    dispatch({ type: businessType["EDIT_BUSINESS"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    errorHandler(error, true);
  }
};
export const getAllBusinesses = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: true });
    const response = await businessService.getAllBusinesses(payLoad);

    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    dispatch({
      type: businessType["GET_ALL_BUSINESSES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: businessType["LOADING_BUSINESS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const approveABusiness = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["APPROVING"], payLoad: true });
    const response = await businessService.approveBusiness(payLoad);
    dispatch({ type: businessType["APPROVING"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: businessType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const unApproveABusiness = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["APPROVING"], payLoad: true });
    const response = await businessService.unapproveBusiness(payLoad);
    dispatch({ type: businessType["APPROVING"], payLoad: false });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: businessType["APPROVING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const searchBusinessAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["LOADING_SEARCH"], payLoad: true });
    const response = await businessService.searchBusiness(payLoad);
    dispatch({ type: businessType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: businessType["SEARCH_BUSINESS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: businessType["LOADING_SEARCH"], payLoad: false });
  }
};

export const filterBusinessAction = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: businessType["FILTERING"], payLoad: true });
    const response = await businessService.filterBusiness(payLoad, page);
    dispatch({ type: businessType["FILTERING"], payLoad: false });
    dispatch({ type: businessType["FILTER_BUSINESS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: businessType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};
export const filterBusinessActionNoPage = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: businessType["FILTERING"], payLoad: true });
    const response = await businessService.filterBusinessNoPage(payLoad);
    dispatch({ type: businessType["FILTERING"], payLoad: false });
    dispatch({
      type: businessType["FILTER_BUSINESS_NOPAGE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: businessType["FILTERING"], payLoad: false });
  }
};
