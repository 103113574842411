// Import Swiper React components
import React, { useCallback } from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavButton } from "../buttons/NavButton";

SwiperCore.use([Navigation, Autoplay]);

export const CategoryNav = ({ categoryRef }) => {
  const handlePrev = useCallback(() => {
    if (!categoryRef?.current) return;
    categoryRef?.current.swiper.slidePrev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = useCallback(() => {
    if (!categoryRef?.current) return;
    categoryRef?.current.swiper.slideNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NavButton handleNext={handleNext} handlePrev={handlePrev} />;
};

export default function CategorySlider({ children, categoryRef }) {
  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      ref={categoryRef}
      spaceBetween={15}
      slidesPerView={6}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        300: {
          slidesPerView: 2,
        },
        600: {
          slidesPerView: 3,
        },
        800: {
          slidesPerView: 4,
        },
        1000: {
          items: 6,
        },
      }}
    >
      {children?.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
}
