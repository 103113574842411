import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { onboardService } from "./../../services/onBoard.service";
import onboardType from "./onBoardingTypes";

/**
 * 
 * @param {Object} payLoad {
 business_email:pink@yandex.com
business_name:pinkHills
business_phone:07034738829
business_city:lagos
business_address:12 victoria island lane
business_state:Lagos
business_country:Nigeria
business_zip:100110
business_description:A big small company
business_type:resturant}
 * @returns 
 */

export const onboardBusiness = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: true });
    const response = await onboardService.onboardBusiness({ ...payLoad });
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: false });
    dispatch({ type: onboardType["ONBOARD_BUSINESS"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * 
 * @param {object} payLoad {user_name:orange
user_email:orange@gmail.com
user_password:password
user_password_confirmation:password
user_phone:08021415578
user_description:I am a human being
user_birth_date:0000-04-17
user_birth_year:1997
business_id:bc4f908e-1e4f-4493-88c7-c1c00e756d86
employee_name:Geeber
employee_job_title:rider
employee_job_description:delivers only ice cream and cake, sometimes meatpie
employee_location_latitude:16.972741
employee_location_longitude:12.378865
employee_phone:07034738829
employee_address:12 victoria island lane
employee_city:lagos
employee_state:Lagos
employee_country:Nigeria
employee_zip:100110 }
 * @returns 
 */

export const onboardEmployee = (payLoad) => async (dispatch) => {
  try {
    const response = await onboardService.onboardEmployee({ ...payLoad });
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: false });
    dispatch({ type: onboardType["ONBOARD_EMPLOYEE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * 
 * @param {object} payLoad 
 * user_name:yellow
user_email:yellow@gmail.com
user_password:yellowyellow
user_password_confirmation:yellowyellow
user_phone:03021415578
user_description:I am a human being
user_birth_date:1997-09-21
user_birth_year:1997
user_location_latitude:16.972741
user_location_longitude:12.378865
user_address:12 victoria island lane
user_city:lagos
user_state:Lagos
user_country:Nigeria
user_zip:100110
invite_code:FHS2782
 * @returns 
 */
export const onboardInvite = (payLoad) => async (dispatch) => {
  try {
    const response = await onboardService.onboardInvite({ ...payLoad });
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: false });
    dispatch({ type: onboardType["ONBOARD_INVITE"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: onboardType["LOADING_ONBOARD"], payLoad: false });
    errorHandler(error, true);
  }
};
