import authType from "./restaurantOrderTypes";

const initialState = {
  isLoading: false,
  updating: false,
  approving: false,
  searching: false,
  filtering: false,
  marking: false,
  downloading: false,
  shipping: false,
  allOrders: {},
  allOrdersNoPagination: {},
  myOrders: [],
  order: {},
  search: [],
};

export default function restaurantOrderReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };

    case authType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case authType.MARKING:
      return {
        ...state,
        marking: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.SHIPPING:
      return {
        ...state,
        shipping: action.payLoad,
      };

    case authType.ALL_RESTAURANT_ORDERS:
      return {
        ...state,
        allOrders: action.payLoad?.data,
      };

    case authType.ALL_RESTAURANTS_NO_PAGINATION:
      return {
        ...state,
        allOrdersNoPagination: action.payLoad?.data,
      };

    case authType.SINGLE_RESTAURANT_ORDER:
      return {
        ...state,
        order: action.payLoad?.data,
      };

    case authType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_RESTAURANT_ORDERS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case authType.MY_RESTAURANT_ORDERS:
      return {
        ...state,
        myOrders: action.payLoad?.data,
      };

    case authType.FILTER_RESTAURANT_ORDERS:
      return {
        ...state,
        allOrders: action.payLoad?.data,
      };
    default:
      return state;
  }
}
