import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import Warning from "../../../assets/images/icons/warning.svg";
import { cleanInput } from "../../../helpers/cleanInput";
import { getUserFromCookies } from "../../../utils/Auth";
import { filterFoodAction } from "../../../redux/food/foodActions";
import { getOutletFromCookies, getTeamFromCookies } from "../../../utils/Auth";
import restaurantType from "../../../redux/restaurant/restaurantTypes";
import { searchRestaurantsAction } from "../../../redux/restaurant/restaurantActions";

export default function FilterFoodDropDown({
  isFilterOpen,
  setFilterState,
  payLoad,
}) {
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const team = getTeamFromCookies();
  const { search } = useSelector((state) => state.restaurant);
  const { isLoading, filtering } = useSelector((state) => state.food);
  const { allCategories } = useSelector((state) => state.productCatergory);

  const [restautantName, setRestautantName] = useState([]);
  const [searchData, setsearchData] = useState([]);
  const [errorMsg, setErrorMsg] = useState(false);

  const [data, setData] = useState({
    food_name: "",
    food_amount: "",
    food_categories: [],
    food_operating_status: "",
    restaurant_id: "",
  });
  const {
    food_name,
    food_amount,
    food_categories,
    food_operating_status,
    restaurant_id,
  } = data;

  const user = getUserFromCookies();
  let business = Object.keys(user?.groups)[0];

  useEffect(() => {
    if (search) {
      setsearchData(search);
    }
  }, [search]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "food_categories") {
      setData({ ...data, food_categories: [value] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleSearch = (event) => {
    event.preventDefault();
    const { value } = event.target;
    setRestautantName(value);
    if (value.length >= 3) {
      dispatch(
        searchRestaurantsAction({
          search: value,
        })
      );
    } else if (value.length <= 3) {
      let search = { data: [] };
      dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: search });
    }
  };

  function selectRestaurant(result) {
    setData((data) => ({
      ...data,
      restaurant_id: result.id ?? "",
    }));

    setRestautantName(result.restaurant_name);

    let search = { data: [] };
    dispatch({ type: restaurantType["SEARCH_RESTAURANT"], payLoad: search });
  }

  const handleReset = async () => {
    setData({
      food_name: "",
      food_amount: "",
      food_categories: [],
      food_operating_status: "",
      restaurant_id: "",
    });

    try {
      const response = await dispatch(filterFoodAction(payLoad, 1));
      if (response?.status === "success") {
        setFilterState(!isFilterOpen);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleSubmit = async () => {
    cleanInput(data);
    let newData = { ...data, ...payLoad };
    if (team !== "administrator") {
      newData.business_id = business;
      newData.restaurant_id = outlet?.id;
    }
    if (
      food_name ||
      food_amount ||
      food_categories.length > 0 ||
      food_operating_status ||
      restaurant_id
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterFoodAction(newData, 1));
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div data-test="filter-food-dropdown">
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1 gap-2">
          <div>
            <Input.Label
              title={"Restaurant Name"}
              type="text"
              name="restaurant_id"
              id="restaurant_id"
              value={restautantName}
              autoComplete="off"
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
              placeholder="Search restaurant"
              onChange={handleSearch}
            />

            {searchData &&
              Array.isArray(searchData) &&
              searchData?.map((result, index) => (
                <div
                  className="p-2 px-1 cursor-pointer hover:border-b"
                  id={result.id}
                  key={index}
                  onClick={() => selectRestaurant(result)}
                >
                  <div>
                    <h6 className="text-sm font-meduim">
                      Name: {result?.restaurant_name}
                    </h6>
                  </div>
                </div>
              ))}
          </div>

          <Input.Label
            title={"Food Name"}
            type="text"
            name="food_name"
            id="food_name"
            value={food_name}
            autoComplete="off"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter food name"
            onChange={handleChange}
          />

          <Input.Label
            title={"Food Price"}
            type="number"
            name="food_amount"
            id="food_amount"
            value={food_amount}
            autoComplete="off"
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter food amount"
            onChange={handleChange}
          />

          <Input.Select
            title={"Category"}
            name="food_categories"
            id="food_categories"
            defaultValue={food_categories?.[0]}
            htmlFor={"food_categories"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="E.g: 200"
            onChange={handleChange}
          >
            <option>Select Category</option>
            {allCategories?.data?.length > 0 &&
              allCategories?.data?.map(
                (category, i) =>
                  category.type === "restaurant" && (
                    <option key={i} value={category?.name}>
                      {category?.name}
                    </option>
                  )
              )}
          </Input.Select>

          <Input.Select
            title={"Status"}
            name="food_operating_status"
            id="food_operating_status"
            htmlFor={"food_operating_status"}
            defaultValue={food_operating_status}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="E.g: 200"
            onChange={handleChange}
          >
            <option>Select Status</option>
            <option value={"available"}>Available</option>
            <option value={"unavailable"}>Unavailable</option>
          </Input.Select>
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={isLoading}
            title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </div>
  );
}
