const COUPON = {
  GET_ALL_COUPON: "GET_ALL_COUPON",
  FILTER_COUPON: "FILTER_COUPON",
  FILTER_COUPON_NOPAGE: "FILTER_COUPON_NOPAGE",
  SEARCH_COUPON: "SEARCH_COUPON",
  STORE_COUPON: "STORE_COUPON",
  SHOW_COUPON: "SHOW_COUPON",
  SHOW_MY_COUPON: "SHOW_MY_COUPON",
  EDIT_COUPON: "EDIT_COUPON",
  DELETE_COUPON: "DELETE_COUPON",
  LOADING_COUPON: "LOADING_COUPON",
  LOADING_SEARCH: "LOADING_SEARCH",
  DELETING: "DELETING",
  FILTERING: "FILTERING",
};

export default COUPON;
