// Application types

const PUBLICSTORE = {
  LOADING_PUBLIC_STORE: "LOADING_PUBLIC_STORE",
  LOADING_MORE_STORES: "LOADING_MORE_STORES",
  ALL_PUBLIC_STORES: "ALL_PUBLIC_STORES",
  SINGLE_PUBLIC_STORE: "SINGLE_PUBLIC_STORE",
  SEARCH_PUBLIC_STORE: "SEARCH_PUBLIC_STORE",
  MY_PUBLIC_STORES: "MY_PUBLIC_STORES",
  FILTER_PUBLIC_STORE: "FILTER_PUBLIC_STORE",
  MORE_PUBLIC_STORES: "MORE_PUBLIC_STORES",
};

export default PUBLICSTORE;
