import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { applicationService } from "../../services/application.service";
import applicationType from "./applicationType";

export const createNewApplication = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: applicationType["LOADING_APPLICATION_SETTINGS"],
      payLoad: true,
    });
    const response = await applicationService.createApplicationSettings(
      payLoad
    );

    dispatch({
      type: applicationType["CREATE_APPLICATION_SETTINGS"],
      payLoad: response,
    });
    dispatch({
      type: applicationType["LOADING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: applicationType["LOADING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
export const updateApplication = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: applicationType["UPDATING_APPLICATION_SETTINGS"],
      payLoad: true,
    });
    const response = await applicationService.updateApplicationSettings(
      payLoad
    );

    dispatch({
      type: applicationType["UPDATE_APPLICATION_SETTINGS"],
      payLoad: response,
    });
    dispatch({
      type: applicationType["UPDATING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: applicationType["UPDATING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const showApplication = () => async (dispatch) => {
  try {
    dispatch({
      type: applicationType["LOADING_APPLICATION_SETTINGS"],
      payLoad: true,
    });
    const response = await applicationService.showApplicationSettings();
    dispatch({
      type: applicationType["LOADING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    dispatch({
      type: applicationType["SHOW_APPLICATION_SETTINGS"],
      payLoad: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: applicationType["LOADING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

export const enhanceApplication = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: applicationType["UPDATING_APPLICATION_SETTINGS"],
      payLoad: true,
    });
    const response = await applicationService.enhanceApplicationSettings(
      payLoad
    );
    dispatch({
      type: applicationType["ENHANCE_APPLICATION_SETTINGS"],
      payLoad: response,
    });
    dispatch({
      type: applicationType["UPDATING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: applicationType["UPDATING_APPLICATION_SETTINGS"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
