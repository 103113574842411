import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Pending from './tabs/pending';
import Success from './tabs/success';
import Failure from './tabs/failure';

const tabs = [
  { name: "Pending", href: "pending" },
  { name: "Success", href: "success" },
  { name: "Failure", href: "failure" },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Payments() {
  const DEFAULT_ACTIVE_TAB = "pending";
  const navigate = useNavigate();
  const { active_tab } = useParams();

  useEffect(() => {
    if (!active_tab) {
      navigate(`/admin/payments/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/payments/${tab.href}/1`);
    }
  };

  return (
    <div data-test="restaurant-page">
      {/* <div className="mb-8">
        <h1 className="text-3xl font-semibold text-gray-900">Restaurants</h1>
      </div> */}
      <div className="sm:block">
        <div className="border-b border-gray-200">
          <nav
            className="-mb-px flex space-x-8 overflow-x-auto"
            aria-label="Tabs"
          >
            {tabs.map((tab) => (
              <button
                key={tab.name}
                onClick={() => {
                  toggle(tab);
                }}
                className={classNames(
                  tab.href === active_tab
                    ? "border-primary text-primary font-bold"
                    : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                  "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                )}
                aria-current={tab.href === active_tab ? "page" : undefined}
              >
                {tab.name}
              </button>
            ))}
          </nav>
        </div>
      </div>

      <div>{active_tab === "pending" && <Pending />}</div>
      <div>{active_tab === "success" && <Success />}</div>
      <div>{active_tab === "failure" && <Failure />}</div>
    </div>
  );
}
