// Import Swiper React components
import React, { useCallback } from "react";
import SwiperCore, { Navigation, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { NavButton } from "../buttons/NavButton";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

SwiperCore.use([Navigation,Autoplay]);

export const CatalogueNav = ({ catalogueRef }) => {
  const handlePrev = useCallback(() => {
    if (!catalogueRef?.current) return;
    catalogueRef?.current.swiper.slidePrev();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = useCallback(() => {
    if (!catalogueRef?.current) return;
    catalogueRef?.current.swiper.slideNext();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <NavButton handleNext={handleNext} handlePrev={handlePrev} />;
};

const CatalogueSlider = ({ children, catalogueRef }) => {
  let result = children.filter(function (e) {
    return e.length;
  });

  var newArray = Array.prototype.concat.apply([], result);

  return (
    <Swiper
      modules={[Navigation, Autoplay]}
      ref={catalogueRef}
      spaceBetween={20}
      slidesPerView={4}
      breakpoints={{
        0: {
          slidesPerView: 1,
        },
        500: {
          slidesPerView: 2,
        },
        800: {
          slidesPerView: 3,
        },
        1000: {
          items: 4,
        },
      }}
    >
      {newArray.map((child, index) => (
        <SwiperSlide key={index}>{child}</SwiperSlide>
      ))}
    </Swiper>
  );
};

export default CatalogueSlider;
