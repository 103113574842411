import authType from "./subscriptionFoodTypes";

const initialState = {
  isLoading: false,
  searching: false,
  filtering: false,
  deleting: false,
  downloading: false,
  allSubscriptionFoods: { data: [] },
  search: {},
  singleSubscriptionFood: {},
};

export default function subscriptionFoodReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };

    case authType.ALL_FOOD_SUBSCRIPTIONS:
      return {
        ...state,
        allSubscriptionFoods: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.LOADING_SUBSCRIPTION:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.SEARCH_FOOD_SUBSCRIPTIONS:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case authType.ADD_SUBSCRIPTION_ITEM:
      const prevItems = state?.allSubscriptionFoods;
      let newItems;
      if (prevItems?.data?.length > 0) {
        newItems = {
          ...state.allSubscriptionFoods,
          data: [...state.allSubscriptionFoods?.data, action.payLoad?.data],
        };
      } else {
        newItems = action.payLoad?.data;
      }
      return {
        ...state,
        allSubscriptionFoods: newItems,
      };

    case authType.FILTER_FOOD_SUBSCRIPTIONS:
      return {
        ...state,
        allSubscriptionFoods: action.payLoad?.data,
      };

    case authType.SINGLE_SUBSCRIPTION_FOOD:
      return {
        ...state,
        singleSubscriptionFood: action.payLoad?.data,
      };

    case authType.DELETE_SUBSCRIPTION_FOOD:
      return {
        ...state,
        singleSubscriptionFood: action.payLoad?.data,
      };

    case authType.APPROVE_SUBSCRIPTION_FOOD:
      let approved = {
        ...state.singleSubscriptionFood,
        management_approved: true,
      };
      return {
        ...state,
        singleSubscriptionFood: approved,
      };

    case authType.UNAPPROVE_SUBSCRIPTION_FOOD:
      let unapproved = {
        ...state.singleSubscriptionFood,
        management_approved: false,
      };
      return {
        ...state,
        singleSubscriptionFood: unapproved,
      };

    default:
      return state;
  }
}
