import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../components/buttons/buttons";
import Input from "../../../components/inputs/inputs";
import Location from "../../../components/includes/location";
import { onboardBusiness } from "../../../redux/onBoarding/onBoardingAction";
import { logoutUserLocallyAction } from "../../../plugins/axios.plugin";
import {
  // getAllCategories,
  filterProductCategory,
} from "../../../redux/productCatergory/productCategoryAction";
import { filterProductTag } from "../../../redux/productTags/productTagActions";
import { history } from "../../../helpers/history";

export default function CreateStoreBusiness() {
  const [active_tab, setActive_tab] = useState("create business");

  const dispatch = useDispatch();

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const { allCategories } = useSelector((state) => state.productCatergory);
  const storeCategories = allCategories?.data?.filter((item) => {
    return item?.type === "store";
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([
    {
      business_email: "",
      business_name: "",
      business_city: "",
      business_address: "",
      business_state: "",
      business_country: "",
      business_description: "",
      business_type: "",
      store_email: "",
      store_name: "",
      store_address: "",
      store_city: "",
      store_state: "",
      store_country: "",
      store_description: "",
      store_categories: [],
    },
  ]);
  const {
    business_email,
    business_name,
    business_type,
    business_city,
    business_address,
    business_state,
    business_country,
    business_description,
    store_name,
    store_email,
    store_address,
    store_city,
    store_state,
    store_country,
    store_description,
    store_categories,
  } = data;

  useEffect(() => {
    // dispatch(getAllCategories());
    dispatch(filterProductTag({ type: "store" }, 1));
    dispatch(filterProductCategory({ type: "store" }, 1));
    // eslint-disable-next-line
  }, []);

  let [business_phone, setBusinessPhone] = useState("");
  let [store_phone, setStorePhone] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
    event.preventDefault();
    if (name === "store_categories") {
      setData({ ...data, store_categories: [value] });
    } else {
      setData((data) => ({ ...data, [name]: value }));
    }
  };

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  function categories() {
    let dataToMap = storeCategories;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((category, i) => {
        let singleRowArray = [
          <option key={i} value={category.name}>
            {category.name}
          </option>,
        ];
        outputArray.push(singleRowArray);

        return true;
      });

      return outputArray;
    }
  }
  business_phone = business_phone?.slice(1);
  store_phone = store_phone?.slice(1);
  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (
      business_name &&
      business_phone &&
      business_email &&
      business_type &&
      business_city &&
      business_state &&
      business_address &&
      business_country
    ) {
      // dispatch action to create data
      const response = await dispatch(
        onboardBusiness({
          ...data,
          business_phone,
          store_phone,
        })
      );
      if (response === true) {
        logoutUserLocallyAction();
        history("/success");
      }
    }
  };
  const handleNext = () => {
    setSubmitted(true);
    if (
      business_name &&
      business_phone &&
      business_email &&
      business_type &&
      business_city &&
      business_state &&
      business_address &&
      business_country
    ) {
      setActive_tab("store details");
    }
  };
  const handleBack = () => {
    setActive_tab("create business");
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };
  return (
    <div>
      {active_tab === "create business" && (
        <div>
          <div
            className="pt-4 sm:pt-2 sm:flex items-center mx-auto"
            data-test="businessComponent"
          >
            <div className=" max-w-4xl mx-auto mt-10">
              <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
                <form name="form " id="business-form" onSubmit={handleSubmit}>
                  <div className="border-b-2 py-4 mb-6">
                    <p className=" text-base font-medium text-gray-800 flex justify-center ">
                      Create Business
                    </p>
                  </div>
                  <div className="sm:px-10">
                    <div
                      className={
                        "mt-4" +
                        (submitted && business_name ? " border-red-500" : "")
                      }
                    >
                      <Input.Label
                        title={"Business Name *"}
                        htmlFor={"business_name"}
                        value={business_name}
                        type={"text"}
                        name={"business_name"}
                        onChange={handleChange}
                        placeholder={"Enter business name"}
                      />
                      {submitted && !business_name && (
                        <div className="text-xs text-red-500">
                          Business name is required
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && business_email ? " border-red-500" : "")
                      }
                    >
                      <Input.Label
                        title={"Business Email *"}
                        htmlFor={"business_email"}
                        value={business_email}
                        type={"email"}
                        name={"business_email"}
                        onChange={handleChange}
                        placeholder={"Enter business email"}
                      />
                      {submitted && !business_email && (
                        <div className="text-xs text-red-500">
                          Business email is required
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && business_phone ? " border-red-500" : "")
                      }
                    >
                      <Input.Phone
                        defaultCountry="NG"
                        title={"Business phone"}
                        htmlFor={"business_phone"}
                        value={business_phone}
                        onChange={setBusinessPhone}
                      />
                      {submitted && !business_phone && (
                        <div className="text-xs text-red-500">
                          Business phone is required
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && business_type ? " border-red-500" : "")
                      }
                    >
                      <label
                        htmlFor="business_type"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        Business Type *
                      </label>
                      <select
                        name="business_type"
                        value={business_type}
                        className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFFF] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={handleChange}
                      >
                        <option>{business_type ?? "Select type"}</option>
                        {/* <option value="restaurant">Restaurant</option> */}
                        <option value="store">Store</option>
                      </select>
                      {submitted && !business_type && (
                        <div className="text-xs text-red-500">
                          Business type is required
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && business_address ? " border-red-500" : "")
                      }
                    >
                      <Input.Label
                        title={"Business Address *"}
                        htmlFor={"business_address"}
                        type={"text"}
                        value={business_address}
                        name={"business_address"}
                        placeholder={"Enter business address"}
                        onChange={handleChange}
                      />
                      {submitted && !business_address && (
                        <div className="text-xs text-red-500">
                          Business address is required
                        </div>
                      )}
                    </div>

                    <div
                      className={
                        "mt-4" +
                        (submitted && business_description
                          ? " border-red-500"
                          : "")
                      }
                    >
                      <Input.TextArea
                        title={"Business Description "}
                        htmlFor={"business_description"}
                        value={business_description}
                        type={"text"}
                        name={"business_description"}
                        placeholder={"Describe your business to us"}
                      />
                    </div>
                    {/* Here, we utilize the location component to get the location data */}
                    <Location
                      country_id={locationId.country_id}
                      state_id={locationId.state_id}
                      city_id={locationId.city_id}
                      countryList={(value) => {
                        setLocationList({
                          ...locationList,
                          countryList: value,
                        });
                      }}
                      stateList={(value) => {
                        setLocationList({
                          ...locationList,
                          stateList: value,
                        });
                      }}
                      cityList={(value) => {
                        setLocationList({ ...locationList, cityList: value });
                      }}
                    />
                    <div
                      className={
                        submitted && business_country ? " border-red-500" : ""
                      }
                    >
                      <label
                        htmlFor="business_country"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        Country*
                      </label>
                      <select
                        name="business_country"
                        className="mt-2 block w-full pl-3 pr-10 text-base  bg-[#FFF] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            country_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                      >
                        <option>{business_country ?? "Select country"}</option>

                        {locationList.countryList &&
                          locationList.countryList.length > 0 &&
                          locationList.countryList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>

                      {submitted && !business_country && (
                        <div className="text-xs text-red-500">
                          Business country is required
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && business_state ? " border-red-500" : "")
                      }
                    >
                      <label
                        htmlFor="business_state"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        {" "}
                        State*{" "}
                      </label>
                      <select
                        name="business_state"
                        className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            state_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                      >
                        <option>{business_state ?? "Select a state"}</option>

                        {locationList.stateList &&
                          locationList.stateList.length > 0 &&
                          locationList.stateList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      {submitted && !business_state && (
                        <div className="text-xs text-red-500">
                          Business state is required.
                        </div>
                      )}
                    </div>
                    <div
                      className={
                        "mt-4 mb-16" +
                        (submitted && business_city ? " border-red-500" : "")
                      }
                    >
                      <label
                        htmlFor="business_city"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        {" "}
                        Business City*{" "}
                      </label>
                      <select
                        name="business_city"
                        className="mt-2 block w-full pl-3 pr-10 text-base bg-[#FFF] focus:outline-none focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            city_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                      >
                        <option>{business_city ?? "Select a city"}</option>
                        {locationList.cityList &&
                          locationList.cityList.length > 0 &&
                          locationList.cityList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                      {submitted && !business_city && (
                        <div className="text-xs text-red-500">
                          Business city is required
                        </div>
                      )}
                    </div>
                  </div>
                </form>
              </div>
              <Button.Primary
                onClick={handleNext}
                title={"Next"}
                className={
                  "w-[333px] sm:mt-10 mx-auto py-4 flex justify-center  "
                }
              />
            </div>
          </div>
        </div>
      )}
      {active_tab === "store details" && (
        <div>
          <div
            className="pt-4 sm:pt-2 sm:flex items-center mx-auto"
            data-test="createStoreComponent"
          >
            <div className=" max-w-4xl mx-auto mt-10">
              <div className="sm:w-[629px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
                <form name="form " id="business-form" onSubmit={handleSubmit}>
                  <div className="border-b-2 py-4 mb-6">
                    <p className=" text-base font-medium text-gray-800 flex justify-center ">
                      Store Details
                    </p>
                  </div>
                  <div className=" sm:px-10">
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_name ? " border-red-500" : "")
                      }
                    >
                      <Input.Label
                        title={"Store Name "}
                        htmlFor={"store_name"}
                        type={"text"}
                        value={store_name}
                        name={"store_name"}
                        placeholder={"Enter store name"}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_email ? " border-red-500" : "")
                      }
                    >
                      <Input.Label
                        title={"Store Email *"}
                        htmlFor={"store_email"}
                        value={store_email}
                        type={"email"}
                        name={"store_email"}
                        placeholder={"Enter store email address"}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_phone ? " border-red-500" : "")
                      }
                    >
                      <Input.Phone
                        defaultCountry="NG"
                        title={"Store Phone Number"}
                        htmlFor={"store_phone"}
                        value={store_phone}
                        onChange={setStorePhone}
                      />
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_address ? " border-red-500" : "")
                      }
                    >
                      <Input.Label
                        title={"Store Address *"}
                        htmlFor={"store_address"}
                        value={store_address}
                        type={"text"}
                        name={"store_address"}
                        placeholder={"Enter store Address"}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_description
                          ? " border-red-500"
                          : "")
                      }
                    >
                      <Input.TextArea
                        title={"Store Description "}
                        htmlFor={"store_description"}
                        value={store_description}
                        type={"text"}
                        name={"store_description"}
                        placeholder={"Describe your store to us"}
                        onChange={handleChange}
                      />
                    </div>
                    <div
                      className={
                        submitted && !store_categories ? "  border-red-500" : ""
                      }
                    >
                      <label
                        htmlFor="store_categories"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Store Category*
                      </label>
                      <div className="mt-2 ">
                        <select
                          name="store_categories"
                          className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                          onChange={handleChange}
                        >
                          <option>
                            {store_categories ?? "Select a store category"}
                          </option>
                          {categories()}
                        </select>
                        {submitted && !store_categories && (
                          <div className="text-primary text-xs ">
                            Store category is required
                          </div>
                        )}
                      </div>
                    </div>
                    <Location
                      country_id={locationId.country_id}
                      state_id={locationId.state_id}
                      city_id={locationId.city_id}
                      countryList={(value) => {
                        setLocationList({
                          ...locationList,
                          countryList: value,
                        });
                      }}
                      stateList={(value) => {
                        setLocationList({
                          ...locationList,
                          stateList: value,
                        });
                      }}
                      cityList={(value) => {
                        setLocationList({ ...locationList, cityList: value });
                      }}
                    />
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_country ? " border-red-500" : "")
                      }
                    >
                      <label
                        htmlFor="store_country"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        Country*
                      </label>
                      <select
                        name="store_country"
                        className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            country_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                      >
                        <option>Select Country</option>

                        {locationList.countryList &&
                          locationList.countryList.length > 0 &&
                          locationList.countryList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div
                      className={
                        "mt-4" +
                        (submitted && store_state ? " border-red-500" : "")
                      }
                    >
                      <label
                        htmlFor="store_state"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        {" "}
                        State{" "}
                      </label>
                      <select
                        name="store_state"
                        className="mt-2 block w-full pl-3 pr-10 text-base focus:outline-none bg-[#FFFFFF]  focus:ring-2 focus:ring-red-500  focus:border-red-500 sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            state_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                      >
                        <option>Select state</option>

                        {locationList.stateList &&
                          locationList.stateList.length > 0 &&
                          locationList.stateList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                    <div
                      className={
                        "mt-4 mb-16" +
                        (submitted && store_city ? " border-red-500" : "")
                      }
                    >
                      <label
                        htmlFor="store_city"
                        className="block text-sm font-medium text-[#151515]"
                      >
                        {" "}
                        City{" "}
                      </label>
                      <select
                        name="store_city"
                        className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                        onChange={(e) => {
                          setLocationId({
                            ...locationId,
                            city_id: fromJson(e.target.value).id,
                          });
                          handleRegion(e);
                        }}
                      >
                        <option>Select a city</option>
                        {locationList.cityList &&
                          locationList.cityList.length > 0 &&
                          locationList.cityList.map((item, key) => {
                            return (
                              <option
                                key={key}
                                value={toJson({
                                  name: item.name,
                                  id: item.id,
                                })}
                              >
                                {item.name}
                              </option>
                            );
                          })}
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="sm:flex sm:justify-between sm:mt-10 sm:max-w-[800px] mx-auto">
            <button
              onClick={handleBack}
              className="rounded-md w-[333px] sm:mt-10 mx-auto flex justify-center mb-5 sm:mb-0 shadow-sm px-4 py-3 border border-primary text-primary text-base font-medium bg-white  sm:text-sm"
            >
              Back
            </button>

            <button
              form="business-form"
              type="submit"
              className=" rounded-md w-[333px] sm:mt-10 mx-auto flex justify-center shadow-sm px-4 py-3 bg-primary text-base font-medium text-white hover:bg-red-700  sm:text-sm"
            >
              {"Submit"}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
