// Application types

const PRODUCT_CATEGORY = {
  GET_PRODUCT_CATEGORIES: "GET_PRODUCT_CATEGORIES",
  STORE_A_PRODUCT_CATEGORY: "STORE_A_PRODUCT_CATEGORY",
  SEARCH_PRODUCT_CATEGORY: "  SEARCH_PRODUCT_CATEGORY",
  DELETE_A_PRODUCT_CATEGORY: "DELETE_A_PRODUCT_CATEGORY",
  EDIT_A_PRODUCT_CATEGORY: "EDIT_A_PRODUCT_CATEGORY",
  SHOW_A_PRODUCT_CATEGORY: "SHOW_A_PRODUCT_CATEGORY",
  TEST_PRODUCT_CATEGORY_MODEL: "TEST_PRODUCT_CATEGORY_MODEL",
  LOADING_PRODUCT_CATEGORIES: "LOADING_PRODUCT_CATEGORIES",
  RELOAD_PRODUCT_CATEGORY: "RELOAD_PRODUCT_CATEGORY",
  LOADING_SEARCH: "LOADING_SEARCH",
  FILTERING: "FILTERING",
  FILTER_PRODUCT_CATEGORY: "FILTER_PRODUCT_CATEGORY",
};

export default PRODUCT_CATEGORY;
