import { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { filterStoreOrders } from "../../../redux/storeOrders/storeOrderAction";
import { Beat } from "../../../plugins/spinners.plugin";

export default function FilterStoreOrdersDropDown({
  payLoad,
  page,
  isFilterOpen,
  setFilterState,
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.business);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    recipient_name: "",
    recipient_phone: "",
    recipient_city: "",
    order_amount: "",
    order_id: "",
    order_paid: "",
    status: payLoad?.status,
  });
  const {
    recipient_name,
    recipient_phone,
    recipient_city,
    order_amount,
    order_paid,
    order_id,
  } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      recipient_name: "",
      recipient_phone: "",
      recipient_city: "",
      order_amount: "",
      order_id: "",
      order_paid: "",
    });
    await dispatch(filterStoreOrders(payLoad, page));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (
      recipient_name ||
      recipient_phone ||
      recipient_city ||
      order_amount ||
      order_paid ||
      order_id
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterStoreOrders({ ...data }, 1));
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Customer Name"}
              htmlFor={"recipient_name"}
              name={"recipient_name"}
              onChange={handleChange}
              value={recipient_name}
              placeholder={"Enter Customer Name"}
            />
            <Input.Number
              title={"Customer Phone Number"}
              htmlFor={"recipient_phone"}
              name={"recipient_phone"}
              onChange={handleChange}
              value={recipient_phone}
              placeholder={"Enter Phone Number"}
            />
            <Input.Label
              title={"Customer City"}
              htmlFor={"recipient_city"}
              name={"recipient_city"}
              onChange={handleChange}
              value={recipient_city}
              autoComplete="off"
              placeholder={"Enter Customer City"}
              className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            />
            <Input.Number
              title={"Order Id"}
              htmlFor={"order_id"}
              name={"order_id"}
              onChange={handleChange}
              value={order_id}
              placeholder={"Enter Order Id"}
            />
            <Input.Number
              title={"Order Amount"}
              htmlFor={"order_amount"}
              name={"order_amount"}
              onChange={handleChange}
              value={order_amount}
              placeholder={"Enter amount"}
            />
            <Input.Select
              title={"Order Paid"}
              htmlFor={"order_paid"}
              name={"order_paid"}
              value={order_paid}
              onChange={handleChange}
            >
              <option>Select payment status</option>
              <option value={"1"}>Paid</option>
              <option value={"0"}>Unpaid</option>
            </Input.Select>
            <div className="py-8 grid grid-cols-2 gap-6 text-center">
              <Button.Secondary
                disabled={isLoading}
                title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
                className="px-4"
                onClick={handleReset}
                type="button"
              />
              <Button.Primary
                disabled={filtering}
                title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
                className="px-4"
                onClick={handleSubmit}
                type="button"
              />
            </div>
          </div>
          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
