import React, { useMemo, useEffect, useState } from "react";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Card from "../../../../../components/cards/admin/cards";
import Button from "../../../../../components/buttons/buttons";
import Table from "../../../../../components/tables/tableCols2";
import { Pagination } from "../../../../../components/pagination/pagination";
import TableSkeleton from "../../../../../components/skeleton/table";
import {
  filterNotifierGroup,
  searchNotifierGroup,
} from "../../../../../redux/notifierGroup/notifierGroupAction";
import Settings from "../../../../../assets/images/icons/settingsEmpty.svg";
import { AllFiltersModal } from "../../../../../components/modals/allFiltersModal";
import FilterCategoryDropDown from "../../../../../components/dropDown/filter/categoryFilter";

export default function RecipientGroup() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  let { page } = useParams();
  const { filtering, search, searching, allNotifierGroup } = useSelector(
    (state) => state.notifierGroup
  );
  const [isFilterOpen, setFilterState] = useState(false);
  const [data, setData] = useState([]);
  const [input, setInput] = useState({
    search: "",
  });

  // eslint-disable-next-line no-unused-vars
  const [payLoad] = useState({
    end_date: new Date(),
  });

  useEffect(() => {
    dispatch(filterNotifierGroup(payLoad, page));
  }, [dispatch, payLoad, page]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((notify, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          title: notify.title,
          users: notify.recipients?.length,
          date: moment(notify.created_at).format("MMM. DD, YYYY"),
          group: notify,
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allNotifierGroup?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allNotifierGroup]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allNotifierGroup?.data);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchNotifierGroup(input));
          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Title",
            accessor: "title",
          },
          {
            Header: "Users",
            accessor: "users",
          },
          {
            Header: "Date Sent",
            accessor: "date",
          },
        ],
      },
    ],
    []
  );

  const goToSingle = (params) => {
    navigate(
      `/admin/settings/broadcast-notification/recipient-groups/${params?.group?.id}`
    );
  };

  return (
    <div>
      <Card.TableFilter
        results={allNotifierGroup?.data?.length}
        totalResults={allNotifierGroup?.data?.length}
        onChange={handleChange}
        title={"Recipient Groups"}
        payLoad={payLoad}
        page={page}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterCategoryDropDown
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={"Name"}
      >
        <Button.Primary
          title={"+ Create Group"}
          onClick={() =>
            navigate("/admin/settings/broadcast-notification/create-recipient")
          }
        />
      </Card.TableFilter>

      <div className="border-b border-[#E4E4F3]" />
      <div className="bg-white rounded-md py-4 ">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            className="w-full cursor-pointer"
            onClick={goToSingle}
          />
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={Settings} alt="settinga" />
            </div>
            <p className="font-bold text-xl my-3">Settings</p>
            <p className="text-sm text-gray-500">
              You do not have any Categories yet
            </p>
          </div>
        )}
      </div>

      <Pagination
        data={allNotifierGroup}
        route={"admin/settings/broadcast-notification/recipient-groups"}
      />
    </div>
  );
}
