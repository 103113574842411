import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Input from "../../../../components/inputs/inputs";
import { cleanInput } from "../../../../helpers/cleanInput";
import { Beat } from "../../../../plugins/spinners.plugin";
import {
  showApplication,
  updateApplication,
} from "../../../../redux/applicationSettings/applicationAction";

export default function PlatformRatesSettings() {
  const dispatch = useDispatch();
  const { updating, settings } = useSelector((state) => state.application);
  const [data, setData] = useState({
    percentage: settings?.platform_charges?.service_fee?.percentage,
    limit: settings?.platform_charges?.service_fee?.limit,
    status: settings?.platform_charges?.service_fee?.status,
  });

  useEffect(() => {
    dispatch(showApplication());
  }, [dispatch]);

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleUpdate = async () => {
    cleanInput(data);
    const response = dispatch(
      updateApplication({
        platform_charges: {
          service_fee: {
            ...data,
          },
        },
      })
    );
    if (response.status === "success") {
      dispatch(showApplication());
    }
  };
  return (
    <>
      <div className="w-100 mx-auto">
        <div className="mx-auto bg-white sm:w-[630px] w-full mt-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
          <div className="border-b-2 py-4 mb-6">
            <p className=" text-base font-medium text-gray-800 flex justify-center ">
              Platform Rates
            </p>
          </div>

          <form className="sm:px-10">
            <div className="mb-10">
              <div className="border mb-3 px-2 py-3">
                <h4 className="font-bold">Service Fee (%) :</h4>
                <p className="text-gray-400 text-sm">
                  Percentage charged per checkout{" "}
                </p>
              </div>
              <Input.Number
                title={"Service Fee Percentage (%)"}
                type="number"
                name="percentage"
                id="percentage"
                defaultValue={data?.percentage || ""}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm flex-1"
                placeholder="Enter featured cost per hour"
                onChange={handleChange}
              />
            </div>

            <div className="mb-10">
              <div className="border mb-3 px-2 py-3">
                <h4 className="font-bold ">Service Fee Limit (NGN):</h4>
                <p className="text-gray-400 text-sm">
                  Maximum amount for % charged per checkout
                </p>
              </div>
              <Input.Number
                title={"Limit"}
                type="number"
                name="limit"
                id="limit"
                defaultValue={data?.limit || ""}
                className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm flex-1"
                placeholder="NGN 0.00"
                onChange={handleChange}
              />
            </div>

            <div className="mb-10">
              <div className="border mb-3 px-2 py-3">
                <h4 className="font-bold ">Service Fee Status:</h4>
                <p className="text-gray-400 text-sm">
                  Enable or disable the service fee feature
                </p>
              </div>
              <div className="mb-10">
                <Input.Select
                  title={"Status"}
                  name="status"
                  htmlFor={"status"}
                  defaultValue={data?.status}
                  onChange={handleChange}
                >
                  <option>Select Status</option>
                  <option value={"available"}>Available</option>
                  <option value={"unavailable"}>Unavailable</option>
                </Input.Select>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="flex justify-center mx-auto">
        <Button.Primary
          disabled={updating}
          title={updating ? <Beat color={"#ffffff"} /> : "Save Changes"}
          className={"px-4 rounded-sm sm:mt-10"}
          onClick={handleUpdate}
        />
      </div>
    </>
  );
}
