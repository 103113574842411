import React from "react";
import { getOutletTypeFromCookies } from "../../../../utils/Auth";
import RestaurantOrders from "./restauant";
import StoreOrders from "./store";

export default function OutletOrders() {
  const outletType = getOutletTypeFromCookies();

  return (
    <>
      {outletType === "restaurant" && <RestaurantOrders />}
      {outletType === "store" && <StoreOrders />}
    </>
  );
}
