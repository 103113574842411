export const groupBy = (arrayOfObjects, key) => {
  //   if (isEmpty(arrayOfObjects)) {
  //     return false;
  //   }

  let bag = {};
  arrayOfObjects?.map((item, index) => {
    if (bag[item[key]]) {
      return (bag[item[key]] = [...bag[item[key]], { ...item, index: index }]);
    } else {
      return (bag[item[key]] = [{ ...item, index: index }]);
    }
  });

  return bag;
};
