import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import Warning from "../../../assets/images/icons/warning.svg";
import { filterReferralsAction } from "../../../redux/referral/referralActions";

export default function FilterReferralsDropDown(props) {
  const { payLoad, isFilterOpen, setFilterState } = props;
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.referral);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    referral_code: "",
    referrer_code: "",
    referral_count: "",
  });
  const { referral_code, referrer_code, referral_count } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleReset = async () => {
    setData({
      referral_code: "",
      referrer_code: "",
      referral_count: "",
    });
    await dispatch(filterReferralsAction(payLoad, 1));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (referral_code || referrer_code || referral_count) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterReferralsAction(
            {
              ...data,
            },
            1
          )
        );
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        } else {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div data-test="">
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1">
          <Input.Label
            title={"Referral Code"}
            type="text"
            name="referral_code"
            id="referral_code"
            value={referral_code}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter referral code"
            onChange={handleChange}
          />
          <Input.Label
            title={"Referee Code"}
            type="text"
            name="referrer_code"
            id="referrer_code"
            value={referrer_code}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter referrer code"
            onChange={handleChange}
          />
          <Input.Number
            title={"Referral Count"}
            name="referral_count"
            id="referral_count"
            value={referral_count}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            placeholder="Enter referral count"
            onChange={handleChange}
          />
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={isLoading}
            title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </div>
  );
}
