// Application types

const USER = {
  LOADING_AUTH: "LOADING_AUTH",
  ALL_REFERRALS: "ALL_REFERRALS",
  SEARCHING: "SEARCHING",
  SEARCH_REFERRALS: "SEARCH_REFERRALS",
  GET_MY_REFERRALS: "GET_MY_REFERRALS",
  FILTERING_REFERRALS: "FILTERING_REFERRALS",
  FILTER_REFERRALS: "FILTER_REFERRALS",
  DOWNLOADING: "DOWNLOADING",
};

export default USER;
