import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../../assets/images/icons/close.svg";
import CreateFoodVariationModal from "./createFoodVariationModal";

export default function AddFoodVariationModal(props) {
  const {
    selected,
    setSelected,
    selectedArr,
    setSelectedArr,
    food_business_id,
  } = props;
  const [variation, setVariation] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const { allAttributes } = useSelector((state) => state.attribute);

  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (allAttributes) {
      let newAttr = allAttributes?.data?.filter(
        (attribute) => attribute?.type === "restaurant"
      );
      setVariation(newAttr);
    }
  }, [allAttributes]);

  function handleSelected(item) {
    setSelected([...selected, item]);
    setSelectedArr([...selectedArr, item.id]);
  }

  return (
    <div className="Coupon" data-test="couponModal">
      <Transition appear show={props?.show}>
        <Dialog as="div" className="relative z-10" onClose={props?.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[380px] text-left sm:w-[750px] p-4 sm:p-6">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-full p-1 sm:p-3 "
                        onClick={props?.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-4 text-center sm:mt-2 sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title as="h3" className="text-xl text-black">
                        Your product attributes
                      </Dialog.Title>
                    </div>
                  </div>
                  <CreateFoodVariationModal
                    show={isOpen}
                    onClose={closeModal}
                    food_business_id={food_business_id}
                  />
                  {variation?.length === 0 ? (
                    <>
                      <div className="text-center mx-auto max-w-[270px] py-8">
                        <h4 className="text-xl font-medium mb-2s">
                          Please create an attribute
                        </h4>
                        <p>No attributes available for this restaurant</p>
                      </div>
                      <div className="flex justify-end gap-8">
                        <button onClick={openModal} className="text-primary">
                          Create attribute
                        </button>
                        <button onClick={props.onClose}>Close</button>
                      </div>
                    </>
                  ) : (
                    <>
                      <p className="py-4 pb-6">
                        Select from available attributes and add to your food or{" "}
                        <span
                          className="text-primary cursor-pointer"
                          onClick={openModal}
                        >
                          Create a new attribute
                        </span>
                      </p>

                      <table className="py-3 w-full px-2">
                        <thead className="w-full border-b">
                          <tr className="mb-3">
                            <th className="py-3">S/N</th>
                            <th className="py-3">Title</th>
                            <th className="py-3">Name</th>
                            <th className="py-3">Price</th>
                            <th className="py-3">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {variation?.length > 0 &&
                            variation?.map((item, i) => (
                              <tr
                                className="w-full py-3 border-b cursor-pointer"
                                key={i}
                                onClick={() => {
                                  handleSelected(item);
                                }}
                              >
                                <td className="px-2 py-2">{i + 1}</td>
                                <td className="px-2 py-2">{item?.title}</td>
                                <td className="px-2 py-2">{item?.name}</td>
                                <td className="px-2 py-2">
                                  NGN {item?.amount}
                                </td>
                                <td className="text-primary px-2 py-2">Add</td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </>
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
