import authType from "./packageSubscriptionMerchandiseOrderTypes";

const initialState = {
  isLoading: false,
  searching: false,
  approving: false,
  filtering: false,
  shipping: false,
  marking: false,
  downloading: false,
  allPackageOrders: {},
  singlePackageOrder: {},
  myPackages: {},
  search: [],
};

export default function packageSubscriptionMerchandiseOrderReducer(
  state = initialState,
  action
) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };

    case authType.SHIPPING:
      return {
        ...state,
        shipping: action.payLoad,
      };

    case authType.MARKING:
      return {
        ...state,
        marking: action.payLoad,
      };

    case authType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case authType.ALL_PACKAGES:
      return {
        ...state,
        allPackageOrders: action.payLoad?.data,
      };

    case authType.SINGLE_PACKAGE_ORDER:
      return {
        ...state,
        singlePackageOrder: action.payLoad?.data,
      };

    case authType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case authType.SEARCH_PACKAGE:
      return {
        ...state,
        search: action.payLoad,
      };
    case authType.MY_PACKAGES:
      return {
        ...state,
        myPackages: action.payLoad.data,
      };

    case authType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case authType.FILTER_PACKAGE:
      return {
        ...state,
        allPackageOrders: action.payLoad?.data,
      };
    default:
      return state;
  }
}
