import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import All from "./All";
import Tiers from "./Tiers";

const tabs = [
  { name: "KYC", href: "all" },
  { name: "KYC Tiers", href: "tiers" },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function KYC() {
  const navigate = useNavigate();
  const DEFAULT_ACTIVE_TAB = "all";
  const { active_tab } = useParams();
  const { tab } = useParams();

  useEffect(() => {
    if (active_tab === "kyc-verification") {
      navigate(`/admin/settings/kyc-verification/${DEFAULT_ACTIVE_TAB}/1`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggle = (tab) => {
    if (active_tab !== tab) {
      navigate(`/admin/settings/kyc-verification/${tab.href}/1`);
    }
  };

  return (
    <div>
      <div>
        <div>
          <div className="sm:block">
            <div>
              <nav
                className="-mb-px flex space-x-4 overflow-x-auto"
                aria-label="Tabs"
              >
                {tabs.map((singleTab) => (
                  <button
                    key={singleTab.name}
                    onClick={() => {
                      toggle(singleTab);
                    }}
                    className={classNames(
                      singleTab.href === tab
                        ? "border-primary text-primary font-bold"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "whitespace-nowrap py-4  border-b-2 font-medium text-sm"
                    )}
                    aria-current={singleTab.href === tab ? "page" : undefined}
                  >
                    {singleTab.name}
                  </button>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div>
        {tab === "all" && <All />}
        {tab === "tiers" && <Tiers />}
      </div>
    </div>
  );
}
