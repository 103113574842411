import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import packageMerchType from "./packageSubscriptionMerchandiseOrderTypes";
import { packageSubscriptionMerchandiseOrderService } from "../../services/packageSubscriptionMerchandiseOrder.service";

/**
 * Get all packages
 **/

export const getAllSubscriptionMerchandiseOrders = () => async (dispatch) => {
  try {
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: true });
    const response =
      await packageSubscriptionMerchandiseOrderService.getAllSubscriptionMerchandiseOrders();
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: packageMerchType["ALL_PACKAGES"], payLoad: response });
  } catch (error) {
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all packages no pagination
 **/

export const getAllSubscriptionMerchandiseOrdersNoPagination =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: packageMerchType["DOWNLOADING"], payLoad: true });
      const response =
        await packageSubscriptionMerchandiseOrderService.getAllSubscriptionMerchandiseOrdersNoPagination(
          payload
        );
      dispatch({ type: packageMerchType["DOWNLOADING"], payLoad: false });
      return response;
    } catch (error) {
      dispatch({ type: packageMerchType["DOWNLOADING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Get a package
 **/

export const getASubscriptionMerchandiseOrder = (id) => async (dispatch) => {
  try {
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: true });
    const response =
      await packageSubscriptionMerchandiseOrderService.getASubscriptionMerchandiseOrder(
        id
      );
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: packageMerchType["SINGLE_PACKAGE_ORDER"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search packages
 **/

export const searchSubscriptionMerchandiseOrder =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: packageMerchType["SEARCHING"], payLoad: true });
      const response =
        await packageSubscriptionMerchandiseOrderService.searchSubscriptionMerchandiseOrder(
          payload
        );
      dispatch({ type: packageMerchType["SEARCHING"], payLoad: false });
      dispatch({ type: packageMerchType["SEARCH_PACKAGE"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: packageMerchType["SEARCHING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Filter packages
 **/

export const filterSubscriptionMerchandiseOrder =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: packageMerchType["FILTERING"], payLoad: true });
      const response =
        await packageSubscriptionMerchandiseOrderService.filterSubscriptionMerchandiseOrder(
          payload,
          page
        );
      dispatch({ type: packageMerchType["FILTERING"], payLoad: false });
      dispatch({ type: packageMerchType["FILTER_PACKAGE"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: packageMerchType["FILTERING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Search packages
 **/

export const closeSubscriptionMerchandiseOrder =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: packageMerchType["MARKING"], payLoad: true });
      const response =
        await packageSubscriptionMerchandiseOrderService.closeSubscriptionMerchandiseOrder(
          payload
        );
      dispatch({ type: packageMerchType["MARKING"], payLoad: false });
      // dispatch({ type: packageMerchType["SEARCH_PACKAGE"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: packageMerchType["MARKING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Ship package order
 **/

export const shipSubscriptionMerchandiseOrder =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: packageMerchType["SHIPPING"], payLoad: true });
      const response =
        await packageSubscriptionMerchandiseOrderService.shipSubscriptionMerchandiseOrder(
          payload
        );
      dispatch({ type: packageMerchType["SHIPPING"], payLoad: false });
      // dispatch({ type: packageMerchType["SEARCH_PACKAGE"], payLoad: response });
      successHandler(response, true);
      return response;
    } catch (error) {
      dispatch({ type: packageMerchType["SHIPPING"], payLoad: false });
      errorHandler(error, true);
    }
  };
export const getMySubscriptionMerchandiseOrder = () => async (dispatch) => {
  try {
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: true });
    const response =
      await packageSubscriptionMerchandiseOrderService.getMySubscriptionMerchandiseOrder();
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: packageMerchType["MY_PACKAGES"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: packageMerchType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};
