import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const PaymentSkeleton = () => {
  return (
    <section>
      <ul className="grid grid-cols-1 gap-0">
        {Array(2)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <Skeleton height={12} />
            </li>
          ))}
      </ul>
    </section>
  );
};
export default PaymentSkeleton;
