import storeType from "./storeTypes";

const initialState = {
  isLoading: false,
  updating: false,
  approving: false,
  searching: false,
  filtering: false,
  allStores: {},
  editStore: {},
  allStoresNoPage: {},
  searchStore: [],
  store: {},
  singleStore: {},
  approveStore: {},
  unApproveStore: {},
  managementApproved: {},
  filterStores: {},
  employeeLocation: {},
  myStores: {},
  testModel: {},
};

export default function storeReducer(state = initialState, action) {
  switch (action.type) {
    case storeType.LOADING_STORES:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case storeType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    case storeType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };
    case storeType.GET_ALL_STORES:
      return {
        ...state,
        allStores: action.payLoad?.data,
      };
    case storeType.GET_ALL_STORES_NOPAGE:
      return {
        ...state,
        allStoresNoPage: action.payLoad?.data,
      };
    case storeType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };
    case storeType.EDIT_STORE:
      return {
        ...state,
        editStore: action.payLoad.data,
      };
    case storeType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case storeType.FILTER_STORES:
      return {
        ...state,
        allStores: action.payLoad?.data,
      };
    case storeType.GET_MY_STORES:
      return {
        ...state,
        myStores: action.payLoad?.data?.data,
      };
    case storeType.DELETE_STORE:
      const deleteStore = state.deleteStore.filter(
        (item) => item.id !== action.payLoad.id
      );

      return {
        ...state,
        allStores: deleteStore,
      };
    case storeType.CREATE_STORE:
      return {
        ...state,
        store: action.payLoad.data.data,
      };
    case storeType.SHOW_A_STORE:
      return {
        ...state,
        singleStore: action.payLoad?.data,
      };
    case storeType.SEARCH_STORES:
      return {
        ...state,
        searchStore: action.payLoad?.data,
      };
    case storeType.APPROVE_STORE:
      let approved_business = {
        ...state.singleStore,
        business_approved: true,
      };
      return {
        ...state,
        singleStore: approved_business,
      };
    case storeType.UNAPPROVE_STORE:
      let unapproved_business = {
        ...state.singleStore,
        business_approved: false,
      };
      return {
        ...state,
        singleStore: unapproved_business,
      };
    case storeType.APPROVE_STORE_MANAGEMENT:
      let approved = {
        ...state.singleStore,
        management_approved: true,
      };
      return {
        ...state,
        singleStore: approved,
      };
    case storeType.UNAPPROVE_STORE_MANAGEMENT:
      let unapproved = {
        ...state.singleStore,
        management_approved: false,
      };
      return {
        ...state,
        singleStore: unapproved,
      };
    default:
      return state;
  }
}
