import axios from "../plugins/axios.plugin";

class AttributeService {
  baseServicePath = "/api/attribute";

  getAllAttributes(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  setNewAttribute(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  editAttribute(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  deleteAttribute(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  showSingleAttribute(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }

  searchAttributes(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterAttributes(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }
}
export const attributeService = new AttributeService();
