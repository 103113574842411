import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import UserFoodPackageOrders from "./packages";
import UserRestaurantOrders from "./restaurant";
import UserStoreOrders from "./store";
import UserMerchandisePackageOrders from "./storePackages.js";
import Button from "../../../../components/buttons/buttons";

const tabs = [
  {
    name: "Restaurant Orders",
    href: "restaurant",
  },
  {
    name: "Store Orders",
    href: "store",
  },
  {
    name: "Food Package Orders",
    href: "food-package",
  },
  {
    name: "Merchandise Package Orders",
    href: "merch-package",
  },
];
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function MyOrders() {
  const navigate = useNavigate();
  const [active_tab, setActiveTab] = useState("restaurant");

  const toggle = (tab) => {
    if (active_tab !== tab) {
      setActiveTab(tab.href);
    }
  };
  return (
    <div>
      <div className="sm:w-[820px] w-full mt-10 sm:ml-10 sm:mt-0 px-4 sm:px-6 bg-white rounded-sm shadow-2xl">
        <div className="py-4 sm:py-5">
          <div className="border-b border-gray-200 overflow-x-scroll">
            <nav
              className="-mb-px flex space-x-8 justify-between"
              aria-label="Tabs"
            >
              {tabs.map((tab) => (
                <button
                  key={tab.name}
                  onClick={() => {
                    toggle(tab);
                  }}
                  className={classNames(
                    tab.href === active_tab
                      ? "border-primary text-primary font-bold"
                      : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                    "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
                  )}
                  aria-current={tab.href === active_tab ? "page" : undefined}
                >
                  {tab.name}
                </button>
              ))}
            </nav>
          </div>
        </div>

        <div>
          {active_tab === "restaurant" && <UserRestaurantOrders />}
          {active_tab === "store" && <UserStoreOrders />}
          {active_tab === "food-package" && <UserFoodPackageOrders />}
          {active_tab === "merch-package" && <UserMerchandisePackageOrders />}
        </div>
        <Button.Secondary
          type="button"
          title={"Back"}
          className={"sm:hidden rounded-md mb-8 sm:mb-0 w-full"}
          onClick={() => navigate("/profile")}
        />
      </div>
    </div>
  );
}
