import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Chart } from "react-google-charts";
import Input from "../../../../../components/inputs/inputs";
import Button from "../../../../../components/buttons/buttons";
import { Beat } from "../../../../../plugins/spinners.plugin";
import { getOutletFromCookies } from "../../../../../utils/Auth";
import EmptyState from "../../../../../assets/images/emptyState/stats.svg";
import { getRestaurantStatistics } from "../../../../../redux/statistics/statisticsAction";

export const options = {
  curveType: "function",
  legend: "none",
  height: 280,
  series: {
    0: {
      color: "#01d120",
    },
    1: {
      color: "#ce2600",
    },
  },
};

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function RestaurantOrderStatistics() {
  const dispatch = useDispatch();
  const outlet = getOutletFromCookies();
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState({
    end_date: "",
    start_date: "",
    restaurant_id: outlet?.id,
  });
  const { end_date, start_date } = data;
  const { restaurantStats, isLoading } = useSelector(
    (state) => state.statistics
  );

  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };

  const handleSubmit = (e) => {
    setSubmitted(true);
    dispatch(getRestaurantStatistics(data));
  };

  return (
    <div data-test="singleOrder-page">
      <div className="grid grid-cols-1 sm:grid-cols-12 gap-8">
        {/* Grid 1 */}
        <div className="sm:col-span-5">
          <div className="border border-[#E4E4F3] h-full bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-center">
              <h3 className="text-lg font-medium">Check statistics</h3>
            </div>

            <div className="flex flex-col 2xl:flex-row justify-between items-end py-6 mt-6">
              <div className="sm:flex w-full sm:w-auto gap-3">
                <Input.DatePicker
                  title={"Date From :"}
                  htmlFor={"end_date"}
                  name={"start_date"}
                  value={start_date}
                  onChange={handleChange}
                />
                <Input.DatePicker
                  title={"Date To :"}
                  htmlFor={"end_date"}
                  name={"end_date"}
                  value={end_date}
                  onChange={handleChange}
                />
              </div>

              <div>
                <Button.Primary
                  title={isLoading ? <Beat color={"#ffffff"} /> : "Check"}
                  disabled={isLoading || !(start_date && end_date)}
                  className="w-full mb-4 px-5"
                  type={"button"}
                  onClick={handleSubmit}
                />
              </div>
            </div>

            <ul className="mt-6">
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Total Orders</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted ? restaurantStats?.stats?.orders?.total_orders : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Unshipped Orders</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.orders?.unshipped_orders
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Shipped Orders</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.orders?.shipped_orders
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Closed Orders</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.orders?.closed_orders
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Delivered Orders</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.orders?.delivered_orders
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">
                  Third Party Orders
                </span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.orders?.third_party_orders
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Paid Orders</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted ? restaurantStats?.stats?.orders?.order_paid : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">
                  Paid Orders Total
                </span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  NGN{" "}
                  {submitted
                    ? numberWithCommas(
                        restaurantStats?.stats?.orders?.order_paid_amount.toFixed(
                          2
                        )
                      )
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Approved Foods</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.foods?.approved_foods
                    : 0}
                </span>
              </li>
              <li className="flex px-3 py-4 mt-1 justify-between border-b">
                <span className="text-xs text-[#344B67]">Unapproved Foods</span>
                <span className="text-sm text-[#4D4D4D] font-bold">
                  {submitted
                    ? restaurantStats?.stats?.foods?.unapproved_foods
                    : 0}
                </span>
              </li>
            </ul>
          </div>
        </div>

        {/* Grid 2 */}
        <div className="sm:col-span-7">
          <div className="border border-[#E4E4F3] h-full bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-center">
              <h3 className="text-lg font-medium">Overview/Summary</h3>
            </div>

            <div className="h-full flex items-center justify-center">
              {/* <div className="w-full">
                <h2 className="my-2 text-[#1D2129] text-xl">
                  Total Orders vs Month
                </h2>
                <Chart
                  chartType="LineChart"
                  width="100%"
                  data={[
                    ["Age", "Weight"],
                    [4, 5.5],
                    [8, 12],
                  ]}
                  options={options}
                />
              </div> */}
              <div className="py-24 text-center">
                <div className="flex justify-center">
                  <img src={EmptyState} className="w-auto" alt="no data" />
                </div>
                <h3 className="mt-5 text-xl font-bold">Stats</h3>
                <p className="mt-2 text-sm text-[#9CA3AF]">
                  You do not have any report records yet.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
