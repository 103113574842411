import React from "react";
import AllEmployees from "./all";

export default function EmployeeRoles() {
  return (
    <div className="employees-page">
      <div className="sm:block">
        <div className="border-b border-gray-200"></div>
      </div>
      <div>
        <AllEmployees />
      </div>
    </div>
  );
}
