const SHIPPERS = {
  GET_ALL_SHIPPERS: "GET_ALL_SHIPPERS",
  FILTERING: "FILTERING",
  VERIFYING: "VERIFYING",
  APPROVING: "APPROVING",
  UPDATING: "UPDATING",
  CREATING: "CREATING",
  FILTER_SHIPPERS: "FILTER_SHIPPERS",
  FILTER_SHIPPERS_NOPAGE: "FILTER_SHIPPERS_NOPAGE",
  SEARCH_SHIPPERS: "SEARCH_SHIPPERS",
  STORE_SHIPPERS: "STORE_SHIPPERS",
  SHOW_SHIPPERS: "SHOW_SHIPPERS",
  SHOW_MY_SHIPPERS: "SHOW_MY_SHIPPERS",
  EDIT_SHIPPERS: "EDIT_SHIPPERS",
  DELETE_SHIPPERS: "DELETE_SHIPPERS",
  LOADING_SHIPPERS: "LOADING_SHIPPERS",
  LOADING_SEARCH: "LOADING_SEARCH",
  VERIFY_SHIPPER: "VERIFY_SHIPPER",
  UNVERIFY_SHIPPER: "UNVERIFY_SHIPPER",
  APPROVE_SHIPPER: "APPROVE_SHIPPER",
  UNAPPROVE_SHIPPER: "UNAPPROVE_SHIPPER",
  DELETING: "DELETING",
};

export default SHIPPERS;
