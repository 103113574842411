import axios from "../plugins/axios.plugin";

class NotifierGroupService {
  baseServicePath = "/api/notifier-group";

  storeANotifierGroup(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  getAllCategories(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  deleteANotifierGroup(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  closeANotifierGroup(payload) {
    return axios.post(`${this.baseServicePath}/mark/closed`, payload);
  }

  editANotifierGroup(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  showANotifierGroup(id, user_page) {
    return axios.get(
      `${this.baseServicePath}/show?id=${id}&properties=1&user_page=${user_page}`
    );
  }
  searchNotifierGroup(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }

  filterNotifierGroup(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
}

export const notifierGroupService = new NotifierGroupService();
