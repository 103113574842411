import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { subscriptionFoodService } from "../../services/subscriptionFood.service";
import subscriptionType from "./subscriptionFoodTypes";

/**
 * Get all packages
 **/
export const getAllSubscriptionFoodAction = () => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: true });
    const response = await subscriptionFoodService.getAllSubscriptionFoods();
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: subscriptionType["ALL_FOOD_SUBSCRIPTIONS"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all packages no pagination
 **/

export const getAllSubscriptionFoodNoPagination =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: subscriptionType["DOWNLOADING"], payLoad: true });
      const response =
        await subscriptionFoodService.getAllSubscriptionFoodNoPagination(
          payload
        );
      dispatch({ type: subscriptionType["DOWNLOADING"], payLoad: false });
      return response;
    } catch (error) {
      dispatch({ type: subscriptionType["DOWNLOADING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Get all packages
 **/
export const getASubscriptionFoodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: true });
    const response = await subscriptionFoodService.getASubscriptionFood(id);
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: subscriptionType["SINGLE_SUBSCRIPTION_FOOD"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: subscriptionType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Store subscription food
 **/
export const createSubscriptionFoodAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["LOADING_SUBSCRIPTION"], payLoad: true });
    const response = await subscriptionFoodService.createSubscriptionFood(
      payload
    );
    dispatch({
      type: subscriptionType["LOADING_SUBSCRIPTION"],
      payLoad: false,
    });
    dispatch({
      type: subscriptionType["ADD_SUBSCRIPTION_ITEM"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: subscriptionType["LOADING_SUBSCRIPTION"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Search Subscription
 **/

export const searchSubscriptionFoodAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["SEARCHING"], payLoad: true });
    const response = await subscriptionFoodService.searchSubscriptionFood(
      params
    );
    dispatch({ type: subscriptionType["SEARCHING"], payLoad: false });
    dispatch({
      type: subscriptionType["SEARCH_FOOD_SUBSCRIPTIONS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["SEARCHING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter Subscription
 **/

export const filterSubscriptionFoodAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: subscriptionType["FILTERING"], payLoad: true });
      const response = await subscriptionFoodService.filterSubscriptionFood(
        payload,
        page
      );
      dispatch({ type: subscriptionType["FILTERING"], payLoad: false });
      dispatch({
        type: subscriptionType["FILTER_FOOD_SUBSCRIPTIONS"],
        payLoad: response,
      });
      return response;
    } catch (error) {
      dispatch({ type: subscriptionType["FILTERING"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Filter Subscription
 **/

export const deleteSubscriptionFoodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["DELETING"], payLoad: true });
    const response = await subscriptionFoodService.deleteSubscriptionFood(id);
    dispatch({ type: subscriptionType["DELETING"], payLoad: false });
    dispatch({
      type: subscriptionType["DELETE_SUBSCRIPTION_FOOD"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["DELETING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve Foods by mgt
 **/

export const approveSubscriptionFood = (payload) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["APPROVING_FOOD"], payLoad: true });
    const response = await subscriptionFoodService.approveSubscriptionFood(
      payload
    );
    dispatch({ type: subscriptionType["APPROVING_FOOD"], payLoad: false });
    dispatch({
      type: subscriptionType["APPROVE_SUBSCRIPTION_FOOD"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["APPROVING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove Foods by mgt
 **/

export const unapproveSubscriptionFood = (payload) => async (dispatch) => {
  try {
    dispatch({ type: subscriptionType["APPROVING_FOOD"], payLoad: true });
    const response = await subscriptionFoodService.unapproveSubscriptionFood(
      payload
    );
    dispatch({ type: subscriptionType["APPROVING_FOOD"], payLoad: false });
    dispatch({
      type: subscriptionType["UNAPPROVE_SUBSCRIPTION_FOOD"],
      payLoad: false,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: subscriptionType["APPROVING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};
