import axios from "../plugins/axios.plugin";

class PublicServiceAreaService {
  baseServicePath = "/api/public/service-area";

  getAllPublicServiceAreas() {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=1`);
  }

  filterPublicServiceAreas(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }
}
export const publicServiceAreaService = new PublicServiceAreaService();
