import axios from "../plugins/axios.plugin";

class PublicCartService {
  baseServicePath = "/api/public/cart";

  loadPublicCarts(payload) {
    return axios.post(`${this.baseServicePath}/load`, payload);
  }

  checkoutPublicCart(payload) {
    return axios.post(`${this.baseServicePath}/checkout`, payload);
  }
}

export const publicCartService = new PublicCartService();
