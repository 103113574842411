import React, { useRef } from "react";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import RestaurantStatistics from "./types/RestaurantStatistics";
import StoreStatistics from "./types/StoreStatistics";
import { getUserFromCookies } from "../../../../utils/Auth";
import { getOutletTypeFromCookies } from "../../../../utils/Auth";
import ShareLinkModal from "../../../../components/modals/admin/shareLinkModal";

export default function Dashboard() {
  const shareLinkRef = useRef(null);
  const user = getUserFromCookies();
  const outlet_type = getOutletTypeFromCookies();
  var currentDate = new Date();

  return (
    <>
      <div className="mb-10">
        <h1 className="text-3xl font-semibold text-gray-900">
          Welcome back, {user?.name}
        </h1>
        <div className="flex flex-col sm:flex-row gap-3 justify-between sm:items-center">
          <p>Here’s what’s happening with your store today </p>
          <div className="flex gap-3">
            {outlet_type === "restaurant" ? (
              <Button.Primary
                title={"Restaurant Link"}
                className="px-4"
                onClick={() => shareLinkRef?.current?.open()}
                type="button"
              />
            ) : (
              <Button.Primary
                title={"Store Link"}
                className="px-4"
                onClick={() => shareLinkRef?.current?.open()}
                type="button"
              />
            )}
            <div className="bg-white py-2 px-4 border border-[#E4E4F3] rounded-lg text-sm tracking-widest w-fit">
              {moment(currentDate).format("dddd, DD/MM/YYYY")}
            </div>
          </div>
        </div>
      </div>

      {outlet_type === "restaurant" && <RestaurantStatistics />}
      {outlet_type === "store" && <StoreStatistics />}

      <ShareLinkModal ref={shareLinkRef} />
    </>
  );
}
