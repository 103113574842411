import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { transactionService } from "../../services/walletTransaction.service";
import transactionType from "./trsansactionTypes";

/**
 * Get all Transactions
 **/

export const getAllTransactionsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["LOADING_AUTH"], payLoad: true });
    const response = await transactionService.getAllTransactions(page);
    dispatch({ type: transactionType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: transactionType["ALL_TRANSACTIONS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: transactionType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all Transactions
 **/

export const getAllTransactionsNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["DOWNLOADING"], payLoad: true });
    const response = await transactionService.getAllTransactionsNoPagination(
      payload
    );
    dispatch({ type: transactionType["DOWNLOADING"], payLoad: false });
    dispatch({
      type: transactionType["ALL_TRANSACTIONS_NO_PAGINATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: transactionType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all incomplete Transactions
 **/

export const getAllIncompleteTransactions = (payload) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["DOWNLOADING"], payLoad: true });
    const response = await transactionService.getAllIncompleteTransactions(
      payload
    );
    dispatch({ type: transactionType["DOWNLOADING"], payLoad: false });
    dispatch({
      type: transactionType["INCOMPLETE_TRANSACTIONS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: transactionType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a Transactions
 **/

export const getATransactionAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["LOADING_AUTH"], payLoad: true });
    const response = await transactionService.getATransaction(id);
    dispatch({ type: transactionType["LOADING_AUTH"], payLoad: false });
    dispatch({
      type: transactionType["SINGLE_TRANSACTION"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: transactionType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search Transactions
 **/

export const searchTransactionsAction = (params) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["LOADING_SEARCH"], payLoad: true });
    const response = await transactionService.searchTransactions(params);
    dispatch({ type: transactionType["LOADING_SEARCH"], payLoad: false });
    dispatch({
      type: transactionType["SEARCH_TRANSACTION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: transactionType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter Transactions
 **/

export const filterTransactionsAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["FILTERING"], payLoad: true });
    const response = await transactionService.filterTransactions(payload, page);
    dispatch({ type: transactionType["FILTERING"], payLoad: false });
    dispatch({
      type: transactionType["FILTER_TRANSACTIONS"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: transactionType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Transfer Transactions by self
 **/

export const transferToWalletAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: transactionType["TRANSFERRING"], payLoad: true });
    const response = await transactionService.transferToWallet(payload);
    dispatch({ type: transactionType["TRANSFERRING"], payLoad: false });
    dispatch({
      type: transactionType["TRANSFER_TRANSACTIONS"],
      payLoad: false,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: transactionType["TRANSFERRING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const concludeWalletTransactionAction =
  (payload) => async (dispatch) => {
    try {
      dispatch({ type: transactionType["UPDATING"], payLoad: true });
      const response = await transactionService.concludeTransaction(payload);
      dispatch({ type: transactionType["UPDATING"], payLoad: false });
      dispatch({
        type: transactionType["TRANSFER_TRANSACTIONS"],
        payLoad: false,
      });
      successHandler(response, true);
      return true;
    } catch (error) {
      dispatch({ type: transactionType["UPDATING"], payLoad: false });
      errorHandler(error, true);
    }
  };
