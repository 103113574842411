import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// components
import { Switch } from "@headlessui/react";
import { Beat } from "../../../plugins/spinners.plugin";
import Button from "../../../components/buttons/buttons";
import Table from "./tabs/index";
import ServiceAreaCharges from "./ServiceAreaCharges";
// requests
import {
  approveShipper,
  showShipper,
  unapproveShipper,
  editShipper,
} from "../../../redux/shippers/shippersActions";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function SingleShipper() {
  let { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { shipper, updating } = useSelector((state) => state.shipper);
  const [approved, setApproved] = useState(false);
  const [unApproved, setUnApproved] = useState(true);
  useEffect(() => {
    dispatch(showShipper(id));
    // eslint-disable-next-line
  }, []);

  const handleApprove = (e) => {
    dispatch(approveShipper({ id: id }));
  };

  const handleUnapprove = (e) => {
    dispatch(unapproveShipper({ id: id }));
  };
  const setDefault = () => {
    dispatch(editShipper({ id: id, shipper_selected: 1 }));
  };

  return (
    <div data-test="singleRestaurant-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between gap-6 items-center">
          <h3 className="text-lg font-medium">Shipper Details</h3>
          <div className="flex gap-4">
            <Button.Primary
              onClick={() => navigate(`/admin/shippers/edit/${id}`)}
              title={"Edit Shipper"}
            />
            {!shipper?.shipper_selected && (
              <Button.Secondary
                onClick={setDefault}
                title={
                  updating ? (
                    <Beat color={"#ce2600"} />
                  ) : (
                    "Set as Default Shipper"
                  )
                }
              />
            )}
          </div>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Shipper Name</label>
            <p className="text-[#151515] text-sm">{shipper?.name}</p>
          </div>

          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">{shipper?.phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Zip Code</label>
            <p className="text-[#151515] text-sm">{shipper?.zip}</p>
          </div>
          <div>
            {shipper?.management_approved === false ? (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF]"
                  >
                    Approve Shipper
                  </Switch.Description>
                </span>
                <Switch
                  checked={approved}
                  onChange={(e) => {
                    setApproved(e);
                    handleApprove(e);
                  }}
                  className={classNames(
                    approved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      approved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            ) : (
              <Switch.Group as="div">
                <span>
                  <Switch.Description
                    as="p"
                    className="text-sm mb-2 text-[#9CA3AF]"
                  >
                    Unapprove Shipper
                  </Switch.Description>
                </span>
                <Switch
                  checked={unApproved}
                  onChange={(e) => {
                    handleUnapprove(e);
                    setUnApproved(e);
                  }}
                  className={classNames(
                    unApproved ? "bg-primary" : "bg-gray-200",
                    "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      unApproved ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
                    )}
                  />
                </Switch>
              </Switch.Group>
            )}
          </div>

          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">{shipper?.country}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm">{shipper?.state}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm">{shipper?.city}</p>
          </div>
          {/* <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Latitude</label>
            <p className="text-[#151515] text-sm">{shipper?.latitude}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Longitude</label>
            <p className="text-[#151515] text-sm">{shipper?.longitude}</p>
          </div> */}
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Shipper Rates</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-6 gap-6 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Base Charge</label>
            <p className="text-[#151515] text-sm">{shipper?.base_fee}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Charge by Meter
            </label>
            <p className="text-[#151515] text-sm">
              {shipper?.base_fee_per_meter}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Insurance rate(%)
            </label>
            <p className="text-[#151515] text-sm">
              {shipper?.insurance_percentage}
            </p>
          </div>

          <div>
            <label className="text-[#9CA3AF] text-sm mb-2"> Tax rate(%)</label>
            <p className="text-[#151515] text-sm">{shipper?.tax_percentage}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Discount rate(%)
            </label>
            <p className="text-[#151515] text-sm">
              {shipper?.discount_percentage}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">
              Maximum weight(Kg)
            </label>
            <p className="text-[#151515] text-sm">
              {shipper?.weight_limit_by_kilogram}
            </p>
          </div>
        </div>
      </div>

      <ServiceAreaCharges shipper={shipper} />

      <div className="mt-6">
        <div>
          <Table shipper={shipper} />
        </div>
      </div>
    </div>
  );
}
