import applicationType from "./applicationType";

const initialState = {
  isLoading: false,
  updating: false,
  create: {},
  update: {},
  settings: {},
};
export default function applicationReducer(state = initialState, action) {
  switch (action.type) {
    case applicationType.LOADING_APPLICATION_SETTINGS:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case applicationType.CREATE_APPLICATION_SETTINGS:
      return {
        ...state,
        create: action.payLoad?.data,
      };
    case applicationType.UPDATING_APPLICATION_SETTINGS:
      return {
        ...state,
        updating: action.payLoad,
      };
    case applicationType.UPDATE_APPLICATION_SETTINGS:
      return {
        ...state,
        update: action.payLoad?.data,
      };
    case applicationType.SHOW_APPLICATION_SETTINGS:
      return {
        ...state,
        settings: action.payLoad?.data,
      };
    default:
      return state;
  }
}
