import axios from "../plugins/axios.plugin";

class CurrencyService {
  baseServicePath = "/api/currency";

  getAllCurrencies() {
    return axios.get(
      `${this.baseServicePath}/index?category=currencies&properties=1&unique=1`
    );
  }
}

export const currencyService = new CurrencyService();
