import feedbackType from "./feedbackTypes";
import { feedbackService } from "../../services/feedback.service";
import errorHandler from "../../handlers/errorHandler";

/**
 * Get Feedback
 **/

export const getAllFeedback = (page) => async (dispatch) => {
  try {
    dispatch({ type: feedbackType["LOADING_FEEDBACK"], payLoad: true });
    const response = await feedbackService.getAllFeedback(page);
    dispatch({ type: feedbackType["LOADING_FEEDBACK"], payLoad: false });
    dispatch({ type: feedbackType["ALL_FEEDBACK"], payLoad: response });
  } catch (error) {
    dispatch({ type: feedbackType["LOADING_FEEDBACK"], payLoad: false });
  }
};

/**
 * Get Feedback
 **/

export const getSingleFeedback = (id) => async (dispatch) => {
  try {
    dispatch({ type: feedbackType["LOADING_FEEDBACK"], payLoad: true });
    const response = await feedbackService.getSingleFeedback(id);
    dispatch({ type: feedbackType["LOADING_FEEDBACK"], payLoad: false });
    dispatch({ type: feedbackType["SINGLE_FEEDBACK"], payLoad: response });
  } catch (error) {
    dispatch({ type: feedbackType["LOADING_FEEDBACK"], payLoad: false });
  }
};

/**
 * Filter a Feedback
 **/

export const filterFeedback = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: feedbackType["FILTERING_FEEDBACK"], payLoad: true });
    const response = await feedbackService.filterFeedback(payLoad, page);
    dispatch({ type: feedbackType["FILTERING_FEEDBACK"], payLoad: false });
    dispatch({ type: feedbackType["FILTER_FEEDBACK"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: feedbackType["FILTERING_FEEDBACK"], payLoad: false });
  }
};

/**
 * Filter a Feedback
 **/

export const storeFeedback = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: feedbackType["STORING_FEEDBACK"], payLoad: true });
    const response = await feedbackService.storeFeedback(payLoad, page);
    dispatch({ type: feedbackType["STORING_FEEDBACK"], payLoad: false });
    return response;
  } catch (error) {
    dispatch({ type: feedbackType["STORING_FEEDBACK"], payLoad: false });
  }
};

/**
 * Search Feedback
 **/

export const searchFeedback = (params) => async (dispatch) => {
  try {
    dispatch({ type: feedbackType["LOADING_SEARCH"], payLoad: true });
    const response = await feedbackService.searchFeedback(params);
    dispatch({ type: feedbackType["LOADING_SEARCH"], payLoad: false });
    dispatch({ type: feedbackType["SEARCH_FEEDBACK"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: feedbackType["LOADING_SEARCH"], payLoad: false });
    errorHandler(error, true);
  }
};
