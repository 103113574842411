import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Location from "../../../../components/includes/location";
import Input from "../../../../components/inputs/inputs";
import { history } from "../../../../helpers/history";
import { logoutUserLocallyAction } from "../../../../plugins/axios.plugin";
import { onboardInvite } from "../../../../redux/onBoarding/onBoardingAction";

export default function InviteReg() {
  const urlParams = new URLSearchParams(window.location.search);
  const invite = urlParams.get("invite_code");
  const dispatch = useDispatch();

  const [locationId, setLocationId] = useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  // Form actions
  const [submitted, setSubmitted] = useState(false);
  let [user_phone, setUserPhone] = useState("");
  const [data, setData] = useState({
    user_name: "",
    user_email: "",
    user_password: "",
    user_phone: "",
    user_password_confirmation: "",
    user_address: "",
    user_city: "",
    user_state: "",
    user_country: "",
    invite_code: invite,
  });
  const {
    user_name,
    user_email,
    user_password,
    user_password_confirmation,
    user_address,
    user_city,
    user_state,
    user_country,
    invite_code,
  } = data;
  user_phone = user_phone.slice(1);
  function handleChange(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: value }));
  }

  function handleRegion(e) {
    const { name, value } = e.target;
    setData((data) => ({ ...data, [name]: fromJson(value).name }));
  }
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      user_name &&
      user_email &&
      user_password &&
      user_password_confirmation &&
      user_phone &&
      user_address &&
      user_city &&
      user_state &&
      user_country &&
      invite_code
    ) {
      // get return url from location state or default to home page
      setSubmitted(true);
      const response = await dispatch(onboardInvite({ ...data, user_phone }));
      if (response) {
        logoutUserLocallyAction();
        history("/user/home");
      }
    }
  };

  return (
    <div className="mt-20">
      <div className="p-5 sm:w-[629px] max-w-lg bg-white rounded-sm border relative shadow-xl sm:p-8 mx-auto">
        <form onSubmit={handleSubmit} id="register_form" autoComplete="off">
          <div className="sm:flex sm:items-start ">
            <div className=" text-center sm:ml-24 sm:text-left">
              <h3 as="h3" className="text-xl  font-bold text-black">
                Enter Employment Details
              </h3>
            </div>
          </div>
          <div className=" border-b-2 mt-4"></div>
          <div className="text-left pt-4">
            <div
              className={
                " mb-4" + (submitted && user_name ? "  border-red-500" : "")
              }
            >
              <Input.Label
                title={"Name*"}
                htmlFor="user_name"
                placeholder="Enter name"
                type="text"
                name="user_name"
                onChange={handleChange}
                autoComplete="off"
                required
              />

              {submitted && !user_name && (
                <div className="text-primary text-xs">Name is required</div>
              )}
            </div>

            <div
              className={
                " mb-4" + (submitted && user_phone ? "  border-red-500" : "")
              }
            >
              <Input.Phone
                title={"Phone Number*"}
                htmlFor="user_phone"
                defaultCountry="NG"
                value={user_phone}
                onChange={setUserPhone}
              />

              {submitted && !user_phone && (
                <div className="text-primary text-xs">Phone is required</div>
              )}
            </div>

            <div
              className={
                " mb-4" + (submitted && user_email ? "  border-red-500" : "")
              }
            >
              <Input.Label
                htmlFor="user_email"
                title={"Email*"}
                placeholder="Enter Email"
                type="email"
                name="user_email"
                onChange={handleChange}
                autoComplete="off"
                required
              />
              {submitted && !user_email && (
                <div className="text-primary text-xs">Email is required</div>
              )}
            </div>

            <div
              className={
                " mb-4" + (submitted && user_password ? "  border-red-500" : "")
              }
            >
              <Input.Label
                htmlFor="user_password"
                title={"Password*"}
                placeholder="Minimum of six(6) characters"
                type="password"
                name="user_password"
                autoComplete="off"
                onChange={handleChange}
              />
              {submitted && !user_password && (
                <div className="text-primary text-xs">
                  {" "}
                  Password is required
                </div>
              )}
              {/* <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction}
              ></i> */}
            </div>
            <div
              className={
                " mb-4" +
                (submitted && user_password_confirmation
                  ? "  border-red-500"
                  : "")
              }
            >
              <Input.Label
                htmlFor="user_password_confirmation"
                title={"Confirm Password*"}
                placeholder="Minimum of six(6) characters"
                type="password"
                autoComplete="off"
                name="user_password_confirmation"
                onChange={handleChange}
              />
              {submitted && !user_password_confirmation && (
                <div className="text-primary text-xs">
                  Password confirmation is required
                </div>
              )}
              {/* <i
                className="i-Eye-Visible toggleIcon"
                onClick={toggleFunction2}
              ></i> */}
            </div>
          </div>

          <div className="text-left row ">
            <div
              className={
                " mb-4" + (submitted && user_address ? "  border-red-500" : "")
              }
            >
              <Input.Label
                htmlFor="user_address"
                title={"Residential Address*"}
                placeholder="Enter address"
                type="text"
                name="user_address"
                autoComplete="off"
                onChange={handleChange}
              />
              {submitted && !user_address && (
                <div className="text-primary text-xs">
                  {" "}
                  Address is required.
                </div>
              )}
            </div>

            {/* Here, we utilize the location component to get the location data */}
            <Location
              country_id={locationId.country_id}
              state_id={locationId.state_id}
              city_id={locationId.city_id}
              countryList={(value) => {
                setLocationList({
                  ...locationList,
                  countryList: value,
                });
              }}
              stateList={(value) => {
                setLocationList({
                  ...locationList,
                  stateList: value,
                });
              }}
              cityList={(value) => {
                setLocationList({
                  ...locationList,
                  cityList: value,
                });
              }}
            />
            <div
              className={
                " mb-4" + (submitted && user_country ? "  border-red-500" : "")
              }
            >
              <label htmlFor="user_country"> Country*</label>
              <select
                name="user_country"
                placeholder="Select Country"
                className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    country_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select Country</option>
                {locationList.countryList &&
                  locationList.countryList.length > 0 &&
                  locationList.countryList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !user_country && (
                <div className="text-primary text-xs"> country is required</div>
              )}
            </div>
            <div
              className={
                " mb-4" + (submitted && user_state ? "  border-red-500" : "")
              }
            >
              <label htmlFor="user_state"> State*</label>

              <select
                name="user_state"
                placeholder="select State"
                className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    state_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select State</option>
                {locationList.stateList &&
                  locationList.stateList.length > 0 &&
                  locationList.stateList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !user_state && (
                <div className="text-primary text-xs">State is required.</div>
              )}
            </div>
            <div
              className={
                " mb-4" + (submitted && user_city ? "  border-red-500" : "")
              }
            >
              <label htmlFor="user_city">City*</label>

              <select
                name="user_city"
                placeholder="Select City"
                className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                onChange={(e) => {
                  setLocationId({
                    ...locationId,
                    city_id: fromJson(e.target.value).id,
                  });
                  handleRegion(e);
                }}
              >
                <option>Select City</option>
                {locationList.cityList &&
                  locationList.cityList.length > 0 &&
                  locationList.cityList.map((item, key) => {
                    return (
                      <option
                        key={key}
                        value={toJson({
                          name: item.name,
                          id: item.id,
                        })}
                      >
                        {item.name}
                      </option>
                    );
                  })}
              </select>
              {submitted && !user_city && (
                <div className="text-primary text-xs">City is required</div>
              )}
            </div>
          </div>
        </form>
      </div>
      <button
        form="register_form"
        type="submit"
        className=" rounded-md  w-[450px] sm:mt-10 mx-auto flex justify-center shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700  sm:text-sm"
      >
        {"Submit Employment Form"}
      </button>
    </div>
  );
}
