// Application types

const AUTH = {
  LOADING_AUTH: "LOADING_AUTH",
  LOGIN_USER: "LOGIN_USER",
  LOGOUT_USER: "LOGOUT_USER",
  REGISTER_USER: "REGISTER_USER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  RESET_PASSWORD: "RESET_PASSWORD",
  CHANGE_PASSWORD: "CHANGE_PASSWORD",
  VERIFY_USER_EMAIL: "VERIFY_USER_EMAIL",
  USER_DETAILS: "USER_DETAILS",
  REFRESHED_TOKEN: "REFRESHED_TOKEN",
  TEST_USER_MODEL: "USER_DETAILS",
};

export default AUTH;
