import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../../components/buttons/buttons";
import { useNavigate } from "react-router-dom";

import { Beat } from "../../../../../plugins/spinners.plugin";

import {
  approveStore,
  unApproveStore,
  getSingleStore,
} from "../../../../../redux/stores/storeActions";
import { getOutletFromCookies } from "../../../../../utils/Auth";

export default function StoreInformation() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const outlet = getOutletFromCookies();
  const { singleStore, approving } = useSelector((state) => state.store);
  function goToSinglePage() {
    navigate(`/admin/${outlet.store_name}/settings/store/${outlet?.id}/edit`, {
      state: { store: singleStore },
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }
  const approve = () => {
    dispatch(approveStore({ id: singleStore?.id }));
  };
  const unapprove = () => {
    dispatch(unApproveStore({ id: singleStore?.id }));
  };

  useEffect(() => {
    dispatch(getSingleStore(outlet?.id));

    // eslint-disable-next-line
  }, []);
  return (
    <div data-test="openingHours-page">
      <div className="bg-white border w-full px-4 sm:px-6 py-4 mt-8">
        <p>
          Manage your store details, make changes to your store details here.
        </p>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 my-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">{singleStore?.store_name}</h3>
          <Button.Primary
            title={
              approving ? (
                <Beat color={"#ffffff"} />
              ) : singleStore?.business_approved === true ? (
                "Unpublish Store"
              ) : (
                "Publish Store"
              )
            }
            disabled={approving}
            onClick={singleStore?.business_approved ? unapprove : approve}
          />
        </div>
        <div className="grid grid-cols-5 gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Store ID</label>
            <p className="text-[#151515] text-sm">{singleStore?.id}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_email}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone Number</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_phone}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] capitalize text-sm">
              {singleStore?.store_operating_status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm">
              {singleStore?.store_country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_state}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm">{singleStore?.store_city}</p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
            <p className="text-[#151515] text-sm">
              {singleStore.store_address}
            </p>
          </div>
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">
              Store Images
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-6 gap-8 mt-8">
          {singleStore?.store_pictures?.length > 0 ? (
            singleStore?.store_pictures?.map((image, index) => (
              <figure className="w-full h-[160px] relative" key={index}>
                <img
                  src={image}
                  className="w-[160px] h-[160px]"
                  alt="merchandise"
                />
              </figure>
            ))
          ) : (
            <p className="text-base font-medium ">
              No Images uploaded. Edit store to add Images
            </p>
          )}
        </div>
      </div>
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <div>
            <h3 className="text-lg font-medium">
              Store Banner
            </h3>
          </div>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-6 gap-8">
          {singleStore?.store_banners?.length > 0 ? (
            singleStore?.store_banners?.map((image, index) => (
              <figure className="w-full h-[200px] relative" key={index}>
                <img
                  src={image}
                  className="object-cover w-full h-full"
                  alt="merchandise"
                />
              </figure>
            ))
          ) : (
            <p className="text-base font-medium">
              No banners uploaded. Edit store to add Banner
            </p>
          )}
        </div>
      </div>
      <div className="mx-auto flex justify-center my-16">
        <Button.Primary title={"Edit Store"} onClick={goToSinglePage} />
      </div>
    </div>
  );
}
