import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { refuseInvite } from "../../../redux/invite/inviteActions";
import Button from "../../buttons/buttons";

export default function InviteModal({ modalControl, modalVisible, data }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAccept = () => {
    navigate(`/auth/inviteRegisteration?invite_code=${data.invite_code}`);
    modalControl();
  };
  const handleReject = () => {
    dispatch(refuseInvite(data.id));
    modalControl();
  };
  return (
    <>
      <Transition appear show={modalVisible}>
        <Dialog as="div" className="relative z-10" onClose={modalControl}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[315px]  rounded-sm  p-6  ">
                  <div>
                    <div className="mt-5 text-lg font-medium text-center">
                      <h5>Are you sure you want to accept this invite ?</h5>
                    </div>
                    <div className="mt-10 sm:mt-10">
                      <Button.Primary
                        title={"Accept Invite"}
                        className={"rounded-sm px-4 w-64 mb-3"}
                        onClick={handleAccept}
                      />
                      <Button.Secondary
                        title={"Ignore"}
                        className={"rounded-sm px-4 w-64"}
                        onClick={handleReject}
                      />
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
