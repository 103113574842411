import errorHandler from "../../handlers/errorHandler";
// import successHandler from "../../handlers/successHandler";
import { contactService } from '../../services/contact.service';
import contactType from "./contactTypes";

/**
 * Get all Contacts
 **/

export const getAllContactsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: true });
    const response = await contactService.getAllContacts(page);
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: contactType["ALL_CONTACTS"], payLoad: response });
  } catch (error) {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all Contacts
 **/

export const getAllContactsNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: contactType["DOWNLOADING"], payLoad: true });
    const response = await contactService.getAllContactsNoPagination(payload);
    dispatch({ type: contactType["DOWNLOADING"], payLoad: false });
    // dispatch({ type: contactType["ALL_CONTACTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a Contact
 **/

export const getAContactAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: true });
    const response = await contactService.getAContact(id);
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: contactType["SINGLE_CONTACT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Search all Contacts
 **/

export const searchContactsAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: contactType["SEARCHING"], payLoad: true });
    const response = await contactService.searchContacts(payload);
    dispatch({ type: contactType["SEARCHING"], payLoad: false });
    dispatch({ type: contactType["SEARCH_CONTACTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["SEARCHING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter all Contacts
 **/

export const filterContactsAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: contactType["FILTERING"], payLoad: true });
    const response = await contactService.filterContacts(payload, page);
    dispatch({ type: contactType["FILTERING"], payLoad: false });
    dispatch({ type: contactType["FILTER_CONTACTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const getAContactsAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: contactType["FILTERING"], payLoad: true });
    const response = await contactService.getAContact(payload);
    dispatch({ type: contactType["FILTERING"], payLoad: false });
    dispatch({ type: contactType["GET_CONTACT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["FILTERING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const DeleteAContactsAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: true });
    const response = await contactService.deleteAContact(payload);
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: contactType["GET_CONTACT"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

export const getContactWithRelationAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: contactType["LOADING_AUTH"], payLoad: true });
      const response = await contactService.getContactWithRelation(payload, page);
      dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
      dispatch({ type: contactType["ALL_CONTACTS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Filter all Contacts
 **/

export const editAContactAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: true });
    const response = await contactService.editAContact(payload, page);
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    // dispatch({ type: contactType["FILTER_CONTACTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};


/**
 * Filter all Contacts
 **/

export const showContactRolesAction = (user_id) => async (dispatch) => {
  try {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: true });
    const response = await contactService.showContactRoles(user_id);
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: contactType["SHOW_CONTACT_ROLES"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: contactType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};
