// Application types
const FOOD = {
  LOADING_FOOD: "LOADING_FOOD",
  UPDATING: "UPDATING",
  SEARCHING: "SEARCHING",
  APPROVING_FOOD: "APPROVING_FOOD",
  FILTERING_FOOD: "FILTERING_FOOD",
  DELETING_FOOD: "DELETING_FOOD",
  DOWNLOADING: "DOWNLOADING",
  STORE_FOOD: "STORE_FOOD",
  ALL_FOOD: "ALL_FOOD",
  SINGLE_FOOD: "SINGLE_FOOD",
  SEARCH_FOOD: "SEARCH_FOOD",
  FILTER_FOOD: "FILTER_FOOD",
  UPDATE_FOOD: "UPDATE_FOOD",
  DELETE_FOOD: "DELETE_FOOD",
  APPROVE_FOOD_BUSINESS: "APPROVE_FOOD_BUSINESS",
  UNAPPROVE_FOOD_BUSINESS: "UNAPPROVE_FOOD_BUSINESS",
  APPROVE_FOOD_MANAGEMENT: "APPROVE_FOOD_MANAGEMENT",
  UNAPPROVE_FOOD_MANAGEMENT: "UNAPPROVE_FOOD_MANAGEMENT",
  ALL_FOOD_NO_PAGINATION: "ALL_FOOD_NO_PAGINATION",
};

export default FOOD;
