// Application types

const PROFILE = {
  LOADING: "LOADING",
  MY_PROFILE: "MY_PROFILE",
  UPDATING: "UPDATING",
  FILTERING: "FILTERING",
  FILTER_VERIFICATION: "FILTER_VERIFICATION",
  SEARCHING: "SEARCHING",
  SHOW_A_VERIFICATION: "SHOW_A_VERIFICATION",
  SEARCH_VERIFICATION: "SEARCH_VERIFICATION",
  SHOW_MY_VERIFICATION: "SHOW_MY_VERIFICATION",
};

export default PROFILE;
