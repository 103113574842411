import React, { useState } from "react";
import SEARCH from "../../assets/images/icons/search.svg";
import DotMenu from "../../assets/images/icons/dot-menu.svg";

export default function TableFilter({
  results,
  searchPlaceholder,
  title,
  totalResults,
  filter,
  onChange,
  children,
  handleKeyDown,
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleBtn = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="bg-white rounded-md py-5 px-4 sm:px-8">
      <div className="mb-5 flex items-center justify-between">
        <p className="text-[#717485]">{title}</p>

        <div data-test="filterModal" className="relative block sm:hidden">
          <button
            className="flex text-[#717485] gap-1 items-center"
            onClick={toggleBtn}
            type="button"
          >
            <img src={DotMenu} alt="menu" />
          </button>
          {isOpen && (
            <div className="absolute right-0 z-10 w-[280px] border-[#E4E4F3] bg-white px-0 overflow-y-auto shadow-xl transform transition-all">
              <div className="grid grid-cols-1 gap-4 p-4">{children}</div>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between">
        <div className="flex flex-col flex-1 sm:flex-row items-start sm:items-center gap-3 relative w-full">
          <div className="w-full sm:w-auto">{filter}</div>
          <div className="w-full sm:w-[380px] relative">
            <input
              type={"Search"}
              name={"search"}
              autoComplete="off"
              placeholder={searchPlaceholder}
              className=" fa-search w-full sm:w-[380px] h-[36px] p-2 pr-6 border border-[#E4E4F3] focus-within:border-none focus:border-primary focus:outline-primary focus:ring-0 sm:text-sm"
              onChange={onChange}
              onKeyDown={handleKeyDown}
            />
            <img
              className="fa-search absolute top-[10px] right-4 h-4 "
              src={SEARCH}
              alt="search-icon"
            />
          </div>
        </div>
        <div className="flex sm:flex gap-3 hidden">{children}</div>
      </div>
    </div>
  );
}
