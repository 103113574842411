import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { notifierService } from "../../services/notifier.Service";
import notifierType from "./notifierTypes";

/** GetAllCategories **/
export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    let response = await notifierService.getAllCategories();

    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["GET_PRODUCT_CATEGORIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/** GetAllCategories **/
export const getOutletCategories = (page) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    let response = await notifierService.getOutletCategories(page);

    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["GET_PRODUCT_CATEGORIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * StoreANotifier
 * @param {object} payLoad{ name:"gaming",type: store}
 * @returns {void}
 * */
export const storeANotifier = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    const response = await notifierService.storeANotifier({
      ...payLoad,
    });
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["STORE_A_NOTIFIER"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete a notifier
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteANotifier = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["DELETING"],
      payLoad: true,
    });
    const response = await notifierService.deleteANotifier({
      ...payLoad,
    });
    dispatch({
      type: notifierType["DELETING"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["DELETE_A_NOTIFIER"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Delete a notifier
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const closeANotifier = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["DELETING"],
      payLoad: true,
    });
    const response = await notifierService.closeANotifier({
      ...payLoad,
    });
    dispatch({
      type: notifierType["DELETING"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["DELETE_A_NOTIFIER"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Edit a category
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editACategory = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    const response = await notifierService.editACategory({
      ...payLoad,
    });
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["EDIT_A_PRODUCT_CATEGORY"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Show a category
 * @param {object} payLoad {properties:1}
 * */
export const showANotifier = (payLoad) => async (dispatch) => {
  try {
    const response = await notifierService.showANotifier(payLoad);

    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["SHOW_A_NOTIFIER"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
export const searchNotifier = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["LOADING_SEARCH"],
      payLoad: false,
    });
    const response = await notifierService.searchNotifier({
      ...payLoad,
    });
    dispatch({
      type: notifierType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["SEARCH_NOTIFIER"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};
export const filterNotifier = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: notifierType["FILTERING"],
      payLoad: true,
    });
    const response = await notifierService.filterNotifier(payLoad, page);
    dispatch({
      type: notifierType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["FILTER_NOTIFIER"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: notifierType["FILTERING"],
      payLoad: false,
    });
  }
};
/**
 * TestCategoryModel
 * @param {object} 
  @returns {void}
 * */
export const testCategoryModel = () => async (dispatch) => {
  try {
    const response = await notifierService.testACategory({});

    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierType["TEST_PRODUCT_CATEGORY_MODEL"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: notifierType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
