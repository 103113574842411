import promoType from "./promoTypes";

const initialState = {
  allPromoCodes: {},
  filterNoPage: {},
  loading: false,
  deleting: false,
  filtering: false,
  searching: false,
  storeCoupon: {},
  singlePromoCode: {},
  deleteCoupon: {},
  myCoupons: {},
  emptyCoupon: {},
  filterCoupons: [],
  search: [],
  reloadCoupon: false,
};
export default function promoReducer(state = initialState, action) {
  switch (action.type) {
    case promoType.GET_ALL_PROMO:
      return {
        ...state,
        allPromoCodes: action.payLoad?.data,
      };
    case promoType.STORE_PROMO:
      return {
        ...state,
        storeCoupon: action.payLoad?.data?.data,
      };
    case promoType.DELETE_PROMO:
      return {
        ...state,
        deleteCoupon: action.payLoad.data.data,
      };

    case promoType.SHOW_PROMO:
      return {
        ...state,
        singlePromoCode: action.payLoad?.data,
      };
    case promoType.SHOW_MY_PROMO:
      return {
        ...state,
        myCoupons: action.payLoad?.data,
      };
    case promoType.FILTER_PROMO:
      return {
        ...state,
        allPromoCodes: action.payLoad?.data,
      };
    case promoType.FILTER_PROMO_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payLoad?.data,
      };
    case promoType.SEARCH_PROMO:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case promoType.LOADING_PROMO:
      return {
        ...state,
        loading: action.payLoad,
      };
    case promoType.DELETING:
      return {
        ...state,
        deleting: action.payLoad,
      };
    case promoType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case promoType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };
    default:
      return state;
  }
}
