import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import moment from "moment";
import Button from "../../../../components/buttons/buttons";
import { copyTextToClipboard } from "../../../../components/helpers";
import { ReactComponent as CopyIcon } from "../../../../assets/images/icons/copy-outline.svg";
import { getSingleFeedback } from "../../../../redux/feedback/feedbackActions";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function SingleFeedback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();

  const { singleFeedback, loadingFeedback } = useSelector(
    (state) => state.feedback
  );

  useEffect(() => {
    if (params?.id) {
      dispatch(getSingleFeedback(params?.id));
    }
    // eslint-disable-next-line
  }, [params?.id]);

  const goToOrder = () => {
    if (singleFeedback?.restaurant_order) {
      navigate(
        `/admin/orders/restaurant/${singleFeedback?.restaurant_order?.id}`
      );
    }
    if (singleFeedback?.store_order) {
      navigate(`/admin/orders/store/${singleFeedback?.store_order?.id}`);
    }
    if (singleFeedback?.subscription_food_order) {
      navigate(
        `/admin/orders/package-food/${singleFeedback?.subscription_food_order?.id}`
      );
    }
    if (singleFeedback?.subscription_merchandise_order) {
      navigate(
        `/admin/orders/package-merchandise/${singleFeedback?.subscription_merchandise_order?.id}`
      );
    }
  };

  return (
    <div data-test="singleOrder-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">Feedback Message </h3>
        </div>

        <p className="text-lg mt-6">{singleFeedback?.description}</p>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Date</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Time</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleFeedback?.created_at).format("MMM DD, YYYY")}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-8 pt-6">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 sm:mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Customer Details</h3>
              <Button.Primary
                title={"View"}
                disabled={loadingFeedback}
                onClick={() =>
                  navigate(`/admin/user/${singleFeedback?.user?.id}`, {
                    state: { user: singleFeedback?.user },
                  })
                }
              />
            </div>

            <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleFeedback?.user?.name}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
                <p className="text-[#151515] text-sm  font-bold">
                  {singleFeedback?.user?.email}
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Phone Number
                </label>
                <p className="text-[#151515] text-sm  font-bold">
                  {singleFeedback?.user?.phone}
                </p>
              </div>
              {/* <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
                <p className="text-[#151515] text-sm font-bold">
                  {singleFeedback?.user?.recipient_address}
                </p>
              </div> */}
            </div>
          </div>
        </div>

        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md px-4 sm:px-8 py-5 sm:mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
              <Button.Primary
                title={"View"}
                disabled={loadingFeedback}
                // className={"h-11 mt-6"}
                onClick={() => goToOrder()}
              />
            </div>

            {singleFeedback?.restaurant_order && (
              <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order date
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    {moment(
                      singleFeedback?.restaurant_order?.created_at
                    ).format("MMM DD, YYYY")}
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order ID
                  </label>
                  <p className="text-[#151515] text-sm font-bold flex">
                    <span className="flex items-center">
                      #
                      {singleFeedback?.restaurant_order?.id?.substr(0, 7) +
                        "...." +
                        singleFeedback?.restaurant_order?.id?.substr(
                          singleFeedback?.restaurant_order?.id?.length - 6
                        )}
                      <button
                        type="button"
                        onClick={() => {
                          copyTextToClipboard(
                            singleFeedback?.restaurant_order?.id
                          );
                        }}
                      >
                        <CopyIcon className="h-4 w-4 ml-1" />
                      </button>
                    </span>
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Amount
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    NGN{" "}
                    {numberWithCommas(
                      singleFeedback?.restaurant_order?.order_amount
                    )}
                  </p>
                </div>

                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Status
                  </label>
                  <div className="text-sm font-bold">
                    {singleFeedback?.restaurant_order?.status ===
                    "unshipped" ? (
                      <p className="text-primary text-sm font-bold">
                        Unshipped
                      </p>
                    ) : singleFeedback?.restaurant_order?.status ===
                      "shipped" ? (
                      <p className="text-[#046ECF] text-sm font-bold">
                        Shipped
                      </p>
                    ) : singleFeedback?.restaurant_order?.status ===
                      "closed" ? (
                      <p className="text-primary text-sm font-bold">Closed</p>
                    ) : singleFeedback?.restaurant_order?.status ===
                      "delivered" ? (
                      <p className="text-[#00D220] text-sm font-bold">
                        Delivered
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {singleFeedback?.store_order && (
              <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order date
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    {moment(singleFeedback?.store_order?.created_at).format(
                      "MMM DD, YYYY"
                    )}
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order ID
                  </label>
                  <p className="text-[#151515] text-sm font-bold flex">
                    <span className="flex items-center">
                      #
                      {singleFeedback?.store_order?.id?.substr(0, 7) +
                        "...." +
                        singleFeedback?.store_order?.id?.substr(
                          singleFeedback?.store_order?.id?.length - 6
                        )}
                      <button
                        type="button"
                        onClick={() => {
                          copyTextToClipboard(singleFeedback?.store_order?.id);
                        }}
                      >
                        <CopyIcon className="h-4 w-4 ml-1" />
                      </button>
                    </span>
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Amount
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    NGN{" "}
                    {numberWithCommas(
                      singleFeedback?.store_order?.order_amount
                    )}
                  </p>
                </div>

                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Status
                  </label>
                  <div className="text-sm font-bold">
                    {singleFeedback?.store_order?.status === "unshipped" ? (
                      <p className="text-primary text-sm font-bold">
                        Unshipped
                      </p>
                    ) : singleFeedback?.store_order?.status === "shipped" ? (
                      <p className="text-[#046ECF] text-sm font-bold">
                        Shipped
                      </p>
                    ) : singleFeedback?.store_order?.status === "closed" ? (
                      <p className="text-primary text-sm font-bold">Closed</p>
                    ) : singleFeedback?.store_order?.status === "delivered" ? (
                      <p className="text-[#00D220] text-sm font-bold">
                        Delivered
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {singleFeedback?.subscription_food_order && (
              <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order date
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    {moment(
                      singleFeedback?.subscription_food_order?.created_at
                    ).format("MMM DD, YYYY")}
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order ID
                  </label>
                  <p className="text-[#151515] text-sm font-bold flex">
                    <span className="flex items-center">
                      #
                      {singleFeedback?.subscription_food_order?.id?.substr(
                        0,
                        7
                      ) +
                        "...." +
                        singleFeedback?.subscription_food_order?.id?.substr(
                          singleFeedback?.subscription_food_order?.id?.length -
                            6
                        )}
                      <button
                        type="button"
                        onClick={() => {
                          copyTextToClipboard(
                            singleFeedback?.subscription_food_order?.id
                          );
                        }}
                      >
                        <CopyIcon className="h-4 w-4 ml-1" />
                      </button>
                    </span>
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Amount
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    NGN{" "}
                    {numberWithCommas(
                      singleFeedback?.subscription_food_order?.order_amount
                    )}
                  </p>
                </div>

                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Status
                  </label>
                  <div className="text-sm font-bold">
                    {singleFeedback?.subscription_food_order?.status ===
                    "unshipped" ? (
                      <p className="text-primary text-sm font-bold">
                        Unshipped
                      </p>
                    ) : singleFeedback?.subscription_food_order?.status ===
                      "shipped" ? (
                      <p className="text-[#046ECF] text-sm font-bold">
                        Shipped
                      </p>
                    ) : singleFeedback?.subscription_food_order?.status ===
                      "closed" ? (
                      <p className="text-primary text-sm font-bold">Closed</p>
                    ) : singleFeedback?.subscription_food_order?.status ===
                      "delivered" ? (
                      <p className="text-[#00D220] text-sm font-bold">
                        Delivered
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}

            {singleFeedback?.subscription_merchandise_order && (
              <div className="grid grid-cols-2 gap-4 sm:gap-8 mt-6">
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order date
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    {moment(
                      singleFeedback?.subscription_merchandise_order?.created_at
                    ).format("MMM DD, YYYY")}
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order ID
                  </label>
                  <p className="text-[#151515] text-sm font-bold flex">
                    <span className="flex items-center">
                      #
                      {singleFeedback?.subscription_merchandise_order?.id?.substr(
                        0,
                        7
                      ) +
                        "...." +
                        singleFeedback?.subscription_merchandise_order?.id?.substr(
                          singleFeedback?.subscription_merchandise_order?.id
                            ?.length - 6
                        )}
                      <button
                        type="button"
                        onClick={() => {
                          copyTextToClipboard(
                            singleFeedback?.subscription_merchandise_order?.id
                          );
                        }}
                      >
                        <CopyIcon className="h-4 w-4 ml-1" />
                      </button>
                    </span>
                  </p>
                </div>
                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Amount
                  </label>
                  <p className="text-[#151515] text-sm font-bold">
                    NGN{" "}
                    {numberWithCommas(
                      singleFeedback?.subscription_merchandise_order
                        ?.order_amount
                    )}
                  </p>
                </div>

                <div>
                  <label className="text-[#9CA3AF] text-sm mb-2">
                    Order Status
                  </label>
                  <div className="text-sm font-bold">
                    {singleFeedback?.subscription_merchandise_order?.status ===
                    "unshipped" ? (
                      <p className="text-primary text-sm font-bold">
                        Unshipped
                      </p>
                    ) : singleFeedback?.subscription_merchandise_order
                        ?.status === "shipped" ? (
                      <p className="text-[#046ECF] text-sm font-bold">
                        Shipped
                      </p>
                    ) : singleFeedback?.subscription_merchandise_order
                        ?.status === "closed" ? (
                      <p className="text-primary text-sm font-bold">Closed</p>
                    ) : singleFeedback?.subscription_merchandise_order
                        ?.status === "delivered" ? (
                      <p className="text-[#00D220] text-sm font-bold">
                        Delivered
                      </p>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
