import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Beat } from "../../../plugins/spinners.plugin";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import Warning from "../../../assets/images/icons/warning.svg";

import {
  filterTransactionsAction,
  getAllTransactionsNoPagination,
} from "../../../redux/walletTransaction/transactionActions";

export default function FilterWalletTransactionDropDown({
  isFilterOpen,
  setFilterState,
  payLoad = {},
}) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.food);

  const [errorMsg, setErrorMsg] = useState(false);
  const [data, setData] = useState({
    reference_id: "",
    amount: "",
    type: "",
    status: "",
    start_date: "",
    end_date: "",
  });
  const { reference_id, type, amount, status, start_date, end_date } = data;

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "restaurant_categories") {
      setData({ ...data, restaurant_categories: [value] });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleReset = async () => {
    setData({
      reference_id: "",
      amount: "",
      type: "",
      status: "",
      start_date: "",
      end_date: "",
    });
    await dispatch(getAllTransactionsNoPagination(1));
    setFilterState(!isFilterOpen);
  };

  const handleSubmit = async () => {
    cleanInput(data);
    if (reference_id || type || amount || status || start_date || end_date) {
      setErrorMsg(false);
      try {
        const response = await dispatch(
          filterTransactionsAction({
            ...data,
            ...payLoad,
          })
        );
        if (response?.status === "success") {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };

  return (
    <div data-test="">
      <form className="py-5 mt-4 px-5">
        <div className="grid grid-cols-1">
          <Input.Label
            title={"Reference ID"}
            htmlFor={"reference_id"}
            name={"reference_id"}
            onChange={handleChange}
            value={reference_id}
            autoComplete="off"
            placeholder={"Enter Reference ID"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />
          <Input.Label
            title={"Amount"}
            htmlFor={"amount"}
            name={"amount"}
            onChange={handleChange}
            value={amount}
            autoComplete="off"
            placeholder={"Enter Amount"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />
          <Input.Label
            title={"Transaction Type"}
            htmlFor={"type"}
            name={"type"}
            onChange={handleChange}
            value={type}
            autoComplete="off"
            placeholder={"Enter Transaction Type"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.Label
            title={"Status"}
            htmlFor={"status"}
            name={"status"}
            onChange={handleChange}
            value={status}
            autoComplete="off"
            placeholder={"Enter Status"}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
          />

          <Input.DatePicker
            title={"Start Date"}
            name="start_date"
            id="start_date"
            htmlFor={"start_date"}
            defaultValue={start_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
          <Input.DatePicker
            title={"End Date"}
            name="end_date"
            id="end_date"
            htmlFor={"end_date"}
            defaultValue={end_date}
            className="w-full border border-[#E4E4F3] focus:border-primary focus:ring-0 sm:text-sm"
            onChange={handleChange}
            selected
          />
        </div>
        <div className="py-8 grid grid-cols-2 gap-6 text-center">
          <Button.Secondary
            disabled={isLoading}
            title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
            className="px-4"
            onClick={handleReset}
            type="button"
          />
          <Button.Primary
            disabled={filtering}
            title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
            className="px-4"
            onClick={handleSubmit}
            type="button"
          />
        </div>
        {errorMsg && (
          <div className="flex gap-1 -mt-2">
            <img src={Warning} alt="warning" />
            <p>No value to filter by</p>
          </div>
        )}
      </form>
    </div>
  );
}
