import React, { useState } from "react";
import PLUS from "../../assets/images/icons/add-circle.svg";
import MINUS from "../../assets/images/icons/minus-cirlce.svg";
import DisabledPlus from "../../assets/images/icons/disabledAdd.svg";
import DisabledMinus from "../../assets/images/icons/disabledMinus.svg";
import Skipped from "../../assets/images/pictures/skipped.png";
import Placeholder from "../../assets/images/emptyState/restaurant-list.jpg";

export default function SelectedMeal({ meal, changeMeal }) {
  const [food_counter, setFoodCounter] = useState(1);

  const incrementCounter = () => {
    setFoodCounter(food_counter + 1);
  };

  const decrementCounter = () => {
    if (food_counter > 1) {
      setFoodCounter(food_counter - 1);
    }
  };

  const option = { weekday: "long" };
  const weekDay = meal?.date.toLocaleDateString("en-us", option);
  //this adds 0 before any number less than 10
  function minTwoDigits(n) {
    return (n < 10 ? "0" : "") + n;
  }
  const onImageError = (e) => {
    e.target.src = Placeholder;
  };

  return (
    <div data-test="selected-meal">
      {meal?.display ? (
        <div
          className="mb-6 w-full cursor-pointer"
          onClick={() => changeMeal(meal?.date)}
        >
          <div className="mb-2 flex items-center gap-2">
            <h2 className="text-md sm:text-2xl font-medium sm:mb-4">
              {weekDay}
            </h2>
          </div>
          {!meal.skipped ? (
            <div className="flex gap-2 sm:gap-4">
              <img
                src={meal?.food_pictures?.[0] ?? Placeholder}
                alt="food"
                onError={onImageError}
                className=" h-24 w-24 sm:h-[140px] sm:w-[140px] object-cover rounded"
              />
              <div className="flex gap-2 w-full justify-between">
                <div>
                  <h5 className="font-medium sm:text-[20px] text-sm">
                    {meal?.food_name}
                  </h5>
                  <p className="text-[#676565] w-full text-xs mt-1 sm:mt-4 sm:text-base w-[300px] text-ellipsis">
                    {meal?.food_description}
                  </p>
                </div>
                <div className="ml-1 text-center">
                  <p
                    className="text-primary font-bold sm:text-lg mt-6 sm:mt-0 text-sm cursor-pointer ml-4 sm:ml-0"
                    onClick={() => changeMeal(meal?.date)}
                  >
                    Change
                  </p>

                  <button
                    type="button"
                    className=" w-24 sm:w-[92px] mt-16 sm:mt-24 inline-flex  items-center h-11 sm:h-5 text-base font-medium  sm:text-sm ml-2 sm:ml-0"
                  >
                    <img
                      src={MINUS}
                      alt="decrement counter"
                      onClick={decrementCounter}
                      className=" w-6 h-6 sm:w-8 sm:h-8"
                    />

                    <p className=" mx-2 text-sm">
                      {minTwoDigits(food_counter)}
                    </p>
                    <img
                      src={PLUS}
                      alt="decrement counter"
                      onClick={incrementCounter}
                      className=" w-6 h-6 sm:w-8 sm:h-8"
                    />
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="flex gap-2 sm:gap-4">
              <img
                src={Skipped}
                alt="food"
                className="h-24 w-24 sm:h-[154px] sm:w-[140px] object-cover rounded"
              />
              <div className="flex gap-2 w-full justify-between">
                <div>
                  <h5 className="font-medium sm:text-[20px] text-sm">
                    You have skipped {weekDay}!
                  </h5>
                  <p className="text-[#676565] text-xs mt-1 sm:mt-4 sm:text-base max-w-[300px] text-ellipsis">
                    Click on{" "}
                    <span
                      className="text-primary font-bold"
                      onClick={() => changeMeal(meal.date)}
                    >
                      Change
                    </span>{" "}
                    to select meal for today or Ignore and continue. Select the
                    package that suits your health.
                  </p>
                </div>
                <div className="ml-1 text-center">
                  <p
                    className="text-primary font-bold sm:text-lg mt-6 sm:mt-0 text-sm ml-4 sm:ml-0 cursor-pointer"
                    onClick={() => changeMeal(meal.date)}
                  >
                    Change
                  </p>

                  <div
                    type="button"
                    className="w-24 sm:w-[92px] mt-16 sm:mt-24 inline-flex cursor-not-allowed items-center h-11 sm:h-5 text-base font-medium  sm:text-sm  ml-2 sm:ml-0"
                  >
                    <img
                      src={DisabledMinus}
                      alt="decrement counter"
                      className="w-6 h-6 sm:w-8 sm:h-8"
                    />
                    <p className="mx-2 text-sm">{minTwoDigits(food_counter)}</p>
                    <img
                      src={DisabledPlus}
                      alt="decrement counter"
                      className=" w-6 h-6 sm:w-8 sm:h-8"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </div>
  );
}
