import React from "react";
import Container from "../../../components/container/container";
import Card from "../../../components/cards/cards";
import { ReactComponent as SearchIcon } from "../../../assets/images/icons/search.svg";

const restaurants = [
  "Golden Toast",
  "Ntachi Osa",
  "Daisy Life",
  "Sheraton",
  "Mama Onyinye",
  "Octopus",
  "Pizza Jungle",
  "Chops n More",
];
const menus = [
  {
    title: "Pancake Drip",
    amount: "$2500",
    outlet: "Breakfast Co",
  },
  {
    title: "Burger and chips platter",
    amount: "$2500",
    outlet: "Fire factory",
  },
  {
    title: "Asun Kebab",
    amount: "$2500",
    outlet: "Breakfast Co",
  },
  {
    title: "Fish Meal",
    amount: "$2500",
    outlet: "Breakfast Co",
  },
  {
    title: "Food snacks",
    amount: "$2500",
    outlet: "Breakfast Co",
  },
  {
    title: "Asun Kebab",
    amount: "$2500",
    outlet: "Breakfast Co",
  },
  {
    title: "Pancake Drip",
    amount: "$2500",
    outlet: "Breakfast Co",
  },
  {
    title: "Burger and chips platter",
    amount: "$2500",
    outlet: "Fire factory",
  },
];

export default function Search() {
  return (
    <div className="category" data-test="categoryListComponent">
      <Container>
        <div className="w-full mt-5 sm:hidden">
          <label htmlFor="search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="pointer-events-none absolute inset-y-0 left-0 pl-3 flex items-center">
              <SearchIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            </div>
            <input
              id="search"
              name="search"
              className="block w-full bg-white border border-gray-200 rounded-md py-2 pl-10 pr-3 text-sm placeholder-gray-500 focus:outline-none focus:text-gray-900 focus:placeholder-gray-400 focus:ring-1 text-sm"
              placeholder="Search anything"
              type="search"
            />
          </div>
        </div>
        <div className="py-5 mt-2 sm:mt-12">
          <div className="flex justify-between gap-4 items-center mb-2">
            <div>
              <h3 className="text-2xl sm:text-3xl font-bold mb-2">
                Showing results for: "Search item"
              </h3>
              <p className="text-sm">92 results found</p>
            </div>
          </div>
        </div>
      </Container>

      <Container>
        <div className="py-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">Stores (8)</h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
            {restaurants.map((restaurant) => (
              <Card.Restaurants title={restaurant} />
            ))}
          </div>
        </div>
      </Container>

      <Container>
        <div className="py-8">
          <div>
            <h3 className="text-2xl font-bold mb-4">Food Menu (84)</h3>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 sm:gap-8">
            {menus.map((menu) => (
              <Card.Menu
                title={menu.title}
                amount={menu.amount}
                outlet={menu.outlet}
              />
            ))}
          </div>
        </div>
      </Container>
    </div>
  );
}
