import errorHandler from "../../handlers/errorHandler";
import publicStoreType from "./publicStoreTypes";
import { publicStoreService } from "./../../services/publicStore";

/**
 * Get all Store
 **/

export const getAllPublicStore = (page) => async (dispatch) => {
  try {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: true });
    const response = await publicStoreService.getAllPublicStores(page);
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    dispatch({ type: publicStoreType["ALL_PUBLIC_STORES"], payLoad: response });
  } catch (error) {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all Store
 **/

export const getMorePublicStore = (page) => async (dispatch) => {
  try {
    dispatch({ type: publicStoreType["LOADING_MORE_STORES"], payLoad: true });
    const response = await publicStoreService.getAllPublicStores(page);
    dispatch({ type: publicStoreType["LOADING_MORE_STORES"], payLoad: false });
    dispatch({
      type: publicStoreType["MORE_PUBLIC_STORES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: publicStoreType["LOADING_MORE_STORES"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a Store
 **/

export const getPublicStore = (id) => async (dispatch) => {
  try {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: true });
    const response = await publicStoreService.getPublicStore(id);
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    dispatch({
      type: publicStoreType["SINGLE_PUBLIC_STORE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter Store
 **/

export const filterPublicStore = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: true });
    const response = await publicStoreService.filterPublicStore(payload, page);

    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    dispatch({
      type: publicStoreType["FILTER_PUBLIC_STORE"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter all Store
 **/

export const filterMorePublicStore = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: publicStoreType["LOADING_MORE_STORES"], payLoad: true });
    const response = await publicStoreService.filterPublicStore(payload, page);
    dispatch({ type: publicStoreType["LOADING_MORE_STORES"], payLoad: false });
    dispatch({
      type: publicStoreType["MORE_PUBLIC_STORES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: publicStoreType["LOADING_MORE_STORES"], payLoad: false });
    errorHandler(error, true);
  }
};

export const searchPublicStore = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: true });
    const response = await publicStoreService.searchPublicStore(payLoad);
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    dispatch({
      type: publicStoreType["SEARCH_PUBLIC_STORE"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: publicStoreType["LOADING_PUBLIC_STORE"], payLoad: false });
    errorHandler(error, true);
  }
};
