import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Warning from "../../../assets/images/icons/warning.svg";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import { cleanInput } from "../../../helpers/cleanInput";
import { filterCarts, getAllCarts } from "../../../redux/cart/cartAction";
import { Beat } from "../../../plugins/spinners.plugin";

export default function FilterCartDropDown({ isFilterOpen, setFilterState }) {
  const dispatch = useDispatch();
  const { isLoading, filtering } = useSelector((state) => state.cart);

  const [errorMsg, setErrorMsg] = useState(false);
  const [productDetails, setProductDetails] = useState({
    product_id: "",
    product_outlet_name: "",
    product_outlet_type: "",
  });
  const [data, setData] = useState({
    user_id: "",
    amount: "",
    products: [],
  });
  const { user_id, amount, products } = data;
  const { product_id, product_outlet_name, product_outlet_type } =
    productDetails;
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const handleReset = async () => {
    setData({
      user_id: "",
      amount: "",
      products: [],
    });
    await dispatch(getAllCarts());
    setFilterState(!isFilterOpen);
  };
  const addproductDetails = () => {
    if (product_id !== "") {
      products.push(productDetails);
    }
    if (product_outlet_name !== "") {
      products.push(productDetails);
    }
    if (product_outlet_type !== "") {
      products?.push(productDetails);
    }
  };
  const handleSubmit = async () => {
    addproductDetails();
    cleanInput(data);
    if (
      user_id ||
      amount ||
      product_outlet_name ||
      product_id ||
      product_outlet_type
    ) {
      setErrorMsg(false);
      try {
        const response = await dispatch(filterCarts({ ...data }));
        if (response) {
          setFilterState(!isFilterOpen);
        }
      } catch (error) {
        console.log(error);
        setFilterState(!isFilterOpen);
      }
    } else {
      setErrorMsg(true);
    }
  };
  return (
    <>
      <div className="py-5 mt-4 px-5">
        <form className="text-left">
          <div className="grid grid-cols-1">
            <Input.Label
              title={"Cart Id"}
              htmlFor={"user_id"}
              name={"user_id"}
              onChange={handleChange}
              placeholder={"Enter Cart Id"}
            />
            <Input.Number
              title={"Amount"}
              htmlFor={"amount"}
              name={"amount"}
              onChange={handleChange}
              placeholder={"Enter Amount"}
            />

            <Input.Number
              title={"Food/Merchandise Id"}
              htmlFor={"product_id"}
              name={"product_id"}
              onChange={(e) => {
                setProductDetails({
                  ...productDetails,
                  product_id: e.target.value,
                });
              }}
              placeholder={"Enter Product Id"}
            />
            <div>
              <Input.Select
                title={"Select Outlet type"}
                name={"product_outlet_type"}
                htmlFor={"product_outlet_type"}
                defaultValue={product_outlet_type}
                onChange={(e) => {
                  setProductDetails({
                    ...productDetails,
                    product_outlet_type: e.target.value,
                  });
                }}
              >
                <option>Select outlet type for order</option>
                <option value="store">Store</option>
                <option value="restaurant">Restaurant</option>
              </Input.Select>
            </div>
            <Input.Label
              title={"Outlet Name"}
              htmlFor={"product_outlet_name"}
              name={"product_outlet_name"}
              onChange={(e) => {
                setProductDetails({
                  ...productDetails,
                  product_outlet_name: e.target.value,
                });
              }}
              placeholder={"Enter Outlet Name"}
            />
          </div>
          
          <div className="py-8 grid grid-cols-2 gap-6 text-center">
            <Button.Secondary
              disabled={isLoading}
              title={isLoading ? <Beat color={"#ce2600"} /> : "Reset"}
              className="px-4"
              onClick={handleReset}
              type="button"
            />
            <Button.Primary
              disabled={filtering}
              title={filtering ? <Beat color={"#ffffff"} /> : "Filter"}
              className="px-4"
              onClick={handleSubmit}
              type="button"
            />
          </div>

          {errorMsg && (
            <div className="flex gap-1 -mt-2">
              <img src={Warning} alt="warning" />
              <p>No value to filter by</p>
            </div>
          )}
        </form>
      </div>
    </>
  );
}
