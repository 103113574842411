import catalogueType from "./catalogueTypes";

const initialState = {
  isLoading: false,
  approving: false,
  filtering: false,
  create: {},
  myCatalogue: {},
  catalogue: {},
  allCatalogues: {},
  filterNoPage: {},
  unaApproved: {},
  approved: {},
  search: [],
};

export default function catalogueReducer(state = initialState, action) {
  switch (action.type) {
    case catalogueType.LOADING_CATALOGUE:
      return {
        ...state,
        isLoading: action.payLoad,
      };
    case catalogueType.FILTERING:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case catalogueType.CREATE_CATALOGUE:
      return {
        ...state,
        create: action.payLoad?.data,
      };
    case catalogueType.FILTER_CATALOGUE:
      return {
        ...state,
        allCatalogues: action.payLoad?.data,
      };
    case catalogueType.FILTER_CATALOGUE_NOPAGE:
      return {
        ...state,
        filterNoPage: action.payLoad?.data,
      };
    case catalogueType.LOADING_SEARCH:
      return {
        ...state,
        searching: action.payLoad,
      };

    case catalogueType.SEARCH_CATALOGUE:
      return {
        ...state,
        search: action.payLoad?.data,
      };
    case catalogueType.APPROVING:
      return {
        ...state,
        approving: action.payLoad,
      };

    case catalogueType.MY_CATALOGUE:
      return {
        ...state,
        myCatalogues: action.payLoad?.data,
      };
    case catalogueType.GET_A_CATALOGUE:
      return {
        ...state,
        catalogue: action.payLoad?.data,
      };

    case catalogueType.UPDATE_CATALOGUE:
      return {
        ...state,
        myCatalogue: action.payLoad?.data,
      };
    case catalogueType.GET_ALL_CATALOGUES:
      let unapprovedData = {};
      if (action.payLoad?.data === undefined) {
        unapprovedData = {};
      } else {
        unapprovedData = action.payLoad?.data;
      }
      return {
        ...state,
        allCatalogues: unapprovedData,
      };
    case catalogueType.APPROVE_CATALOGUE:
      let approved = {
        ...state.catalogue,
        management_approved: 1,
      };
      return {
        ...state,
        catalogue: approved,
      };
    case catalogueType.UNAPPROVE_CATALOGUE:
      let unApproved = {
        ...state.catalogue,
        management_approved: 0,
      };
      return {
        ...state,
        catalogue: unApproved,
      };

    default:
      return state;
  }
}
