import React, { lazy, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
// Navigation
import NavBarUser from "../components/navigation/NavBarUser";
import NavBarCommon from "../components/navigation/NavBarCommon";
import FooterCommon from "../components/footer/FooterCommon";
// Pages
// import Home from "../pages/user/home";
import RestaurantList from "../pages/user/listing/RestaurantList";
import StoreList from "../pages/user/listing/StoreList";
import CategoryRestaurant from "../pages/user/listing/categoryList/Restaurant";
import FoodPackages from "../pages/user/listing/FoodPackages";
// import StoreCheckout from "../pages/user/guestCheckout/StoreCheckout";
import LazyLoader from "../components/LazyLoader";
import StoreCategoryList from "../pages/user/listing/categoryList/storeCategoryList";

export default function UserLayout() {
  const UserHome = lazy(() => import("../pages/user/home"));

  return (
    <React.Fragment>
      <main className="relative bg-white min-h-screen">
        <NavBarCommon />
        <NavBarUser />

        <section>
          {/* Body */}
          <Routes>
            <Route
              path="/home"
              exact
              element={
                <Suspense fallback={<LazyLoader />}>
                  <UserHome />
                </Suspense>
              }
            />
            <Route path="/restaurants" exact element={<RestaurantList />} />
            <Route path="/stores" exact element={<StoreList />} />
            <Route
              path="/restaurant-category/:name"
              exact
              element={<CategoryRestaurant />}
            />
            <Route
              path="/store-category/:name"
              exact
              element={<StoreCategoryList />}
            />
            <Route path="/food-packages" exact element={<FoodPackages />} />

            <Route path="/*" element={<Navigate to="/404" replace />} />
          </Routes>
        </section>
        <FooterCommon />
      </main>
    </React.Fragment>
  );
}
