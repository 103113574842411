import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import Input from "../../../../components/inputs/inputs";
import Button from "../../../../components/buttons/buttons";
import { Beat } from "../../../../plugins/spinners.plugin";
import { ShipperOperatingHours } from "../../../../components/modals/admin/shippers/shipperOperatingHours";

export default function ShippersRate2({
  handleChange,
  handleBack2,
  data,
  handleSubmit,
  submitted,
  removebyDelivery,
  setData,
}) {
  const { creating } = useSelector((state) => state.shipper);

  useEffect(() => {
    setData({
      ...data,
      base_fees_by_delivery_mode: [
        {
          title: "",
          charge: "",
          category: "",
        },
      ],
    });
    // eslint-disable-next-line
  }, []);
  const {
    weight_limit_by_kilogram,
    base_fees_by_delivery_mode,
    base_fee_per_kilogram,
    vat_percentage,
  } = data;

  const addFormField3 = () => {
    setData({
      ...data,
      base_fees_by_delivery_mode: [
        ...data.base_fees_by_delivery_mode,
        {
          title: "",
          charge: "",
          category: "",
        },
      ],
    });
  };

  const handleDynamicChange = (e, index) => {
    const { name, value } = e.target;
    let oldBaseBydelivery = [...base_fees_by_delivery_mode];
    oldBaseBydelivery[index][name] = value;

    setData({
      ...data,
      base_fees_by_delivery_mode: oldBaseBydelivery,
    });
  };

  const removeFile2 = (i) => {
    let newArr = base_fees_by_delivery_mode.filter(
      (item, index) => index !== i
    );
    console.log("newArr", newArr);
    removebyDelivery(newArr);
  };

  const checkSubmit = () => {
    let latest = {};

    if (base_fees_by_delivery_mode?.length < 1) {
      latest["base_fees_by_delivery_mode"] = {
        title: "",
        charge: "",
        category: "",
      };
    }

    setData((data) => ({
      ...data,
      base_fees_by_delivery_mode: base_fees_by_delivery_mode,
      base_fee_per_kilogram: base_fee_per_kilogram,
    }));
    // handleSubmit();
    handleSubmit(latest);
  };
  return (
    <>
      <div className=" mx-auto bg-white sm:w-[630px] w-full mt-10 sm:ml-10 sm:mt-0 sm:border px-6 sm:border-gray-200">
        <div className="border-b-2 py-4 mb-6">
          <p className=" text-base font-medium text-gray-800 flex justify-center ">
            Shipper Rate
          </p>
        </div>
        <form name="form" id="shippers-form" onSubmit={(e) => handleSubmit(e)}>
          <div className="sm:px-10">
            <div className="mb-8">
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg font-bold text-[#151515]">
                  Maximum supported weight (Kg) :
                </h3>
                <p className="text-[#4D4D4DB2] text-sm">
                  This is the maximum supported weight per delivery vessel of a
                  company depending on the category.
                </p>
              </div>
              <Input.Label
                title={"Maximum Weight"}
                htmlFor={"weight_limit_by_kilogram"}
                name={"weight_limit_by_kilogram"}
                placeholder={"Enter maximum weight"}
                value={weight_limit_by_kilogram}
                onChange={handleChange}
              />
              {submitted && !weight_limit_by_kilogram && (
                <div className="text-primary text-xs ">
                  Maximum weight is required
                </div>
              )}
            </div>

            <div className="mb-8">
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg font-bold text-[#151515]">
                  Charge per weight (1kg) :
                </h3>
                <p className="text-[#4D4D4DB2] text-sm">
                  This is the charge per weight of customer item depending on
                  category.
                </p>
              </div>
              <div>
                <Input.Label
                  title={"Charge Per Weight"}
                  htmlFor={"base_fee_per_kilogram"}
                  name={"base_fee_per_kilogram"}
                  placeholder={"NGN 500"}
                  onChange={handleChange}
                />
                {submitted && !base_fee_per_kilogram && (
                  <div className="text-primary text-xs ">
                    Charge is required
                  </div>
                )}
              </div>
            </div>

            <div className="mb-8">
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg font-bold text-[#151515]">
                  VAT Percentage (%) :
                </h3>
                <p className="text-[#4D4D4DB2] text-sm">
                  This is a company's VAT.
                </p>
              </div>
              <div>
                <Input.Label
                  title={"VAT Percentage"}
                  htmlFor={"vat_percentage"}
                  name={"vat_percentage"}
                  placeholder={"Enter VAT percentage"}
                  onChange={handleChange}
                />
                {submitted && !vat_percentage && (
                  <div className="text-primary text-xs ">
                    VAT percentage is required
                  </div>
                )}
              </div>
            </div>

            <div>
              <div className="border border-gray-300 py-2 px-2 mb-2">
                <h3 className="block text-lg  font-bold text-[#151515]">
                  Cost Per Delivery Mode :
                </h3>
                <p className="text-[#4D4D4DB2] text-sm">
                  This is the cost per vehicle type
                </p>
              </div>
              {base_fees_by_delivery_mode?.map((element, index) => (
                <div key={index}>
                  <div className="grid grid-cols-2 gap-2">
                    <Input.Label
                      title={"Vehicle"}
                      htmlFor={"title"}
                      name={"title"}
                      onChange={(e) => handleDynamicChange(e, index)}
                      placeholder={"EG: Car, Bike, Airplane"}
                      defaultValue={element.title || ""}
                    />
                    <div>
                      <Input.Label
                        title={"Charge"}
                        htmlFor={"charge"}
                        name={"charge"}
                        onChange={(e) => handleDynamicChange(e, index)}
                        placeholder={"NGN 500"}
                        defaultValue={element.charge || ""}
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <Input.Select
                        title={"Category"}
                        htmlFor={"category"}
                        name={"category"}
                        placeholder={"NGN 500"}
                        onChange={(e) => handleDynamicChange(e, index)}
                        defaultValue={element.category || ""}
                      >
                        <option>Select a category</option>
                        <option value={"fragile"}>Fragile</option>
                        <option value={"none fragile"}>Non-Fragile</option>
                      </Input.Select>
                      {submitted && !base_fees_by_delivery_mode && (
                        <div className="text-primary text-xs ">
                          Category is required
                        </div>
                      )}
                    </div>
                    <div>
                      <label className="block text-sm font-medium text-[#151515] mb-2">
                        Operating Hours
                      </label>
                      <ShipperOperatingHours
                        index={index}
                        data={data}
                        setData={setData}
                      />
                      {submitted && !base_fees_by_delivery_mode && (
                        <div className="text-primary text-xs ">
                          Operating Hours is required
                        </div>
                      )}
                    </div>
                  </div>

                  {base_fees_by_delivery_mode?.length > 1 && (
                    <div className="w-full text-right">
                      <button
                        className="text-primary text-xs font-medium"
                        type="button"
                        onClick={() => removeFile2(index)}
                      >
                        Remove
                      </button>
                    </div>
                  )}
                </div>
              ))}
              <p
                className="text-primary text-xs text-right font-medium mb-4 cursor-pointer"
                onClick={() => addFormField3()}
              >
                Add more +
              </p>
              {/* <div>
                <div>
                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <Input.Label
                        title={"Vehicle"}
                        htmlFor={"title"}
                        name={"title"}
                        placeholder={"EG: Car, Bike, Airplane"}
                        value={baseBydelivery.title}
                        onChange={(e) => {
                          setBaseByDelivery({
                            ...baseBydelivery,
                            title: e.target.value,
                          });
                        }}
                      />
                      {submitted && !base_fees_by_delivery_mode && (
                        <div className="text-primary text-xs ">
                          Vehicle is required
                        </div>
                      )}
                    </div>
                    <div>
                      <Input.Label
                        title={"Charge"}
                        htmlFor={"charge"}
                        name={"charge"}
                        placeholder={"NGN 500"}
                        value={baseBydelivery.charge}
                        onChange={(e) => {
                          setBaseByDelivery({
                            ...baseBydelivery,
                            charge: e.target.value,
                          });
                        }}
                      />
                      {submitted && !baseBydelivery.charge && (
                        <div className="text-primary text-xs ">
                          Charge is required
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="grid grid-cols-2 gap-2">
                    <div>
                      <Input.Select
                        title={"Category"}
                        htmlFor={"category"}
                        name={"category"}
                        placeholder={"NGN 500"}
                        value={baseBydelivery.category}
                        onChange={(e) => {
                          setBaseByDelivery({
                            ...baseBydelivery,
                            category: e.target.value,
                          });
                        }}
                      >
                        <option value=""> Select Category</option>
                        <option value={"fragile"}>Fragile</option>
                        <option value={"none fragile"}>Non-Fragile</option>
                      </Input.Select>
                      {submitted && !baseBydelivery.category && (
                        <div className="text-primary text-xs ">
                          Category is required
                        </div>
                      )}
                    </div>

                    <div>
                      <ShipperOperatingHours />
                      {submitted && !base_fees_by_delivery_mode && (
                        <div className="text-primary text-xs ">
                          Operating Hours is required
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <p
                  className="text-primary text-xs text-right font-medium mb-4 cursor-pointer"
                  onClick={() => addFormField3()}
                >
                  {" "}
                  Add more +
                </p>
              </div> */}
            </div>
          </div>
        </form>
      </div>
      <div className="flex justify-center mx-auto sm:mt-20  ">
        <Button.Secondary
          title={"Previous"}
          className={"px-2 rounded-sm mr-4"}
          onClick={handleBack2}
        />
        <Button.Primary
          type={"button"}
          title={creating ? <Beat color={"#ffffff"} /> : "Create Shipper"}
          className={"w-[135px] ml-2  rounded-sm "}
          onClick={checkSubmit}
        />
      </div>
    </>
  );
}
