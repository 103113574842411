import axios from "../plugins/axios.plugin";

class EmployeesService {
  baseServicePath = "/api/employee";

  approveEmployee(payload) {
    return axios.post(`${this.baseServicePath}/approve/business`, payload);
  }
  unApproveEmployee(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/business`, payload);
  }
  terminateEmployee(payload) {
    return axios.post(`${this.baseServicePath}/terminate`, payload);
  }
  getAllEmployees(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  filterEmployee(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  filterEmployeeNoPage(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?pagination=0`,
      payload
    );
  }
  searchEmployee(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showAnEmployee(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}&properties=1`);
  }
  getMyEmployee(payload) {
    return axios.get(`${this.baseServicePath}/me`, payload);
  }
  showEmployeeLocation(payload) {
    return axios.get(`${this.baseServicePath}/locate?id=${payload}`);
  }
  deleteEmployee(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  editEmployee(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  setEmployeeActive(payload) {
    return axios.post(`${this.baseServicePath}/update/status`, payload);
  }
  setEmployeeInactive(payload) {
    return axios.post(`${this.baseServicePath}/update/status`, payload);
  }
}

export const employeesService = new EmployeesService();
