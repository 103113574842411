import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Heart from "../../assets/images/icons/heart-circle.svg";
import Placeholder from "../../assets/images/emptyState/restaurant-list.jpg";
import { getAPublicPackageAction } from "../../redux/publicPackages/publicPackagesActions";

export default function ChooseMeal({
  setCurrentMeal,
  weekDay,
  subscriptionDays,
  setSubscriptionDays,
  selectedFoods,
  setSelectedFoods,
}) {
  const dispatch = useDispatch();
  const { id } = useParams();
  let day = weekDay.toLowerCase();
  const { singlePackage } = useSelector((state) => state.publicPackages);

  let food_of_day = singlePackage?.package_products?.[day].map((e, i) => {
    let temp = singlePackage?.subscription_foods.find(
      (element) => Number(element.id) === Number(e)
    );
    return temp;
  });

  useEffect(() => {
    dispatch(getAPublicPackageAction(id));
    // eslint-disable-next-line
  }, []);

  const handleSelection = (order) => {
    let newObj = {
      [day]: {
        subscription_product_outlet_id: order?.restaurant_id,
        subscription_product_id: order?.id,
        subscription_product_quantity: 1,
      },
    };

    setSubscriptionDays({ ...subscriptionDays, ...newObj });
  };

  const onImageError = (e) => {
    e.target.src = Placeholder;
  };

  const selectFood = (day, index) => {
    setSelectedFoods((prevSelectedFoods) => ({
      ...prevSelectedFoods,
      [day]: index,
    }));
  };

  return (
    <div className="sm:max-w-2xl mb-12 relative" data-test="choose-meal">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
        {food_of_day?.map((order, index) => (
          <div key={index}>
            {typeof order !== "undefined" && (
              <div
                className={
                  selectedFoods && selectedFoods[day] === index
                    ? `bg-[#d9d9d9]  mb-3 cursor-pointer rounded  `
                    : `mb-3 cursor-pointer bg-none`
                }
                onClick={() => {
                  setCurrentMeal({ ...order, index });
                  handleSelection(order);
                  selectFood(day, index);
                }}
              >
                {selectedFoods && selectedFoods[day] === index && (
                  <div className="absolute ml-[298px] sm:ml-60 sm:mt-4 mt-3">
                    <img src={Heart} alt="" className="ml-2" />
                    <p className="text-sm mt-1 text-white">Selected</p>
                  </div>
                )}

                <img
                  src={order?.food_pictures?.[0 ?? Placeholder]}
                  alt="food"
                  onError={onImageError}
                  className="h-52 mb-1 w-full object-cover rounded"
                />
                <h5 className="pt-5 pb-1 text-lg font-medium">
                  {order?.food_name}
                </h5>
                <p className="text-[#676565] text-sm">
                  {order?.food_description}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
