import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { humanVerificationLevelService } from '../../services/humanVerifiationLevel.service';
import actionType from "./humanVerificationLevelTypes";

export const editHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationLevelService.editHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const storeHumanVerificationLevel = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["LOADING"], payLoad: true });
    const response =
      await humanVerificationLevelService.storeHumanVerificationLevel(payLoad);
    dispatch({ type: actionType["LOADING"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: actionType["LOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const filterHumanVerificationLevel = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: actionType["FILTERING"], payLoad: true });
    const response = await humanVerificationLevelService.filterHumanVerificationLevel(
      payLoad,
      page
    );
    dispatch({ type: actionType["FILTERING"], payLoad: false });
    dispatch({
      type: actionType["FILTER_VERIFICATION_LEVEL"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: actionType["FILTERING"], payLoad: false });
  }
};

export const searchHumanVerificationLevel = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["SEARCHING"], payLoad: true });
    const response =
      await humanVerificationLevelService.searchHumanVerificationLevel({
        ...payLoad,
      });
    dispatch({ type: actionType["SEARCHING"], payLoad: false });
    dispatch({
      type: actionType["SEARCH_VERIFICATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: actionType["SEARCHING"], payLoad: false });
  }
};

export const showAHumanVerification = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["LOADING"], payLoad: true });
    const response = await humanVerificationLevelService.showAHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["LOADING"], payLoad: false });
    dispatch({
      type: actionType["SHOW_A_VERIFICATION"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({ type: actionType["LOADING"], payLoad: false });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const verifyHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationLevelService.verifyHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

export const rejectHumanVerificationAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: actionType["UPDATING"], payLoad: true });
    const response = await humanVerificationLevelService.rejectHumanVerification(
      payLoad
    );
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    successHandler(response, true);
  } catch (error) {
    dispatch({ type: actionType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};
