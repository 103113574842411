import React, { useMemo, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/buttons/buttons";
import Table from "../../../../components/tables/tableCols1";
import TableSkeleton from "../../../../components/skeleton/table";
import TableFilter from "../../../../components/tables/tableFilter";
import { Pagination } from "../../../../components/pagination/pagination";
import {
  filterMerchandises,
  filterMerchandisesNoPage,
  searchAllMerchandises,
} from "../../../../redux/merchandise/merchandiseActions";
import Merchandise from "../../../../assets/images/icons/merchandiseEmpty.svg";
import { writeFileWithXLSX } from "../../../../components/exports/xlsx";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterMerchandise from "../../../../components/dropDown/filter/merchandiseFilter";
import { Beat } from "../../../../plugins/spinners.plugin";
import merchandiseType from "../../../../redux/merchandise/merchandiseTypes";

function numberWithCommas(x) {
  return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export default function UnApprovedMerchandise() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let params = useParams();

  const { allMerchandises, downloading, filtering, filterNoPage, searching } =
    useSelector((state) => state.merchandise);
  // eslint-disable-next-line no-unused-vars
  const [payLoad] = useState({
    management_approved: 0,
  });

  const [data, setData] = useState([]);
  const [isFilterOpen, setFilterState] = useState(false);
  const [input, setInput] = useState({
    search: "",
  });

  useEffect(() => {
    dispatch(filterMerchandises(payLoad, params?.page));
    dispatch(filterMerchandisesNoPage(payLoad));
    // eslint-disable-next-line
  }, [params]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((merchandise, index) => {
        let single = {
          num: <div className="text-sm">{index + 1}</div>,
          name: <div className="text-sm">{merchandise?.merchandise_name}</div>,
          price: (
            <div className="text-sm">
              {numberWithCommas(merchandise?.merchandise_amount)}
            </div>
          ),
          availability:
            merchandise?.merchandise_operating_status === "available" ? (
              <p className=" text-sm ">Available</p>
            ) : (
              <p className=" text-sm">Not Available</p>
            ),
          approval:
            merchandise?.management_approved === true ? (
              <p className="font-bold text-sm text-green-500">Approved</p>
            ) : (
              <p className="font-bold text-sm text-primary">Unapproved</p>
            ),
          merchandise: merchandise,
        };

        outputArray.push({ single });

        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(allMerchandises?.data);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allMerchandises]);

  function createTableNoPage(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap?.map((merchandise, index) => {
        let single = {
          name: merchandise?.merchandise_name,
          price: merchandise?.merchandise_amount,
          availability:
            merchandise?.merchandise_operating_status === "available"
              ? "Available"
              : "Unavailable",
        };

        outputArray.push(single);

        return true;
      });
      return outputArray;
    }
  }

  const downloadCSV = () => {
    if (filterNoPage) {
      const newData = createTableNoPage(filterNoPage);
      writeFileWithXLSX(newData, {
        filename: "unapproved Merchandise.xlsx",
        download: true,
      });
    }
  };

  function goToSinglePage(params) {
    let merchandise_id = params?.single?.merchandise?.id;
    navigate(`/admin/merchandise/${merchandise_id}`, {
      state: { merchandise: params?.single?.merchandise },
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      doSearch();
    }
  };

  const showSearch = (search) => {
    let dataSet = createTableData(search);
    setData(dataSet);
  };

  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allMerchandises?.data);
      setData(dataSet);
      dispatch({
        type: merchandiseType["SEARCH_MERCHANDISE"],
        payLoad: { data: [] },
      });
    }
  };

  async function doSearch() {
    try {
      let response = await dispatch(searchAllMerchandises(input));
      if (response?.status === "success") {
        showSearch(response?.data);
      }
      if (!response?.status) {
        showSearch([]);
      }
    } catch (error) {}
  }

  useEffect(() => {
    if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "single.num",
          },
          {
            Header: "Merchandise Name",
            accessor: "single.name",
          },

          {
            Header: "Price",
            accessor: "single.price",
          },
          {
            Header: "Availiability",
            accessor: "single.availability",
          },
          {
            Header: "Approval",
            accessor: "single.approval",
          },
        ],
      },
    ],
    []
  );
  function goToCreate() {
    navigate(`/admin/merchandise/create`);
  }
  return (
    <>
      <TableFilter
        title={"Merchandise"}
        results={allMerchandises?.to}
        totalResults={allMerchandises?.total}
        onChange={handleChange}
        handleKeyDown={handleKeyDown}
        page={params?.page}
        payLoad={payLoad}
        filter={
          <AllFiltersModal
            isFilterOpen={isFilterOpen}
            setFilterState={setFilterState}
          >
            <FilterMerchandise
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
              payLoad={payLoad}
            />
          </AllFiltersModal>
        }
        searchPlaceholder={" Merchandise Name"}
      >
        <Button.Primary title={"+ Create Merchandise"} onClick={goToCreate} />
        <Button.Secondary
          title={downloading ? <Beat color={"#CE2600"} /> : "Download CSV"}
          onClick={() => downloadCSV()}
          disabled={!data}
        />
      </TableFilter>

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-3">
        {filtering || searching ? (
          <TableSkeleton />
        ) : data?.length > 0 ? (
          <Table
            columns={columns}
            data={data?.length > 0 ? data : []}
            onClick={goToSinglePage}
            className="w-full"
            rowClass="hover:shadow-md cursor-pointer"
          />
        ) : (
          <div className="text-center">
            <div className="flex justify-center my-12 ">
              <img src={Merchandise} alt="merchandise" />
            </div>
            <p className="font-bold text-xl my-3">Merchandise</p>
            <p className="text-sm text-gray-500">
              You have no unapproved Merchandise
            </p>
          </div>
        )}

        <Pagination
          data={allMerchandises}
          route={"admin/merchandise/approved"}
        />
      </div>
    </>
  );
}
