import React, { useState, useEffect } from "react";
import invite from "../../../assets/images/icons/pana.svg";
import Container from "../../../components/container/container";
import { SignUpModal } from "../../../components/modals/auth/signUpModal";

export default function Success() {
  let [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <div>
      <Container>
        <div className="pt-4 sm:pt-2 mb-4 max-w-6xl mx-auto">
          <div className="flex justify-center my-8">
            <img src={invite} alt="onBoard" className="max-h-[432px] w-auto" />
          </div>
          <div className="text-center">
            <h3 className="font-bold text-[36px] sm:text-[52px] mb-4">
              Welcome Onboard{" "}
            </h3>
            <h3 className="text-3xl sm:text-5xl mb-7">
              Business Registered Successfully.
            </h3>
            <p className="text-lg sm:text-2xl mb-6">
              We have built a modern platform that handles all your store
              management needs on the go, allowing you to focus on other
              important aspects of the business.
            </p>
            <p className="text-lg sm:text-2xl mb-10">
              Please, login to your account, upload and publish your products as
              it relates to your account type. We care about you and your
              business, reach out to us via help centre.
            </p>
          </div>
          <SignUpModal show={isOpen} onClose={closeModal} />
          <button
            type="submit"
            className="rounded-md w-full sm:w-[450px] sm:mt-10 mx-auto flex justify-center shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700  sm:text-sm"
            onClick={openModal}
          >
            Log In
          </button>
        </div>
      </Container>
    </div>
  );
}
