import serviceAreaType from "./publicServiceAreasTypes";

const initialState = {
  loading: false,
  filtering: false,
  allPublicServiceAreas: {},
  filter: {},
};
export default function publicServiceAreaReducer(state = initialState, action) {
  switch (action.type) {
    case serviceAreaType.LOADING_PUBLIC_SERVICE_AREAS:
      return {
        ...state,
        loading: action.payLoad,
      };
    case serviceAreaType.GET_ALL_PUBLIC_SERVICE_AREAS:
      return {
        ...state,
        allPublicServiceAreas: action.payLoad?.data,
      };
    case serviceAreaType.FILTERING_PUBLIC_SERVICE_AREAS:
      return {
        ...state,
        filtering: action.payLoad,
      };
    case serviceAreaType.FILTER_PUBLIC_SERVICE_AREAS:
      return {
        ...state,
        filter: action.payLoad?.data,
      };

    default:
      return state;
  }
}
