import React, { useState } from "react";
import { Switch } from "@headlessui/react";

export default function HourDays({
  restaurant_operating_time,
  day,
  set_restaurant_operating_time,
}) {
  const [enabled, setEnabled] = useState(true);

  //   useEffect(() => {
  //     if (
  //       restaurant_operating_time[day]?.open === "00:00" &&
  //       restaurant_operating_time[day]?.close === "00:00"
  //     ) {
  //       setEnabled(false);
  //     } else {
  //       setEnabled(true);
  //     }
  //   }, [restaurant_operating_time, day]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    if (name === "open") {
      set_restaurant_operating_time((restaurant_operating_time) => {
        restaurant_operating_time[day].open = value;
        return { ...restaurant_operating_time };
      });
    }
    if (name === "close") {
      set_restaurant_operating_time((restaurant_operating_time) => {
        restaurant_operating_time[day].close = value;
        return { ...restaurant_operating_time };
      });
    }
  };

  const handleSwitch = (e) => {
    // Create a copy of the data object
    const updatedTime = { ...restaurant_operating_time };

    // Update the desired value within the copied object
    if (e === false) {
      updatedTime[day].open = "00:00";
      updatedTime[day].close = "00:00";
    }

    // Update the state with the modified object
    set_restaurant_operating_time(updatedTime);
  };

  return (
    <div className="flex flex-col sm:flex-row items-center gap-5 sm:gap-10 mb-10">
      <div className="flex w-full sm:w-auto items-center gap-10">
        <label className="block text-2xl min-w-[160px] font-medium text-[#2B3674] capitalize">
          {day}
        </label>
        <Switch.Group
          as="div"
          className="flex w-full sm:w-auto gap-4 items-center"
        >
          <Switch
            checked={enabled}
            onChange={(e) => {
              setEnabled(e);
              handleSwitch(e);
            }}
            className={classNames(
              enabled ? "bg-primary" : "bg-gray-200",
              "relative inline-flex h-5 sm:h-6 w-10 sm:w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none "
            )}
          >
            <span
              aria-hidden="true"
              className={classNames(
                enabled ? "translate-x-5" : "translate-x-0",
                "pointer-events-none inline-block h-4 sm:h-5 w-4 sm:w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out"
              )}
            />
          </Switch>
          <p className="text-lg font-medium text-primary">
            {enabled ? "Open" : "Close"}
          </p>
        </Switch.Group>
      </div>

      {enabled && (
        <div className="flex flex-col sm:flex-row w-full sm:w-auto gap-4 items-center">
          <div className="w-full sm:w-auto">
            <label
              htmlFor="Opening_hours"
              className="block sm:hidden text-sm font-medium mb-2"
            >
              Opening Hours
            </label>
            <input
              type="time"
              name="open"
              value={restaurant_operating_time[day]?.open}
              className="block w-full sm:w-[190px] border border-[#E4E4F3] px-4 rounded-sm py-3 sm:text-md text-[#9CA3AF] focus:border-primary"
              placeholder="Select Opening Hours"
              onChange={handleChange}
            />
          </div>
          <p className="hidden sm:block text-xl text-[#4D4D4D] font-bold">To</p>
          <div className="w-full sm:w-auto">
            <label
              htmlFor="closing_hours"
              className="block sm:hidden text-sm font-medium mb-2"
            >
              Closing Hours
            </label>

            <input
              type="time"
              name="close"
              value={restaurant_operating_time[day]?.close}
              className="block w-full sm:w-[190px] border border-[#E4E4F3] px-4 rounded-sm py-3 sm:text-md text-[#9CA3AF]"
              placeholder="Select closing hours"
              onChange={handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
}
