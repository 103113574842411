import foodType from "./foodTypes";

const initialState = {
  isLoading: false,
  approving: false,
  searching: false,
  filtering: false,
  downloading: false,
  updating: false,
  deleting: false,
  allFood: { data: [] },
  allFoodNoPagination: {},
  singleFood: {},
  search: [],
};

export default function foodReducer(state = initialState, action) {
  switch (action.type) {
    case foodType.LOADING_FOOD:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case foodType.DOWNLOADING:
      return {
        ...state,
        downloading: action.payLoad,
      };

    case foodType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    case foodType.ALL_FOOD:
      let newData = {};
      if (action.payLoad?.data === undefined) {
        newData = {};
      } else {
        newData = action.payLoad?.data;
      }
      return {
        ...state,
        allFood: newData,
      };

    case foodType.SINGLE_FOOD:
      return {
        ...state,
        singleFood: action.payLoad?.data,
      };

    case foodType.STORE_FOOD:
      const prevFood = state?.allFood;
      let newFood;
      if (prevFood?.data?.length > 0) {
        newFood = {
          ...state.allFood,
          data: [...state.allFood?.data, action.payLoad?.data],
        };
      } else {
        newFood = action.payLoad?.data;
      }
      return {
        ...state,
        allFood: newFood,
      };

    case foodType.APPROVING_FOOD:
      return {
        ...state,
        approving: action.payLoad,
      };

    case foodType.APPROVE_FOOD_BUSINESS:
      let approval = {
        ...state.singleFood,
        business_approved: true,
      };
      return {
        ...state,
        singleFood: approval,
      };

    case foodType.UNAPPROVE_FOOD_BUSINESS:
      let unapproval = {
        ...state.singleFood,
        business_approved: false,
      };
      return {
        ...state,
        singleFood: unapproval,
      };

    case foodType.APPROVE_FOOD_MANAGEMENT:
      let approved = {
        ...state.singleFood,
        management_approved: true,
      };
      return {
        ...state,
        singleFood: approved,
      };

    case foodType.UNAPPROVE_FOOD_MANAGEMENT:
      let unapproved = {
        ...state.singleFood,
        management_approved: false,
      };
      return {
        ...state,
        singleFood: unapproved,
      };

    case foodType.SEARCHING:
      return {
        ...state,
        searching: action.payLoad,
      };

    case foodType.SEARCH_FOOD:
      return {
        ...state,
        search: action.payLoad?.data,
      };

    case foodType.FILTERING_FOOD:
      return {
        ...state,
        filtering: action.payLoad,
      };

    case foodType.FILTER_FOOD:
      return {
        ...state,
        allFood: action.payLoad?.data,
      };

    case foodType.ALL_FOOD_NO_PAGINATION:
      return {
        ...state,
        allFoodNoPagination: action.payLoad?.data,
      };

    case foodType.DELETING_FOOD:
      return {
        ...state,
        deleting: action.payLoad,
      };

    default:
      return state;
  }
}
