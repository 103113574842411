import errorHandler from "../../handlers/errorHandler";
import { referralService } from "../../services/referral.service";
import referralType from "./referralTypes";

/**
 * Get all referrals
 **/

export const getAllReferralsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: referralType["LOADING_AUTH"], payLoad: true });
    const response = await referralService.getAllReferrals(page);
    dispatch({ type: referralType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: referralType["ALL_REFERRALS"], payLoad: response });
  } catch (error) {
    dispatch({ type: referralType["LOADING_AUTH"], payLoad: false });
  }
};

/**
 * Get all referrals
 **/

export const getAllReferralsNoPagination =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: referralType["DOWNLOADING"], payLoad: true });
      const response = await referralService.getAllReferralsNoPagination(
        payload,
        page
      );
      dispatch({ type: referralType["DOWNLOADING"], payLoad: false });
      // dispatch({ type: referralType["ALL_REFERRALS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: referralType["DOWNLOADING"], payLoad: false });
    }
  };

/**
 * Get all my referrals
 **/

export const getMyReferralsAction = () => async (dispatch) => {
  try {
    dispatch({ type: referralType["LOADING_AUTH"], payLoad: true });
    const response = await referralService.getMyReferrals();
    dispatch({ type: referralType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: referralType["GET_MY_REFERRALS"], payLoad: response });
  } catch (error) {
    dispatch({ type: referralType["LOADING_AUTH"], payLoad: false });
  }
};

/**
 * Search referrals
 **/

export const searchReferralsAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: referralType["SEARCHING"], payLoad: true });
    const response = await referralService.searchReferrals(payload);
    dispatch({ type: referralType["SEARCHING"], payLoad: false });
    dispatch({ type: referralType["SEARCH_REFERRALS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: referralType["SEARCHING"], payLoad: false });
  }
};

/**
 * Filter referrals
 **/

export const filterReferralsAction = (payload, page) => async (dispatch) => {
  try {
    dispatch({ type: referralType["FILTERING_REFERRALS"], payLoad: true });
    const response = await referralService.filterReferrals(payload, page);
    dispatch({ type: referralType["FILTERING_REFERRALS"], payLoad: false });
    dispatch({ type: referralType["FILTER_REFERRALS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: referralType["FILTERING_REFERRALS"], payLoad: false });
    errorHandler(error, true);
  }
};
