// Application types

const BUSINESS = {
  LOADING_BUSINESS: "LOADING_BUSINESS",
  LOADING_SEARCH: "LOADING_SEARCH",
  APPROVING: "APPROVING",
  FILTERING: "FILTERING",
  SEARCH_BUSINESS: " SEARCH_BUSINESS",
  FILTER_BUSINESS: " FILTER_BUSINESS",
  FILTER_BUSINESS_NOPAGE: " FILTER_BUSINESS_NOPAGE",
  MY_BUSINESS: "MY_BUSINESS",
  A_BUSINESS: "A_BUSINESS",
  UPDATE_BUSINESS: "UPDATE_BUSINESS",
  GET_ALL_BUSINESSES: "GET_ALL_BUSINESSES",
  APPROVE_BUSINESS: "APPROVE_BUSINESS",
  UNAPPROVE_BUSINESS: "UNAPPROVE_BUSINESS",
  EDIT_BUSINESS: "EDIT_BUSINESS",
};

export default BUSINESS;
