import React, { useState, useEffect, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import Button from "../../buttons/buttons";
import { RiCloseLine } from "react-icons/ri";
import { XIcon } from "@heroicons/react/outline";
import { ReactComponent as Upload } from "../../../assets/images/icons/upload.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  filterProductCategory,
  storeACategory,
} from "../../../redux/productCatergory/productCategoryAction";
import { Beat } from "../../../plugins/spinners.plugin";

export default function CategoryStoreModal(teamId) {
  const dispatch = useDispatch();
  const hiddenFileInput = useRef(null);
  let [isOpen, setIsOpen] = useState(false);
  function handleClose() {
    setIsOpen(false);
  }

  const [images, setImages] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [image64, setImage64] = useState([]);
  const { loading } = useSelector((state) => state.productCatergory);
  const [submitted, setSubmitted] = useState(false);
  const [data, setData] = useState([{ name: "", type: "", base64_photos: "" }]);
  const { name, type } = data;
  const handleChange = (event) => {
    const { name, value } = event.target;
    setData((data) => ({ ...data, [name]: value }));
  };

  const [payLoad] = useState({
    type: "store",
  });
  const page = 1;
  function onImageChange(e) {
    setImages([...e.target.files]);
  }

  const handleFileClick = (event) => {
    event.preventDefault();
    hiddenFileInput.current.click();
  };
  const removeFile = (index) => {
    let newArr = images;
    newArr.splice(index, 1);
    setImages([...newArr]);
  };
  useEffect(() => {
    const newImageUrls = [];
    const newImage64 = [];
    images?.forEach((image) => {
      newImageUrls.push(URL.createObjectURL(image));
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onloadend = () => {
        newImage64.push(reader.result);
      };
    });
    setImageUrls(newImageUrls);
    setImage64(newImage64);
  }, [images]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    if (name && type && image64?.length > 0) {
      // dispatch action to create data
      const response = await dispatch(
        storeACategory({
          ...data,
          teamId,
          base64_photos: image64 ? image64 : [],
        })
      );
      if (response) {
        dispatch(filterProductCategory(payLoad, page));
      }
      handleClose();
    }
  };
  return (
    <>
      <Button.Primary
        title={"+ New Category"}
        onClick={() => setIsOpen(true)}
      />
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={handleClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[358px] sm:rounded-md rounded-sm sm:w-[450px] p-4 sm:p-6 ">
                  <form name="form" onSubmit={(e) => handleSubmit(e)}>
                    <div>
                      <div className="hidden sm:block absolute top-3 right-2 pt-4 pr-4">
                        <button
                          type="button"
                          className=" "
                          onClick={handleClose}
                        >
                          <span className="sr-only">Close</span>
                          <RiCloseLine />
                        </button>
                      </div>
                      <div className="">
                        <div className=" text-center sm:text-left ">
                          <Dialog.Title
                            as="h3"
                            className="text-base font-medium text-black "
                          >
                            New Category
                          </Dialog.Title>
                        </div>
                      </div>
                      <div className=" border-b-2 mt-4"></div>
                      <div className="mt-5 sm:mt-7 text-left">
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Name*
                          </label>
                          <div className="mt-2 ">
                            <input
                              type="text"
                              name="name"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                              placeholder="Enter category name"
                              onChange={handleChange}
                            />
                            {submitted && !name && (
                              <div className="text-primary text-xs ">
                                Name is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="type"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Type*
                          </label>
                          <div className="mt-2 ">
                            <select
                              name="type"
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF]  focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm rounded-sm h-[40px] px-4 py-2 mb-4 border border-gray-300"
                              onChange={handleChange}
                            >
                              <option>Select a type</option>
                              <option value="store">Store</option>
                              <option value="restaurant">Restaurant</option>
                            </select>
                            {submitted && !type && (
                              <div className="text-primary text-xs ">
                                Type is required
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="base64_photos"
                            className="block text-sm mt-4 font-medium text-gray-700"
                          >
                            Category Image
                          </label>
                          {imageUrls?.length === 0 ? (
                            <div className="bg-[#FFFBFA] text-center border border-dashed border-[#E4E4F3] rounded-[10px] mt-4 p-5 h-[130px]">
                              <button
                                className="text-primary mx-auto"
                                onClick={handleFileClick}
                              >
                                <Upload className="h-[56px] w-[56px]" />
                              </button>
                              <input
                                type="file"
                                className="hidden"
                                accept="image/*"
                                multiple
                                onChange={onImageChange}
                                ref={hiddenFileInput}
                              />
                              <p className="text-[#676565] text-lg text-center mt-1">
                                Click here to select a photo
                              </p>
                            </div>
                          ) : (
                            <div className="grid grid-cols-1  gap-8 mt-8">
                              {imageUrls?.map((image, index) => (
                                <figure
                                  className="w-full h-[160px] relative"
                                  key={index}
                                >
                                  <img
                                    src={image}
                                    className="object-cover w-full h-full"
                                    alt="food"
                                  />
                                  <button
                                    type="button"
                                    className="h-8 w-8 bg-[#FAE9E6] rounded-full absolute right-2 top-2"
                                    onClick={() => removeFile(index)}
                                  >
                                    <XIcon className="h-5 w-5 text-primary mx-auto" />
                                  </button>
                                </figure>
                              ))}
                            </div>
                          )}
                          {submitted && image64?.length < 1 && (
                            <div className="text-primary text-xs mt-3">
                              Category image is required
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="flex justify-between mt-10 sm:mt-20 mb-6">
                        <Button.Secondary
                          title={"Back"}
                          className={"rounded-md px-4 h-14 w-28"}
                          onClick={handleClose}
                        />
                        <button
                          type="submit"
                          className="h-14 rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                        >
                          {loading ? <Beat /> : "Add Category"}
                        </button>
                      </div>
                    </div>
                  </form>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
