import errorHandler from "../../handlers/errorHandler";
import { publicRestaurantService } from "../../services/publicRestaurant.service";
import authType from "./publicRestaurantTypes";

/**
 * Get all restaurants
 **/

export const getAllPublicRestaurantsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await publicRestaurantService.getAllPublicRestaurants(
      page
    );
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["ALL_RESTAURANTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get more restaurants
 **/

export const getMorePublicRestaurantsAction = (page) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_MORE"], payLoad: true });
    const response = await publicRestaurantService.getAllPublicRestaurants(
      page
    );
    dispatch({ type: authType["LOADING_MORE"], payLoad: false });
    dispatch({ type: authType["MORE_RESTAURANTS"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: authType["LOADING_MORE"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a restaurants
 **/

export const getPublicRestaurantAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
    const response = await publicRestaurantService.getPublicRestaurant(id);
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    dispatch({ type: authType["SINGLE_RESTAURANT"], payLoad: response });
  } catch (error) {
    dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Filter restaurants
 **/

export const filterPublicRestaurantsAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: authType["LOADING_AUTH"], payLoad: true });
      const response = await publicRestaurantService.filterRestaurants(
        payload,
        page
      );
      dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
      dispatch({ type: authType["FILTER_RESTAURANTS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: authType["LOADING_AUTH"], payLoad: false });
      errorHandler(error, true);
    }
  };

/**
 * Filter more restaurants
 **/

export const filterMorePublicRestaurantsAction =
  (payload, page) => async (dispatch) => {
    try {
      dispatch({ type: authType["LOADING_MORE"], payLoad: true });
      const response = await publicRestaurantService.filterRestaurants(
        payload,
        page
      );
      dispatch({ type: authType["LOADING_MORE"], payLoad: false });
      dispatch({ type: authType["MORE_RESTAURANTS"], payLoad: response });
      return response;
    } catch (error) {
      dispatch({ type: authType["LOADING_MORE"], payLoad: false });
      errorHandler(error, true);
    }
  };
