import React from "react";
import OutletRestaurantReportDetail from "./RestaurantReportDetail";
import OutletStoreReportDetail from "./StoreReportDetail";
import { getOutletTypeFromCookies } from "../../../../utils/Auth";

export default function OutletReport() {
  const outlet_type = getOutletTypeFromCookies();

  return (
    <>
      {outlet_type === "restaurant" && <OutletRestaurantReportDetail />}
      {outlet_type === "store" && <OutletStoreReportDetail />}
    </>
  );
}
