import axios from "../plugins/axios.plugin";

class ProductTagService {
  baseServicePath = "/api/product/tag";

  storeATag(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  getAllTags(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getOutletTags(page) {
    return axios.get(
      `${this.baseServicePath}/index?properties=1&page=${page}&relation=1`
    );
  }

  deleteATag(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  editATag(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  showATag(payload) {
    return axios.post(
      `${this.baseServicePath}/show?id=${payload}&properties=1`,
      payload
    );
  }
  searchProductTag(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterProductTag(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
}

export const productTagService = new ProductTagService();
