import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export function RestaurantInfo(props) {
  return (
    <>
      <Transition show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="p-5 sm:w-[477px] max-w-lg bg-white rounded-2xl border relative shadow-xl sm:p-8 mx-auto">
                  <div className="sm:block absolute top-9 left-12  pr-4">
                    <button type="button" className=" " onClick={props.onClose}>
                      <span className="sr-only">Close</span>
                      <div className=" w-3 h-3 sm:w-4 sm:h-4">
                        <ArrowLeftIcon />
                      </div>
                    </button>
                  </div>
                  <div className="sm:flex sm:items-start ">
                    <div className=" text-center sm:ml-24 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="text-xl  font-bold text-black"
                      >
                        Restaurant Information
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 text-left">
                    <div className="my-7 mt-10">
                      <p className=" text-lg font-medium my-3">Address</p>
                      <p className="text-gray-600">
                        14 Egbema lane, new haven extension, Independence
                        layout, Enugu
                      </p>
                    </div>
                    <p className="text-primary-500 font-medium mb-6 cursor-pointer">
                      View Map
                    </p>
                    <div className="my-6">
                      <p className=" text-lg font-medium my-3">Open Hours</p>
                      <p className="text-gray-600">
                        Mon - Sat(9.00am - 6.00pm)
                      </p>
                    </div>
                    <div>
                      <p className=" text-lg font-medium my-3">Phone</p>
                      <p className="text-gray-600">09087657463</p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
