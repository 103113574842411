import currencyType from "./currencyTypes";

const initialState = {
  isLoading: false,
  allCurrencies: [],
};

export default function currencyReducer(state = initialState, action) {
  switch (action.type) {
    case currencyType.LOADING_CURRENCY:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case currencyType.GET_ALL_CURRENCIES:
      return {
        ...state,
        allCurrencies: action.payLoad?.data,
      };

    default:
      return state;
  }
}
