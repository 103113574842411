import axios from "../plugins/axios.plugin";

class ProductCategoryService {
  baseServicePath = "/api/product/category";

  storeACategory(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }

  getAllCategories(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getOutletCategories(page) {
    return axios.get(
      `${this.baseServicePath}/index?properties=1&page=${page}&relation=1`
    );
  }

  deleteACategory(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }

  editACategory(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }

  showACategory(payload) {
    return axios.post(
      `${this.baseServicePath}/show?id=${payload}&properties=1`,
      payload
    );
  }
  searchProductCategory(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  filterProductCategory(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
}

export const productCategoryService = new ProductCategoryService();
