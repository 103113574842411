import onboardType from "./onBoardingTypes";

const initialState = {
  isLoading: false,
  business: {},
  invite: {},
  employee: {},
};

export default function onboardReducer(state = initialState, action) {
  switch (action.type) {
    case onboardType.LOADING_ONBOARD:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case onboardType.ONBOARD_BUSINESS:
      return {
        ...state,
        business: action.payLoad?.data?.data,
      };
    case onboardType.ONBOARD_EMPLOYEE:
      return {
        ...state,
        employee: action.payLoad?.data?.data,
      };
    case onboardType.ONBOARD_INVITE:
      return {
        ...state,
        invite: action.payLoad?.data?.data,
      };
    default:
      return state;
  }
}
