import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { productTagService } from "../../services/productTag.service";
import productTagType from "./productTagTypes";

/** GetAllTags **/
export const getAllTags = (page) => async (dispatch) => {
  dispatch({
    type: productTagType["LOADING_PRODUCT_TAG"],
    payLoad: true,
  });
  try {
    let response = await productTagService.getAllTags(page);
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["GET_PRODUCT_TAG"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/** Get Outlet Tags with relation **/
export const getOutletTags = (page) => async (dispatch) => {
  dispatch({
    type: productTagType["LOADING_PRODUCT_TAG"],
    payLoad: true,
  });
  try {
    let response = await productTagService.getOutletTags(page);
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["GET_PRODUCT_TAG"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const filterProductTag = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    const response = await productTagService.filterProductTag(payLoad, page);
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["FILTER_PRODUCT_TAG"],
      payLoad: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * StoreATag
 * @param {object} payLoad{ name:"gaming",type: store}
 * @returns {void}
 * */
export const storeATag = (payLoad) => async (dispatch) => {
  try {
    const response = await productTagService.storeATag({
      ...payLoad,
    });
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["STORE_A_PRODUCT_TAG"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Delete a Tag
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteATag = (payLoad) => async (dispatch) => {
  try {
    const response = await productTagService.deleteATag({
      ...payLoad,
    });
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["DELETE_A_PRODUCT_TAG"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Edit a Tag
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editATag = (payLoad) => async (dispatch) => {
  try {
    const response = await productTagService.editATag({
      ...payLoad,
    });
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["EDIT_A_PRODUCT_TAG"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Show a Tag
 * @param {object} payLoad {properties:1}
 * */
export const showATag = (payLoad) => async (dispatch) => {
  try {
    const response = await productTagService.showATag({
      ...payLoad,
    });

    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["SHOW_A_PRODUCT_TAG"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

export const searchProductTag = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: productTagType["LOADING_SEARCH"],
      payLoad: false,
    });
    const response = await productTagService.searchProductTag({
      ...payLoad,
    });

    dispatch({
      type: productTagType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["SEARCH_PRODUCT_TAG"],
      payLoad: response,
    });

    return response;
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};

/**
 * TestTagModel
 * @param {object} 
  @returns {void}
 * */
export const testTagModel = () => async (dispatch) => {
  try {
    const response = await productTagService.testATag({});

    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    dispatch({
      type: productTagType["TEST_PRODUCT_TAG_MODEL"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: productTagType["LOADING_PRODUCT_TAG"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
