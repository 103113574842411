import axios from "../plugins/axios.plugin";

class ThirdPartyService {
  baseServicePath = "/api/third-party";

  getMyThirdPartyToken() {
    return axios.get(`${this.baseServicePath}/token/me`);
  }

  createThirdPartyToken(payLoad) {
    return axios.post(`${this.baseServicePath}/token/store`, payLoad);
  }
}

export const thirdPartyService = new ThirdPartyService();
