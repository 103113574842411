import successHandler from "../../handlers/successHandler";
import errorHandler from "../../handlers/errorHandler";
import { foodService } from "../../services/food.service";
import foodType from "./foodTypes";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

/**
 * Get all food
 **/

export const getAllFoodAction = () => async (dispatch) => {
  try {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: true });
    const response = await foodService.getAllFood();
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    dispatch({ type: foodType["ALL_FOOD"], payLoad: response });
  } catch (error) {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get all food
 **/

export const getAllFoodNoPagination = (payload) => async (dispatch) => {
  try {
    dispatch({ type: foodType["DOWNLOADING"], payLoad: true });
    const response = await foodService.getAllFoodNoPagination(payload);
    dispatch({ type: foodType["DOWNLOADING"], payLoad: false });
    dispatch({
      type: foodType["ALL_FOOD_NO_PAGINATION"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({ type: foodType["DOWNLOADING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a food
 **/

export const getAFoodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: true });
    const response = await foodService.getAFood(id);
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    dispatch({ type: foodType["SINGLE_FOOD"], payLoad: response });
  } catch (error) {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Get a food
 **/

export const deleteAFoodAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: foodType["DELETING_FOOD"], payLoad: true });
    const response = await foodService.deleteAFood(id);
    dispatch({ type: foodType["DELETING_FOOD"], payLoad: false });
    dispatch({ type: foodType["DELETE_FOOD"], payLoad: response }); 
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: foodType["DELETING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

export const searchFoodAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: foodType["SEARCHING"], payLoad: true });
    const response = await foodService.searchFoods(payLoad);
    dispatch({ type: foodType["SEARCHING"], payLoad: false });
    dispatch({ type: foodType["SEARCH_FOOD"], payLoad: response });
    return response;
  } catch (error) {
    dispatch({ type: foodType["SEARCHING"], payLoad: false });
  }
};

export const filterFoodAction = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({ type: foodType["FILTERING_FOOD"], payLoad: true });
    const response = await foodService.filterFood(payLoad, page);
    dispatch({ type: foodType["FILTERING_FOOD"], payLoad: false });
    dispatch({ type: foodType["FILTER_FOOD"], payLoad: response });
    // successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: foodType["FILTERING_FOOD"], payLoad: false });
  }
};

export const storeAFoodAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: true });
    const response = await foodService.storeAFood(payLoad);
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    dispatch({ type: foodType["STORE_FOOD"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: foodType["LOADING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

export const updateAFoodAction = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: foodType["UPDATING"], payLoad: true });
    const response = await foodService.updateAFood(payLoad);
    dispatch({ type: foodType["UPDATING"], payLoad: false });
    dispatch({ type: foodType["UPDATE_FOOD"], payLoad: response });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: foodType["UPDATING"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve Foods by self
 **/

export const approveFoodBySelfAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: true });
    const response = await foodService.approveFoodBySelf(id);
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    dispatch({ type: foodType["APPROVE_FOOD_BUSINESS"], payLoad: false });
    // successHandler(response, true);
    toast.success("Food was published", {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
    return response;
  } catch (error) {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove Foods by self
 **/

export const unapproveFoodBySelfAction = (id) => async (dispatch) => {
  try {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: true });
    const response = await foodService.unapproveFoodBySelf(id);
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    dispatch({ type: foodType["UNAPPROVE_FOOD_BUSINESS"], payLoad: false });
    // successHandler(response, true);
    toast.success("Food was published", {
      position: toast.POSITION.TOP_RIGHT,
      theme: "colored",
    });
    return response;
  } catch (error) {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Approve Foods by mgt
 **/

export const approveFoodByMgtAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: true });
    const response = await foodService.approveFoodByMgt(payload);
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    dispatch({ type: foodType["APPROVE_FOOD_MANAGEMENT"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};

/**
 * Unapprove Foods by mgt
 **/

export const unapproveFoodByMgtAction = (payload) => async (dispatch) => {
  try {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: true });
    const response = await foodService.unapproveFoodByMgt(payload);
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    dispatch({ type: foodType["UNAPPROVE_FOOD_MANAGEMENT"], payLoad: false });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({ type: foodType["APPROVING_FOOD"], payLoad: false });
    errorHandler(error, true);
  }
};
