import React, { useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Table from "../../../../components/tables/tableCols2";
import Card from "../../../../components/cards/admin/cards";
import TableSkeleton from "../../../../components/skeleton/table";
import { Pagination } from "../../../../components/pagination/pagination";
import TagModal from "../../../../components/modals/admin/tagModal";
import Settings from "../../../../assets/images/icons/settingsEmpty.svg";
import {
  searchProductTag,
  filterProductTag,
} from "./../../../../redux/productTags/productTagActions";
import EditTag from "./../../../../components/modals/admin/editRestaurantTag";
import RemoveRestaurantTag from "./../../../../components/modals/admin/removeRestaurantTag.js";
import { AllFiltersModal } from "../../../../components/modals/allFiltersModal";
import FilterTagDropDown from "../../../../components/dropDown/filter/tagFilter";

export default function TagRestaurant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [isFilterOpen, setFilterState] = useState(false);
  const [input, setInput] = useState({
    search: "",
  });
  const { allTags, loading, search, searching } = useSelector(
    (state) => state.productTag
  );
  const allTagsData = allTags?.data;
  const { page } = useParams();

  const restaurantTags = allTagsData;
  // eslint-disable-next-line no-unused-vars
  const [payLoad] = useState({
    type: "restaurant",
  });
  useEffect(() => {
    if (!page) {
      navigate(`/admin/settings/tags/restaurant/1`);
    }
  }, [page, navigate]);
  useEffect(() => {
    dispatch(filterProductTag(payLoad, page));
  }, [dispatch, page, payLoad]);

  function createTableData(data) {
    let dataToMap = data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((tag, index) => {
        let singleRowArray = {
          num: <span className="text-base font-semibold">{index + 1}</span>,
          name: <div className=" capitalize">{tag.name}</div>,
          type: tag.type,
          actions: (
            <div className="flex justify-around max-w-[92px]">
              <EditTag restaurantTags={tag} />
              <p className="text-[#717485] text-xl">|</p>
              <RemoveRestaurantTag restaurantTags={tag} />
            </div>
          ),
        };
        outputArray.push(singleRowArray);
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData(restaurantTags);
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allTags]);
  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      let dataSet = createTableData(search);
      setData(dataSet);
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      let dataSet = createTableData(allTagsData);
      setData(dataSet);
    }
  };

  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchProductTag(input));

          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "num",
          },
          {
            Header: "Tags",
            accessor: "name",
          },

          {
            Header: "Actions",
            accessor: "actions",
          },
        ],
      },
    ],
    []
  );
  return (
    <>
      <div>
        <Card.TableFilter
          title={"Tags"}
          onChange={handleChange}
          payLoad={payLoad}
          page={page}
          filter={
            <AllFiltersModal
              isFilterOpen={isFilterOpen}
              setFilterState={setFilterState}
            >
              <FilterTagDropDown
                isFilterOpen={isFilterOpen}
                setFilterState={setFilterState}
                payLoad={payLoad}
              />
            </AllFiltersModal>
          }
          searchPlaceholder={"Tag Name"}
        >
          <TagModal />
        </Card.TableFilter>

        <div className="border-b border-[#E4E4F3]" />
        <div className="bg-white rounded-md py-4 ">
          {loading || searching ? (
            <TableSkeleton />
          ) : data?.length > 0 ? (
            <Table
              columns={columns}
              data={data?.length > 0 ? data : []}
              className="w-full"
              rowClass="hover:shadow-md cursor-pointer"
            />
          ) : (
            <div className="text-center">
              {" "}
              <div className="flex justify-center my-12 ">
                <img src={Settings} alt="settinga" />
              </div>
              <p className="font-bold text-xl my-3">Settings</p>
              <p className="text-sm text-gray-500">
                You do not have any Tags yet
              </p>
            </div>
          )}
        </div>

        <Pagination data={allTags} route={"admin/settings/tags/restaurant"} />
      </div>
    </>
  );
}
