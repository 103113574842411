import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { productCategoryService } from "../../services/productCategory.Service";
import productCategoryType from "./productCategoryTypes";

/** GetAllCategories **/
export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: true,
    });
    let response = await productCategoryService.getAllCategories();

    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["GET_PRODUCT_CATEGORIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/** GetAllCategories **/
export const getOutletCategories = (page) => async (dispatch) => {
  try {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: true,
    });
    let response = await productCategoryService.getOutletCategories(page);

    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["GET_PRODUCT_CATEGORIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * StoreACategory
 * @param {object} payLoad{ name:"gaming",type: store}
 * @returns {void}
 * */
export const storeACategory = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: true,
    });
    const response = await productCategoryService.storeACategory({
      ...payLoad,
    });
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["STORE_A_PRODUCT_CATEGORY"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete a category
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteACategory = (payLoad) => async (dispatch) => {
  try {
    const response = await productCategoryService.deleteACategory({
      ...payLoad,
    });
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["DELETE_A_PRODUCT_CATEGORY"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Edit a category
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editACategory = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: true,
    });
    const response = await productCategoryService.editACategory({
      ...payLoad,
    });
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["EDIT_A_PRODUCT_CATEGORY"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Show a category
 * @param {object} payLoad {properties:1}
 * */
export const showACategory = (payLoad) => async (dispatch) => {
  try {
    const response = await productCategoryService.showACategory({
      ...payLoad,
    });

    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["SHOW_A_PRODUCT_CATEGORY"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
export const searchProductCategory = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: productCategoryType["LOADING_SEARCH"],
      payLoad: false,
    });
    const response = await productCategoryService.searchProductCategory({
      ...payLoad,
    });
    dispatch({
      type: productCategoryType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["SEARCH_PRODUCT_CATEGORY"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};
export const filterProductCategory = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: productCategoryType["FILTERING"],
      payLoad: false,
    });
    const response = await productCategoryService.filterProductCategory(
      payLoad,
      page
    );
    dispatch({
      type: productCategoryType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["FILTER_PRODUCT_CATEGORY"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: productCategoryType["FILTERING"],
      payLoad: false,
    });
  }
};
/**
 * TestCategoryModel
 * @param {object} 
  @returns {void}
 * */
export const testCategoryModel = () => async (dispatch) => {
  try {
    const response = await productCategoryService.testACategory({});

    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    dispatch({
      type: productCategoryType["TEST_PRODUCT_CATEGORY_MODEL"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: productCategoryType["LOADING_PRODUCT_CATEGORIES"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
