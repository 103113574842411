import authType from "./profileTypes";

const initialState = {
  isLoading: false,
  updating: false,
  myProfile: {},
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case authType.LOADING_AUTH:
      return {
        ...state,
        isLoading: action.payLoad,
      };

    case authType.MY_PROFILE:
      return {
        ...state,
        myProfile: action.payLoad?.data,
      };

    case authType.UPDATING:
      return {
        ...state,
        updating: action.payLoad,
      };

    default:
      return state;
  }
}
