import React from "react";
import RestaurantOrderStatistics from "./RestaurantOrderStatistics";
import StoreOrderStatistics from "./StoreOrderStatistics";
import { getOutletTypeFromCookies } from "../../../../../utils/Auth";

export default function OutletReportStatistics() {
  const outlet_type = getOutletTypeFromCookies();

  return (
    <>
      {outlet_type === "restaurant" && <RestaurantOrderStatistics />}
      {outlet_type === "store" && <StoreOrderStatistics />}
    </>
  );
}
