import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import moment from "moment";
// import Button from "../../../../components/buttons/buttons";
import ReviewKYCModal from "../../../../components/modals/admin/reviewKYC";
import { showAHumanVerification } from "../../../../redux/humanVerification/humanVerificationActions";

export default function KYCUserDetails() {
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const params = useParams();

  const { singleVerification } = useSelector(
    (state) => state.humanVerification
  );

  useEffect(() => {
    if (params?.id) {
      dispatch(showAHumanVerification(params?.id));
    }
    // eslint-disable-next-line
  }, [params?.id]);

  return (
    <div data-test="singleOrder-page">
      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">User Details</h3>
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-5 gap-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.name}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Gender</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.gender}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.email}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Phone number</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.phone}
            </p>
          </div>

          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Date Created</label>
            <p className="text-[#151515] text-sm font-bold">
              {moment(singleVerification?.user?.created_at).format(
                "MMM DD, YYYY"
              )}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">KYC Document</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.document_type}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Licence No:</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.document_number}
            </p>
          </div>

          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Country</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.country}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">City</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.city}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">State</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.user?.state}
            </p>
          </div>
        </div>
      </div>

      <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-4 sm:px-8 mt-8">
        <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
          <h3 className="text-lg font-medium">KYC Status</h3>
          {/* <Button.Primary title={"Review Status"} /> */}
          <ReviewKYCModal singleVerification={singleVerification} />
        </div>

        <div className="grid grid-cols-2 sm:grid-cols-3 gap-4 sm:gap-8 mt-6">
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Status</label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.status}
            </p>
          </div>
          <div>
            <label className="text-[#9CA3AF] text-sm mb-2">Comment: </label>
            <p className="text-[#151515] text-sm font-bold">
              {singleVerification?.comment}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
