import React, { useState, Fragment } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import Button from "../../buttons/buttons";
import Input from "../../inputs/inputs";
import {
  storeAPermissionAction,
  assignAPermissionAction,
} from "../../../redux/roles/rolesActions";

export function AddPermissionModal(props) {
  let { id } = useParams();
  const dispatch = useDispatch();
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    name: "",
    display_name: "",
    description: "",
  });
  const { name, display_name, description } = input;

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async () => {
    setSubmitted(true);
    if (name && display_name && description) {
      try {
        const response = await dispatch(storeAPermissionAction(input));
        if (response.status === "success") {
          const new_response = await dispatch(
            assignAPermissionAction({
              permission_id: response?.data?.id,
              role_id: id,
            })
          );
          if (new_response === true) {
            props.onClose();
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
  };
  return (
    <>
      <Transition appear show={props.show} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-[415px] sm:w-[500px] bg-white rounded-3xl py-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg">
                  <div>
                    <div className="sm:block absolute top-4 right-5">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3  "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="sm:flex justify-center">
                      <div className="text-center sm:text-center mt-2">
                        <Dialog.Title
                          as="h3"
                          className="text-xl font-bold text-black"
                        >
                          Add New Permission
                        </Dialog.Title>
                      </div>
                    </div>
                  </div>

                  <div className=" border-b-2 mt-4"></div>
                  <div className="mt-5 sm:mt-7 sm:px-8 p-4">
                    <form onSubmit={handleSubmit}>
                      <Input.Label
                        title={"Name"}
                        type="text"
                        name="name"
                        id="name"
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Enter permission name"
                        onChange={handleChange}
                      />
                      {submitted && !name && (
                        <div className="text-xs text-red-500">
                          Name is required
                        </div>
                      )}
                      <Input.Label
                        title={"Display Name"}
                        type="text"
                        name="display_name"
                        id="display_name"
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Permission display name"
                        onChange={handleChange}
                      />
                      {submitted && !display_name && (
                        <div className="text-xs text-red-500">
                          Display name is required
                        </div>
                      )}
                      <Input.TextArea
                        title={"Description"}
                        type="text"
                        name="description"
                        id="description"
                        className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
                        placeholder="Describe permission"
                        onChange={handleChange}
                      />
                      {submitted && !description && (
                        <div className="text-xs text-red-500">
                          Description is required
                        </div>
                      )}

                      <div className="flex gap-10 mt-8">
                        <Button.Secondary
                          title={"Cancel"}
                          className="w-full"
                          onClick={props.onClose}
                        />
                        <Button.Primary
                          title={"Add Permission"}
                          type={"button"}
                          className="w-full"
                          onClick={handleSubmit}
                        />
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
