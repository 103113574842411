// Application types

const CATALOGUE = {
  LOADING_CATALOGUE: "LOADING_CATALOGUE",
  LOADING_SEARCH: "LOADING_SEARCH",
  APPROVING: "APPROVING",
  FILTERING: "FILTERING",
  CREATE_CATALOGUE: "CREATE_CATALOGUE",
  SEARCH_CATALOGUE: " SEARCH_CATALOGUE",
  FILTER_CATALOGUE: " FILTER_CATALOGUE",
  FILTER_CATALOGUE_NOPAGE: " FILTER_CATALOGUE_NOPAGE",
  MY_CATALOGUE: "MY_CATALOGUE",
  GET_A_CATALOGUE: "GET_A_CATALOGUE",
  UPDATE_CATALOGUE: "UPDATE_CATALOGUE",
  GET_ALL_CATALOGUES: "GET_ALL_CATALOGUES",
  APPROVE_CATALOGUE: "APPROVE_CATALOGUE",
  UNAPPROVE_CATALOGUE: "UNAPPROVE_CATALOGUE",
  DELETE_CATALOUGE: "DELETE_CATALOGUE",
};

export default CATALOGUE;
