import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { notifierGroupService } from "../../services/notifierGroup.Service";
import notifierGroupType from "./notifierGroupTypes";

/** GetAllCategories **/
export const getAllCategories = () => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    let response = await notifierGroupService.getAllCategories();

    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["GET_PRODUCT_CATEGORIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/** GetAllCategories **/
export const getOutletCategories = (page) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    let response = await notifierGroupService.getOutletCategories(page);

    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["GET_PRODUCT_CATEGORIES"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * StoreANotifier
 * @param {object} payLoad{ name:"gaming",type: store}
 * @returns {void}
 * */
export const storeANotifierGroup = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    const response = await notifierGroupService.storeANotifierGroup({
      ...payLoad,
    });
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["STORE_A_NOTIFIER"],
      payLoad: response,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};

/**
 * Delete a category
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const deleteANotifierGroup = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["DELETING"],
      payLoad: true,
    });
    const response = await notifierGroupService.deleteANotifierGroup(payLoad);
    dispatch({
      type: notifierGroupType["DELETING"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["DELETE_A_NOTIFIER_GROUP"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierGroupType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Delete a category
 * @param {object} payLoad{ id:"22"}
 * @returns {void}
 * */
export const closeANotifierGroup = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["DELETING"],
      payLoad: true,
    });
    const response = await notifierGroupService.closeANotifierGroup(payLoad);
    dispatch({
      type: notifierGroupType["DELETING"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["DELETE_A_NOTIFIER_GROUP"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierGroupType["DELETING"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Edit a category
 * @param {object} payLoad {id:"22"}
 * @returns {void}
 * */
export const editANotifierGroup = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["UPDATING"],
      payLoad: true,
    });
    const response = await notifierGroupService.editANotifierGroup(payLoad);
    dispatch({
      type: notifierGroupType["UPDATING"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["EDIT_A_PRODUCT_CATEGORY"],
      payLoad: payLoad,
    });
    successHandler(response, true);
    return response;
  } catch (error) {
    dispatch({
      type: notifierGroupType["UPDATING"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};

/**
 * Show a category
 * @param {object} payLoad {properties:1}
 * */
export const showANotifierGroup = (id, user_page) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: true,
    });
    const response = await notifierGroupService.showANotifierGroup(
      id,
      user_page
    );

    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["SHOW_A_NOTIFIER_GROUP"],
      payLoad: response,
    });
  } catch (error) {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
    throw new Error("");
  }
};
export const searchNotifierGroup = (payLoad) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["LOADING_SEARCH"],
      payLoad: false,
    });
    const response = await notifierGroupService.searchNotifierGroup({
      ...payLoad,
    });
    dispatch({
      type: notifierGroupType["LOADING_SEARCH"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["SEARCH_NOTIFIER"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: notifierGroupType["LOADING_SEARCH"],
      payLoad: false,
    });
  }
};
export const filterNotifierGroup = (payLoad, page) => async (dispatch) => {
  try {
    dispatch({
      type: notifierGroupType["FILTERING"],
      payLoad: true,
    });
    const response = await notifierGroupService.filterNotifierGroup(
      payLoad,
      page
    );
    dispatch({
      type: notifierGroupType["FILTERING"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["FILTER_NOTIFIER_GROUP"],
      payLoad: response,
    });
    return response;
  } catch (error) {
    dispatch({
      type: notifierGroupType["FILTERING"],
      payLoad: false,
    });
  }
};
/**
 * TestCategoryModel
 * @param {object} 
  @returns {void}
 * */
export const testCategoryModel = () => async (dispatch) => {
  try {
    const response = await notifierGroupService.testACategory({});

    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    dispatch({
      type: notifierGroupType["TEST_PRODUCT_CATEGORY_MODEL"],
      payLoad: response,
    });
    successHandler(response, true);
  } catch (error) {
    dispatch({
      type: notifierGroupType["LOADING_NOTIFIER"],
      payLoad: false,
    });
    errorHandler(error, true);
  }
};
