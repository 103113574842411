import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
import Button from "../../buttons/buttons";
import CLOSE from "../../../assets/images/icons/close.svg";
import {
  setNewAttributeAction,
  filterAttributeAction,
} from "../../../redux/attribute/attributeActions";
import { getUserFromCookies } from "../../../utils/Auth";
import { Beat } from "../../../plugins/spinners.plugin";

export default function AddAttribute(props) {
  const {merch_business_id}= props;
  const dispatch = useDispatch();
  const { isLoading } = useSelector((state) => state.attribute);
  const user = getUserFromCookies();
  let business = Object.keys(user?.groups)[0];

  //const [variation, setVariation] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    business_id: business,
    title: "",
    name: "",
    type: "store",
    amount: "",
  });
  const { business_id, title, name, amount } = input;

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleSubmit = async (event) => {
    setSubmitted(true);
    if (title && name && name.length > 3 && amount) {
      try {
        const response = await dispatch(
          setNewAttributeAction({
            ...input,
            business_id: merch_business_id?merch_business_id:business_id,
          })
        );

        if (response?.status === "success") {
          dispatch(
            filterAttributeAction({
              business_id: merch_business_id ? merch_business_id : business_id,
            })
          );
          props.onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <div className="Coupon" data-test="couponModal">
      <Transition appear show={props.show}>
        <Dialog as="div" className="relative z-10" onClose={props.onClose}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className=" bg-white w-[380px] rounded-md text-left sm:w-[972px] p-4 sm:p-6">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-md p-1 sm:p-3 "
                        onClick={props.onClose}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-5 text-center sm:mt-2 sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title as="h3" className="text-xl text-black">
                        Add Attribute
                      </Dialog.Title>
                    </div>
                  </div>
                  <form className="py-5 mt-4 px-5">
                    <div className="grid grid-cols-3 gap-8">
                      <div>
                        <Input.Label
                          title={"Title*"}
                          name="title"
                          htmlFor={"title"}
                          placeholder="E.g: Color"
                          onChange={handleChange}
                        />
                        {submitted && !title && (
                          <div className="text-primary text-xs ">
                            Title is required
                          </div>
                        )}
                      </div>
                      <div>
                        <Input.Label
                          title={"Name* "}
                          htmlFor="name"
                          name="name"
                          placeholder=" E.g Black"
                          onChange={handleChange}
                        />
                        {submitted && !name && (
                          <div className="text-primary text-xs ">
                            Name is required
                          </div>
                        )}
                        {submitted && name && name.length < 4 && (
                          <div className="text-xs text-red-500">
                            Name must be more than 4 characters
                          </div>
                        )}
                      </div>

                      <div>
                        <Input.Label
                          title={"Value "}
                          htmlFor="amount"
                          name="amount"
                          placeholder="NGN 4500"
                          onChange={handleChange}
                        />
                        {submitted && !amount && (
                          <div className="text-primary text-xs ">
                            Amount is required
                          </div>
                        )}
                      </div>
                    </div>
                  </form>

                  <div className="py-8 max-w-3xl mx-auto text-center">
                    <Button.Primary
                      form="form_a"
                      title={
                        isLoading ? (
                          <Beat color={"#ffffff"} />
                        ) : (
                          "Create Attribute"
                        )
                      }
                      disabled={isLoading}
                      className="px-4"
                      onClick={handleSubmit}
                      type="button"
                    />
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}
