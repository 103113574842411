import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ReactComponent as CopyIcon } from "../../../assets/images/icons/copy-outline.svg";
import Button from "../../../components/buttons/buttons";
import { getARestaurantOrderAction } from "../../../redux/restaurantOrder/restaurantOrderActions";

export default function SingleOrder() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getARestaurantOrderAction());
    // eslint-disable-next-line
  }, []);

  return (
    <div data-test="singleOrder-page">
      <div className="mb-10">
        <h1 className="text-md font-semibold text-gray-900">Back</h1>
      </div>

      <div className="grid grid-cols-2 gap-8">
        {/* Grid 1 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Customer Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
                <p className="text-[#151515] text-sm font-bold">
                  Raphael Obinna
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Email</label>
                <p className="text-[#151515] text-sm  font-bold">
                  raphaelo@gmail.com
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Phone Number
                </label>
                <p className="text-[#151515] text-sm  font-bold">
                  090989796948
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Address</label>
                <p className="text-[#151515] text-sm font-bold">
                  22A Agbani Rd
                </p>
              </div>
            </div>
          </div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Order Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order date
                </label>
                <p className="text-[#151515] text-sm font-bold">Aug 6, 2021</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Order ID</label>
                <p className="text-[#151515] text-sm font-bold flex">
                  #1e698292c...1aafaec8
                  <CopyIcon className="h-4 w-4 ml-1" />
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN 27,000.00
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Delivery Fee
                </label>
                <p className="text-[#151515] text-sm font-bold">NGN 3,000.00</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Order Status
                </label>
                <p className="text-primary text-sm font-bold">Pending</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Total Amount
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  NGN 30,000.00
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Status
                </label>
                <p className="text-[#00D220] text-sm font-bold">Paid</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Payment Method
                </label>
                <p className="text-[#1634A1] text-sm font-bold">Interswitch</p>
              </div>
              <Button.Primary title={"Close Order"} />
              <Button.Green title={"Get Rider"} />
            </div>
          </div>
        </div>
        {/* Grid 2 */}
        <div>
          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">
                Product Details (Optional)
              </h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Name</label>
                <p className="text-[#151515] text-sm font-bold">Pizza</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Category</label>
                <p className="text-[#151515] text-sm font-bold">
                  kilimanjaro@gmail.com
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">Type</label>
                <p className="text-[#151515] text-sm font-bold">09023456789</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Showcase (Featured)
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  22A Agbani Rd
                </p>
              </div>
            </div>
          </div>

          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Store Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Store Name
                </label>
                <p className="text-[#151515] text-sm font-bold">Red</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Store Email
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  red@gmail.com
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Store Phone Number
                </label>
                <p className="text-[#151515] text-sm font-bold">09023456789</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Completed Orders
                </label>
                <p className="text-[#151515] text-sm font-bold">10</p>
              </div>
            </div>
          </div>

          <div className="border border-[#E4E4F3] bg-white rounded-md py-5 px-8 mt-6">
            <div className="border-b border-[#E4E4F3] py-3 flex justify-between items-center">
              <h3 className="text-lg font-medium">Employee Details</h3>
            </div>

            <div className="grid grid-cols-2 gap-8 mt-6">
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Employee Name
                </label>
                <p className="text-[#151515] text-sm font-bold">Blue</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Employee Email
                </label>
                <p className="text-[#151515] text-sm font-bold">
                  blue@gmail.com
                </p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Employee Phone Number
                </label>
                <p className="text-[#151515] text-sm font-bold">09023456789</p>
              </div>
              <div>
                <label className="text-[#9CA3AF] text-sm mb-2">
                  Employee Position
                </label>
                <p className="text-[#151515] text-sm font-bold">Operations</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
