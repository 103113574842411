import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Button from "../../../../components/buttons/buttons";
import Card from "../../../../components/cards/admin/cards";
import Input from "../../../../components/inputs/inputs";
import TableSkeleton from "../../../../components/skeleton/table";
import { Pagination } from "../../../../components/pagination/pagination";
import Adjust from "../../../../assets/images/icons/adjust.svg";
import CLOSE from "../../../../assets/images/icons/close.svg";
import Copy from "../../../../assets/images/icons/copy-outline.svg";
import { Beat } from "../../../../plugins/spinners.plugin";
import {
  searchAllAdvertisments,
  filterAdvertisments,
} from "../../../../redux/advertisment/advertismentAction";
import { advertismentArrangements } from "../../../../redux/arrangements/arrangementAction";

export const CheckBox = ({
  // handleOnChange,
  setPosition,
  selectId,
  setSelectId,
  advert_id,
  position,
  setShowModal,
}) => {
  return (
    <div
      onClick={() => {
        setSelectId(advert_id);
        setShowModal(true);
        setPosition(position);
      }}
      className={` w-4 h-4 text-primary bg-gray-100 rounded border
       ${
         selectId === advert_id
           ? "border-primary bg-primary"
           : "border-gray-300"
       }`}
    />
  );
};

export default function SlidersArrangement() {
  const dispatch = useDispatch();
  const { page } = useParams();
  let lastMonth = new Date();
  lastMonth.setDate(lastMonth.getDate() - 30);

  const { arranging } = useSelector((state) => state.arrangement);
  const { allAdvertisments, isLoading, search } = useSelector(
    (state) => state.advertisment
  );

  const [advertisment, setAdvertisment] = useState(allAdvertisments?.data);
  const [input, setInput] = useState({
    search: "",
  });
  const [data, setData] = useState({ position: "" });
  const [selectId, setSelectId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [position, setPosition] = useState(false);
  const [active_tab, setActive_tab] = useState("all-sliders");
  const [payLoad] = useState({
    // start_date: lastMonth,
    end_date: new Date(),
  });
  useEffect(() => {
    dispatch(filterAdvertisments(payLoad, page));
    //eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    setAdvertisment(allAdvertisments?.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAdvertisments]);

  //to copy text to clipboard
  const copyTextToClipboard = async (text) => {
    if ("clipboard" in navigator) {
      await navigator.clipboard.writeText(text);
    } else {
      document.execCommand("copy", true, text);
    }
    toast.success("Copied", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  /**
   * Truncate transaction ref and add ellipsis in middle
   *
   * @param {string} string
   * @param {number} lengthToShow
   * @param {string} seperator
   * @returns {string}
   */
  const truncateText = (string, lengthToShow = 25, separator = "...") => {
    if (string?.length <= lengthToShow) return string;

    const sepLen = separator?.length;
    const charsToShow = lengthToShow - sepLen;
    const frontChars = Math.ceil(charsToShow / 2);
    const backChars = Math.floor(charsToShow / 2);

    return (
      string?.substr(0, frontChars) +
      separator +
      string?.substr(string.length - backChars)
    );
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const items = Array.from(advertisment);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setAdvertisment(items);
  };

  const handleSave = async () => {
    // if the page = 1, position number will start from 0,
    // else position number needs to add 1 to account for zero indexing
    const position_number = () => {
      if (page > 1) {
        let last_item = Number((page - 1) * 24);
        return last_item + 1;
      } else {
        return Number((page - 1) * 24);
      }
    };
    const lists = advertisment.reduce((acc, list, i) => {
      acc[list.id] = i + position_number();
      return acc;
    }, {});
    try {
      const response = await dispatch(
        advertismentArrangements({ list: lists })
      );
      if (response) {
        dispatch(filterAdvertisments(payLoad, page));
      }
    } catch (error) {}
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const showSearch = () => {
    if (input?.search?.length > 2) {
      setActive_tab("searched");
    }
  };
  const removeSearch = () => {
    if (input?.search?.length === 0) {
      setActive_tab("all-sliders");
    }
  };
  const handlePosition = () => {
    const lists = {};
    search?.map((list) => {
      lists[list.id] = parseInt(data);
      return list;
    });
    dispatch(advertismentArrangements({ list: lists }));
    removeSearch();
  };
  useEffect(() => {
    if (input?.search?.length > 2) {
      async function doSearch() {
        try {
          let response = await dispatch(searchAllAdvertisments(input));

          if (response?.status === "success") {
            showSearch();
          }
        } catch (error) {}
      }
      doSearch();
    } else if (input?.search?.length === 0) {
      removeSearch();
    }
    // eslint-disable-next-line
  }, [input, dispatch]);
  return (
    <div className="relative">
      <div className=" mb-12">
        <Card.TableFilter
          totalResults={allAdvertisments?.to}
          results={allAdvertisments?.total}
          onChange={handleChange}
          title={"Web Sliders Arrangement"}
          searchPlaceholder="Slider Name, Status or Phone Number"
          payLoad={payLoad}
          page={page}
        >
          <Button.Primary
            title={arranging ? <Beat /> : "Save"}
            disabled={!data || arranging}
            className=" w-20"
            onClick={handleSave}
          />
        </Card.TableFilter>
        <div className="border-b border-[#E4E4F3]">
          {active_tab === "all-sliders" && (
            <div className="bg-white rounded-md py-4">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <DragDropContext
                  onDragEnd={(results) => handleDragEnd(results)}
                >
                  <table className=" sm:w-full sm:inline-table text-left text-[#151515] ">
                    <thead className="text-sm font-bold border-b-[1px] ">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          S/N
                        </th>
                        <th scope="col" className="py-3 px-6"></th>
                        <th scope="col" className="py-3 px-14">
                          Status
                        </th>
                        <th scope="col" className="py-3 px-14">
                          Position
                        </th>
                        <th scope="col" className="py-3 px-4">
                          Date
                        </th>
                        <th scope="col" className="py-3 px-8">
                          Title
                        </th>
                        <th scope="col" className="py-3 px-8">
                          Link/URL
                        </th>

                        <th scope="col" className="py-3 px-8">
                          Adjust
                        </th>
                      </tr>
                    </thead>
                    <Droppable droppableId="advert">
                      {(provided) => (
                        <tbody
                          className="advert text-sm"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {advertisment?.map((advert, index) => (
                            <Draggable
                              draggableId={advert.title}
                              index={index}
                              key={advert.id}
                            >
                              {(provided) => (
                                <tr
                                  className="bg-white border-b text-sm"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <td className="py-4 px-6">{index + 1}</td>
                                  <td className="p-4 w-4">
                                    <div className="flex items-center">
                                      {/* <input
                                        id="checkbox-table-search-1"
                                        type="checkbox"
                                        className="w-4 h-4 text-primary bg-gray-100 rounded border-gray-300"
                                      />
                                      <label
                                        htmlFor="checkbox-table-search-1"
                                        className="sr-only"
                                      >
                                        checkbox
                                      </label> */}
                                      <CheckBox
                                        setPosition={setPosition}
                                        setShowModal={setShowModal}
                                        setSelectId={setSelectId}
                                        selectId={selectId}
                                        position={advert?.position}
                                        advert_id={advert?.id}
                                      />
                                    </div>
                                  </td>
                                  <td className="py-4 px-14">
                                    {advert?.status === "active" ? (
                                      <span className="text-[#00D220] font-bold">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="text-primary font-bold">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                  <td className="py-4 px-14">
                                    {advert.position}
                                  </td>
                                  <td>
                                    {moment(advert?.created_at).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </td>
                                  <td className="py-4 px-6 capitalize">
                                    {advert.title}
                                  </td>
                                  <td className=" flex py-4 px-6">
                                    {truncateText(advert.url)}
                                    <img
                                      src={Copy}
                                      className="ml-4 cursor-pointer"
                                      alt=""
                                      onClick={() => {
                                        copyTextToClipboard(advert.url);
                                      }}
                                    ></img>
                                  </td>

                                  <td
                                    className="py-4 px-6"
                                    {...provided.dragHandleProps}
                                  >
                                    {
                                      <img
                                        className="cursor-pointer ml-5"
                                        src={Adjust}
                                        alt="ratings"
                                      ></img>
                                    }
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              )}

              <Pagination
                data={allAdvertisments}
                route={`admin/settings/sliders`}
              />
            </div>
          )}

          {active_tab === "searched" && (
            <div className="bg-white rounded-md py-4">
              {isLoading ? (
                <TableSkeleton />
              ) : (
                <DragDropContext
                  onDragEnd={(results) => handleDragEnd(results)}
                >
                  <table className=" sm:w-full sm:inline-table text-left text-[#151515] ">
                    <thead className="text-sm font-bold border-b-[1px] ">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          S/N
                        </th>
                        <th scope="col" className="py-3 px-6"></th>
                        <th scope="col" className="py-3 px-14">
                          Status
                        </th>
                        <th scope="col" className="py-3 px-4">
                          Date
                        </th>
                        <th scope="col" className="py-3 px-8">
                          Title
                        </th>
                        <th scope="col" className="py-3 px-8">
                          Link/URL
                        </th>

                        <th scope="col" className="py-3 px-8">
                          Adjust
                        </th>
                      </tr>
                    </thead>
                    <Droppable droppableId="advert">
                      {(provided) => (
                        <tbody
                          className="advert text-sm"
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {search?.map((advert, index) => (
                            <Draggable
                              draggableId={advert?.title}
                              index={index}
                              key={advert?.id}
                            >
                              {(provided) => (
                                <tr
                                  className="bg-white border-b text-sm"
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                >
                                  <td className="py-4 px-6">{index + 1}</td>
                                  <td className="p-4 w-4">
                                    <div className="flex items-center">
                                      <CheckBox
                                        setSelectId={setSelectId}
                                        selectId={selectId}
                                        setPosition={setPosition}
                                        setShowModal={setShowModal}
                                        position={advert?.position}
                                        advert_id={advert?.id}
                                      />
                                    </div>
                                  </td>
                                  <td className="py-4 px-14">
                                    {advert?.status === "active" ? (
                                      <span className="text-[#00D220] font-bold">
                                        Active
                                      </span>
                                    ) : (
                                      <span className="text-primary font-bold">
                                        Inactive
                                      </span>
                                    )}
                                  </td>
                                  <td>
                                    {moment(advert?.created_at).format(
                                      "MMM DD, YYYY"
                                    )}
                                  </td>
                                  <td className="py-4 px-6 capitalize">
                                    {advert.title}
                                  </td>
                                  <td className=" flex py-4 px-6">
                                    {truncateText(advert.url)}
                                    <img
                                      src={Copy}
                                      className="ml-4 cursor-pointer"
                                      alt=""
                                      onClick={() => {
                                        copyTextToClipboard(advert.url);
                                      }}
                                    ></img>
                                  </td>

                                  <td
                                    className="py-4 px-6"
                                    {...provided.dragHandleProps}
                                  >
                                    {
                                      <img
                                        className="cursor-pointer ml-5"
                                        src={Adjust}
                                        alt="ratings"
                                      ></img>
                                    }
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </DragDropContext>
              )}
            </div>
          )}
        </div>
      </div>
      {showModal && (
        <div className="absolute top-0 bottom-0 h-full w-full bg-gray-500 bg-opacity-75 transition-opacity">
          <div className="relative bg-white mt-20 mx-auto w-[990px] rounded-md py-5 px-8 flex justify-around">
            <button
              type="button"
              className="text-black block absolute top-5 right-5"
              onClick={() => {setShowModal(false); setSelectId("");}}
            >
              <span className="sr-only">Close</span>
              <img src={CLOSE} alt="close" className=" w-3 h-3" />
            </button>
            <Input.Label
              title={"Current Position"}
              className={"w-80"}
              defaultValue={position}
              disabled
            />

            <Input.Label
              title={"New Position"}
              htmlFor="position"
              name="position"
              onChange={(e) => setData(e.target.value)}
              className={"w-80"}
            />
            <Button.Primary
              title={arranging ? <Beat /> : "Change Position"}
              disabled={arranging}
              className={"h-11 mt-6"}
              onClick={handlePosition}
            />
          </div>
        </div>
      )}
    </div>
  );
}
