import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import CLOSE from "../../../assets/images/icons/close.svg";
import Warning from "../../../assets/images/icons/warning-circle.svg";

export function ServiceAreaNoticeModal({ open, setOpen }) {
  function closeModal() {
    setOpen(false);
  }

  return (
    <>
      <Transition show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-end sm:items-center justify-center min-h-full p-4 sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative max-w-[950px]  bg-white rounded-sm px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:my-10 sm:w-full sm:p-8">
                  <div className="sm:block absolute top-2 right-3 pt-4 pr-4">
                    <button type="button" className="focus:outline-0 focus:ring-0" onClick={closeModal}>
                      <span className="sr-only">Close</span>
                      <img
                        src={CLOSE}
                        alt="close"
                        className=" w-3 h-3 sm:w-4 sm:h-4"
                      />
                    </button>
                  </div>

                  <div className="flex items-center gap-4">
                    <img src={Warning} alt="warning" />
                    <div>
                      <h3 className="text-primary font-bold">
                        A message about incorrect service areas
                      </h3>
                      <p className="text-sm text-[#333333]">
                        Please note, before proceeding to checkout, kindly
                        ensure you have selected the correct delivery service
                        area to avoid any potential additional charges for your
                        package.
                      </p>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
