const CARTS = {
  LOADING_STATS: "LOADING_STATS",
  GET_BUSINESS_STATISTICS: "GET_BUSINESS_STATISTICS",
  GET_USER_STATISTICS: "GET_USER_STATISTICS",
  GET_GENERAL_STATISTICS: "GET_GENERAL_STATISTICS",
  GET_STORE_STATISTICS: "GET_STORE_STATISTICS",
  GET_RESTAURANT_STATISTICS: "GET_RESTAURANT_STATISTICS",
};

export default CARTS;
