import React from "react";
import { useDispatch } from "react-redux";
import { Popover } from "@headlessui/react";
import { ReactComponent as CART } from "../../assets/images/icons/cart.svg";
import FOOD from "../../assets/images/icons/Food-packages.svg";
import HELP from "../../assets/images/icons/help.svg";
import LOGOUT from "../../assets/images/icons/logout.svg";
import ORDERS from "../../assets/images/icons/orders.svg";
import OUTLET from "../../assets/images/icons/outlet.svg";
import HOME from "../../assets/images/icons/home.svg";
import PROFILE from "../../assets/images/icons/profile.svg";
import WALLET from "../../assets/images/icons/wallet.svg";
import RESTAURANT from "../../assets/images/icons/restaurant.svg";
import { isAuthenticatedUser } from "../../utils/Auth";
import { logoutUserLocallyAction } from "../../redux/auth/unAuthActions";
// import REWARD from "../../assets/images/icons/rewards.svg";
// import SETTINGS from "../../assets/images/icons/settings.svg";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const navigation = [
  {
    name: "Home",
    href: "/user/home",
    image: <img src={HOME} alt="icon" />,
    current: true,
  },
  {
    name: "Stores",
    href: "/user/stores",
    image: <img src={OUTLET} alt="icon" />,
    current: false,
  },
  {
    name: "Restaurants",
    href: "/user/restaurants",
    image: <img src={RESTAURANT} alt="icon" />,
    current: false,
  },
];
const userNavigation = [
  {
    name: "Profile",
    href: "/profile",
    image: <img src={PROFILE} alt="icon" />,
  },
  {
    name: "Orders",
    href: "/profile/orders",
    image: <img src={ORDERS} alt="icon" />,
  },
  {
    name: "Payments",
    href: "/profile/payments",
    image: <img src={WALLET} alt="icon" />,
  },
  { name: "Help", href: "/help", image: <img src={HELP} alt="icon" /> },
];

const others = [
  {
    name: "Cart",
    href: "/cart",
    image: <CART />,
    current: false,
  },
  {
    name: "Food Packages",
    href: "/user/food-packages",
    image: <img src={FOOD} alt="icon" />,
    current: false,
  },
  // {
  //   name: "Rewards",
  //   href: "#",
  //   image: <img src={REWARD} alt="" />,
  //   current: false,
  // },
];

export default function NavBarMobile() {
  const dispatch = useDispatch();
  const isLoggedIn = isAuthenticatedUser();

  function logoutUser() {
    dispatch(logoutUserLocallyAction());
  }
  return (
    <Popover.Panel
      as="nav"
      className="lg:hidden w-[340px] "
      aria-label="Global"
    >
      <div className="max-w-2xl mx-auto px-2 pt-2 pb-3 space-y-1 sm:px-4">
        {navigation.map((item) => (
          <a
            key={item.name}
            href={item.href}
            aria-current={item.current ? "page" : undefined}
            className={classNames(
              item.current ? "bg-gray-100 text-gray-900" : "hover:bg-gray-50",
              "block rounded-md py-2 px-3 text-base font-medium"
            )}
          >
            <div className="flex">
              {item.image}
              <p className="ml-4">{item.name} </p>
            </div>
          </a>
        ))}
      </div>
      <div className=" pt-4 pb-3">
        <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
          <p className=" text-xl font-bold ml-2">OTHERS</p>
          {others.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="block rounded-md py-2 px-3 text-base font-medium"
            >
              <div className="flex">
                {item.image}
                <p className="ml-4">{item.name} </p>
              </div>
            </a>
          ))}
        </div>
      </div>
      <div className=" pt-4 pb-3">
        <div className="mt-3 max-w-3xl mx-auto px-2 space-y-1 sm:px-4">
          <p className=" text-xl font-bold ml-2">MY ACCOUNT</p>
          {userNavigation.map((item) => (
            <a
              key={item.name}
              href={item.href}
              className="block rounded-md py-2 px-3 text-base font-medium"
            >
              <div className="flex">
                {item.image}
                <p className="ml-4">{item.name} </p>
              </div>
            </a>
          ))}

          {isLoggedIn && (
            <button
              className="block rounded-md py-2 px-3 text-base font-medium"
              onClick={logoutUser}
            >
              <div className="flex">
                <img src={LOGOUT} alt="icon" />
                <p className="ml-4">Sign out </p>
              </div>
            </button>
          )}
        </div>
      </div>
    </Popover.Panel>
  );
}
