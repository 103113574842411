import axios from "../plugins/axios.plugin";

class AdvertisementService {
  baseServicePath = "/api/advertisement";

  CreateAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  getAllAdvertisements(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }
  filterAdvertisements(payload, page) {
    return axios.post(
      `${this.baseServicePath}/filter/index?page=${page}`,
      payload
    );
  }
  searchAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showAdvertisement(payload) {
    return axios.get(`${this.baseServicePath}/show?id=${payload}`);
  }
  showMyAdvertisements() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  deleteAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  editAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  approveAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/approve/management`, payload);
  }
  unapproveAdvertisement(payload) {
    return axios.post(`${this.baseServicePath}/unapprove/management`, payload);
  }
}

export const advertisementService = new AdvertisementService();
