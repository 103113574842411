//ADVERTISMENT Types

const ADVERTISMENT = {
  LOADING_ADVERTISMENT: "LOADING_ADVERTISMENT",
  LOADING_SEARCH: "LOADING_SEARCH",
  APPROVING_ADVERTISMENT: "APPROVING_ADVERTISMENT",
  CREATE_ADVERTISMENT: "CREATE_ADVERTISMENT",
  GET_ALL_ADVERTISMENT: "GET_ALL_ADVERTISMENT",
  FILTER_ADVERTISMENT: "FILTER_ADVERTISMENT",
  SEARCH_ADVERTISMENT: "SEARCH_ADVERTISMENT",
  SHOW_AN_ADVERTISMENT: "GET_AN_ADVERTISMENT",
  GET_MY_ADVERTISMENT: "GET_MY_ADVERTISMENT",
  EDIT_ADVERTISMENT: "EDIT_ADVERTISMENT",
  DELETE_ADVERTISMENT: "DELETE_ADVERTISMENT",
  APPROVE_ADVERTISMENT: "APPROVE_ADVERTISMENT",
  UNAPPROVE_ADVERTISMENT: "UNAPPROVE_ADVERTISMENT",
};
export default ADVERTISMENT;
