import axios from "../plugins/axios.plugin";

class FeatureService {
  baseServicePath = "/api/featured";

  getAllFeaturedProducts(page) {
    return axios.get(`${this.baseServicePath}/index?properties=1&page=${page}`);
  }

  getAllFeaturedNoPagination(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }

  getActiveFeaturedProducts(page) {
    return axios.get(
      `${this.baseServicePath}/index?properties=1&status=active&page=${page}`
    );
  }
  getInactiveFeaturedProducts(page) {
    return axios.get(
      `${this.baseServicePath}/index?properties=1&status=inactive&page=${page}`
    );
  }
  filterFeaturedProducts(payload) {
    return axios.post(
      `${this.baseServicePath}/filter/index?properties=1`,
      payload
    );
  }
  searchFeaturedProducts(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  getSingleFeatureProduct(id) {
    return axios.get(`${this.baseServicePath}/show?id=${id}&properties=1`);
  }
  activateFeaturedProducts(payload) {
    return axios.post(`${this.baseServicePath}/activate`, payload);
  }
  deactivateFeaturedProducts(payload) {
    return axios.post(`${this.baseServicePath}/deactivate`, payload);
  }
  storeFeaturedProduct(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  estimateFeaturedRate(payload) {
    return axios.post(`${this.baseServicePath}/rate/estimate`, payload);
  }
  storeFeaturedProductRate(payload) {
    return axios.post(`${this.baseServicePath}/rate/store`, payload);
  }
  showFeaturedProductRate(payload) {
    return axios.post(`${this.baseServicePath}/rate/show`, payload);
  }
}

export const featureService = new FeatureService();
