import errorHandler from "../../handlers/errorHandler";
import successHandler from "../../handlers/successHandler";
import { arrangementService } from "../../services/arrangement.service";
import arrangmentType from "./arrangmentTypes";

export const advertismentArrangements = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: true });
    const response = await arrangementService.advertismentArrangements(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({
      type: arrangmentType["ADVERTISMENT_ARRANGEMENTS"],
      payLoad: response,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
  }
};

export const storeArrangments = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: true });
    const response = await arrangementService.storeArrangement(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({ type: arrangmentType["STORE_ARRANGEMENTS"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const restaurantArrangement = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: true });
    const response = await arrangementService.restaurantArrangement(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({
      type: arrangmentType["RESTAURANT_ARRANGEMENTS"],
      payLoad: response,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    errorHandler(error, true);
  }
};
export const foodArrangement = (payLoad) => async (dispatch) => {
  try {
    const response = await arrangementService.foodArrangement(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({ type: arrangmentType["FOOD_ARRANGEMENTS"], payLoad: response });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const merchandiseArrangements = (payLoad) => async (dispatch) => {
  try {
    const response = await arrangementService.merchandiseArrangement(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({
      type: arrangmentType["MERCHANDISE_ARRANGEMENT"],
      payLoad: response,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const featureArrangements = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: true });
    const response = await arrangementService.featureArrangement(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({
      type: arrangmentType["FEATURE_ARRANGEMENTS"],
      payLoad: response,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    errorHandler(error, true);
  }
};

export const packageArrangements = (payLoad) => async (dispatch) => {
  try {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: true });
    const response = await arrangementService.packageArrangement(payLoad);
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    dispatch({
      type: arrangmentType["PACKAGE_ARRANGEMENTS"],
      payLoad: response,
    });
    successHandler(response, true);
    return true;
  } catch (error) {
    dispatch({ type: arrangmentType["LOADING_ARRANGEMENTS"], payLoad: false });
    errorHandler(error, true);
  }
};
