import axios from "../plugins/axios.plugin";

class CartService {
  baseServicePath = "/api/cart";

  getAllCarts(payload) {
    return axios.get(
      `${this.baseServicePath}/index?properties=1&page=${payload}`,
      payload
    );
  }
  filterCart(payload) {
    return axios.post(`${this.baseServicePath}/filter/index`, payload);
  }
  storeCart(payload) {
    return axios.post(`${this.baseServicePath}/store`, payload);
  }
  searchCart(payload) {
    return axios.post(`${this.baseServicePath}/search/index`, payload);
  }
  showCart(payload) {
    return axios.get(
      `${this.baseServicePath}/show?user_id=${payload}&properties=1`
    );
  }
  showMyCart() {
    return axios.get(`${this.baseServicePath}/me?properties=1`);
  }
  showMyCartNoProperties() {
    return axios.get(`${this.baseServicePath}/me`);
  }
  editCart(payload) {
    return axios.post(`${this.baseServicePath}/update`, payload);
  }
  deleteCart(payload) {
    return axios.post(`${this.baseServicePath}/delete`, payload);
  }
  emptyCart(payload) {
    return axios.post(`${this.baseServicePath}/empty`, payload);
  }
  checkoutCart(payload) {
    return axios.post(`${this.baseServicePath}/checkout`, payload);
  }

  publicCart(payload) {
    return axios.post(`/api/public/cart/load`, payload);
  }
}
export const cartService = new CartService();
