import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { searchUsersAction } from "../redux/users/userActions";
import { searchRestaurantsAction } from "../redux/restaurant/restaurantActions";
import { searchAllStores } from "../redux/stores/storeActions";

const SearchableDropdown = ({
  options,
  label,
  id,
  name,
  htmlFor,
  placeholder,
  selectOption,
}) => {
  const [query, setQuery] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [selectedVal, setSelectedVal] = useState("");
  const [optionsArray, setOptionsArray] = useState([]);
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    setOptionsArray(options);
  }, [options]);

  useEffect(() => {
    document.addEventListener("click", toggle);
    return () => document.removeEventListener("click", toggle);
  }, []);

  function toggle(e) {
    setIsOpen(e && e.target === inputRef.current);
  }

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    if (value?.length > 3) {
      handleSearch(value);
    }
    if (value?.length < 1) {
      setOptionsArray(options);
    }
  };

  const handleSearch = async (value) => {
    if (options?.[0]?.name) {
      try {
        const response = await dispatch(searchUsersAction({ search: value }));
        if (response) {
          setOptionsArray(response?.data);
        }
      } catch (error) {}
    } else if (options?.[0]?.restaurant_name) {
      try {
        const response = await dispatch(
          searchRestaurantsAction({ search: value })
        );
        if (response) {
          setOptionsArray(response?.data);
        }
      } catch (error) {}
    } else {
      try {
        const response = await dispatch(searchAllStores({ search: value }));
        if (response) {
          setOptionsArray(response?.data);
        }
      } catch (error) {}
    }
  };

  const filter = (options) => {
    return options?.filter(
      (option) => option[label]?.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  useEffect(() => {
    setQuery(selectedVal);
  }, [selectedVal]);

  return (
    <div className="dropdown">
      <div className="control">
        <div className="selected-value">
          <input
            className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
            ref={inputRef}
            type="text"
            htmlFor={htmlFor}
            value={query}
            placeholder={placeholder}
            name={name}
            autoComplete="off"
            onChange={(e) => handleChange(e)}
            onClick={toggle}
          />
        </div>
        <div className={`arrow ${isOpen ? "open" : ""}`}></div>
      </div>

      <div className={`options ${isOpen ? "open" : ""}`}>
        {filter(optionsArray)?.map((option, index) => {
          return (
            <div
              onClick={() => {
                setIsOpen(false);
                selectOption(option);
                setSelectedVal(
                  option.restaurant_name
                    ? option.restaurant_name
                    : option.store_name
                    ? option.store_name
                    : option.name
                    ? option.name
                    : ""
                );
              }}
              className={`option ${
                option[label] === selectedVal ? "selected" : ""
              }`}
              key={`${id}-${index}`}
            >
              {option[label]}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SearchableDropdown;
