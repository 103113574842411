import React from "react";
import Container from "../components/container/container";
import Header from "../components/restaurant/restaurant-header";

export function LeftColumn({ children }) {
  return (
    <div className="grid grid-cols-1 gap-4 lg:col-span-3 max-w-2xl">
      {children}
    </div>
  );
}

export function RightColumn({ children }) {
  return (
    <div id="right" className="grid grid-cols-1 lg:col-span-2 gap-4">
      {children}
    </div>
  );
}

export default function RestaurantLayout({ children, restaurant, SearchFood }) {
  return (
    <div id="start">
      <Header restaurant={restaurant} SearchFood={SearchFood} />
      <Container>
        <main className="pb-8 pt-6">
          <div className="max-w-3xl mx-auto lg:max-w-7xl">
            {/* Main 3 column grid */}
            <div className="grid grid-cols-1 gap-8 items-start lg:grid-cols-5 lg:gap-16">
              {children}
            </div>
          </div>
        </main>
      </Container>
    </div>
  );
}
