import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const TableSkeleton = () => {
  return (
    <section>
      <h4 className="card-title mb-5">
        <Skeleton height={36} />
      </h4>
      <ul className="grid grid-cols-1 gap-2">
        {Array(8)
          .fill()
          .map((item, index) => (
            <li className="card" key={index}>
              <Skeleton height={30} />
            </li>
          ))}
      </ul>
    </section>
  );
};
export default TableSkeleton;
