const SERVICE_AREAS = {
  FILTERING: "FILTERING",
  CREATING: "CREATING",
  DELETING: "DELETING",
  UPDATING: "UPDATING",
  FILTER_SERVICE_AREAS: "FILTER_SERVICE_AREAS",
  CREATE_SERVICE_AREA: "CREATE_SERVICE_AREA",
  EDIT_SERVICE_AREA: "EDIT_SERVICE_AREA",
};

export default SERVICE_AREAS;
