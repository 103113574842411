import React, { useState, Fragment, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dialog, Transition } from "@headlessui/react";

import CLOSE from "../../../assets/images/icons/close.svg";
import CloseTag from "../../../assets/images/icons/close.svg";

import AddPackageSkeleton from "../../skeleton/addPackage";
import { searchUsersAction } from "../../../redux/users/userActions";
import { searchProductTag } from "../../../redux/productTags/productTagActions";
import { searchProductCategory } from "../../../redux/productCatergory/productCategoryAction";

export function SelectableInputModal(props) {
  const {
    title,
    type,
    options,
    selectedFood,
    setSelectedFood,
    selectedFoodArr,
    setSelectedFoodArr,
    isOpen,
    setIsOpen,
    selectOption,
    selectedVal,
    setSelectedVal,
  } = props;
  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [optionsArray, setOptionsArray] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(false);
  const [show_search, setShowSearch] = useState(false);
  const [query, setQuery] = useState("");
  const { search, searching } = useSelector((state) => state.food);

  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    setOptionsArray(options);
  }, [options]);

  const filter = (options) => {
    return options?.filter(
      (option) => option?.name?.toLowerCase().indexOf(query.toLowerCase()) > -1
    );
  };

  function handleSelected(item) {
    setShowSearch(false);
    setSelectedFood([...selectedFood, item]);
    setSelectedFoodArr([...selectedFoodArr, item.id]);
  }

  function toggle(e) {
    setOpenDropdown(e && e.target === inputRef.current);
  }

  const removeItem = (index) => {
    let arr = [...selectedVal];
    const filtered = arr.filter((item, i) => i !== index);
    setSelectedVal(filtered);
  };

  const addSelectedValue = (option) => {
    if (!selectedVal.includes(option?.name)) {
      setSelectedVal([...selectedVal, option?.name]);
    }
  };

  const handleChange = (e) => {
    const { value } = e.target;
    setQuery(value);
    if (value?.length > 3) {
      handleSearch(value);
    }
    if (value?.length < 1) {
      setOptionsArray(options);
    }
  };

  const handleSearch = async (value) => {
    if (type === "tag") {
      try {
        const response = await dispatch(searchProductTag({ search: value }));
        if (response) {
          setOptionsArray(response?.data);
        }
      } catch (error) {}
    } else if (type === "user") {
      try {
        const response = await dispatch(searchUsersAction({ search: value }));
        if (response) {
          setOptionsArray(response?.data);
        }
      } catch (error) {}
    } else {
      try {
        const response = await dispatch(
          searchProductCategory({ search: value })
        );
        if (response) {
          setOptionsArray(response?.data);
        }
      } catch (error) {}
    }
  };

  return (
    <>
      <Transition appear show={isOpen}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative w-full min-h-[502px] sm:w-[650px] sm:max-w-2xl bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:p-8">
                  <div>
                    <div className=" sm:block absolute top-3 right-2 pt-4 pr-4">
                      <button
                        type="button"
                        className="bg-[#ffeae5] rounded-sm p-1 sm:p-3  "
                        onClick={closeModal}
                      >
                        <span className="sr-only">Close</span>
                        <img
                          src={CLOSE}
                          alt="close"
                          className=" w-3 h-3 sm:w-4 sm:h-4"
                        />
                      </button>
                    </div>
                    <div className="pb-3 text-center sm:text-center border-b border-[#E4E4F3]">
                      <Dialog.Title
                        as="h3"
                        className="text-xl font-bold text-black "
                      >
                        Search and select {type}
                      </Dialog.Title>
                    </div>
                  </div>

                  <div className="mt-8">
                    <div className="mt-4">
                      <label
                        htmlFor="store_id"
                        className="text-sm font-medium text-[#151515]"
                      >
                        {title}
                      </label>

                      <div className="dropdown">
                        <div className="control">
                          <div className="selected-value">
                            <input
                              className="mt-2 block pl-3 pr-10 w-full text-base bg-[#FFFFFF] focus:ring-2 focus:ring-red-600 focus:border-red-600 focus:outline-none sm:text-sm h-[40px] px-4 py-2 mb-4 border border-gray-300 "
                              ref={inputRef}
                              type="text"
                              value={query}
                              placeholder={"search"}
                              autoComplete="off"
                              onChange={(e) => handleChange(e)}
                              onClick={toggle}
                            />
                          </div>
                          <div
                            className={`arrow ${openDropdown ? "open" : ""}`}
                          ></div>
                        </div>

                        <div
                          className={`options ${openDropdown ? "open" : ""}`}
                        >
                          {filter(optionsArray)?.map((option, index) => {
                            return (
                              <div
                                key={index}
                                onClick={() => {
                                  setOpenDropdown(false);
                                  selectOption(option);
                                  setQuery(option.name);
                                  addSelectedValue(option);
                                }}
                                className={`option ${
                                  option.name === selectedVal ? "selected" : ""
                                }`}
                              >
                                {option.name}
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>

                    <div className="relative">
                      {searching ? (
                        <div className="absolute w-full bg-white shadow top-[68px]">
                          <div className="p-3">
                            <AddPackageSkeleton />
                          </div>
                        </div>
                      ) : (
                        show_search &&
                        search?.length > 0 && (
                          <div className="absolute w-full bg-white shadow top-[68px]">
                            <div className="p-3">
                              {search?.length > 0 &&
                                search?.map((item, i) => (
                                  <div
                                    key={i}
                                    className="flex justify-between cursor-pointer mb-2 hover:border-b"
                                    onClick={() => handleSelected(item)}
                                  >
                                    <div>
                                      {" "}
                                      <p className="text-sm">
                                        {item?.food_name}
                                      </p>{" "}
                                    </div>
                                    <p className="text-primary text-sm">
                                      + Add
                                    </p>
                                  </div>
                                ))}
                            </div>
                          </div>
                        )
                      )}
                    </div>

                    <div className="flex flex-wrap gap-3 mt-4">
                      {selectedVal.length > 0 &&
                        selectedVal.map((val, i) => (
                          <span
                            key={i}
                            onClick={(e) => {
                              e.stopPropagation();
                              removeItem(i);
                            }}
                            className="px-3 text-[16px] bg-[#F4F4F4] px-2 py-1 rounded-md inline-flex gap-2 items-center"
                          >
                            {val}
                            <img
                              src={CloseTag}
                              alt="close"
                              className="w-[13px] h-[13px]"
                            />
                          </span>
                        ))}
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
