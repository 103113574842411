import React, { useMemo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Card from "../../../../components/cards/admin/cards";
import Table from "../../../../components/tables/tableCols1";
import { Pagination } from "../../../../components/pagination/pagination";
import { getAllSubscriptionMerchAction } from "../../../../redux/subscriptionMerchandise/subscriptionMerchAction";

export default function Subscription() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  let { allSubscriptionMerchs } = useSelector(
    (state) => state.subscriptionMerch
  );

  useEffect(() => {
    dispatch(getAllSubscriptionMerchAction());
    // eslint-disable-next-line
  }, []);

  function createTableData() {
    let dataToMap = allSubscriptionMerchs?.data;
    if (Array.isArray(dataToMap)) {
      let outputArray = [];
      dataToMap.map((food, index) => {
        let show = {
          id: index + 1,
          invoice: "iogw663710011772",
          day: "Friday",
          price: "₦8,000.00",
          name: "Breakfast Extra",
          subscriber: "subscriber@gmail.com",
          courier: "Goodluck",
          store: food?.restaurant?.restaurant_name,
          packageData: food,
        };
        outputArray.push({ show });
        return true;
      });
      return outputArray;
    }
  }

  useEffect(() => {
    const dataSet = createTableData();
    setData(dataSet);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allSubscriptionMerchs]);

  function goToSinglePage(params) {
    let id = params?.show?.packageData?.id;
    navigate(`/admin/merchandise-packages/package/${id}`);
  }

  const columns = useMemo(
    () => [
      {
        Header: "",
        hideHeader: false,
        id: "checkbox-table-column",
        // First group columns
        columns: [
          {
            Header: "S/N",
            accessor: "show.id",
          },
          {
            Header: "Invoice",
            accessor: "show.invoice",
          },
          {
            Header: "Day",
            accessor: "show.day",
          },
          {
            Header: "Remiter Price",
            accessor: "show.price",
          },
          {
            Header: "Package Name",
            accessor: "show.name",
          },
          {
            Header: "Subscriber",
            accessor: "show.subscriber",
          },
          {
            Header: "Courier",
            accessor: "show.courier",
          },
          {
            Header: "Store",
            accessor: "show.store",
          },
        ],
      },
    ],
    []
  );
  return (
    <div data-test="subscription-page">
      <Card.TableFilter results={80} />

      <div className="border-b border-[#E4E4F3]"></div>

      <div className="bg-white rounded-md py-4 px-8">
        <Table
          columns={columns}
          data={data?.length > 0 ? data : []}
          onClick={goToSinglePage}
          className="w-full"
          rowClass="hover:shadow-md cursor-pointer"
        />
      </div>

      <Pagination data={allSubscriptionMerchs} />
    </div>
  );
}
