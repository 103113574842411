import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../inputs/inputs";
import { Beat } from "../../../plugins/spinners.plugin";
import Location from "../../includes/location";
import {
  setNewAddressAction,
  getMyAddressesAction,
} from "../../../redux/address/addressActions";
import { getUserFromCookies } from "../../../utils/Auth";
import { filterPublicServiceAreas } from "../../../redux/publicServiceAreas/publicServiceAreasActions";

export default function CreateAddressform({ props }) {
  const dispatch = useDispatch();
  const user = getUserFromCookies();

  const { creating } = useSelector((state) => state.address);
  const { filter, filtering } = useSelector((state) => state.publicServiceArea);

  const [payLoad] = useState({ end_date: new Date(), pagination: 0 });
  const [submitted, setSubmitted] = useState(false);
  const [input, setInput] = useState({
    // title: "",
    name: "",
    phone: "",
    address: "",
    country: "",
    state: "",
    city: "",
    service_area_id: "",
    address_selected: 0,
    email: user?.email,
  });
  const { name, phone, address, service_area_id, country, state, city } = input;

  //This is used to get the list of region data ie: country, city and states
  const [locationId, setLocationId] = React.useState({
    country_id: "",
    state_id: "",
    city_id: "",
  });
  const [locationList, setLocationList] = React.useState({
    countryList: [],
    stateList: [],
    cityList: [],
  });
  const [locationLoader, setLocationLoader] = useState(false);
  const toJson = (value) => JSON.stringify(value);
  const fromJson = (value) => JSON.parse(value);

  useEffect(() => {
    dispatch(filterPublicServiceAreas(payLoad));
    // eslint-disable-next-line
  }, []);

  function handleRegion(e) {
    const { name, value } = e.target;
    setInput((input) => ({ ...input, [name]: fromJson(value).name }));
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    e.preventDefault();
    setInput({ ...input, [name]: value });
  };

  const handleDefault = (e) => {
    // const { name, } = e.target;
    e.preventDefault();
    setInput({ ...input, address_selected: 1 });
  };

  useEffect(() => {
    if (city !== "") {
      dispatch(filterPublicServiceAreas({ city: city, pagination: 0 }));
    }
  }, [dispatch, city]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    if (
      name &&
      phone &&
      address &&
      service_area_id &&
      country &&
      state &&
      city
    ) {
      try {
        const response = await dispatch(
          setNewAddressAction({
            ...input,
            email: user?.email,
          })
        );

        if (response) {
          dispatch(getMyAddressesAction());
          props.onClose();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* <div>
        <Input.Label
          title={"Title (E.g: My Home, My Office)"}
          type="text"
          name="title"
          id="title"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your address title e.g: Home, Office, etc."
          onChange={handleChange}
        />
        <p className="text-[10px] text-[#4D4D4D]">
          Name tag of this address e.g my work, my apartment.
        </p>
        {submitted && !title && (
          <div className="text-xs text-red-500">Title is required</div>
        )}
      </div> */}
      <div className="mt-6">
        <Input.Label
          title={"Recipient Name"}
          type="text"
          name="name"
          id="name"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter recipient name"
          onChange={handleChange}
        />
        {submitted && !name && (
          <div className="text-xs text-red-500">Recipient name is required</div>
        )}
      </div>
      <div className="mt-6">
        <Input.Label
          title={"Address Details (Door, Apartment Number)"}
          type="text"
          name="address"
          id="address"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your full address"
          onChange={handleChange}
        />
        {submitted && !address && (
          <div className="text-xs text-red-500">Address is required</div>
        )}
      </div>
      <div className="mt-6">
        <Input.Label
          title={"Phone Number"}
          type="number"
          name="phone"
          id="phone"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          placeholder="Enter your phone number"
          onChange={handleChange}
        />
        {submitted && !phone && (
          <div className="text-xs text-red-500">Phone number is required</div>
        )}
      </div>

      <Location
        country_id={locationId.country_id}
        state_id={locationId.state_id}
        city_id={locationId.city_id}
        loadingLocation={(value) => {
          setLocationLoader(value);
        }}
        countryList={(value) => {
          setLocationList({
            ...locationList,
            countryList: value,
          });
        }}
        stateList={(value) => {
          setLocationList({
            ...locationList,
            stateList: value,
          });
        }}
        cityList={(value) => {
          setLocationList({ ...locationList, cityList: value });
        }}
      />

      <div className="mt-6">
        <Input.Select
          title={"Select Country"}
          name="country"
          id="country"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"country"}
          onChange={(e) => {
            setLocationId({
              ...locationId,
              country_id: fromJson(e.target.value).id,
            });
            handleRegion(e);
          }}
        >
          <option>Select Country</option>
          {locationLoader ? (
            <option>Loading...</option>
          ) : (
            locationList?.countryList &&
            locationList?.countryList?.length > 0 &&
            locationList?.countryList?.map((item, key) => {
              return (
                <option
                  key={key}
                  value={toJson({
                    name: item.name,
                    id: item.id,
                  })}
                >
                  {item.name}
                </option>
              );
            })
          )}
        </Input.Select>
        {submitted && !country && (
          <div className="text-xs text-red-500">Country is required</div>
        )}
      </div>
      <div className="mt-6">
        <Input.Select
          title={"Select State"}
          name="state"
          id="state"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"state"}
          onChange={(e) => {
            setLocationId({
              ...locationId,
              state_id: fromJson(e.target.value).id,
            });
            handleRegion(e);
          }}
        >
          <option>Select State</option>
          {locationLoader ? (
            <option>Loading...</option>
          ) : (
            locationList?.stateList &&
            locationList?.stateList?.length > 0 &&
            locationList?.stateList?.map((item, key) => {
              return (
                <option
                  key={key}
                  value={toJson({
                    name: item.name,
                    id: item.id,
                  })}
                >
                  {item.name}
                </option>
              );
            })
          )}
        </Input.Select>
        {submitted && !state && (
          <div className="text-xs text-red-500">State is required</div>
        )}
      </div>
      <div className="mt-6">
        <Input.Select
          title={"Select City"}
          name="city"
          id="city"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"city"}
          onChange={(e) => {
            setLocationId({
              ...locationId,
              city_id: fromJson(e.target.value).id,
            });
            handleRegion(e);
          }}
        >
          <option>Select City</option>
          {locationLoader ? (
            <option>Loading...</option>
          ) : (
            locationList?.cityList &&
            locationList?.cityList?.length > 0 &&
            locationList?.cityList?.map((item, key) => {
              return (
                <option
                  key={key}
                  value={toJson({
                    name: item.name,
                    id: item.id,
                  })}
                >
                  {item.name}
                </option>
              );
            })
          )}
        </Input.Select>
        {submitted && !city && (
          <div className="text-xs text-red-500">City is required</div>
        )}
      </div>
      <div className="mt-6">
        <Input.Select
          title={"Service Area"}
          name="service_area_id"
          id="service_area_id"
          className="w-full border border-[#E4E4F3] focus-within:border-primary focus:border-primary focus:ring-0 sm:text-sm"
          htmlFor={"service_area_id"}
          onChange={handleChange}
        >
          <option>Select service area</option>
          {filtering ? (
            <option>Loading...</option>
          ) : (
            filter?.length > 0 &&
            filter?.map((item, i) => {
              return (
                <option key={i} value={item?.id}>
                  {item.area}
                </option>
              );
            })
          )}
        </Input.Select>
        {submitted && !service_area_id && (
          <div className="text-xs text-red-500">Service area is required</div>
        )}
      </div>
      <div>
        <button
          type="button"
          className="mt-5 w-full inline-flex justify-center h-14 rounded-md border border-primary shadow-sm px-4 py-4 bg-inherit text-base text-primary font-medium   sm:mt-6 sm:text-sm"
          onClick={handleDefault}
        >
          {"Set As Default Address"}
        </button>

        <button
          type="submit"
          disabled={creating}
          className=" mt-5 w-full inline-flex justify-center  h-14  rounded-md border border-transparent shadow-sm px-4 py-4 bg-primary text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500  sm:mt-6 sm:text-sm"
        >
          {creating ? <Beat color={"#ffffff"} /> : "Add address"}
        </button>
      </div>
    </form>
  );
}
